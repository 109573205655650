import React from 'react';
import { gql } from '@apollo/client';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import {
  MergeItemSiteCountryFragment,
} from '../../../../../../../../../gql/typings';
import Flag, { CountryLabel, FlagCountry } from '../../../../../../../../components/Flag/Flag';
import CountryPicker from '../../../../../../../../components/Input/Fields/CountryPicker';

const AdminMergeItemSiteCountry: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const localization = useLocalization();
  const info = props.item.type as MergeItemSiteCountryFragment;

  const displayCountry = (country: FlagCountry) => (
    <span>
      <Flag country={country} rectangle height={24} />
      &nbsp;
      {localization.formatMessage(CountryLabel[country]!)}
    </span>
  );

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.fromSiteCountry}
      toValue={info.toSiteCountry}
      valueDisplayMapper={v => displayCountry(v as FlagCountry)}
      updateMutation={() => Promise.reject()}
      inputRenderer={({ value, onChange }) => <CountryPicker value={value} onChange={onChange} />}
    >
      {localization.formatMessage(Locale.Text.Merge_request_countries_mismatch_description, {
        country1: (
          <span>
            <Flag country={info.fromSiteCountry} rectangle height={24} />
            &nbsp;
            {localization.formatMessage(CountryLabel[info.fromSiteCountry as FlagCountry]!)}
          </span>
        ),
        country2: (
          <span>
            <Flag country={info.toSiteCountry} rectangle height={24} />
            &nbsp;
            {localization.formatMessage(CountryLabel[info.toSiteCountry as FlagCountry])}
          </span>
        ),
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  fragment MergeItemSiteCountry on SiteCountry_EntityDataToBeTransferredType {
    code
    entityType
    fromSiteCountry: from
    toSiteCountry: to
  }
`;

export default AdminMergeItemSiteCountry;
