/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { FetchResult } from '@apollo/client/link/core';
import { ActivityDetailContext, ActivityDetailsContentFormInstanceProps } from '../ActivityDetailsTypes';
import { ActivityPersons_FRAGMENT } from './ActivityPersonsFragment';
import { buildPersonTableFragment } from '../../../person/PersonTable';
import { buildSelectionCriteria } from '../../../../components/Table/utils';
import { useTableColumns } from '../../../../components/entitiesSearch/results/useTableColumns';
import apolloClient from '../../../../apollo/apolloClient';
import { stringToAbsHash } from '../../../../apollo/util';
import {
  ActivityPersonSelectionIdsQueryQuery, ActivityPersonSelectionIdsQueryQueryVariables,
  ActivityPersonsQueryQuery,
  ActivityPersonsQueryQueryVariables,
  AddMultiplePersonToActivityMutationMutation,
  AddMultiplePersonToActivityMutationMutationVariables,
  EntityTypeEnum,
  personActivityQueryQuery,
  personActivityQueryQueryVariables,
  PersonCriteriaOrder,
  RemoveMultiplePersonToActivityMutationMutation,
  RemoveMultiplePersonToActivityMutationMutationVariables,
  ReplaceSinglePersonToActivityMutationMutation, ReplaceSinglePersonToActivityMutationMutationVariables,
  SelectionCriteriaInput
} from '../../../../../gql/typings';
import { TableRowSelectionReturnProps, useTableRowSelection } from '../../../../components/Table/useTableRowSelection';


export type UseActivityPersonSelectionReturnTypeOld = {
  addPersons: (selection: SelectionCriteriaInput) => Promise<void | FetchResult | ActivityDetailsContentFormInstanceProps>;
  replacePerson: (id: number) => Promise<void | FetchResult | ActivityDetailsContentFormInstanceProps>;
  personsToShow: NonNullable<ActivityPersonsQueryQuery['persons']>['nodes'];
  removePersons: (
    selection: SelectionCriteriaInput,
    validate?: boolean
  ) => Promise<void | FetchResult | ActivityDetailsContentFormInstanceProps>;
  totalCount: number;
  loading: boolean;
  tableSelection: TableRowSelectionReturnProps;
  // tableProps: {
  //   loading?: boolean;
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   columns: ColumnsType<any>;
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   onChange: TableProps<any>['onChange'];
  // };
};

export const useActivityPersonSelection = (): UseActivityPersonSelectionReturnTypeOld => {
  const {
    activityId,
    personIds,
    dispatch,
  } = useContext(ActivityDetailContext);

  const tableSelection = useTableRowSelection();
  const {
    query,
    sorting,
    loading: columnsLoading,
  } = useTableColumns(EntityTypeEnum.PERSON);
  const { data, loading, refetch } = useQuery<ActivityPersonsQueryQuery, ActivityPersonsQueryQueryVariables>(DataQuery(query), {
    variables: {
      activityId: activityId ?? -1,
      limit: tableSelection.tableProps.perPageState[0],
      offset: (tableSelection.tableProps.pageState[0] - 1) * tableSelection.tableProps.perPageState[0],
      ordering: sorting.length > 0 ? sorting.map(s => s.currentEnum as PersonCriteriaOrder) : undefined,
    },
    skip: !activityId || columnsLoading,
  });

  const {
    data: personData
  } = useQuery<personActivityQueryQuery, personActivityQueryQueryVariables>(PersonActivityQuery(query), {
    variables: {
      ids: personIds,
      limit: tableSelection.tableProps.perPageState[0],
      offset: (tableSelection.tableProps.pageState[0] - 1) * tableSelection.tableProps.perPageState[0],
    },
    skip: !personIds || personIds.length === 0,
  });


  const [
    addPersonsMutation,
    { loading: addLoading },
  ] = useMutation<AddMultiplePersonToActivityMutationMutation, AddMultiplePersonToActivityMutationMutationVariables>(
    ADD_PERSONS_MUTATION
  );
  const [
    removePersonsMutation,
    { loading: removeLoading },
  ] = useMutation<RemoveMultiplePersonToActivityMutationMutation, RemoveMultiplePersonToActivityMutationMutationVariables>(
    REMOVE_PERSONS_MUTATION
  );

  const [
    replacePersonMutation,
    { loading: replaceLoading },
  ] = useMutation<ReplaceSinglePersonToActivityMutationMutation, ReplaceSinglePersonToActivityMutationMutationVariables>(
    REPLACE_PERSON_MUTATION
  );

  /**
   * Make sure data is update to date when mounting the current component
   */
  useEffect(() => {
    if (!loading) refetch();
  }, []);


  const addPersons = (
    selection: SelectionCriteriaInput,
  ): Promise<void | FetchResult | ActivityDetailsContentFormInstanceProps> => {
    if (!activityId) {
      return apolloClient.query<ActivityPersonSelectionIdsQueryQuery, ActivityPersonSelectionIdsQueryQueryVariables>({
        query: ActivityPersonSelectionIds_DATA_QUERY,
        variables: { selection },
      }).then(res => dispatch({
        type: 'updatePersonIds',
        personIds: res.data.selectionToIds,
      }));
    }
    return addPersonsMutation({
      variables: {
        activityId,
        selection,
      },
    }).then(() => refetch());
  };

  const removePersons = (
    selection: SelectionCriteriaInput,
  ): Promise<void | FetchResult | ActivityDetailsContentFormInstanceProps> => {
    if (!activityId) {
      return apolloClient.query<ActivityPersonSelectionIdsQueryQuery, ActivityPersonSelectionIdsQueryQueryVariables>({
        query: ActivityPersonSelectionIds_DATA_QUERY,
        variables: { selection },
      }).then(res => dispatch({
        type: 'updatePersonIds',
        personIds: personIds?.filter(existing => !res.data.selectionToIds.includes(existing)) ?? [],
      }));
    }

    return removePersonsMutation({
      variables: {
        activityId,
        personSelection: selection,
      },
    }).then(() => refetch());
  };

  const replacePerson = (id: number): Promise<void | FetchResult | ActivityDetailsContentFormInstanceProps> => {
    if (!activityId) return removePersons(buildSelectionCriteria({ selected: [id] }))
      .then(() => addPersons(buildSelectionCriteria({ selected: [id] })));

    return replacePersonMutation({
      variables: { activityId, personId: id }
    }).then(() => refetch());
  };


  return {
    personsToShow: data?.persons.nodes ?? personData?.persons.nodes ?? [],
    totalCount: data?.persons.totalCount ?? personIds?.length ?? 0,
    addPersons,
    removePersons,
    replacePerson,
    loading: removeLoading || addLoading || replaceLoading,
    tableSelection,
  };
};

const ADD_PERSONS_MUTATION = gql`
  mutation AddMultiplePersonToActivityMutation($activityId: Int!, $selection: SelectionCriteriaInput!) {
    addPersonsToActivity(activityId: $activityId, selection: $selection) {
      id
      persons {
        hash
        totalCount
        nodes {
          id
          ...ActivityPersonFragment
        }
      }
    }
  }
  ${ActivityPersons_FRAGMENT}
`;
const REMOVE_PERSONS_MUTATION = gql`
  mutation RemoveMultiplePersonToActivityMutation($activityId: Int!, $personSelection: SelectionCriteriaInput!) {
    removePersonsFromActivity(activityId: $activityId, personSelection: $personSelection) {
      id
      persons {
        hash
        totalCount
        nodes {
          id
          ...ActivityPersonFragment
        }
      }
    }
  }
  ${ActivityPersons_FRAGMENT}
`;

const REPLACE_PERSON_MUTATION = gql`
  mutation ReplaceSinglePersonToActivityMutation($activityId: Int!, $personId: Int!) {
    replacePersonInActivity(activityId: $activityId, personId: $personId) {
      id
      persons {
        hash
        totalCount
        nodes {
          id
          ...ActivityPersonFragment
        }
      }
    }
  }
  ${ActivityPersons_FRAGMENT}
`;

const DataQuery = (fields: string|null) => gql`
  query ActivityPersonsQuery($activityId: ID!, $limit: Int!, $offset: Int!, $ordering: [PersonCriteriaOrder!]) {
    persons(criteria: {
      activityId: $activityId,
      includeUnplaced: null,
      isActive: BOTH,
      ordering: $ordering,
      fetchSize: {
        limit: $limit,
        offset: $offset
      }
    }) {
      hash
      totalCount
      nodes {
        id
        ...ActivityPersonFragment
        ...PersonTableBuiltFragment${stringToAbsHash(fields)}
      }
    }
  }
  ${ActivityPersons_FRAGMENT}
  ${buildPersonTableFragment(fields)}
`;


export const ActivityPersonSelectionIds_DATA_QUERY = gql`
  query ActivityPersonSelectionIdsQuery($selection: SelectionCriteriaInput!){
    selectionToIds(selection: $selection, entityType: PERSON)
  }
`;

const PersonActivityQuery = (fields: string|null) => gql`
  query personActivityQuery($ids: [ID!], $limit: Int, $offset: Int){
    persons(criteria: {ids: $ids, includeUnplaced: null, isActive: BOTH, fetchSize: { limit: $limit, offset: $offset }}){
      hash
      nodes{
        id
        ...ActivityPersonFragment
        ...PersonTableBuiltFragment${stringToAbsHash(fields)}
      }
    }
  }
  ${ActivityPersons_FRAGMENT}
  ${buildPersonTableFragment(fields)}
`;
