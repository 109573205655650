import React, { useEffect } from 'react';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import MergeRequestItemPrefixIcon from '../MergeRequestItemPrefixIcon';
import { useRandomKey } from '../../../../../../../../hooks/useRandomKey';

type AdminMergeItemBasicFieldProps = AdminEntityMergeRequestItemProps;

function AdminMergeItemBasicField({
  emitter,
  item,
  children,
}: React.PropsWithChildren<AdminMergeItemBasicFieldProps>) {
  const emitterKey = useRandomKey();

  useEffect(() => {
    emitter.emit({
      key: emitterKey,
      state: 'finished',
    });
    return () => {
      emitter.emit({
        key: emitterKey,
        state: 'removed',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitter, item]);

  return (
    <span>
      <MergeRequestItemPrefixIcon state="MOVED" />
      {children}
    </span>
  );
}

export default AdminMergeItemBasicField;
