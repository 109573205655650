import { useContext } from 'react';
import DetailsViewContext, { DetailsViewContextType } from './DetailsViewContext';

type UseDetailsContext = {
  broadcastShouldRefetchData: DetailsViewContextType['broadcastShouldRefetchData'];
};

export const useDetailsContext = (refetchCb?: () => void): UseDetailsContext => {
  const ctx = useContext(DetailsViewContext);

  if (!ctx) return {
    broadcastShouldRefetchData: () => {},
  };

  if (refetchCb) {
    ctx.useShouldRefetchData(refetchCb);
  }

  return ctx;
};
