import { FormInstance, Modal } from 'antd';
import React from 'react';
import { useLocalization } from '../../../util/useLocalization';
import { StateArray } from '../../../util/StateArrayType';
import CriteriaLovModalContent from './CriteriaLovModalContent';
import { CriteriaSuggestionReturned } from '../useSuggestionDropdownValues';


export type CriteriaLovModalProps = {
  isVisible: boolean;
  title?: string | null;
  suggestionData: CriteriaSuggestionReturned;
  setVisibleAction: () => void;
  form: FormInstance;
  fromValueKey: string;
  searchState?: StateArray<string>;
};


const CriteriaLovModal: React.FC<CriteriaLovModalProps> = (props) => {
  const localization = useLocalization();

  return (
    <Modal
      title={localization.formatMessageByStr(props.title || 'Select')}
      width="clamp(480px, 70%, 960px)"
      open={props.isVisible}
      footer={false}
      onCancel={props.setVisibleAction}
      zIndex={10000}
    >
      {props.isVisible && <CriteriaLovModalContent {...props} />}
    </Modal>
  );
};


export default CriteriaLovModal;
