import React, { useState } from 'react';
import { Button, Modal, Tabs } from 'antd';
import { AdminGridConfigurationItemProps } from '../AdminGridConfigurationRight';
import { useLocalization } from '../../../../../util/useLocalization';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { SupportGridCardEntityTypes } from './useGridRightCardsData';
import AdminGridConfigureCardsTable from './AdminGridConfigureCardsTable';
import { EntityTypeEnum } from '../../../../../../gql/typings';


const items: Record<SupportGridCardEntityTypes, {
  headingKey: { id: string };
  mainBoilerplate: string[];
  metaBoilerplate: string[];
  tabs: SupportGridCardEntityTypes[];
}> = {
  PERSON: {
    headingKey: Locale.Attribute.Person_HCP,
    mainBoilerplate: [
      'firstName',
      'lastName',
      'education',
      'personType',
      'dateOfBirth',
      'languages',
      'privateAddress',
      'contactInformation:EMAIL',
      'contactInformation:PHONE',
    ],
    metaBoilerplate: [
      'created',
      'updated',
      'imported',
      'id',
      'aPureBaseId',
      'originSource',
      'maintainerSource',
    ],
    tabs: [
      EntityTypeEnum.PERSON,
      EntityTypeEnum.AFFILIATION,
      EntityTypeEnum.SITE,
    ],
  },
  SITE: {
    headingKey: Locale.Attribute.Site_HCO,
    mainBoilerplate: [
      'name',
      'address:HCOMAIL',
      'address:HCOVISIT',
      'county',
      'specialties',
      'type',
      'contactInformation:EMAIL',
      'contactInformation:FAX',
      'contactInformation:PHONE',
      'website',
    ],
    metaBoilerplate: [
      'created',
      'updated',
      'imported',
      'id',
      'aPureBaseId',
      'originSource',
      'maintainerSource',
    ],
    tabs: [
      EntityTypeEnum.SITE,
      EntityTypeEnum.PERSON,
    ],
  },
  AFFILIATION: {
    headingKey: Locale.General.Site_person,
    mainBoilerplate: [
      'position',
      'workSpc',
      'contactInformation:EMAIL',
      'contactInformation:FAX',
      'contactInformation:PHONE',
    ],
    metaBoilerplate: [
      'created',
      'updated',
      'imported',
      'id',
      'aPureBaseId',
      'originSource',
      'maintainerSource',
    ],
    tabs: [
      EntityTypeEnum.AFFILIATION,
      EntityTypeEnum.PERSON,
      EntityTypeEnum.SITE,
    ],
  },
};

const AdminGridRightCardsConfigurator: React.FC<AdminGridConfigurationItemProps> = ({
  entityType,
  itemConfigurationId,
}) => {
  const localization = useLocalization();
  const [showModal, setShowModal] = useState(false);
  if (![EntityTypeEnum.PERSON, EntityTypeEnum.AFFILIATION, EntityTypeEnum.SITE].includes(entityType)) {
    return (
      <span>Cards for this view is currently not supported {entityType}</span>
    );
  }

  const config = items[entityType as SupportGridCardEntityTypes];

  return (
    <div>
      <Button onClick={() => setShowModal(true)}>
        Configure
      </Button>
      <Modal
        title="Configure fields to be shown on cards"
        open={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
        width="clamp(680px, 80%, 1080px)"
      >
        <Tabs items={config.tabs.map(key => ({
          key: items[key]!.headingKey.id.toString(),
          label: localization.formatMessage(items[key].headingKey),
          children: (
            <AdminGridConfigureCardsTable
              itemConfigurationId={itemConfigurationId}
              entityType={key}
              mainBoilerplate={items[key]!.mainBoilerplate}
              metaBoilerplate={items[key]!.metaBoilerplate}
            />
          )
        }))}
        />
      </Modal>
    </div>
  );
};


export default AdminGridRightCardsConfigurator;
