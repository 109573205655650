import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Col, Row, Skeleton } from 'antd';
import CardWithFields from '../../../components/Card/CardWithFields';
import SiteCardController from '../SiteCardController';
import PersonByPosCodeView from './PersonByPosCodeView';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import Card from '../../../components/Card/Card';
import LabelWithCount from '../../../components/Label/LabelWithCount';
import { EntityTabCardsProps } from '../../../components/EntityTabs/Cards/EntityTabCards';
import { EntityTypeEnum, SiteCardContainerQueryQuery, SiteCardContainerQueryQueryVariables } from '../../../../gql/typings';
import { FIELD_KEY_FRAGMENT } from '../../../util/useFieldKeysTransformed';

type SiteCardsContainerProps = {
  siteId: number;
  entityGridItemConfiguration: EntityTabCardsProps['configuration'];
};

const SiteCardsContainer: React.FC<SiteCardsContainerProps> = ({
  siteId,
  entityGridItemConfiguration,
}) => {
  const localization = useLocalization();
  const { data, loading } = useQuery<SiteCardContainerQueryQuery, SiteCardContainerQueryQueryVariables>(DATA_QUERY, {
    variables: { siteId, entityGridItemConfigurationId: entityGridItemConfiguration.id },
  });
  const controller = SiteCardController(siteId);
  const { groups } = controller.getPositionGroups();
  const positionCount = controller.getCount();

  return (
    <Skeleton loading={loading}>
      {data && data.site && <section className="site-detail-cards-container">
        <Row gutter={[16, 8]}>
          <Col id="site-card" sm={24} md={8} lg={8} xl={8} xxl={6}>
            <CardWithFields
              title={localization.formatMessage(Locale.General.Site_Information)}
              fields={data.cardFieldKeys.site.fields}
              secondaryFields={data.cardFieldKeys.site.drawerFields}
              size={entityGridItemConfiguration.height}
              topRightBadgeChar={data.site!.siteSegmentationCode?.heading}
              icon="SITE"
              recordId={siteId}
              entityType={EntityTypeEnum.SITE}
              rootEntityType={EntityTypeEnum.SITE}
            />
          </Col>
          <Col sm={24} md={8} lg={8} xl={8} xxl={6}>
            <Card
              title={localization.formatMessage(Locale.Text.Positions_on_site, { posistionCount: positionCount })}
              size={entityGridItemConfiguration.height}
              icon="POSITION"
            >
              {groups.map(group => <span key={group.code} onClick={() => controller.addSelected(group.code)}>
                <LabelWithCount
                  isSelected={controller.selectedGroup?.code === group.code}
                  label={group.heading || (<span style={{ fontStyle: 'italic' }}>
                    {localization.formatMessage(Locale.General.Without_position)}
                  </span>)}
                  count={group.count}
                />
              </span>)}
            </Card>
          </Col>
          <PersonByPosCodeView
            controller={controller}
            fields={data.cardFieldKeys}
            height={entityGridItemConfiguration.height}
          />
        </Row>
      </section>}
    </Skeleton>
  );
};

const DATA_QUERY = gql`
  query SiteCardContainerQuery($siteId: Int!, $entityGridItemConfigurationId: Int!) {
    cardFieldKeys(entityGridItemConfigurationId: $entityGridItemConfigurationId) {
      entityGridItemConfigurationId
      person {
        entityGridItemConfigurationId
        entityType
        fields { ...GridCardField }
        drawerFields { ...GridCardField }
      }
      site {
        entityGridItemConfigurationId
        entityType
        fields { ...GridCardField }
        drawerFields { ...GridCardField }
      }
    }
    site(siteId: $siteId) {
      id
      name
      siteSegmentationCode {
        id
        heading
      }
    }
  }
  ${FIELD_KEY_FRAGMENT}
`;

export default SiteCardsContainer;

