import React from 'react';
import { gql } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  SiteCriteriaOrder, UpdateSiteName2MutationMutationVariables,
} from '../../../../gql/typings';
import { buildFreeTextUpdateFieldConfig } from '../../../components/FieldConfig/buildFreeTextUpdateFieldConfig';

export const siteName2FieldConfig = siteFieldConfig(
  'name2',
  [
    'id',
    'name2',
    'isLocal',
    'controlSetting.id',
    'controlSetting.entityTypeId',
    'controlSetting.maintainerSourceCode',
    'controlSetting.createDcr',
  ],
  ({ localization, openInNewTab }) => ({
    sorting: {
      ascend: SiteCriteriaOrder.NAME2_ASC,
      descend: SiteCriteriaOrder.NAME2_DESC,
    },
    title: localization.formatMessage(Locale.Attribute.Site_name2),
    additionalTableConfig: {
      width: 120
    },
    // className: 'site-antd-table-column-width mw-275',
    render: (record, { isViewingFromEntity, isViewingFromPage }) => {
      if (isViewingFromPage == 'DETAIL_PAGE' && isViewingFromEntity === 'SITE') return record.name2;
      return <LinkBadge
        url={record.isLocal ? `/hco/${record.id}` : undefined}
        label={record.name2}
        openInNewTab={openInNewTab}
        className="open-site-link"
      />;
    },
    updateView: buildFreeTextUpdateFieldConfig({
      valuePath: 'name2',
      mutation: UPDATE_SITE_NAME2_MUTATION,
      buildVariables: (value, record) => ({
        siteId: record.id,
        siteName2: value,
      }) as UpdateSiteName2MutationMutationVariables,
    }),
  })
);

const UPDATE_SITE_NAME2_MUTATION = gql`
  mutation UpdateSiteName2Mutation($siteId: Int!, $siteName2: String!) {
    updateSiteName2(siteId: $siteId, siteName2: $siteName2) {
      id
      name2
    }
  }
`;
