import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import { DashboardItemProps } from '../DashboardItemTypes';
import DashboardCard from '../DashboardCard';
import { StackedColumnChart } from '../../Charts';
import { DashboardDCRStatusesQueryQuery } from '../../../../gql/typings';

const DashboardDcrStatusItem: React.FC<DashboardItemProps> = ({ configuration, editStatus, dashboardId, size }) => {
  const intl = useIntl();
  const id = configuration.headingKey ?? configuration.dashboardItem.headingKey;
  const { data, loading, refetch } = useQuery<DashboardDCRStatusesQueryQuery>(DATA_QUERY);

  useEffect(() => {
    if (!loading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  return (
    <DashboardCard
      editMode={editStatus}
      id={dashboardId}
      title={intl.formatMessage({ id, defaultMessage: id })}
    >
      <StackedColumnChart
        height={size.height}
        width={size.width}
        legend
        data={(data?.getDcrCounts ?? [])}
      />
    </DashboardCard>
  );
};

const DATA_QUERY = gql`
  query DashboardDCRStatusesQuery {
    getDcrCounts {
      seriesLabel
      label
      value
      color
      
    }
  }
`;

export default DashboardDcrStatusItem;
