import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Col, message, Select, Skeleton, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { EntityTabFieldProps } from './EntityTabFieldsTypes';
import { usePermission } from '../../../../permission/usePermission';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import {
  PermissionEnum,
  SiteSegmentationCodesTabQueryQuery,
  SiteSegmentationCodesTabQueryQueryVariables,
  UpsertSiteSegmentationCodeTabMutation,
  UpsertSiteSegmentationCodeTabMutationVariables
} from '../../../../../gql/typings';


const SiteSegmentationTabField: React.FC<EntityTabFieldProps> = ({ recordId }) => {
  const localization = useLocalization();
  const { read, update } = usePermission(PermissionEnum.HCP_SEGMENTATION);
  const [removeSiteSegmentation] = useMutation(RemoveHcoSegmentationCode);
  const { formatMessage } = useIntl();
  const {
    data,
    loading,
  } = useQuery<SiteSegmentationCodesTabQueryQuery, SiteSegmentationCodesTabQueryQueryVariables>(DATA_QUERY, {
    variables: { siteId: recordId },
    skip: !read,
  });
  const [
    updateSegmentation,
  ] = useMutation<UpsertSiteSegmentationCodeTabMutation, UpsertSiteSegmentationCodeTabMutationVariables>(
    UpsertSegmentationCodeMutation
  );
  const [selected, setSelected] = useState<number>();

  const handleChange = (selectedItems: number) => {
    if (selectedItems != null) {
      updateSegmentation({
        variables: {
          id: recordId,
          segmentationId: selectedItems,
        },
        refetchQueries: ['SiteSegmentationCodesTabQuery'],
      }).then(() => message.success(formatMessage(Locale.Text.Segmentation_code_has_been_successfully_updated)));
    } else {
      removeSiteSegmentation({
        variables: {
          id: recordId,
        },
        refetchQueries: ['SiteSegmentationCodesTabQuery'],
      }).then(() => message.success(formatMessage(Locale.Text.Segmentation_code_has_been_successfully_removed)));
    }
  };

  useEffect(() => {
    if (data?.site?.id) setSelected(data.site.siteSegmentationCode?.id);
  }, [data]);

  const segmentationText = localization.formatMessage(Locale.Attribute.HCO_Segment);

  // TODO: Fix!
  if (!update && data?.site?.siteSegmentationCode?.heading) return (
    <span>NaDa'</span>
  );


  return (
    <>
      <Col sm={24} md={8}>
        <strong>{segmentationText}</strong>
        <Tooltip title={localization.formatMessage(Locale.Text.Add_segmentation_code_here)}>
          <InfoCircleOutlined style={{ paddingLeft: 4, verticalAlign: 'top' }} />
        </Tooltip>
      </Col>
      <Col sm={24} md={16}>
        <Skeleton loading={loading} title={{ width: 240 }} active={loading} paragraph={false}>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={localization.formatMessage(Locale.Command.Select_segment_code)}
            optionFilterProp="children"
            allowClear
            onChange={handleChange}
            value={selected}
          >
            {(data?.segmentationCodes.nodes ?? []).map(segmentation => (
              <Select.Option key={segmentation.id} value={segmentation.id}>
                {segmentation.heading}
              </Select.Option>
            ))}
          </Select>
        </Skeleton>
      </Col>
    </>
  );
};

const DATA_QUERY = gql`
  query SiteSegmentationCodesTabQuery($siteId: Int!) {
    site(siteId: $siteId) {
      id
      siteSegmentationCode {
        id
        heading
      }
    }
    segmentationCodes {
      hash
      nodes {
        code
        id
        description
        heading
      }
    }
  }
`;

const UpsertSegmentationCodeMutation = gql`
  mutation UpsertSiteSegmentationCodeTab(
    $id: Int!,
    $segmentationId: Int!
  ) {
    upsertSiteSegmentationCode(siteId: $id, segmentationCodeId: $segmentationId) {
      id
      segmentationCode {
        id
        code
        heading
      }
    }
  }
`;

const RemoveHcoSegmentationCode = gql`
  mutation RemoveHcoTabSegmentationCodeMutation(
    $id: Int!,
  ) {
    removeSiteSegmentation(id: $id){
      id
    }
  }
`;

export default SiteSegmentationTabField;
