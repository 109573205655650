import { gql, NetworkStatus, useQuery } from '@apollo/client';
import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import FullCalendar, { ViewOptionsRefined } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { EventReceiveArg } from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import { CalendarApi, DateRangeInput, DateSelectArg, EventApi, EventClickArg } from '@fullcalendar/common';
import moment from 'moment';
import { toNumber } from 'lodash';
import { useDebounceFn, useEventListener } from 'ahooks';
import { Badge, message, Popover, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import MainView from '../../components/View/MainView';
import ActivityDrawer from '../activity/CreateView/ActivityDrawer';
import CalendarTopMenu from './CalendarTopMenu';
import CalendarActivityEvent from './CalendarActivityEvent';
import { FullcalendarDisplayMode, useCalendarOn } from './FullcalendarTypes';
import { UserType, useUser } from '../../util/useUser';
import { ActivityDetailsInitialState } from '../activity/ActivityDetails/ActivityDetailsTypes';
import { apbListDayPlugin } from './apbListDay';
import CalendarNavigationMenu from './CalendarNavigationMenu';
import { Locale } from '../../../localization/LocalizationKeys';
import { usePermission } from '../../permission/usePermission';
import CalendarUserSelector from './CalendarUserSelector';
import { useLocalization } from '../../util/useLocalization';
import {
  useUpdateActivityTimespanMutationMutation,
  ViewerActivitiesCalendarQuery,
  ViewerActivitiesCalendarQueryVariables
} from '../../../gql/typings';
import { DATE_FORMAT_DAY } from '../../util/format';

type CalendarProps = {
  hideTopConfiguration?: boolean;
  initialView?: FullcalendarDisplayMode;
  storageKey?: string;
};


const visibleRange = (currentDate: Date): DateRangeInput => {
  // Generate a new date for manipulating in the next step
  const startDate = new Date(currentDate.valueOf());
  const endDate = new Date(currentDate.valueOf());

  // Adjust the start & end dates, respectively
  startDate.setDate(startDate.getDate() - 1); // One day in the past
  endDate.setDate(endDate.getDate() + 1); // Two days into the future

  return { start: startDate, end: endDate };
};

const Calendar: React.FC<CalendarProps> = (props) => {
  const drawerState = useState(false);
  const ref = useRef<LegacyRef<void>>();
  const date = moment().date();
  const localization = useLocalization();

  const [calendarApi, setCalendarApi] = useState<CalendarApi>();
  const [activityState, setActivityState] = useState<ActivityDetailsInitialState & { activityId?: number }>();
  const [activityPlanningState, setActivityPlanningState] = useState(false);
  const { read: hasOtherCalendarPermission } = usePermission('OtherCalendar');
  const user = useUser();
  const [userPopoverVisible, setUserPopoverVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserType>(user);
  const {
    data,
    refetch,
    networkStatus,
  } = useQuery<ViewerActivitiesCalendarQuery, ViewerActivitiesCalendarQueryVariables>(DATA_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      dateFrom: calendarApi ? moment(calendarApi.view.activeStart) : moment().startOf('week'),
      dateTo: calendarApi ? moment(calendarApi.view.activeEnd) : moment().endOf('week'),
      responsibleUserId: selectedUser.id,
    },
  });
  const [updateTimespanMutation] = useUpdateActivityTimespanMutationMutation();
  const { run: updateTimespan } = useDebounceFn(updateTimespanMutation, { wait: 200 });
  const { run: successDebounce } = useDebounceFn(message.success, { wait: 350 });


  useEffect(() => {
    if (ref) {
      // @ts-ignore
      setCalendarApi(ref.current?._calendarApi);

      if (props.initialView) {
        // @ts-ignore
        ref.current?._calendarApi?.changeView(props.initialView);
        // @ts-ignore
        if (props.initialView === '3day') ref.current?._calendarApi.gotoDate(moment().subtract(1, 'day').toISOString());
      }
    }
  }, [props.initialView, ref]);

  const setCalendarHeight = () => {
    if (calendarApi) {
      // Set the height to fill the browser window
      const cal = document.getElementsByClassName('fc-view-harness')[0] as HTMLElement;
      calendarApi.setOption('contentHeight', window.innerHeight - cal.offsetTop);
    }
  };

  useEventListener('resize', setCalendarHeight, { capture: true });
  useEffect(setCalendarHeight, [calendarApi]);

  const handleDateSelect = (info: DateSelectArg) => {
    setActivityState({
      startDate: dayjs(info.start),
      endDate: dayjs(info.end),
    });
    drawerState[1](true);
  };

  const handleDrop = (info: EventReceiveArg) => {
    setActivityState({
      personIds: [toNumber(info.event.id)],
      startDate: dayjs(info.event.start),
      endDate: dayjs(info.event.end),
    });
    drawerState[1](true);
    info.revert();
  };

  const handleEventClick = (info: EventClickArg) => {
    setActivityState({
      activityId: toNumber(info.event.id),
    });
    drawerState[1](true);

  };

  const handleEventMove = (event: EventApi) => updateTimespan({
    variables: {
      activityId: toNumber(event.id),
      startDate: event.start,
      endDate: event.end,
    },
    optimisticResponse: {
      __typename: 'Mutation',
      updateActivity: {
        id: toNumber(event.id),
        startDate: event.start,
        endDate: event.end,
        __typename: 'Activity',
      },
    },
    update: () => successDebounce(localization.formatMessage(Locale.Text.Update_Time_Success)),
  });

  const doRefetch = () => {
    if (calendarApi) refetch({
      dateFrom: moment(calendarApi.view.activeStart),
      dateTo: moment(calendarApi.view.activeEnd),
      responsibleUserId: selectedUser.id,
    });
  };

  useCalendarOn(() => {
    // Whenever the timespan has been changed, we will fetch the activities
    doRefetch();
  }, 'datesSet', calendarApi);


  const weekOptions: ViewOptionsRefined = {
    type: 'timeGridWeek',
    allDaySlot: false,
    viewClassNames: 'work-week-calendar-container',
    dayHeaderClassNames: 'work-week-calendar-day-header',
    dayHeaderContent: (info) => {
      const m = moment(info.date);
      return (
        <div className="calendar-th-container">
          {renderCalendarMenu && (m.date() === date)
            ? <span className="th-header">
              {
                localization.formatMessage(Locale.General.Today)
              }{m.format(`(${DATE_FORMAT_DAY})`)}
            </span> : ''}
          {renderCalendarMenu && (m.date() - 1 === date)
            ? <span className="th-header">
              {localization.formatMessage(Locale.General.Tomorrow)}{m.format(`(${DATE_FORMAT_DAY})`)}
            </span> : ''}
          {
            renderCalendarMenu && (m.date() + 1 === date)
              ? <span className="th-header">
                {localization.formatMessage(Locale.General.Yesterday)}{m.format(`(${DATE_FORMAT_DAY})`)}
              </span> : ''
          }
          {!renderCalendarMenu && m.format('D ddd')}


          {renderCalendarMenu && (m.date() > date + 1 || m.date() < date - 1)
            ? <span className="th-header">{m.format(`(${DATE_FORMAT_DAY})`)}</span> : ''}

          <Badge
            className="badge"
            count={(data?.activities.nodes ?? [])
              .filter(a => moment(a.startDate).isSame(m, 'day'))
              .length}
          />
        </div>
      );
    },
    // @ts-ignore
    slotDuration: { hours: 1 },
    slotLabelFormat: ({ date }) => moment(date)
      .format('LT')
      .replace(/([:.e])[0-9]{2}/, ''),
  };

  const renderCalendarMenu = props.initialView === '3day';

  const CalendarTitle: React.FC = () => {
    if (renderCalendarMenu) return <span />;

    let other = <span />;
    if (hasOtherCalendarPermission) other = (
      <Popover
        placement="bottom"
        arrow={{ arrowPointAtCenter: true }}
        trigger={['click']}
        open={userPopoverVisible}
        onOpenChange={setUserPopoverVisible}
        content={<CalendarUserSelector
          selectedUserState={[selectedUser, setSelectedUser]}
          close={() => setUserPopoverVisible(false)}
        />}
      >
        <Tooltip title={localization.formatMessage(Locale.Text.Select_user_shared_calendar_tooltip)}>
          <DownOutlined />
        </Tooltip>
      </Popover>
    );

    return (
      <div className="title">
        <span>
          {selectedUser.id !== user.id && `${selectedUser.fullname}'s `}
          {localization.formatMessage(Locale.Attribute.Calendar)}
        </span>
        {other}
      </div>
    );
  };

  return (
    <MainView
      className="calendar-view-container"
      withoutPadding
      title={<CalendarTitle />}
      style={{ padding: '30px 0 0 30px' }}
    >
      <div>
        <ActivityDrawer
          visibleState={drawerState}
          state={activityState}
          activityId={activityState?.activityId}
          onCreated={doRefetch}
        />
        {calendarApi && props.hideTopConfiguration !== true && <CalendarTopMenu
          api={calendarApi}
          storageKey={props.storageKey ?? 'main'}
          loading={[NetworkStatus.fetchMore, NetworkStatus.loading].includes(networkStatus)}
          hideChangeViewButtons={renderCalendarMenu}
          setActivityPlanning={setActivityPlanningState}
          activityPlanningState={activityPlanningState}
        />}
        <div className="calendar-container">


          <div className={activityPlanningState ? 'calendar-container-modified-width' : 'calendar-container-full'}>
            <FullCalendar
              // @ts-ignore
              ref={ref}
              plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, momentPlugin, apbListDayPlugin]}
              headerToolbar={false}
              editable
              selectable
              selectMirror
              dayMaxEvents
              droppable
              rerenderDelay={5000}
              events={(data?.activities.nodes ?? []).map(activity => ({
                id: `${activity.id}`,
                title: activity.heading,
                status: activity.status,
                start: activity.startDate,
                phone: activity.sites.nodes[0]?.phone,
                end: activity.endDate,
                site: activity.sites.nodes[0],
                textColor: activity.type.color.value,
                persons: activity.persons
              }))}
              select={handleDateSelect}
              eventResizeStop={info => handleEventMove(info.event)}
              eventChange={info => handleEventMove(info.event)}
              eventReceive={handleDrop}
              eventClick={handleEventClick}
              eventContent={info => <CalendarActivityEvent content={info} />}
              eventClassNames="calendar-event-container"
              nowIndicator
              firstDay={moment().weekday(0).isoWeekday()} // Not sure if this is correct :P
              slotEventOverlap={false}
              businessHours={{
                start: moment().set({ hour: 7, minute: 0 }).format('hh:mm'),
                end: moment().set({ hour: 17, minute: 0 }).format('hh:mm'),
              }}
              views={{
                day: {
                  type: 'timeGridDay', // apbListDay
                  allDaySlot: false,
                  viewClassNames: 'work-week-calendar-container',
                },
                '3day': {
                  ...weekOptions,
                  visibleRange,
                  duration: {
                    days: 3
                  },
                },
                workWeek: {
                  ...weekOptions,
                  weekends: false,
                },
                week: {
                  ...weekOptions,
                  weekends: true,
                },
                month: {
                  type: 'dayGridMonth',
                  viewClassNames: 'calendar-month-container',
                }
              }}
            />
          </div>
          {activityPlanningState && <div className="activity-planning-section">
            <CalendarNavigationMenu />
          </div>}
        </div>
      </div>
    </MainView>
  );
};

const DATA_QUERY = gql`
  query ViewerActivitiesCalendar($responsibleUserId: ID!, $dateFrom: DateTime, $dateTo: DateTime) {
    activities(criteria: {
      responsibleUserId: $responsibleUserId,
      fetchSize: {limit: 100},
      dateWithin: {
        from: $dateFrom,
        to: $dateTo
      }
    }) {
      hash
      totalCount
      nodes {
        id
        startDate
        endDate
        heading
        status {
          code
          codeEnum
          headingKey
        }
        persons{
          hash
          nodes{
            id
            fullName
          }
        }
        sites {
          hash
          nodes {
            id
            name
            phone: mainContact(type: PHONE) { id, value }
            visitAddress {
              id
              city
              countyCode
              street
              postalCode
            }
          }
        }

        type {
          code
          heading
          color {
            code
            value
          }
        }
      }
    }
  }
`;

gql`
  mutation UpdateActivityTimespanMutation($activityId: Int!, $startDate: DateTime!, $endDate: DateTime!) {
    updateActivity(input: {
      activityId: $activityId,
      startDate: $startDate,
      endDate: $endDate
    }) {
      id
      startDate
      endDate
    }
  }
`;


export default Calendar;

