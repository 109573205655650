import { gql } from '@apollo/client';

export const SELECTED_GRID_ITEM_CONFIGURATION_FRAGMENT = gql`
  fragment SelectedGridItemConfigurationFragment on EntityGridItemConfiguration {
    id
    fields {
      hash
      nodes {
        id
        gridItemFieldTypeCode
        entityGridItemConfigurationId
        sort
        type {
          code
          heading
          isActive
          enum
        }
        customFieldConfiguration{
          id
          customField{
            code
            heading
            customFieldTypeCode
            options{
              hash
              totalCount
              nodes{
                code
                value{
                  id
                  value
                }
              }
            }
            fieldType {
              code
              enum
            }
          }
        }
      }
    }
  }
`;
