/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { message, Modal, Select, Space } from 'antd';
import { ActivityDetailContext } from '../ActivityDetailsTypes';
import Icon from '../../../../components/Icon/Icon';
import { EntitiesSearch } from '../../../../components/entitiesSearch';
import HeaderWithValue from '../../../../components/DisplayFields/HeaderWithValue';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import {
  ActivitySiteAffiliationsQuery, ActivitySiteAffiliationsQueryVariables,
  ActivitySitesSuggestionsQuery, ActivitySitesSuggestionsQueryVariables,
  AttachmentRuleEnum, EntityTypeEnum, SiteFragmentForDisplayFragment,
  UpdateActivityLocationMutation,
  UpdateActivityLocationMutationVariables
} from '../../../../../gql/typings';
import { useTableRowSelection } from '../../../../components/Table/useTableRowSelection';

const ActivityLocationContainer = () => {
  const localization = useLocalization();
  const {
    activityId,
    dispatch,
    personIds,
    locations,
    activityType,
    locationsErrors,
  } = useContext(ActivityDetailContext);
  const moduleEnabled = activityType && [
    AttachmentRuleEnum.ONE,
    AttachmentRuleEnum.OPTIONAL_ONE,
  ].includes(activityType.locationRule);
  const [
    UpdateActivityLocation,
  ] = useMutation<UpdateActivityLocationMutation, UpdateActivityLocationMutationVariables>(UPDATE_ACTIVITY_LOCATION);
  const [
    loadSiteSuggestions,
    { data: suggestionData, loading: suggestionsLoading },
  ] = useLazyQuery<ActivitySitesSuggestionsQuery, ActivitySitesSuggestionsQueryVariables>(SUGGESTION_QUERY);
  const tableSelection = useTableRowSelection({ virtualSupport: false, type: 'radio' });

  const [suggestions, setSuggestions] = useState<SiteFragmentForDisplayFragment[]>([]);

  const { data } = useQuery<ActivitySiteAffiliationsQuery, ActivitySiteAffiliationsQueryVariables>(DATA_QUERY, {
    variables: { id: activityId ?? -1 },
    skip: !moduleEnabled,
  });

  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (tableSelection.selection.selected.length > 0) {
      dispatch({ type: 'updateLocations', values: tableSelection.selection.selected });
    }
  }, [tableSelection]);

  useEffect(() => {
    if (data?.activity) dispatch({
      type: 'updateLocations',
      values: data.activity.sites.nodes.map(s => s.id)
    });
  }, [data]);


  const [enableCriteriaSearch, setEnableCriteriaSearch] = useState(false);

  const checkHcp = () => {
    if (personIds && personIds.length > 0) {
      loadSiteSuggestions({
        variables: { personIds },
      });
    }
  };

  // Init effect
  useEffect(() => {
    checkHcp();
  }, []);
  useEffect(() => {
    checkHcp();
  }, [personIds]);

  useEffect(() => {
    if (activityId) loadSiteSuggestions({
      variables: { activityId },
    });
  }, [activityId]);

  useEffect(() => {
    if (suggestionData?.sites.nodes) setSuggestions(suggestionData.sites.nodes);
  }, [suggestionData]);

  useEffect(() => {
    if (locations) {
      loadSiteSuggestions({ variables: { siteIds: locations as number[] } });
    }
  }, [locations]);

  if (!moduleEnabled) {
    return <></>;
  }

  function onChange(value?: number | 'criteria-search') {
    if (value === 'criteria-search') {
      return setEnableCriteriaSearch(true);
    }

    if (activityId && value) {
      UpdateActivityLocation({
        variables: {
          activityId,
          locationId: value,
        },
        refetchQueries: ['ActivitySiteAffiliations'],
      }).then(() => messageApi.success(localization.formatMessage(Locale.Text.Location_has_been_updated)));
    } else if (value) dispatch({
      type: 'updateLocations',
      values: [value],
    });
  }

  function addFromCriteriaSearch() {
    if (activityId) {
      UpdateActivityLocation({
        variables: {
          activityId,
          locationId: Number(locations?.[0]),
        },
        refetchQueries: ['ActivitySiteAffiliations'],
      }).then(() => messageApi.success(localization.formatMessage(Locale.Text.Location_has_been_updated)));
    }
    setEnableCriteriaSearch(false);
  }


  return (
    <HeaderWithValue
      heading={localization.formatMessage(Locale.Attribute.Location)}
      className="activity-location-container"
      errors={locationsErrors}
    >
      {contextHolder}
      <Modal
        open={enableCriteriaSearch}
        onCancel={() => setEnableCriteriaSearch(false)}
        width="80%"
        // eslint-disable-next-line react/jsx-no-bind
        onOk={addFromCriteriaSearch}
        wrapClassName="activity-location-modal-container"
      >
        <EntitiesSearch
          entityType={EntityTypeEnum.SITE}
          tableSelection={tableSelection}
        />
      </Modal>
      <Select
        showSearch
        size='large'
        style={{ width: '100%' }}
        placeholder={localization.formatMessage(Locale.Command.Select_location)}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={onChange}
        loading={suggestionsLoading}
        optionFilterProp="children"
        filterOption={(input, option) => {
          // @ts-ignore
          if (typeof option?.children?.props?.['data-text'] === 'string') {
            // @ts-ignore
            return option?.children?.props?.['data-text'].toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }
          return true;
        }}
        // @ts-ignore
        value={locations ?? []}
      >
        {suggestions.map(site => (
          <Select.Option key={site.id} value={site.id}>
            <Space data-text={site.name}>
              <Icon iconType="SITE" />
              {site.name}
            </Space>
          </Select.Option>
        ))}
        <Select.Option value="criteria-search" className="open-search-modal">
          <Space>
            <Icon iconType="PLUS" />
            {localization.formatMessage(Locale.Command.Search)}
          </Space>
        </Select.Option>
      </Select>
    </HeaderWithValue>
  );
};

const ActivitySiteAffiliationSiteFragment = gql`
  fragment ActivitySiteSiteFragment on Site {
    id
    name
    type {
      code
      type
      label
    }
  }
`;

const SiteFragment = gql`
  fragment SiteFragmentForDisplay on Site {
    id
    name
  }
`;

const DATA_QUERY = gql`
  query ActivitySiteAffiliations($id: Int!) {
    activity(id: $id) {
      id
      sites{
        hash
        nodes{
          ...ActivitySiteSiteFragment
        }
      }
      siteAffiliations{
        ...SiteFragmentForDisplay
      }
    }
  }
  ${ActivitySiteAffiliationSiteFragment}
  ${SiteFragment}
`;

const SUGGESTION_QUERY = gql`
  query ActivitySitesSuggestions($personIds: [ID!], $activityId: ID, $siteIds: [ID!]) {
    sites(criteria: {
      personIds: $personIds,
      activityId: $activityId,
      ids: $siteIds
    }) {
      hash
      nodes {
        ...SiteFragmentForDisplay
      }
    }
  }
  ${SiteFragment}
`;

const UPDATE_ACTIVITY_LOCATION = gql`
  mutation UpdateActivityLocation($activityId: Int!, $locationId: Int!) {
    updateActivityLocation(input: { activityId: $activityId, locationId: $locationId }) {
      id
    }
  }
`;

export default ActivityLocationContainer;

