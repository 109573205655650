import React, { useState } from 'react';
import { message, Modal } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { EntitiesSearchQuickActionItem } from '../../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import { StateArray } from '../../../util/StateArrayType';
import { usePermission } from '../../../permission/usePermission';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  InactivatePersonActionQuery,
  InactivatePersonActionQueryVariables,
  InactivatePersonLinkMutation,
  InactivatePersonLinkMutationVariables,
  SetActiveStatusPersonMutation,
  SetActiveStatusPersonMutationVariables,
  SourceEnum,
} from '../../../../gql/typings';
import { useLocalization } from '../../../util/useLocalization';
import { useDetailsContext } from '../../../components/DetailsView/useDetailsContext';

type ReturnProps = {
  hidden: React.ReactNode;
  action: EntitiesSearchQuickActionItem;
  showModalState: StateArray<boolean>;
  loading: boolean;
};

export const useInactivatePersonAction = (personId: number): ReturnProps => {
  const localization = useLocalization();
  const { delete: deletePermission } = usePermission('Person');
  const showModalState = useState(false);
  const { data, loading, refetch } = useQuery<InactivatePersonActionQuery, InactivatePersonActionQueryVariables>(DATA_QUERY, {
    variables: { personId },
  });
  const [
    setActivateMutation,
    { loading: isInactivating },
  ] = useMutation<SetActiveStatusPersonMutation, SetActiveStatusPersonMutationVariables>(SET_ACTIVE_MUTATION);
  const [
    inactivateLink,
    { loading: isInactivatingLink },
  ] = useMutation<InactivatePersonLinkMutation, InactivatePersonLinkMutationVariables>(INACTIVATE_LINK_MUTATION);
  const { broadcastShouldRefetchData } = useDetailsContext(refetch);

  const inactivateLinks = () => Promise.all(data?.person?.affiliations?.nodes?.map(link => inactivateLink({
    variables: { linkId: link.id },
  })) ?? [Promise.resolve()]).then(() => broadcastShouldRefetchData());

  const inactivate = () => setActivateMutation({ variables: { personId, isActive: false } })
    .then(() => {
      message.success(localization.formatMessage(Locale.Text.Successfully_inactivated_record));
      showModalState[1](false);
      broadcastShouldRefetchData();
    });

  const activate = () => setActivateMutation({ variables: { personId, isActive: true } })
    .then(() => {
      message.success(localization.formatMessage(Locale.Text.Successfully_activated_record));
      broadcastShouldRefetchData();
    });

  const isActive = data?.person?.isActive ?? false;

  return {
    showModalState,
    loading: loading || isInactivating || isInactivatingLink,
    action: {
      key: 'delete-record',
      hide: data?.person?.maintainerSourceCode !== SourceEnum.LOCAL || !deletePermission,
      disabled: !isActive && (data?.person?.mergeRequests?.totalCount ?? 0) > 0,
      label: isActive
        ? localization.formatMessage(Locale.Command.Inactivate_record)
        : localization.formatMessage(Locale.Command.Activate_record),
      onClick: () => !isActive
        ? activate()
        : data?.person?.affiliations.totalCount === 0
          ? inactivate()
          : showModalState[1](true),
    },
    hidden: (
      <Modal
        open={showModalState[0]}
        onCancel={() => showModalState[1](false)}
        okText={localization.formatMessage(Locale.General.Yes)}
        cancelText={localization.formatMessage(Locale.Command.Cancel)}
        okButtonProps={{ loading: loading || isInactivating || isInactivatingLink }}
        onOk={() => inactivateLinks().then(() => inactivate())}
      >
        {localization.pluralMessage(
          Locale.Text.Inactivate_with_links_description,
          data?.person?.affiliations.totalCount ?? 0,
        )}
      </Modal>
    ),
  };
};

const SET_ACTIVE_MUTATION = gql`
  mutation SetActiveStatusPerson($personId: Int!, $isActive: Boolean!) {
    setPersonActiveStatus(persId: $personId, isActive: $isActive) {
      id
      isActive
    }
  }
`;

const INACTIVATE_LINK_MUTATION = gql`
  mutation InactivatePersonLink($linkId: Int!) {
    setSitePersonActiveStatus(id: $linkId, isActive: false) {
      id
      isActive
      person {
        id
        affiliations {
          hash
          totalCount
          nodes { id }
        }
      }
    }
  }
`;

const DATA_QUERY = gql`
  query InactivatePersonAction($personId: Int!) {
    person(id: $personId) {
      id
      isActive
      maintainerSourceCode
      mergeRequests {
        hash
        totalCount
      }
      affiliations {
        hash
        totalCount
        nodes {
          id
        }
      }
    }
  }
`;
