import { Locale } from '../../../../localization/LocalizationKeys';
import { siteSelectionRowFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SiteSelectionRowCriteriaOrder } from '../../../../gql/typings';

export const siteSelectionRowSelectionGroupDescriptionFieldConfig = siteSelectionRowFieldConfig(
  'selectionGroupDescription',
  [
    'selectionRow.id',
    'selectionRow.group.id',
    'selectionRow.group.description',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Group_description),
    sorting: {
      ascend: SiteSelectionRowCriteriaOrder.SELECTION_GROUP_DESCRIPTION_ASC,
      descend: SiteSelectionRowCriteriaOrder.SELECTION_GROUP_DESCRIPTION_ASC,
    },
    render: record => record.selectionRow?.group.description ?? localization.formatMessage(Locale.General.Unknown),
  }),
);
