import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import DateDisplay from '../../../components/Badge/DateDisplay';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SitePersonCriteriaOrder, SourceEnum } from '../../../../gql/typings';

export const linkUpdatedFieldConfig = linkFieldConfig(
  'updated',
  ['ut', 'imfUt', 'maintainerSourceCode'],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Updated),
    additionalTableConfig: {
      width: 125
    },
    sorting: {
      ascend: [SitePersonCriteriaOrder.UT_ASC],
      descend: [SitePersonCriteriaOrder.UT_DESC]
    },
    render: (record) => {
      const isLocalDB = record.maintainerSourceCode === SourceEnum.LOCAL;
      return <DateDisplay>{isLocalDB ? record.ut: record.imfUt}</DateDisplay>;
    },
  })
);
