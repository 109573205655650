import React from 'react';
import { gql } from '@apollo/client';
import { Card, Col, Row } from 'antd';
import { DetailedGridLayoutItemProps } from '../DetailedGridLayout';
import {
  useAdminDetailedGridCardsPreviewQueryQuery
} from '../../../../../gql/typings';
import { FIELD_KEY_FRAGMENT } from '../../../../util/useFieldKeysTransformed';

const AdminDetailedGridCardsPreview: React.FC<DetailedGridLayoutItemProps> = ({
  entityGridItemConfigurationId,
}) => {
  const { data } = useAdminDetailedGridCardsPreviewQueryQuery({ variables: { entityGridItemConfigurationId } });

  return (
    <Row>
      <Col span={8}>
        <Card title="Person">
          Card Fields: {data?.cardFieldKeys.person.fields.length}
          <br />
          Drawer Fields: {data?.cardFieldKeys.person.drawerFields.length}
        </Card>
      </Col>

      <Col span={8}>
        <Card title="Link">
          Card Fields: {data?.cardFieldKeys.link.fields.length}
          <br />
          Drawer Fields: {data?.cardFieldKeys.link.drawerFields.length}
        </Card>
      </Col>

      <Col span={8}>
        <Card title="Site">
          Card Fields: {data?.cardFieldKeys.site.fields.length}
          <br />
          Drawer Fields: {data?.cardFieldKeys.site.drawerFields.length}
        </Card>
      </Col>

    </Row>
  );
};

gql`
  query AdminDetailedGridCardsPreviewQuery($entityGridItemConfigurationId: Int!) {
    cardFieldKeys(entityGridItemConfigurationId: $entityGridItemConfigurationId) {
      entityGridItemConfigurationId
      person {
        entityGridItemConfigurationId
        entityType
        drawerFields { ...GridCardField }
        fields { ...GridCardField }
      }
      site {
        entityGridItemConfigurationId
        entityType
        drawerFields { ...GridCardField }
        fields { ...GridCardField }
      }
      link {
        entityGridItemConfigurationId
        entityType
        drawerFields { ...GridCardField }
        fields { ...GridCardField }
      }
    }
  }
  ${FIELD_KEY_FRAGMENT}
`;

export default AdminDetailedGridCardsPreview;
