import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { LinkColumn } from '../../../components/Table/utils';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SitePersonCriteriaOrder } from '../../../../gql/typings';

export const linkSiteNameFieldConfig = linkFieldConfig(
  'siteName',
  [
    'site.id',
    'site.name',
  ],
  ({ localization, openInNewTab }) => ({
    title: localization.formatMessage(Locale.Attribute.Site_name),
    additionalTableConfig: {
      width: 150
    },
    sorting: {
      ascend: [SitePersonCriteriaOrder.SITE_NAME_ASC],
      descend: [SitePersonCriteriaOrder.SITE_NAME_DESC],
    },
    render: record => (
      <LinkColumn
        text={record.site.name}
        url={`/hco/${record.site.id}`}
        openInNewTab={openInNewTab}
      />
    ),
  })
);
