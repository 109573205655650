import React, { useEffect, useState } from 'react';
import { Form, Select, Spin, Tag } from 'antd';
import { isNil } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BaseSelectProps } from 'rc-select/lib/BaseSelect';
import { CriteriaInputProps } from '../CriteriaInputTools';
import { criteriaInputKeyGenerator } from '../CriteriaInputFactory';
import Icon from '../../Icon/Icon';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import { PreferredLOVLanguage, usePreferredLOVLanguage } from '../../../util/usePreferredLOVLanguage';
import { CriteriaSuggestionRecord, useSuggestionDropdownValues } from '../useSuggestionDropdownValues';

const CriteriaMultiGroupSelectInput: React.FC<CriteriaInputProps> = React.memo(({
  form,
  criteria,
  inputPath,
  disabled,
  initialValue,
  countryCodes,
  disableMaxCount,
  initialValueOptionsLoad
}) => {
  const localization = useLocalization();
  const preferredLanguage = usePreferredLOVLanguage();
  const key = criteriaInputKeyGenerator(criteria, inputPath);
  const selectedValues = Form.useWatch(key, form);
  const groups = inputPath.service!.split('|');
  if (groups.length !== 2) throw Error('Only single level of groupings are supported at the moment!');

  const [search, setSearch] = useState('');


  const groupDropdown = useSuggestionDropdownValues({
    service: groups[0]!,
    criteriaPathId: inputPath.id,
    countryCodes,
    criteria,
    selectedKeys: selectedValues,
    search,
  });
  const dataDropdown = useSuggestionDropdownValues({
    service: groups[1]!,
    criteriaPathId: inputPath.id,
    countryCodes,
    criteria,
    selectedKeys: selectedValues,
    search,
  });

  useEffect(() => {
    if (dataDropdown.records.length === 0 && groupDropdown.records.length === 0 && initialValueOptionsLoad) {
      groupDropdown.triggerLoading();
      dataDropdown.triggerLoading();
    }
    if (!isNil(initialValue)) form.setFieldsValue({ [key]: initialValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, key, initialValue]);


  const loading = groupDropdown.loading || dataDropdown.loading;

  const groupings: Record<string, CriteriaSuggestionRecord & { options: CriteriaSuggestionRecord[] }> = groupDropdown
    .records
    .reduce((acc, curr) => ({
      ...acc,
      [curr.key]: {
        ...curr,
        options: [],
      },
    }), {});
  dataDropdown.records.map(item => {
    groupings[item.parentKey!]?.options.push(item);
  });


  const options = groupDropdown.records.map(group => ({
    key: group.key,
    group: true,
    label: preferredLanguage === PreferredLOVLanguage.ENGLISH && !group.isValueHeadingKey && group.valueGb
      ? group.valueGb
      : (group.isValueHeadingKey
        ? localization.formatMessageByStr(group.value)
        : group.value),
    options: groupings[group.key]?.options?.filter(d => !d.isGlobalOnly).map(option => {
      const raw = preferredLanguage === PreferredLOVLanguage.ENGLISH && !option.isValueHeadingKey && option.valueGb
        ? option.valueGb
        : (option.isValueHeadingKey
          ? localization.formatMessageByStr(option.value)
          : option.value);
      return {
        key: `${group.key}-${option.key}`,
        value: option.key,
        // count: option.count,
        raw,
        label: (
          <span
            className="option-value"
            data-value-is-heading-key={option.isValueHeadingKey}
            data-localization-key={preferredLanguage === PreferredLOVLanguage.ENGLISH
              ? option.valueGb || option.value
              : option.value}
          >
            <span>{raw}</span>
            {/* <span className="option-badge">{option.count}</span> */}
          </span>
        ),
      };
    })
  }));


  const tagRender: BaseSelectProps['tagRender'] = props => {
    if (!React.isValidElement(props.label)) return <Tag closable={!disabled && props.closable} onClose={props.onClose}>
      {props.label}
    </Tag>;
    return (
      <Tag closable={!disabled && props.closable} onClose={props.onClose}>
        {props.label.props['data-value-is-heading-key']
          ? localization.formatMessageByStr(props.label.props['data-localization-key'])
          : props.label.props['data-localization-key']}
      </Tag>
    );
  };


  return (
    <Form.Item name={key}>
      <Select
        id={key}
        showSearch
        onSearch={setSearch}
        mode="multiple"
        loading={loading}
        placeholder={inputPath.placeholderHeading && !disabled
          ? localization.formatMessageByStr(inputPath.placeholderHeading)
          : undefined}
        size="large"
        disabled={disabled}
        notFoundContent={loading ? <Spin size="small" /> : <span>{localization.formatMessage(Locale.Text.Nothing_found)}</span>}
        popupClassName="criteria-multi-select-input-container-dropdown-container"
        style={{ width: '100%' }}
        maxTagCount={disableMaxCount ? undefined : 'responsive'}
        dropdownMatchSelectWidth={false}
        onDropdownVisibleChange={visible => {
          groupDropdown.triggerLoading();
          dataDropdown.triggerLoading();
        }}
        optionLabelProp="raw"
        menuItemSelectedIcon={({ isSelected }) => !isSelected || criteria.inputPaths.nodes.length === 1
          ? <></>
          : <Icon iconType="CHECK" />}
        tagRender={tagRender}
        filterOption={false}
        options={options}
        onBlur={() => setSearch('')}
      />
    </Form.Item>
  );
});


export default CriteriaMultiGroupSelectInput;
