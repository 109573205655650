import { useQuery, gql } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { Input, Skeleton, Empty, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { groupBy, keys } from 'lodash';
import { useThrottle } from 'ahooks';
import Highlighter from 'react-highlight-words';
import EntitiesSearchContext from '../../EntitiesSearchContext';
import { criteriaInputKey } from '../../../CriteriaInput/CriteriaInputFactory';
import { AnyValueType } from '../../../../util/StateArrayType';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import { CriteriaInputFactory_FRAGMENT } from '../../../CriteriaInput/CriteriaInputQueries';
import { MySimpleSavedSearchesQuery, MySimpleSavedSearchesQueryVariables } from '../../../../../gql/typings';


const SimpleSavedSearches: React.FC = () => {
  const localization = useLocalization();
  const {
    entityType,
    setSelectedCriterias,
    form,
    setSelectedSaved,
    selectedSaved,
  } = useContext(EntitiesSearchContext);
  const [search, setSearch] = useState('');
  const searchValue = useThrottle(search, { wait: 500 });
  const { data, loading, refetch } = useQuery<MySimpleSavedSearchesQuery, MySimpleSavedSearchesQueryVariables>(DATA_QUERY, {
    variables: {
      entityType,
      search: `%${searchValue}%`,
    },
  });

  useEffect(() => {
    if (!loading) refetch();
  });

  const onClick = (savedCriteria: NonNullable<MySimpleSavedSearchesQuery['viewer']>['savedCriterias']['nodes']['0']) => {
    const dataMap: Record<string, AnyValueType> = {};
    savedCriteria.criteriaAffiliations.nodes.map(affiliation => {
      const groups = groupBy(affiliation.paths.nodes, p => p.pathId);
      keys(groups).map(pathId => {
        const values = groups[pathId]!;
        dataMap[`${criteriaInputKey}${affiliation.criteria.id}-${pathId}`] = values.length > 1
          ? values.map(v => v.value)
          : values[0]!.value;
      });
    });
    setSelectedSaved(savedCriteria);
    setSelectedCriterias(savedCriteria.criteriaAffiliations.nodes.map(affiliation => affiliation.criteria));
    form.setFieldsValue(dataMap);
  };

  const results = data?.viewer?.savedCriterias.nodes ?? [];

  return (
    <Space size="middle" direction="vertical" className="simple-saved-searches-container">
      <Input.Search
        className="search"
        allowClear
        loading={loading}
        onChange={e => setSearch(e.target.value)}
        placeholder={localization.formatMessage(Locale.Text.Search_for_saved_searched)}
      />
      {results.map(savedCriteria => (
        <Space
          key={savedCriteria.id}
          // @ts-ignore
          onClick={() => onClick(savedCriteria)}
          className={savedCriteria.id === (selectedSaved && selectedSaved.id) ? 'row selected' : 'row'}
        >
          <Space className="left">
            <SearchOutlined />
            <Highlighter searchWords={[searchValue]} textToHighlight={savedCriteria.heading} />
          </Space>
          <Space className="right">
            {localization.pluralMessage(Locale.Text.Saved_results, savedCriteria.totalCount) }
          </Space>
        </Space>
      ))}
      {results.length === 0 && (loading
        ? <Skeleton loading active title={false} paragraph={{ rows: 4, width: '100%' }} />
        : <Empty />
      )}
    </Space>
  );
};

export const SimpleSavedSearches_FRAGMENT = gql`
  fragment SimpleSavedSearchFragment on CriteriaSimpleSaved {
    id
    heading
    totalCount # TODO: This can be heavy, so needs to be cached on the backend! Maybe just up to 1minute cache.
    criteriaAffiliations {
      hash
      nodes {
        id
        criteria { ...CriteriaInputFragment }
        paths {
          hash
          nodes {
            id
            pathId
            value
          }
        }
      }
    }
  }
  ${CriteriaInputFactory_FRAGMENT}
`;

const DATA_QUERY = gql`
  query MySimpleSavedSearches($entityType: EntityTypeEnum!, $search: String) {
    viewer {
      id
      savedCriterias(criteria: { entityType: $entityType, heading: $search }) {
        hash
        nodes { ...SimpleSavedSearchFragment }
      }
    }
  }
  ${SimpleSavedSearches_FRAGMENT}
`;

export default SimpleSavedSearches;
