import React, { useState } from 'react';
import { Button, message, Modal, Select, Tag, Typography } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { PlusOutlined } from '@ant-design/icons';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { FlagCountry } from '../../../../components/Flag/Flag';
import { StateArray } from '../../../../util/StateArrayType';
import CountryPicker from '../../../../components/Input/Fields/CountryPicker';
import {
  ActivityTypesForInReportsQueryQuery, ActivityTypesForInReportsQueryQueryVariables,
  AddActivityTypeToReportMutation,
  AddActivityTypeToReportMutationVariables, RemoveActivityTypeToReportMutation, RemoveActivityTypeToReportMutationVariables
} from '../../../../../gql/typings';

type ActivityTypesInReports = {
  reportCode: string;
  selectedCountryState: StateArray<FlagCountry|undefined>;
};

type ActivityType = NonNullable<
ActivityTypesForInReportsQueryQuery['reportSettingsActivityTypes']
>['nodes']['0']['activityType'];

const ActivityTypesInReports: React.FC<ActivityTypesInReports> = ({
  selectedCountryState: [selectedCountry, setSelectedCountry],
  reportCode,
}) => {
  const localization = useLocalization();
  const [isAdding, setIsAdding] = useState(false);
  const [selectedTypeCode, setSelectedTypeCode] = useState<string>();
  const [
    add, { loading: isAddingLoading },
  ] = useMutation<AddActivityTypeToReportMutation, AddActivityTypeToReportMutationVariables>(ADD_MUTATION);
  const [
    remove,
  ] = useMutation<RemoveActivityTypeToReportMutation, RemoveActivityTypeToReportMutationVariables>(REMOVE_MUTATION);
  const {
    data,
    refetch,
    loading,
  } = useQuery<ActivityTypesForInReportsQueryQuery, ActivityTypesForInReportsQueryQueryVariables>(DATA_QUERY, {
    variables: { reportCode, country: selectedCountry }
  });
  const [messageApi, contextHolder] = message.useMessage();

  const onAdd = () => {
    setIsAdding(false);
    if (selectedTypeCode) return add({
      variables: {
        reportCode,
        country: selectedCountry,
        activityTypeCode: selectedTypeCode,
      }
    }).then(() => {
      setSelectedTypeCode(undefined);
      return refetch();
    });
  };

  const onModalClose = () => {
    setIsAdding(false);
    setSelectedTypeCode(undefined);
    return refetch();
  };


  const onRemove = (type: ActivityType) => (e: React.MouseEvent<HTMLElement>) => remove({
    variables: {
      reportCode,
      activityTypeCode: type.code,
      country: selectedCountry,
    },
  }).then(() => {
    const undoSave = () => {
      // @ts-ignore
      close();
      add({
        variables: {
          reportCode,
          activityTypeCode: type.code,
          country: selectedCountry,
        },
      }).then(() => refetch());
    };
    const close = messageApi.success((
      <span>
        Successfully removed activity type <b>{type.heading}</b> from report.
        <Button type="link" onClick={undoSave}>Undo</Button>
      </span>
    ), 5);
    return refetch();
  });

  return (
    <div>
      {contextHolder}
      <Typography.Paragraph>
        {localization.formatText(Locale.Text.Activity_types_in_reports_description)}
      </Typography.Paragraph>
      <CountryPicker
        showFlag
        value={selectedCountry}
        onChange={e => setSelectedCountry(e as FlagCountry)}
      />
      <br /><br />
      {selectedCountry && (data?.reportSettingsActivityTypes.nodes ?? []).map(type => (
        <Tag
          key={type.id}
          color={type.activityType.color.value}
          closable
          onClose={onRemove(type.activityType)}
        >
          {type.activityType.heading}
        </Tag>
      ))}
      {selectedCountry && <Tag onClick={() => setIsAdding(true)} style={{ cursor: 'pointer' }}>
        <PlusOutlined /> Add Type
      </Tag>}
      <Modal
        title={localization.formatMessage(Locale.Command.Select_type)}
        open={isAdding}
        onCancel={onModalClose}
        onOk={onAdd}
        okButtonProps={{ disabled: !selectedTypeCode, loading: loading || isAddingLoading }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Select
            loading={loading}
            dropdownMatchSelectWidth
            value={selectedTypeCode}
            onChange={setSelectedTypeCode}
            style={{ minWidth: 240 }}
          >
            {(data?.activitySuperTypes.nodes ?? []).filter(g => g.types.nodes.length > 0).map(group => (
              <Select.OptGroup key={group.code} label={group.heading}>
                {group.types.nodes.map(type => (
                  <Select.Option key={type.code}>
                    {type.heading}
                  </Select.Option>
                ))}
              </Select.OptGroup>
            ))}
          </Select>
        </div>
      </Modal>
    </div>
  );
};

const ADD_MUTATION = gql`
  mutation AddActivityTypeToReport($reportCode: String!, $country: CountryCode!, $activityTypeCode: String!) {
    addActivityTypeToReport(country: $country, reportCode: $reportCode, activityTypeCode: $activityTypeCode) {
      id
      includeInReport
    }
  }
`;

const REMOVE_MUTATION = gql`
  mutation RemoveActivityTypeToReport($reportCode: String!, $country: CountryCode!, $activityTypeCode: String!) {
    removeActivityTypeFromReport(country: $country, reportCode: $reportCode, activityTypeCode: $activityTypeCode) {
      id
      includeInReport
    }
  }
`;

const DATA_QUERY = gql`
  query ActivityTypesForInReportsQuery($reportCode: String!, $country: CountryCode!) {
    activitySuperTypes {
      hash
      nodes {
        code
        heading
        types(criteria: { excludeSelectedInReportSettingByReportCode: $reportCode }) {
          hash
          nodes {
            code
            heading
            
            colorCode
          }
        }
      }
    }
    reportSettingsActivityTypes(criteria: { country: $country, reportCode: $reportCode }) {
      hash
      nodes {
        id
        activityType {
          code
          heading
          colorCode
          color{
            code
            value
          }
        }
      }
    }
  }
`;

export default ActivityTypesInReports;
