/* eslint-disable arrow-body-style */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { gql } from '@apollo/client';
import { LocalizationShape, useLocalization } from '../../../util/useLocalization';
import { generateMessageObject } from '../../../util/generateMessageObject';
import { Locale } from '../../../../localization/LocalizationKeys';
import SingleLineSkeleton from '../../../components/Skeleton/SingleLineSkeleton';
import {
  DcrGroupHeadingFragmentFragment,
  useDcrGroupHeadingQueryQuery
} from '../../../../gql/typings';


type DcrGroupHeadingProps = {
  groupId: number;
  withLinks?: true;
};


type ConfigType = {
  [K in NonNullable<DcrGroupHeadingFragmentFragment['heading']>['__typename']]: (
    localization: LocalizationShape,
    heading: Extract<NonNullable<DcrGroupHeadingFragmentFragment['heading']>, { ['__typename']: K }>,
    withLinks: undefined|true,
    groupId: number,
  ) => React.ReactNode;
};

const config: ConfigType = {
  DcrGroupHeadingCreatePerson: (localization, heading, withLinks) => {
    return localization.formatMessage(generateMessageObject(heading.headingKey), {
      personName: withLinks
        ? <NavLink to={`/hcp/${heading.rPerson.id}`}>{heading.rPerson.fullName}</NavLink>
        : heading.rPerson.fullName,
    });
  },
  DcrGroupHeadingDeletePerson: (localization, heading, withLinks) => {
    return localization.formatMessage(generateMessageObject(heading.headingKey), {
      personName: withLinks
        ? <NavLink to={`/hcp/${heading.rPerson.id}`}>{heading.rPerson.fullName}</NavLink>
        : heading.rPerson.fullName,
    });
  },
  DcrGroupHeadingUpdatePerson: (localization, heading, withLinks) => {
    return localization.formatMessage(generateMessageObject(heading.headingKey), {
      personName: withLinks
        ? <NavLink to={`/hcp/${heading.rPerson.id}`}>{heading.rPerson.fullName}</NavLink>
        : heading.rPerson.fullName,
    });
  },
  DcrGroupHeadingCreateSite: (localization, heading, withLinks) => {
    return localization.formatMessage(generateMessageObject(heading.headingKey), {
      siteName: withLinks
        ? <NavLink to={`/hco/${heading.rSite.id}`}>{heading.rSite.name}</NavLink>
        : heading.rSite.name,
    });
  },
  DcrGroupHeadingDeleteSite: (localization, heading, withLinks) => {
    return localization.formatMessage(generateMessageObject(heading.headingKey), {
      siteName: withLinks
        ? <NavLink to={`/hco/${heading.rSite.id}`}>{heading.rSite.name}</NavLink>
        : heading.rSite.name,
    });
  },
  DcrGroupHeadingUpdateSite: (localization, heading, withLinks) => {
    return localization.formatMessage(generateMessageObject(heading.headingKey), {
      siteName: withLinks
        ? <NavLink to={`/hco/${heading.rSite.id}`}>{heading.rSite.name}</NavLink>
        : heading.rSite.name,
    });
  },
  DcrGroupHeadingDeleteWorkplace: (localization, heading, withLinks) => {
    return localization.formatMessage(generateMessageObject(heading.headingKey), {
      siteName: withLinks
        ? <NavLink to={`/hco/${heading.oSite?.id}`}>{heading.oSite?.name}</NavLink>
        : heading.oSite?.name,
      personName: withLinks
        ? <NavLink to={`/hcp/${heading.oPerson?.id}`}>{heading.oPerson?.fullName}</NavLink>
        : heading.oPerson?.fullName,
    });
  },
  DcrGroupHeadingNewPersonWithWorkplace: (localization, heading, withLinks) => {
    return localization.formatMessage(generateMessageObject(heading.headingKey), {
      siteName: withLinks
        ? <NavLink to={`/hco/${heading.oSite?.id}`}>{heading.oSite?.name}</NavLink>
        : heading.oSite?.name,
      personName: withLinks
        ? <NavLink to={`/hcp/${heading.rPerson.id}`}>{heading.rPerson.fullName}</NavLink>
        : heading.rPerson.fullName,
    });
  },
  DcrGroupHeadingNewWorkplace: (localization, heading, withLinks) => {
    return localization.formatMessage(generateMessageObject(heading.headingKey), {
      siteName: withLinks
        ? <NavLink to={`/hco/${heading.oSite?.id}`}>{heading.oSite?.name}</NavLink>
        : heading.oSite?.name,
      personName: withLinks
        ? <NavLink to={`/hcp/${heading.rPerson.id}`}>{heading.rPerson.fullName}</NavLink>
        : heading.rPerson.fullName,
    });
  },
  DcrGroupHeadingUpdateWorkplace: (localization, heading, withLinks) => {
    return localization.formatMessage(generateMessageObject(heading.headingKey), {
      siteName: withLinks
        ? <NavLink to={`/hco/${heading.oSite?.id}`}>{heading.oSite?.name}</NavLink>
        : heading.oSite?.name,
      personName: withLinks
        ? <NavLink to={`/hcp/${heading.oPerson?.id}`}>{heading.oPerson?.fullName}</NavLink>
        : heading.oPerson?.fullName,
    });
  },
  DcrGroupHeadingUnknownDcrType: (localization, heading, withLinks, dcrGroupId) => {
    return localization.formatMessage(generateMessageObject(heading.headingKey), {
      dcrGroupId
    });
  },
};


const DcrGroupHeading: React.FC<DcrGroupHeadingProps> = ({ groupId, withLinks }) => {
  const localization = useLocalization();

  const { data, loading } = useDcrGroupHeadingQueryQuery({
    variables: { groupId },
  });

  return (
    <SingleLineSkeleton loading={loading} blink={loading}>
      {data?.dcrGroup?.heading
        ? config[data?.dcrGroup?.heading.__typename](
          localization,
          // @ts-ignore
          data.dcrGroup.heading,
          withLinks,
          groupId,
        ) : localization.formatMessage(Locale.Text.Dcr_calculated_heading_unknown_type, { dcrGroupId: groupId })}
    </SingleLineSkeleton>
  );
};

const DcrGroupHeading_FRAGMENT = gql`
  fragment DcrGroupHeadingFragment on DcrGroup {
    heading {
      ... on DcrGroupHeadingNewWorkplace {
        code
        headingKey
        rPerson: person { id, fullName }
        oSite: site { id, name }
      }
      ... on DcrGroupHeadingNewPersonWithWorkplace {
        code
        headingKey
        rPerson: person { id, fullName }
        oSite: site { id, name }
      }
      ... on DcrGroupHeadingUpdateWorkplace {
        code
        headingKey
        oPerson: person { id, fullName }
        oSite: site { id, name }
      }
      ... on DcrGroupHeadingDeleteWorkplace {
        code
        headingKey
        oPerson: person { id, fullName }
        oSite: site { id, name }
      }
      
      ... on DcrGroupHeadingCreatePerson {
        code
        headingKey
        rPerson: person { id, fullName }
      }
      ... on DcrGroupHeadingUpdatePerson {
        code
        headingKey
        rPerson: person { id, fullName }
      }
      ... on DcrGroupHeadingDeletePerson {
        code
        headingKey
        rPerson: person { id, fullName }
      }
      
      ... on DcrGroupHeadingCreateSite {
        code
        headingKey
        rSite: site { id, name }
      }
      ... on DcrGroupHeadingUpdateSite {
        code
        headingKey
        rSite: site { id, name }
      }
      ... on DcrGroupHeadingDeleteSite {
        code
        headingKey
        rSite: site { id, name }
      }
      
      ... on DcrGroupHeadingUnknownDcrType {
        code
        headingKey
      }
    }
  }
`;

gql`
  query DcrGroupHeadingQuery($groupId: Int!) {
    dcrGroup(id: $groupId) {
      id
      ...DcrGroupHeadingFragment
    }
  }
  ${DcrGroupHeading_FRAGMENT}
`;

export default DcrGroupHeading;
