import CriteriaTextInput from './types/CriteriaTextInput';
import CriteriaSwitchInput from './types/CriteriaSwitchInput';
import CriteriaSelectInput from './types/CriteriaSelectInput';
import CriteriaMultiSelectInput from './types/CriteriaMultiSelectInput';
import CriteriaMultiGroupSelectInput from './types/CriteriaMultiGroupSelectInput';
import CriteriaDateOptions from './types/CriteriaDateOptions';


export default {
  text: CriteriaTextInput,
  switch: CriteriaSwitchInput,
  'user-switch': CriteriaSwitchInput,
  select: CriteriaSelectInput,
  multiSelect: CriteriaMultiSelectInput,
  multiGroupSelect: CriteriaMultiGroupSelectInput,
  date: CriteriaDateOptions,
};
