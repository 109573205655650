import * as React from 'react';
import { MenuProps, Tooltip } from 'antd';
import { MenuDividerType } from 'antd/es/menu/hooks/useItems';


export type TableActionItem = {
  /**
   * a unique key for this specific menu item
   */
  key: string;

  /**
   * a callback on when this menu item is being pressed
   */
  onClick?: () => void;

  /**
   * Add a tooltip message upon this menu item
   */
  tooltip?: string|React.ReactNode;

  /**
   * Either a string to be displayed or a custom rendered react element
   */
  label: React.ReactNode;

  /**
   * Whether to be visible or not
   */
  hide?: boolean;

  /**
   * Show menu item as disabled
   */
  disabled?: boolean;

  /**
   * This is recursive, so it has support for the same properties as the root one
   */
  items?: Array<TableActionItem | null>;
};

export const actionToTakeMenuItems = (items: Array<TableActionItem | null>, source?: string): MenuProps['items'] => (items)
  .filter(item => !item || !item.hide).map((item) => {
    if (!item) {
      return { type: 'divider' } as MenuDividerType;
    }
    if (item.items) {
      return {
        key: item.key,
        label: item.tooltip ? (<Tooltip title={item.label}>{item.label}</Tooltip>) : item.label,
        onTitleClick: item.onClick,
        onClick: item.onClick,
        disabled: item.disabled,
        popupClassName: source ? `menu-element-item-${source}` : 'menu-element-item',
        children: actionToTakeMenuItems(item.items)
      };
    }
    return {
      key: item.key,
      label: item.tooltip ? (<Tooltip title={item.label}>{item.label}</Tooltip>) : item.label,
      disabled: item.disabled,
      onClick: item.onClick,
    };
  });
