import { gql, useMutation, useQuery } from '@apollo/client';
import { useDynamicList } from 'ahooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { message } from 'antd';
import { isFunction } from 'lodash';
import {
  entitiesTableConfigs,
  SupportedEntitySearchTypes,
  TableFieldReturnedRecordPageType
} from '../../browse/search_old/types';
import { usePermission } from '../../permission/usePermission';
import { useFieldOptionAttributes } from '../Card/useFieldOptionAttributes';
import { useLocalization } from '../../util/useLocalization';
import { useUser } from '../../util/useUser';
import { TABLE_COLUMNS_DATA_QUERY } from '../entitiesSearch/results/useTableColumns';
import { Locale } from '../../../localization/LocalizationKeys';
import { useTableColumnTreeDataFn } from './useTableColumnTreeDataFn';
import {
  AdminTableColumnSettingsQueryQuery,
  AdminTableColumnSettingsQueryQueryVariables,
  UpdateDefaultTableColumnsMutationMutation,
  UpdateDefaultTableColumnsMutationMutationVariables,
  UpdateUserTableColumnsMutationMutation,
  UpdateUserTableColumnsMutationMutationVariables,
  UserTableColumnsQueryQuery,
  UserTableColumnsQueryQueryVariables
} from '../../../gql/typings';

export enum TableColumnConfigureSettingType {
  PERSONAL,
  SYSTEM,
}

type ColumnKey = { id: string };

export const useTableColumnConfigureSettings = (
  entityType: SupportedEntitySearchTypes,
  type: TableColumnConfigureSettingType,
) => {
  const config = entitiesTableConfigs[entityType];
  const updatePermission = type === TableColumnConfigureSettingType.SYSTEM
    ? usePermission('DefaultTableColumn').update
    : true;
  const localization = useLocalization();
  const navigate = useNavigate();
  const { id: userId } = useUser();
  const [initialized, setInitialized] = useState(false);
  const dynamicList = useDynamicList<ColumnKey>([]);
  const { optionMapGet } = useFieldOptionAttributes(entityType);
  const [
    updateSystem,
  ] = useMutation<UpdateDefaultTableColumnsMutationMutation, UpdateDefaultTableColumnsMutationMutationVariables>(
    SYSTEM_UPDATE_MUTATION
  );
  const [
    updateUser,
  ] = useMutation<UpdateUserTableColumnsMutationMutation, UpdateUserTableColumnsMutationMutationVariables>(
    USER_UPDATE_MUTATION
  );
  const {
    data: systemData,
    loading: systemLoading,
  } = useQuery<AdminTableColumnSettingsQueryQuery, AdminTableColumnSettingsQueryQueryVariables>(SYSTEM_DATA_QUERY, {
    variables: { entityType },
    skip: type !== TableColumnConfigureSettingType.SYSTEM,
  });
  const {
    data: userData,
    loading: userLoading,
  } = useQuery<UserTableColumnsQueryQuery, UserTableColumnsQueryQueryVariables>(USER_DATA_QUERY, {
    variables: { userId, entityType },
    skip: type !== TableColumnConfigureSettingType.PERSONAL,
  });
  const { items } = useFieldOptionAttributes(entityType, Object.keys(config.columnConfig.fields));
  const loading = systemLoading || userLoading;

  const valueFromKey = (k: string) => {
    if (k == '-1') return '';
    const [key, keyCode] = k.split(':');
    const fn = config.columnConfig.fields[key as string];
    if (!fn) throw Error(`No field config found for '${key}' within entityType '${entityType}'`);
    const { title } = fn({ keyCode, localization, navigate });
    if (isFunction(title)) title({
      refetchData: () => {},
      isViewingFromEntity: entityType,
      isViewingFromPage: TableFieldReturnedRecordPageType.VIEW_PAGE,
    });
    return title;
  };

  const treeData = useTableColumnTreeDataFn({ items, optionMapGet, list: dynamicList.list });

  useEffect(() => {
    if (type == TableColumnConfigureSettingType.PERSONAL && userData?.userTableColumns && !initialized) {
      const userItems = userData.userTableColumns.nodes.map(n => ({ id: n.columnKey }));
      const defaultItems = userData.defaultTableColumns.nodes.map((n => ({ id: n.columnKey })));
      const items = userItems.length === 0 ? defaultItems : userItems;
      dynamicList.resetList(items);
      setInitialized(true);
    } else if (type == TableColumnConfigureSettingType.SYSTEM && systemData?.defaultTableColumns && !initialized) {
      const defaultItems = systemData.defaultTableColumns.nodes.map((n => ({ id: n.columnKey })));
      dynamicList.resetList(defaultItems);
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, systemData]);


  const set = (columnKeys: string[]) => {
    if (type === TableColumnConfigureSettingType.PERSONAL) return updateUser({
      refetchQueries: [
        {
          query: TABLE_COLUMNS_DATA_QUERY,
          variables: { entityType },
        },
        {
          query: USER_DATA_QUERY,
          variables: { userId, entityType },
        }
      ],
      variables: {
        entityType,
        columnKeys,
      }
    });
    if (type === TableColumnConfigureSettingType.SYSTEM) return updateSystem({
      refetchQueries: [
        {
          query: TABLE_COLUMNS_DATA_QUERY,
          variables: { entityType },
        },
        {
          query: SYSTEM_DATA_QUERY,
          variables: { entityType },
        }
      ],
      variables: {
        entityType,
        columnKeys,
      }
    });
    return Promise.reject();
  };

  const resetToDefaults = () => {
    const newList = type === TableColumnConfigureSettingType.PERSONAL
      ? userData?.defaultTableColumns.nodes.map(n => ({ id: n.columnKey }))
      : systemData?.defaultTableColumns.nodes.map(n => ({ id: n.columnKey }));
    dynamicList.resetList(newList ?? []);
    set([]).then(() => message.success(localization.formatMessage(Locale.Attribute.Updated)));
  };

  const updateList = (newList: ColumnKey[]) => {
    const updatedList = newList.map(l => l.id).filter(id => id !== '-1');
    if (newList.length === updatedList.length) {
      set(updatedList).then(() => message.success(localization.formatMessage(Locale.Attribute.Updated)));
    }
  };

  return {
    loading,
    dynamicList,
    resetToDefaults,
    updateList,
    treeData,
    valueFromKey,
    updatePermission,
    isNotUsingDefaults: type === TableColumnConfigureSettingType.PERSONAL
      ? (userData?.userTableColumns.nodes.length ?? 0) > 0
      : null,
  };
};

const SYSTEM_DATA_QUERY = gql`
  query AdminTableColumnSettingsQuery($entityType: EntityTypeEnum!) {
    defaultTableColumns(criteria: { entityType: $entityType }) {
      hash
      nodes {
        id
        sort
        columnKey
      }
    }
  }
`;

const USER_DATA_QUERY = gql`
  query UserTableColumnsQuery($userId: ID!, $entityType: EntityTypeEnum!) {
    userTableColumns(criteria: { userId: $userId, entityType: $entityType }) {
      hash
      nodes {
        id
        columnKey
        sort
      }
    }
    defaultTableColumns(criteria: { entityType: $entityType }) {
      hash
      nodes {
        id
        columnKey
        sort
      }
    }
  }
`;

const USER_UPDATE_MUTATION = gql`
  mutation UpdateUserTableColumnsMutation($entityType: EntityTypeEnum!, $columnKeys: [String!]!) {
    updateUserTableColumns(entityType: $entityType, columnKeys: $columnKeys) {
      id
    }
  }
`;
const SYSTEM_UPDATE_MUTATION = gql`
  mutation UpdateDefaultTableColumnsMutation($entityType: EntityTypeEnum!, $columnKeys: [String!]!) {
    updateDefaultTableColumns(entityType: $entityType, columnKeys: $columnKeys) {
      hash
      nodes {
        id
        sort
        columnKey
      }
    }
  }
`;
