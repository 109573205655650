import React from 'react';
import { Card, Grid, Row, Skeleton } from 'antd';
import { LineChartOutlined } from '@ant-design/icons';
import { ReportControllerType } from '../controller/reportController';


const ReportCard: React.FC<{ controller: ReportControllerType }> = ({
  controller,
}) => {
  const { md, lg, xl } = Grid.useBreakpoint();

  const loading = controller.reportsLoading;
  const reports = controller.reportsData?.reports.nodes ?? [];

  let width = '100%';
  if (md) width = '50%';
  if (lg) width = '33%';
  if (xl) width = '25%';

  return (
    <Row className="steps-content">
      <Skeleton loading={loading} active={loading}>
        {reports.map(report => (
          <Card.Grid
            key={report.code}
            className={
              `report-card ${controller.selectedReport && report.code === controller.selectedReport.code
                ? 'selected'
                : 'abc'}`
            }
            style={{ width }}
            // @ts-ignore
            onClick={() => {
              // MARK: This is on a new line, to make sure the ts-ignore is not ignoring this line.
              controller.setSelectedReport(report);
            }}
          >
            <LineChartOutlined style={{ fontSize: '100px' }} />
            <h3>{report.heading}</h3>
            <p>{report.description}</p>
          </Card.Grid>
        ))}
      </Skeleton>
    </Row>
  );
};


export default ReportCard;

