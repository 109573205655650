import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { SyncErrorType } from 'async-validator';
import { Optional } from '../../util/StateArrayType';
import AnimatedTextError from '../AnimatedTextError/AnimatedTextError';

const HeaderWithValue: React.FC<{
  heading: React.ReactNode;
  className?: string;
  errors?: Optional<SyncErrorType[]>;
}> = ({
  heading,
  children,
  className,
  errors,
}) => (
  <div className={`advance-input-container ${className || ''}`}>
    <div className="advance-input-header">{heading}</div>
    <div className="advance-input-label">{children}</div>
    <AnimatedTextError message={typeof errors?.[0] === 'string' ? errors[0] : errors?.[0]!.message} />
  </div>
);


export default HeaderWithValue;

