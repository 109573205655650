import { Select } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';

type MonthPickerProps = {
  onChange?: (newValue: Moment) => void;
  value?: Moment|Date;
};

const MonthPicker: React.FC<MonthPickerProps> = ({ onChange, value }) => {
  const localization = useLocalization();

  return (
    <Select
      value={value ? moment(value).month() : undefined}
      onChange={selectedMonth => onChange?.(moment().set('month', selectedMonth))}
      placeholder={localization.formatMessage(Locale.Command.Select_month)}
    >
      <Select.Option value={0}>
        {localization.formatMessage(Locale.General.January)}
      </Select.Option>
      <Select.Option value={1}>
        {localization.formatMessage(Locale.General.February)}
      </Select.Option>
      <Select.Option value={2}>
        {localization.formatMessage(Locale.General.March)}
      </Select.Option>
      <Select.Option value={3}>
        {localization.formatMessage(Locale.General.April)}
      </Select.Option>
      <Select.Option value={4}>
        {localization.formatMessage(Locale.General.May)}
      </Select.Option>
      <Select.Option value={5}>
        {localization.formatMessage(Locale.General.June)}
      </Select.Option>
      <Select.Option value={6}>
        {localization.formatMessage(Locale.General.July)}
      </Select.Option>
      <Select.Option value={7}>
        {localization.formatMessage(Locale.General.August)}
      </Select.Option>
      <Select.Option value={8}>
        {localization.formatMessage(Locale.General.September)}
      </Select.Option>
      <Select.Option value={9}>
        {localization.formatMessage(Locale.General.October)}
      </Select.Option>
      <Select.Option value={10}>
        {localization.formatMessage(Locale.General.November)}
      </Select.Option>
      <Select.Option value={11}>
        {localization.formatMessage(Locale.General.December)}
      </Select.Option>
    </Select>
  );
};

export default MonthPicker;
