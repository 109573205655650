import { Tooltip } from 'antd';
import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';

export const siteMailCityFieldConfig = siteFieldConfig(
  'mailCity',
  ['mailAddress.id', 'mailAddress.city'],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Mail_city),
    additionalTableConfig: {
      width: 130
    },
    render: (record) => {
      const address = record.mailAddress?.city;
      return <Tooltip placement='left' title={address}>{address}</Tooltip>;
    },
  })
);
