import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Form, Switch } from 'antd';
import { FormProps } from 'antd/lib/form/Form';
import { PermissionInfo } from '../../../../permission/permissionUtils';
import {
  PureAdvanceCustomerProfileQuery,
  PureAdvanceCustomerProfileQueryVariables,
  useUpdateCustomerModuleMutation
} from '../../../../../gql/typings';

type PureAdvanceCustomerProfileContainerProps = {
  customerCode: string;
  permission: PermissionInfo;
};

const formItemLayout: FormProps = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const PureAdvanceCustomerProfileContainer: React.FC<PureAdvanceCustomerProfileContainerProps> = ({
  permission,
  customerCode,
}) => {
  const { data } = useQuery<PureAdvanceCustomerProfileQuery, PureAdvanceCustomerProfileQueryVariables>(DATA_QUERY, {
    variables: { customerCode },
  });
  const [update, { loading }] = useUpdateCustomerModuleMutation();

  if (!permission.read) return <></>;

  return (
    <div>
      <Form {...formItemLayout} labelAlign="left" size="small">
        {(data?.pureAdvanceModules.nodes ?? []).map(module => {
          const enabled = (data?.pureAdvanceCustomer?.modules?.nodes?.filter(n => n.module.id === module.id) ?? [])[0];
          return (
            <Form.Item key={module.id} label={module.name}>
              <Switch
                size="default"
                checked={!!enabled}
                disabled={loading || !permission.update}
                onChange={enabled => update({
                  variables: {
                    enabled,
                    customerCode,
                    module: module.enum,
                  }
                })}
              />
            </Form.Item>
          );
        })}
      </Form>
    </div>
  );
};

const DATA_QUERY = gql`
  query PureAdvanceCustomerProfile($customerCode: String!) {
    pureAdvanceModules {
      hash
      nodes {
        id
        name
        enum
        description
      }
    }
    pureAdvanceCustomer(customerCode: $customerCode) {
      code
      modules {
        hash
        nodes {
          id {
            customerCode
            moduleId
          }
          module {
            id
            name
            enum
            description
          }
        }
      }
    }
  }
`;

gql`
  mutation UpdateCustomerModule($customerCode: String!, $module: ModuleEnumType!, $enabled: Boolean!) {
    updatePureAdvanceCustomerModule(customerCode: $customerCode, module: $module, enabled: $enabled) {
      code
      modules {
        hash
        nodes {
          id {
            customerCode
            moduleId
          }
          module {
            id
            name
            description
          }
        }
      }
    }
  }
`;

export default PureAdvanceCustomerProfileContainer;
