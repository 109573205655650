import React from 'react';
import * as PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { drop, join, take } from 'lodash';
import { get } from 'lodash/fp';
import BeekBadge from '../Badge/BeekBadge';
import DisplayContent from '../Badge/DisplayContent';

const MultiColumn = ({
  items = [],
  max = 5,
  disableLink,
}) => {
  const display = take(items, max);
  const hidden = drop(items, max);

  if (disableLink) {
    return (
      <div>
        {display.map((item, index) => (
          <span key={item.id || item.key || item.label}>
          
            <DisplayContent>
              {item.label}
            </DisplayContent>
            {(index < display.length - 1) && ', '}
          </span>
        ))}
        <BeekBadge
          prefix="+"
          count={items.length}
          greaterThan={max}
          tooltip={join(hidden.map(get('label')), ', ')}
        />
      </div>
    );
  }

  return (
    <div>
      {display.map((item, index) => (
        <span key={item.id || item.key || item.label}>
          
          <NavLink to={item.url}>
            {item.label}
          </NavLink>
          {(index < display.length - 1) && ', '}
        </span>
      ))}
      <BeekBadge
        prefix="+"
        count={items.length}
        greaterThan={max}
        tooltip={join(hidden.map(get('label')), ', ')}
      />
    </div>
  );
};

MultiColumn.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    // MARK: [url] probably shouldn't be required.
    url: PropTypes.string.isRequired,
  })).isRequired,
  max: PropTypes.number,
  disableLink: PropTypes.bool,
};

MultiColumn.defaultProps = {
  max: 5,
  disableLink: false,
};


export default MultiColumn;
