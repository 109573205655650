import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Select } from 'antd';
import { isUndefined } from 'lodash';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import { TableFieldUpdateViewProps } from '../../../search_old/types';
import { usePersonFieldMutation } from '../../../person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import {
  CUSTOM_REMOVE_MUTATION,
  CUSTOM_UPSERT_MUTATION,
  FIELD_OPTIONS,
} from './customFieldUtils';
import { getVariableField } from '../../../person/fields/utils/personFieldsUtils';
import {
  EntityCustomFieldOptionsQueryQuery,
  EntityCustomFieldOptionsQueryQueryVariables, RemoveCustomFieldInputValueMutation, RemoveCustomFieldInputValueMutationVariables
} from '../../../../../gql/typings';


const CustomSelect: React.FC<TableFieldUpdateViewProps> = (props) => {
  const values = getVariableField(props!.record, props!.options, 'customFields');
  const localization = useLocalization();
  const [selected, setSelected] = useState<number>();
  const { data } = useQuery<EntityCustomFieldOptionsQueryQuery, EntityCustomFieldOptionsQueryQueryVariables>(FIELD_OPTIONS, {
    variables: {
      code: props.options.selectedOption!.code as string
    },
    fetchPolicy: 'network-only'
  });

  const {
    Renderer,
    blocking,
    submit,
  } = usePersonFieldMutation({
    mutation: CUSTOM_UPSERT_MUTATION,
    controlSetting: props.record?.controlSetting,
    skipDcrWarning: true,
  });

  const [removeCustomFieldValue] = useMutation<RemoveCustomFieldInputValueMutation,
  RemoveCustomFieldInputValueMutationVariables>(CUSTOM_REMOVE_MUTATION);

  useEffect(() => {
    setSelected(values.nodes[0]?.value?.id ?? undefined);
  }, [values]);

  const buildVariables = (newSelected: number) => ({
    recordId: props.record.id,
    customFieldCode: props.options.selectedOption?.code,
    customValues: [{ valueId: newSelected }]
  });

  const onChange = (newSelected: number) => {
    if (newSelected) {
      submit(
        buildVariables(newSelected)
      ).then(() => !blocking && props.endEditing());
    } else {
      const toRemoveLinkId = values?.nodes[0]!.id;
      removeCustomFieldValue({ variables: {
        customValues: [toRemoveLinkId]
      } }).then(() => !blocking && props.endEditing());
    }
  };

  return (
    <Renderer>
      <Select
        value={selected}
        placeholder={localization.formatMessage(Locale.Command.Select)}
        loading={isUndefined(data?.customField?.options)}
        autoFocus
        allowClear
        onBlur={() => !blocking && props.endEditing()}
        filterOption={false}
        style={{ width: 'clamp(120px, 100%, 230px)' }}
        onChange={onChange}
      >
        {(data?.customField?.options?.nodes ?? []).map(option => (
          <Select.Option key={option.value!.id} value={option.value!.id}>
            {option.value!.value}
          </Select.Option>
        ))}
      </Select>
    </Renderer>

  );
};

export default CustomSelect;

