import { gql } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  SelectSiteSegmentationCodeSuggestionQueryQuery, SelectSiteSegmentationCodeSuggestionQueryQueryVariables,
  UpdateSiteSectorTypeMutationMutationVariables
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';


export const siteSegmentationFieldConfig = siteFieldConfig(
  'segmentation',
  [
    'id',
    'siteSegmentationCode.code',
    'siteSegmentationCode.id',
    'siteSegmentationCode.heading',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.HCO_Segment),
    additionalTableConfig: {
      width: 90
    },
    render: record => record.siteSegmentationCode?.heading,
    updateView: buildSelectUpdateFieldConfig({
      mode: 'single',
      allowClear: true,
      selectPlaceholder: localization.formatMessage(Locale.Command.Select_segment_code),
      mutation: UPDATE_SITE_SEGMENT_CODE_MUTATION,
      selectedKey: record => record.siteSegmentationCode?.id?.toString(),
      buildVariables: (selectedKey, record) => ({
        siteId: record.id,
        segmentCodeId: selectedKey,
      }) as UpdateSiteSectorTypeMutationMutationVariables,
      fetchSuggestions: ({ client }) => (
        client.query<SelectSiteSegmentationCodeSuggestionQueryQuery, SelectSiteSegmentationCodeSuggestionQueryQueryVariables>({
          query: SUGGESTIONS_QUERY,
        }).then(res => res.data.segmentationCodes
          .nodes.map(segCodes => ({ label: segCodes.heading, code: segCodes.id?.toString() })))
      )
    }),
  }),
);

const UPDATE_SITE_SEGMENT_CODE_MUTATION = gql`
  mutation UpdateSiteSegmentationCodeMutation($siteId: Int!, $segmentCodeId: Int!) {
    upsertSiteSegmentationCode(siteId: $siteId, segmentationCodeId: $segmentCodeId) {
      id
    }
  }
`;

const SUGGESTIONS_QUERY = gql`
  query SelectSiteSegmentationCodeSuggestionQuery {
    segmentationCodes(criteria: { fetchSize: { limit: 1000 } }) {
      hash
      nodes {
        id
        code
        heading
      }
    }
  }
`;
