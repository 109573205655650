import React, { isValidElement } from 'react';
import { omit } from 'lodash';
import QuickActionButton from '../../QuickActions/QuickActionButton';
import { EntitiesSearchQuickActionItem } from './EntitiesSearchQuickActionItemType';
import { Optional } from '../../../util/StateArrayType';

export type EntitiesSearchQuickActionsProps = {
  quickActions: Optional<Array<EntitiesSearchQuickActionItem|React.ReactNode>>;
};

const filter = (e: EntitiesSearchQuickActionItem|React.ReactNode) => {
  if (isValidElement(e)) return true;
  return !(e as EntitiesSearchQuickActionItem).hide;
};

const EntitiesSearchQuickActions: React.FC<EntitiesSearchQuickActionsProps> = ({ quickActions }) => {

  if (!quickActions || quickActions.filter(filter).length === 0) return <></>;

  return (
    <div className="entities-search-quick-actions-container" style={{ zIndex: 1 }}>
      {quickActions
        .filter(filter)
        .map(action => {
          if (isValidElement(action)) return action;
          const a = action as EntitiesSearchQuickActionItem;
          return <QuickActionButton key={a.key} {...omit(a, 'key')} />;
        })}
    </div>
  );
};


export default EntitiesSearchQuickActions;
