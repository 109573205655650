import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, DatePicker, Form, InputNumber, message, Modal, Select, Space, Typography } from 'antd';
import moment, { Moment } from 'moment';
import Flag, { FlagCountry } from '../../../../components/Flag/Flag';
import CountryPicker from '../../../../components/Input/Fields/CountryPicker';
import { StateArray } from '../../../../util/StateArrayType';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import MonthPicker from '../../../../components/Date/MonthPicker';
import {
  CreateNewSalesCycleMutationMutation, CreateNewSalesCycleMutationMutationVariables,
  KPIQueryQuery,
  KPIQueryQueryVariables,
  SegmentationSaleCycleInput
} from '../../../../../gql/typings';

type CycleSetupListProps = {
  visibleState: StateArray<NonNullable<KPIQueryQuery['salesCycleSetups']>['nodes']['0'] | undefined>;
  selectedCountry: FlagCountry;
};

type FormData = {
  yearStart: Moment;
  cycleStartMonth: Moment;
  country: string;
  cycleAmount: number;
  segmentations: SegmentationSaleCycleInput[];
};

const CycleSetupList: React.FC<CycleSetupListProps> = ({
  visibleState: [selectedSetting, setSelectedSetting],
  selectedCountry,
}) => {
  const localization = useLocalization();
  const [show, setShow] = useState<boolean>(false);
  const [form] = Form.useForm<FormData>();

  const { data, loading } = useQuery<KPIQueryQuery, KPIQueryQueryVariables>(DATA_QUERY, {
    variables: { selectedCountry },
  });
  const [
    create,
  ] = useMutation<CreateNewSalesCycleMutationMutation, CreateNewSalesCycleMutationMutationVariables>(CYCLE_MUTATION);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    setSelectedSetting(undefined);
  }, [data, setSelectedSetting]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        country: selectedCountry,
        cycleAmount: 4,
        yearStart: moment(),
        segmentations: data.segmentationCodes.nodes.map(e => ({
          segmentationId: e.id,
          goal: 0
        })),
      });

    }
  }, [data, form, selectedCountry]);


  const onFinish = () => {
    const {
      country,
      cycleAmount,
      segmentations,
      yearStart,
      cycleStartMonth,
    } = form.getFieldsValue();

    return create({
      variables: {
        cycleStartMonth: cycleStartMonth.get('month') + 1,
        countryCode: country,
        cycleAmount,
        year: yearStart.year(),
        segmentations,
      },
      refetchQueries: [DATA_QUERY],
    }).then(() => setShow(false))
      .finally(() => messageApi.success('Sales cycle has been successfully created'));
  };

  const selectSetting = (id: number) => setSelectedSetting(
    data?.salesCycleSetups?.nodes?.filter(it => it.id === id)?.[0],
  );

  return (
    <Space>
      {contextHolder}
      <Select
        onChange={selectSetting}
        value={selectedSetting?.id}
        placeholder="Please select cycle"
        loading={loading}
      >
        {selectedCountry && (data?.salesCycleSetups?.nodes ?? []).map(cycleSetup => (
          <Select.Option key={cycleSetup.id} value={cycleSetup.id}>
            <Flag country={cycleSetup.countryCode as FlagCountry} rectangle height={22} /> {cycleSetup.year}
          </Select.Option>
        ))}
      </Select>

      <Button size="middle" onClick={() => setShow(true)} type="primary">
        {localization.formatMessage(Locale.Text.Cycle_create_new)}
      </Button>

      <Modal
        title={localization.formatMessage(Locale.Text.Cycle_create_new)}
        open={show}
        footer={null}
        onOk={() => setShow(false)}
        onCancel={() => setShow(false)}
      >

        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            yearStart: moment(),
            cycleAmount: 4
          }}
        >
          <Typography.Title level={4}>Cycle setup</Typography.Title>
          <Typography.Paragraph>
            Please insert the cycle information for the new fiscal year
          </Typography.Paragraph>

          <Form.Item
            name="country"
            label={localization.formatMessage(Locale.Attribute.Country)}
            labelAlign="right"
          >
            <CountryPicker
              showFlag
              style={{ width: 200 }}
            />
          </Form.Item>

          <Form.Item
            name="yearStart"
            label={localization.formatMessage(Locale.Attribute.Fiscal_year_start)}
            labelAlign="right"
          >
            <DatePicker picker="year" />
          </Form.Item>

          <Form.Item
            name="cycleAmount"
            label={localization.formatMessage(Locale.Command.Amount_cycles_pr_year)}
            labelAlign="right"
          >
            <InputNumber min={1} max={12} />
          </Form.Item>

          <Form.Item
            name="cycleStartMonth"
            label={localization.formatMessage(Locale.Attribute.Fiscal_year_start)}
          >
            <MonthPicker />
          </Form.Item>

          <Typography.Title level={4}>Segmentation</Typography.Title>
          <Typography.Paragraph>
            Please assign the correct number of visits pr segmentation value
          </Typography.Paragraph>
          <Form.List
            name="segmentations"
          >
            {(fields, _, { errors }) => <>
              {fields.map((field, index) => (
                <Form.Item
                  {...field}
                  key={field.key}
                  label={data?.segmentationCodes?.nodes?.[index]?.heading}
                >
                  <SegmentationInput />
                </Form.Item>
              ))}
              <Form.ErrorList errors={errors} />
            </>}
          </Form.List>

          <Space>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-admin-btn"

            >Create</Button>

            <Button
              type="default"
              onClick={() => setShow(false)}
            >Cancel</Button>
          </Space>

        </Form>

      </Modal>
    </Space>
  );
};

type SegmentationInputProps = {
  value?: FormData['segmentations'][0];
  onChange?: (v: FormData['segmentations'][0]) => void;
};
const SegmentationInput: React.FC<SegmentationInputProps> = props => (
  <InputNumber
    min={0}
    max={100}
    value={props.value?.goal}
    onChange={goal => props.onChange?.({ ...props.value!, goal: goal as number })}
  />
);


const DATA_QUERY = gql`
  query KPIQuery($selectedCountry: CountryCode!) {
    segmentationCodes {
      hash
      nodes {
        id
        heading
      }
    }
    salesCycleSetups(criteria: { countries: [$selectedCountry] }) {
      hash
      totalCount
      nodes {
        id
        countryCode
        year
        numberOfCyclesInYear
        cycleStartMonth
        targets {
          hash
          nodes {
            id
            goal
            segmentationCodeId
            segmentationCode {
              id
              heading
              code
            }
          }
        }
      }
    }
  }
`;


const CYCLE_MUTATION = gql`
  mutation CreateNewSalesCycleMutation(
    $year: Int!,
    $countryCode: CountryCode!,
    $cycleAmount: Int!,
    $cycleStartMonth: Month!,
    $segmentations: [SegmentationSaleCycleInput!]!
  ) {
    createNewSalesCycle(
      country: $countryCode,
      cycleStartMonth: $cycleStartMonth,
      year: $year,
      cycleAmount: $cycleAmount,
      segmentationValues: $segmentations
    ) {
      id

    }
  }
`;


export default CycleSetupList;
