import React, { useState } from 'react';
import { Collapse } from 'antd';

const LightCollapse = ({
  children,
  defaultActiveKey,
  accordion = false,
  className,
}) => {
  const [active, setActive] = useState(defaultActiveKey);

  return (
    <div className={`light-collapse-container ${className}`}>
      <Collapse
        activeKey={active}
        onChange={setActive}
        accordion={accordion}
      >
        {children}
      </Collapse>
    </div>
  );
};

LightCollapse.Panel = Collapse.Panel;

export default LightCollapse;
