import { gql, useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { Result, Row, Skeleton, Tabs } from 'antd';
import { useNavigate, useMatch } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import MainView from '../../../../components/View/MainView';
import { isActualNumber } from '../../../../util/Util';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import UserAdminGeneralDetails from './UserAdminGeneralDetails';
import UserAdminRoleDetails from './UserAdminRoleDetails';
import UserAdminCommunicationDetails from './UserAdminCommunicationDetails';
import UserAdminResponsibleAndTargetDetails from './UserAdminResponsibleAndTargetDetails';
import { usePermission } from '../../../../permission/usePermission';
import UserAdminDetailsRightActions from './UserAdminTitleDetails/UserAdminDetailsRightActions';
import {
  AdminUserQueryQuery,
  AdminUserQueryQueryVariables,
  UpdateUserMutationMutation,
  UpdateUserMutationMutationVariables
} from '../../../../../gql/typings';

const UserAdminDetails: React.FC = () => {
  const match = useMatch('/admin/user/:id');
  const localization = useLocalization();
  const navigate = useNavigate();
  if (!isActualNumber(match?.params?.id)) return <MainView className="user-admin-details-container">
    <Result status="500" />
  </MainView>;
  const userId = match!.params.id!;
  const { read, update } = usePermission('UserControl');
  const { data, loading } = useQuery<AdminUserQueryQuery, AdminUserQueryQueryVariables>(DATA_QUERY, {
    variables: { userId },
  });
  const [
    updateUser,
    { loading: isUpdating },
  ] = useMutation<UpdateUserMutationMutation, UpdateUserMutationMutationVariables>(UPDATE_USER_MUTATION);

  if (!loading && !read) return <MainView className="user-admin-details-container">
    <Result status="403" title="You do not have enough access rights to view users" />
  </MainView>;

  if (!loading && !data?.user) return <MainView className="user-admin-details-container">
    <Result status="404" title={localization.formatMessage(Locale.Text.Not_found)} />
  </MainView>;


  return (
    <MainView
      className="user-admin-details-container"
      title={data?.user?.fullname}
      rightActions={<UserAdminDetailsRightActions user={data?.user} />}
      titleLoading={loading}
      breadcrumbs={[
        {
          label: 'Admin Settings',
          onClick: () => navigate('/admin'),
        },
        {
          label: 'Users',
          onClick: () => navigate('/admin/users'),
        },
        {
          label: data?.user?.fullname ?? '',
        },
      ]}
    >
      <Skeleton loading={loading}>
        <Tabs
          type="card"
          tabBarExtraContent={isUpdating && <LoadingOutlined />}
          items={[
            {
              key: 'details',
              label: 'Details',
              children: (
                data?.user && <Row>
                  <UserAdminGeneralDetails user={data.user} allowUpdate={update} updateUser={updateUser} />
                  <UserAdminRoleDetails user={data.user} allowUpdate={update} updateUser={updateUser} />
                  <UserAdminCommunicationDetails user={data.user} allowUpdate={update} />
                  <UserAdminResponsibleAndTargetDetails user={data.user} allowUpdate={update} />
                </Row>
              )
            },
            {
              key: 'activities',
              label: 'Activities',
              children: ' This page hasn\'t been implemented yet'
            }
          ]}
        />
      </Skeleton>
    </MainView>
  );
};


const UPDATE_USER_MUTATION = gql`
  mutation UpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      fullname
      firstname
      lastname
      email
      roleId
      role { id }
      countries { hash, nodes { code } }
    }
  }
`;

const DATA_QUERY = gql`
  query AdminUserQuery($userId: Int!) {
    user(userId: $userId) {
      id
      fullname
      firstname
      lastname
      email
    }
  }
`;

export default UserAdminDetails;
