import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import DateDisplay from '../../../components/Badge/DateDisplay';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';

export const linkImportedFieldConfig = linkFieldConfig(
  'imported',
  ['ct'],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Import_date),
    additionalTableConfig: {
      width: 125
    },
    render: (record) => <DateDisplay>{record.ct}</DateDisplay>,
  })
);
