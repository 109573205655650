import React from 'react';
import UrlTabs from '../adminComponents/UrlTabs/UrlTabs';
import InstanceDeploymentsView from './InstanceDeploymentsView';
import InstanceReleases from './releases/InstanceReleases';

const ClientsView: React.FC = () => (
  <UrlTabs
    path="clients"
    items={[
      {
        path: 'releases',
        title: 'Releases',
        render: InstanceReleases,
      },
      {
        path: 'list',
        title: 'Deployments',
        render: InstanceDeploymentsView,
      }
    ]}
  />
);


export default ClientsView;
