import { gql } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  SelectValueChainSuggestionQueryQuery,
  SelectValueChainSuggestionQueryQueryVariables,
  SiteCriteriaOrder,
  UpdateSiteChainMutationMutationVariables
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';


export const siteChainFieldConfig = siteFieldConfig(
  'chain',
  [
    'id',
    'countryCode',
    'chain.label',
    'chain.displayLabel',
    'chain.code',
    'chain.type',
    'controlSetting.id',
    'controlSetting.entityTypeId',
    'controlSetting.maintainerSourceCode',
    'controlSetting.createDcr',
  ],
  ({ localization }) => ({
    sorting: {
      ascend: SiteCriteriaOrder.CHAIN_ASC,
      descend: SiteCriteriaOrder.CHAIN_DESC,
    },
    dcrInfo: site => ({
      field: FieldEnum.HCO_CHAIN,
      entityType: EntityTypeEnum.SITE,
      entityAffiliationId: site.id,
    }),
    title: localization.formatMessage(Locale.Attribute.Chain),
    additionalTableConfig: {
      width: 100
    },
    render: (record) => record.chain?.label,
    updateView: buildSelectUpdateFieldConfig({
      mode: 'single',
      allowClear: true,
      selectPlaceholder: localization.formatMessage(Locale.Command.Select_Chain),
      mutation: UPDATE_SITECHAIN_MUTATION,
      selectedKey: record => record.chain?.code,
      buildVariables: (selectedKey, record) => ({
        siteId: record.id,
        chainCode: selectedKey,
      }) as UpdateSiteChainMutationMutationVariables,
      fetchSuggestions: ({ client, record, userInput }) => (
        client.query<SelectValueChainSuggestionQueryQuery, SelectValueChainSuggestionQueryQueryVariables>({
          query: SUGGESTED_QUERY,
          variables: { countryCode: record.countryCode, search: `%${userInput}%` },
        }).then(res => res.data.valueChains.nodes)
      ),
    }),
  })
);

const UPDATE_SITECHAIN_MUTATION = gql`
  mutation UpdateSiteChainMutation($siteId: Int!, $chainCode: String!) {
    updateSiteChain(siteId: $siteId, chainCode: $chainCode) {
      id {
        siteId
        chainCode
      }
    }
  }
`;

const SUGGESTED_QUERY = gql`
  query SelectValueChainSuggestionQuery($search: String, $countryCode: CountryCode!) {
    valueChains(criteria: { label: $search, countries: [$countryCode] fetchSize: { limit: 1000 } }) {
      hash
      nodes {
        code
        label: displayLabel
        type
      }
    }
  }
`;
