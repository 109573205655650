import { gql } from '@apollo/client';
import { Locale } from '../../../localization/LocalizationKeys';
import { TableConfig, TableFieldsConfig } from '../search_old/types';
import { linkPositionFieldConfig } from './fields/linkPositionFieldConfig';
import { linkFullNameFieldConfig } from './fields/linkFullNameFieldConfig';
import { linkSiteNameFieldConfig } from './fields/linkSiteNameFieldConfig';
import { linkWorkSpecialitiesFieldConfig } from './fields/linkWorkSpecialitiesFieldConfig';
import { linkCreatedFieldConfig } from './fields/linkCreatedFieldConfig';
import { linkUpdatedFieldConfig } from './fields/linkUpdatedFieldConfig';
import { linkIsPrimaryFieldConfig } from './fields/linkIsPrimaryFieldConfig';
import { linkImportedFieldConfig } from './fields/linkImportedFieldConfig';
import { linkPureAdvanceIdFieldConfig } from './fields/linkPureAdvanceIdFieldConfig';
import { linkValidatedFieldConfig } from './fields/linkValidatedFieldConfig';
import { linkMaintainerSourceFieldConfig } from './fields/linkMaintainerSourceFieldConfig';
import { linkOriginSourceFieldConfig } from './fields/linkOriginSourceFieldConfig';
import { buildEntityContactInformationFieldConfigs } from '../person/fields/buildEntityContactInformationFieldConfigs';
import { buildEntityExternalIdFieldConfigs } from '../site/fields/buildEntityExternalIdFieldConfigs';
import { linkFirstNameFieldConfig } from './fields/linkFirstNameFieldConfig';
import { linkMiddleNameFieldConfig } from './fields/linkMiddleNameFieldConfig';
import { linkLastNameFieldConfig } from './fields/linkLastNameFieldConfig';
import { linkPersonPureAdvanceIdFieldConfig } from './fields/linkPersonPureAdvanceIdFieldConfig';
import { linkSitePureAdvanceIdFieldConfig } from './fields/linkSitePureAdvanceIdFieldConfig';
import { linkPersonAPureBaseIdFieldConfig } from './fields/linkPersonAPureBaseIdFieldConfig';
import { linkSiteAPureBaseIdFieldConfig } from './fields/linkSiteAPureBaseIdFieldConfig';
import { linkPersonTypeFieldConfig } from './fields/linkPersonTypeFieldConfig';
import { linkPersonEducationsFieldConfig } from './fields/linkPersonEducationsFieldConfig';
import { linkSiteTypeFieldConfig } from './fields/linkSiteTypeFieldConfig';
import { linkSiteAddressFieldConfig } from './fields/linkSiteAddressFieldConfig';
import { stringToAbsHash } from '../../apollo/util';
import { linkTherapeuticFocusFieldConfig } from './fields/linkTherapeuticFocusFieldConfig';
import { EntityTypeEnum, SitePersonCriteriaOrder } from '../../../gql/typings';
import { linkNumberOfPatientsFieldConfig } from './fields/linkNumberOfPatientsFieldConfig';
import { linkSiteFieldConfig } from './fields/linkSiteFieldConfig';
import { buildEntityCustomFieldConfigs } from '../site/fields/buildEntityCustomFieldConfigs';
import { linkPersonTitleFieldConfig } from './fields/linkPersonTitleFieldConfig';
import { linkSiteParentNameFieldConfig } from './fields/linkSiteParentNameFieldConfig';
import { buildApureBaseIdFieldConfig } from '../person/fields/personApureBaseIdFieldConfig';

export const buildLinkTableFragment = (additionalQuery: string|null) => gql`
  fragment LinkTableFragment${stringToAbsHash(additionalQuery)} on SitePerson {
    id
    apurebaseId
    isActive
    isLocal
    ${additionalQuery ?? ''}
  }
`;

export const buildLinkConnectionDataQuery = (additionalQuery: string|null) => gql`
  query LinkConnectionQuery${stringToAbsHash(additionalQuery)}($criteria: SitePersonCriteria) {
    connection: sitePersonAffiliations(criteria: $criteria) {
      hash
      totalCount
      nodes { ...LinkTableFragment${stringToAbsHash(additionalQuery)} }
    }
  }
  ${buildLinkTableFragment(additionalQuery)}
`;

export const buildLinkRecordDataQuery = (additionalQuery: string|null) => gql`
  query LinkRecordQuery${stringToAbsHash(additionalQuery)}($recordId: Int!) {
    record: sitePersonAffiliation(id: $recordId) {
      id
      ...LinkTableFragment${stringToAbsHash(additionalQuery)}
    }
  }
  ${buildLinkTableFragment(additionalQuery)}
`;

export type SupportedLinkTableFields =
  'position'
  | 'id'
  | 'aPureBaseId'
  | 'workSpc'
  | 'contactInformation'
  | 'created'
  | 'updated'
  | 'validated'
  | 'imported'
  | 'externalId'
  | 'originSource'
  | 'maintainerSource'
  | 'isPrimary'
  | 'therapeuticFocus'
  | 'numberOfPatients'
  | 'customField'

  // Person
  | 'personId'
  | 'personAPureBaseId'
  | 'fullName'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'personType'
  | 'personEducations'
  | 'personTitle'

  // Site
  | 'siteId'
  | 'siteAPureBaseId'
  | 'siteName'
  | 'siteType'
  | 'siteAddress'
  | 'updatableSite'
  | 'siteParentName';

export const supportedLinkTableFields: TableFieldsConfig<SupportedLinkTableFields>['fields'] = {
  id: linkPureAdvanceIdFieldConfig,
  aPureBaseId: buildApureBaseIdFieldConfig(EntityTypeEnum.AFFILIATION, {
    ascend: [SitePersonCriteriaOrder.APUREBASE_ID_ASC],
    descend: [SitePersonCriteriaOrder.APUREBASE_ID_DESC],
  },),
  position: linkPositionFieldConfig,
  workSpc: linkWorkSpecialitiesFieldConfig,
  created: linkCreatedFieldConfig,
  updated: linkUpdatedFieldConfig,
  imported: linkImportedFieldConfig,
  validated: linkValidatedFieldConfig,
  isPrimary: linkIsPrimaryFieldConfig,
  maintainerSource: linkMaintainerSourceFieldConfig,
  originSource: linkOriginSourceFieldConfig,
  contactInformation: buildEntityContactInformationFieldConfigs(EntityTypeEnum.AFFILIATION),
  externalId: buildEntityExternalIdFieldConfigs(EntityTypeEnum.AFFILIATION),
  therapeuticFocus: linkTherapeuticFocusFieldConfig,
  numberOfPatients: linkNumberOfPatientsFieldConfig,
  customField: buildEntityCustomFieldConfigs(EntityTypeEnum.AFFILIATION),

  // Person
  personId: linkPersonPureAdvanceIdFieldConfig,
  personAPureBaseId: linkPersonAPureBaseIdFieldConfig,
  fullName: linkFullNameFieldConfig,
  firstName: linkFirstNameFieldConfig,
  middleName: linkMiddleNameFieldConfig,
  lastName: linkLastNameFieldConfig,
  personType: linkPersonTypeFieldConfig,
  personEducations: linkPersonEducationsFieldConfig,
  personTitle: linkPersonTitleFieldConfig,

  // Site
  siteId: linkSitePureAdvanceIdFieldConfig,
  siteAPureBaseId: linkSiteAPureBaseIdFieldConfig,
  siteName: linkSiteNameFieldConfig,
  siteType: linkSiteTypeFieldConfig,
  siteAddress: linkSiteAddressFieldConfig,
  updatableSite: linkSiteFieldConfig,
  siteParentName: linkSiteParentNameFieldConfig
};

export const LinkTableConfig: TableConfig = {
  buildConnectionQuery: buildLinkConnectionDataQuery,
  buildRecordQuery: buildLinkRecordDataQuery,
  buildFragment: buildLinkTableFragment,
  titleLabel: Locale.Attribute.Person_Affiliations,
  columnConfig: {
    fields: supportedLinkTableFields,
  },
};
