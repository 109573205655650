import React from 'react';
import { Select } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import {
  EducationDataQueryQuery,
  EducationDataQueryQueryVariables,
} from '../../../../gql/typings';

type EducationCodesFormInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  countryCode: string;
  disabled?: boolean;
};

const EducationCodesFormInput: React.FC<EducationCodesFormInputProps> = ({
  value,
  onChange,
  disabled,
  countryCode
}) => {
  const {
    data,
    loading,
    refetch,
  } = useQuery<EducationDataQueryQuery, EducationDataQueryQueryVariables>(EDUCATION_DATA_QUERY, {
    variables: { countries: countryCode ? [countryCode] : [], search: '%', },
  });
  const searchDebounced = debounce(
    search => {
      const input: EducationDataQueryQueryVariables = { search: `%${search}%` };
      if (countryCode) input.countries = [countryCode];
      return refetch(input);
    },
    25,
  );

  return (
    <Select
      placeholder='Select Education Speciality'
      mode='multiple'
      style={{ minWidth: 120 }}
      loading={loading}
      value={value as string|undefined}
      showSearch
      onSearch={searchDebounced}
      filterOption={false}
      onChange={e => {
        onChange?.(e);
        searchDebounced('');
      }}
      disabled={disabled}
      allowClear
      options={(data?.educations.nodes ?? []).map(ed => ({
        value: ed.code,
        label: ed.label,
      }))}
    />
  );
};

const EDUCATION_DATA_QUERY = gql`
  query EducationDataQuery($countries: [CountryCode!], $search: String) {
    educations(criteria:{
      label: $search,
      countries: $countries,
      fetchSize: { limit: 1000 }
    }) {
      hash
      nodes {
        code
        label: displayLabel
        type
      }
    }
  }
`;

export default EducationCodesFormInput;
