import { gql } from '@apollo/client';
import { useEffect } from 'react';
import moment, { Moment } from 'moment';
import {
  EntityTypeEnum,
  usePersonEntityRecordsLazyQuery, useSiteEntityRecordsLazyQuery
} from '../../gql/typings';
import { Optional } from '../util/StateArrayType';

export type UseEntityRecordSupportedTypes = EntityTypeEnum.PERSON | EntityTypeEnum.SITE;

export type ReturnEntityRecordType = {
  id: number;
  name: string;
  url: string;
  ct: Moment;
  ut: Moment;
};

type ReturnType = {
  records: Optional<ReturnEntityRecordType[]>;
  loading: boolean;
};

export function useEntityRecord<T extends UseEntityRecordSupportedTypes>(
  type: T,
  ids: number[],
  startLoading = true,
): ReturnType {
  const [loadPersons, personData] = usePersonEntityRecordsLazyQuery({ variables: { ids } });
  const [loadSites, siteData] = useSiteEntityRecordsLazyQuery({ variables: { ids } });

  useEffect(() => {
    if (startLoading) {
      if (type === EntityTypeEnum.PERSON) loadPersons();
      if (type === EntityTypeEnum.SITE) loadSites();
    }
  }, [loadPersons, loadSites, startLoading, type]);

  switch (type) {
    case EntityTypeEnum.PERSON:
      return {
        loading: personData.loading,
        records: personData.data?.personsById.map(p => ({
          id: p.id,
          name: p.fullName,
          url: `/hcp/${p.id}`,
          ct: moment(p.ct),
          ut: moment(p.ut),
        })),
      };
    case EntityTypeEnum.SITE:
      return {
        loading: siteData.loading,
        records: siteData.data?.sitesById.map(s => ({
          id: s.id,
          name: s.name,
          url: `/hco/${s.id}`,
          ct: moment(s.ct),
          ut: moment(s.ut),
        })),
      };
    default:
      return { loading: false, records: null };
  }
}


gql`
  query PersonEntityRecords($ids: [ID!]!) {
    personsById(ids: $ids) {
      id
      fullName
      ct
      ut
    }
  }
`;

gql`
  query SiteEntityRecords($ids: [ID!]!) {
    sitesById(ids: $ids) {
      id
      name
      ct
      ut
      }
  }
`;
