import React from 'react';
import Highlighter from 'react-highlight-words';

type ApbTextHighlighterProps = {
  text: string;
  search: string;
};


const ApbTextHighlighter: React.FC<ApbTextHighlighterProps> = ({ text, search }) => (
  <Highlighter
    searchWords={[search]}
    textToHighlight={text}
    highlightTag={({ children }) => (
      <span style={{ background: 'yellow' }}>
        {children}
      </span>
    )}
  />
);

export default ApbTextHighlighter;
