import React, { useEffect, forwardRef, MutableRefObject } from 'react';
import { useMutation, useQuery, gql } from '@apollo/client';
import { useIntl } from 'react-intl';
import { Form, Table } from 'antd';
import { keys, toNumber } from 'lodash';
import { ColumnsType } from 'antd/lib/table/interface';
import moment, { Moment } from 'moment';
import Popup from '../Popup/Popup';
import { Locale } from '../../../localization/LocalizationKeys';
import Input from '../Form/Input';
import {
  GetAllFavListsQuery,
  UpdateFavSortMutationMutation,
  UpdateFavSortMutationMutationVariables,
  UserFavInput
} from '../../../gql/typings';
import { Optional } from '../../util/StateArrayType';


type DragFavoriteListsProps = {
  visible?: boolean;
  onClose: () => void;
};

export type DragFavoriteListsRef = {
  /**
   * As this component will be created twice by ant, we don't want to over-fetch and only load the data once or as needed.
   * We will use this ref to see if it's actually needed to refetch the data.
   * @see https://ant.design/components/menu/#Why-will-Menu's-children-be-rendered-twice
   */
  lastRefetchRef?: Optional<Moment>;
};

const DragFavoriteLists = forwardRef<DragFavoriteListsRef, DragFavoriteListsProps>(({
  visible,
  onClose,
}, r) => {
  const ref = r as Optional<MutableRefObject<DragFavoriteListsRef>>;

  let shouldFetch = true;
  if (ref?.current.lastRefetchRef?.isAfter(moment().subtract('5', 'minutes'))) {
    shouldFetch = false;
  }

  const [form] = Form.useForm();
  const { data, loading, refetch } = useQuery<GetAllFavListsQuery>(GET_FAV_LISTS, { skip: !shouldFetch });
  const [updateFavSort] = useMutation<UpdateFavSortMutationMutation, UpdateFavSortMutationMutationVariables>(upDateSortValue);
  const intl = useIntl();

  useEffect(() => {
    if (shouldFetch && !loading) refetch();

    if (ref) ref.current.lastRefetchRef = moment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const favLists = data?.viewer?.getAllFavoriteLists.nodes ?? [];
  const listColumns: ColumnsType<NonNullable<GetAllFavListsQuery['viewer']>['getAllFavoriteLists']['nodes']['0']> = [
    {
      title: intl.formatMessage(Locale.Attribute.Title),
      dataIndex: 'title',
      render: (text, _, index) => ({
        props: { className: index < 5 ? 'fav-line' : '' },
        children: text,
      }),
    },
    {
      title: intl.formatMessage(Locale.General.Entity),
      dataIndex: ['entityType', 'heading'],
    },
    {
      title: intl.formatMessage(Locale.General.List_type),
      dataIndex: ['listType', 'heading'],
    },
    {
      title: intl.formatMessage(Locale.General.Entity),
      dataIndex: 'sort',
      width: 75,
      render: (text, record) => (
        <div>
          <Input id={`${record.id}`} form={form} initialValue={record.sort} />
        </div>
      ),
    },
  ];

  const onAccept = () => {
    const all = form.getFieldsValue();
    const inputVariable: UserFavInput[] = keys(all)
      .map(key => ({
        listId: toNumber(key),
        sort: all[key],
      }));
    return updateFavSort({
      variables: {
        input: inputVariable,
      },
      refetchQueries: ['FavoriteLists', 'FavoriteListsNavigationQuery', 'GetAllFavLists'],
    }).then(onClose);
  };

  return (
    <Popup
      title={intl.formatMessage(Locale.Command.Sort_Favorite_Lists)}
      visible={visible}
      acceptLabel={intl.formatMessage(Locale.Command.Update)}
      cancelLabel={intl.formatMessage(Locale.Command.Cancel)}
      className="sort-fav-lists"
      onClose={onClose}
      onAccept={onAccept}
      size="65%"
    >
      <Form form={form}>
        <Table
          dataSource={favLists}
          rowKey="id"
          columns={listColumns}
        />
        <div className="future-label-container" />
        {/* <FutureLabel color="light-green" title={intl.formatMessage(Locale.Text.Displayed_on_navigation_bar)} /> */}
        <div className="clear">&nbsp;</div>
      </Form>
    </Popup>
  );
});

const GET_FAV_LISTS = gql`
  query GetAllFavLists {
    viewer {
      id
      getAllFavoriteLists{
        hash
        totalCount
        nodes{
          id
          title
          sort
          listType {
            id
            code
            color
            ct
            heading
            note
            status
            ut
          }

          entityType {
            id
            code
            heading
          }

        }
      }
    }
  }
`;


const upDateSortValue = gql`
  mutation UpdateFavSortMutation(
    $input: [UserFavInput!]!
  ) {
    updateFavSort(input: $input)
  }
`;

export default DragFavoriteLists;
