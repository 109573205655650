import React, { useEffect, useState, ReactElement, PropsWithChildren } from 'react';
import { Button, Form, Input, Modal, Tooltip } from 'antd';
import { isBoolean } from 'lodash';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import MergeRequestItemPrefixIcon from '../MergeRequestItemPrefixIcon';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { Optional } from '../../../../../../../../util/StateArrayType';
import { useRandomKey } from '../../../../../../../../hooks/useRandomKey';

export type AdminMergeItemBasicConflictFieldProps<T> = AdminEntityMergeRequestItemProps & {
  fromValue: Optional<T>;
  toValue: Optional<T>;
  valueDisplayMapper?: (value: Optional<T>) => string|React.ReactNode;
  updateMutation: (newValue: T) => Promise<unknown>;
  inputRenderer?: (props: {
    value?: T;
    onChange?: (newValue: Optional<T>) => void;
  }) => React.ReactElement;
};

function AdminMergeItemBasicConflictField<T = string>({
  item,
  emitter,
  updateMutation,
  fromValue,
  toValue,
  children,
  valueDisplayMapper = v => `${v}`,
  inputRenderer,
}: PropsWithChildren<AdminMergeItemBasicConflictFieldProps<T>>): ReactElement {
  const emitterKey = useRandomKey();
  const localization = useLocalization();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [winnerValue, setWinnerValue] = useState<T>();

  if (item.ignoredByMaintainer) return <></>;

  useEffect(() => {
    emitter.emit({
      key: emitterKey,
      state: (isBoolean(winnerValue) || winnerValue) ? 'finished' : (item.hasConflict ? 'not_started' : 'skip'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emitter, item]);

  const onModalSave = () => form.validateFields().then(({ value }) => {
    setWinnerValue(value);
    setModalVisible(false);
    emitter.emit({
      key: emitterKey,
      state: 'finished',
      onSave: () => updateMutation(value),
    });
  });

  const InputRenderer = inputRenderer ?? Input;

  return (
    <span>
      <MergeRequestItemPrefixIcon state={(isBoolean(winnerValue) || winnerValue) ? 'OK' : 'CONFLICT'} />
      {children}
      <Button type="link" onClick={() => setModalVisible(true)}>
        <Tooltip
          title={(isBoolean(winnerValue) || winnerValue) && (
            `${localization.formatMessage(Locale.General.Selected)}: ${valueDisplayMapper(winnerValue)}`
          )}
        >
          [
          {(isBoolean(winnerValue) || winnerValue)
            ? localization.formatMessage(Locale.Command.Update)
            : localization.formatMessage(Locale.Command.Fix)}
          ]
        </Tooltip>
      </Button>
      {(isBoolean(winnerValue) || winnerValue) && (
        <>
          <br />
          {localization.formatMessage(Locale.Text.Will_be_value, {
            value: <strong>{valueDisplayMapper(winnerValue)}</strong>,
          })}
        </>
      )}
      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={onModalSave}
        okText={localization.formatMessage(Locale.Command.Select)}
        closeIcon={<></>}
      >
        {localization.formatText(Locale.Text.Merge_request_conflict_write_in_value)}<br />
        <strong>{valueDisplayMapper(fromValue)}</strong>
        <Button
          size="small"
          type="link"
          onClick={() => form.setFieldValue('value', fromValue)}
        >
          [{localization.formatMessage(Locale.Command.Choose)}]
        </Button>
        <br />

        <strong>{valueDisplayMapper(toValue)}</strong>
        <Button
          size="small"
          type="link"
          onClick={() => form.setFieldValue('value', toValue)}
        >
          [{localization.formatMessage(Locale.Command.Choose)}]
        </Button>
        <br /><br />

        <Form form={form} onFinish={onModalSave}>
          <Form.Item
            name="value"
            label={localization.formatMessage(Locale.Attribute.Final_value)}
            initialValue={toValue}
          >
            <InputRenderer />
          </Form.Item>
        </Form>
      </Modal>
    </span>
  );
}

export default AdminMergeItemBasicConflictField;
