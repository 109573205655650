import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Col, message, Select, Skeleton, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { useLocalization } from '../../../../../util/useLocalization';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import {
  SegmentationCodesTabQueryQuery,
  SegmentationCodesTabQueryQueryVariables,
  UpsertSegmentationCodeTabMutation, UpsertSegmentationCodeTabMutationVariables
} from '../../../../../../gql/typings';


type PersonSegmentationTabFieldProps = {
  personId: number;
  updateAccess: boolean;
};

const PersonSegmentationTabField: React.FC<PersonSegmentationTabFieldProps> = ({ personId, updateAccess }) => {
  const localization = useLocalization();
  const { data, loading } = useQuery<SegmentationCodesTabQueryQuery, SegmentationCodesTabQueryQueryVariables>(DATA_QUERY, {
    variables: { personId },
  });
  const [
    updateSegmentation,
  ] = useMutation<UpsertSegmentationCodeTabMutation, UpsertSegmentationCodeTabMutationVariables>(
    UpsertSegmentationCodeMutation
  );
  const [selected, setSelected] = useState<number>();
  const [removeHcpSegmentationCode] = useMutation(RemoveHcpSegmentationCode);
  const { formatMessage } = useIntl();


  const handleChange = (selectedItems: number) => {
    if (selectedItems != null) {
      updateSegmentation({
        variables: {
          id: personId,
          segmentationId: selectedItems,
        },
        refetchQueries: ['SegmentationCodesTabQuery'],
      })
        .then(() => message.success(formatMessage(Locale.Text.Segmentation_code_has_been_successfully_updated)));
    } else {
      removeHcpSegmentationCode({
        variables: {
          id: personId,
        },
        refetchQueries: ['SegmentationCodesTabQuery'],
      })

        .then(() => message.success(formatMessage(Locale.Text.Segmentation_code_has_been_successfully_removed)));
    }


  };

  useEffect(() => {
    if (data?.person?.id) setSelected(data.person.segmentationCode?.id);
  }, [data]);

  const segmentationText = localization.formatMessage(Locale.Attribute.HCP_Segment);

  // TODO: Fix!
  if (!updateAccess && data?.person?.segmentationCode?.heading) return (
    <span>NaDa'</span>
  );


  return (
    <>
      <Col sm={24} md={8}>
        <strong>{segmentationText}</strong>
        <Tooltip title={localization.formatMessage(Locale.Text.Add_segmentation_code_here)}>
          <InfoCircleOutlined style={{ paddingLeft: 4, verticalAlign: 'top' }} />
        </Tooltip>
      </Col>
      <Col sm={24} md={16}>
        <Skeleton loading={loading} title={{ width: 240 }} active={loading} paragraph={false}>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={localization.formatMessage(Locale.Command.Select_segment_code)}
            optionFilterProp="children"
            allowClear
            onChange={handleChange}
            value={selected}
          >
            {(data?.segmentationCodes.nodes ?? []).map(segmentation => (
              <Select.Option key={segmentation.id} value={segmentation.id}>
                {segmentation.heading}
              </Select.Option>
            ))}
          </Select>
        </Skeleton>
      </Col>
    </>
  );
};

const DATA_QUERY = gql`
  query SegmentationCodesTabQuery($personId: Int!) {
    person(id: $personId) {
      id
      segmentationCode {
        id
        heading
      }
    }
    segmentationCodes {
      hash
      nodes {
        code
        id
        description
        heading
      }
    }
  }
`;

const RemoveHcpSegmentationCode = gql`
  mutation removeHcpSegmentationCode(
    $id: Int!,
  ) {
    removeSegmentationCode(personId: $id)
  }
`;


const UpsertSegmentationCodeMutation = gql`
  mutation UpsertSegmentationCodeTab(
    $id: Int!,
    $segmentationId: Int!
  ) {
    upsertPersonSegmentationCode(id: $id, segmentationId: $segmentationId) {
      id
      segmentationCode {
        id
        code
        heading
      }
    }
  }
`;

export default PersonSegmentationTabField;
