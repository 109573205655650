import { gql } from '@apollo/client';

export const CONFIGURE_ACTIVITY_TYPE_FRAGMENT = gql`
  fragment ActivityTypeMutationFragment on ActivityType {
    code
    heading
    isActive
    description
    sort
    standardTimeLengthMinutes
    superTypeCode
    color {
      code
      headingKey
      value
    }
    brandRule
    locationRule
    participationUserRule
    personRule
    productRule
    projectRule
    responsibleUserRule
    customFields {
      hash
      nodes {
        code
        heading
      }
    }
  }
`;
