import { get, zipObject } from 'lodash';

export const bootstrapOptionSizes = ['xsmall', 'small', 'medium', 'large'];
export const bootstrapOptionSizeMappings = ['xs', 'sm', 'md', 'lg'];

export const bootstrapSizeMappings = zipObject(bootstrapOptionSizes, bootstrapOptionSizeMappings);

export const bootstrapBtnSize = (size) => {
  const btnSize = get(bootstrapSizeMappings, size, null);
  if (btnSize === null) return '';
  return `btn-${btnSize}`;
};
