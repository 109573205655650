import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';

export const siteMailZipCodeFieldConfig = siteFieldConfig(
  'mailZip',
  ['mailAddress.id', 'mailAddress.postalCode'],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Postal_code),
    additionalTableConfig: {
      width: 100
    },
    render: record => record.mailAddress?.postalCode,
  })
);
