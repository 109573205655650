import React from 'react';
import UrlTabs from '../adminComponents/UrlTabs/UrlTabs';
import { usePermission } from '../../../permission/usePermission';
import { EntitiesSearch } from '../../../components/entitiesSearch';
// eslint-disable-next-line import/no-named-as-default
import CreateUser from './CreateUser';
import { EntityTypeEnum } from '../../../../gql/typings';

const UsersView: React.FC = () => {
  const { read: roles, loading: l1 } = usePermission('RolePermission');
  const { read: users, create: createUsers, loading: l2 } = usePermission('UserControl');

  return (
    <UrlTabs
      path="users"
      loading={l1 || l2}
      items={[
        users && {
          path: 'list',
          title: 'Users',
          render: () => (
            <EntitiesSearch
              entityType={EntityTypeEnum.USER}
              includeInactiveRecords={false}
              criteriaFilter={{
                isLocked: null,
                isHidden: null,
              }}
            />
          ),
        },
        createUsers && {
          path: 'create',
          title: 'Create User',
          render: () => <CreateUser />,
        },
        roles && {
          path: 'roles',
          title: 'Roles & Permissions',
          render: () => <EntitiesSearch
            entityType={EntityTypeEnum.ROLE}
            includeInactiveRecords={false}
          />,
        }
      ]}
    />
  );
};


export default UsersView;
