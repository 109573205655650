import React, { useMemo, useState } from 'react';
import { Form, FormInstance } from 'antd';
import { useLocalization } from '../../../util/useLocalization';
import {
  EntityTypeEnum,
} from '../../../../gql/typings';
import { useTableRowSelection } from '../../../components/Table/useTableRowSelection';
import { TableActionItem } from '../../../components/Table/Actions';
import { Locale } from '../../../../localization/LocalizationKeys';
import MassAssign from '../../massAssign/MassAssign';
import { EntitiesSearchQuickActionItem } from '../../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import { EntityTableQuickActions } from '../../person/hooks/usePersonTableQuickActions';

export const useLinkTableQuickActions: EntityTableQuickActions = (form: FormInstance = Form.useForm()[0]) => {
  const localization = useLocalization();
  const showMassassignState = useState(false);

  const rowSelectionProps = useMemo(() => {
    if (!form) return { entityType: EntityTypeEnum.AFFILIATION };
    return { form };
  }, [form]);
  const tableSelection = useTableRowSelection({
    ...rowSelectionProps,
    maintainPageState: { entityType: EntityTypeEnum.AFFILIATION } });

  const actions: Array<boolean | TableActionItem> = [
    {
      key: 'mass-assign',
      hide: false,
      label: localization.formatMessage(Locale.Attribute.Mass_assign),
      onClick: () => showMassassignState[1](true),
      disabled: tableSelection.selection.count === 0,
    }
  ];

  return {
    HiddenRender: () => (
      <>
        <MassAssign
          entityType={EntityTypeEnum.AFFILIATION}
          visibleState={showMassassignState}
          selection={tableSelection.selection}
        />
      </>
    ),
    tableSelection,
    entitiesSearchProps: {
      actions: actions.filter(e => e) as EntitiesSearchQuickActionItem[]
    }
  };
};
