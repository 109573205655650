import React, { memo } from 'react';
import { Skeleton } from 'antd';
import { TableFieldReturned, TableFieldReturnedRecordOptions } from '../../browse/search_old/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FieldLabelValueProps<T = any> = {
  isEditing: boolean;
  render: TableFieldReturned<T>['render'];
  preserve?: boolean;
  record: T;
  options: TableFieldReturnedRecordOptions;
};

const FieldLabelValue: React.FC<FieldLabelValueProps> = memo(({
  isEditing,
  record,
  preserve,
  render,
  children,
  options,
}) => {
  if (!record) return <Skeleton.Input active size="small" block />;
  return (
    <span style={{ flex: 1, width: 'calc(100% - 20px)' }}>
      {isEditing && children}
      {(!isEditing || preserve) && <span className="value">{render?.(record, options) || ''}</span>}
    </span>
  );
});

export default FieldLabelValue;
