import { gql } from '@apollo/client';
import { Locale } from '../../../localization/LocalizationKeys';
import { TableConfig } from '../search_old/types';
import { supportedSelectionRowResultTableColumns } from './supportedSelectionRowResultTableColumns';


export const buildSelectionRowResultTableFragment = (additionalQuery: string|null) => gql`
  fragment SelectionRowResultTableBuiltFragment on SelectionRowResult {
    id
    ${additionalQuery || ''}
  }
`;

export const buildSelectionRowResultConnectionDataQuery = (additionalQuery: string|null) => gql`
  query SelectionRowResultTableQuery($criteria: SelectionRowResultCriteria) {
    connection: selectionRowResults(criteria: $criteria) {
      hash
      totalCount
      nodes { ...SelectionRowResultTableBuiltFragment }
    }
  }
  ${buildSelectionRowResultTableFragment(additionalQuery)}
`;

export const buildSelectionRowResultRecordDataQuery = (additionalQuery: string|null) => gql`
  query SelectionRowResultRecordQuery($recordId: ID!) {
    record: selectionRowResult(id: $recordId) {
      id
      ...SelectionRowResultTableBuiltFragment
    }
  }
  ${buildSelectionRowResultTableFragment(additionalQuery)}
`;

export const SelectionRowResultTableConfig: TableConfig = {
  buildConnectionQuery: buildSelectionRowResultConnectionDataQuery,
  buildRecordQuery: buildSelectionRowResultRecordDataQuery,
  buildFragment: buildSelectionRowResultTableFragment,
  titleLabel: Locale.Attribute.Selections,
  columnConfig: {
    fields: supportedSelectionRowResultTableColumns,
  },
};
