import React from 'react';

type DetailsPageSectionProps = {
  heading: string;
  right?: React.ReactNode;
  className?: string;
};

const DetailsPageSection: React.FC<DetailsPageSectionProps> = ({
  heading,
  right,
  className,
  children,
}) => (
  <>
    <div className="details-page-section-container">
      <div className="left">{heading}</div>
      {right && <div className="right">{right}</div>}
    </div>
    <div className={className}>
      {children}
    </div>
  </>
);

export default DetailsPageSection;
