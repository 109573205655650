import React, { useState } from 'react';
import { Steps, Row, Col, Table, Divider } from 'antd';
import { useIntl } from 'react-intl';
import { Locale } from '../../../../localization/LocalizationKeys';
import { ImportTemplatesQuery } from '../../../../gql/typings';


type VisualizeTemplateProps = {
  template: NonNullable<ImportTemplatesQuery['importTemplates']>['nodes']['0'];
};

type SelectedItemType = NonNullable<ImportTemplatesQuery['importTemplates']>['nodes']['0']['processes']['nodes']['0']|undefined;

const VisualizeTemplate: React.FC<VisualizeTemplateProps> = ({ template }) => {
  const intl = useIntl();
  const [selected, setSelected] = useState(0);

  const selectedItem: SelectedItemType = template.processes.nodes[selected];

  if (!selectedItem) return <span>Loading...</span>;

  return (
    <div>
      <Row>
        <Col md={4} sm={24}>
          <h2>
            {intl.formatMessage(Locale.Command.Import_steps)}
          </h2>
        </Col>
        <Col md={20} sm={24}>
          <h2>Description</h2>
          <p>{template.note}</p>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col md={4} sm={8} xs={12}>
          <Steps
            current={selected}
            direction="vertical"
            labelPlacement="vertical"
            progressDot
            onChange={setSelected}
            items={template.processes.nodes.map((p, index) => (
              {
                title: `${index + 1}. ${p.heading}`
              }
            ))}
          />
        </Col>
        <Col md={20} sm={16} xs={12}>
          <h1>{selectedItem.heading}</h1>
          {selectedItem.note?.split('\\n').map((p, index) => <p key={index}>{p}</p>)}
          <Table
            dataSource={selectedItem.fields.nodes}
            pagination={false}
            rowKey="id"
            columns={[
              {
                title: intl.formatMessage(Locale.General.Column_index),
                dataIndex: 'lineDataIndex',
              },
              {
                title: intl.formatMessage(Locale.General.Column_name),
                dataIndex: ['field', 'code'],
              },
              {
                title: intl.formatMessage(Locale.Attribute.Description),
                dataIndex: ['field', 'note'],
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default VisualizeTemplate;
