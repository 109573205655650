import { Key, ReactNode, useEffect, useMemo, useState } from 'react';
import { FormInstance } from 'antd/lib/form';
import { RowSelectionType, SelectionItem, TableRowSelection } from 'antd/lib/table/interface';
import { isArray, isBoolean, toNumber, uniq } from 'lodash';
import { gql, useApolloClient } from '@apollo/client';
import criteriaBuilder from '../../browse/search_old/types/criteriaBuilder';
import { Locale } from '../../../localization/LocalizationKeys';
import { defaultPageNumber, defaultPerPage } from './utils';
import { EntitiesSearchProps } from '../entitiesSearch';
import { useLocalization } from '../../util/useLocalization';
import { Optional, StateArray } from '../../util/StateArrayType';
import {
  DynamicCriteriaInput, EntityTypeEnum,
  GetCriteriaDefinitionQuery,
  GetCriteriaDefinitionQueryVariables, SelectionCriteriaInput,
  SelectionCriteriaStyle
} from '../../../gql/typings';
import { isActualNumber } from '../../util/Util';
import { useBroadcastStorage } from '../../util/useBroadcastStorage';
import { usePageState } from '../../util/usePageState';

export type TableRowSelectionTableProps = {
  onVisibleKeysChange: Required<EntitiesSearchProps>['onVisibleKeysChange'];
  pageState: StateArray<number>;
  perPageState: StateArray<number>;
  onDataChange: Required<EntitiesSearchProps>['onDataChange'];
  rowSelection: TableRowSelection<{ id: number|string }> & {
    selectedLabel?: ReactNode;
  };
};

export type TableRowSelectionReturnProps = {
  selection: SelectionCriteriaInput;
  tableProps: TableRowSelectionTableProps;

  addToSelected: (key: Key|Key[]) => void;
  removeFromSelected: (key: Key|Key[]) => void;
  clearState: () => void;
  onSelectedCountriesChange: (selectedCountries: string[]|undefined) => void;
};

export type UseTableRowSelectionArgsType = {
  form?: FormInstance;
  listId?: number;
  entityType?: EntityTypeEnum;
  initialSelection?: Optional<SelectionCriteriaInput>;

  /**
   *
   */
  virtualSupport?: false;
  type?: RowSelectionType;
  maintainPageState?: {
    entityType: EntityTypeEnum;
  };
};

// TODO: For performance, I would like this to use context information instead of being in the root re-render tree
export const useTableRowSelection = (args?: UseTableRowSelectionArgsType): TableRowSelectionReturnProps => {
  const localization = useLocalization();
  const apolloClient = useApolloClient();
  const [selectionStyle, setSelectionStyle] = useState<SelectionCriteriaStyle>(SelectionCriteriaStyle.STANDARD);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [excludedRowIds, setExcludedRowIds] = useState<number[]>([]);
  const [visibleKeys, setVisibleKeys] = useState<number[]>([]);
  const [currentTotalCount, setCurrentTotalCount] = useState(0);
  const [selectionCriterias, setSelectionCriterias] = useState<DynamicCriteriaInput[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<string[]>();
  const pageState = (args && args?.maintainPageState)
    ? usePageState(args.maintainPageState.entityType) : useState(defaultPageNumber);
  const perPageState = useBroadcastStorage<number>('per-page', defaultPerPage);
  const [page] = pageState;
  const [perPage] = perPageState;

  const hasVirtualSupport = !isBoolean(args?.virtualSupport) || args?.virtualSupport;


  useEffect(() => {
    if (args?.listId && args.entityType) {
      apolloClient.query<GetCriteriaDefinitionQuery, GetCriteriaDefinitionQueryVariables>({
        query: GET_CRITERIA_DEF,
        variables: { code: 'APB_LIST', entityType: args.entityType },
      }).then(res => {
        if (res.data.criteriaByCode?.inputPaths.nodes.length === 1) {
          setSelectionCriterias([{
            criteriaId: res.data.criteriaByCode.id,
            value: {
              pathId: res.data.criteriaByCode.inputPaths.nodes[0]!.id,
              values: [
                { value: `${args.listId}` }
              ]
            }
          }]);
        } else throw Error("Couldn't find criteria by code");
      });
    }
  }, [apolloClient, args?.listId, args?.entityType]);

  useEffect(() => {
    if (args?.initialSelection) {
      const init = args.initialSelection;
      if (init.criterias !== selectionCriterias) setSelectionCriterias(init.criterias);
      if (init.excluded !== excludedRowIds) setExcludedRowIds(init.excluded);
      if (init.selected !== selectedRowIds) setSelectedRowIds(init.selected);
      if (init.style !== selectionStyle) setSelectionStyle(init.style);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addToSelected = (k: Key|Key[]) => {
    const keys = isArray(k)
      ? k.map(toNumber)
      : [toNumber(k)];
    setSelectedRowIds(uniq([...selectedRowIds, ...keys]));
  };

  const removeFromSelected = (k: Key|Key[]) => {
    const keys = isArray(k)
      ? k.map(toNumber)
      : [toNumber(k)];
    setSelectedRowIds(selectedRowIds.filter(id => !keys.includes(id)));
  };

  const onChange = (k: Key, checked: boolean) => {
    const key = toNumber(k);

    if (args?.type === 'radio') {
      setSelectedRowIds(checked ? [key] : []);
      return;
    }

    switch (selectionStyle) {
      case SelectionCriteriaStyle.STANDARD:
        if (checked) addToSelected(k);
        else removeFromSelected(k);
        break;
      case SelectionCriteriaStyle.ALL:
        if (checked) setExcludedRowIds(excludedRowIds.filter(id => id !== key));
        else setExcludedRowIds([...excludedRowIds, toNumber(k)]);
        break;
      case SelectionCriteriaStyle.ONLY_EVEN:
      case SelectionCriteriaStyle.ONLY_ODD:
        // eslint-disable-next-line no-case-declarations
        const didSelectSelectionStyleIndex = selectionStyle === SelectionCriteriaStyle.ONLY_EVEN
          ? visibleKeys.findIndex(id => id === k) % 2 === 1
          : visibleKeys.findIndex(id => id === k) % 2 === 0;
        if (didSelectSelectionStyleIndex && checked) setExcludedRowIds(excludedRowIds.filter(id => id !== key));
        else if (didSelectSelectionStyleIndex && !checked) setExcludedRowIds([...excludedRowIds, key]);
        else if (!didSelectSelectionStyleIndex && checked) addToSelected(k);
        else if (!didSelectSelectionStyleIndex && !checked) removeFromSelected(k);
        break;
      default:
        throw Error(`Currently not supported - ${selectionStyle} - ${k}`);
    }
  };

  const onSelectAll = (checked: boolean) => {
    switch (selectionStyle) {
      case SelectionCriteriaStyle.STANDARD:
        if (checked) setSelectedRowIds(uniq([...selectedRowIds, ...visibleKeys]));
        else setSelectedRowIds(selectedRowIds.filter(id => !visibleKeys.includes(id)));
        break;
      case SelectionCriteriaStyle.ALL:
        if (checked) setExcludedRowIds([]);
        else {
          setSelectionStyle(SelectionCriteriaStyle.STANDARD);
          setExcludedRowIds([]);
        }
        break;
      default:
    }
  };

  const clearState = () => {
    setSelectedRowIds([]);
    setExcludedRowIds([]);
  };

  const SelectionTypes = [
    hasVirtualSupport && {
      key: 'all',
      text: 'Select All Results',
      onSelect: () => {
        setSelectionStyle(SelectionCriteriaStyle.ALL);
        clearState();
      },
    } as SelectionItem,
    hasVirtualSupport && {
      key: 'only_even',
      text: 'Even',
      onSelect: () => {
        setSelectionStyle(SelectionCriteriaStyle.ONLY_EVEN);
        clearState();
      },
    } as SelectionItem,
    hasVirtualSupport && {
      key: 'only_odd',
      text: 'Odd',
      onSelect: () => {
        setSelectionStyle(SelectionCriteriaStyle.ONLY_ODD);
        clearState();
      },
    } as SelectionItem,
    {
      key: 'clear_selection',
      text: 'Clear Selection',
      onSelect: () => {
        setSelectionStyle(SelectionCriteriaStyle.STANDARD);
        clearState();
      },
    } as SelectionItem,
  ].filter(e => e) as SelectionItem[];

  let getSelectedKeys: Key[] = [];
  const startEven: boolean = ((page - 1) * perPage) % 2 === 0;
  switch (selectionStyle) {
    case SelectionCriteriaStyle.STANDARD:
      getSelectedKeys = selectedRowIds;
      break;
    case SelectionCriteriaStyle.ALL:
      getSelectedKeys = visibleKeys.filter(id => !excludedRowIds.includes(id));
      break;
    case SelectionCriteriaStyle.ONLY_ODD:
      getSelectedKeys = visibleKeys.filter((id, index) => {
        if (excludedRowIds.includes(id)) return false;
        if (selectedRowIds.includes(id)) return true;
        return index % 2 !== (startEven ? 1 : 0);
      });
      break;
    case SelectionCriteriaStyle.ONLY_EVEN:
      getSelectedKeys = visibleKeys.filter((id, index) => {
        if (excludedRowIds.includes(id)) return false;
        if (selectedRowIds.includes(id)) return true;
        return index % 2 !== (startEven ? 0 : 1);
      });
      break;
    default:
      getSelectedKeys = [];
  }

  const currentSelected = getSelectedKeys.length;
  let count = 0;
  switch (selectionStyle) {
    case SelectionCriteriaStyle.STANDARD:
      count = selectedRowIds.length;
      break;
    case SelectionCriteriaStyle.ALL:
      count = currentTotalCount - excludedRowIds.length;
      break;
    case SelectionCriteriaStyle.ONLY_ODD:
      count = Math.floor(currentTotalCount / 2) + (currentTotalCount % 2);
      count += selectedRowIds.length;
      count -= excludedRowIds.length;
      break;
    case SelectionCriteriaStyle.ONLY_EVEN:
      count = Math.floor(currentTotalCount / 2);
      count += selectedRowIds.length;
      count -= excludedRowIds.length;
      break;
    default:
  }
  const getSelectedCount = currentSelected > 0 ? count : undefined;

  let getCriterias: DynamicCriteriaInput[] = [];
  if (!args?.form) getCriterias = selectionCriterias;
  else getCriterias = criteriaBuilder({
    searchFilter: args?.form.getFieldsValue(),
  }).criteria.criterias ?? [];


  return useMemo(() => ({
    addToSelected,
    removeFromSelected,
    clearState,
    onSelectedCountriesChange: setSelectedCountries,
    tableProps: {
      pageState,
      perPageState,
      onVisibleKeysChange: keys => {
        if (keys.filter((key) => !isActualNumber(key)).length === 0) {
          // Only take this change into action, if the values are actually not the same.
          if (JSON.stringify(keys) !== JSON.stringify(visibleKeys)) {
            setVisibleKeys(keys.map(toNumber));
          }
        }
      },
      onDataChange: data => setCurrentTotalCount(data.totalCount ?? 0),
      rowSelection: {
        type: args?.type ?? 'checkbox',
        onChange: () => {}, // We do not want to do anything on 'onChange'
        onSelect: (r, checked) => onChange(r.id, checked),
        preserveSelectedRowKeys: true,
        selectedRowKeys: getSelectedKeys,
        selections: SelectionTypes,
        onSelectAll,
        selectedLabel: getSelectedCount && args?.type !== 'radio' ? localization.pluralMessage(
          Locale.General.Search_results_selected,
          getSelectedCount ?? 0,
        ) : ''
      },
    },
    selection: {
      style: selectionStyle,
      criterias: getCriterias,
      count: getSelectedCount ?? 0,
      selected: selectedRowIds,
      excluded: excludedRowIds,
      countries: selectedCountries,
    },
  }),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    selectionStyle,
    selectedRowIds,
    excludedRowIds,
    visibleKeys,
    currentTotalCount,
    selectionCriterias,
    page,
    perPage,
  ]);
};

const GET_CRITERIA_DEF = gql`
  query GetCriteriaDefinition($entityType: EntityTypeEnum!, $code: String!) {
    criteriaByCode(entityType: $entityType, code: $code) {
      id
      inputPaths {
        hash
        nodes {
          id
        }
      }
    }
  }
`;
