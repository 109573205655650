import { reduce, isObject, keys, toString } from 'lodash';
import { b64DecodeUnicode } from '../util/Util';
import { Optional } from '../util/StateArrayType';

export const decodeJWT = (token: string) => {
  const firstIndex = token.indexOf('.');
  const lastIndex = token.lastIndexOf('.');
  const toDecode = token.substring(firstIndex + 1, lastIndex);
  const decoded = b64DecodeUnicode(toDecode);
  return JSON.parse(decoded);
};

export const POLL_FAST = 750;
export const POLL_NORMAL = 1750;
export const POLL_SLOW = 3500;

/**
 * This calculation should always match to on in [calculateHashValues:Extensions.kt]
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const calculateCriteriaHash = (obj: any): number => reduce(keys(obj), (sum, key) => {
  // Recursively calculate nested objects
  if (isObject(obj[key])) return sum + calculateCriteriaHash(obj[key]);

  let hash = 0;
  const stringified = toString(obj[key]);
  for (let i = 0; i < stringified.length; i++) {
    const character = stringified.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (i + 1) * (((hash << 5) - hash) + character);
  }
  return sum + hash;
}, 0);

export const stringToAbsHash = (input: Optional<string>): number => {
  if (!input) return 0;
  let h = 0;
  let i = 0;
  const l = input.length;
  if (l > 0) {
    while (i < l) {
      // eslint-disable-next-line no-bitwise
      h = (h << 5) - h + input.charCodeAt(i++) | 0;
    }
  }
  return Math.abs(h);
};
