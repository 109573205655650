import React, { useEffect, useRef, useState } from 'react';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { isEqual, max, range } from 'lodash';
import { Card, message, Skeleton } from 'antd';
import { useIntl } from 'react-intl';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useSize } from 'ahooks';
import DashboardItem from '../../../components/DashboardItem/DashboardItem';
import { Locale } from '../../../../localization/LocalizationKeys';
import DashboardGridTopActions from './DashboardGridTopActions';
import { DashboardItem_FRAGMENT } from '../../../components/DashboardItem/DashboardItemTypes';
import {
  DashboardItemsQueryQuery, DashboardItemsQueryQueryVariables,
  UpdateGridPositionsMutationMutation,
  UpdateGridPositionsMutationMutationVariables
} from '../../../../gql/typings';


/**
 * This can be provided as a className to other elements anywhere within the children's tree
 */
export const dashboardGridViewDraggableCancelClassName = 'grid-drag-cancel';

const ResponsiveGridLayout = WidthProvider(Responsive);

const DashboardGridView: React.FC<{ dashboardId: number; totalItemsCount: number }> = React.memo(({
  dashboardId,
  totalItemsCount,
}) => {
  const { formatMessage } = useIntl();
  const [editMode, setEditMode] = useState(false);
  const [recentlyCreated, setRecentlyCreated] = useState(0);
  const [layouts, setLayouts] = useState<Layout[]>(range(totalItemsCount).map(index => ({
    i: `-${index}`,
    x: 0,
    y: 0,
    w: 3,
    h: 2,
  })));
  const [
    updateDashboardItems,
  ] = useMutation<UpdateGridPositionsMutationMutation, UpdateGridPositionsMutationMutationVariables>(updateGridPositions);
  const {
    data,
    loading,
  } = useQuery<DashboardItemsQueryQuery, DashboardItemsQueryQueryVariables>(DATA_QUERY, { variables: { dashboardId } });


  useEffect(() => {
    setLayouts((data?.dashboard?.dashboardItemConfigurations.nodes ?? []).map(item => ({
      i: `${item.id}`,
      x: item.x ?? 0,
      y: item.y ?? 0,
      h: item.height ?? 1,
      w: item.width ?? 1,
      static: false,
      moved: false,
    })));
  }, [data, setLayouts]);

  const onSaveUpdates = () => {
    if (!layouts) return;
    const input = {
      dashboardId,
      gridItems: layouts
    };
    setEditMode(false);
    updateDashboardItems({
      variables: { input },
    })
      .finally(() => message.success(formatMessage(Locale.Text.Update_Grid_Success)));
  };

  return (
    <div className="dashboard-grid-view-container">
      {data?.dashboard && <DashboardGridTopActions
        onSave={onSaveUpdates}
        editModeState={[editMode, setEditMode]}
        recentlyCreatedState={[recentlyCreated, setRecentlyCreated]}
        dashboard={data.dashboard}
        layoutState={[layouts, setLayouts]}
      />}
      <Skeleton active={loading} loading={loading}><span /></Skeleton>
      <ResponsiveGridLayout
        className="layout"
        compactType="horizontal"
        draggableCancel={`.${dashboardGridViewDraggableCancelClassName}`}
        layouts={{ lg: layouts }}
        margin={[9, 14]}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        onLayoutChange={(l) => editMode && !isEqual(l, layouts) && setLayouts(l)}
        isDraggable={editMode}
        isResizable={editMode}
      >
        {layouts.map(item => (
          <div key={item.i}>
            <RenderLayoutItem
              item={item}
              data={data}
              dashboardId={dashboardId}
              isEditable={editMode}
              recentlyCreatedId={recentlyCreated}
            />
          </div>
        ))}
      </ResponsiveGridLayout>
    </div>
  );
}, isEqual); // 👍 Make sure that no useless re-rendering is happening

const RenderLayoutItem: React.FC<{
  item: Layout;
  dashboardId: number;
  data: DashboardItemsQueryQuery|undefined;
  isEditable: boolean;
  recentlyCreatedId: number;
}> = React.memo(({ item, dashboardId, data, isEditable, recentlyCreatedId }) => {
  const ref = useRef<HTMLDivElement>(null);
  const divSize = useSize(ref);
  const conf: NonNullable<DashboardItemsQueryQuery['dashboard']>['dashboardItemConfigurations']['nodes']['0']|undefined = data
    ?.dashboard?.dashboardItemConfigurations.nodes.filter(n => `${n.id}` === item.i)[0];

  return (
    <div key={item.i} ref={ref} style={{ width: '100%', height: '100%' }}>
      {conf && <DashboardItem
        dashboardItemId={conf.id}
        dashboardId={dashboardId}
        recentlyCreatedId={recentlyCreatedId}
        configuration={conf}
        editStatus={isEditable}
        size={{
          // card item has a padding of 24
          // header is 56
          width: max([0, divSize ? (divSize.width - 48) : 0])!,
          height: max([0, divSize ? (divSize.height - 104) : 0])!,
        }}
      />}
      {!conf && <Card loading style={{ height: '100%', width: '100%' }} />}
    </div>
  );
}, isEqual);

const DATA_QUERY = gql`
  query DashboardItemsQuery($dashboardId: Int!) {
    dashboard(id: $dashboardId) {
      id
      isDefaultDashboard
      dashboardItemConfigurations {
        hash
        nodes {
          id
          x
          y
          height
          width
          ...DashboardItemFragment
        }
      }
    }
  }
  ${DashboardItem_FRAGMENT}
`;

const updateGridPositions = gql`
  mutation UpdateGridPositionsMutation($input: DashboardItemConfigurationInput!) {
    updateDashboardItems(input: $input) {
      id
    }
  }
`;

export default DashboardGridView;
