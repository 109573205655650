import { useState, useEffect, useCallback } from 'react';
// @ts-ignore
import browserPubSub from 'offline-browser-pub-sub';
import { isFunction } from 'lodash';
import { StateArray } from './StateArrayType';

export function useBroadcastStorage<T>(
  key: string,
  defaultValue: T,
  initialFilter?: (storageValue: T) => T,
): StateArray<T> {
  const localStorageData = window.localStorage.getItem(`live-${key}`);
  const initialValue = localStorageData ? JSON.parse(localStorageData).message : undefined;
  const [value, setValue] = useState(initialValue || defaultValue);

  useEffect(() => {
    if (localStorageData) {
      if (initialValue !== value) {
        if (initialFilter) setValue(initialFilter(initialValue));
        else setValue(initialValue);
      }
    }
    const token = browserPubSub.subscribe(`live-${key}`, (message: T) => {
      setValue(message);
    });
    return () => {
      browserPubSub.unsubscribe(token);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return [
    value,
    useCallback((newValue: T|((oldValue: T) => T)) => {
      const finalValue = isFunction(newValue) ? newValue(value) : newValue;
      return browserPubSub.publish(`live-${key}`, finalValue);
    }, [value, key]),
  ];
}

