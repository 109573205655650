import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';

export const personOriginSourceFieldConfig = personFieldConfig(
  'originSource',
  [
    'originSource.code',
    'originSource.heading',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Origin_Source),
    additionalTableConfig: {
      width: 100
    },
    render: (record) => record.originSource.heading,
  })
);

