import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { DatePicker, Form, InputNumber, Typography } from 'antd';
import dayjs from 'dayjs';
import { useLocalization } from '../../../../util/useLocalization';
import { FlagCountry } from '../../../../components/Flag/Flag';
import CountryPicker from '../../../../components/Input/Fields/CountryPicker';
import { MonthTextResourceByMonthValue, MonthValue } from '../../../../components/Date/DateUtils';
import { Locale } from '../../../../../localization/LocalizationKeys';
import {
  ConfigureFieldDaysQueryQuery,
  ConfigureFieldDaysQueryQueryVariables,
  UpdateFieldDaysMutationMutation, UpdateFieldDaysMutationMutationVariables
} from '../../../../../gql/typings';


const ConfigureFieldDays: React.FC = () => {
  const localization = useLocalization();
  const [selectedCountry, setSelectedCountry] = useState<FlagCountry>();
  const [selectedYear, setSelectedYear] = useState<number>();
  const {
    data,
  } = useQuery<ConfigureFieldDaysQueryQuery, ConfigureFieldDaysQueryQueryVariables>(DATA_QUERY, {
    skip: !selectedCountry || !selectedYear,
    variables: {
      country: selectedCountry,
      year: selectedYear,
    }
  });
  const [update] = useMutation<UpdateFieldDaysMutationMutation, UpdateFieldDaysMutationMutationVariables>(UPDATE_MUTATION);

  return (
    <div>
      <Typography.Paragraph>
        {localization.formatMessage(Locale.Text.Configure_field_days_description)}
      </Typography.Paragraph>
      <CountryPicker
        onChange={e => setSelectedCountry(e as FlagCountry)}
        value={selectedCountry}
      />
      <DatePicker
        picker="year"
        value={selectedYear ? dayjs().set('year', selectedYear) : undefined}
        onChange={selected => setSelectedYear(selected?.get('year'))}
      />
      <br />
      {(data?.activityFieldDaysInYear ?? []).length === 0 && <i>
        {localization.formatMessage(Locale.Command.Select_country_and_year)}
      </i>}
      <Form
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        labelAlign="left"
      >
        {(data?.activityFieldDaysInYear ?? []).map(monthSetting => (
          <Form.Item
            key={monthSetting.id}
            label={localization.formatMessage(MonthTextResourceByMonthValue[monthSetting.month as MonthValue])}
            style={{ marginBottom: 0, marginTop: 8 }}
          >
            <InputNumber
              value={monthSetting.count}
              min={1}
              max={31}
              onChange={count => update({
                variables: {
                  country: monthSetting.country,
                  year: monthSetting.year,
                  month: monthSetting.month,
                  count: count as number,
                }
              })}
            />
          </Form.Item>
        ))}
      </Form>
    </div>
  );
};

const DATA_QUERY = gql`
  query ConfigureFieldDaysQuery($country: CountryCode!, $year: Year!) {
    activityFieldDaysInYear(country: $country, year: $year) {
      id
      country
      month
      year
      count
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateFieldDaysMutation($country: CountryCode!, $year: Year!, $month: Month!, $count: Int!) {
    updateActivityFieldDaysInMonth(country: $country, year: $year, month: $month, count: $count) {
      id
      count
    }
  }
`;

export default ConfigureFieldDays;
