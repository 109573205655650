import React, { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { NavLink, useLocation } from 'react-router-dom';
import { EditOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Collapse, Menu, Popover, Row, Skeleton, Tooltip } from 'antd';
import { kebabCase } from 'lodash';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Icon, { ITE } from '../../components/Icon/Icon';
import { AppContext } from '../AppTypes';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';
import GlowButton from '../../components/Input/GlowButton';
import NavigationListsFunction from './NavigationListsFunction';
import { MenuItemFragmentFragment, NavigationDataQueryQuery } from '../../../gql/typings';


const Navigation: React.FC<{
  setCollapse: (v: boolean) => void;
}> = ({ setCollapse }) => {
  const { data, loading } = useQuery<NavigationDataQueryQuery>(NAVIGATION_DATA_QUERY);
  const localization = useLocalization();
  const [visible, setVisible] = useState(false);
  const sortListModalState = useState(false);
  const location = useLocation();

  const {
    collapse,
  } = useContext(AppContext);

  const links = [
    {
      key: 2,
      url: '/activity/create',
      class: 'menu-icon',
      icon: <Icon iconType="ACTIVITY" />,
      label: localization.formatMessage(Locale.Command.Create_Activity),
      tooltip: localization.formatMessage(Locale.Text.Create_a_new_activity),
    },
    {
      key: 3,
      url: '/list/create',
      class: 'menu-icon',
      icon: <Icon iconType="LIST" />,
      label: localization.formatMessage(Locale.Command.Create_Static_List),
      tooltip: localization.formatMessage(Locale.Text.Create_a_new_static_list),
    },
    {
      key: 5,
      url: '/hcp/create',
      label: localization.formatMessage(Locale.Command.Create_Person),
      icon: <Icon iconType="PERSON" />,
    },
    {
      key: 6,
      url: '/hco/create',
      label: localization.formatMessage(Locale.Command.Create_Site),
      icon: <Icon iconType="SITE" />,
    },
  ];

  const content: ItemType = {
    key: 'popover-create',
    className: 'popover-create',
    onClick: () => setVisible(!visible),
    label: (
      <Popover
        key="123123"
        placement="right"
        open={visible}
        className="nav-create"
        content={<div>
          <div className="popover-close" onClick={() => setVisible(false)} style={{ float: 'right' }}>
            <Icon
              iconType="CLOSE"
              iconOnly
            /></div>
          <div className="clear" />
          {links.map(l => (
            <div className="sub-navigation" key={l.key}>
              <Row>
                <Col span={4}>
                  <i>
                    {l.icon}
                  </i>
                </Col>
                <Col span={20}>
                  <NavLink className="menu-label" id={`nav-${kebabCase(l.label)}`} to={l.url}>
                    {l.label}
                  </NavLink>
                </Col>
              </Row>
            </div>
          ))}
        </div>}
      >
        <i className="menu-icon">
          <GlowButton
            size="x-small"
            type="primary"
            label={localization.formatMessage(Locale.Attribute.Overview_create_new_item)}
          />
        </i>
        {!collapse[0] && (
          <span className="overview-label-btn">{localization.formatMessage(Locale.Attribute.Overview_create_new_item)}</span>
        )}
      </Popover>
    )
  };

  const generateMenu = (items: MenuItemFragmentFragment[], includeCreate: boolean) => (
    <div style={{ margin: 8 }}>
      <Skeleton active={loading} loading={loading} paragraph={{ rows: 8, width: 0 }}>
        <Menu
          style={{ paddingLeft: 8 }}
          selectedKeys={items.filter(menu => location.pathname.indexOf(menu.url) > -1).map(m => m.code)}
          items={[
            ...(includeCreate ? [content] : []),
            ...items.map(menu => ({
              key: menu.code,
              label: (
                <Tooltip
                  placement="right"
                  mouseEnterDelay={1.5}
                  title={menu.tooltipKey ? localization.formatMessageByStr(menu.tooltipKey) : ''}
                >
                  <NavLink id={`nav-${kebabCase(menu.url)}`} to={menu.url}>
                    <i className="menu-icon">
                      {menu.iconKey && <Icon
                        style={{ fontSize: '20px' }}
                        iconType={menu.iconKey as ITE}
                        iconOnly
                      />}
                    </i>
                    {!collapse[0] && (
                      <span className="menu-label">
                        {localization.formatMessageByStr(menu.headingKey)}
                      </span>
                    )}
                  </NavLink>
                </Tooltip>
              ),
            }))]}
        />
      </Skeleton>
    </div>
  );

  return (
    <div>
      <section className={`navigation ${collapse[0] ? 'collapse' : ''}`}>
        <div className={`collapse-button-container ${collapse[0] ? 'collapse' : 'open'}`}>
          <span 
            className="toggle-collapse" 
            onClick={() => {
              const toSet = !collapse[0];  
              localStorage.setItem('isCollapse', toSet.toString());
              setCollapse(toSet);
            }}
          >
            {collapse[0]
              ? <RightOutlined />
              : (
                <span>
                  <LeftOutlined />
                  &nbsp;
                  {localization.formatMessage(Locale.Command.Close_Menu)}
                </span>
              )}
          </span>
        </div>

        <Collapse
          style={{ border: 'none', backgroundColor: 'white' }}
          expandIconPosition="end"
          ghost
          defaultActiveKey={['items', 'functions', 'lists']}
          key={1}
        >
          <Collapse.Panel
            key="items"
            header={localization.formatMessage(Locale.General.Menu_items)}
          >
            {generateMenu(data?.viewer?.menuItems.nodes ?? [], true)}
          </Collapse.Panel>
          <Collapse.Panel
            key="functions"
            header={localization.formatMessage(Locale.General.Functions)}
          >
            {generateMenu(data?.viewer?.functionMenuItems.nodes ?? [], false)}
          </Collapse.Panel>


          {/* {data?.menuGroups.nodes.map(group => <Collapse.Panel */}
          {/*  key={group.id} */}
          {/*  className={collapse ? 'panel-collapse' : ''} */}
          {/*  header={group.groupName} */}
          {/* > */}
          {/*  <Menu */}
          {/*    mode="inline" */}
          {/*    inlineCollapsed={collapse} */}
          {/*    className={`navigation_menu ${group.id === 1 ? 'primary' : 'secondary'}`} */}
          {/*    selectedKeys={(group.menuItems ?? []) */}
          {/*      .filter(item => location.pathname.includes(item.entity?.url ?? '')) */}
          {/*      .map(item => `${item.id}`) as string[]} */}
          {/*    items={[ */}
          {/*      content, */}
          {/*      ...(group?.menuItems ?? []).map(x => ({ */}
          {/*        key: x.id, */}
          {/*        label: ( */}
          {/*          <Tooltip */}
          {/*            placement="right" */}
          {/*            mouseEnterDelay={1.5} */}
          {/*            title={localization.formatMessageByStr(x.entity ? x.entity.tooltip : '')} */}
          {/*          > */}
          {/*            <NavLink id={`nav-${kebabCase(x.entity?.url)}`} to={x.entity ? x.entity.url : ''}> */}
          {/*              <i className="menu-icon"> */}
          {/*                {x.entity && <Icon */}
          {/*                  style={{ fontSize: '20px' }} */}
          {/*                  className={x.entity.heading} */}
          {/*                  iconType={icons(x.entity.code)} */}
          {/*                />} */}
          {/*              </i> */}
          {/*              {!collapse && ( */}
          {/*                <span className="menu-label"> */}
          {/*                  {localization.formatMessageByStr(x.entity ? x.entity.heading : '')} */}
          {/*                </span> */}
          {/*              )} */}
          {/*            </NavLink> */}
          {/*          </Tooltip> */}
          {/*        ), */}
          {/*      })), */}
          {/*    ]} */}
          {/*  /> */}
          {/* </Collapse.Panel>)} */}
          <Collapse.Panel
            key="lists"
            header={<span>
              {localization.formatMessage(Locale.General.My_lists)}&nbsp;&nbsp;
              {(1 ?? 0) > 1 && <EditOutlined onClick={() => sortListModalState[1](true)} />}
            </span>}
          >
            <NavigationListsFunction sortListModalState={sortListModalState} />
          </Collapse.Panel>

        </Collapse>
      </section>
    </div>
  );
};

export const NAVIGATION_DATA_QUERY = gql`
  query NavigationDataQuery {
    viewer {
      id
      menuItems {
        hash
        nodes { ...MenuItemFragment }
      }
      functionMenuItems {
        hash
        nodes { ...MenuItemFragment }
      }
    }
    person(id:1) {
      id
      sites {
        totalCount
      }
    }
  }
  fragment MenuItemFragment on MenuItem {
    code
    headingKey
    tooltipKey
    iconKey
    url
  }
`;

export default Navigation;
