import React from 'react';
import * as PropTypes from 'prop-types';


const DisplayContent = ({ children }) => (
  <span>
    {children || ''}</span>
);

DisplayContent.propsTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default DisplayContent;
