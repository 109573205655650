import { FormInstance } from 'antd';
import React from 'react';
import MassAssignSelectType from './types/MassAssignSelectType';
import MassAssignInputType from './types/MassAssignInputType';
import MassAssignMultiSelectType from './types/MassAssignMultiSelect';
import { CreateMassAssignData } from './MassAssignProcess';
import { StateArray } from '../../util/StateArrayType';
import MassAssignSwitchType from './types/MassAssignSwitchType';
import MassAssignTagType from './types/MassAssignTagType';
import MassAssignDateType from './types/MassAssignDateType';
import { EntityTypeEnum, MassAssignFieldAction, MassAssignFieldTypeEnum } from '../../../gql/typings';
import MassAssignEntitySearchType from './types/MassAssignEntitySearchType';

export type MassAssignFieldTypesProps = {
  heading: string;
  isHeadingKey: boolean;
  entityType: EntityTypeEnum;
  form: FormInstance<CreateMassAssignData>;
  actionName: string;
  valueSelected: StateArray<boolean>;
  actionSelected: StateArray<MassAssignFieldAction>;
};

export const massAssignFieldTypes: Record<MassAssignFieldTypeEnum, React.FC<MassAssignFieldTypesProps>> = {
  Select: MassAssignMultiSelectType,
  SingleSelect: MassAssignSelectType,
  Text: MassAssignInputType,
  Switch: MassAssignSwitchType,
  Tag: MassAssignTagType,
  Date: MassAssignDateType,
  EntitySearch: MassAssignEntitySearchType
};
