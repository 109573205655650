import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { personFieldConfig } from './utils/personFieldsUtils';
import { EntityTypeEnum } from '../../../../gql/typings';

export const personMainPositionFieldConfig = personFieldConfig(
  'mainPosition',
  [
    'primaryOrFirstAffiliation.id',
    'primaryOrFirstAffiliation.position.code',
    'primaryOrFirstAffiliation.position.type',
    'primaryOrFirstAffiliation.position.displayLabel',
  ],
  ({ localization, openInNewTab }) => ({
    title: localization.formatMessage(Locale.Attribute.Primary_Position),
    additionalTableConfig: {
      width: 100
    },
    render: (record, { isViewingFromPage, isViewingFromEntity }) => {
      const label = record.primaryOrFirstAffiliation?.position?.displayLabel ?? '';
      if (record.primaryOrFirstAffiliation
      && (
        (isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE
        && isViewingFromEntity !== EntityTypeEnum.AFFILIATION)
        || isViewingFromPage === TableFieldReturnedRecordPageType.VIEW_PAGE
      )
      ) {
        return (
          <LinkBadge
            url={`/link/${record.primaryOrFirstAffiliation.id}`}
            label={label}
            openInNewTab={openInNewTab}
          />
        );
      }
      return label;
    },
  })
);

