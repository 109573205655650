import React from 'react';
import { Alert, Col, Row, Space } from 'antd';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';
import Icon from '../../components/Icon/Icon';
import { DcrGroupDetailQuery, DcrResponseTypeEnum } from '../../../gql/typings';

type DcrStatusMessageProps = {
  response: NonNullable<DcrGroupDetailQuery['dcrGroup']>['responses']['nodes']['0'];
  group: DcrGroupDetailQuery['dcrGroup'];
  date: React.ReactNode;
};


const DcrStatusMessage: React.FC<DcrStatusMessageProps> = ({ response, group, date }) => {
  const { formatMessage } = useLocalization();
  const isRejected = response?.responseTypeEnum == DcrResponseTypeEnum.REJECT || DcrResponseTypeEnum.PENDING_REJECT;
  const isDcrAcceptedAsLocal = group?.isDcrGroupKeptAsLocalRecord;
  const configuration: Record<DcrResponseTypeEnum, string> = {
    [DcrResponseTypeEnum.QUEUE]: formatMessage(Locale.Text.DCR_history_create, { value: group?.responsibleUser.fullname }),
    [DcrResponseTypeEnum.UNHANDLED]: formatMessage(Locale.Text.DCR_history_unhandled),
    [DcrResponseTypeEnum.PENDING_REJECT]: formatMessage(Locale.Text.DCR_history_pending_reject),
    [DcrResponseTypeEnum.REJECT]: isDcrAcceptedAsLocal
      ? formatMessage(Locale.Text.DCR_history_reject_by_user_kept_as_local, { value: response?.user?.fullname })
      : response.user ? formatMessage(Locale.Text.DCR_history_reject_by_user, { value: response?.user?.fullname })
        : formatMessage(Locale.Text.DCR_history_reject),
    [DcrResponseTypeEnum.ACCEPT]: formatMessage(Locale.Text.DCR_history_accept),
  };
  const label = configuration[response.responseTypeEnum];

  return (
    <Row>
      <Col>{date}:</Col>
      <Col><Space>{label}{response.comment && (
        <Alert
          style={{ height: 25 }}
          message={<Icon iconType="MESSAGE" text={response.comment} />}
          type={isRejected ? 'error' : 'success'}
        />
      )}</Space></Col>

    </Row>
  );
};


export default DcrStatusMessage;
