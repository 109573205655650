import React from 'react';
import { toNumber } from 'lodash';
import { useMatch } from 'react-router';
import { gql, useQuery } from '@apollo/client';
import Processing from './Processing';
import FinishedDetails from './FinishedDetails';
import MainView from '../../../components/View/MainView';
import {
  BasicImportProjectStatusQuery,
  BasicImportProjectStatusQueryVariables,
  ImportProcessStatusEnum
} from '../../../../gql/typings';

const ImportProjectProcessingDetails: React.FC = () => {
  const match = useMatch('import/:id/process');
  const { data } = useQuery<BasicImportProjectStatusQuery, BasicImportProjectStatusQueryVariables>(DATA_QUERY, {
    variables: { projectId: toNumber(match?.params?.id) },
  });

  return (
    <MainView className="import-project-detail-processing-container">
      {data?.importProject?.statusCode === ImportProcessStatusEnum.PROCESSED
        ? <FinishedDetails projectId={toNumber(match?.params?.id)} />
        : <Processing projectId={toNumber(match?.params?.id)} />}
    </MainView>
  );
};

const DATA_QUERY = gql`
  query BasicImportProjectStatus($projectId: Int!) {
    importProject(id: $projectId) {
      id
      statusCode
    }
  }
`;

export default ImportProjectProcessingDetails;
