import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import { customFieldTypes } from '../../../components/EntityTabs/Fields/fieldTypes/CustomField';
import { ActivityDetailContext } from './ActivityDetailsTypes';
import {
  ActivityDetailsCustomFieldsQuery,
  ActivityDetailsCustomFieldsQueryVariables,
  CustomFieldValueFragmentFragment
} from '../../../../gql/typings';
import { CustomField_FRAGMENT, CustomFieldValue_FRAGMENT } from '../../../components/CustomField/CustomFieldUtils';
import HeaderWithValue from '../../../components/DisplayFields/HeaderWithValue';

const ActivityDetailsCustomFields: React.FC = () => {
  const {
    activityId,
    activityType,
  } = useContext(ActivityDetailContext);
  const {
    data,
    refetch,
  } = useQuery<ActivityDetailsCustomFieldsQuery, ActivityDetailsCustomFieldsQueryVariables>(DATA_QUERY, {
    skip: !(activityId && activityType),
    variables: {
      activityId: activityId ?? -1,
      activityTypeCode: activityType?.code ?? '',
    }
  });


  return (
    <Row>
      {activityId && data?.activityType?.customFields.nodes?.map(cf => {
        const ToDisplay = customFieldTypes[cf.fieldTypeEnum];
        return (
          <Col key={cf.code} sm={24} md={12}>
            <HeaderWithValue heading={cf.heading}>
              <ToDisplay
                key={cf.code}
                field={cf}
                values={cf.customLinkValues as CustomFieldValueFragmentFragment}
                recordId={activityId}
                refreshData={() => refetch()}
              />
            </HeaderWithValue>
          </Col>
        );
      })}
    </Row>
  );
};

const DATA_QUERY = gql`
  query ActivityDetailsCustomFields($activityTypeCode: String!, $activityId: Int!) {
    activityType(code: $activityTypeCode) {
      code
      customFields {
        hash
        nodes {
          code
          heading
          fieldTypeEnum
          ...CustomFieldFragment
          customLinkValues(recordId: $activityId) {
            hash
            ...CustomFieldValueFragment
          }
        }
      }
    }
  }
  ${CustomField_FRAGMENT}
  ${CustomFieldValue_FRAGMENT}
`;

export default ActivityDetailsCustomFields;
