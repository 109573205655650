import { gql } from '@apollo/client';
import { Tooltip, Typography } from 'antd';
import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum, PersonCriteriaOrder, SelectPersonTypeSuggestionQuery2Query, SelectPersonTypeSuggestionQuery2QueryVariables,
  UpdatePersonTypeMutationMutationVariables
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';

export const personTypeFieldConfig = personFieldConfig(
  'personType',
  [
    'id',
    'countryCode',
    'persType.code',
    'persType.type',
    'persType.label',
    'persType.displayLabel',
    'controlSetting.id',
    'controlSetting.createDcr',
    'controlSetting.maintainerSourceCode',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Person_type),
    additionalTableConfig: {
      width: 130
    },
    sorting: {
      ascend: [PersonCriteriaOrder.PERSON_TYPE_ASC],
      descend: [PersonCriteriaOrder.PERSON_TYPE_DESC],
    },
    dcrInfo: person => ({
      entityType: EntityTypeEnum.PERSON,
      entityAffiliationId: person.id,
      field: FieldEnum.HCP_TYPE_CODE,
    }),
    render: (person, { isViewingFromPage }) => {
      const personTypeField = person.persType?.displayLabel;
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={personTypeField}>{personTypeField}</Tooltip>
      </Typography.Paragraph>);
    },
    updateView: buildSelectUpdateFieldConfig({
      mode: 'single',
      allowClear: true,
      mutation: UPDATE_MUTATION,
      selectPlaceholder: localization.formatMessage(Locale.Command.Select_person_type),
      selectedKey: r => r.persType?.code,
      buildVariables: (selectedKey, record) => ({
        persId: record.id,
        typeCode: selectedKey,
      }) as UpdatePersonTypeMutationMutationVariables,
      fetchSuggestions: ({ client, userInput, record }) => (
        client.query<SelectPersonTypeSuggestionQuery2Query, SelectPersonTypeSuggestionQuery2QueryVariables>({
          query: SUGGESTIONS_QUERY,
          variables: { country: record.countryCode, search: `%${userInput}%` },
        }).then(res => res.data.personTypes.nodes)
      ),
    }),
  })
);

const UPDATE_MUTATION = gql`
  mutation UpdatePersonTypeMutation($persId: Int!, $typeCode: String) {
    updatePersonType(persId: $persId, typeCode: $typeCode) {
      id
      persTypeCode
      persType {
        code
        type
      }
    }
  }
`;

const SUGGESTIONS_QUERY = gql`
  query SelectPersonTypeSuggestionQuery2($country: CountryCode!, $search: String) {
    personTypes(criteria: { countries: [$country], label: $search, fetchSize: { limit: 1000 } }) {
      hash
      nodes {
        code
        label: displayLabel
        type
      }
    }
  }
`;
