import { gql, useApolloClient, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Card, Empty, Input, Space, Tooltip, Tree } from 'antd';
/* eslint-disable import/no-extraneous-dependencies */
import { DataNode, Key } from 'rc-tree/lib/interface';
import { uniq, find, flatMap, partition } from 'lodash';
import { useDebounce, usePrevious } from 'ahooks';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import EntitiesSearchContext from '../../EntitiesSearchContext';
import LightCollapse from '../../../LightCollapse/LightCollapse';
import { Locale } from '../../../../../localization/LocalizationKeys';
import SimpleSavedSearches from './SimpleSavedSearches';
import { generateMessageObject } from '../../../../util/generateMessageObject';
import { useLocalization } from '../../../../util/useLocalization';
import { CriteriaInputFactory_FRAGMENT } from '../../../CriteriaInput/CriteriaInputQueries';
import { EntitySimpleSearchCriteriasQuery, EntitySimpleSearchCriteriasQueryVariables } from '../../../../../gql/typings';

const EntitiesSimpleSearch = () => {
  const localization = useLocalization();
  const {
    entityType,
    selectedCriterias,
    setSelectedCriterias,
    globalState,
  } = useContext(EntitiesSearchContext);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, { wait: 500 });
  const {
    data,
    loading: isLoading,
  } = useQuery<EntitySimpleSearchCriteriasQuery, EntitySimpleSearchCriteriasQueryVariables>(
    DATA_QUERY,
    { variables: { entityType, search: `%${debouncedSearch.replace(/\s+/g, '%')}%` } },
  );
  const loading = isLoading || search !== debouncedSearch;
  const prevData = usePrevious(data);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>();

  const { formatMessage } = useIntl();
  const apollo = useApolloClient();
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>(selectedCriterias.map(it => it.id));
  const [selectedKeys] = useState<React.Key[]>([]);
  useEffect(() => {
    setCheckedKeys(selectedCriterias.map(c => c.id));
  }, [selectedCriterias]);

  useEffect(() => {
    if (!expandedKeys && data?.criteriaGroups.nodes) setExpandedKeys(data.criteriaGroups.nodes.map(cg => cg.code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Some simple cache. If list is empty, and it's loading, we'll try to use the old values state
  const nodes = loading && data?.criteriaGroups.nodes.length === 0
    ? prevData?.criteriaGroups.nodes ?? []
    : data?.criteriaGroups.nodes ?? [];

  const onClickGroupTitle = (groupCode: string) => () => {
    if (expandedKeys!.includes(groupCode)) setExpandedKeys(expandedKeys!.filter(k => k !== groupCode));
    else setExpandedKeys([...expandedKeys!, groupCode]);
  };
  const onClickCriteriaTitle = (criteriaId: number) => () => {
    if (checkedKeys.includes(criteriaId)) setSelectedCriterias(selectedCriterias.filter(sc => sc.id !== criteriaId));
    else setSelectedCriterias([
      ...selectedCriterias,
      apollo.readFragment({
        id: `Criteria:${criteriaId}`,
        fragment: CriteriaInputFactory_FRAGMENT,
      })!,
    ]);
  };

  const treeView: DataNode[] = nodes.map(group => ({
    key: group.code,
    title: <span onClick={onClickGroupTitle(group.code)}>{group.heading ?? ''}</span>,
    checkable: false,
    entity: group.entityId ?? 0,
    code: group.code ?? '',
    children: group.criterias.nodes.map(criteria => {
      const disabled = globalState && globalState[0] && !criteria.hasGlobalSupport;
      const title = !disabled ? (
        <span onClick={onClickCriteriaTitle(criteria.id)}>
          {formatMessage(generateMessageObject(criteria.heading)) ?? ''}
        </span>
      ) : (
        <Tooltip
          title={localization.formatMessage(Locale.Text.This_criteria_not_supported_in_global_search)}
          placement="topRight"
        >
          {formatMessage(generateMessageObject(criteria.heading)) ?? ''}
            &nbsp;<InfoCircleOutlined />
        </Tooltip>
      );

      return {
        key: criteria.id ?? 0,
        className: 'tree-child-item',
        title,
        id: criteria.id ?? 0,
        isChecked: !!find(selectedCriterias, { id: criteria.id }),
        entityType: criteria.entityType ?? '',
        disabled,
        code: criteria.code ?? 0,
        criteriaGroupCode: criteria.criteriaGroupCode ?? 0,
      };
    }),
  })).filter(g => g.children.length > 0);


  const onSelect = (areChecked: { checked: Key[]; halfChecked: Key[] } | Key[]) => {
    const checked = areChecked as Key[];
    const visibleKeys = flatMap(treeView, group => group.children?.map(c => c.key)).filter(e => e) as React.Key[];
    const [toAdd, toRemove] = partition(visibleKeys, cId => checked.includes(cId));
    const updated = uniq([
      ...checkedKeys.filter(ck => !toRemove.includes(ck)),
      ...toAdd,
    ]);
    const criterias = uniq(updated.map(criteriaId => apollo.readFragment({
      id: `Criteria:${criteriaId}`,
      fragment: CriteriaInputFactory_FRAGMENT,
    })));
    setSelectedCriterias(criterias);
  };

  const subMenus = [
    {
      key: 1,
      heading: formatMessage(Locale.Attribute.Saved_Searches),
      content: <SimpleSavedSearches />
    },
    {
      key: 2,
      heading: formatMessage(Locale.Text.Search_avilable_fields),
      content: <Space direction="vertical">
        {formatMessage(Locale.Text.Avilable_fields_text)}
        <div className="entity-search-drawer-container">
          <Input
            id="entity-search-drawer-criteria-search-input"
            placeholder={formatMessage(Locale.Command.Search_criteria_name)}
            allowClear={!loading}
            size="large"
            onChange={v => setSearch(v.target.value)}
            value={search}
            suffix={loading && <LoadingOutlined />}
          />
          <br /><br />
          <div className="entities-search-drawer-simple-form">
            <Card
              className="dashboard-card-container"
              style={{ display: 'flex', ...(treeView.length === 0 ? { justifyContent: 'center' } : {}) }}
            >
              {treeView.length === 0
                ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                : (
                  <Tree
                    expandedKeys={expandedKeys ?? []}
                    onExpand={setExpandedKeys}
                    checkedKeys={checkedKeys}
                    selectedKeys={selectedKeys}
                    onCheck={onSelect}
                    checkable
                    treeData={treeView}
                  />)}
            </Card>
          </div>
        </div>
      </Space>
    },

  ];

  return (
    <LightCollapse
      accordion
      defaultActiveKey={2}
      className="entities-simple-search-container"
    >

      {subMenus.map(group => (
        <LightCollapse.Panel key={`${group.key}`} header={group.heading}>
          {group.content}
        </LightCollapse.Panel>
      ))}
    </LightCollapse>
  );
};

const DATA_QUERY = gql`
    query EntitySimpleSearchCriterias($entityType: EntityTypeEnum!, $search: String) {
        criteriaGroups(criteria: { entityType: $entityType }) {
            hash
            nodes {
                code
                entityId
                heading
                criterias(criteria: {heading: $search}) {
                    hash
                    nodes {
                        ...CriteriaInputFragment
                    }
                }
            }
        }
    }
    ${CriteriaInputFactory_FRAGMENT}
`;

export default EntitiesSimpleSearch;
