import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import { Form, Select, Switch, Button, Input } from 'antd';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import MainView from '../../../components/View/MainView';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  ListTypeEnum,
  useCreateListMutationMutation
} from '../../../../gql/typings';


const CreateListView = () => {
  const navigate = useNavigate();
  const [listForm] = Form.useForm();
  const [createList, { data }] = useCreateListMutationMutation();
  const { formatMessage } = useIntl();

  const onSubmit = () => listForm.validateFields()
    .then(values => {
      createList({
        variables: {
          input: {
            title: values['list-title'],
            description: values['list-description'],
            type: ListTypeEnum.STAT,
            entityType: values.entityType,
            isActive: true,
            isShared: values['list-visability']
          },
        },
      })
        .then(res => navigate(`/list/${res?.data?.createList?.id}`));
    });


  useEffect(() => {
    if (data) navigate(`/list/${data.createList.id}`);
  }, [data, navigate]);

  return (
    <MainView
      title={formatMessage(Locale.Command.Create_Static_List)}
      titleClassName="project-create"
      className="project-create-container"
      rightActions={<Button
        type="primary"
        onClick={onSubmit}
      >
        {formatMessage(Locale.Command.Save)}
      </Button>}
    >
      <Form className="static-list-create-container" form={listForm}>
        <span className="label">
          {formatMessage(Locale.Attribute.Heading)}
        </span>
        <div className="input">
          <Form.Item
            name="list-title"
            rules={[
              {
                required: true,
                message: formatMessage(Locale.Text.Please_enter_heading)
              },
              {
                max: 40,
                message: formatMessage(Locale.Text.Heading_must_be_beneath_40_characters)
              },
            ]}
          >
            <Input
              className="list-title"
              autoFocus
              placeholder={formatMessage(Locale.Command.Enter_title)}
            />
          </Form.Item>
        </div>

        <span className="label">
          {formatMessage(Locale.Command.Select_Entity_Type)}
        </span>
        <div className="input">
          <Form.Item
            name="entityType"
          >
            <Select
              size="large"
              placeholder={formatMessage(Locale.Command.Select_List_Type)}
            >
              <Select.Option value="PERSON">
                {formatMessage(Locale.Attribute.Person_HCP)}
              </Select.Option>
              <Select.Option value="SITE">
                {formatMessage(Locale.Attribute.Site_HCO)}
              </Select.Option>
              <Select.Option value="ACTIVITY">
                {formatMessage(Locale.General.Activity)}
              </Select.Option>
            </Select>
          </Form.Item>
        </div>
        <span className="label">
          {formatMessage(Locale.Attribute.Description)}
        </span>
        <div className="input">
          <Form.Item name="list-description">
            <Input.TextArea
              id="list-description"
              placeholder={formatMessage(Locale.Command.Enter_description)}
            />
          </Form.Item>
        </div>
        <span className="label">
          {formatMessage(Locale.General.Visibility)}
        </span>
        <div className="input">
          <Form.Item name="list-visability" valuePropName='checked'>
            <Switch
              checkedChildren="Shared"
              unCheckedChildren="Private"
              defaultChecked={false}
            />
          </Form.Item>
        </div>
      </Form>
    </MainView>
  );
};

gql`
  mutation CreateListMutation($input: ListInput!) {
    createList(input: $input) { id }
  }
`;

export default CreateListView;
