import React, { useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Col, Form, message, Select } from 'antd';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { EntityTabFieldProps } from './EntityTabFieldsTypes';
import {
  GetResponsibleUsersTabQuery,
  PersonResponsibleUserTabQuery,
  PersonResponsibleUserTabQueryVariables,
  RemoveResponsibleAreaMutationMutation, RemoveResponsibleAreaMutationMutationVariables,
  UpsertResponsibleAreaTabMutation,
  UpsertResponsibleAreaTabMutationVariables
} from '../../../../../gql/typings';


const PersonResponsibleAreasTabField: React.FC<EntityTabFieldProps> = (props) => {
  const localization = useLocalization();
  const [form] = Form.useForm();
  const { data } = useQuery<GetResponsibleUsersTabQuery>(GET_RESPONSIBLE_USERS);
  const {
    data: pData,
    loading: personLoading,
  } = useQuery<PersonResponsibleUserTabQuery, PersonResponsibleUserTabQueryVariables>(DATA_QUERY, {
    variables: { personId: props.recordId },
  });
  const [
    upsertResponsibleArea,
  ] = useMutation<UpsertResponsibleAreaTabMutation, UpsertResponsibleAreaTabMutationVariables>(
    UPSERT_RESPONSIBLE_AREA_TAB_MUTATION
  );

  const [
    removeResponsibleArea,
  ] = useMutation<RemoveResponsibleAreaMutationMutation, RemoveResponsibleAreaMutationMutationVariables>(
    REMOVE_RESPONSIBLE_AREA_MUTATION
  );

  useEffect(() => {
    if (pData?.person) {
      form.setFieldsValue(pData.person.userResponsibilites.nodes.reduce((acc, curr) => ({
        ...acc,
        [`selected-${curr.responsibleArea.id}`]: curr.user.id,
      }), {}));

    }
  }, [form, pData, personLoading]);

  const onChange = (responsibleAreaId: number) => (userId: number) => {
    if (userId != null) {
      upsertResponsibleArea({
        variables: {
          persId: props.recordId,
          areaId: responsibleAreaId,
          userId,
        },
        refetchQueries: ['GetResponsibleUsers'],
      }).finally(() => message.success('Updated'));
    } else {
      removeResponsibleArea({
        variables: {
          persId: props.recordId,
          areaId: responsibleAreaId,
        },
        refetchQueries: ['GetResponsibleUsers'],
      }).finally(() => message.success('user removed'));
    }
  };

  return (
    <Form form={form} layout="inline">
      <Col span={8}>
        <strong>{localization.formatMessage(Locale.Command.Select_user_types)}</strong>
      </Col>
      <Col span={16}>
        <strong>{localization.formatMessage(Locale.Command.Select_responsible_User)}</strong>
      </Col>
      {(data?.responsibleAreas?.nodes ?? []).map(node => (
        <React.Fragment key={node.id}>
          <Col span={8}>
            {node.heading}:
          </Col>
          <Col span={16}>
            <Form.Item name={`selected-${node.id}`} style={{ margin: '4px 4px 4px 0' }}>
              <Select
                showSearch
                placeholder={localization.formatMessage(Locale.Command.Select_user)}
                optionFilterProp="children"
                allowClear
                onChange={onChange(node.id)}
                filterOption={(input, option) => (
                  option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )}
              >
                {(data?.users.nodes ?? []).map(user => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.fullname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </React.Fragment>
      ))}
    </Form>
  );
};

const UPSERT_RESPONSIBLE_AREA_TAB_MUTATION = gql`
  mutation UpsertResponsibleAreaTab(
    $persId: Int!,
    $areaId: Int!,
    $userId: Int!
  ) {
    upsertResponsibleArea(persId: $persId, areaId: $areaId, userId: $userId ) {
      id
    }
  }
`;


const DATA_QUERY = gql`
  query PersonResponsibleUserTab($personId: Int!) {
    person(id: $personId) {
      id
      fullName
      userResponsibilites{
        hash
        nodes{
          ct
          id
          user {
            id
          }
          person{
            id
            fullName
          }

          responsibleArea{
            id
            code
            ct
            heading
          }
        }
      }
    }
  }
`;

const GET_RESPONSIBLE_USERS = gql`
  query GetResponsibleUsersTab {
    users {
      hash
      nodes {
        id
        fullname
      }
    }
    responsibleAreas {
      hash
      nodes {
        id
        code
        heading
      }
    }
  }
`;

const REMOVE_RESPONSIBLE_AREA_MUTATION = gql`
  mutation RemoveResponsibleAreaMutation(
    $persId: Int!,
    $areaId: Int!,
  ) {
    removeResponsibleArea(persId: $persId, areaId: $areaId)
  }
`;

export default PersonResponsibleAreasTabField;
