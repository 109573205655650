import { Locale } from '../../../../localization/LocalizationKeys';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';

export const linkSiteAPureBaseIdFieldConfig = linkFieldConfig(
  'siteAPureBaseId',
  [
    'site.id',
    'site.apurebaseId',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Site_aPureBase_ID),
    additionalTableConfig: {
      width: 120
    },
    render: (record) => record.site.apurebaseId,
  })
);
