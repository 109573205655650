import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Col, List, Row } from 'antd';
import { isEqual, toNumber } from 'lodash';
import { useNavigate } from 'react-router';
import { useInterval, useThrottleEffect } from 'ahooks';
import { Link } from 'react-router-dom';
import RawPersonPopoverCard from '../../browse/person/PopoverCard/RawPersonPopoverCard';
import DownloadBadge from '../../browse/person/Components/DownloadBadge/DownloadBadge';
import {
  LiveMatchFetchHCPIdQuery,
  LiveMatchFetchHCPIdQueryVariables, LiveMatchOfficialIdQuery, LiveMatchOfficialIdQueryVariables,
  PersonElasticMatchQuery,
  PersonElasticMatchQueryVariables
} from '../../../gql/typings';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';

type PersonMatchType = Extract<NonNullable<LiveMatchOfficialIdQuery['externalIds']>['nodes']['0']['entityAffiliation'],
{ ['__typename']: 'Person' }>;

const LivePersonMatchSearch: React.FC<{ build: () => PersonElasticMatchQueryVariables }> = ({
  build,
}) => {
  const navigate = useNavigate();
  const localization = useLocalization();
  const [state, setState] = useState<'none'|'waiting'|'loading'>('none');
  const apolloClient = useApolloClient();
  const [search, setSearch] = useState<PersonElasticMatchQueryVariables>({});
  const [data, setData] = useState<PersonElasticMatchQuery>();
  const [idMatch, setIdMatch] = useState<PersonMatchType>();
  const [
    loadApbId,
    { data: apbIdData },
  ] = useLazyQuery<LiveMatchFetchHCPIdQuery, LiveMatchFetchHCPIdQueryVariables>(ID_BY_APB_ID);

  const officialId = search.person?.officialId;

  useInterval(() => {
    const newSearch = build();
    if (!isEqual(search, newSearch)) setSearch(newSearch);
  }, 1000);

  useEffect(() => {
    setState('loading');
    apolloClient.query<PersonElasticMatchQuery, PersonElasticMatchQueryVariables>({
      query: DATA_QUERY,
      variables: search,
    }).then(res => {
      setState('none');
      setData(res.data);
    }).catch(e => {});
  }, [apolloClient, search]);

  useEffect(() => {
    const id = apbIdData?.persons.nodes[0]?.id;
    if (id) navigate(`/hcp/${id}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apbIdData]);

  useThrottleEffect(() => {
    if (officialId && officialId.length > 0) {
      apolloClient.query<LiveMatchOfficialIdQuery, LiveMatchOfficialIdQueryVariables>({
        query: OFFICIAL_ID_MATCH,
        variables: { officialId }
      }).then(res => {
        if (res.data.externalIds.nodes.length === 1) {
          setIdMatch(res.data.externalIds.nodes[0]!.entityAffiliation as PersonMatchType);
        } else if (idMatch) setIdMatch(undefined);
      });
    }
  }, [apolloClient, officialId]);

  const click = (record: { apurebaseId: string }) => loadApbId({
    variables: {
      apbId: toNumber(record.apurebaseId),
    },
  });

  return (
    <div className="live-match-search-container" data-loading-state={state}>
      {idMatch && (
        <Alert
          showIcon
          type="warning"
          message={localization.formatMessage(Locale.Text.Official_ID_match)}
          description={localization.formatMessage(Locale.Text.Exact_official_id_match_description, {
            enteredId: <strong>{officialId}</strong>,
            personName: <strong><Link to={`/hcp/${idMatch.id}`}>{idMatch.fullName}</Link></strong>,
          })}
        />
      )}
      {!idMatch && <List
        bordered
        header={
          <Row>
            <Col span={18}><b>Did you mean? (Live Search)</b></Col>
            <Col span={6}>
              {state === 'loading' && <LoadingOutlined />}
            </Col>
          </Row>
        }
      >
        {(data?.elasticMatch ?? []).map(record => (
          <List.Item
            key={record.apurebaseId}
            className="list-item-hcp-record"
            data-firstname={record.person.firstName}
            data-lastname={record.person.lastName}
            data-gender={record.person.gender}
            data-title={record.person.title}
            data-apb-id={record.person.apurebaseId}
          >
            <div className="item">
              <RawPersonPopoverCard
                firstName={record.person.firstName}
                lastName={record.person.lastName}
                title={record.person.title}
                gender={record.person.gender}
                personType={record.person.personType}
                countryCode={record.person.countryCode}
                officialId={null} // TODO: Add support from the backend.
              >
                <span className={`item-left ${record.inLocal ? 'local' : 'global'}`} onClick={() => click(record)}>
                  {record.person.firstName} {record.person.lastName} ({record.person.title})
                </span>
              </RawPersonPopoverCard>
              {/* eslint-disable-next-line */}
              <a href="#" style={{cursor: "BeekBadge", color: record.inLocal ? "green" : "gray"}} />
              {!record.inLocal && <div className="item-right">
                <DownloadBadge
                  entity={{ countryCode: record.person.countryCode, id: toNumber(record.apurebaseId) }}
                  type="PERSON"
                  name={`${record.person.firstName} ${record.person.lastName}`}
                  style={{ textAlign: 'left', cursor: 'pointer' }}
                />
              </div>}
              <div className="clear" />
            </div>
          </List.Item>
        ))}
      </List>}
    </div>
  );
};


const DATA_QUERY = gql`
  query PersonElasticMatch($minScore: Int = 51, $person: PersonMatchInput, $site: SiteMatchInput, $link: SitePersonMatchInput) {
    elasticMatch(
      person: $person,
      link: $link,
      site: $site,
      criteria: {
        limit: 5,
        minScore: $minScore
      }
    ) {
      apurebaseId
      inLocal
      score
      person {
        apurebaseId
        firstName
        lastName
        countryCode
        title
        gender
        personType
      }
      site {
        apurebaseId
        name
      }
      link {
        apurebaseId
        role
        specialty
      }
    }
  }
`;

const ID_BY_APB_ID = gql`
  query LiveMatchFetchHCPId($apbId: Int!) {
    persons(criteria: {aPureBaseId: $apbId}) {
      hash
      nodes {
        id
        apurebaseId
      }
    }
  }
`;

const OFFICIAL_ID_MATCH = gql`
  query LiveMatchOfficialId($officialId: String!) {
    externalIds(criteria: {
      entityType: PERSON,
      externalId: $officialId,
      externalIdTypeCode: "APB_OFFICIAL"
    }) {
      hash
      nodes {
        id
        externalId
        entityAffiliation {
          id
          ... on Person {
            fullName
          }
        }
      }
    }
  }
`;

export default LivePersonMatchSearch;
