import React from 'react';
import { Button as AntdButton } from 'antd';
import { ButtonShape } from 'antd/lib/button';
import { bootstrapBtnSize } from '../../util/bootstrap';


/**
 * @deprecated - Use Ant components instead of this small wrapper
 */
const Button: React.FC<{
  label?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  loading?: boolean;
  className?: string;
  visible?: boolean;
  disabled?: boolean;
  size?: 'xsmall'|'small'|'medium'|'large';
  color?: 'primary'|'blue'|'green'|'light';
  shape?: ButtonShape;
  rounded?: boolean;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
}> = ({
  label,
  loading = false,
  visible = true,
  disabled = false,
  size,
  className = '',
  color = 'green',
  shape = 'default',
  rounded = false,
  icon,
  style,
  onClick,
}) => {
  if (!visible) return <></>;
  const clsColor = `btn-${color}`;
  const clsClassName = className || '';
  const clsBootstrap = bootstrapBtnSize(size);
  const clsRounded = rounded ? 'btn-rounded' : '';
  return (
    <AntdButton
      className={`btn ${clsColor} ${clsClassName} ${clsBootstrap} ${clsRounded}`}
      htmlType="submit"
      onClick={onClick
        ? (e) => {
          e.preventDefault();
          onClick(e);
        }
        : undefined}
      style={style}
      shape={shape}
      disabled={disabled}
      loading={loading}
      icon={icon}
    >
      {label}
    </AntdButton>
  );
};

export default Button;
