import { ReactNode } from 'react';
import { sortBy, toLower } from 'lodash';
import { TableFieldsReturnedOptionType } from '../../browse/search_old/types';
import { FieldItemConfigs } from '../FieldLabel/FieldLabel';

type Args = {
  list: { id: string }[];
  items: FieldItemConfigs<string>[];
  optionMapGet: (key: string) => (TableFieldsReturnedOptionType[] | undefined);
};

export type TableColumnTreeFnReturn = (record: { id: string }) => {
  title: ReactNode;
  value: string;
  selectable?: boolean;
  children?: {
    title: ReactNode;
    value: string;
  }[];
}[];


export function useTableColumnTreeDataFn({ items, optionMapGet, list }: Args): TableColumnTreeFnReturn {
  return (record: { id: string }) => sortBy(items
    .filter(([cc]) => record.id === cc.key || !list.map(l => l.id).includes(cc.key))
    .map(([cc]) => {
      if (cc.getOptions) {
        return {
          title: cc.getOptions[0],
          value: cc.key,
          selectable: false,
          children: (optionMapGet(cc.key) ?? []).filter(o => {
            const k = `${cc.key}:${o.code}`;
            return record.id === k || !list.map(l => l.id).includes(k);
          }).map(o => ({ value: `${cc.key}:${o.code}`, title: o.label })),
        };
      }
      return {
        value: cc.key,
        title: cc.title || cc.key,
      };
    })
    .filter(f => !f.children || f.children.length > 0), e => toLower(e.title as string));
}
