import { Locale } from '../../../../localization/LocalizationKeys';
import { siteSelectionRowFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SiteSelectionRowCriteriaOrder } from '../../../../gql/typings';

export const siteSelectionRowSelectionTypeFieldConfig = siteSelectionRowFieldConfig(
  'selectionType',
  [
    'selectionRow.id',
    'selectionRow.group.id',
    'selectionRow.group.selection.id',
    'selectionRow.group.selection.type',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Selection_type),
    sorting: {
      ascend: SiteSelectionRowCriteriaOrder.SELECTION_TYPE_ASC,
      descend: SiteSelectionRowCriteriaOrder.SELECTION_TYPE_DESC,
    },
    render: record => record.selectionRow?.group.selection.type ?? localization.formatMessage(Locale.General.Unknown),
  }),
);
