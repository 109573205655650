import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  SelectSiteParentSuggestionQueryQuery,
  SelectSiteParentSuggestionQueryQueryVariables,
  SitePersonCriteriaOrder, UpdateSiteParentMutationMutationVariables
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';
import { SITE_PARENT_SUGGESTIONS_QUERY, UPDATE_SITE_PARENT_MUTATION } from '../../site/fields/siteParentNameFieldConfig';

export const linkSiteParentNameFieldConfig = linkFieldConfig(
  'siteParentName',
  [
    'id',
    'site.id',
    'site.countryCode',
    'site.parentSite.id',
    'site.parentSite.name',
    'site.controlSetting.id',
    'site.controlSetting.entityTypeId',
    'site.controlSetting.maintainerSourceCode',
    'site.controlSetting.createDcr',
  ],
  ({ localization, openInNewTab }) => ({
    title: localization.formatMessage(Locale.Attribute.Parent_site),
    additionalTableConfig: {
      width: 150
    },
    sorting: {
      ascend: SitePersonCriteriaOrder.SITE_PARENT_NAME_ASC,
      descend: SitePersonCriteriaOrder.SITE_PARENT_NAME_DESC,
    },
    dcrInfo: record => ({
      field: FieldEnum.HCO_PARENT_ID,
      entityAffiliationId: record.site.id,
      entityType: EntityTypeEnum.SITE,
    }),
    render: record => <LinkBadge
      url={record.site.parentSite ? `/hco/${record.site.parentSite.id}` : undefined}
      label={record.site.parentSite?.name}
      openInNewTab={openInNewTab}
      className="open-site-link"
    />,
    updateView: buildSelectUpdateFieldConfig({
      mode: 'single',
      allowClear: true,
      selectPlaceholder: localization.formatMessage(Locale.Command.Select_Site),
      selectedKey: record => record.site.parentSite?.id?.toString(),
      mutation: UPDATE_SITE_PARENT_MUTATION,
      buildVariables: (selectedKey, record) => ({
        siteId: record.site.id,
        parentId: selectedKey,
      }) as UpdateSiteParentMutationMutationVariables,
      fetchSuggestions: ({ client, record, userInput }) => (
        client.query<SelectSiteParentSuggestionQueryQuery, SelectSiteParentSuggestionQueryQueryVariables>({
          query: SITE_PARENT_SUGGESTIONS_QUERY,
          variables: { countryCode: record.site.countryCode, search: `%${userInput}%` },
        }).then(res => res.data.sites.nodes.map(s => ({ code: s.id.toString(), label: s.name })))
      ),
    }),
  })
);
