import React from 'react';

type AttributeLabelProps = {
  label?: string | React.ReactNode;
  className?: string;
  value: string | React.ReactNode;
  update?: string | React.ReactNode;
  editState?: boolean;


};

const AttributeLabel: React.FC<AttributeLabelProps> = ({
  label,
  value,
  update,
  editState,
  className
}) => (
  <div className="attribute-label-container">
    {label && <div className="label">
      <span className={`detail-view-label ${className ?? ''}`}>
        {label}
      </span>
    </div>}
    <div className="value">
      <div className={`detail-view-value ${className ?? ''}`}>
        <div>{editState ? update : value}</div>
      </div>
    </div>
    <div className="clear" />
  </div>
);

export default AttributeLabel;

