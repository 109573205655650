import { useNavigate } from 'react-router';
import { isObject, uniq, isFunction, has, isBoolean } from 'lodash';
import { useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { entitiesTableConfigs, SupportedEntitySearchTypes } from '../browse/search_old/types';
import { FieldItemConfig, FieldItemConfigs } from '../components/FieldLabel/FieldLabel';
import { useLocalization } from './useLocalization';
import { EntitySearchColumnParamType } from '../browse/search_old/types/EntitySearchColumnParamType';
import { usePermissions } from '../permission/usePermissions';
import { gqlFieldKeyAliasTransformer } from './gqlFieldKeyAliasTransformer';
import { GridCardFieldFragment, PermissionEnum } from '../../gql/typings';

export type FieldTransformerKeys = string[]|GridCardFieldFragment[];

export const useFieldKeysTransformed = (
  entityType: SupportedEntitySearchTypes,
  fields?: FieldTransformerKeys,
  additionalProps?: EntitySearchColumnParamType,
): FieldItemConfigs<string>[] => {
  const localization = useLocalization();
  const navigate = useNavigate();
  const [customPermissionMap, setCustomPermissionMap] = useState<Record<string, boolean>>({});
  const typeConfig = entitiesTableConfigs[entityType];

  const props = useMemo(() => {
    if (!additionalProps) return undefined;
    return {
      cherryPickSupport: additionalProps.cherryPickSupport,
      span: additionalProps.span,
      openInNewTab: additionalProps.openInNewTab,
    } as EntitySearchColumnParamType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalProps?.cherryPickSupport, additionalProps?.span, additionalProps?.openInNewTab]);

  const configs: (FieldItemConfigs<string>|null)[] = useMemo(() => (fields ?? []).map(k => {
    const fieldKey: string = isObject(k) ? k.fieldKey : k;
    const index = fieldKey.indexOf(':');
    let key: string;
    let keyCode: string|undefined;
    if (index === -1) {
      key = fieldKey;
    } else {
      key = fieldKey.substring(0, index);
      keyCode = fieldKey.substring(index + 1);
    }

    const configFn = typeConfig.columnConfig.fields[key]!;
    if (!configFn) return null;


    const config = configFn({
      ...props,
      navigate,
      localization,
      keyCode,
    });

    const updatedConfig: FieldItemConfig<string> = {
      ...config,
      fieldOptions: isObject(k) ? k : undefined,
      updatedQueries: config.queryFields?.map((qf: string) => {
        if (qf.indexOf('{{keyCode}}') > -1) {
          const alias = gqlFieldKeyAliasTransformer(keyCode, qf);
          const query = qf.replace('{{keyCode}}', keyCode as string);
          return `${alias}: ${query}`;
        }
        return qf.replace('{{keyCode}}', keyCode as string);
      }),
    };

    return [updatedConfig, { keyCode: keyCode as string }];
  }), [props, fields, localization, navigate, typeConfig]);

  const permissionCodes = useMemo(
    () => uniq(configs.filter(row => row?.[0]?.permission && !isFunction(row?.[0]?.permission)).map(i => i?.[0]?.permission)),
    [configs],
  );

  const permissionMap = usePermissions(permissionCodes as PermissionEnum[]);

  return useMemo(() => (configs.filter(r => {
    if (!r) return false;
    if (isFunction(r[0].permission)) {
      if (has(customPermissionMap, r[1].keyCode)) {
        return customPermissionMap[r[1].keyCode];
      }

      const promiseResult = r[0].permission(r[1].keyCode);
      if (isBoolean(promiseResult)) {
        setCustomPermissionMap(c => ({ ...c, [r[1].keyCode]: promiseResult }));
        return promiseResult;
      }
      return promiseResult.then(res => setCustomPermissionMap(c => ({ ...c, [r[1].keyCode]: res })));
    }
    if (!r[0].permission) return true;
    return permissionMap[r[0].permission].read;
  }) as FieldItemConfigs<string>[]).map(([config, opts]) => {
    if (config.permission && !isFunction(config.permission) && !permissionMap[config.permission].update) return [
      { ...config, updateView: undefined },
      opts,
    ];
    return [config, opts];
  }), [configs, permissionMap, customPermissionMap]);
};


export const FIELD_KEY_FRAGMENT = gql`
  fragment GridCardField on EntityGridItemCardField {
    id
    fieldKey
    heading
    isHeadingKey
  }
`;
