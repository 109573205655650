import React, { useState } from 'react';
import { Dropdown, MenuProps, notification } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { omit as omitFp } from 'lodash/fp';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import AddToExistingStaticListModal from '../../list/CreateList/AddToExistingStaticListModal';
import ApbButton from '../../../components/Button/ApbButton';
import NoteDrawer from '../../../components/Note/NoteDrawer';
import { Locale } from '../../../../localization/LocalizationKeys';
import { buildSelectionCriteria } from '../../../components/Table/utils';
import { useLocalization } from '../../../util/useLocalization';
import { useRemoveSiteAction } from '../hooks/useRemoveSiteAction';
import { useAddSiteToActivityAction } from '../hooks/useAddSiteToActivityAction';
import { EntityTypeEnum, PermissionEnum, SiteDetailQuery, SourceEnum } from '../../../../gql/typings';
import { useInactivateSiteAction } from './useInactivateSiteAction';
import AddPersonsToSiteModal from './AddPersonsToSiteModal/AddPersonsToSiteModal';
import { useAddPersonToSiteAction } from './AddPersonsToSiteModal/useAddPersonToSiteAction';
import { usePermissions } from '../../../permission/usePermissions';
import EntityMergeModal from '../../../components/EntityMerge/EntityMergeModal';

type HcoSectionRightProps = {
  siteId: number;
  setValue: () => void;
  refreshEmitter: EventEmitter<void>;
  site?: SiteDetailQuery['site'];
};

const HcoSectionRight: React.FC<HcoSectionRightProps> = ({
  siteId,
  setValue,
  site,
  refreshEmitter,
}) => {
  const localization = useLocalization();
  const permissions = usePermissions([PermissionEnum.MERGE_RECORD]);
  const [showMassAssign, setShowMassAssign] = useState(false);
  const [displayDrawer, setDisplayDrawer] = useState(false);
  const [showAddPersonModal, setShowAddPersonModal] = useState(false);
  const mergeModalState = useState(false);

  const addPersonToSite = useAddPersonToSiteAction(siteId, site?.countryCode);
  const removeSite = useRemoveSiteAction(siteId);
  const addSiteToActivity = useAddSiteToActivityAction(siteId);
  const inactivateSiteAction = useInactivateSiteAction(siteId);

  const onClick = () => {
    setValue();
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
    notification.open({
      message: localization.formatMessage(Locale.Text.Dcr_remove_hco_affiliation_heading),
      description: localization.formatMessage(Locale.Text.Dcr_remove_hco_affiliation_text)
    });
  };

  const items: MenuProps['items'] = [
    {
      key: 'add_to_list',
      onClick: () => setShowMassAssign(true),
      label: localization.formatMessage(Locale.Command.Add_to_list),
    },
    {
      key: 'create-note',
      onClick: () => setDisplayDrawer(true),
      label: localization.formatMessage(Locale.Command.Create_Note),
    },
    addPersonToSite.action.hide ? null : addPersonToSite.action,
    addSiteToActivity.action.hide ? null : addSiteToActivity.action,
    {
      key: 'merge-record',
      label: localization.formatMessage(Locale.Command.Merge_record),
      hide: !permissions.MERGE_RECORD.create || site?.maintainerSourceCode !== SourceEnum.LOCAL,
      onClick: () => mergeModalState[1](true),
    },
    removeSite.action.hide ? null: removeSite.action,
    {
      key: 'dcr_remove_affiliation_persons',
      onClick,
      label: localization.formatMessage(Locale.Text.Dcr_remove_affiliation_persons),
    },
    inactivateSiteAction.action.hide ? null : inactivateSiteAction.action,
  ].filter(e => e && !e.hide).map(omitFp('hide'));

  return (
    <div className="content-right-section">
      <div className="button-select-container">
        <Dropdown menu={{ items }} trigger={['click']}>
          <span onClick={e => e.preventDefault()}>
            <ApbButton
              icon={<DownOutlined />}
              size="large"
              buttonType="action"
              text={localization.formatMessage(Locale.Text.Actions_to_take)}
            />
          </span>
        </Dropdown>
      </div>
      {addPersonToSite.hidden}
      {addSiteToActivity.hidden}
      {removeSite.hidden}
      {inactivateSiteAction.hidden}
      <EntityMergeModal
        entityType={EntityTypeEnum.SITE}
        visibleState={mergeModalState}
        fromId={siteId}
        onCompleteHook={refreshEmitter.emit}
      />
      <AddToExistingStaticListModal
        visible={showMassAssign}
        entityType={EntityTypeEnum.SITE}
        selection={buildSelectionCriteria({ selected: [siteId] })}
        onClose={() => setShowMassAssign(false)}
      />

      <NoteDrawer
        id={siteId}
        entity={EntityTypeEnum.SITE}
        displayDrawer={displayDrawer}
        setDisplayDrawer={setDisplayDrawer}
      />
      <AddPersonsToSiteModal
        visible={showAddPersonModal}
        setVisible={setShowAddPersonModal}
        siteId={siteId}
        countryCode={site?.countryCode}
      />
    </div>
  );
};

export default HcoSectionRight;
