import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Tabs, TabsProps } from 'antd';
import MainView from '../../../components/View/MainView';
import PureAdvanceCustomerSchedules from './PureAdvanceCustomerSchedules/PureAdvanceCustomerSchedules';
import PureAdvanceCustomerProfileContainer from './PureAdvanceCustomerProfileContainer/PureAdvanceCustomerProfileContainer';
import { usePermission } from '../../../permission/usePermission';
import CustomerSelectionContainer from './CustomerSelectionContainer/CustomerSelectionContainer';
import { useInstanceDeploymentDetailsQueryQuery } from '../../../../gql/typings';

const InstanceDeploymentDetails: React.FC = () => {
  const match = useMatch('/admin/client/:id');
  const navigate = useNavigate();
  const customerCode = match!.params.id!;

  const controlPermission = usePermission('INSTANCE_DEPLOY');
  const selectionPermission = usePermission('SELECTION');

  const { data, loading } = useInstanceDeploymentDetailsQueryQuery({ variables: { customerCode } });

  return (
    <MainView
      className="instance-deployment-details-container"
      title={data?.pureAdvanceCustomer?.heading}
      titleLoading={loading}
      breadcrumbs={[
        {
          label: 'Admin Settings',
          onClick: () => navigate('/admin'),
        },
        {
          label: 'Clients',
          onClick: () => navigate('/admin/clients/list'),
        },
        {
          label: data?.pureAdvanceCustomer?.heading ?? '',
        },
      ]}
    >
      <Tabs items={[
        {
          key: 'profile',
          label: 'Profile',
          children: 'TBD'
        },
        controlPermission.read && {
          key: 'modules',
          label: 'Modules',
          children: <PureAdvanceCustomerProfileContainer customerCode={customerCode} permission={controlPermission} />
        },
        {
          key: 'schedules',
          label: 'Schedules',
          children: <PureAdvanceCustomerSchedules customerCode={customerCode} />
        },
        selectionPermission.read && {
          key: 'selection',
          label: 'Selection',
          children: <CustomerSelectionContainer customerCode={customerCode} />
        }
      ] as TabsProps['items']}
      />
    </MainView>
  );
};


gql`
  query InstanceDeploymentDetailsQuery($customerCode: String!) {
    pureAdvanceCustomer(customerCode: $customerCode) {
      code
      heading
      
    }
  }
`;

export default InstanceDeploymentDetails;
