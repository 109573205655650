import { Tooltip, Typography } from 'antd';
import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { EntityTypeEnum, FieldEnum, SitePersonCriteriaOrder } from '../../../../gql/typings';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';


export const linkSiteTypeFieldConfig = linkFieldConfig(
  'siteType',
  [
    'id',
    'site.id',
    'site.type.label',
    'site.type.displayLabel',
    'site.type.code',
    'site.type.type',
  ],
  ({ localization }) => ({
    dcrInfo: record => ({
      field: FieldEnum.HCO_TYPE_CODE,
      entityType: EntityTypeEnum.SITE,
      entityAffiliationId: record.site.id,
    }),
    sorting: {
      ascend: SitePersonCriteriaOrder.SITE_TYPE_ASC,
      descend: SitePersonCriteriaOrder.SITE_TYPE_DESC,
    },
    title: localization.formatMessage(Locale.Attribute.Site_type),
    additionalTableConfig: {
      width: 120
    },
    render: (record, { isViewingFromPage }) => {
      const siteType = record.site.type?.label;
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={siteType}>{siteType}</Tooltip>
      </Typography.Paragraph>);
    },
  })
);
