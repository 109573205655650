import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import DisplayContent from '../../../components/Badge/DisplayContent';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SiteCriteriaOrder } from '../../../../gql/typings';

export const siteCountryFieldConfig = siteFieldConfig(
  'country',
  ['countryCode'],
  ({ localization }) => ({
    sorting: {
      ascend: SiteCriteriaOrder.COUNTRY_ASC,
      descend: SiteCriteriaOrder.COUNTRY_DESC,
    },
    title: localization.formatMessage(Locale.Attribute.Country),
    additionalTableConfig: {
      width: 80
    },
    // className: 'apb-antd-table-column-width-no-wrap',
    render: (record) => <DisplayContent>{record.countryCode}</DisplayContent>,
  })
);
