import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client/core';
import { useInterval } from 'ahooks';
import { useApolloClient } from '@apollo/client';
import { Col, List, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { isEqual } from 'lodash';
import {
  ApiSiteMatchInputSearch,
  LiveSearchElasticSiteQuery,
  LiveSearchElasticSiteQueryVariables
} from '../../../gql/typings';


type LiveSiteMatchSearchProps = {
  buildSearch: () => ApiSiteMatchInputSearch;
};

const LiveSiteMatchSearch: React.FC<LiveSiteMatchSearchProps> = ({ buildSearch }) => {
  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<NonNullable<LiveSearchElasticSiteQuery>['elasticSiteMatch']>([]);
  const [search, setSearch] = useState<ApiSiteMatchInputSearch>({});

  useInterval(() => {
    const newSearch = buildSearch();
    if (!isEqual(newSearch, search)) setSearch(newSearch);
  }, 1000);


  useEffect(() => {
    setLoading(true);
    apolloClient.query<LiveSearchElasticSiteQuery, LiveSearchElasticSiteQueryVariables>({
      query: DATA_QUERY,
      variables: { search },
    }).then(res => {
      if (res?.data?.elasticSiteMatch) setResults(res.data.elasticSiteMatch);
    }).finally(() => setLoading(false));
  }, [apolloClient, search]);

  return (
    <div className="live-site-match-search-container">
      <List
        bordered
        header={
          <Row>
            <Col span={18}><b>Did you mean? (Live Search)</b></Col>
            <Col span={6}>
              {loading && <LoadingOutlined />}
            </Col>
          </Row>
        }
      >
        {results.map(record => (
          <List.Item key={record.apurebaseId}>
            <div className="item">
              {record.result.name}
            </div>
          </List.Item>
        ))}
      </List>
    </div>
  );
};

const DATA_QUERY = gql`
  query LiveSearchElasticSite($search: ApiSiteMatchInputSearch!) {
    elasticSiteMatch(search: $search, criteria: { limit: 10, minScore: 0 }) {
      apurebaseId
      score
      inLocalDb
      result {
        id
        address
        city
        country
        ct
        name
        people {
          id
          firstname
        }
        site_type
        spc
        status
        ut
        zip
      }
    }
  }
`;

export default LiveSiteMatchSearch;
