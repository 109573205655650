import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toNumber } from 'lodash';
import { Badge, Divider, message, Modal, Popconfirm, Tooltip } from 'antd';
import { useLocalization } from '../../../../../util/useLocalization';
import { AdminGridConfigurationItemProps } from '../AdminGridConfigurationRight';
import Icon, { ITE } from '../../../../../components/Icon/Icon';
import SortableElements from '../../../../../components/SortableElements/SortableElements';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { ENTITY_GRID_CONFIGURATION_DATA_QUERY } from '../../EntityGridConfigurationDisplay';
import UpdateCustomField from '../../../customFields/UpdateCustomField';
import AdminGridRightAddFieldToFieldsConfigurator, {
  ALL_FIELD_TYPES_QUERY,
} from './AdminGridRightAddFieldToFieldsConfigurator';
import { SELECTED_GRID_ITEM_CONFIGURATION_FRAGMENT } from './AdminGridRightFieldsQueryFragments';
import {
  entityGridItemConfigurationQueryDocument,
  EntityGridItemFieldTypeEnum,
  SelectedFieldsGridItemQueryQuery,
  SelectedFieldsGridItemQueryQueryVariables,
  UpdateGridItemFieldSortingMutation,
  UpdateGridItemFieldSortingMutationVariables,
  useRemoveEntityGridItemMutation
} from '../../../../../../gql/typings';

const icons: Record<EntityGridItemFieldTypeEnum, ITE> = {
  HCP_CONSENT: 'CONSENT',
  HCP_SEGMENTATION: 'SEGMENTATION',
  HCP_EXTERNAL_IDS: 'EXTERNAL_ID',
  HCP_TAGS: 'TAGS',
  HCP_RES_AREAS: 'RES_USERS',
  HCP_CUSTOM_FIELD: 'CUSTOM_FIELD',
  HCO_CUSTOM_FIELD: 'CUSTOM_FIELD',
  LINK_CUSTOM_FIELD: 'CUSTOM_FIELD',
  HCO_EXTERNAL_IDS: 'EXTERNAL_ID',
  HCO_SEGMENTATION: 'SEGMENTATION',
  HCO_TAGS: 'TAGS',
};

type QueriedFieldNode = NonNullable<SelectedFieldsGridItemQueryQuery['entityGridItemConfiguration']>['fields']['nodes']['0'];

const AdminGridRightFieldsConfigurator: React.FC<AdminGridConfigurationItemProps> = ({
  layout,
  entityType,
  tabCode,
  itemConfigurationId,
}) => {
  const localization = useLocalization();
  const [selected, setSelected] = useState<QueriedFieldNode>();
  const {
    data,
    loading,
    refetch,
  } = useQuery<SelectedFieldsGridItemQueryQuery, SelectedFieldsGridItemQueryQueryVariables>(DATA_QUERY, {
    variables: { gridItemId: toNumber(layout.i) },
  });
  const [removeEntityGridItem] = useRemoveEntityGridItemMutation();
  const [
    updateSorting,
    { loading: updatingSortingLoading },
  ] = useMutation<UpdateGridItemFieldSortingMutation, UpdateGridItemFieldSortingMutationVariables>(UPDATE_SORTING);


  const remove = (node: QueriedFieldNode) => {
    removeEntityGridItem({
      variables: { id: node.id },
      refetchQueries: [
        ENTITY_GRID_CONFIGURATION_DATA_QUERY,
        entityGridItemConfigurationQueryDocument,
        ALL_FIELD_TYPES_QUERY,
      ],
    }).then(() => message.success(localization.formatMessage(
      Locale.Text.Group_grid_item_successfully_removed,
      { itemType: localization.formatMessageByStr(node.type.heading) },
    )));
  };

  const onSortUpdate = (rows: QueriedFieldNode[]) => rows.length === 0
    ? message.error('Can\'t update sorting when no fields are available to sort!')
    : updateSorting({
      variables: {
        gridItemConfigurationId: rows[0]!.entityGridItemConfigurationId,
        fieldIds: rows.map(r => r.id),
      },
    }).finally(() => refetch());

  return (
    <div className="admin-grid-right-fields-configurator-container">
      <SortableElements<QueriedFieldNode>
        dataSource={data?.entityGridItemConfiguration?.fields.nodes ?? []}
        rowKey={e => `${e.id}`}
        rowHeight={52}
        onUpdate={onSortUpdate}
        cancelDraggableClassname="right"
        loading={loading || updatingSortingLoading}
        renderItem={item => <div className="item">
          <Icon
            className="left"
            iconType={icons[item.type.enum]}
            text={item.customFieldConfiguration
              ? item.customFieldConfiguration.customField.heading
              : localization.formatMessageByStr(item.type.heading)}
          />
          <div className="right">
            {item.customFieldConfiguration?.customField && <div className="settings">
              <Icon onClick={() => setSelected(item)} iconType="SETTINGS" />
            </div>}
            <div className="remove">
              <Popconfirm
                placement="left"
                onConfirm={() => remove(item)}
                okText={localization.formatMessage(Locale.General.Yes)}
                title={localization.formatMessage(Locale.Text.Are_you_sure)}
              >
                <Icon iconType="CLOSE" />
              </Popconfirm>
            </div>
            {item.customFieldConfiguration?.customField.options != null && <div className="amount-of-options">
              <Tooltip title="Amount of options">
                <Badge
                  style={{ backgroundColor: '#C2CE26' }}
                  count={item.customFieldConfiguration?.customField.options.totalCount}
                />
              </Tooltip>
            </div>}
          </div>
        </div>}
      />
      <Divider />

      <AdminGridRightAddFieldToFieldsConfigurator
        tabCode={tabCode}
        itemConfigurationId={itemConfigurationId}
        entityType={entityType}
      />

      <Modal
        open={!!selected?.customFieldConfiguration?.customField}
        onCancel={() => setSelected(undefined)}
        footer={null}
      >
        {selected?.customFieldConfiguration?.customField && (
          <UpdateCustomField customFieldCode={selected.customFieldConfiguration.customField.code} />
        )}
      </Modal>
    </div>
  );
};

const UPDATE_SORTING = gql`
  mutation UpdateGridItemFieldSorting($gridItemConfigurationId: Int!, $fieldIds: [Int!]!) {
    updateEntityGridItemFieldSorting(gridItemConfigurationId: $gridItemConfigurationId, fieldIds: $fieldIds) {
      id
      sort
    }
  }
`;

gql`
  mutation RemoveEntityGridItem(
    $id: Int!,
  ) {
    removeEntityGridItem(id: $id)
  }
`;

const DATA_QUERY = gql`
  query SelectedFieldsGridItemQuery($gridItemId: Int!) {
    entityGridItemConfiguration(id: $gridItemId) {
      id
      ...SelectedGridItemConfigurationFragment
    }
  }
  ${SELECTED_GRID_ITEM_CONFIGURATION_FRAGMENT}
`;

export default AdminGridRightFieldsConfigurator;
