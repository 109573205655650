import React from 'react';
import { NavLink } from 'react-router-dom';
import { Locale } from '../../../localization/LocalizationKeys';
import { Optional } from '../../util/StateArrayType';
import { useLocalization } from '../../util/useLocalization';
import { DcrGroupDetailQuery, ListTypeEnum } from '../../../gql/typings';

type FilterListsDcrProps = {
  relatedLists: Optional<Extract<
  NonNullable<
  DcrGroupDetailQuery['dcrGroup']>['dcrs']['nodes']['0']['dcrSnapshotInfo']['0']['affiliationEntity'],
  { ['__typename']: 'List' }
  >[]>;
  ownerStatus: boolean | undefined | null;
  responsibleUserId: number | undefined | null;
};

const FilterListsDcr: React.FC<FilterListsDcrProps> = ({
  relatedLists,
  responsibleUserId,
  ownerStatus,
}) => {
  const { pluralMessage, formatMessage } = useLocalization();
  const filterCreaterLists = relatedLists?.filter(it => it.createdUser?.id === responsibleUserId || it.isShared);
  const dynamicLists = relatedLists?.filter(it => it.listType.code === ListTypeEnum.DYN);
  const staticLists = relatedLists?.filter(it => it.listType.code === ListTypeEnum.STAT);
  const privateLists = relatedLists?.filter(it => !it.isShared);
  const dynCount = dynamicLists ? dynamicLists.length : 0;
  const statCount = staticLists ? staticLists.length : 0;
  const privateCount = privateLists ? privateLists.length : 0;

  return (
    <>
      {ownerStatus ? <div>
        {filterCreaterLists?.map(it => <li key={it.id}>
          {formatMessage(Locale.Text.DCR_Hcp_dynamic_list_message_by_type, { type: it.listType.heading })}
          <NavLink to={`/list/${it.id}`}>
            {it.title}
          </NavLink>

        </li>)}
      </div>
        : <div>{dynCount > 0 && <li>{pluralMessage(Locale.Text.DCR_Hcp_dynamic_list_message_count, dynCount)}</li>}
          {statCount > 0 && <li>{pluralMessage(Locale.Text.DCR_Hcp_static_list_message_count, statCount)}</li>}
          {privateCount > 0
          && <li>{pluralMessage(Locale.Text.DCR_Hcp_private_list_message_count, privateCount)}</li>}</div>}

    </>
  );
};


export default FilterListsDcr;
