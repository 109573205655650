import { get as getFp } from 'lodash/fp';
import { gql } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Tooltip } from 'antd';
import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  SitePersonCriteriaOrder,
  TherapeuticFocusSuggestionQueryQuery,
  TherapeuticFocusSuggestionQueryQueryVariables,
  UpdateTherapeuticFocusesMutationMutationVariables
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';

export const linkTherapeuticFocusFieldConfig = linkFieldConfig(
  'therapeuticFocus',
  [
    'id',
    'countryCode',
    'therapeuticFocuses.hash',
    'therapeuticFocuses.nodes.code',
    'therapeuticFocuses.nodes.type',
    'therapeuticFocuses.nodes.label',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Text.Therapeutic_focus),
    additionalTableConfig: {
      width: 150,
      ellipsis: {
        showTitle: false
      }
    },
    sorting: {
      ascend: SitePersonCriteriaOrder.THERAPEUTIC_FOCUS_ASC,
      descend: SitePersonCriteriaOrder.THERAPEUTIC_FOCUS_DESC,
    },
    render: (link, { isViewingFromPage }) => {
      const therapeuticField = link.therapeuticFocuses?.nodes.map(getFp('label')).join(', ');
      return <Tooltip placement='left' title={therapeuticField}>{therapeuticField}</Tooltip>;
    },
    preserve: true,
    updateView: buildSelectUpdateFieldConfig({
      mode: 'multiple',
      mutation: UPDATE_THERAPEUTIC_FOCUS_MUTATION,
      selectedKeys: record => record.therapeuticFocuses.nodes.map(n => n.code),
      modalHeading: localization.formatMessage(Locale.Command.Select_therapeutic_focus),
      buildVariables: (selectedKeys, record) => ({
        linkId: record.id,
        therapeuticFocusCodes: selectedKeys,
      }) as UpdateTherapeuticFocusesMutationMutationVariables,
      fetchSuggestions: ({ client, record }) => (
        client.query<TherapeuticFocusSuggestionQueryQuery, TherapeuticFocusSuggestionQueryQueryVariables>({
          query: SUGGESTION_QUERY,
          variables: { countryCode: record.countryCode },
        }).then(res => res.data.therapeuticFocuses.nodes)
      ),
    }),
  })
);

const UPDATE_THERAPEUTIC_FOCUS_MUTATION = gql`
  mutation UpdateTherapeuticFocusesMutation($linkId: Int!, $therapeuticFocusCodes: [String!]!) {
    updateSitePersonTherapeuticFocuses(sitePersonId: $linkId, therapeuticFocusCodes: $therapeuticFocusCodes) {
      id
      therapeuticFocusCode
      sitePerson {
        id
        therapeuticFocuses {
          hash
          nodes {
            code
            label
            type
          }
        }
      }
    }
  }
`;

const SUGGESTION_QUERY = gql`
  query TherapeuticFocusSuggestionQuery($search: String, $countryCode: CountryCode!) {
    therapeuticFocuses(criteria: { label: $search, countries: [$countryCode], fetchSize: { limit: 1000 } }) {
      hash
      nodes {
        code
        label
        type
      }
    }
  }
`;
