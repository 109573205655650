import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload as AntUpload, message } from 'antd';
import { useIntl } from 'react-intl';
import { getBearerToken } from '../../../util/rest';
import { Locale } from '../../../../localization/LocalizationKeys';

type FileStepProps = {
  templateId: number;
  setProjectId: (projectId: number) => void;
  done: () => void;
};

const FileStep: React.FC<FileStepProps> = ({
  templateId,
  setProjectId,
  done
}) => {
  const intl = useIntl();
  return (
    <AntUpload.Dragger
      name="file"
      action={`/import/${templateId}`}
      multiple={false}
      headers={{ authorization: getBearerToken()! }}
      height={250}
      onChange={(info) => {
        const { file } = info;
        if (file.status === 'done') {
          if (file.response.errors) {
            // @ts-ignore TODO: Do not use ts-ignore!
            file.response.errors.map(err => message.error(err.message));
          } else {
            const projectId = info.file.response;
            message.success(intl.formatMessage(Locale.Text.File_uploaded_successfully, { name: file.name }));
            setProjectId(projectId);
            done();
          }
        } else if (file.status === 'error') {
          message.error(intl.formatMessage(Locale.Text.File_upload_failed, { name: file.name }));
        }
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        {intl.formatMessage(Locale.Text.Click_to_drag_a_file_to_this_area_to_upload)}
      </p>
      <p className="ant-upload-hint">
        {intl.formatMessage(Locale.Text.Support_for_single_csv_file)}
        <br />
        {intl.formatMessage(Locale.Text.Strictly_prohibited_from_uploading_company_data_or_other)}

      </p>
    </AntUpload.Dragger>
  );
};

export default FileStep;
