import React from 'react';
import { Modal } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { LinkColumn } from '../../../components/Table/utils';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { EntitiesSearch } from '../../../components/entitiesSearch';
import {
  EntityTypeEnum,
  NewLinkFromExistingMutationMutation,
  NewLinkFromExistingMutationMutationVariables
} from '../../../../gql/typings';
import { usePermission } from '../../../permission/usePermission';
import { useTableRowSelection } from '../../../components/Table/useTableRowSelection';

export const linkSiteFieldConfig = linkFieldConfig(
  'updatableSite',
  [
    'id',
    'site.id',
    'site.name',
    'controlSetting.id',
    'controlSetting.entityTypeId',
    'controlSetting.maintainerSourceCode',
    'controlSetting.createDcr',
  ],
  ({ localization, openInNewTab }) => ({
    title: localization.formatMessage(Locale.Attribute.Site),
    additionalTableConfig: {
      width: 150
    },
    render: record => (
      <LinkColumn
        text={record.site.name}
        url={`/hco/${record.site.id}`}
        openInNewTab={openInNewTab}
      />
    ),
    preserve: true,
    hasUpdateSupport: () => usePermission('DATA_MANAGEMENT').create,
    updateView: props => {
      const [update] = useMutation<NewLinkFromExistingMutationMutation,
      NewLinkFromExistingMutationMutationVariables>(NEW_LINK_FROM_EXISTING_MUTATION);
      const tableSelection = useTableRowSelection({ virtualSupport: false, type: 'radio' });

      return (
        <Modal
          onCancel={props.endEditing}
          open
          width="clamp(600px, 60%, 1400px)"
          footer={false}
        >
          <EntitiesSearch
            entityType={EntityTypeEnum.SITE}
            tableSelection={tableSelection}
            openInNewTab
            quickActions={[
              {
                key: 'select-site-from-linkSiteFieldConfig',
                label: localization.formatMessage(Locale.Command.Select_A_Site),
                disabled: tableSelection.selection.count != 1,
                onClick: () => update({
                  variables: {
                    linkId: props.record.id,
                    siteId: tableSelection.selection.selected[0]!,
                  }
                }).then(props.endEditing)
              }
            ]}
          />
        </Modal>
      );
    }
  })
);

const NEW_LINK_FROM_EXISTING_MUTATION = gql`
  mutation NewLinkFromExistingMutation($linkId: ID!, $siteId: ID!) {
    newLinkFromExisting(linkId: $linkId, siteId: $siteId) {
      id
      person {
        id
        sites {
          hash
          nodes {
            id
          }
        }
        affiliations {
          hash
          nodes {
            id
          }
        }
      }
    }
  }
`;
