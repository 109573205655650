import React from 'react';
import { gql } from '@apollo/client';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import {
  MergeItemSiteExternalIdFragment,
  EntityMergeFragment_Site_Fragment,
  useMergeRequestExternalIdMutation
} from '../../../../../../../../../gql/typings';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { Optional } from '../../../../../../../../util/StateArrayType';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';

const AdminMergeItemSiteExternalId: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemSiteExternalIdFragment;
  const fromSite = props.from as EntityMergeFragment_Site_Fragment;
  const toSite = props.into as Optional<EntityMergeFragment_Site_Fragment>;
  const localization = useLocalization();

  const [setExternalId] = useMergeRequestExternalIdMutation();

  if (!props.item.hasConflict) return (
    <AdminMergeItemBasicField {...props}>
      {localization.formatMessage(Locale.Text.Merge_request_external_id_transfer_description, {
        value: <strong>{info.externalId.externalId}</strong>,
        record1: <strong>{fromSite.name}</strong>,
        record2: <strong>{toSite?.name}</strong>,
        externalIdType: info.externalId.type.isHeadingKey
          ? <strong>{localization.formatMessageByStr(info.externalId.type.heading)}</strong>
          : <strong>{info.externalId.type.heading}</strong>,
      })}
    </AdminMergeItemBasicField>
  );

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.externalId.externalId}
      toValue={info.existingExternalId?.externalId}
      updateMutation={newValue => setExternalId({
        variables: {
          existingExternalIdId: info.existingExternalId?.id ?? -1,
          removeOld: !!info.existingExternalId,
          input: {
            externalId: newValue,
            internalId: toSite!.id,
            externalIdTypeCode: info.externalId.type.code,
          },
        },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_external_id_mismatch_description, {
        id1: <strong>{info.externalId.externalId}</strong>,
        id2: <strong>{info.existingExternalId?.externalId}</strong>,
        externalIdType: info.externalId.type.isHeadingKey
          ? <strong>{localization.formatMessageByStr(info.externalId.type.heading)}</strong>
          : <strong>{info.externalId.type.heading}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  fragment MergeItemSiteExternalId on SiteExternalId_EntityDataToBeTransferredType {
    code
    externalId {
      id
      type {
        code
        heading
        isHeadingKey
      }
      externalId
    }
    existingExternalId {
      id
      externalId
    }
  }
`;

export default AdminMergeItemSiteExternalId;
