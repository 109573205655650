import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { Radio } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import { DashboardItemProps } from '../DashboardItemTypes';
import DashboardCard from '../DashboardCard';
import { LineChart, LineChartDataItem } from '../../Charts';
import {
  DashboardRecordsImportedItemQueryQuery,
  DashboardRecordsImportedItemQueryQueryVariables,
  GroupByDateRanges
} from '../../../../gql/typings';

const displayFormat: Record<GroupByDateRanges, string> = {
  YEAR: 'YYYY',
  MONTH: 'MMMM YYYY',
  WEEK: 'WW GGGG',
  DAY: 'LL',
  HOUR: 'LLLL',
  MINUTE: 'LLLL',
};

const DashboardRecordsImportedItem: React.FC<DashboardItemProps> = (props) => {
  const intl = useIntl();
  const [grouping, setGrouping] = useState(GroupByDateRanges.MONTH);
  const { data, refetch } = useQuery<DashboardRecordsImportedItemQueryQuery, DashboardRecordsImportedItemQueryQueryVariables>(
    DATA_QUERY,
    { variables: { grouping } },
  );

  useEffect(() => {
    refetch({ grouping });
  }, [refetch, grouping]);

  const dataPoints: LineChartDataItem[] = (data?.dashboardRecordsImportedDataPoints ?? []).map(item => ({
    label: moment(item.time).format(displayFormat[grouping]),
    value: item.value,
  }));

  return (
    <DashboardCard
      title="Records imported"
      className="dashboard-records-imported-item-container"
      id={props.dashboardItemId}
    >
      <Radio.Group
        name="radiogroup"
        className="radio-for-line"
        defaultValue={grouping}
        onChange={e => setGrouping(e.target.value)}
      >
        <Radio value={GroupByDateRanges.YEAR}>
          {intl.formatMessage(Locale.Attribute.Year)}
        </Radio>
        <Radio value={GroupByDateRanges.MONTH}>
          {intl.formatMessage(Locale.Attribute.Month)}
        </Radio>
        <Radio value={GroupByDateRanges.WEEK}>
          {intl.formatMessage(Locale.Attribute.Week)}
        </Radio>
        <Radio value={GroupByDateRanges.DAY}>
          {intl.formatMessage(Locale.Attribute.Day)}
        </Radio>
      </Radio.Group>
      <LineChart
        data={dataPoints}
        width={props.size.width * 0.7}
        height={props.size.height * 0.55}
      />
    </DashboardCard>
  );
};

const DATA_QUERY = gql`
  query DashboardRecordsImportedItemQuery($grouping: GroupByDateRanges!) {
    dashboardRecordsImportedDataPoints(grouping: $grouping) {
      time
      value
    }
  }
`;

export default DashboardRecordsImportedItem;
