import React from 'react';
import { gql } from '@apollo/client';
import { DatePicker } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { MergeItemPersonBirthFragment, useUpdatePersonBirthMutation } from '../../../../../../../../../gql/typings';
import { DATE_FORMAT as DateFormat } from '../../../../../../../../util/format';

const DATE_FORMAT = DateFormat;

const AdminMergeItemPersonBirth: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const localization = useLocalization();
  const info = props.item.type as MergeItemPersonBirthFragment;
  const [updateBirth] = useUpdatePersonBirthMutation();

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.birthFrom ? dayjs(info.birthFrom) : undefined}
      toValue={info.birthTo ? dayjs(info.birthTo) : undefined}
      inputRenderer={props => <DatePicker {...props} />}
      valueDisplayMapper={v => v ? v.format(DATE_FORMAT) : ''}
      updateMutation={newValue => updateBirth({
        variables: {
          personId: props.into.id,
          newValue,
        },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_birth_mismatch_description, {
        birthday1: <strong>{info.birthFrom ? moment(info.birthFrom).format(DATE_FORMAT) : undefined}</strong>,
        birthday2: <strong>{info.birthTo ? moment(info.birthTo).format(DATE_FORMAT) : undefined}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation UpdatePersonBirth($personId: Int!, $newValue: DateTime) {
    updatePersonDateOfBirth(persId: $personId, date: $newValue) {
      id
      birth
    }
  }
`;

gql`
  fragment MergeItemPersonBirth on PersonBirth_EntityDataToBeTransferredType {
    code
    birthFrom: from
    birthTo: to
  }
`;

export default AdminMergeItemPersonBirth;
