import React from 'react';
import { NavLink } from 'react-router-dom';

type LinkBadgeProps = {
  url?: string;
  openInNewTab?: boolean;
  label?: React.ReactNode|string|number;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
};

const LinkBadge: React.FC<LinkBadgeProps> = ({
  url,
  openInNewTab = false,
  label,
  style,
  children,
  className,
}) => {
  if (!url && !label && !openInNewTab) return <></>;
  return url ? (
    <NavLink className={className} style={style} to={url} target={openInNewTab ? '_blank' : '_self'}>
      {label || children}
    </NavLink>
  ) : (
    (label && <span>{label}</span>) || <>{children}</> || <></>
  );
};


export default LinkBadge;
