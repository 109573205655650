import { useEffect, useState } from 'react';
import { debounce } from 'lodash';

export const useResponsive = () => {
  // screen resolutions
  const [state, setState] = useState({
    isTablet: false,
    isDesktop: false,
  });


  // update the state on window resize
  const onResizeHandler = () => {
    const isTablet = window.innerWidth <= 1000;
    const isDesktop = window.innerWidth > 1000;

    setState({ isTablet, isDesktop });
  };

  const debounceHandler = debounce(onResizeHandler, 500);

  useEffect(() => {
    // update the state on the initial load
    onResizeHandler();

    window.addEventListener('resize', debounceHandler, false);

    return () => {
      // remove the event
      window.removeEventListener('resize', debounceHandler, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return state;
};
