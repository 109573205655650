import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { reactJoin } from '../../../util/reactJoin';
import { personFieldConfig } from './utils/personFieldsUtils';

export const personSiteNamesFieldConfig = personFieldConfig(
  'siteNames',
  [
    'affiliations.hash',
    'affiliations.nodes.id',
    'affiliations.nodes.site.id',
    'affiliations.nodes.site.name',
  ],
  ({ localization, openInNewTab }) => ({
    additionalTableConfig: {
      width: 100
    },
    title: localization.formatMessage(Locale.Attribute.Site_names),
    render: record => reactJoin(
      record.affiliations.nodes.map((n: { id: number; site: { name: string; id: number } }) => (
        <LinkBadge
          key={n.id}
          url={`/hco/${n.site.id}`}
          label={n.site.name}
          openInNewTab={openInNewTab}
        />
      ))
    ),
  })
);
