import React, { CSSProperties } from 'react';
// Flags taken from:
//   * circle:    https://tnovau.github.io/react-circle-flags/gallery
//   * square:    https://www.flaticon.com/packs/square-country-simple-flags?k=1625141069815
//   * rectangle: https://www.flaticon.com/packs/rectangular-country-simple-flags
import dkFlagCircle from '../../../img/flag/circle/dk.svg';
import noFlagCircle from '../../../img/flag/circle/no.svg';
import seFlagCircle from '../../../img/flag/circle/se.svg';
import fiFlagCircle from '../../../img/flag/circle/fi.svg';
import chFlagCircle from '../../../img/flag/circle/ch.svg';
import itFlagCircle from '../../../img/flag/circle/it.svg';
import atFlagCircle from '../../../img/flag/circle/at.svg';
import skFlagCircle from '../../../img/flag/circle/sk.svg';
import eeFlagCircle from '../../../img/flag/circle/ee.svg';
import lvFlagCircle from '../../../img/flag/circle/lv.svg';
import ltFlagCircle from '../../../img/flag/circle/lt.svg';
import nlFlagCircle from '../../../img/flag/circle/nl.svg';
import beFlagCircle from '../../../img/flag/circle/be.svg';
import xxFlagCircle from '../../../img/flag/circle/xx.svg';

import dkFlagSquare from '../../../img/flag/sqaure/dk.svg';
import noFlagSquare from '../../../img/flag/sqaure/no.svg';
import seFlagSquare from '../../../img/flag/sqaure/se.svg';
import fiFlagSquare from '../../../img/flag/sqaure/fi.svg';
import chFlagSquare from '../../../img/flag/sqaure/ch.svg';
import itFlagSquare from '../../../img/flag/sqaure/it.svg';
import atFlagSquare from '../../../img/flag/sqaure/at.svg';
import skFlagSquare from '../../../img/flag/sqaure/sk.svg';
import eeFlagSquare from '../../../img/flag/sqaure/ee.svg';
import lvFlagSquare from '../../../img/flag/sqaure/lv.svg';
import ltFlagSquare from '../../../img/flag/sqaure/lt.svg';
import nlFlagSquare from '../../../img/flag/sqaure/nl.svg';
import beFlagSquare from '../../../img/flag/sqaure/be.svg';

import dkFlagRectangle from '../../../img/flag/rectangle/dk.svg';
import noFlagRectangle from '../../../img/flag/rectangle/no.svg';
import seFlagRectangle from '../../../img/flag/rectangle/se.svg';
import fiFlagRectangle from '../../../img/flag/rectangle/fi.svg';
import chFlagRectangle from '../../../img/flag/rectangle/ch.svg';
import itFlagRectangle from '../../../img/flag/rectangle/it.svg';
import atFlagRectangle from '../../../img/flag/rectangle/at.svg';
import skFlagRectangle from '../../../img/flag/rectangle/sk.svg';
import eeFlagRectangle from '../../../img/flag/rectangle/ee.svg';
import lvFlagRectangle from '../../../img/flag/rectangle/lv.svg';
import ltFlagRectangle from '../../../img/flag/rectangle/lt.svg';
import nlFlagRectangle from '../../../img/flag/rectangle/nl.svg';
import beFlagRectangle from '../../../img/flag/rectangle/be.svg';
import { LocalizationShape, useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';

// TODO: Figure out how to properly lazy load svg images!

export type FlagCountry = 'DK'|'NO'|'SE'|'FI'|'CH'|'IT'|'AT'|'SK'|'NL'|'EE'|'LT'|'LV'|'BE';
export type FlagCountryWithUnknown = undefined|null|'XX'|FlagCountry;

export const CountryLabel: Record<FlagCountry, { id: string }> = {
  AT: Locale.General.Austria,
  CH: Locale.General.Switzerland,
  DK: Locale.General.Denmark,
  FI: Locale.General.Finland,
  IT: Locale.General.Italy,
  NO: Locale.General.Norway,
  SE: Locale.General.Sweden,
  SK: Locale.General.Slovakia,
  EE: Locale.General.Estonia,
  LV: Locale.General.Latvia,
  LT: Locale.General.Lithuania,
  NL: Locale.General.Netherlands,
  BE: Locale.General.Belgium,
};

type ImageProps = {
  height: number;
  width: number;
  type: 'circle'|'square'|'rectangle';
  localization: LocalizationShape;
  style?: CSSProperties;
};

const images: Record<Exclude<FlagCountryWithUnknown, null|undefined>, React.FC<ImageProps>> = {
  DK: ({ width, height, type, localization, style }) => <img
    src={type === 'circle' ? dkFlagCircle : (type === 'square' ? dkFlagSquare : dkFlagRectangle)}
    width={width}
    height={height}
    alt={localization.formatMessage(CountryLabel.DK)}
    style={style}
  />,
  NO: ({ width, height, type, localization, style }) => <img
    src={type === 'circle' ? noFlagCircle : (type === 'square' ? noFlagSquare : noFlagRectangle)}
    width={width}
    height={height}
    alt={localization.formatMessage(CountryLabel.NO)}
    style={style}
  />,
  SE: ({ width, height, type, localization, style }) => <img
    src={type === 'circle' ? seFlagCircle : (type === 'square' ? seFlagSquare : seFlagRectangle)}
    width={width}
    height={height}
    alt={localization.formatMessage(CountryLabel.SE)}
    style={style}
  />,
  FI: ({ width, height, type, localization, style }) => <img
    src={type === 'circle' ? fiFlagCircle : (type === 'square' ? fiFlagSquare : fiFlagRectangle)}
    width={width}
    height={height}
    alt={localization.formatMessage(CountryLabel.FI)}
    style={style}
  />,
  CH: ({ width, height, type, localization, style }) => <img
    src={type === 'circle' ? chFlagCircle : (type === 'square' ? chFlagSquare : chFlagRectangle)}
    width={width}
    height={height}
    alt={localization.formatMessage(CountryLabel.CH)}
    style={style}
  />,
  IT: ({ width, height, type, localization, style }) => <img
    src={type === 'circle' ? itFlagCircle : (type === 'square' ? itFlagSquare : itFlagRectangle)}
    width={width}
    height={height}
    alt={localization.formatMessage(CountryLabel.IT)}
    style={style}
  />,
  AT: ({ width, height, type, localization, style }) => <img
    src={type === 'circle' ? atFlagCircle : (type === 'square' ? atFlagSquare : atFlagRectangle)}
    width={width}
    height={height}
    alt={localization.formatMessage(CountryLabel.AT)}
    style={style}
  />,
  SK: ({ width, height, type, localization, style }) => <img
    src={type === 'circle' ? skFlagCircle : (type === 'square' ? skFlagSquare : skFlagRectangle)}
    width={width}
    height={height}
    alt={localization.formatMessage(CountryLabel.SK)}
    style={style}
  />,
  EE: ({ width, height, type, localization, style }) => <img
    src={type === 'circle' ? eeFlagCircle : (type === 'square' ? eeFlagSquare : eeFlagRectangle)}
    width={width}
    height={height}
    alt={localization.formatMessage(CountryLabel.EE)}
    style={style}
  />,
  LV: ({ width, height, type, localization, style }) => <img
    src={type === 'circle' ? lvFlagCircle : (type === 'square' ? lvFlagSquare : lvFlagRectangle)}
    width={width}
    height={height}
    alt={localization.formatMessage(CountryLabel.LV)}
    style={style}
  />,
  LT: ({ width, height, type, localization, style }) => <img
    src={type === 'circle' ? ltFlagCircle : (type === 'square' ? ltFlagSquare : ltFlagRectangle)}
    width={width}
    height={height}
    alt={localization.formatMessage(CountryLabel.LT)}
    style={style}
  />,
  NL: ({ width, height, type, localization, style }) => <img
    src={type === 'circle' ? nlFlagCircle : (type === 'square' ? nlFlagSquare : nlFlagRectangle)}
    width={width}
    height={height}
    alt={localization.formatMessage(CountryLabel.NL)}
    style={style}
  />,
  BE: ({ width, height, type, localization, style }) => <img
    src={type === 'circle' ? beFlagCircle : (type === 'square' ? beFlagSquare : beFlagRectangle)}
    width={width}
    height={height}
    alt={localization.formatMessage(CountryLabel.BE)}
    style={style}
  />,
  XX: ({ width, height, localization, style }) => <img
    src={xxFlagCircle}
    width={width}
    height={height}
    alt={localization.formatMessage(Locale.Text.Unknown_flag)}
    style={style}
  />,
};

const Flag: React.FC<{
  country?: FlagCountryWithUnknown|null;
  rectangle?: boolean;
  square?: boolean;
  width?: number;
  height?: number;
  style?: CSSProperties;
}> = ({
  country,
  square,
  rectangle,
  height,
  width,
  style,
}) => {
  const localization = useLocalization();
  const Flag = (country && images[country]) || images.XX;
  return <Flag
    height={height ?? 30}
    width={width ?? 30}
    type={rectangle ? 'rectangle' : (square ? 'square' : 'circle')}
    localization={localization}
    style={style}
  />;
};

export default Flag;
