import React, { createContext, useContext } from 'react';
import { Select, Space } from 'antd';
import { useIntl } from 'react-intl';
import { Moment } from 'moment';

type FormattedMessageType = {
  id: string;
};

export type SliderActionOptionValue = {
  label: FormattedMessageType;
  format?: string|((v: Moment) => string);
  groupBy?: (v: Moment) => number;
};

export type SliderActionsContextType = {
  label?: string;
  options: Array<SliderActionOptionValue>;
  selected?: null|SliderActionOptionValue;
  setSelected: (value: SliderActionOptionValue) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapGroup?: <T> (values: T[]) => any[];
};

// @ts-ignore
export const SliderActionContext = createContext<SliderActionsContextType>({});

const SliderActions = () => {
  const intl = useIntl();
  const {
    label,
    options,
    selected,
    setSelected,
  } = useContext(SliderActionContext);
  const onChange = (v: string) => {
    const selected = options.find(o => o.label.id === v);
    if (selected === undefined) throw Error();
    setSelected(selected);
  };

  return (
    <div className="slider-action-container-tmp">
      <Space>
        <span>{(label && label.length > 0) ? `${label}:` : ''}</span>
        <Select
          defaultValue={selected?.label.id ?? ''}
          onChange={onChange}
        >
          {options.map(option => (
            <Select.Option key={option.label.id} value={option.label.id}>
              {intl.formatMessage(option.label)}
            </Select.Option>
          ))}
        </Select>
      </Space>
    </div>
  );
};

export default SliderActions;
