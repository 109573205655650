import React from 'react';
import { Modal, Typography } from 'antd';
import { StateArray } from '../../util/StateArrayType';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';

type DcrModalConfirmProps = {
  visibleState: StateArray<boolean>;
  onAccept: () => void;
  messageKey: { id: string };
};

const DcrModalConfirm: React.FC<DcrModalConfirmProps> = ({
  visibleState: [visible, setVisible],
  onAccept,
  messageKey,
}) => {
  const localization = useLocalization();

  return (
    <Modal
      title={localization.formatMessage(Locale.Command.Confirm)}
      open={visible}
      onOk={onAccept}
      onCancel={() => setVisible(false)}
      okText={localization.formatMessage(Locale.General.Yes)}
      cancelText={localization.formatMessage(Locale.General.No)}
    >
      <div style={{ textAlign: 'center' }}>
        <Typography.Text>{localization.formatText(messageKey)}</Typography.Text>
        <p>{localization.formatMessage(Locale.Text.Do_you_want_to_submit_dcr)}</p>
      </div>
    </Modal>
  );
};

export default DcrModalConfirm;
