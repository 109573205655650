import React from 'react';
import { isNumber } from 'lodash';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';

type ApbResultsHeaderForTableProps = {
  count?: number;
  selectedLabel?: React.ReactNode|null;
};

const ApbTableHeader: React.FC<ApbResultsHeaderForTableProps> = (props) => {
  const localization = useLocalization();

  return (
    <div className="apb-results-header-for-table-container">
      <div className="results">
        <div>
          <span className={`count ${isNumber(props.count) ? '' : 'loading'}`}>
            {localization.pluralMessageWithLoading(Locale.Text.search_results, props.count)}
          </span>
          <span className="selected-count">
            {props?.selectedLabel}
          </span>
        </div>
      </div>
      {props.children}
    </div>
  );
};

export default ApbTableHeader;
