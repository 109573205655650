import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { isArray, isEmpty } from 'lodash';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { RecursivePartial } from '../../../util/StateArrayType';
import CreatePersonAddWorkspace from '../../sitePerson/create/CreatePersonAddWorkspace';
import LivePersonMatchSearch from '../../../components/LiveSearch/LivePersonMatchSearch';
import {
  EntityTypeEnum,
  PersonInput
} from '../../../../gql/typings';
import { createPersonFields } from './CreatePersonFields';
import Loading from '../../../components/Loading/Loading';
import { useSystemCountriesState } from '../../../util/useSystemCountriesState';
import { useEntityFieldData } from '../../../util/useEntityFieldData';
import { useQueryParams } from '../../../util/useQueryParams';

export const PersonInputCodeMap = {
  APB_FNAME: 'firstName',
  APB_LNAME: 'lastName',
  APB_SEX: 'sex',
  APB_PHONE: 'contact',
  APB_PTITLE: 'title',
  APB_SRC: 'maintainerSource',
  APB_PTYPE: 'persTypeCode',
  APB_COUNTRY: 'countryCode',
};

type CreatePersonProps = {
  initialValues?: RecursivePartial<PersonInput>;
  loading?: boolean;
  allowAddWorkplace?: true;
};

export type CreatePersonRef = {
  clear: () => void;
};


const CreatePerson = forwardRef<CreatePersonRef, CreatePersonProps>(({
  initialValues,
  loading,
  allowAddWorkplace
}, ref) => {
  const localization = useLocalization();
  const addWorkspace = useState(false);
  const [form] = Form.useForm();
  const [systemCountries] = useSystemCountriesState();
  const queryObject = useQueryParams();

  const { fieldKeys, fieldLoading } = useEntityFieldData(systemCountries?.[0], EntityTypeEnum.PERSON);

  useEffect(() => {
    if (!initialValues) {
      if (!isEmpty(queryObject)) {
        const tempInitValues = Object.keys(queryObject).reduce((acc, curr) => {
          if (!curr) return acc;
          if (!Object.prototype.hasOwnProperty.call(PersonInputCodeMap, curr)) return acc;
          // @ts-ignore
          const mappedKey = PersonInputCodeMap[curr];
          return { ...acc, [mappedKey]: queryObject[curr] };
        }, {});
        form.setFieldsValue(tempInitValues);
      }
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [queryObject, initialValues, form]);

  useImperativeHandle(ref, () => ({
    clear: () => {
      const emptyInitialValues = initialValues
        ? Object.keys(initialValues).reduce((acc, curr) => ({ ...acc, [curr]: undefined }), {})
        : {};
      form.setFieldsValue(emptyInitialValues);
    }
  }), [form, initialValues]);

  if (fieldLoading) return <Loading />;


  return (
    <div className="create-person-container">
      <Form
        name="person"
        form={form}
        labelCol={{ xs: { span: 24 }, sm: { span: 7 }, md: { span: 6 }, lg: { span: 4 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 17 } }}
        initialValues={initialValues}
      >
        <Row>
          <Col span={15}>
            {fieldKeys && Object.keys(fieldKeys).map(f => {
              const key = f as keyof Omit<PersonInput, 'workplace'>;
              const Component = createPersonFields[key];
              if (isArray(fieldKeys[f])) {
                return <Component key={f} keyCodes={fieldKeys[f] as string[]} />;
              }
              return <Component key={f} />;
            })}
          </Col>
          <Col span={8} offset={1}>
            <Form.Item shouldUpdate={() => true} noStyle>
              {({ getFieldsValue }) => (
                <LivePersonMatchSearch build={() => {
                  const values = getFieldsValue() as Partial<PersonInput>;
                  return {
                    person: {
                      firstname: values.firstName,
                      lastname: values.lastName,
                      title: values.title,
                      gender: values.sex,
                      country: values.countryCode,
                      personType: values.persTypeCode?.[0],
                      officialId: values.externalIds
                        ?.filter(e => e.externalIdTypeCode === 'APB_OFFICIAL')?.[0]
                        ?.externalIdValue,
                    },
                    site: {
                      zip: values.addresses?.[0]?.postalCode,
                      city: values.addresses?.[0]?.city,
                      address: values.addresses?.[0]?.street,
                    },
                  };
                }}
                />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={15}>
            <div className="bottom-actions">
              <Button type="primary" htmlType="submit" loading={loading}>
                {localization.formatMessage(Locale.Command.Create)}
              </Button>
              {allowAddWorkplace && (
                <Form.Item
                  noStyle
                  shouldUpdate={(prev: PersonInput, curr: PersonInput) => prev.countryCode !== curr.countryCode}
                >
                  {({ getFieldValue }) => (
                    <CreatePersonAddWorkspace
                      countryCode={getFieldValue('countryCode')}
                      visibleState={addWorkspace}
                      initialState={initialValues?.workplace}
                    />
                  )}
                </Form.Item>)}
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
});


export default CreatePerson;
