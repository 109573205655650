import React from 'react';
import { Select } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  SiteSpecialityTypeQueryQuery,
  SiteSpecialityTypeQueryQueryVariables,
} from '../../../../gql/typings';
import { useLocalization } from '../../../util/useLocalization';

type SiteSectorFormInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
};

const SiteSectorFormInput: React.FC<SiteSectorFormInputProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const localization = useLocalization();
  const {
    data,
    loading,
    refetch,
  } = useQuery<SiteSpecialityTypeQueryQuery, SiteSpecialityTypeQueryQueryVariables>(SITE_SPECIALITY_DATA_QUERY, {
    variables: { search: '%' },
  });
  const searchDebounced = debounce(
    search => refetch({ search: `%${search}%` }),
    25,
  );

  return (
    <Select
      placeholder={localization.formatMessage(Locale.Command.Select_Site_Sector_Type)}
      style={{ minWidth: 120 }}
      loading={loading}
      value={value as string|undefined}
      showSearch
      onSearch={searchDebounced}
      filterOption={false}
      onChange={e => {
        onChange?.(e);
        searchDebounced('');
      }}
      disabled={disabled}
      allowClear
    >
      {
        (data?.siteSectorTypes.nodes ?? []).map(st => (
          <Select.Option key={st!.code} value={st!.code}>
            {
              st!.label
            }
          </Select.Option>
        ))
      }
    </Select>
  );
};

const SITE_SPECIALITY_DATA_QUERY = gql`
  query SiteSpecialityTypeQuery($search: String) {
    siteSectorTypes(criteria: { label: $search}) {
      hash
      nodes {
        code
        label
      }
    }
  }
`;

export default SiteSectorFormInput;
