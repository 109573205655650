import React from 'react';
import { Alert } from 'antd';
import AnchoredSections, { AnchoredItem } from '../../adminComponents/AdminSection/AnchoredSections';

const AddressAdminSettings: React.FC = () => (
  <div className="address-admin-settings-container">
    <AnchoredSections>
      <AnchoredItem anchor="general" title="General">
        <Alert message="Configuration of address is coming soon" />
      </AnchoredItem>

      <AnchoredItem anchor="country" title="Countries">
        Country
      </AnchoredItem>

      <AnchoredItem anchor="county" title="Counties">
        County
      </AnchoredItem>

      <AnchoredItem anchor="postal-code" title="Postal Codes">
        Postal Code
      </AnchoredItem>
    </AnchoredSections>
  </div>
);

export default AddressAdminSettings;
