import React, { useEffect } from 'react';
import { List, Image, Modal, Skeleton, Typography } from 'antd';
import { gql } from '@apollo/client/core';
import { StateArray } from '../../../../util/StateArrayType';
import {
  JiraVersionReleaseNotesQueryQuery,
  PureAdvanceReleasesQueryQuery, useJiraVersionReleaseNotesQueryQuery
} from '../../../../../gql/typings';

type InstanceReleaseModalProps = {
  selectedState: StateArray<NonNullable<PureAdvanceReleasesQueryQuery['releases']>['values']['0']|undefined>;
};

const InstanceReleaseModal: React.FC<InstanceReleaseModalProps> = ({
  selectedState: [selected, setSelected],
}) => {
  const { data, loading, refetch } = useJiraVersionReleaseNotesQueryQuery({
    variables: { version: '' },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (selected?.version) refetch({
      version: selected.version,
    });
  }, [refetch, selected]);

  const groups = data?.jiraIssues?.issues.reduce((acc, curr) => {
    const items = [...(acc[curr.fields.issuetype!.id] ?? []), curr];
    return { ...acc, [curr.fields.issuetype!.id]: items };
  }, {} as Record<string, NonNullable<JiraVersionReleaseNotesQueryQuery['jiraIssues']>['issues']>);

  return (
    <Modal
      open={!!selected}
      destroyOnClose
      onCancel={() => setSelected(undefined)}
      width="clamp(480px, 80%, 1420px)"
      title={`Version ${selected?.version}`}
      footer={false}
    >
      <Skeleton loading={loading} active={loading}>
        <div style={{ display: 'flex', justifyContent: 'space-between', columnGap: 8 }}>
          {Object.keys(groups ?? {}).map(issueTypeId => {
            const issues = groups![issueTypeId]!;
            const issueType = issues[0]!.fields.issuetype!;
            return (
              <div key={issueTypeId} style={{ flex: 1 }}>
                <List
                  dataSource={issues}
                  bordered
                  header={<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: 8 }}>
                    <Image src={issueType.iconUrl} preview={false} />
                    {issueType.name}
                  </div>}
                  renderItem={issue => (
                    <List.Item style={{ flex: 1 }}>
                      <div style={{ display: 'block' }}>
                        <Typography.Link href={`https://apurebase.atlassian.net/browse/${issue.key}`} target="_blank">
                          {issue.key}
                        </Typography.Link>
                        &nbsp;&nbsp;
                        {issue.fields.summary}
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            );
          })}
        </div>
      </Skeleton>
    </Modal>
  );
};

gql`
  query JiraVersionReleaseNotesQuery($version: String!) {
    jiraIssues(version: $version) {
      total
      issues {
        key
        self
        fields {
          summary
          issuetype {
            id
            name
            iconUrl
          }
        }
      }
    }
  }
`;

export default InstanceReleaseModal;
