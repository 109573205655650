import React from 'react';
import { Button, Modal, Space } from 'antd';
import { CustomFieldTypeEnum, MergeItemPersonCustomFieldFragment } from '../../../../../../../../../gql/typings';
import { StateArray } from '../../../../../../../../util/StateArrayType';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { reactJoin } from '../../../../../../../../util/reactJoin';


const Noop = () => <i>Custom Field management has currently not been implemented!</i>;

export const testing: Record<CustomFieldTypeEnum, React.FC> = {
  SWITCH: Noop,
  CHECK: Noop,
  SELECT: Noop,
  NUMBER: Noop,
  TAG: Noop,
  TEXT: Noop,
};

type AdminMergeItemCustomFieldModalProps = {
  visibleState: StateArray<boolean>;
  hasConflict: boolean;
  existingLoserValues: string[];
  field: NonNullable<MergeItemPersonCustomFieldFragment['field']>;
};

const AdminMergeItemCustomFieldModal: React.FC<AdminMergeItemCustomFieldModalProps> = ({
  visibleState,
  hasConflict,
  existingLoserValues,
  field,
}) => {
  const localization = useLocalization();

  const ToDisplay = testing[field.fieldTypeEnum];

  return (
    <Modal
      open={visibleState[0]}
      onCancel={() => visibleState[1](false)}
      footer={false}
    >
      <Space direction="vertical">
        {field.fieldTypeEnum}
        <span>
          {hasConflict && localization.formatMessage(Locale.Text.Merge_request_custom_field_conflict_modal_description, {
            field: <strong>{field.heading}</strong>,
            values1: <>[{reactJoin(existingLoserValues.map((v, i) => <strong key={i}>{v}</strong>))}]</>,
            values2: <>[{reactJoin(existingLoserValues.map((v, i) => <strong key={i}>{v}</strong>))}]</>,
          })}
          {!hasConflict && <i>Some description...</i>}
        </span>

        <ToDisplay />

        <div style={{ display: 'flex', justifyContent: 'center', gap: 8 }}>
          <Button>
            {localization.formatMessage(Locale.Command.Cancel)}
          </Button>
          <Button type="primary">
            {localization.formatMessage(Locale.Command.Choose)}
          </Button>
        </div>
      </Space>
    </Modal>
  );
};

export default AdminMergeItemCustomFieldModal;
