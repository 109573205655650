import React from 'react';
import { Card, Col, message, Popconfirm, Row, Tag, Typography } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { PlusOutlined } from '@ant-design/icons';
import { useLocalization } from '../../../../../util/useLocalization';
import Flag, { FlagCountry } from '../../../../../components/Flag/Flag';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { useEditable } from '../../../../../util/useEditable';
import {
  RemoveTerritoryMutationMutation, RemoveTerritoryMutationMutationVariables, SiteBrickTerritoryQueryQuery,
  UpdateTerritoryHeadingMutationMutation,
  UpdateTerritoryHeadingMutationMutationVariables,
  UpdateTerritoryUserMutationMutation,
  UpdateTerritoryUserMutationMutationVariables
} from '../../../../../../gql/typings';

type AdminSiteBrickTerritoryCardProps = {
  territory: TerritoryType;
};

type BrickType = NonNullable<SiteBrickTerritoryQueryQuery['territories']>['nodes']['0']['bricks']['nodes']['0'];
type TerritoryType = NonNullable<SiteBrickTerritoryQueryQuery['territories']>['nodes']['0'];

const AdminSiteBrickTerritoryCard: React.FC<AdminSiteBrickTerritoryCardProps> = ({ territory }) => {
  const localization = useLocalization();

  const [
    updateHeading,
  ] = useMutation<UpdateTerritoryHeadingMutationMutation, UpdateTerritoryHeadingMutationMutationVariables>(UPDATE_HEADING);
  useMutation<UpdateTerritoryUserMutationMutation, UpdateTerritoryUserMutationMutationVariables>(UPDATE_RES_USER);
  const [
    removeTerritoryFromBrick,
  ] = useMutation<RemoveTerritoryMutationMutation, RemoveTerritoryMutationMutationVariables>(REMOVE_TERRITORY);


  const { editable: headingEditable, isEditing: headingIsEditing } = useEditable<TerritoryType>({
    onSave: (terr, newHeading) => {
      if (terr.heading != newHeading) updateHeading({
        variables: { territoryCode: terr.code, newHeading },
      }).then(res => {
        if (res.errors?.[0]?.message) message.error(res.errors[0].message);
        else message.success('Successfully updated the territory heading');
      });
    },
  });

  const onRemoveBrick = (brick: BrickType) => removeTerritoryFromBrick({
    variables: { brickCode: brick.code },
  }).then(res => {
    if (res.errors?.[0]?.message) message.error(res.errors[0].message);
    else message.success(`Removed '${brick.heading}' from territory '${territory.heading}'`);
  });

  return (
    <Card
      title={
        <span>
          {!headingIsEditing && <Flag country={territory.countryCode as FlagCountry} rectangle height={20} />}
          <Typography.Text editable={headingEditable(territory)}>
            {territory.heading}
          </Typography.Text>
        </span>
      }
    >
      <Row>
        <Col span={12}>
          <Typography.Text>
            Responsible User:
          </Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text>
            {territory.responsibleUser?.fullname ?? 'Unassigned'}
          </Typography.Text>
        </Col>

        <Col span={12}>
          <Typography.Text>
            Amount of HCOs:
          </Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text>
            {territory.bricks.siteTotalCount}
          </Typography.Text>
        </Col>

        <Col span={12}>
          <Typography.Text>
            Amount of Bricks:
          </Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text>
            {territory.bricks.totalCount}
          </Typography.Text>
        </Col>

        <Col span={24} style={{ paddingTop: 8 }}>
          {territory.bricks.nodes.map(brick => (
            <Tag
              key={brick.code}
              closable
              onClose={e => e.preventDefault()}
              closeIcon={
                <Popconfirm
                  title="Are you sure you want to remove this brick from this territory?"
                  okText={localization.formatMessage(Locale.General.Yes)}
                  onConfirm={() => onRemoveBrick(brick)}
                >
                  x
                </Popconfirm>
              }
            >
              {brick.heading}
            </Tag>
          ))}
          <Tag>
            <PlusOutlined /> Add brick
          </Tag>
        </Col>
      </Row>
    </Card>
  );
};


const UPDATE_HEADING = gql`
  mutation UpdateTerritoryHeadingMutation($territoryCode: String!, $newHeading: String!) {
    updateTerritoryHeading(territoryCode: $territoryCode, heading: $newHeading) {
      code
      heading
    }
  }
`;

const UPDATE_RES_USER = gql`
  mutation UpdateTerritoryUserMutation($territoryCode: String!, $responsibleUserId: Int) {
    updateTerritoryResponsibleUser(territoryCode: $territoryCode, responsibleUserId: $responsibleUserId) {
      code
      responsibleUserId
      responsibleUser { id }
    }
  }
`;

const REMOVE_TERRITORY = gql`
  mutation RemoveTerritoryMutation($brickCode: String!) {
    setTerritory(brickCode: $brickCode, territoryCode: null) {
      code
      territoryCode
      territory { code }
    }
  }
`;


export default AdminSiteBrickTerritoryCard;
