import React, { useEffect, useState } from 'react';
import { Col, Radio, Row, Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { CustomFieldProps, REMOVE_MUTATION, UPSERT_MUTATION } from './CustomFieldUtils';
import Icon from '../Icon/Icon';
import CustomFieldCheckableTag from './CustomFieldCheckableTag';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';
import {
  CustomFieldFragmentFragment,
  RemoveCustomFieldValueMutation,
  RemoveCustomFieldValueMutationVariables,
  UpsertCustomFieldValueMutation, UpsertCustomFieldValueMutationVariables
} from '../../../gql/typings';


const CustomFieldRadio: React.FC<CustomFieldProps> = ({ recordId, field, values, refreshData }) => {
  const [selected, setSelected] = useState<number>();
  const localization = useLocalization();

  const [
    update
  ] = useMutation<UpsertCustomFieldValueMutation,
  UpsertCustomFieldValueMutationVariables>(UPSERT_MUTATION);

  const [
    removeCustomFieldValue
  ] = useMutation<RemoveCustomFieldValueMutation,
  RemoveCustomFieldValueMutationVariables>(REMOVE_MUTATION);

  const [, setEditble] = useState(false);

  useEffect(() => {
    setSelected(values.nodes[0]?.value?.id ?? undefined);
  }, [values]);
  const settings = field.customFieldControlSetting;

  const checkForDelete = (id: number) => {
    if (id === selected) {
      const toRemoveLinkId = values?.nodes?.filter(v => v.value?.id == id)[0]!.id;
      removeCustomFieldValue({
        variables: {
          customValues: [toRemoveLinkId]
        }
      }).then(() => setEditble(false))
        .finally(refreshData);
    }
  };

  const onSelect = (e: RadioChangeEvent) => {
    update({
      variables: {
        recordId,
        customFieldCode: field.code,
        customValues: [{ valueId: e.target.value }],
      }
    }).then(() => setEditble(false))
      .finally(refreshData);
  };


  return (
    <Row gutter={[4, 4]} className="custom-field-select-grid">
      <Col span={settings?.isSynced ? 23 : 24}>
        {!field.isCheckableTag ? <Radio.Group onChange={onSelect} value={selected}>
          <Row>
            {(field.options?.nodes ?? []).map((radio: NonNullable<CustomFieldFragmentFragment['options']>['nodes']['0']) => (
              <Col key={radio.value!.id} span={24}>
                <Tooltip
                  title={field.accessRules?.allowMutation
                    ? '' : localization.formatMessage(Locale.Text.Mutation_permission_denied)}
                >
                  <Radio
                    key={radio.value!.id}
                    disabled={!field.accessRules?.allowMutation}
                    value={radio.value!.id}
                    onClick={() => {
                      checkForDelete(radio.value!.id);
                    }}
                  >
                    {radio.value?.value}
                  </Radio>
                </Tooltip>
              </Col>
            ))}
          </Row>
        </Radio.Group>
          : <CustomFieldCheckableTag recordId={recordId} field={field} values={values} refreshData={refreshData} />}


      </Col>
      {settings?.isSynced && (
        <Col span={1}>
          <Icon toolTip="is synchronized" style={{ marginTop: 8, marginLeft: 8 }} iconType="SYNC" />
        </Col>
      )}
    </Row>
  );
};

export default CustomFieldRadio;
