import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import NoteCard from '../../person/Components/NoteCard';
import NoteDrawer from '../../../components/Note/NoteDrawer';
import {
  EntityTypeEnum,
  PersonNoteDetailQueryQuery,
  SiteNoteDetailQuery,
  SiteNoteDetailQueryVariables
} from '../../../../gql/typings';

const SiteNoteContainer: React.FC<{ siteId: number }> = ({
  siteId,
}) => {
  const notesState = useState<NonNullable<PersonNoteDetailQueryQuery['person']>['notes']['nodes']['0'] | null>(null);
  const { data } = useQuery<SiteNoteDetailQuery, SiteNoteDetailQueryVariables>(DATA_QUERY, {
    variables: { siteId },
  });
  const [displayDrawer, setDisplayDrawer] = useState(false);

  return (
    <>
      {(data?.site?.notes.nodes ?? []).map(note => (
        <span key={note.id}>
          <NoteCard note={note} setNote={notesState[1]} setDisplayDrawer={setDisplayDrawer} />
        </span>
      ))}
      <NoteDrawer
        id={siteId}
        entity={EntityTypeEnum.PERSON}
        noteState={notesState}
        displayDrawer={displayDrawer}
        setDisplayDrawer={setDisplayDrawer}
      />
    </>
  );
};

const DATA_QUERY = gql`
  query SiteNoteDetail($siteId: Int!) {
    site(siteId: $siteId) {
      id
      notes{
        hash
        totalCount
        nodes{
          id
          text
          ct
          ut
          status
          private
          title
          user{
            id
            fullname
          }
        }
      }
    }
  }
`;

SiteNoteContainer.propTypes = {};


export default SiteNoteContainer;

