import React, { useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import ActivityHeadingDetails from '../Heading/ActivityHeadingDetails';
import { ActivityDetailContext } from '../ActivityDetailsTypes';
import ActivitySectionRight from '../../components/ActivitySectionRight';
import Icon from '../../../../components/Icon/Icon';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import { AnimatedTextErrorClassName } from '../../../../components/AnimatedTextError/AnimatedTextError';
import {
  AttachmentRuleEnum,
  CreateActivityMutationMutation,
  CreateActivityMutationMutationVariables
} from '../../../../../gql/typings';


function filterIfNeeded<T>(rule: AttachmentRuleEnum, value: T): T|undefined {
  if (rule === AttachmentRuleEnum.NONE) return;
  return value;
}

const ActivityDetailsTopComponent: React.FC<{
  onCreated?: (activity: NonNullable<CreateActivityMutationMutation['createActivity']>) => void;
  setVisualState?: () => void;
}> = ({ onCreated, setVisualState }) => {
  const localizationShape = useLocalization();
  const navigate = useNavigate();
  const {
    activityId,
    activityType,
    allErrors,
    ...rest
  } = useContext(ActivityDetailContext);
  const [
    create,
    { loading },
  ] = useMutation<CreateActivityMutationMutation, CreateActivityMutationMutationVariables>(CREATE_ACTIVITY_MUTATION);

  const onCreate = () => {
    // If any errors, we'll scroll to the first one.
    if (Object.keys(allErrors ?? {}).length > 0) {
      document.getElementsByClassName(`${AnimatedTextErrorClassName}-active`)[0]?.scrollIntoView({
        behavior: 'smooth',
      });
    }
    if (!activityId && Object.keys(allErrors ?? {}).length === 0 && activityType) {
      create({
        variables: {
          // TODO: This object should somehow be created within the [activityReducer.tsx] instead of here!
          input: {
            endDate: rest.endDate,
            heading: rest.heading,
            locations: filterIfNeeded(activityType.locationRule, rest.locations as number[]|null),
            participationUserIds: filterIfNeeded(activityType.participationUserRule, rest.participationUserIds),
            personIds: filterIfNeeded(activityType.personRule, rest.personIds),
            productBrandIds: filterIfNeeded(activityType.productRule, rest.productBrandIds),
            productIds: filterIfNeeded(activityType.productRule, rest.productIds),
            projectIds: filterIfNeeded(activityType.projectRule, rest.projectIds),
            responsibleUserIds: filterIfNeeded(activityType.responsibleUserRule, rest.responsibleUserIds),
            startDate: rest.startDate,
            status: rest.status,
            typeCode: activityType.code,
          },
        },
        refetchQueries: ['ActivityOverview', 'ViewerActivitiesCalendar', 'usersResponsibleList'],
      }).then(res => {
        if (!res.errors && res.data) {
          if (onCreated) onCreated(res.data.createActivity);
          else navigate(`/activity/${res.data.createActivity.id}`);
        }
      });
    }
  };

  return (
    <div className="activity-details-top-container">
      <Row align='top' gutter={{ md: 10, lg: 25 }}>
        <Col span={12}>
          <ActivityHeadingDetails />
        </Col>
        <Col span={12}>
          {activityId && <ActivitySectionRight setVisualState={setVisualState} activityId={activityId} />}
          {!activityId && <div className="right">
            <Button onClick={onCreate} type="primary" shape="round" size="large" loading={loading}>
              <Icon iconType="SAVE_WHITE" />
                &nbsp;&nbsp;
              {localizationShape.formatMessage(Locale.Command.Save)}
            </Button>
          </div>}
        </Col>
      </Row>
    </div>
  );
};

const CREATE_ACTIVITY_MUTATION = gql`
  mutation CreateActivityMutation($input: CreateActivityInput!) {
    createActivity(input: $input) {
      id
    }
  }
`;

export default ActivityDetailsTopComponent;
