import React from 'react';
import DownloadBadge from '../Components/DownloadBadge/DownloadBadge';
import { personFieldConfig } from './utils/personFieldsUtils';

export const personCherryPickFieldConfig = personFieldConfig(
  'cherryPick',
  [
    'id',
    'countryCode',
    'fullname',
    'isLocal',
  ],
  ({ cherryPickSupport }) => ({
    additionalTableConfig: {
      width: 100
    },
    render: (record) => !cherryPickSupport ? <></> : (
      <DownloadBadge
        type="PERSON"
        entity={record}
        name={record.fullname}
        visible={!record.isLocal}
      />
    ),
  })
);

