import React from 'react';
import ReportProducts from './ReportProducts';
import ReportDatePicker from './ReportDatePicker';
import ReportUsers from './ReportUsers';
import ReportDaySpanPicker from './ReportDaySpanPicker';
import ReportCountries from './ReportCountries';
import { DynamicReportInputProps } from './dynamicReportInputUtils';
import KpiReportInput from './custom/KpiReportInput';
import { ReportInputEnum } from '../../../../../gql/typings';

export const DynamicReportInput: Record<ReportInputEnum, React.FC<DynamicReportInputProps>> = {
  COUNTRY_DF_ALL: ({ controller, inputDef }) => (
    <ReportCountries controller={controller} inputDef={inputDef} initialAllSelected />
  ),
  DAY_SPAN: ({ controller, inputDef }) => <ReportDaySpanPicker controller={controller} inputDef={inputDef} />,
  HCP: ({ controller, inputDef }) => <span>Currently not supported!</span>,
  MONTH: ({ controller, inputDef }) => <ReportDatePicker controller={controller} inputDef={inputDef} pickerType="month" />,
  PRODUCT: ({ controller, inputDef }) => <ReportProducts controller={controller} inputDef={inputDef} />,
  PRODUCTS: ({ controller, inputDef }) => <ReportProducts controller={controller} inputDef={inputDef} multiple />,
  TEXT: ({ controller, inputDef }) => <span>Currently Not Supported</span>,
  USERS: ({ controller, inputDef }) => <ReportUsers controller={controller} inputDef={inputDef} />,
  USERS_DF_ALL: ({ controller, inputDef }) => <ReportUsers controller={controller} inputDef={inputDef} initialAllSelected />,
  YEAR: ({ controller, inputDef }) => <ReportDatePicker controller={controller} inputDef={inputDef} pickerType="year" />,
  SPECIFIC_KPI_REPORT: KpiReportInput,
};
