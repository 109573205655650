import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useDebounce } from 'ahooks';
import { Col, Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { EntityTabFieldProps } from './EntityTabFieldsTypes';
import {
  FilterCodesSearchTabQueryQuery, FilterCodesSearchTabQueryQueryVariables,
  PersonFilterCodesTabQueryQuery, PersonFilterCodesTabQueryQueryVariables,
  UpdatePersonFilterCodeTabMutationMutation,
  UpdatePersonFilterCodeTabMutationMutationVariables
} from '../../../../../gql/typings';

const PersonTagsTabField: React.FC<EntityTabFieldProps> = (props) => {
  const localization = useLocalization();
  const [
    updatePersonFilterCode,
  ] = useMutation<UpdatePersonFilterCodeTabMutationMutation, UpdatePersonFilterCodeTabMutationMutationVariables>(
    UPDATE_MUTATION
  );
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<number[]>([]);
  const debouncedSearch = useDebounce(search, { wait: 250, trailing: true });
  const {
    data,
    loading,
  } = useQuery<PersonFilterCodesTabQueryQuery, PersonFilterCodesTabQueryQueryVariables>(DATA_QUERY, {
    variables: { personId: props.recordId },
  });
  const {
    data: searchData,
    loading: searchLoading,
  } = useQuery<FilterCodesSearchTabQueryQuery, FilterCodesSearchTabQueryQueryVariables>(SEARCH_QUERY, {
    variables: { search: `%${debouncedSearch}%` },
  });

  useEffect(() => {
    if (data?.person?.filterCodes) setSelected(data.person.filterCodes.nodes.map(f => f.id));
  }, [data]);

  const handleChange = (selectedItems: number[]) => {
    setSelected(selectedItems);
    return updatePersonFilterCode({
      variables: {
        persId: props.recordId,
        filterCodeIds: selectedItems,
      },
    });
  };

  const filterCodeTypes = localization.formatMessage(Locale.Attribute.Tag_types);

  return (
    <>
      <Col sm={24} md={8}>
        <strong>{filterCodeTypes}</strong>
        <Tooltip title={localization.formatMessage(Locale.Text.Add_tags_from_all_different_codes, { filterCodeTypes })}>
          <InfoCircleOutlined style={{ paddingLeft: 4, verticalAlign: 'top' }} />
        </Tooltip>
      </Col>
      <Col sm={24} md={16}>
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          onChange={handleChange}
          value={selected}
          loading={loading || searchLoading}
          onSearch={setSearch}
          filterOption={false}
          onBlur={() => setSearch('')}
        >
          {(searchData?.filterCodeTypes.nodes ?? []).map(ft => (
            <Select.OptGroup key={ft.code} label={ft.heading}>
              {ft.getFilterCodesByTypeId.nodes.map(x => (
                <Select.Option key={x.id} value={x.id}>
                  <Tooltip mouseEnterDelay={1} title={ft.heading}>
                    <div>{x.header}</div>
                  </Tooltip>
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
      </Col>
    </>
  );
};

const UPDATE_MUTATION = gql`
  mutation UpdatePersonFilterCodeTabMutation(
    $persId: Int!,
    $filterCodeIds: [Int!]!
  ) {
    updatePersonFilterCode(personId: $persId, filterCodeIds: $filterCodeIds) {
      id
      person {
        id
        filterCodes {
          hash
          nodes { id }
        }
      }
    }
  }
`;

const SEARCH_QUERY = gql`
  query FilterCodesSearchTabQuery($search: String) {
    filterCodeTypes(criteria: {filterCodeTypeOrFilterCodeLabel: $search}) {
      hash
      nodes {
        id
        code
        heading
        getFilterCodesByTypeId(criteria: {header: $search}) {
          hash
          totalCount
          nodes {
            id
            header
          }
        }
      }
    }
  }
`;

const DATA_QUERY = gql`
  query PersonFilterCodesTabQuery($personId: Int!) {
    person(id: $personId) {
      id
      filterCodes {
        hash
        nodes {
          id
          filterCodeTypeId
          header
        }
      }
    }
  }
`;

export default PersonTagsTabField;
