import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { Space } from 'antd';
import parse from 'html-react-parser';
import { Locale } from '../../../../localization/LocalizationKeys';
import Icon from '../../../components/Icon/Icon';
import { NoteDrawerFragmentFragment } from '../../../../gql/typings';
import { DATE_FORMAT } from '../../../util/format';

const NoteCard: React.FC<{
  note: NoteDrawerFragmentFragment;
  setNote: (note: NoteDrawerFragmentFragment | null) => void;
  setDisplayDrawer: (visible: boolean) => void;
}> = ({
  note,
  setNote,
  setDisplayDrawer,
}) => {
  const { formatMessage } = useIntl();
  const getUserName = note.user?.fullname;
  const userId = note.user?.id;
  const urlUser = userId ? <>
    {getUserName}
  </> : <></>;
  const created = moment(note.ct).format(DATE_FORMAT);
  const handleOnClick = () => {
    setNote(note);
    setDisplayDrawer(true);
  };

  return (
    <div className="affiliation-card-container">
      <div className="affiliation-container">
        <div className="right">
          <div className="note-container">
            <div className="note-icon-container">
              <Icon className="note-icon" iconType="NOTE" style={{ fontSize: '30px' }} />
            </div>

            <div className="note-text">
              <span className="created-date">
                {formatMessage(Locale.Text.Created_by_urlUser, { created, urlUser })}
              </span>

              <div className="desc">
                <div className="title">
                  {note.title || formatMessage(Locale.Attribute.Untitled)}
                </div>
                <div className="text">
                  {parse(note.text ?? '')}
                </div>
              </div>
            </div>
            <div className="link">
              <Space>
                {note.private ? <></> : <span onClick={handleOnClick}><Icon iconType="SHARE" /></span>}
                <span onClick={handleOnClick}><Icon iconType="LINK" /></span>
              </Space>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteCard;
