import React from 'react';
import { Table, TableProps } from 'antd';
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
  SortableHandle as sortableHandle,
  SortEndHandler,
} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';
import { ColumnsType, ColumnType } from 'antd/lib/table/interface';
import { omit } from 'lodash';
import { DynamicList } from '../../util/AHookTypes';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';

export interface DraggableTableItem {
  id: React.Key;
}

export type DraggableTableProps<T extends DraggableTableItem> = Omit<TableProps<T>,
'components'|'rowKey'|'dataSource'|'columns'> & {
  showSortLabel?: true;
  dynamicList: DynamicList<T>;
  onListUpdate?: (newState: T[], previousState: T[]) => void;
  draggable?: boolean;
  columns: ({ hide?: boolean } & ColumnType<T>)[];
};


const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

// @ts-ignore
const SortableItem = sortableElement(props => <tr {...props} />);
// @ts-ignore
const SortableContainer = sortableContainer(props => <tbody {...props} />);

function DraggableTable<T extends DraggableTableItem>({
  dynamicList,
  columns,
  showSortLabel,
  className,
  draggable = true,
  onListUpdate,
  ...restProps
}: React.PropsWithChildren<DraggableTableProps<T>>) {
  const localization = useLocalization();
  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const prevList = [...dynamicList.list];
      const newList = arrayMove([...dynamicList.list], oldIndex, newIndex).filter(el => !!el);
      dynamicList.resetList(newList);
      onListUpdate?.(newList, prevList);
    }
  };

  const DraggableContainer: React.FC = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="draggable-table-container-row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<{ 'data-row-key'?: number }> = (props) => (
    <SortableItem
      index={dynamicList.list.findIndex(x => x.id === props['data-row-key'])}
      {...omit(props, ['className', 'style'])}
    />
  );

  return (
    <Table
      dataSource={dynamicList.list}
      scroll={{ x: 'max-content' }}
      className={className || 'draggable-table'}
      columns={[
        ...[draggable && {
          // eslint-disable-next-line no-undef
          title: showSortLabel ? localization.formatMessage(Locale.General.Sort) : '',
          dataIndex: 'sort',
          width: 30,
          className: 'draggable-table-container-drag-visible',
          render: () => <DragHandle />,
        }].filter(e => e) as ColumnsType<T>,
        ...columns.filter(column => !column.hide).map(column => ({
          ...column,
          className: `${column.className} draggable-table-container-drag-visible`,
        })),
      ]}
      rowKey="id"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
      {...restProps}
    />
  );
}

export default DraggableTable;
