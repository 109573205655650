import React, { ReactNode, isValidElement, useMemo } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Skeleton, Typography } from 'antd';
import { flatten, isArray, isNil, isString } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MessageDescriptor } from '@formatjs/intl/src/types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core';
import { generateMessageObject } from './generateMessageObject';
import { Optional } from './StateArrayType';
import { reactJoin } from './reactJoin';

export type LocalizationShape = {
  intl: IntlShape;
  formatMessage: IntlShape['formatMessage'];
  formatText: (
    descriptor: MessageDescriptor,
    values?: Record<string, ReactNode | PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: IntlMessageFormatOptions,
  ) => React.ReactNode;
  formatMessageByStr: (str: string) => string;
  formatMessageByStrLoading: (str: Optional<string>) => ReactNode;
  pluralMessage: (key: { id: string }, amount: number, variables?: Record<string, ReactNode>) => ReactNode;
  pluralMessageWithLoading: (key: { id: string }, amount: number|undefined, variables?: Record<string, ReactNode>) => ReactNode;
};

export const useLocalization = (): LocalizationShape => {
  const intl = useIntl();

  return useMemo(() => ({
    intl,
    formatMessage: intl.formatMessage,
    formatText: (descriptor, values, opts) => {
      const raw = intl.formatMessage(descriptor, values, opts);
      const formatted = isArray(raw)
        ? flatten(raw.map(m => isString(m) ? m.split('\\n') : [m]))
        : [raw];

      if (isArray(formatted)) {
        let elements: (string|ReactNode)[] = [];
        let lastWasElement = false;
        for (let index = 0; index < formatted.length; index++) {
          const curr = formatted[index];
          if (isValidElement(curr) || lastWasElement) {
            const copy = [...elements];
            const mergeWith = copy.pop();
            elements = [
              ...copy,
              <span key={index}>{mergeWith}{curr}</span>,
            ];
          } else elements = [...elements, ...(curr as string).split('\\n')];
          lastWasElement = isValidElement(curr);
        }
        const test = elements.map((text: ReactNode, index: number) => <Typography.Text key={index}>{text}</Typography.Text>);
        return reactJoin(test, <br />);
      }
      const elements = (formatted as string).split('\\n')
        .map((text, index) => <Typography.Text key={index}>{text}</Typography.Text>);
      return reactJoin(elements, <br />);
    },
    formatMessageByStr: (str: string) => intl.formatMessage(generateMessageObject(str)),
    formatMessageByStrLoading: (str: Optional<string>) => str
      ? intl.formatMessage(generateMessageObject(str))
      : <Skeleton.Input active />,

    pluralMessageWithLoading: (
      key: { id: string },
      amount: number|undefined,
      variables: Record<string, ReactNode> = {},
    ) => {
      const resource = intl.formatMessage(key, {
        [key.id.replace(/\./g, '_')]: amount ?? -999,
        ...variables,
      }) as string;

      if (isNil(amount)) {
        return (
          <span>
            {resource.replace(' (-999)', '').replace('-999', '')}
            &nbsp;(<Skeleton.Avatar active size="small" shape="circle" />)
          </span>
        );
      }
      return resource;
    },

    pluralMessage: (
      key: { id: string },
      amount: number|ReactNode,
      variables: Record<string, ReactNode> = {},
    ) => intl.formatMessage(key, {
      [key.id.replace(/\./g, '_')]: amount,
      ...variables,
    }),
  }), [intl]);
};
