import { gql, useApolloClient, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Select } from 'antd';
import { useIntl } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import Popup from '../../../../components/Popup/Popup';
import { ActivityDetailContext } from '../ActivityDetailsTypes';
import HeaderWithValue from '../../../../components/DisplayFields/HeaderWithValue';
import { Locale } from '../../../../../localization/LocalizationKeys';
import Card from '../../../../components/ContactCard/Card';
import { Optional } from '../../../../util/StateArrayType';
import {
  ActivityProductQueryQuery,
  ActivityProductQueryQueryVariables,
  ActivityProductSuggestionQueryQuery,
  ActivityProductSuggestionQueryQueryVariables, AttachmentRuleEnum,
  useAddProductToActivityMutation, useRemoveProductToActivityMutation
} from '../../../../../gql/typings';


const ActivityProductContainer: React.FC = () => {
  const {
    activityId,
    activityType,
    dispatch,
    productIds,
    productIdsErrors,
    editAccess,
  } = useContext(ActivityDetailContext);
  const intl = useIntl();
  const apolloClient = useApolloClient();
  const [
    addProduct,
  ] = useAddProductToActivityMutation();
  const [removeProductToActivity] = useRemoveProductToActivityMutation();
  const {
    data,
    loading,
  } = useQuery<ActivityProductQueryQuery, ActivityProductQueryQueryVariables>(DATA_QUERY, {
    variables: { activityId: activityId ?? -1 },
    skip: !activityId,
  });
  const { data: suggestions } = useQuery<ActivityProductSuggestionQueryQuery, ActivityProductSuggestionQueryQueryVariables>(
    SUGGESTION_QUERY,
    { variables: { activityId } },
  );
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<number>();

  useEffect(() => {
    if (data?.activity) dispatch({
      type: 'updateProductIds',
      productIds: data.activity.activityProductAffiliations.nodes.map(apa => apa.product.id),
    });
  }, [data, dispatch]);

  const rule = activityType?.productRule;
  const items = productIds ?? [];

  if (!activityType || activityType.productRule === AttachmentRuleEnum.NONE) return <></>;

  const ruleWithMultiple = rule === AttachmentRuleEnum.OPTIONAL_MANY || rule === AttachmentRuleEnum.MANY;

  const allowRemove = !activityId
    || items.length > 1
    || rule === AttachmentRuleEnum.OPTIONAL_ONE
    || rule === AttachmentRuleEnum.OPTIONAL_MANY;
  const allowChange = !allowRemove && rule == AttachmentRuleEnum.ONE;

  return (
    <HeaderWithValue
      className="activity-product-container"
      heading={intl.formatMessage(Locale.Attribute.Product)}
      errors={productIdsErrors}
    >
      {items.length > 1 && !ruleWithMultiple && (
        <Alert
          type="error"
          showIcon
          message={intl.formatMessage(Locale.Text.You_are_not_allowed_to_have_more_than_one_type_to_activity, {
            count: items.length - 1,
            type: items.length === 2
              ? intl.formatMessage(Locale.Attribute.Product)
              : intl.formatMessage(Locale.Attribute.Products),
          })}
        />
      )}
      <div className={`activity-card-product-list ${items.length === 0 ? 'border-when-empty' : ''}`}>
        {items.map(productId => {
          // const item = items.find(item => item.id === itemId) ?? idToItem?.(itemId);
          const product: Optional<
          NonNullable<ActivityProductQueryQuery['activity']>['activityProductAffiliations']['nodes']['0']['product']
          > = apolloClient
            // @ts-ignore
            .cache.data.data[`Product:${productId}`];

          return (
            <div key={productId}>
              <Card
                loading={!product}
                size='xsmall'
                editAccess={editAccess}
                title={product?.heading}
                icon="pill"
                iconRounded
                onRemove={allowRemove && product && (() => activityId
                  ? removeProductToActivity({
                    variables: {
                      activityId,
                      productId: product.id,
                    },
                  })
                  : dispatch({
                    type: 'updateProductIds',
                    productIds: (productIds ?? []).filter(pId => pId !== product.id),
                  })
                )}
                onChange={allowChange ? (() => setPopupVisible(true)) : null}
              />
            </div>
          );
        })}
      </div>
      {(items.length === 0 || rule === AttachmentRuleEnum.OPTIONAL_MANY || rule === AttachmentRuleEnum.MANY) && (
        <Button
          type='dashed'
          size='small'
          icon={<PlusOutlined />}
          className='add-button-absolute'
          onClick={() => setPopupVisible(true)}
        >
          {intl.formatMessage(
            Locale.Command.Add_type,
            { type: intl.formatMessage(Locale.Attribute.Product) },
          )}
        </Button>
      )}
      <Popup
        title={intl.formatMessage(Locale.Text.Add_product_to_activity)}
        centered
        size="380"
        acceptLabel={intl.formatMessage(Locale.Command.Add)}
        cancelLabel={intl.formatMessage(Locale.Command.Cancel)}
        visible={popupVisible}
        onClose={() => setPopupVisible(false)}
        wrapClassName="activity-product-modal-container"
        onAccept={() => {
          setPopupVisible(false);
          if (selectedProductId && activityId) addProduct({
            variables: {
              activityId: activityId!,
              productId: selectedProductId,
            },
            refetchQueries: ['ActivityProductQuery', 'ActivityProductSuggestionQuery'],
          });
          else if (selectedProductId) dispatch({
            type: 'updateProductIds',
            productIds: productIds ? [...productIds, selectedProductId] : [selectedProductId],
          });
        }}
      >
        {!loading && <Select
          showSearch
          style={{ width: 300 }}
          placeholder={intl.formatMessage(Locale.Command.Select_Product)}
          optionFilterProp="children"
          onChange={(n: number) => setSelectedProductId(n)}
          filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {(suggestions?.products.nodes ?? []).filter(product => !productIds?.includes(product.id)).map(product => (
            <Select.Option key={product.id} value={product.id} data-product-code={product.code}>
              {product.heading}
            </Select.Option>
          ))}
        </Select>}
      </Popup>
    </HeaderWithValue>
  );
};


const DATA_QUERY = gql`
  query ActivityProductQuery($activityId: Int!) {
    activity(id: $activityId) {
      id
      activityProductAffiliations{
        hash
        totalCount
        nodes {
          id
          product {
            id
            heading
            code
          }
        }
      }
    }
  }
`;

const SUGGESTION_QUERY = gql`
  query ActivityProductSuggestionQuery($activityId: ID) {
    products(criteria: { excludeActivityId: $activityId }) {
      hash
      nodes {
        id
        code
        heading
      }
    }
  }
`;

gql`
  mutation AddProductToActivity($activityId: ID!, $productId: ID!) {
    addProductToActivity(activityId: $activityId, productId: $productId) {
      id
      activityProductAffiliations {
        hash
        nodes {
          id
          product {
            id
            heading
            code
          }
        }
      }
    }
  }
`;

gql`
  mutation RemoveProductToActivity($activityId: Int!, $productId: Int!) {
    removeProductToActivity(activityId: $activityId, productId: $productId) {
      id
      activityProductAffiliations {
        hash
        nodes {
          id
          product {
            id
            heading
            code
          }
        }
      }
    }
  }
`;


export default ActivityProductContainer;
