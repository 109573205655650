import dayjs, { Dayjs } from 'dayjs';

export type TimeSearch = {
  from?: {
    timestamp?: Dayjs;
    relativePoint?: string;
  };
  to?: {
    timestamp?: Dayjs;
    relativePoint?: string;
  };
};
export type TimeSearchStringValue = {
  relativePoint?: string;
  timestamp?: string;
};
export type TimeSearchString = {
  from: TimeSearchStringValue;
  to: TimeSearchStringValue;
};
export const SerialiseDate = (input: TimeSearch): string => {
  const b: TimeSearchString = { from: {}, to: {} };

  if (input.from?.relativePoint) b.from.relativePoint = input.from.relativePoint;
  if (input.from?.timestamp) b.from.timestamp = input.from.timestamp.toISOString();
  if (input.to?.relativePoint) b.to.relativePoint = input.to.relativePoint;
  if (input.to?.timestamp) b.to.timestamp = input.to.timestamp.toISOString();
  return JSON.stringify(b);
};

export const DeserializeDate = (input: string): TimeSearch => {
  const unpacked: TimeSearchString = input ? JSON.parse(input) : { from: {}, to: {} };
  return {
    from: {
      timestamp: unpacked.from.timestamp ? dayjs(unpacked.from.timestamp) : undefined,
      relativePoint: unpacked.from.relativePoint,
    },
    to: {
      timestamp: unpacked.to.timestamp ? dayjs(unpacked.to.timestamp) : undefined,
      relativePoint: unpacked.to.relativePoint,
    },
  };
};
