import React from 'react';
import { Modal } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { EntitiesSearch } from '../../../components/entitiesSearch';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  SiteCriteriaOrder,
  UpdateSiteParentMutationMutation,
  UpdateSiteParentMutationMutationVariables
} from '../../../../gql/typings';
import { useTableRowSelection } from '../../../components/Table/useTableRowSelection';

export const siteParentNameFieldConfig = siteFieldConfig(
  'parentName',
  [
    'id',
    'countryCode',
    'parentSite.id',
    'parentSite.name',
    'controlSetting.id',
    'controlSetting.entityTypeId',
    'controlSetting.maintainerSourceCode',
    'controlSetting.createDcr',
  ],
  ({ localization, openInNewTab }) => ({
    title: localization.formatMessage(Locale.Attribute.Parent_site),
    additionalTableConfig: {
      width: 150
    },
    sorting: {
      ascend: SiteCriteriaOrder.PARENT_NAME_ASC,
      descend: SiteCriteriaOrder.PARENT_NAME_DESC,
    },
    dcrInfo: record => ({
      field: FieldEnum.HCO_PARENT_ID,
      entityAffiliationId: record.id,
      entityType: EntityTypeEnum.SITE,
    }),
    render: record => <LinkBadge
      url={record.parentSite ? `/hco/${record.parentSite.id}` : undefined}
      label={record.parentSite?.name}
      openInNewTab={openInNewTab}
      className="open-site-link"
    />,
    updateView: props => {
      const [update] = useMutation<UpdateSiteParentMutationMutation,
      UpdateSiteParentMutationMutationVariables>(UPDATE_SITE_PARENT_MUTATION);
      const tableSelection = useTableRowSelection({ virtualSupport: false, type: 'radio' });

      return (
        <Modal
          onCancel={props.endEditing}
          open
          width="clamp(600px, 60%, 1400px)"
          footer={false}
        >
          <EntitiesSearch
            entityType={EntityTypeEnum.SITE}
            tableSelection={tableSelection}
            openInNewTab
            quickActions={[
              {
                key: 'clear-parent-from-siteParentNameFieldConfig',
                label: localization.formatMessage(Locale.Command.Clear_Parent_Site),
                disabled: false,
                onClick: () => update({
                  variables: {
                    siteId: props.record.id,
                    parentId: null,
                  }
                }).then(props.endEditing)
              },
              {
                key: 'select-parent-from-siteParentNameFieldConfig',
                label: localization.formatMessage(Locale.Command.Select_Site_As_Parent),
                disabled: tableSelection.selection.count != 1,
                onClick: () => update({
                  variables: {
                    siteId: props.record.id,
                    parentId: tableSelection.selection.selected[0]!,
                  }
                }).then(props.endEditing)
              }
            ]}
          />
        </Modal>
      );
    },
  })
);

export const SITE_PARENT_SUGGESTIONS_QUERY = gql`
  query SelectSiteParentSuggestionQuery($search: String, $countryCode: CountryCode!) {
    sites(criteria: { siteName: $search, countries: [$countryCode], fetchSize: { limit: 1000 } }) {
      hash
      nodes {
        id
        name
      }
    }
  }
`;

export const UPDATE_SITE_PARENT_MUTATION = gql`
  mutation UpdateSiteParentMutation($siteId: Int!, $parentId: Int) {
    updateSiteParentId(siteId: $siteId, parentId: $parentId) {
      id
      parentId
      parentSite {
        id
        name
      }
    }
  }
`;
