import { gql } from '@apollo/client';
import CriteriasChooserAddButton from '../../search_old/CriteriasChooserAddButton';
import { CriteriaInputFactory_FRAGMENT } from '../../../components/CriteriaInput/CriteriaInputQueries';


export const LIST_DYNAMIC_RULES_FRAGMENT = gql`
  fragment ListDynamicRulesFragment on List {
    criterias {
      hash
      nodes {
        id
        criteria {
          id
          heading
          ...CriteriaInputFragment
        }
        values {
          hash
          nodes {
            id
            value
            path {
              id
              type { id, type }
            }
          }
        }
      }
    }
  }
  ${CriteriaInputFactory_FRAGMENT}
`;

export const DYNAMIC_RULES_DATA_QUERY = gql`
  query DynamicRulesQuery($listId: Int!, $entityTypeEnum: EntityTypeEnum) {
    list(id: $listId) {
      id
      ...ListDynamicRulesFragment
    }
    criteriaGroups(criteria: {entityType: $entityTypeEnum}) {
      hash
      nodes {
        ...CriteriasGroupsFragment
      }
    }
  }
  ${CriteriasChooserAddButton.FRAGMENT}
  ${LIST_DYNAMIC_RULES_FRAGMENT}
`;

