import React from 'react';
import { Drawer } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Optional, StateArray } from '../../../util/StateArrayType';
import { ActivityDetailsInitialState } from '../ActivityDetails/ActivityDetailsTypes';
import ActivityDetails from '../ActivityDetails/ActivityDetails';
import Icon from '../../../components/Icon/Icon';

type ActivityCreateDrawerProps = {
  visibleState: StateArray<boolean>;
  activityId?: Optional<number>;
  state?: ActivityDetailsInitialState;
  onCreated?: () => void;
};

const ActivityDrawer: React.FC<ActivityCreateDrawerProps> = ({
  visibleState: [visible, setVisible],
  activityId,
  state,
  onCreated,
}) => {
  const navigate = useNavigate();
  return (
    <Drawer
      open={visible}
      onClose={e => {
        // @ts-ignore
        if (e.target.nodeName === 'svg' || e.target.className === 'ant-drawer-close') {
          navigate(`/activity/${activityId}`);
        }
        setVisible(false);
      }}
      closeIcon={activityId ? <Icon iconType="EXPAND" /> : ''}
      rootClassName="activity-create-drawer-container"
    >
      {visible && (
        <div className="activity-create-drawer-inner-container">
          <ActivityDetails
            skipMainView
            setVisualState={() => setVisible(false)}
            initialState={state}
            activityId={activityId ?? undefined}
            onCreated={() => {
              setVisible(false);
              onCreated?.();
            }}
          />
        </div>
      )}
    </Drawer>
  );
};

export default ActivityDrawer;
