import React from 'react';
import { Col, Form, Radio, Row, Space, Switch } from 'antd';
import { MassAssignFieldTypesProps } from '../MassAssignFieldTypes';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { MassAssignFieldAction } from '../../../../gql/typings';


const MassAssignSwitchType: React.FC<MassAssignFieldTypesProps> = ({
  entityType,
  actionName,
  valueSelected: [valueSelected, setValueSelected],
  actionSelected: [actionSelected, setActionSelected],
}) => {
  const localization = useLocalization();
  setValueSelected(true);
  return (
    <div className="mass-assign-container">
      <div className="mass-assign-type-description">
        <p>{localization.formatMessage(Locale.Text.Mass_assign_description_selectionType)}</p>
      </div>
      <div className="dashboard-card-container">
        <div className="mass-assign-content-container">
          <Row>
            <Form.Item
              name="actionName"
              style={{ display: 'none' }}
              initialValue={actionName}
            />
            <Form.Item
              name="entityType"
              style={{ display: 'none' }}
              initialValue={entityType}

            />
            <Col span={12}>
              <h3>{localization.formatMessage(Locale.Text.Action)}</h3>
              <Form.Item
                name="actionType"
                initialValue={MassAssignFieldAction.SetValueToSelected}
                rules={[{ required: true, message: localization.formatMessage(Locale.Text.Options_cant_be_blank) }]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio
                      onClick={() => setActionSelected(MassAssignFieldAction.SetValueToSelected)}
                      defaultChecked
                      value={MassAssignFieldAction.SetValueToSelected}
                    >{localization.formatMessage(Locale.Text.Mass_assign_action_set_value_to_selected)}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <h3>{localization.formatMessage(Locale.Text.Select_value)}</h3>
              <Form.Item
                name="singleSelectValue"
                initialValue
              >
                <Switch
                  defaultChecked
                />
              </Form.Item>
            </Col>


          </Row>
        </div>

      </div>


    </div>
  );
};


export default MassAssignSwitchType;
