import React from 'react';
import { Tabs } from 'antd';
import {
  TableColumnConfigureSettingType
} from '../../../../../components/ConfigureTableColumnSettings/useTableColumnConfigureSettings';
import ConfigureTableColumnSettings from '../../../../../components/ConfigureTableColumnSettings/ConfigureTableColumnSettings';
import { EntityTypeEnum } from '../../../../../../gql/typings';

const AdminConfigureLinkTableColumns: React.FC = () => (
  <Tabs items={[
    {
      key: 'link',
      label: 'Standard',
      children: <ConfigureTableColumnSettings
        entityType={EntityTypeEnum.AFFILIATION}
        type={TableColumnConfigureSettingType.SYSTEM}
      />
    },
    {
      key: 'person',
      label: 'From Person',
      children: <ConfigureTableColumnSettings
        entityType={EntityTypeEnum.AFFILIATION_FROM_PERSON}
        type={TableColumnConfigureSettingType.SYSTEM}
      />
    },
    {
      key: 'site',
      label: 'From Site',
      children: <ConfigureTableColumnSettings
        entityType={EntityTypeEnum.AFFILIATION_FROM_SITE}
        type={TableColumnConfigureSettingType.SYSTEM}
      />
    }
  ]}
  />
);

export default AdminConfigureLinkTableColumns;
