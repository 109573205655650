import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { debounce } from 'lodash';
import { Select } from 'antd';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { SelectPositionSuggestionsQueryQuery, SelectPositionSuggestionsQueryQueryVariables } from '../../../../gql/typings';

type SelectPositionProps = {
  countryCode?: string;
  value?: string;
  onChange?: (value: string) => void;
};

const SelectPosition: React.FC<SelectPositionProps> = ({
  countryCode,
  value,
  onChange,
}) => {
  const localization = useLocalization();
  const {
    data: suggestionData,
    loading: suggestionLoading,
    refetch: refetchSuggestions,
  } = useQuery<SelectPositionSuggestionsQueryQuery, SelectPositionSuggestionsQueryQueryVariables>(
    SUGGESTIONS_QUERY, { variables: { search: '%', countryCode } },
  );

  const searchDebounced = debounce(
    search => {
      const input: SelectPositionSuggestionsQueryQueryVariables = { search: `%${search}%` };
      if (countryCode) input.countryCode = countryCode;
      return refetchSuggestions(input);
    },
    25,
  );

  return (
    <Select
      placeholder={localization.formatMessage(Locale.Command.Select_position)}
      loading={suggestionLoading}
      onSelect={() => {
        refetchSuggestions({ search: '%' });
      }}
      showSearch
      onSearch={e => searchDebounced(e)}
      filterOption={false}
      value={value as string}
      onChange={onChange}
      className="position-selector"
    >
      {(suggestionData?.positions.nodes ?? []).map(p => (
        <Select.Option key={p.code} value={p.code}>
          {p.label}
        </Select.Option>
      ))}
    </Select>
  );
};

const SUGGESTIONS_QUERY = gql`
  query SelectPositionSuggestionsQuery($search: String, $countryCode: CountryCode) {
    positions(criteria: { label: $search, country: $countryCode, fetchSize: { limit: 1000 }}) {
      hash
      nodes {
        code
        label: displayLabel
        type
      }
    }
  }
`;


export default SelectPosition;
