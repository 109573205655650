import React from 'react';
import { keys } from 'lodash';
import { Select } from 'antd';

type SelectGenderProps = {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
};

const options = {
  M: 'Male',
  F: 'Female',
};

const SelectGender: React.FC<SelectGenderProps> = ({
  value,
  onChange,
  disabled,
}) => (
  <Select
    placeholder="Select gender"
    value={value as string}
    onChange={onChange}
    allowClear
    disabled={disabled}
  >
    {keys(options).map((key: string) => (
      <Select.Option key={key} value={key}>
        {/* @ts-ignore */}
        {options[key] ?? ''}
      </Select.Option>
    ))}
  </Select>
);

export default SelectGender;
