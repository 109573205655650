/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, Select, Spin, Tag } from 'antd';
import { isArray, isNil } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BaseSelectProps } from 'rc-select/lib/BaseSelect';
import { criteriaInputKeyGenerator } from '../CriteriaInputFactory';
import { CriteriaInputProps } from '../CriteriaInputTools';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import CriteriaLovModal from './CriteriaLovModal';
import Icon from '../../Icon/Icon';
import { PreferredLOVLanguage, usePreferredLOVLanguage } from '../../../util/usePreferredLOVLanguage';
import { useColors } from '../../../util/useColor';
import { useSuggestionDropdownValues } from '../useSuggestionDropdownValues';


const CriteriaMultiSelectInput: React.FC<CriteriaInputProps> = React.memo(({
  form,
  criteria,
  inputPath,
  disabled,
  initialValue,
  countryCodes,
  disableMaxCount,
  isDisallowed,
  initialValueOptionsLoad
}) => {
  const localization = useLocalization();
  const preferredLanguage = usePreferredLOVLanguage();
  const { colorAction: actionColor } = useColors();
  const key = criteriaInputKeyGenerator(criteria, inputPath);
  const [search, setSearch] = useState('');
  const selectedKeys = Form.useWatch<string[]>(key, form);

  const dropdown = useSuggestionDropdownValues({
    service: inputPath.service!,
    criteriaPathId: inputPath.id,
    countryCodes,
    criteria,
    search,
    selectedKeys,
  });

  const [lovSuggestions, setLovSuggestions] = useState(false);
  const [sortTop, setSortTop] = useState<string[]>([]);

  useEffect(() => {
    form.validateFields([key]);
    setSortTop(form.getFieldValue(key) ?? []);
    if (dropdown.records.length === 0 && initialValueOptionsLoad) {
      dropdown.triggerLoading();
    }
    if (!isNil(initialValue)) {
      const value = initialValue
        ? isArray(initialValue) ? initialValue : [initialValue]
        : [];
      form.setFieldsValue({ [key]: value });
    }
  }, [form, initialValue, key]);

  const filterSort = (optionA: { key: string; raw: string }, optionB: { key:string; raw: string }): number => {
    let diff = optionA.raw.toLowerCase().localeCompare(optionB.raw.toLowerCase());
    if (sortTop.includes(optionA.key)) diff -= 5000;
    if (sortTop.includes(optionB.key)) diff += 5000;
    return diff;
  };

  let options = dropdown.records.filter(d => !d.isGlobalOnly).map(item => {
    const raw = preferredLanguage === PreferredLOVLanguage.ENGLISH && !item.isValueHeadingKey && item.valueGb
      ? item.valueGb
      : (item.isValueHeadingKey
        ? localization.formatMessageByStr(item.value)
        : item.value);
    return ({
      key: item.key,
      value: item.key,
      // count: item.count,
      raw,
      disabled: false,
      label: (
        <span
          className="option-value"
          data-value-is-heading-key={item.isValueHeadingKey}
          data-localization-key={preferredLanguage === PreferredLOVLanguage.ENGLISH
            ? item.valueGb || item.value
            : item.value}
        >
          <span>{raw}</span>
          {/* <span className="option-badge"> */}
          {/*  {isActualNumber(item.count) */}
          {/*    ? <FormattedNumber value={item.count} /> */}
          {/*    : <LoadingOutlined style={{ fontSize: 10 }} spin />} */}
          {/* </span> */}
        </span>
      ),
    });
  }).sort(filterSort);

  if (dropdown.hasMoreResults) options = [...options, {
    key: '__load_additional__',
    raw: '__load_additional__',
    value: '__load_additional__',
    disabled: true,
    // count: undefined,
    label: (
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', cursor: 'pointer' }}>
        <Button
          type="link"
          size="small"
          style={{ width: '100%' }}
          onClick={() => dropdown.fetchMore()}
        >
          {localization.formatMessage(Locale.Command.Load_more)}
        </Button>
      </div>
    ),
  }];

  const dropdownRender: BaseSelectProps['dropdownRender'] = menu => (
    <div>
      {menu}
      {criteria.hasGlobalSupport && <>
        <Divider style={{ margin: '4px 0' }} />
        <div
          style={{ display: 'flex', flexWrap: 'nowrap', padding: 8, cursor: 'pointer' }}
          onClick={() => setLovSuggestions(true)}
        >
          <span style={{ color: actionColor }}>
            <Icon
              style={{ color: actionColor, fontSize: 16, marginLeft: 10 }}
              text={localization.formatMessage(Locale.Command.Expand_Search)}
              iconType="EXPAND"
            />
          </span>
        </div>
      </>}
    </div>
  );

  const tagRender: BaseSelectProps['tagRender'] = props => {
    if (!React.isValidElement(props.label)) return (
      <Tag closable={!disabled && props.closable} onClose={props.onClose}>
        {props.label}
      </Tag>
    );
    return (
      <Tag closable={!disabled && props.closable} onClose={props.onClose}>
        {props.label.props['data-value-is-heading-key']
          ? localization.formatMessageByStr(props.label.props['data-localization-key'])
          : props.label.props['data-localization-key']}
      </Tag>
    );
  };

  return (
    <>
      <Form.Item
        name={key}
        className="criteria-multi-select-input-container"
        rules={[
          {
            validator: (e, value) => {
              if (value && isDisallowed && value.length > 0) return Promise.reject(Error('message it\'s self won\'t be used'));
              return Promise.resolve();
            },
          }
        ]}
      >
        <Select
          id={key}
          showSearch={!disabled}
          showArrow={!disabled}
          onSearch={setSearch}
          mode="multiple"
          loading={dropdown.loading}
          placeholder={inputPath.placeholderHeading
            ? localization.formatMessage({ id: inputPath.placeholderHeading, defaultMessage: inputPath.placeholderHeading })
            : ''}
          size="large"
          disabled={disabled}
          notFoundContent={dropdown.loading
            ? <Spin size="small" />
            : <span>{localization.formatMessage(Locale.Text.Nothing_found)}</span>}
          className="criteria-multi-select-input-container-multi-group"
          popupClassName="criteria-multi-select-input-container-dropdown-container"
          style={{ width: '100%' }}
          maxTagCount={disableMaxCount ? undefined : 'responsive'}
          dropdownMatchSelectWidth={false}
          onDropdownVisibleChange={visible => {
            dropdown.triggerLoading();
            // if (visible && data.data.length === 0) data.fetchMore('CriteriaMultiSelectInput:onDropdownVisibleChange');

            if (!visible) {
              setSortTop(selectedKeys ?? []);
            }
          }}
          dropdownRender={dropdownRender}
          menuItemSelectedIcon={() => <></>}
          tagRender={tagRender}
          filterOption={false}
          options={options}
          onBlur={() => setSearch('')}
        />
      </Form.Item>
      <CriteriaLovModal
        title={inputPath.placeholderHeading}
        form={form}
        suggestionData={dropdown}
        isVisible={lovSuggestions}
        setVisibleAction={() => {
          setLovSuggestions(false);
          setSearch('');
          setSortTop(selectedKeys ?? []);
        }}
        fromValueKey={key}
        searchState={[search, setSearch]}
      />
    </>
  );
});

export default CriteriaMultiSelectInput;
