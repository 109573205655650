import React from 'react';
import { gql } from '@apollo/client';
import { Select } from 'antd';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import {
  EntityMergeFragment_Person_Fragment,
  MergeItemPersonProductSegmentationFragment,
  useAddPersonProductSegmentationMergeMutation,
  useDeletePersonProductSegmentationMergeMutation,
  usePersonProductSegmentationMergeLookupQuery,
} from '../../../../../../../../../gql/typings';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { Optional } from '../../../../../../../../util/StateArrayType';


const AdminMergeItemPersonProductSegmentation: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonProductSegmentationFragment;
  const fromPerson = props.from as EntityMergeFragment_Person_Fragment;
  const toPerson = props.into as Optional<EntityMergeFragment_Person_Fragment>;
  const localization = useLocalization();

  const { data } = usePersonProductSegmentationMergeLookupQuery();
  const [remove] = useDeletePersonProductSegmentationMergeMutation();
  const [add] = useAddPersonProductSegmentationMergeMutation();

  if (!props.item.hasConflict) return (
    <AdminMergeItemBasicField {...props}>
      {localization.formatMessage(Locale.Text.Merge_request_person_product_segmentation_code_description, {
        productName: <strong>{info.toBeTransferred?.product.heading}</strong>,
        segmentationCode: <strong>{info.toBeTransferred?.segmentationCode.heading}</strong>,
        user1: <strong>{fromPerson.fullName}</strong>,
        user2: <strong>{toPerson?.fullName}</strong>,
      })}
    </AdminMergeItemBasicField>
  );

  // When there is a conflict
  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.conflictFrom?.segmentationCode.id}
      toValue={info.conflictTo?.segmentationCode.id}
      updateMutation={newValue => remove({
        variables: {
          personId: props.into.id,
          productId: info.conflictFrom!.product.id,
        }
      }).then(() => add({
        variables: {
          personId: props.into.id,
          productId: info.conflictFrom!.product.id,
          segmentationCodeId: newValue,
        }
      }))}
      valueDisplayMapper={value => data?.segmentationCodes.nodes.filter(n => n.id === value)[0]?.heading ?? value}
      inputRenderer={props => (
        <Select {...props}>
          {data?.segmentationCodes.nodes.map(n => (
            <Select.Option key={n.id} value={n.id}>{n.heading}</Select.Option>
          ))}
        </Select>
      )}
    >
      {localization.formatMessage(Locale.Text.Merge_request_person_product_segmentation_code_conflict_mismatch_description, {
        productName: <strong>{info.conflictFrom!.product.heading}</strong>,
        segmentationCode1: <strong>{info.conflictFrom?.segmentationCode.heading}</strong>,
        segmentationCode2: <strong>{info.conflictTo?.segmentationCode.heading}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation DeletePersonProductSegmentationMerge($personId: Int!, $productId: Int!) {
    removeProductSegmentation(personId: $personId, productId: $productId)
  }
`;

gql`
  mutation AddPersonProductSegmentationMerge($personId: Int!, $productId: Int!, $segmentationCodeId: Int!) {
    addProductSegmentation(personId: $personId, productId: $productId, segmentationCodeId: $segmentationCodeId) {
      id
    }
  }
`;

gql`
  query PersonProductSegmentationMergeLookup {
    segmentationCodes {
      hash
      nodes {
        id
        heading
      }
    }
  }
`;

gql`
  fragment MergeItemPersonProductSegmentation on PersonProductSegmentation_EntityDataToBeTransferredType {
    code
    entityType
    toBeTransferred { ...persProdSeg }
    conflictFrom { ...persProdSeg }
    conflictTo { ...persProdSeg }
  }

  fragment persProdSeg on PersonProductSegmentation {
    id
    product {
      id
      heading
    }
    segmentationCode {
      id
      heading
    }
  }
`;

export default AdminMergeItemPersonProductSegmentation;
