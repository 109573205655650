import { gql, MutationFunctionOptions, useApolloClient, useQuery } from '@apollo/client';
import React from 'react';
import { max, uniq } from 'lodash';
import { ExclamationCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { notification, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FetchResult } from '@apollo/client/link/core';
import { NavigateFunction } from 'react-router/lib/hooks';
import useCherryPickMutation from './Components/useCherryPickMutation';
import ExternalNavLink from '../../components/ExternalNavLink/ExternalNavLink';
import { EntitiesSearchQuickActionItem } from '../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import { Locale } from '../../../localization/LocalizationKeys';
import { LocalizationShape, useLocalization } from '../../util/useLocalization';
import {
  CherryPickMutation,
  CherryPickMutationVariables,
  EntityTypeEnum, MultiCherryPickDataQueryQuery, MultiCherryPickDataQueryQueryVariables,
  SelectionCriteriaInput, SelectionCriteriaStyle
} from '../../../gql/typings';

type buildArgs = {
  localization: LocalizationShape;
  navigate: NavigateFunction;
  element?: React.ReactNode;
  globalSelected?: number[];
  cherryPickLoading?: boolean;
  cherryPick?: (
    options?: MutationFunctionOptions<CherryPickMutation, CherryPickMutationVariables>,
  ) => Promise<FetchResult<CherryPickMutation>>;
  onDone?: () => void;
  icon?: React.ReactNode;
  disabled?: boolean;
  countryCode?: string;
};

const build = ({
  localization,
  navigate,
  element = <></>,
  globalSelected = [],
  cherryPickLoading = false,
  cherryPick,
  onDone = () => {},
  icon = <ExclamationCircleOutlined />,
  disabled = true,
  countryCode,
}: buildArgs): EntitiesSearchQuickActionItem => ({
  key: 'cherry-pick',
  className: 'cherry-pick-selected',
  label: localization.formatMessage(Locale.Text.Cherry_pick_selected_records, { number: globalSelected.length }),
  hide: !countryCode,
  disabled: countryCode ? globalSelected.length === 0 : false,
  loading: cherryPickLoading,
  confirm: {
    label: element,
    icon,
    okLabel: localization.formatMessage(Locale.Attribute.Cherry_pick),
    cancelLabel: localization.formatMessage(Locale.Command.Cancel),
    disabled,
    overlayClassName: 'cherry-pick-popover-container',
  },
  onClick: () => {
    if (cherryPick) {
      cherryPick({
        variables: {
          type: EntityTypeEnum.PERSON,
          apurebaseIds: globalSelected,
        },
      }).then(res => {
        onDone();
        if (res.data) {
          const cherryPicked = res.data
            .cherryPick as Extract<NonNullable<CherryPickMutation['cherryPick']>['0'], { ['__typename']: 'Person' }>[];
          notification.success({
            duration: 0,
            message: localization.pluralMessage(
              Locale.Text.Successfully_cherry_picked,
              cherryPicked.length,
            ),
            description: <div>
              {cherryPicked.map(person => (
                <div key={person.id}>
                  <ExternalNavLink navigate={navigate} to={`/hcp/${person.id}`}>
                    {person.fullName}
                  </ExternalNavLink>
                </div>
              ))}
            </div>,
          });
        }
      });
    }
  },
});

/**
 * MARK: I'm sorry for writing this ugly code
 */
const multiCherryPickConfirmBtnAction = (personSelection: SelectionCriteriaInput, {
  onDone = () => {},
  type = EntityTypeEnum.PERSON,
} = {}): EntitiesSearchQuickActionItem|null => {
  const localization = useLocalization();
  const navigate = useNavigate();
  const apollo = useApolloClient();
  const countryCodes = uniq(personSelection.selected.map(selectedId => {
    switch (type) {
      case EntityTypeEnum.PERSON:
        // @ts-ignore
        return apollo.cache.data.data[`Person:${selectedId}`]?.countryCode;
      case EntityTypeEnum.SITE:
        // @ts-ignore
        return apollo.cache.data.data[`Site:${selectedId}`]?.countryCode;
      default:
        throw Error(`Entity type for multi cherrypick is not supported. [Provided: ${type}]`);
    }
  }));
  const countryCode = countryCodes[0];
  const { data, loading } = useQuery<MultiCherryPickDataQueryQuery,
  MultiCherryPickDataQueryQueryVariables>(MultiCherryPickConfirm_DATA_QUERY, {
    variables: { country: countryCode },
    fetchPolicy: 'network-only',
    skip: !countryCode,
  });
  const [cherryPick, { loading: cherryPickLoading }] = useCherryPickMutation();

  if (personSelection.style !== SelectionCriteriaStyle.STANDARD) return null;
  if (personSelection.selected.filter(hcpId => hcpId < 0).length === 0 || countryCode == null) return null;
  if (countryCodes.length > 1) return build({
    localization,
    navigate,
    element: <span>{localization.formatMessage(Locale.Text.Cherry_picking_multiple_records_not_supported)}</span>,
  });

  const amountLeft = data?.cherryPickOverview.amountAvailable ?? 0;
  const totalAmount = data?.cherryPickOverview.amount ?? 0;
  const globalSelected = personSelection.selected.filter(pId => pId < 0);
  let disabled = false;

  const contact = <a href="mailto:sales@apurebase.com">Contact sales</a>;


  let text = (
    <span>
      {localization.formatMessage(Locale.Text.You_have_cherrypicks_left, { amountLeft, totalAmount, countryCode })}.<br />
      {localization.formatMessage(Locale.Text.Do_you_want_to_selected_HCPs, { number: globalSelected.length })}
      {(amountLeft / (max([1, totalAmount]) as number)) <= 0.1 && (<span><br />{contact}</span>)}
    </span>
  );

  if (globalSelected.length > amountLeft) {
    disabled = true;
    text = (
      <span>
        {amountLeft === 0
          ? <span>{localization.formatMessage(Locale.Text.There_are_cherrypicks_left, { amountLeft: 'zero', countryCode })}
          </span>
          : <span>{localization.formatMessage(Locale.Text.There_are_cherrypicks_left, { amountLeft, countryCode })} </span> }
        <br />
        {localization.formatMessage(Locale.Text.You_are_trying_to_cherrypick, { number: globalSelected.length })}<br />
        {contact}
      </span>
    );
  }

  const confirmIcon = disabled
    ? <QuestionCircleOutlined style={{ color: 'red' }} />
    : <ExclamationCircleOutlined />;

  const element = (
    <div style={{ width: 320, height: 40 }}>
      <Skeleton
        title={false}
        paragraph={{ rows: 2 }}
        loading={loading}
        active={loading}
      >
        {text}
      </Skeleton>
    </div>
  );

  return build({
    navigate,
    localization,
    element,
    globalSelected,
    cherryPickLoading,
    cherryPick,
    onDone,
    icon: confirmIcon,
    disabled,
    countryCode,
  });
};

export const MultiCherryPickConfirm_DATA_QUERY = gql`
  query MultiCherryPickDataQuery($country: CountryCode!) {
    cherryPickOverview(country: $country) {
      customerCode
      country
      amount
      amountAvailable
      amountUsed
    }
  }
`;

export default multiCherryPickConfirmBtnAction;
