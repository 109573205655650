import { useQuery, gql } from '@apollo/client';
import React from 'react';
import { Skeleton } from 'antd';
import { NavLink } from 'react-router-dom';
import { SelectOutlined } from '@ant-design/icons';
import AffiliationCard from '../../../components/Card/AffiliationCard';
import { PersonDetailListsQuery } from '../../../../gql/typings';


const PersonListContainer: React.FC<{ personId: number }> = ({ personId }) => {
  const { data, loading } = useQuery<PersonDetailListsQuery>(DATA_QUERY, {
    variables: { personId },
  });
  const lists = data?.person?.lists.nodes ?? [];
  const type = { color: { code: 'GRAY' } };

  return (
    <Skeleton loading={loading}>
      {lists.map(it => <span key={it.id}>
        <AffiliationCard
          title={it.title}
          url={<NavLink to={`/list/${it.id}`}><SelectOutlined /></NavLink>}
          users={it.createdUser ? [it.createdUser] : []}
          type={type}
          note={it.description ?? ''}
          createdDate={it.ct}
        />
      </span>)}
    </Skeleton>
  );
};

const DATA_QUERY = gql`
  query PersonDetailLists($personId: Int!) {
    person(id: $personId) {
      id
      lists{
        hash
        nodes{
          id
          title
          description
          ct
          entityType{
            id
            heading
          }
          listType{
            id
            heading
          }
          createdUser{
            fullname
            id
          }
        }
      }
    }
  }
`;


PersonListContainer.propTypes = {};


export default PersonListContainer;

