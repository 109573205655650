import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { Optional } from '../../../../../../../../util/StateArrayType';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import {
  ContactValueInfoFragment,
  EntityTypeEnum,
  MergeContactValuesMutation,
  MergeContactValuesMutationVariables,
} from '../../../../../../../../../gql/typings';

type AdminMergeItemContactValueProps = AdminEntityMergeRequestItemProps & {
  value1: ContactValueInfoFragment;
  value2: Optional<ContactValueInfoFragment>;
  entityType: EntityTypeEnum;
  entityAffiliationWinnerId: number;
};

const AdminMergeItemContactValue: React.FC<AdminMergeItemContactValueProps> = (props) => {
  const localization = useLocalization();
  const [update] = useMutation<MergeContactValuesMutation, MergeContactValuesMutationVariables>(UPDATE_MUTATION);

  const categoryStr = props.value1.category.isHeadingKey
    ? localization.formatMessageByStr(props.value1.category.heading)
    : props.value1.category.heading;

  if (!props.value2) return (
    <AdminMergeItemBasicField {...props}>
      {localization.formatMessage(Locale.Text.Merge_request_contact_value_transfer_description, {
        contactType: <strong>{categoryStr}</strong>,
        contactValue: <strong>{props.value1.value}</strong>,
      })}
    </AdminMergeItemBasicField>
  );

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={props.value1.value}
      toValue={props.value2.value}
      updateMutation={finalValue => update({
        variables: {
          type: props.value1.category.typeEnum,
          entityType: props.entityType,
          entityAffiliationId: props.entityAffiliationWinnerId,
          values: [
            {
              valueId: props.value2?.id,
              value: finalValue,
              categoryCode: props.value1.category.code,
              consentStatus: props.value1.consent?.status,
            }
          ],
        },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_contact_value_transfer_mismatch_description, {
        contactType: <strong>{categoryStr}</strong>,
        contactValue1: <strong>{props.value1.value}</strong>,
        contactValue2: <strong>{props.value2.value}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

const UPDATE_MUTATION = gql`
  mutation MergeContactValues(
    $entityType: EntityTypeEnum!,
    $type: ContactTypeEnum!,
    $entityAffiliationId: Int!,
    $values: [ContactValueInput!]!
  ) {
    upsertContactValues(entityType: $entityType, type: $type, entityAffiliationId: $entityAffiliationId, values: $values) {
      id
      value
    }
  }
`;

export const AdminMergeItemContactValue_FRAGMENT = gql`
  fragment ContactValueInfo on ContactValue {
    id
    value
    consent {
      id
      status
    }
    category {
      code
      heading
      isHeadingKey
      typeEnum
    }
  }
`;

export default AdminMergeItemContactValue;
