import React from 'react';
import { gql } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { TableConfig, TableFieldsConfig } from '../../../search_old/types';

export const buildRoleAdminTableFragment = (additionalQuery: string|null) => gql`
  fragment RoleAdminTableFragment on Role {
    id
    ${additionalQuery ?? ''}
  }
`;

export const buildRoleAdminConnectionDataQuery = (additionalQuery: string|null) => gql`
  query RoleAdminTableQuery($criteria: RoleCriteria) {
    connection: roles(criteria: $criteria) {
      hash
      totalCount
      nodes {
        id
        ...RoleAdminTableFragment
      }
    }
  }
  ${buildRoleAdminTableFragment(additionalQuery)}
`;

export const buildRoleAdminRecordDataQuery = (additionalQuery: string|null) => gql`
  query RoleAdminRecordQuery($recordId: Int!) {
    record: role(id: $recordId) {
      id
      ...RoleAdminTableFragment
    }
  }
  ${buildRoleAdminTableFragment(additionalQuery)}
`;

export type SupportedRoleAdminTableFields = 'id'|'heading'|'amountUsers';

export const supportedRoleAdminTableFields: TableFieldsConfig<SupportedRoleAdminTableFields>['fields'] = {
  id: props => ({
    key: 'id',
    queryFields: ['id'],
    title: props.localization.formatMessage(Locale.Attribute.ID),
    render: (record) => (
      <NavLink to={`/admin/role/${record.id}`}>
        {record.id}
      </NavLink>
    ),
  }),
  heading: props => ({
    key: 'heading',
    queryFields: ['heading'],
    title: props.localization.formatMessage(Locale.Attribute.Heading),
    render: (record) => record.heading,
  }),
  amountUsers: props => ({
    key: 'amountUsers',
    queryFields: [
      'users.hash',
      'users.totalCount',
    ],
    title: props.localization.formatMessage(Locale.General.Users),
    render: (record) => props.localization.intl.formatNumber(record.users.totalCount),
  }),
};

export const roleAdminTableConfig: TableConfig = {
  buildConnectionQuery: buildRoleAdminConnectionDataQuery,
  buildRecordQuery: buildRoleAdminRecordDataQuery,
  buildFragment: buildRoleAdminTableFragment,
  titleLabel: Locale.General.Roles,
  columnConfig: {
    fields: supportedRoleAdminTableFields,
  },
};
