import React from 'react';
import { Tooltip, Typography } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';
import { PersonCriteriaOrder } from '../../../../gql/typings';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';

export const personWorkingSpecialtyFieldConfig = personFieldConfig(
  'workingSpecialty',
  [
    'primaryOrFirstAffiliation.id',
    'primaryOrFirstAffiliation.countryCode',
    'primaryOrFirstAffiliation.specialtyValues.hash',
    'primaryOrFirstAffiliation.specialtyValues.nodes.code',
    'primaryOrFirstAffiliation.specialtyValues.nodes.type',
    'primaryOrFirstAffiliation.specialtyValues.nodes.label',
    'primaryOrFirstAffiliation.specialtyValues.nodes.displayLabel',
  ],
  ({ localization }) => ({
    sorting: {
      ascend: PersonCriteriaOrder.WORKING_SPECIALTY_ASC,
      descend: PersonCriteriaOrder.WORKING_SPECIALTY_DESC,
    },
    title: localization.formatMessage(Locale.Attribute.Working_specialty),
    additionalTableConfig: {
      ellipsis: {
        showTitle: false
      },
      width: 150
    },
    render: (record, { isViewingFromPage }) => {
      const workingSpField = record?.primaryOrFirstAffiliation?.specialtyValues.nodes.map(s => s.displayLabel).join(', ');
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={workingSpField}>{workingSpField}</Tooltip>
      </Typography.Paragraph>);
    },
  })
);

