import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Card, Collapse, List, Skeleton, Tabs, Typography } from 'antd';
import numeral from 'numeral';
import { useLocalization } from '../../../../util/useLocalization';
import Flag, { CountryLabel, FlagCountry } from '../../../../components/Flag/Flag';
import { CustomerSelectionQueryQuery, CustomerSelectionQueryQueryVariables } from '../../../../../gql/typings';

type CustomerSelectionContainerProps = {
  customerCode: string;
};

const CustomerSelectionContainer: React.FC<CustomerSelectionContainerProps> = ({ customerCode }) => {
  const localization = useLocalization();
  const { data, loading } = useQuery<CustomerSelectionQueryQuery, CustomerSelectionQueryQueryVariables>(DATA_QUERY, {
    variables: { customerCode },
  });

  return (
    <Skeleton loading={loading} active={loading}>
      <Tabs
        tabPosition="left"
        items={(data?.selectionImfCustomers.nodes ?? []).map(country => {
          const countryCode = country.countryCode as FlagCountry;
          return {
            key: country.code,
            label: (
              <span>
                <Flag rectangle country={countryCode} />
                <br />
                {CountryLabel[countryCode] ? localization.formatMessage(CountryLabel[countryCode]) : country.countryCode}
              </span>
            ),
            children: (
              <Collapse>
                {country.selections.nodes.map(selection => (
                  <Collapse.Panel key={selection.id} header={selection.description}>
                    <Card>
                      {selection.groups.nodes.map(group => (
                        <Card.Grid key={group.id} style={{ width: '25%' }}>
                          <List<NonNullable<CustomerSelectionQueryQuery['selectionImfCustomers']
                          >['nodes']['0']['selections']['nodes']['0']['groups']['nodes']['0']['rows']['nodes']['0']>
                            dataSource={group.rows.nodes}
                            header={<Typography.Title level={5}>{group.description}</Typography.Title>}
                            renderItem={(row, index) => (
                              <List.Item>
                                <strong>Row {index}</strong>:&nbsp;
                                Persons: {numeral(row.persons.totalCount).format('0,0')}
                                &nbsp;|&nbsp;
                                Sites: {numeral(row.sites.totalCount).format('0,0')}
                              </List.Item>
                            )}
                          />
                        </Card.Grid>
                      ))}
                    </Card>
                  </Collapse.Panel>
                ))}
              </Collapse>
            )
          };
        })}
      />
    </Skeleton>
  );
};

const DATA_QUERY = gql`
  query CustomerSelectionQuery($customerCode: String!) {
    selectionImfCustomers(criteria: { customerCode: $customerCode }) {
      hash
      nodes {
        code
        countryCode
        isActive
        selections {
          hash
          nodes {
            id
            description
            type
            groups {
              hash
              nodes {
                id
                description
                rows {
                  hash
                  nodes {
                    id
                    persons {
                      hash
                      totalCount
                    }
                    sites {
                      hash
                      totalCount
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default CustomerSelectionContainer;
