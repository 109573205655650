import React from 'react';
import { useIntl } from 'react-intl';
import { Optional } from '../../util/StateArrayType';
import Icon from '../Icon/Icon';
import { Locale } from '../../../localization/LocalizationKeys';


// @ts-ignore
const CalenderCardCount: React.FC<{ count: Optional<number> }> = ({
  count
}) => {
  const { formatMessage } = useIntl();

  if (!count) {
    // @ts-ignore
    return <Icon
      className="calendar-card-badge grey"
      text={formatMessage(Locale.Text.No_activity_registered)}
      iconType="STATUS_ICON_GREY"
    />;
  }
  
  if (count === -1) {
    // @ts-ignore
    return <Icon
      className="calendar-card-badge green"
      text={`In ${count.toString().substring(1)} day`}
      iconType="STATUS_ICON_GREEN"
    />;
  }
  if (count < 0) {
    // @ts-ignore
    return (
      <Icon
        className="calendar-card-badge blue"
        text={`In ${count.toString().substring(1)} days`}
        iconType="STATUS_ICON_BLUE"
      />
    );
  }

  if (count === 0) {
    // @ts-ignore
    return <Icon className="calendar-card-badge green" text="Today" iconType="STATUS_ICON_GREEN" />;
  }
  if (count <= 15) {
    // @ts-ignore
    return <Icon className="calendar-card-badge green" text={`${count} days`} iconType="STATUS_ICON_GREEN" />;
  }
  if (count <= 30) {
    // @ts-ignore
    return <Icon className="calendar-card-badge yellow" text={`${count} days ago`} iconType="STATUS_ICON_YELLOW" />;
  }
  if (count >= 60) {
    // @ts-ignore
    return <Icon className="calendar-card-badge red" text={`${count} days ago`} iconType="STATUS_ICON_RED" />;
  }
  // @ts-ignore
  return <Icon className="calendar-card-badge grey" text="No activity registered" iconType="STATUS_ICON_GREY" />;


};


CalenderCardCount.propTypes = {

};


export default CalenderCardCount;

