import React from 'react';
import { Modal, Table, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { isFunction, sortBy } from 'lodash';
import { StateArray } from '../../util/StateArrayType';
import { useLocalization } from '../../util/useLocalization';
import { TableConfig, TableFieldReturned } from '../../browse/search_old/types';

type ConfigureTableColumnOverviewModalProps = {
  visibleState: StateArray<boolean>;
  config: TableConfig;
};

const ConfigureTableColumnOverviewModal: React.FC<ConfigureTableColumnOverviewModalProps> = ({
  visibleState: [visible, setVisible],
  config,
}) => {
  const localization = useLocalization();
  const navigate = useNavigate();

  const fields: TableFieldReturned<string>[] = Object.values(config.columnConfig.fields).map(fn => fn({
    localization,
    navigate,
  }));

  return (
    <Modal
      title={`${localization.formatMessage(config.titleLabel)} columns overview`}
      open={visible}
      onCancel={() => setVisible(false)}
      width="clamp(40%, 1080px, 100%)"
      footer={false}
    >
      <Table
        dataSource={sortBy(fields, e => e.key)}
        size="small"
        // @ts-ignore
        sticky={{ getContainer: () => document.querySelector('.ant-modal-body') }}
        pagination={false}
        rowSelection={{ type: 'checkbox' }}
        columns={[
          {
            title: 'Key',
            render: (_, record) => <Typography.Text code>{record.key}</Typography.Text>,
          },
          {
            title: 'Title',
            render: (_, record) => isFunction(record.title)
              ? ''
              : record.title
          },
          {
            title: 'Sortable',
            render: (_, record) => record.sorting
              ? <CheckCircleOutlined style={{ color: 'green' }} />
              : <CloseCircleOutlined style={{ color: 'gray' }} />,
          },
          {
            title: 'Update Support',
            render: (_, record) => record.updateView
              ? <CheckCircleOutlined style={{ color: 'green' }} />
              : <CloseCircleOutlined style={{ color: 'gray' }} />,
          },
          {
            title: 'DCR Support',
            render: (_, record) => record.dcrInfo
              ? <CheckCircleOutlined style={{ color: 'green' }} />
              : <CloseCircleOutlined style={{ color: 'gray' }} />,
          },
          {
            title: 'Dynamic Type',
            render: (_, record) => record.getOptions
              ? <CheckCircleOutlined style={{ color: 'green' }} />
              : <CloseCircleOutlined style={{ color: 'gray' }} />,
          },
        ]}
      />
    </Modal>
  );
};

export default ConfigureTableColumnOverviewModal;
