import { gql, useApolloClient } from '@apollo/client';
import moment from 'moment';
import { useEffect } from 'react';
import { useUpdate } from 'ahooks';
import {
  _PermissionStore,
  permissionCallbackPublish,
  permissionCallbackRegister,
  permissionCallbackUnregister,
  PermissionInfo
} from './permissionUtils';
import { HasPermissionQueryQuery, HasPermissionQueryQueryVariables, PermissionEnum } from '../../gql/typings';


export const usePermission = (
  permissionCode: `${PermissionEnum}`,
  doLoad = true,
): PermissionInfo => {
  const apolloClient = useApolloClient();
  const update = useUpdate();

  useEffect(() => {
    const existing = _PermissionStore[permissionCode];
    if (doLoad && (!existing || existing.last.isBefore(moment().subtract(15, 'minutes')))) {
      apolloClient.query<HasPermissionQueryQuery, HasPermissionQueryQueryVariables>({
        query: PERMISSIONS_QUERY,
        variables: { permissions: [permissionCode as PermissionEnum] },
      }).then(res => {
        if (res.data.hasPermissions.length === 1) {
          _PermissionStore[permissionCode] = {
            last: moment(),
            create: res.data.hasPermissions[0]!.create,
            read: res.data.hasPermissions[0]!.read,
            update: res.data.hasPermissions[0]!.update,
            delete: res.data.hasPermissions[0]!.delete,
          };
          permissionCallbackPublish(permissionCode);
        }
      });
    }

    const key = permissionCallbackRegister(permissionCode, () => {
      update();
    });

    return () => {
      permissionCallbackUnregister(permissionCode, key);
    };
  }, [apolloClient, doLoad, permissionCode, update]);

  return {
    loading: !_PermissionStore[permissionCode],
    create: _PermissionStore[permissionCode]?.create ?? false,
    read: _PermissionStore[permissionCode]?.read ?? false,
    update: _PermissionStore[permissionCode]?.update ?? false,
    delete: _PermissionStore[permissionCode]?.delete ?? false,
  };
};


export const PERMISSIONS_QUERY = gql`
  query HasPermissionQuery($permissions: [PermissionEnum!]!) {
    hasPermissions(permissions: $permissions) {
      code
      create
      read
      update
      delete
    }
  }
`;

