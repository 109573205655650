import React from 'react';
import { Tooltip, Typography } from 'antd';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { personFieldConfig } from './utils/personFieldsUtils';
import { EntityTypeEnum, PersonCriteriaOrder } from '../../../../gql/typings';


export const personFullNameFieldConfig = personFieldConfig(
  'fullname',
  ['id', 'fullname', 'isLocal'],
  ({ localization, openInNewTab }) => ({
    sorting: {
      ascend: [PersonCriteriaOrder.FIRSTNAME_ASC, PersonCriteriaOrder.LASTNAME_ASC],
      descend: [PersonCriteriaOrder.FIRSTNAME_DESC, PersonCriteriaOrder.LASTNAME_DESC],
    },
    additionalTableConfig: {
      width: 300
    },
    title: localization.formatMessage(Locale.Attribute.Full_name),
    render: (record, { isViewingFromEntity, isViewingFromPage }) => {
      if (isViewingFromPage == TableFieldReturnedRecordPageType.DETAIL_PAGE
      && isViewingFromEntity === EntityTypeEnum.PERSON) return <Tooltip title={record.fullname} placement='right'>
        <Typography.Text ellipsis>{record.fullname}</Typography.Text>
      </Tooltip>;
      return <LinkBadge
        url={record.isLocal ? `/hcp/${record.id}` : undefined}
        label={record.fullname}
        openInNewTab={openInNewTab}
      />;
    },
  })
);

