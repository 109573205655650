import React from 'react';
import { gql } from '@apollo/client';
import { Locale } from '../../../localization/LocalizationKeys';
import PersonActivityContainer from '../person/Components/Activities';
import PersonSiteAffiliationsTable from '../person/PersonSiteAffiliationsTable';
import PersonListContainer from '../person/Components/PersonListContainer';
import SitePersonAffiliationsTable from '../sitePerson/SitePersonAffiliationsTable';
import { RelationAffiliationTabConfig } from '../../components/EntityTabs/RelationTabs/EntityRelationTabs';
import { LinkTabDetailQuery, PermissionEnum } from '../../../gql/typings';
import LinkNoteContainer from './Components/LinkNoteContainer';
import LinkSelectionRowResultContainer from './Components/LinkSelectionRowResultContainer';


export enum LinkAffiliationsTabsEnum {
  sites = 'sites',
  personAffiliations = 'personAffiliations',
  activities = 'activities',
  lists = 'lists',
  notes = 'notes',
  selections = 'selections',
}

export const linkAffiliationTabConfigs: Record<LinkAffiliationsTabsEnum, RelationAffiliationTabConfig<LinkTabDetailQuery>> = {
  [LinkAffiliationsTabsEnum.sites]: {
    title: Locale.Attribute.Person_other_sites,
    tab: {
      title: ({ formatMessage }, data) => formatMessage(
        Locale.Attribute.Affiliation_site_header,
        { name: data?.sitePersonAffiliation?.person?.fullName },
      ),
      show: true,
      badgeProps: data => ({ count: data?.sitePersonAffiliation?.person.affiliations.totalCount ?? 0 }),
    },
    render: (_, data) => data?.sitePersonAffiliation && <PersonSiteAffiliationsTable
      personId={data.sitePersonAffiliation.person.id}
    />,
  },
  [LinkAffiliationsTabsEnum.notes]: {
    title: Locale.Attribute.Notes,
    tab: {
      title: ({ formatMessage }, data) => formatMessage(
        Locale.Attribute.Notes
      ),
      badgeProps: data => ({ count: data?.sitePersonAffiliation?.notes.totalCount ?? 0 }),
      show: data => (data?.sitePersonAffiliation?.notes.totalCount ?? 0) > 0,
    },
    render: (_, data) => data?.sitePersonAffiliation && (
      <LinkNoteContainer linkId={data.sitePersonAffiliation.id} />
    ),
  },
  [LinkAffiliationsTabsEnum.personAffiliations]: {
    title: Locale.Attribute.Site_colleagues,
    tab: {
      title: ({ formatMessage }, data) => formatMessage(
        Locale.Attribute.Affiliation_person_header,
        { name: data?.sitePersonAffiliation?.site.name },
      ),
      show: data => (data?.sitePersonAffiliation?.site.persons.totalCount ?? 0) > 0,
      badgeProps: data => ({ count: data?.sitePersonAffiliation?.site.persons.totalCount ?? 0 }),
    },
    render: (_, data) => data?.sitePersonAffiliation && (
      <SitePersonAffiliationsTable siteId={data.sitePersonAffiliation.siteId} />
    ),
  },
  [LinkAffiliationsTabsEnum.activities]: {
    title: Locale.Attribute.Person_activities,
    tab: {
      title: ({ formatMessage }, data) => formatMessage(
        Locale.Attribute.Affiliation_activity_header,
        { name: data?.sitePersonAffiliation?.person.fullName },
      ),
      show: data => (data?.sitePersonAffiliation?.person.activities.totalCount ?? 0) > 0,
      badgeProps: data => ({ count: data?.sitePersonAffiliation?.person.affiliations.totalCount ?? 0 })
    },
    render: (_, data) => data?.sitePersonAffiliation && <PersonActivityContainer
      personId={data.sitePersonAffiliation.person.id}
    />,
  },
  [LinkAffiliationsTabsEnum.lists]: {
    title: Locale.Attribute.Person_lists,
    tab: {
      title: ({ formatMessage }, data) => formatMessage(
        Locale.Attribute.Affiliation_list_header,
        { name: data?.sitePersonAffiliation?.person.fullName },
      ),
      show: data => (data?.sitePersonAffiliation?.person.lists.totalCount ?? 0) > 0,
      badgeProps: data => ({ count: data?.sitePersonAffiliation?.person.lists.totalCount ?? 0 }),
    },
    render: (_, data) => data?.sitePersonAffiliation && <PersonListContainer
      personId={data?.sitePersonAffiliation?.person.id}
    />,
  },
  [LinkAffiliationsTabsEnum.selections]: {
    title: Locale.Attribute.Selections,
    readPermission: PermissionEnum.SELECTION,
    tab: {
      show: () => true,
      badgeProps: (data) => ({ count: data?.sitePersonAffiliation?.selectionRowsCustomerCount }),
    },
    render: (_, data) => data?.sitePersonAffiliation?.apurebaseId && <LinkSelectionRowResultContainer
      linkId={data.sitePersonAffiliation.id}
      apurebaseId={data.sitePersonAffiliation.apurebaseId}
    />
  },
};


export const LinkDetails_DATA_QUERY = gql`
  query LinkTabDetail($recordId: Int!) {
    sitePersonAffiliation(id: $recordId) {
      id
      siteId
      apurebaseId
      selectionRowsCustomerCount(criteria: { selectionType: DYNAMIC })
      notes {
        hash
        totalCount
      }
      site{
        id
        name
        persons{
          totalCount
          hash
        }
      }
      person{
        id
        fullName
        title
        ct
        activities{
          hash
          totalCount
          nodes{
            id
            heading
            type{
              code
              heading
            }
          }
        }
        notes{
          hash
          totalCount
          nodes{
            id
            title

          }
        }
        lists{
          hash
          totalCount
          nodes{
            id
            title
          }
        }
        projects{
          hash
          totalCount
          nodes{
            id
            title
          }
        }
        segmentation{
          code
          heading
        }
        affiliations{
          hash
          totalCount
          nodes {
            id
            site{
              id
              name

            }
          }

        }
        persType{
          code
          type
          label
        }
      }
    }
  }
`;
