import React from 'react';
import { ApolloClient, DocumentNode, useQuery } from '@apollo/client';
import { get, set } from 'lodash';
import { ColumnsType, ColumnType } from 'antd/lib/table/interface';
import { QueryResult } from '@apollo/client/react/types/types';
import { NavigateFunction } from 'react-router/lib/hooks';
import { TableProps } from 'antd';
import { SiteTableConfig } from '../../site/SiteTable';
import { BuiltCriteria } from '../../../util/criteriaBuilderSelector';
import { PersonTableConfig } from '../../person/PersonTable';
import { EntitySearchColumnParamType } from './EntitySearchColumnParamType';
import { LocalizationShape } from '../../../util/useLocalization';
import { ActivityTableConfig } from '../../activity/ActivityTable/ActivitiesTable';
import { LinkTableConfig } from '../../link/LinkTable';
import { DcrGroupTableConfig } from '../../dcr/DcrGroupTable';
import { InstanceDeploymentsTableConfig } from '../../admin/clients/InstanceDeploymentsTable';
import { useTableColumns, SortingStorage } from '../../../components/entitiesSearch/results/useTableColumns';
import { UserAdminTableConfig } from '../../admin/users/UserAdminTable';
import { roleAdminTableConfig } from '../../admin/users/roles/RoleAdminTable';
import { adminEntityMergeRequestTable } from '../../admin/adminComponents/AdminEntityMergeRequest/AdminEntityMergeRequestTable';
import { DcrPopOverProps } from '../../../components/DcrPopOver/DcrPopOver';
import { ListTableConfig } from '../../list/components/ListTable';
import { QueryFieldsReturned } from '../../../components/entitiesSearch/queryFieldsTypings';
import { EntityTypeEnum, PermissionEnum, Query } from '../../../../gql/typings';
import { Optional } from '../../../util/StateArrayType';
import { PersonSelectionRowTableConfig } from '../../personSelectionRow/PersonSelectionRowTable';
import { SiteSelectionRowTableConfig } from '../../siteSelectionRow/SiteSelectionRowTable';
import { SelectionRowResultTableConfig } from '../../selectionRowResult/SelectionRowResultTable';


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TableConfig<T = any, FIELDS extends string = string> = {
  buildFragment: (queryFields: string | null) => DocumentNode;
  /**
   * It needs to define a query which:
   * * returns a connection object with the alias 'connection'.
   * * takes in a variable called 'criteria'
   */
  buildConnectionQuery: (queryFields: string | null, hasGlobalSearchPermission?: boolean) => DocumentNode;

  /**
   * It needs to define a query which:
   * * returns a record with the alias 'record'.
   * * takes in a variable called 'recordId'
   */
  buildRecordQuery: (queryFields: string | null) => DocumentNode;
  columnConfig: TableFieldsConfig<FIELDS>;
  titleLabel: { id: string };
  extraConfig?: ExtraConfig<T>;
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtraConfig<T = any> = {
  expandable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapData?: (input: any) => any;
  onRowClick?: (record: T, args: EntitySearchColumnParamTypeWithState) => void;
};

export type SupportedEntitySearchTypes =
  EntityTypeEnum.PERSON
  | EntityTypeEnum.ACTIVITY
  | EntityTypeEnum.SITE
  | EntityTypeEnum.PROJECT
  | EntityTypeEnum.LIST
  | EntityTypeEnum.BRICK
  | EntityTypeEnum.DCR_GROUP
  | EntityTypeEnum.AFFILIATION
  | EntityTypeEnum.AFFILIATION_FROM_PERSON
  | EntityTypeEnum.AFFILIATION_FROM_SITE
  | EntityTypeEnum.PURE_ADVANCE_CUSTOMER
  | EntityTypeEnum.USER
  | EntityTypeEnum.ROLE
  | EntityTypeEnum.ENTITY_MERGE
  | EntityTypeEnum.PERS_SELECTION_ROW
  | EntityTypeEnum.SITE_SELECTION_ROW
  | EntityTypeEnum.SELECTION_ROW_RESULT;

export type EntitySearchColumnParamTypeWithState = EntitySearchColumnParamType & {
  navigate: NavigateFunction;
  localization: LocalizationShape;
  keyCode?: TableFieldsReturnedOptionType['code'];
};

export enum TableFieldReturnedRecordPageType {
  DETAIL_PAGE = 'DETAIL_PAGE',
  VIEW_PAGE = 'VIEW_PAGE',
}

export type TableFieldReturnedRecordOptions = {
  selectedOption?: TableFieldsReturnedOptionType;
  refetchData: () => void;
  isViewingFromEntity: EntityTypeEnum;
  isViewingFromPage: TableFieldReturnedRecordPageType;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TableFieldUpdateViewProps<T = any> = {
  record: T;
  options: TableFieldReturnedRecordOptions;
  endEditing: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface TableFieldReturned<
  K,
  RecordRoot = Query,
  RecordInputs extends string[] = string[],
> {
  key: K;
  /**
   * Available placeholders:
   *   * `{{keyCode}}` - is the selected code from [getOptions]
   */
  queryFields?: RecordInputs;
  permission?: PermissionEnum | ((keycode: string) => Promise<boolean> | boolean);
  title?: ((options: TableFieldReturnedRecordOptions) => React.ReactNode) | React.ReactNode;
  additionalTableConfig?: Omit<ColumnType<RecordRoot>, 'title'|'render'|'sorter'|'sortOrder'>;
  dcrInfo?: (
    record: QueryFieldsReturned<RecordRoot, RecordInputs>,
    options: TableFieldReturnedRecordOptions,
  ) => Optional<DcrPopOverProps>;
  render: (record: QueryFieldsReturned<RecordRoot, RecordInputs>, options: TableFieldReturnedRecordOptions) => React.ReactNode;
  onCell?: (record: QueryFieldsReturned<RecordRoot, RecordInputs>, options: TableFieldReturnedRecordOptions) => React.ReactNode;
  sorting?: {
    ascend: string | string[];
    descend: string | string[];
  };
  /**
   * When enabled. It will always show the [value], also when in updating mode.
   */
  preserve?: true;
  hasUpdateSupport?: (
    args: Omit<TableFieldUpdateViewProps<QueryFieldsReturned<RecordRoot, RecordInputs>>, 'endEditing'>,
  ) => boolean;
  updateView?: React.FC<TableFieldUpdateViewProps<QueryFieldsReturned<RecordRoot, RecordInputs>>>;
  getOptions?: [
    string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    (arg: { apolloClient: ApolloClient<object> }) => Promise<TableFieldsReturnedOptionType[]>,
  ];
}

export type TableFieldsReturnedOptionType = {
  code: number|string;
  label: string|React.ReactNode;
  allowMultiple?: boolean | null | undefined;
};

export type FieldTypeConfig<
  K,
  RecordRoot,
  RecordInputs extends string[],
> = (props: EntitySearchColumnParamTypeWithState) => TableFieldReturned<K, RecordRoot, RecordInputs>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TableFieldsConfig<F extends string, RecordRoot = any, RecordInputs extends string[] = any> = {
  fields: {
    [K in F]: FieldTypeConfig<K, RecordRoot, RecordInputs>;
  };
};


export type UseTableColumns<SUPPORTED_FIELDS = string> = {
  loading: boolean;
  query: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onTableChange: TableProps<any>['onChange'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnsType<any>;
  sorting: SortingStorage<SUPPORTED_FIELDS>[];
};


export const entitiesTableConfigs: Record<SupportedEntitySearchTypes, TableConfig> = {
  PERSON: PersonTableConfig,
  ACTIVITY: ActivityTableConfig,
  SITE: SiteTableConfig,
  LIST: ListTableConfig,
  DCR_GROUP: DcrGroupTableConfig,
  PURE_ADVANCE_CUSTOMER: InstanceDeploymentsTableConfig,
  USER: UserAdminTableConfig,
  ROLE: roleAdminTableConfig,
  ENTITY_MERGE: adminEntityMergeRequestTable,
  // @ts-ignore
  BRICK: undefined,
  // @ts-ignore
  PROJECT: undefined,
  AFFILIATION: LinkTableConfig,
  AFFILIATION_FROM_PERSON: LinkTableConfig,
  AFFILIATION_FROM_SITE: LinkTableConfig,
  PERS_SELECTION_ROW: PersonSelectionRowTableConfig,
  SITE_SELECTION_ROW: SiteSelectionRowTableConfig,
  SELECTION_ROW_RESULT: SelectionRowResultTableConfig,
};

export const tableColumns = (type: SupportedEntitySearchTypes, props: EntitySearchColumnParamType) => {
  const e = entitiesTableConfigs[type];
  if (!e) throw Error(`EntitiesSearch:type: ${type} isn't supported!`);
  return useTableColumns(type, props).columns;
};

export const useEntitiesSearchQuery = (
  entityType: SupportedEntitySearchTypes,
  criteriaInput: BuiltCriteria,
  openInNewTab?: boolean,
  hasGlobalSearchPermission = true
): EntitiesTableQueryConfig => {
  const config = entitiesTableConfigs[entityType];
  if (config == null) throw Error(`EntitiesSearch:entityType: ${entityType} isn't supported!`);

  const columns = useTableColumns(entityType, {
    openInNewTab,
    cherryPickSupport: true,
  });

  const queryResult = useQuery(config.buildConnectionQuery(columns.query, hasGlobalSearchPermission), {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: criteriaInput,
  });

  const data = get(queryResult, 'data.connection.nodes', []);
  set(queryResult, 'data.connection.nodes', config.extraConfig?.mapData?.(data) ?? data);

  // We do not want to show the user that were are re-fetching the nodes for the second time.
  set(queryResult, 'loading', get(queryResult, 'data.connection.totalCount', 0) ? false : queryResult.loading);

  return {
    ...queryResult,
    expandable: config.extraConfig?.expandable ?? false,
    onRowClick: config.extraConfig?.onRowClick,
  };
};

export const getEntityTypeLabel = (entityType: SupportedEntitySearchTypes) => entitiesTableConfigs[entityType]?.titleLabel;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface EntitiesTableQueryConfig<T = any> extends QueryResult<T> {
  /**
   * Whether this table contains fields that could be expanded.
   */
  expandable: boolean;

  onRowClick?: (record: T, args: EntitySearchColumnParamTypeWithState) => void;
}
