import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Select } from 'antd';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import {
  EntityMergeFragment_SitePerson_Fragment,
  MergeItemAffiliationWorkingSpecialitiesFragment, MRSetWorkingSpecialitiesMutation, MRSetWorkingSpecialitiesMutationVariables,
  MRWorkingSpecialitiesQuery, MRWorkingSpecialitiesQueryVariables,
} from '../../../../../../../../../gql/typings';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { reactJoin } from '../../../../../../../../util/reactJoin';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';


const AdminMergeItemAffiliationWorkingSpecialities: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemAffiliationWorkingSpecialitiesFragment;
  const fromLink = props.from as EntityMergeFragment_SitePerson_Fragment;
  const localization = useLocalization();
  const { data, loading } = useQuery<MRWorkingSpecialitiesQuery, MRWorkingSpecialitiesQueryVariables>(DATA_QUERY, {
    variables: { countryCode: fromLink.countryCode },
  });
  const [update] = useMutation<MRSetWorkingSpecialitiesMutation, MRSetWorkingSpecialitiesMutationVariables>(SET_SPECIALTIES);

  if (!props.item.hasConflict) return (
    <AdminMergeItemBasicField {...props}>
      {localization.formatMessage(Locale.Text.Merge_request_person_tags_description, { // <-- TODO: New label!
        tags: reactJoin(info.loserSpecialities.map(lc => <strong key={lc.id}>{lc.specialty.displayLabel}</strong>))
      })}
    </AdminMergeItemBasicField>
  );

  return (
    <AdminMergeItemBasicConflictField<string[]>
      {...props}
      fromValue={info.loserSpecialities.map(lc => lc.specialty.code)}
      toValue={info.winnerSpecialities.map(lc => lc.specialty.code)}
      updateMutation={workingSpecialtyCodes => update({
        variables: {
          linkId: props.into.id,
          workingSpecialtyCodes,
        },
      })}
      valueDisplayMapper={codes => reactJoin((data?.workingSpecialties?.nodes ?? [])
        .filter(n => codes?.includes(n.code)).map(speciality => (
          <strong key={speciality.code}>{speciality.displayLabel}</strong>
        )))}
      inputRenderer={props => (
        <Select
          {...props}
          loading={loading}
          mode="tags"
          options={(data?.workingSpecialties.nodes ?? []).map(fc => ({ value: fc.code, label: fc.displayLabel }))}
        />
      )}
    >
      {localization.formatMessage(Locale.Text.Merge_request_link_working_specialities_mismatch_description, {
        speciality1: reactJoin(info.loserSpecialities.map(ls => <strong key={ls.id}>{ls.specialty.displayLabel}</strong>)),
        speciality2: reactJoin(info.winnerSpecialities.map(ls => <strong key={ls.id}>{ls.specialty.displayLabel}</strong>)),
      })}
    </AdminMergeItemBasicConflictField>
  );
};

const SET_SPECIALTIES = gql`
  mutation MRSetWorkingSpecialities($linkId: Int!, $workingSpecialtyCodes: [String!]!) {
    updateSitePersonWorkingSpecialties(linkId: $linkId, workingSpecialtyCodes: $workingSpecialtyCodes) {
      id
    }
  }
`;

const DATA_QUERY = gql`
  query MRWorkingSpecialities($countryCode: CountryCode!) {
    workingSpecialties(criteria: { countries: [$countryCode], fetchSize: { limit: 10000 } }) {
      hash
      nodes {
        code
        type
        displayLabel
      }
    }
  }
`;

gql`
  fragment MergeItemAffiliationWorkingSpecialities on AffiliationWorkingSpecialities_EntityDataToBeTransferredType {
    code
    entityType
    loserSpecialities {
      id
      specialty {
        code
        type
        displayLabel
      }
    }
    winnerSpecialities {
      id
      specialty {
        code
        type
        displayLabel
      }
    }
  }
`;

export default AdminMergeItemAffiliationWorkingSpecialities;
