import React from 'react';
import { useIntl } from 'react-intl';
import { Popover } from 'antd';
import Icon from '../../../components/Icon/Icon';
import { Locale } from '../../../../localization/LocalizationKeys';
import { Optional } from '../../../util/StateArrayType';

const DisplayItem: React.FC<{ label: React.ReactNode; value: React.ReactNode }> = ({ label, value }) => {
  if (!value) return <></>;
  return (
    <>
      <span><b>{label}: </b></span>
      <span>{value}</span>
      <br />
    </>
  );
};

type RawPersonPopoverCardProps = {
  firstName: Optional<string>;
  lastName: Optional<string>;
  countryCode: Optional<string>;
  title: Optional<string>;
  gender: Optional<string>;
  officialId: Optional<string>;
  personType: Optional<string>;

  loading?: boolean;
  footer?: React.ReactNode;
};

const RawPersonPopoverCard: React.FC<RawPersonPopoverCardProps> = ({
  firstName,
  lastName,
  countryCode,
  title,
  gender,
  officialId,
  personType,

  loading,
  footer,
  children,
}) => {
  const { formatMessage } = useIntl();

  let info = <span>Loading</span>;
  if (!loading) {
    info = (
      <>
        <div className="left grid-item">
          <Icon iconType="PERSON" />
        </div>

        <div className="center grid-item">
          <DisplayItem label={formatMessage(Locale.Attribute.First_name)} value={firstName} />
          <DisplayItem label={formatMessage(Locale.Attribute.Last_name)} value={lastName} />
          <DisplayItem label={formatMessage(Locale.Attribute.Country)} value={countryCode} />
          <DisplayItem label={formatMessage(Locale.Attribute.Title)} value={title} />
          <DisplayItem label={formatMessage(Locale.Attribute.Gender)} value={gender} />
          <DisplayItem label={formatMessage(Locale.Attribute.Official_ID)} value={officialId} />
          <DisplayItem label={formatMessage(Locale.Attribute.Person_type)} value={personType} />
        </div>
      </>
    );
  }

  return (
    <Popover
      trigger="hover"
      placement="topLeft"
      overlayClassName="person-popover-card-container"
      content={
        <div className="container">
          {info}
          {footer && <div className="button">
            <div className="grid-item">
              {footer}
            </div>
          </div>}
        </div>
      }
    >
      {children}
    </Popover>
  );
};


export default RawPersonPopoverCard;
