import React, { useEffect, useState } from 'react';
import { Form, DatePicker, Button, Input, InputNumber, Select, Tabs, Popover, Space } from 'antd';
import { isNil } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { useLocalStorageState } from 'ahooks';
import { criteriaInputKeyGenerator } from '../CriteriaInputFactory';
import { Locale } from '../../../../localization/LocalizationKeys';
import { CriteriaInputProps } from '../CriteriaInputTools';
import { useLocalization } from '../../../util/useLocalization';
import { DeserializeDate, SerialiseDate, TimeSearch } from './CriteriaDateUtils';
import { DATE_FORMAT } from '../../../util/format';

const { RangePicker } = DatePicker;
type TabKey = 'newerThan'|'olderThan'|'exactDate'|'range';


 type DateFormInput = {
   duration?: number;
   durationType?: string;
   exactDate?: Dayjs;
   range?: [Dayjs, Dayjs];
 };

// TODO: This display string should be way better!
const generateDisplayString = (activeKey: TabKey, formValues: string): string => {
  const args = DeserializeDate(formValues);
  switch (activeKey) {
    case 'newerThan':
    case 'olderThan':
      return args.to?.relativePoint ?? args.from?.relativePoint ?? '';
    case 'exactDate':
      return args.to?.timestamp ? dayjs(args.to?.timestamp)?.format(DATE_FORMAT) : '';
    case 'range':
      if (!args.from?.timestamp && !args.to?.timestamp) return '';
      // eslint-disable-next-line max-len
      return `${dayjs(args.from?.timestamp)?.format(DATE_FORMAT) ?? ''} to ${dayjs(args.to?.timestamp)?.format(DATE_FORMAT) ?? ''}`;
    default:
      return '';
  }
};


const CriteriaDateOptions: React.FC<CriteriaInputProps> = ({
  form,
  criteria,
  inputPath,
  initialValue,
  disabled,
}) => {
  const key = criteriaInputKeyGenerator(criteria, inputPath);

  const [activeKey, setActiveKey] = useLocalStorageState<TabKey>('criteria_date_option_tab', { defaultValue: 'newerThan' });
  const [selectedDate, setSelectedDate] = useState<string>(generateDisplayString(activeKey, form.getFieldValue(key)));
  const [clicked, setClicked] = useState(false);

  const formValue = form.getFieldValue(key);
  const localization = useLocalization();
  const [dateForm] = Form.useForm<DateFormInput>();
  const { duration } = Form.useWatch([], dateForm) || {};

  const dateDurationOptions = [
    /*  { label: 'Minutes', value: 'T%M' },
      { label: 'Hours', value: 'T%H' }, */
    { label: localization.pluralMessage(Locale.General.Days_plural, duration ?? 1), value: 'P%D' },
    { label: localization.pluralMessage(Locale.General.Weeks_plural, duration ?? 1), value: 'P%W' },
    { label: localization.pluralMessage(Locale.General.Months_plural, duration ?? 1), value: 'P%M' },
    { label: localization.pluralMessage(Locale.General.Years_plural, duration ?? 1), value: 'P%Y' }];

  const initialDateFormValue = getInitialFormValues();

  const tabOptions = [
    {
      key: 'newerThan',
      label: localization.formatMessage(Locale.General.Newer_than),
      children: (
        <div style={{ display: 'flex', columnGap: 5 }}>
          <Form.Item label={localization.formatMessage(Locale.General.Duration)} name='duration'>
            <InputNumber id='duration' size='middle' style={{ width: '60px' }} />
          </Form.Item>
          <Form.Item name='durationType' style={{ flexGrow: '1' }}>
            <Select
              id='durationType'
              size='middle'
              placeholder={localization.formatMessage(Locale.Text.Select_duration)}
              options={dateDurationOptions}
            />
          </Form.Item>
          <span style={{ alignSelf: 'center', marginBottom: 24 }}>
            {localization.formatMessage(Locale.General.lowercase_ago)}
          </span>
        </div>
      ),
    },
    {
      key: 'olderThan',
      label: localization.formatMessage(Locale.General.Older_than),
      children: (
        <div style={{ display: 'flex', columnGap: 5 }}>
          <Form.Item label={localization.formatMessage(Locale.General.Duration)} name='duration'>
            <InputNumber id='duration' size='middle' style={{ width: '60px' }} />
          </Form.Item>
          <Form.Item name='durationType' style={{ flexGrow: '1' }}>
            <Select
              id='durationType'
              size='middle'
              placeholder={localization.formatMessage(Locale.Text.Select_duration)}
              options={dateDurationOptions}
            />
          </Form.Item>
          <span style={{ alignSelf: 'center', marginBottom: 24 }}>
            {localization.formatMessage(Locale.General.lowercase_ago)}
          </span>
        </div>
      ),
    },
    {
      key: 'exactDate',
      label: localization.formatMessage(Locale.General.Exact_date),
      children: (
        <Form.Item label={localization.formatMessage(Locale.General.Exact_date)} name='exactDate'>
          <DatePicker
            id='exactDate'
            showTime={false}
            size="middle"
            format={DATE_FORMAT}
            style={{ width: '200px' }}
            placeholder={disabled ? '' : localization.formatMessage(Locale.Command.Select_date)}
            disabled={disabled}
          />
        </Form.Item>
      )
    },
    {
      key: 'range',
      label: localization.formatMessage(Locale.General.Range),
      children: (
        <Form.Item label={localization.formatMessage(Locale.General.Range)} name='range'>
          <RangePicker
            id='range'
            size="middle"
            format={DATE_FORMAT}
            disabled={disabled}
          />
        </Form.Item>
      )
    }
  ];

  useEffect(() => {
    if (!isNil(initialValue)) form.setFieldsValue({ [key]: initialValue });
  }, [form, key, initialValue]);

  useEffect(() => {
    if (formValue) {
      const args = DeserializeDate(formValue);
      if (!args.from?.timestamp) {
        args.from?.relativePoint
          ? setActiveKey('newerThan') : setActiveKey('olderThan');
      } else {
        dayjs(args.from?.timestamp).isSame(args.to?.timestamp, 'day')
          ? setActiveKey('exactDate') : setActiveKey('range');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, formValue]);

  function getInitialFormValues() {
    if (!form.getFieldValue(key)) {
      return {
        duration: 1,
        durationType: 'P%D',
      };
    }
    const formData = DeserializeDate(form.getFieldValue(key));
    if (activeKey === 'newerThan' || activeKey === 'olderThan') {
      const durationData = formData?.to?.relativePoint ?? formData?.from?.relativePoint ?? '';
      const toSetDuration = durationData.match(/\d+/g);
      const toSetDurationType = durationData.replace(/-\d+/g, '%');
      return {
        duration: toSetDuration,
        durationType: toSetDurationType
      };
    }
    if (activeKey === 'exactDate') {
      return {
        exactDate: formData?.to?.timestamp ?? ''
      };
    }
    return {
      range: [formData?.to?.timestamp, formData?.from?.timestamp]
    };
  }

  const onSave = () => {
    const formValues = dateForm.getFieldsValue();
    let toBeSet: TimeSearch = { from: {}, to: {} };

    if (activeKey === 'newerThan') {
      toBeSet.from!.relativePoint = formValues.durationType!.replace('%', (formValues.duration! * -1).toString());
    } else if (activeKey === 'olderThan') {
      toBeSet.to!.relativePoint = formValues.durationType!.replace('%', (formValues.duration! * -1).toString());
    } else if (activeKey === 'exactDate') {
      if (formValues.exactDate) {
        toBeSet = {
          from: {
            timestamp: dayjs(formValues.exactDate).startOf('day')
          },
          to: {
            timestamp: dayjs(formValues.exactDate).endOf('day')
          }
        };
      }
    } else if (activeKey === 'range') {
      if (formValues.range) {
        toBeSet = {
          from: {
            timestamp: formValues.range[0].startOf('day')
          },
          to: {
            timestamp: formValues.range[1].endOf('day')
          }
        };
      }
    }
    form.setFieldsValue({ [key]: SerialiseDate(toBeSet) });
    setSelectedDate(generateDisplayString(activeKey, form.getFieldValue(key)));
    setClicked(false);
  };

  const onClear = () => {
    dateForm.resetFields();
    form.setFieldsValue({ [key]: '' });
    setClicked(false);
  };

  return (
    <>
      <Form.Item hidden style={{ maxWidth: '0px' }} name={key}>
        <Input />
      </Form.Item>
      <Form form={dateForm} initialValues={initialDateFormValue}>
        <Popover
          trigger="click"
          open={clicked && !disabled}
          onOpenChange={setClicked}
          content={
            <>
              <Tabs
                activeKey={activeKey}
                centered
                items={tabOptions}
                onChange={value => setActiveKey(value as TabKey)}
              />
              <Space style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="default" onClick={onClear}>
                  { localization.formatMessage(Locale.Command.Clear)}
                </Button>
                <Button type="primary" onClick={onSave}>
                  {localization.formatMessage(Locale.General.Ok)}
                </Button>
              </Space>
            </>
          }
        >
          <Form.Item style={{ maxWidth: '200px' }} shouldUpdate={() => true}>
            {() => <Input
              id={key}
              size="large"
              value={selectedDate}
              onClick={() => !disabled && setClicked(true)}
              placeholder={localization.formatMessage(Locale.Command.Select_date)}
              disabled={disabled}
            />}
          </Form.Item>
        </Popover>
      </Form>
    </>
  );
};

export default CriteriaDateOptions;
