import React from 'react';
import { gql } from '@apollo/client/core';
import SiteActivityContainer from './SiteActivityContainer';
import SitePersonAffiliationsTable from '../../sitePerson/SitePersonAffiliationsTable';
import SiteTreeHierarchy from '../../../components/TreeView/SiteTreeHierarchy';
import SiteNoteContainer from './SiteNoteContainer';
import { Locale } from '../../../../localization/LocalizationKeys';
import PersonDcrGroupContainer from '../../person/Components/PersonDcrGroupContainer';
import { RelationAffiliationTabConfig } from '../../../components/EntityTabs/RelationTabs/EntityRelationTabs';
import { EntityTypeEnum, PermissionEnum, SiteAffiliationContainerQueryQuery } from '../../../../gql/typings';
import CherryPickTabContainer from '../../../components/CherryPickTabContainer/CherryPickTabContainer';
import SiteSelectionRowsContainer from './SiteSelectionRowsContainer';


export enum SiteAffiliationsTabsEnum {
  personAffiliations = 'personAffiliations',
  hierarchy = 'hierarchy',
  activities = 'activities',
  notes = 'notes',
  dcrs = 'dcrs',
  cherrypicks = 'cherrypicks',
  selections = 'selections',
}

export const siteAffiliationTabConfigs: Record<
SiteAffiliationsTabsEnum, RelationAffiliationTabConfig<SiteAffiliationContainerQueryQuery>> = {
  [SiteAffiliationsTabsEnum.personAffiliations]: {
    title: Locale.Attribute.Person_Affiliations,
    tab: {
      show: true,
      badgeProps: data => ({ count: data?.site?.persons.totalCount ?? 0 }),
    },
    render: siteId => <SitePersonAffiliationsTable siteId={siteId} />,
  },
  [SiteAffiliationsTabsEnum.hierarchy]: {
    title: Locale.General.Hierarchy,
    tab: {
      show: true,
      badgeProps: data => ({ count: data?.site?.childrenHierarchy.totalCount ?? 0 }),
    },
    render: siteId => <SiteTreeHierarchy siteId={siteId} />,
  },
  [SiteAffiliationsTabsEnum.activities]: {
    title: Locale.General.Activities,
    tab: {
      show: data => (data?.site?.activities.totalCount ?? 0) > 0,
      badgeProps: data => ({ count: data?.site?.activities.totalCount ?? 0 }),
    },
    render: siteId => <SiteActivityContainer siteId={siteId} />,
  },
  [SiteAffiliationsTabsEnum.notes]: {
    title: Locale.Attribute.Notes,
    tab: {
      show: data => (data?.site?.notes.totalCount ?? 0) > 0,
      badgeProps: data => ({ count: data?.site?.notes.totalCount ?? 0 }),
    },
    render: siteId => <SiteNoteContainer siteId={siteId} />,
  },
  [SiteAffiliationsTabsEnum.dcrs]: {
    title: Locale.General.DCR,
    tab: {
      title: ({ pluralMessage }, data) => pluralMessage(Locale.General.DCR_Plural, data?.site?.dcrGroups?.totalCount ?? 0),
      show: data => (data?.site?.dcrGroups.totalCount ?? 0) > 0,
      badgeProps: data => ({ count: data?.site?.dcrGroups.totalCount }),
    },
    render: siteId => <PersonDcrGroupContainer record={{ entityType: EntityTypeEnum.SITE, entityAffiliationId: siteId }} />,
  },
  [SiteAffiliationsTabsEnum.cherrypicks]: {
    title: Locale.Attribute.Cherry_pick,
    readPermission: PermissionEnum.CHERRY_PICK_OTHER_INSTANCE,
    tab: {
      title: ({ formatMessage }) => formatMessage(Locale.Attribute.Cherry_picked),
      show: (data) => !!(data?.site?.apurebaseId),
    },
    render: (_, data) => (
      data?.site?.apurebaseId && <CherryPickTabContainer
        entityType={EntityTypeEnum.SITE}
        apurebaseId={data.site.apurebaseId}
        countryCode={data.site.countryCode}
      />
    ),
  },
  [SiteAffiliationsTabsEnum.selections]: {
    title: Locale.Attribute.Selections,
    readPermission: PermissionEnum.SELECTION,
    tab: {
      show: () => true,
      badgeProps: (data) => ({ count: data?.site?.selectionRowsCustomerCount }),
    },
    render: (_, data) => data?.site?.apurebaseId && <SiteSelectionRowsContainer
      siteId={data.site.id}
      apurebaseId={data.site.apurebaseId}
    />
  },
};

export const SITE_AFFILIATION_DATA_QUERY = gql`
  query SiteAffiliationContainerQuery($recordId: Int!) {
    site(siteId: $recordId) {
      id
      name
      apurebaseId
      countryCode
      persons {
        hash
        totalCount
      }
      childrenHierarchy {
        hash
        totalCount
      }
      activities {
        hash
        totalCount
      }
      notes {
        hash
        totalCount
      }
      dcrGroups {
        hash
        totalCount
      }
      selectionRowsCustomerCount(criteria: { selectionType: DYNAMIC })
    }
  }
`;
