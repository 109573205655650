import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import NoteCard from './NoteCard';
import NoteDrawer, { NoteDrawer_FRAGMENT } from '../../../components/Note/NoteDrawer';
import { EntityTypeEnum, PersonNoteDetailQueryQuery, PersonNoteDetailQueryQueryVariables } from '../../../../gql/typings';


const PersonNoteContainer: React.FC<{ personId: number }> = ({
  personId,
}) => {
  const notesState = useState<NonNullable<PersonNoteDetailQueryQuery['person']>['notes']['nodes']['0'] | null>(null);
  const { data } = useQuery<PersonNoteDetailQueryQuery, PersonNoteDetailQueryQueryVariables>(DATA_QUERY, {
    variables: { personId },
  });
  const [displayDrawer, setDisplayDrawer] = useState(false);

  return (
    <>
      {(data?.person?.notes.nodes ?? []).map(note => (
        <span key={note.id}>
          <NoteCard note={note} setNote={notesState[1]} setDisplayDrawer={setDisplayDrawer} />
        </span>
      ))}
      <NoteDrawer
        id={personId}
        entity={EntityTypeEnum.PERSON}
        noteState={notesState}
        displayDrawer={displayDrawer}
        setDisplayDrawer={setDisplayDrawer}
      />
    </>
  );
};

const DATA_QUERY = gql`
  query PersonNoteDetailQuery($personId: Int!) {
    person(id: $personId) {
      id
      notes{
        hash
        totalCount
        nodes{
          id
          ...NoteDrawerFragment
        }
      }
    }
  }
  ${NoteDrawer_FRAGMENT}
`;

export default PersonNoteContainer;

