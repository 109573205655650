import { useApolloClient } from '@apollo/client';
import { find, isBoolean } from 'lodash';
import { EntityTypeEnum } from '../../gql/typings';
import { Optional } from './StateArrayType';

const entityTypeMappings = {
  [EntityTypeEnum.PERSON]: 'Person',
  [EntityTypeEnum.SITE]: 'Site',
  [EntityTypeEnum.AFFILIATION]: 'SitePerson',
};

export const useIsAnyInactive = (entityType: EntityTypeEnum, recordIds: number[]): [boolean] => {
  const apollo = useApolloClient();

  const a = find(recordIds, rId => {
    // @ts-ignore
    const isActive: Optional<boolean> = apollo.cache.data.data[`${entityTypeMappings[entityType]}:${rId}`]?.isActive;
    return isBoolean(isActive) && !isActive;
  });

  return [!!a];
};
