import React from 'react';
import { NavigateFunction } from 'react-router/lib/hooks';

type ExternalNavLink = {
  to: string;
  navigate: NavigateFunction;
  onClick?: () => void;
  openInNewTab?: boolean;
};

/**
 * {ExternalNavLink} is being used when the executor of this doesn't have access to the history.
 * Example inside an antd.message popup.
 */
const ExternalNavLink: React.FC<ExternalNavLink> = ({
  children,
  to,
  navigate,
  onClick,
  openInNewTab,
}) => (
  <span
    className="external-nav-link-container"
    onClick={() => {
      onClick?.();
      if (openInNewTab) window.open(to, '_blank')?.focus();
      else navigate(to);
    }}
  >
    {children}
  </span>
);

export default ExternalNavLink;
