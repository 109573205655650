import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { Button } from 'antd';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import AdminMergeItemPersonActivitiesModal from './AdminMergeItemSiteActivitiesModal';
import { Optional } from '../../../../../../../../util/StateArrayType';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { EntityMergeFragment_Site_Fragment, MergeItemSiteActivitiesFragment } from '../../../../../../../../../gql/typings';


const AdminMergeItemSiteActivities: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemSiteActivitiesFragment;
  const fromSite = props.from as EntityMergeFragment_Site_Fragment;
  const toSite = props.into as Optional<EntityMergeFragment_Site_Fragment>;
  const localization = useLocalization();
  const [open, setOpen] = useState(false);


  return (
    <AdminMergeItemBasicField {...props}>
      {localization.pluralMessage(Locale.Text.Merge_request_activities_move_description, info.activityIds.length, {
        name1: <strong>{fromSite.name}</strong>,
        name2: <strong>{toSite?.name}</strong>,

      })}
      <Button type="link" onClick={() => setOpen(true)}>[{localization.formatMessage(Locale.Command.Show)}]</Button>
      <AdminMergeItemPersonActivitiesModal
        open={open}
        close={() => setOpen(false)}
        activityIds={info.activityIds}
      />
    </AdminMergeItemBasicField>
  );
};

gql`
  fragment MergeItemSiteActivities on SiteActivity_EntityDataToBeTransferredType {
    code
    entityType
    activityIds
  }
`;

export default AdminMergeItemSiteActivities;
