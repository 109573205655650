import { useQuery, gql } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Card, Row, Steps } from 'antd';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import FileStep from './FileStep';
import ChooseTemplate from './ChooseTemplate';
import VisualizeTemplate from './VisualizeTemplate';
import ValidationOfFile from './ValidationOfFile';
import { usePageTitle } from '../../../hooks/usePageTitle';
import MainView from '../../../components/View/MainView';
import { Locale } from '../../../../localization/LocalizationKeys';
import { ImportTemplatesQuery } from '../../../../gql/typings';


const Uploader = () => {
  const navigate = useNavigate();
  const { data, loading } = useQuery<ImportTemplatesQuery>(DATA_QUERY);
  const [templateId, setTemplateId] = useState<number>();
  const [projectId, setProjectId] = useState<number>();
  const [step, setStep] = useState(0);
  const intl = useIntl();
  usePageTitle('Import');

  const upper = (stepId: number) => {
    if (stepId === 0) return (
      <ChooseTemplate
        data={data}
        loading={loading}
        templateId={templateId}
        setTemplateId={setTemplateId}
        done={() => setStep(1)}
      />
    );
    if (stepId === 1) return null;
    if (stepId === 2) return (
      <Row style={{ textAlign: 'center' }}>
        <Button
          type="primary"
          onClick={() => navigate(`/import/${projectId}/process`)}
        >
          {intl.formatMessage(Locale.Command.Continue)}
        </Button>
      </Row>
    );
  };

  const lower = (stepId: number) => {
    if (!data) return <></>;
    if (stepId === 0) return (
      templateId && <Card>
        <VisualizeTemplate template={data.importTemplates.nodes.find(n => n.id === templateId)!} />
      </Card>
    );
    if (!templateId) return <></>;
    if (stepId === 1) return (
      <Card>
        <FileStep
          templateId={templateId}
          setProjectId={setProjectId}
          done={() => setStep(2)}
        />
      </Card>
    );
    if (!projectId) return <></>;
    if (stepId === 2) return (
      <Card>
        <ValidationOfFile projectId={projectId} />
      </Card>
    );
  };


  return (
    <MainView title="Import" className="importer-container">
      <Card>
        <Steps
          current={step}
          items={[
            {
              title: intl.formatMessage(Locale.Command.Select_template)
            },
            {
              title: intl.formatMessage(Locale.Command.File_upload)
            },
            {
              title: intl.formatMessage(Locale.Command.File_preview)
            },
            {
              title: intl.formatMessage(Locale.Command.Start_Processing)
            },
          ]}
        />
        <br />
        {upper(step)}
      </Card>
      <br />
      {lower(step)}
    </MainView>
  );
};

const DATA_QUERY = gql`
  query ImportTemplates {
    importTemplates {
      hash
      totalCount
      nodes {
        id
        name
        note
        processes {
          hash
          nodes {
            id
            heading
            note
            sort
            fields {
              hash
              nodes {
                id
                lineDataIndex
                field {
                  code
                  note
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Uploader;
