import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Space } from 'antd';
import MainView from '../../../components/View/MainView';
import { EntitiesSearch } from '../../../components/entitiesSearch';
import CustomActivityEntitiesSearchTable from './CustomActivityEntitiesSearchTable';
import { spans, spansDefaultIndex } from '../ActivityTable/ActivityTableSpans';
import SliderActions, {
  SliderActionContext,
  SliderActionOptionValue,
  SliderActionsContextType
} from '../../../components/Table/SliderActions/SliderActions';
import { activityTableMapGroup } from '../ActivityTable/ActivityTableUtils';
import { usePageTitle } from '../../../hooks/usePageTitle';
import useCreateListActions from '../../list/CreateList/useCreateListActions';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useTableRowSelection } from '../../../components/Table/useTableRowSelection';
import EntityTableRightActions from '../../../components/entitiesSearch/results/EntityTableRightActions';
import { EntityTypeEnum } from '../../../../gql/typings';

const ActivityView: React.FC = () => {
  const [form] = Form.useForm();
  const intl = useIntl();
  usePageTitle(intl.formatMessage(Locale.General.Activities));
  const [span, setSpan] = useState<SliderActionOptionValue>(spans[spansDefaultIndex]!);
  const tableSelection = useTableRowSelection({ form, maintainPageState: { entityType: EntityTypeEnum.ACTIVITY } });

  const [createListAction, CreateListElement] = useCreateListActions({
    entityType: EntityTypeEnum.ACTIVITY,
    selection: tableSelection.selection });

  const sliderMemo = useMemo(() => ({
    label: intl.formatMessage(Locale.General.Grouping),
    selected: span,
    setSelected: setSpan,
    options: spans,
    mapGroup: activityTableMapGroup(span),
  }) as SliderActionsContextType, [intl, span]);

  const buttonActions = (
    <Space>
      <SliderActions />
    </Space>
  );

  return (
    <MainView
      className="activity-view-container2"
      boundingBox={false}
    >
      <SliderActionContext.Provider value={sliderMemo}>
        <EntitiesSearch
          tableSelection={tableSelection}
          entityType={EntityTypeEnum.ACTIVITY}
          form={form}
          urlSearchEnabled
        >
          <CustomActivityEntitiesSearchTable
            tableProps={tableSelection.tableProps}
            act={<div>
              {buttonActions}
              &nbsp;&nbsp;
              <EntityTableRightActions entityType={EntityTypeEnum.ACTIVITY} actionsToTake={[createListAction]} />
            </div>}
          />
        </EntitiesSearch>
      </SliderActionContext.Provider>
      <CreateListElement />
    </MainView>
  );
};

export default ActivityView;
