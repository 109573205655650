import React from 'react';
import MainView from '../../../../components/View/MainView';
import { EntitiesSearch } from '../../../../components/entitiesSearch';
import { EntityTypeEnum } from '../../../../../gql/typings';


const AdminEntityMergeRequestView: React.FC = () => (
  <MainView className="admin-entity-merge-request-view-container" boundingBox={false}>
    <EntitiesSearch
      entityType={EntityTypeEnum.ENTITY_MERGE}
      includeInactiveRecords={false}
    />
  </MainView>
);

export default AdminEntityMergeRequestView;
