import React from 'react';
import { gql } from '@apollo/client';
import { Tooltip, Typography } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  SelectSiteSpecialtiesSuggestionQueryQuery,
  SelectSiteSpecialtiesSuggestionQueryQueryVariables,
  UpdateSiteSpecialtiesMutationMutationVariables
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';

export const siteSpecialtiesFieldConfig = siteFieldConfig(
  'specialties',
  [
    'id',
    'countryCode',
    'siteSpecialties.hash',
    'siteSpecialties.nodes.code',
    'siteSpecialties.nodes.type',
    'siteSpecialties.nodes.label',
    'siteSpecialties.nodes.displayLabel',
    'controlSetting.id',
    'controlSetting.createDcr',
    'controlSetting.maintainerSourceCode',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Site_specialty),
    dcrInfo: record => ({
      entityType: EntityTypeEnum.PERSON,
      entityAffiliationId: record.id,
      field: FieldEnum.HCO_SPECIALTY
    }),
    additionalTableConfig: {
      width: 150,
      ellipsis: {
        showTitle: false
      }
    },
    render: (site, { isViewingFromPage }) => {
      const siteSpField = site.siteSpecialties.nodes.map(s => s.label).join(', ');
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={siteSpField}>{siteSpField}</Tooltip>
      </Typography.Paragraph>);
    },
    preserve: true,
    updateView: buildSelectUpdateFieldConfig({
      mode: 'multiple',
      mutation: UPDATE_SITE_SPECIALTIES_MUTATION,
      selectedKeys: record => record.siteSpecialties.nodes.map(n => n.code),
      modalHeading: localization.formatMessage(Locale.Command.Select_Site_Specialty),
      buildVariables: (selectedKeys, record) => ({
        siteId: record.id,
        specialties: selectedKeys,
      }) as UpdateSiteSpecialtiesMutationMutationVariables,
      fetchSuggestions: ({ client, record }) => (
        client.query<SelectSiteSpecialtiesSuggestionQueryQuery, SelectSiteSpecialtiesSuggestionQueryQueryVariables>({
          query: DATA_QUERY,
          variables: { countries: [record.countryCode] },
        }).then(res => res.data.availableSpecialties.nodes)
      )
    }),
  })
);

const UPDATE_SITE_SPECIALTIES_MUTATION = gql`
  mutation UpdateSiteSpecialtiesMutation($siteId: Int!, $specialties: [String!]!) {
    updateSiteSpecialties(siteId: $siteId, specialties: $specialties) {
      id {
        siteId
        siteSpecialtyCode
      }
    }
  }
`;

const DATA_QUERY = gql`
  query SelectSiteSpecialtiesSuggestionQuery($countries: [CountryCode!]!) {
    availableSpecialties: siteSpecialties(criteria: { countries: $countries, fetchSize: { limit: 5000 } }) {
      hash
      nodes {
        code
        label: displayLabel
        type
      }
    }
  }
`;
