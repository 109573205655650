import React from 'react';
import SiteCherryPickTableButton from '../components/SiteCherryPickTableButton/SiteCherryPickTableButton';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';

export const siteCherryPickFieldConfig = siteFieldConfig(
  'cherryPick',
  [
    'isLocal',
    'name',
    'apurebaseId',
    'countryCode',
    'visitAddress.id',
    'visitAddress.street',
    'visitAddress.postalCode',
  ],
  ({ localization }) => ({
    additionalTableConfig: {
      width: 100
    },
    render: (record) => <SiteCherryPickTableButton record={record} />,
  })
);
