import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Select } from 'antd';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import {
  EntityMergeFragment_Person_Fragment,
  MergeItemPersonTypeFragment, MRPersonTypesQuery, MRPersonTypesQueryVariables,
  useUpdatePersonTypeMutation
} from '../../../../../../../../../gql/typings';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';

const AdminMergeItemPersonType: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonTypeFragment;
  const fromPerson = props.from as EntityMergeFragment_Person_Fragment;
  const localization = useLocalization();
  const { data, loading } = useQuery<MRPersonTypesQuery, MRPersonTypesQueryVariables>(DATA_QUERY, {
    variables: { countryCode: fromPerson.countryCode },
  });
  const [updateType] = useUpdatePersonTypeMutation();

  if (!props.item.hasConflict) return (
    <AdminMergeItemBasicField {...props}>
      {localization.formatMessage(Locale.Text.Merge_request_person_types_description, {
        type: <strong>{info.winnerType?.displayLabel}</strong>
      })}
    </AdminMergeItemBasicField>
  );

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.loserType?.code}
      toValue={info.winnerType?.code}
      updateMutation={newCode => updateType({ variables: { personId: props.into.id, newCode } })}
      valueDisplayMapper={code => data?.personTypes.nodes.filter(pt => pt.code === code)[0]?.displayLabel}
      inputRenderer={props => (
        <Select
          {...props}
          loading={loading}
          options={(data?.personTypes.nodes ?? []).map(pt => ({ value: pt.code, label: pt.displayLabel }))}
        />
      )}
    >
      {localization.formatMessage(Locale.Text.Merge_request_person_type_mismatch_description, {
        type1: <strong>{info.loserType?.displayLabel}</strong>,
        type2: <strong>{info.winnerType?.displayLabel}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

const DATA_QUERY = gql`
  query MRPersonTypes($countryCode: CountryCode!) {
    personTypes(criteria: { countries: [$countryCode], fetchSize: { limit: 10000 }}) {
      hash
      nodes {
        code
        type
        displayLabel
      }
    }
  }
`;

gql`
  mutation UpdatePersonType($personId: Int!, $newCode: String) {
    updatePersonType(persId: $personId, typeCode: $newCode) {
      id
      persTypeCode
      persType {
        code
        type
      }
    }
  }
`;

gql`
  fragment MergeItemPersonType on PersonType_EntityDataToBeTransferredType {
    code
    loserType {
      code
      type
      displayLabel
    }
    winnerType {
      code
      type
      displayLabel
    }
  }
`;

export default AdminMergeItemPersonType;
