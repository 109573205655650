import React, { useRef, forwardRef, useImperativeHandle, useMemo } from 'react';
import { Form, FormInstance, Tabs } from 'antd';
import { AddWorkplaceInput, EntityTypeEnum } from '../../../../../gql/typings';
import { Optional, RecursivePartial, StateArray } from '../../../../util/StateArrayType';
import { useLocalization } from '../../../../util/useLocalization';
import { EntitiesSearch, EntitiesSearchRef } from '../../../../components/entitiesSearch';
import { Locale } from '../../../../../localization/LocalizationKeys';
import CreateSite, { CreateSiteRef } from '../../../site/create/CreateSite';
import { TableRowSelectionReturnProps } from '../../../../components/Table/useTableRowSelection';

type CreatePersonAddWorkspaceSiteStepProps = {
  form: FormInstance<AddWorkplaceInput>;
  countryCode: Optional<string>;
  initialState: Optional<RecursivePartial<AddWorkplaceInput>>;
  currentTabState: StateArray<'new'|'existing'>;
  currentStepState: StateArray<number>;
  siteTableSelection: TableRowSelectionReturnProps;
  next: () => void;
};

export type CreatePersonAddWorkspaceSiteStepRef = {
  clear: () => void;
};

const CreatePersonAddWorkspaceSiteStep = forwardRef<
CreatePersonAddWorkspaceSiteStepRef,
CreatePersonAddWorkspaceSiteStepProps>(({
  form,
  countryCode,
  initialState,
  currentTabState: [currentTab, setCurrentTab],
  currentStepState: [, setCurrentStep],
  siteTableSelection,
  next,
}, ref) => {
  const localization = useLocalization();
  const createSiteRef = useRef<CreateSiteRef>(null);
  const entitiesSearchRef = useRef<EntitiesSearchRef>(null);

  const onCreateEntityTab = () => {
    setCurrentTab('new');
  };

  const initialValues = useMemo(() => {
    if (entitiesSearchRef.current && Object.keys(entitiesSearchRef.current.formValues).length > 0) {
      return entitiesSearchRef.current.formValues;
    }
    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitiesSearchRef.current?.formValues]);


  useImperativeHandle(ref, () => ({
    clear: () => {
      createSiteRef.current?.clear();
      entitiesSearchRef.current?.clearCriterias();
      entitiesSearchRef.current?.refetch();
    },
  }) as CreatePersonAddWorkspaceSiteStepRef, []);

  return (
    <>
      <Tabs
        activeKey={currentTab}
        onChange={key => setCurrentTab(key as 'new' | 'existing')}
        className="add-workplace-tabs"
        items={[
          {
            key: 'existing',
            label: <span id="add-workplace-modal-select-existing">
              {localization.formatMessage(Locale.Command.Select_existing)}
            </span>,
            children: (
              <EntitiesSearch
                ref={entitiesSearchRef}
                entityType={EntityTypeEnum.SITE}
                onCreateEntityTab={onCreateEntityTab}
                tableSelection={siteTableSelection}
                quickActions={[{
                  key: 'select',
                  disabled: siteTableSelection.selection.count === 0,
                  label: 'Select',
                  className: 'select-site-quick-action',
                  onClick: () => next(),
                }]}
              />
            ),
          },
          {
            key: 'new',
            label: <span id="add-workplace-modal-create-new">
              {localization.formatMessage(Locale.Attribute.Create_new)}
            </span>,
            children: (
              <div>
                <Form.Provider
                  onFormFinish={(name, { values }) => {
                    if (name === 'site') {
                      form.setFields([
                        { name: 'siteInput', value: values },
                        { name: 'siteId', value: null },
                      ]);
                      setCurrentStep(1);
                    }
                  }}
                >
                  <CreateSite
                    ref={createSiteRef}
                    allowChooseMaintainer={false}
                    initialState={{
                      ...(initialState?.siteInput ?? {}),
                      country: countryCode,
                      ...initialValues
                    }}
                  />
                </Form.Provider>
              </div>
            )
          }
        ]}
      />
    </>
  );
});

export default CreatePersonAddWorkspaceSiteStep;
