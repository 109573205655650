import React from 'react';
import { Col, Select } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  ActivityTypeMutationFragmentFragment,
  ConfigureCustomFieldsActivityTypeQuery,
  ConfigureCustomFieldsActivityTypeQueryVariables,
  SetCustomFieldsToActivityTypeMutation,
  SetCustomFieldsToActivityTypeMutationVariables
} from '../../../../../gql/typings';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { CONFIGURE_ACTIVITY_TYPE_FRAGMENT } from './ConfigureActivityTypesFragments';

type ConfigureCustomFieldsActivityTypeProps = {
  activityType: ActivityTypeMutationFragmentFragment;
};
const ConfigureCustomFieldsActivityType: React.FC<ConfigureCustomFieldsActivityTypeProps> = ({ activityType }) => {
  const localization = useLocalization();
  const {
    loading,
    data,
  } = useQuery<ConfigureCustomFieldsActivityTypeQuery, ConfigureCustomFieldsActivityTypeQueryVariables>(DATA_QUERY);

  const [
    setCustomFields,
    { loading: isUpdating },
  ] = useMutation<SetCustomFieldsToActivityTypeMutation, SetCustomFieldsToActivityTypeMutationVariables>(SET_FIELDS);


  return (
    <>
      <Col span={8}>{localization.formatMessage(Locale.Attribute.Own_fields)}:</Col>
      <Col span={16}>
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          loading={loading || isUpdating}
          value={activityType.customFields.nodes.map(cf => cf.code)}
          options={data?.customFields.nodes.map(cf => ({
            value: cf.code,
            label: cf.heading,
          }))}
          onChange={customFieldCodes => setCustomFields({
            variables: {
              customFieldCodes,
              activityTypeCode: activityType.code,
            }
          })}
        />
      </Col>
    </>
  );
};

const DATA_QUERY = gql`
  query ConfigureCustomFieldsActivityType($search: String) {
    customFields(criteria: { heading: $search, entityType: ACTIVITY }) {
      hash
      nodes {
        code
        heading
      }
    }
  }
`;

const SET_FIELDS = gql`
  mutation SetCustomFieldsToActivityType($activityTypeCode: String!, $customFieldCodes: [String!]!) {
    setCustomFieldsToActivityType(activityTypeCode: $activityTypeCode, customFieldCodes: $customFieldCodes) {
      code
      ...ActivityTypeMutationFragment
    }
  }
  ${CONFIGURE_ACTIVITY_TYPE_FRAGMENT}
`;

export default ConfigureCustomFieldsActivityType;
