import React from 'react';
import * as PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Table } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';

const deliveryTable = ({ links, loading }) => {
  const { formatMessage } = useIntl();

  const columns = [
    { title: formatMessage(Locale.General.Activity), dataIndex: ['activity', 'title'], key: 'activity.title' },
    { title: formatMessage(Locale.Attribute.Site), dataIndex: ['activity', 'site', 'name'], key: 'activity.site.name' },
    { title: formatMessage(Locale.Attribute.Person_HCP), dataIndex: ['person', 'fullname'], key: 'person.fullname' },

  ];
  return <Table columns={columns} dataSource={links} size="small" bordered loading={loading} />;
};

deliveryTable.propTypes = {
  links: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};


export default deliveryTable;
