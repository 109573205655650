import React from 'react';
import { useIntl } from 'react-intl';
import { Dropdown } from 'antd';
import { actionToTakeMenuItems, TableActionItem } from '../Table/Actions';
import ApbButton from '../Button/ApbButton';
import Icon from '../Icon/Icon';
import { Locale } from '../../../localization/LocalizationKeys';

type ActionsToTakeDropdownProps = {
  loading?: boolean;
  actions?: Array<TableActionItem | null>;
};

const ActionsToTakeDropdown: React.FC<ActionsToTakeDropdownProps> = ({ actions, loading }) => {
  const { formatMessage } = useIntl();
  if (!actions) return <></>;

  const items = actionToTakeMenuItems(actions);


  return (
    <Dropdown
      menu={{ items }}
      placement="bottomRight"
      trigger={['click']}
    >
      <span onClick={e => e.preventDefault()}>
        <ApbButton
          icon={<Icon iconType="DOWN_ARROW" />}
          loading={loading}
          text={
            formatMessage(Locale.Text.Actions_to_take)
          }
          buttonType="action"
        />
      </span>
    </Dropdown>
  );
};

export default ActionsToTakeDropdown;
