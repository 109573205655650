import { gql } from '@apollo/client';
import { supportedPersonTableColumns } from './supportedPersonTableColumns';
import { TableConfig } from '../search_old/types';
import { Locale } from '../../../localization/LocalizationKeys';
import { stringToAbsHash } from '../../apollo/util';

export const buildPersonTableFragment = (additionalQuery: string|null) => gql`
  fragment PersonTableBuiltFragment${stringToAbsHash(additionalQuery)} on Person {
    id
    countryCode
    isActive
    consentInstructions {
      hash
      optedOutFromAPB
    }
    isLocal
    ${additionalQuery ?? ''}
  }
`;

export const buildPersonConnectionTableDataQuery = (additionalQuery: string|null, hasGlobalSearchPermission = true) => gql`
  query PersonsBuiltQuery${stringToAbsHash(additionalQuery)}($criteria: PersonCriteria) {
    connection: persons(criteria: $criteria) {
      hash
      totalCount
      ${hasGlobalSearchPermission ? 'globalAdditionalCount' : ''}
      nodes {
        id
        countryCode
        fullName
        isLocal
        ...PersonTableBuiltFragment${stringToAbsHash(additionalQuery)}
      }
    }
  }
  ${buildPersonTableFragment(additionalQuery)}
`;

export const buildPersonRecordDataQuery = (additionalQuery: string|null) => gql`
  query PersonsRecordBuiltQuery${stringToAbsHash(additionalQuery)}($recordId: Int!) {
    record: person(id: $recordId) {
      id
      countryCode
      fullName
      isLocal
      ...PersonTableBuiltFragment${stringToAbsHash(additionalQuery)}
    }
  }
  ${buildPersonTableFragment(additionalQuery)}
`;


export const PersonTableConfig: TableConfig = {
  buildConnectionQuery: buildPersonConnectionTableDataQuery,
  buildRecordQuery: buildPersonRecordDataQuery,
  buildFragment: buildPersonTableFragment,
  titleLabel: Locale.General.Persons_HCP,
  columnConfig: {
    fields: supportedPersonTableColumns,
  }
};

