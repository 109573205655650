import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Badge, Card, Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import UpdateCustomField from '../../customFields/UpdateCustomField';
import GlowButton from '../../../../components/Input/GlowButton';
import CreateCustomField from '../../customFields/CreateCustomField';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import {
  AdminEntityCustomFieldsQueryQuery,
  AdminEntityCustomFieldsQueryQueryVariables,
  EntityTypeEnum
} from '../../../../../gql/typings';

type AdminEntityCustomFieldsProps = {
  entityType: EntityTypeEnum;
};

const AdminEntityCustomFields: React.FC<AdminEntityCustomFieldsProps> = ({ entityType }) => {
  const localization = useLocalization();
  const [selectedFieldCode, setSelectedFieldCode] = useState<string>();
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const {
    data,
    refetch,
  } = useQuery<AdminEntityCustomFieldsQueryQuery, AdminEntityCustomFieldsQueryQueryVariables>(DATA_QUERY, {
    variables: { entityType },
  });

  return (
    <div className="admin-entity-custom-fields-container">
      <Card>
        {data?.customFields.nodes.map(field => (
          <Card.Grid className="grid-item" key={field.code} onClick={() => setSelectedFieldCode(field.code)}>
            <strong>{field.heading}</strong>
            {!field.isActive && <>&nbsp;&nbsp;<WarningOutlined style={{ color: 'red' }} /></>}
            &nbsp;
            <Badge
              showZero
              className="primary-badge"
              count={field.fieldGridConfigurations.totalCount}
            />
          </Card.Grid>
        ))}
        <Card.Grid className="grid-item flex" onClick={() => setIsCreatingNew(true)}>
          {localization.formatMessage(Locale.Attribute.Create_new)}
          &nbsp;
          <GlowButton type="primary" size="x-small" />
        </Card.Grid>
      </Card>
      <Modal
        open={!!selectedFieldCode || isCreatingNew}
        onCancel={() => {
          setSelectedFieldCode(undefined);
          setIsCreatingNew(false);
        }}
        footer={false}
      >
        {selectedFieldCode && <UpdateCustomField customFieldCode={selectedFieldCode} />}
        {isCreatingNew && <CreateCustomField
          entityType={entityType}
          onCreated={(field) => {
            setSelectedFieldCode(field.code);
            setIsCreatingNew(false);
            return refetch();
          }}
        />}
      </Modal>
    </div>
  );
};

const DATA_QUERY = gql`
  query AdminEntityCustomFieldsQuery($entityType: EntityTypeEnum!) {
    customFields(criteria: { entityType: $entityType, isActive: null }) {
      hash
      nodes {
        code
        heading
        isActive
        fieldTypeEnum
        fieldGridConfigurations {
          hash
          totalCount
        }
      }
    }
  }
`;

export default AdminEntityCustomFields;
