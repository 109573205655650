import { useQuery } from '@apollo/client';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { message, Form, Card, Col, Row, Button, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { range, keys, toNumber } from 'lodash';
import { useIntl } from 'react-intl';
import ListDynamicRules from './ListDynamicRules';
import { Locale } from '../../../../localization/LocalizationKeys';
import { DynamicRuleReturnMap, dynamicRulesMap } from '../components/DynamicRules';
import { AnyValueType } from '../../../util/StateArrayType';
import {
  CriteriaInputFactory,
  criteriaInputKeyGenerator,
} from '../../../components/CriteriaInput/CriteriaInputFactory';
import { SupportedEntitySearchTypes } from '../../search_old/types';
import { DYNAMIC_RULES_DATA_QUERY } from './ListDetailsQueries';
import { DynamicRulesQueryQuery, DynamicRulesQueryQueryVariables } from '../../../../gql/typings';

const spans = { sm: 24, md: 12, lg: 8, xl: 6 };

type DynamicRulesProps = {
  listId: number;
  entityTypeEnum: SupportedEntitySearchTypes;
  totalCount?: number;
  onUpdate?: () => void;
};

const DynamicRules: React.FC<DynamicRulesProps> = ({
  listId,
  entityTypeEnum,
  onUpdate,
  totalCount = 0,
}) => {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const { data, loading } = useQuery<DynamicRulesQueryQuery, DynamicRulesQueryQueryVariables>(DYNAMIC_RULES_DATA_QUERY, {
    variables: { listId, entityTypeEnum },
  });
  const [editMode] = useState(false);
  const editDynamicRulesState = useState(false);
  const criteriasState = useState<DynamicRuleReturnMap[]>([]);
  const [criterias, setCriterias] = criteriasState;
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (!loading) {
      const multiObjects = data?.list?.criterias?.nodes.map(dynamicRulesMap) ?? [];
      const test: Record<string, AnyValueType> = {};
      multiObjects.map(mo => keys(mo.options.initialValue).map(pathId => {
        test[criteriaInputKeyGenerator(mo.criteria, { id: toNumber(pathId) })] = mo.options.initialValue[toNumber(pathId)];
      }));
      form.setFieldsValue(test);
      setCriterias(multiObjects);
    }
  }, [data, loading]);

  const onRemove = (node: DynamicRuleReturnMap) => {
    const before = criterias;
    setCriterias(criterias.filter(v => v.criteria.id !== node.criteria.id));
    messageApi.info(<span>
      Removed rule <b>{node.criteria.heading}</b>.
      <Button type="link" onClick={() => setCriterias(before)}>{formatMessage(Locale.Command.Undo)}</Button>
    </span>);
  };

  const renderLoading = range(0, totalCount).map(i => <Col key={i} {...spans}>
    <Card loading />
  </Col>);

  const renderRules = criterias.map(node => (
    <Col key={node.criteria.id} {...spans}>
      <Card
        title={formatMessage({
          id: node.criteria.heading,
          defaultMessage: node.criteria.heading,
        })}
        extra={editMode && (
          <Tooltip title={formatMessage(Locale.Text.Remove_rule)}>
            <Button type="link" danger onClick={() => onRemove(node)}><DeleteOutlined /></Button>
          </Tooltip>
        )}
      >
        <CriteriaInputFactory
          criteria={node.criteria}
          form={form}
          options={{
            disabled: !editMode,
            hideTitle: true,
            hasMaxWidth: false,
          }}
          initialValueOptionsLoad
        />
      </Card>
    </Col>
  ));

  return (
    <Form form={form}>
      {contextHolder}
      <Row style={{ marginTop: 20 }}>
        <h3 style={{ marginRight: 20 }}>Dynamic rules</h3>
        <EditOutlined id="dynamic-rules-edit-icon" onClick={() => editDynamicRulesState[1](true)} />
      </Row>
      <Row gutter={20} className={`dynamic-rules ${loading ? 'loading' : 'rules-loaded'}`}>
        {loading ? renderLoading : renderRules}
      </Row>
      <ListDynamicRules
        listId={listId}
        entityType={entityTypeEnum}
        visibleState={editDynamicRulesState}
        onUpdate={onUpdate}
      />
    </Form>
  );
};


export default DynamicRules;
