import React, { useState } from 'react';
import { gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Input, Modal, Space, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { TableFieldUpdateViewProps } from '../../../search_old/types';
import { usePersonFieldMutation } from '../../../person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import {
  CustomFieldValueFragmentFragment,
  EntityCustomFieldTagOptionsQueryQuery,
  EntityCustomFieldTagOptionsQueryQueryVariables,
  RemoveCustomFieldInputValueMutation,
  RemoveCustomFieldInputValueMutationVariables,
} from '../../../../../gql/typings';
import { getVariableField } from '../../../person/fields/utils/personFieldsUtils';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import Loading from '../../../../components/Loading/Loading';
import { CUSTOM_REMOVE_MUTATION, CUSTOM_UPSERT_MUTATION } from './customFieldUtils';

const CustomTagInput:React.FC<TableFieldUpdateViewProps> = (props) => {
  const values = getVariableField(props!.record, props!.options, 'customFields') as CustomFieldValueFragmentFragment;
  const localization = useLocalization();
  const [visible, setVisible] = useState(true);
  const [editable] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);

  const { data, loading } = useQuery<EntityCustomFieldTagOptionsQueryQuery
  , EntityCustomFieldTagOptionsQueryQueryVariables>(TAG_QUERY_OPTIONS, {
    variables: {
      code: props.options.selectedOption!.code as string
    },
    fetchPolicy: 'network-only'
  });

  const { submit, blocking } = usePersonFieldMutation({
    mutation: CUSTOM_UPSERT_MUTATION,
    controlSetting: props.record?.controlSetting,
    skipDcrWarning: true,
  });

  const [removeCustomFieldValue] = useMutation<RemoveCustomFieldInputValueMutation,
  RemoveCustomFieldInputValueMutationVariables>(CUSTOM_REMOVE_MUTATION);


  const selectedValue = !!values.nodes[0];

  const close = () => {
    if (!blocking) {
      setVisible(false);
      props.endEditing();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCreate = (value: any) => {
    if (value.value.length > 0) {
      const existingTags = values.nodes.length > 0 ? values.nodes.map(val => ({ valueId: val.value!.id })) : [];
      submit({
        recordId: props.record.id,
        customFieldCode: props.options.selectedOption?.code,
        customValues: [...existingTags, { newValue: value.value }],
      }).then(() => {
        setInputVisible(false);
        props.options.refetchData();
      });
    } else {
      setInputVisible(false);
    }
  };

  const onClose = (customOptionId: number) => {
    const toRemoveLinkId = values?.nodes?.filter(v => v.value?.id == customOptionId)[0]!.id;
    removeCustomFieldValue({
      variables: {
        customValues: [toRemoveLinkId],
      }
    }).then(() => props.options.refetchData());
  };

  if (loading) return <Loading spinSize='small' />;

  return (
    <Modal
      title={props?.options.selectedOption?.label}
      open={visible}
      onCancel={close}
      closable={false}
      footer={false}
      width="clamp(320px, 75%, 480px)"
      wrapClassName='custom-field-modal-container'
    >
      {selectedValue ? values.nodes
        .map(it => <Tag
          color={data?.customField!.color || 'default'}
          closable
          onClose={() => onClose(it.value!.id)}
          key={it.value?.id}
          style={{ marginBottom: 5 }}
        >
          {it.value?.value}
        </Tag>) : <></>}
      {
        !editable && <>
          {inputVisible && (
            <Input
              type="text"
              size="small"
              onPressEnter={e => onCreate(e.target)}
              onBlur={e => onCreate(e.target)}
              style={{ width: 78 }}
            />
          )}
          {!inputVisible && (
            <Tag className="site-tag-plus" color={data?.customField!.color || 'default'} onClick={() => setInputVisible(true)}>
              <PlusOutlined /> New Tag
            </Tag>
          )}
        </>
      }
      <br />
      <Space style={{ width: '100%', justifyContent: 'center', marginTop: 20 }}>
        {
          blocking ? <Loading spinSize='small' />
            : <Button key="2" type="primary" className="save-button" onClick={close}>
              {localization.formatMessage(Locale.General.Ok)}
            </Button>
        }
      </Space>
    </Modal>
  );
};

const TAG_QUERY_OPTIONS = gql`
  query EntityCustomFieldTagOptionsQuery($code: String!) {
    customField(code: $code) {
      color
      options {
        hash
        nodes {
          id
          value {
            id
            value
          }
        }
      }
    }
  }
`;
export default CustomTagInput;
