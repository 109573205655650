import React from 'react';
import { CustomFieldProps } from './CustomFieldUtils';
import CustomFieldSelect from './CustomFieldSelect';
import CustomFieldMultiSelect from './CustomFieldMultiSelect';


const CustomFieldSelectMultiSupport: React.FC<CustomFieldProps> = ({ recordId, field, values, refreshData }) => (
  <span>
    {field.allowMultiple
      ? <CustomFieldMultiSelect recordId={recordId} field={field} values={values} refreshData={refreshData} />
      : <CustomFieldSelect recordId={recordId} field={field} values={values} refreshData={refreshData} />}
  </span>
);


export default CustomFieldSelectMultiSupport;
