import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { CheckOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { useSaveProcessResult } from '../utils';
import {
  LinkUserWithExternalId_MUTATION,
  UsersForUserMapping_QUERY
} from '../../../queries/manualExternalUserMappingAction';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { ImportManualAcctionProps } from '../ImportManualProcessesTypes';
import {
  CurrentImportProjectStateQuery, EntityTypeEnum,
  LinkUserWithExternalIdMutation,
  LinkUserWithExternalIdMutationVariables, ProcessLineEffectActionEnum,
  UsersForUserMappingQuery
} from '../../../../../../gql/typings';


const columns = (intl: IntlShape) => [
  {
    title: intl.formatMessage(Locale.Attribute.ID),
    dataIndex: 'id',
  }, {
    title: intl.formatMessage(Locale.Attribute.First_name),
    dataIndex: 'firstname',
  }, {
    title: intl.formatMessage(Locale.Attribute.Last_name),
    dataIndex: 'lastname',
  }, {
    title: intl.formatMessage(Locale.Attribute.Email),
    dataIndex: 'email',
  },
];

const UserMapping: React.FC<ImportManualAcctionProps> = ({
  importProject: project,
  continueAutomatic,
}) => {
  const { saveProcessResult } = useSaveProcessResult();
  const { data, loading: usersLoading } = useQuery<UsersForUserMappingQuery>(UsersForUserMapping_QUERY);
  const [
    userMappingMutation,
  ] = useMutation<LinkUserWithExternalIdMutation, LinkUserWithExternalIdMutationVariables>(LinkUserWithExternalId_MUTATION);
  const [selected, setSelected] = useState<number>();
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const manual = project?.manual as Extract<NonNullable<
  CurrentImportProjectStateQuery['importProject']>['manual'], { ['__typename']: 'ManualExternalUserIdMapping' }> | null;

  useEffect(() => {
    setLoading(false);
  }, [project]);

  const onSave = () => {
    setLoading(true);
    if (!selected) return;
    setSelected(undefined);
    userMappingMutation({
      variables: {
        input: {
          externalId: manual?.externalIds[0] ?? '-1',
          internalId: selected,
          externalIdTypeCode: manual?.externalIdTypeCode,
        },
      },
    })
      .then(() => saveProcessResult({
        action: ProcessLineEffectActionEnum.UPDATE,
        entityType: EntityTypeEnum.USER,
        entityAffiliationId: selected,
        note: intl.formatMessage(Locale.Text.Linked_externalId_to_user, { value: manual?.externalIds[0] }),
      }))
      .finally(() => {
        setLoading(false);
        if (manual?.externalIds.length === 1) continueAutomatic();
      });
  };

  return (
    <div className="user-mapping-container">
      <h2>
        Mapping External UserId: "
        <b>{manual?.externalIds[0]}</b>
        "
      </h2>
      <div className="select-user-table">
        <Table
          columns={columns(intl)}
          dataSource={data?.usersConnection.nodes ?? []}
          loading={usersLoading}
          pagination={false}
          rowKey={r => r.id}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: selected ? [selected] : [],
            onSelect: row => setSelected(row.id),
          }}
        />
      </div>
      <br />
      <div className="centered-item">
        <Button
          icon={<CheckOutlined />}
          className="action-button"
          disabled={!selected}
          loading={loading}
          onClick={onSave}
        >
          {intl.formatMessage(Locale.Command.Save_Match)}
        </Button>
      </div>
    </div>
  );
};

export default UserMapping;
