import { useApolloClient } from '@apollo/client';
import { useMap } from 'ahooks';
import { useEffect, useMemo } from 'react';
import { uniqBy } from 'lodash';
import { entitiesTableConfigs, SupportedEntitySearchTypes, TableFieldsReturnedOptionType } from '../../browse/search_old/types';
import { FieldTransformerKeys, useFieldKeysTransformed } from '../../util/useFieldKeysTransformed';
import { EntitySearchColumnParamType } from '../../browse/search_old/types/EntitySearchColumnParamType';

export function useFieldOptionAttributes(
  entityType: SupportedEntitySearchTypes,
  fieldsRaw?: FieldTransformerKeys|undefined,
  additionalProps?: EntitySearchColumnParamType,
) {
  const fields = useMemo(
    () => fieldsRaw ?? Object.keys(entitiesTableConfigs[entityType].columnConfig.fields),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fieldsRaw?.join()],
  );
  const apolloClient = useApolloClient();
  const items = useFieldKeysTransformed(entityType, fields, additionalProps);
  const [rawMap, { set: optionMapSet, get: optionMapGet }] = useMap<string, TableFieldsReturnedOptionType[]>();

  useEffect(() => {
    uniqBy(items.filter(([item]) => item.getOptions), ([item]) => item.key).map(([item]) => {
      if (!item.getOptions) return null;
      item.getOptions[1]({ apolloClient }).then(result => {
        optionMapSet(item.key, result);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return useMemo(() => ({
    items,
    optionMapGet,
    rawMap,
  }), [items, optionMapGet, rawMap]);
}
