import React, { useState } from 'react';
import { gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import { Button, Col, Modal, Radio, Row, Space } from 'antd';
import { TableFieldUpdateViewProps } from '../../../search_old/types';
import { getVariableField } from '../../../person/fields/utils/personFieldsUtils';
import { usePersonFieldMutation } from '../../../person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import Loading from '../../../../components/Loading/Loading';
import CustomFieldCheckableTagInput from './CustomFieldCheckableTagInput';
import { CUSTOM_REMOVE_MUTATION, CUSTOM_UPSERT_MUTATION } from './customFieldUtils';
import {
  CustomFieldFragmentFragment,
  CustomFieldValueFragmentFragment,
  EntityRadioCustomFieldCheckableOptionsQueryQuery,
  EntityRadioCustomFieldCheckableOptionsQueryQueryVariables,
  RemoveCustomFieldInputValueMutation,
  RemoveCustomFieldInputValueMutationVariables
} from '../../../../../gql/typings';

const CustomRadioInput: React.FC<TableFieldUpdateViewProps> = (props) => {
  const values = getVariableField(props!.record, props!.options, 'customFields') as CustomFieldValueFragmentFragment;
  const selected = values.nodes[0]?.value?.id ?? undefined;
  const [visible, setVisible] = useState(true);
  const localization = useLocalization();
  const { data, loading } = useQuery<EntityRadioCustomFieldCheckableOptionsQueryQuery,
  EntityRadioCustomFieldCheckableOptionsQueryQueryVariables>(CHECK_QUERY_OPTIONS, {
    variables: {
      code: props.options.selectedOption!.code as string
    },
    fetchPolicy: 'network-only'
  });
  const { submit, blocking } = usePersonFieldMutation({
    mutation: CUSTOM_UPSERT_MUTATION,
    controlSetting: props.record?.controlSetting,
    skipDcrWarning: true,
  });

  const [removeCustomFieldValue] = useMutation<RemoveCustomFieldInputValueMutation,
  RemoveCustomFieldInputValueMutationVariables>(CUSTOM_REMOVE_MUTATION);

  const onSelect = (e: RadioChangeEvent) => {
    submit({
      recordId: props.record.id,
      customFieldCode: props.options.selectedOption?.code,
      customValues: [{ valueId: e.target.value }],
    }).finally(() => props.options.refetchData());
  };

  const checkForDelete = (id: number) => {
    if (id === selected) {
      const toRemoveLinkId = values?.nodes?.filter(v => v.value?.id == id)[0]!.id;
      removeCustomFieldValue({
        variables: {
          customValues: [toRemoveLinkId]
        }
      }).finally(() => props.options.refetchData());
    }
  };

  const close = () => {
    if (!blocking) {
      setVisible(false);
      props.endEditing();
    }
  };

  if (loading) return <Loading spinSize='small' />;

  return (
    <Modal
      title={props?.options.selectedOption?.label}
      open={visible}
      onCancel={close}
      closable={false}
      footer={false}
      width="clamp(320px, 75%, 480px)"
      wrapClassName='custom-field-modal-container'
    >
      {!data?.customField?.isCheckableTag ? <Radio.Group onChange={onSelect} value={selected}>
        <Row>
          {(data?.customField?.options?.nodes ?? [])
            .map((radio: NonNullable<CustomFieldFragmentFragment['options']>['nodes']['0']) => (
              <Col key={radio.value!.id} span={24}>
                <Radio
                  key={radio.value!.id}
                  value={radio.value!.id}
                  onClick={() => checkForDelete(radio.value!.id)}
                >
                  {radio.value?.value}
                </Radio>
              </Col>
            ))}
        </Row>
      </Radio.Group>
        : <CustomFieldCheckableTagInput {...props} />}
      <Space style={{ width: '100%', justifyContent: 'center', marginTop: 20 }}>
        {
          blocking ? <Loading spinSize='small' />
            : <Button key="2" type="primary" className="save-button" onClick={close}>
              {localization.formatMessage(Locale.General.Ok)}
            </Button>
        }
      </Space>
    </Modal>
  );
};

const CHECK_QUERY_OPTIONS = gql`
  query EntityRadioCustomFieldCheckableOptionsQuery($code: String!) {
    customField(code: $code) {
      isCheckableTag
      options {
        hash
        nodes {
          id
          value {
            id
            value
          }
        }
      }
    }
  }
`;

export default CustomRadioInput;
