import { gql } from '@apollo/client';
import React from 'react';
import PersonActivityContainer from './Activities';
import PersonProjectContainer from './PersonProjectContainer';
import PersonNoteContainer from './PersonNoteContainer';
import { Locale } from '../../../../localization/LocalizationKeys';
import PersonDcrGroupContainer from './PersonDcrGroupContainer';
import PersonSiteAffiliationsTable from '../PersonSiteAffiliationsTable';
import PersonListContainer from './PersonListContainer';
import { RelationAffiliationTabConfig } from '../../../components/EntityTabs/RelationTabs/EntityRelationTabs';
import { EntityTypeEnum, PermissionEnum, PersonAffiliationsContainerQueryQuery } from '../../../../gql/typings';
import CherryPickTabContainer from '../../../components/CherryPickTabContainer/CherryPickTabContainer';
import PersonSelectionRowsContainer from './PersonSelectionRowsContainer';

export enum PersonAffiliationsTabsEnum {
  activities = 'activities',
  sites = 'sites',
  lists = 'lists',
  projects = 'projects',
  notes = 'notes',
  dcrs = 'dcrs',
  cherrypicks = 'cherrypicks',
  selections = 'selections',
}

export const personAffiliationTabConfigs: Record<
PersonAffiliationsTabsEnum, RelationAffiliationTabConfig<PersonAffiliationsContainerQueryQuery>> = {
  [PersonAffiliationsTabsEnum.activities]: {
    title: Locale.General.Activities,
    tab: {
      show: data => (data?.person?.activities.totalCount ?? 0) > 0,
      badgeProps: data => ({ count: data?.person?.activities.totalCount ?? 0 }),
    },
    render: personId => <PersonActivityContainer personId={personId} />,
  },
  [PersonAffiliationsTabsEnum.sites]: {
    title: Locale.General.Sites_HCO,
    tab: {
      show: true,
      badgeProps: data => ({ count: data?.person?.affiliations.totalCount ?? 0 }),
    },
    render: personId => <PersonSiteAffiliationsTable personId={personId} />
  },
  [PersonAffiliationsTabsEnum.lists]: {
    title: Locale.General.Lists,
    tab: {
      show: data => (data?.person?.lists.totalCount ?? 0) > 0,
      badgeProps: data => ({ count: data?.person?.lists.totalCount ?? 0 }),
    },
    render: personId => <PersonListContainer personId={personId} />,
  },
  [PersonAffiliationsTabsEnum.projects]: {
    title: Locale.General.Projects,
    tab: {
      show: data => (data?.person?.projects.totalCount ?? 0) > 0,
      badgeProps: data => ({ count: data?.person?.projects.totalCount ?? 0 }),
    },
    render: personId => <PersonProjectContainer personId={personId} />,
  },
  [PersonAffiliationsTabsEnum.notes]: {
    title: Locale.Attribute.Notes,
    tab: {
      show: data => (data?.person?.notes.totalCount ?? 0) > 0,
      badgeProps: data => ({ count: data?.person?.notes.totalCount ?? 0 }),
    },
    render: personId => <PersonNoteContainer personId={personId} />,
  },
  [PersonAffiliationsTabsEnum.dcrs]: {
    title: Locale.General.DCR,
    tab: {
      title: ({ pluralMessage }, data) => pluralMessage(Locale.General.DCR_Plural, data?.person?.dcrGroups.totalCount ?? 1),
      show: (data) => (data?.person?.dcrGroups.totalCount ?? 0) > 0,
      badgeProps: (data) => ({ count: data?.person?.dcrGroups.totalCount ?? 0 }),
    },
    render: personId => (
      <PersonDcrGroupContainer record={{ entityType: EntityTypeEnum.PERSON, entityAffiliationId: personId }} />
    ),
  },
  [PersonAffiliationsTabsEnum.cherrypicks]: {
    title: Locale.Attribute.Cherry_pick,
    readPermission: PermissionEnum.CHERRY_PICK_OTHER_INSTANCE,
    tab: {
      title: ({ formatMessage }) => formatMessage(Locale.Attribute.Cherry_picked),
      show: (data) => !!(data?.person?.apurebaseId),
    },
    render: (_, data) => (
      data?.person?.apurebaseId && <CherryPickTabContainer
        entityType={EntityTypeEnum.PERSON}
        apurebaseId={data.person.apurebaseId}
        countryCode={data.person.countryCode}
      />
    ),
  },
  [PersonAffiliationsTabsEnum.selections]: {
    title: Locale.Attribute.Selections,
    readPermission: PermissionEnum.SELECTION,
    tab: {
      show: () => true,
      badgeProps: (data) => ({ count: data?.person?.selectionRowsCustomerCount }),
    },
    render: (_, data) => data?.person?.apurebaseId && <PersonSelectionRowsContainer
      personId={data.person.id}
      apurebaseId={data.person.apurebaseId}
    />
  },
};

export const PERSON_AFFILIATIONS_DATA_QUERY = gql`
  query PersonAffiliationsContainerQuery($recordId: Int!) {
    person(id: $recordId) {
      id
      apurebaseId
      countryCode
      activities {
        hash
        totalCount
      }
      notes {
        hash
        totalCount
      }
      projects {
        hash
        totalCount
      }
      lists {
        hash
        totalCount
      }
      dcrGroups {
        hash
        totalCount
      }
      affiliations{
        hash
        totalCount
      }
      selectionRowsCustomerCount(criteria: { selectionType: DYNAMIC })
    }
  }
`;
