import { gql } from '@apollo/client';
import React, { useEffect } from 'react';
import { Form, Tooltip, Typography } from 'antd';
import { usePersonFieldMutation } from '../../person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import { Locale } from '../../../../localization/LocalizationKeys';
import AddressForm from '../../../components/Address/AddressForm';
import { Optional } from '../../../util/StateArrayType';
import {
  getVariableField,
  siteFieldConfig
} from '../../person/fields/utils/personFieldsUtils';
import {
  AddressInput,
  AddressTypeEnum,
  EntityTypeEnum,
  FieldEnum,
  SiteAddressTypesQueryQuery,
  UpdateSiteAddressMutationMutation, UpdateSiteAddressMutationMutationVariables
} from '../../../../gql/typings';
import { formatAddress } from '../../../util/format';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';


// MARK: This needs to match the query below!
type Address = {
  id: number;
  city?: string | null;
  countryCode?: string | null;
  postalCode?: string | null;
  street?: string | null;
  addressLine2?: string | null;
  typeCode?: string | null;
  countyCode?: string | null;
  communeCode?: string | null;
};

export const siteAddressFieldConfig = siteFieldConfig(
  'address',
  [
    'id',
    'countryCode',
    'controlSetting.id',
    'controlSetting.entityTypeId',
    'controlSetting.maintainerSourceCode',
    'controlSetting.createDcr',
    'addresses(criteria: { addressTypeCode: "{{keyCode}}" }).hash',
    'addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.id',
    'addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.id',
    'addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.street',
    'addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.addressLine2',
    'addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.postalCode',
    'addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.city',
    'addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.countryCode',
    'addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.countyCode',
    'addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.typeCode',
    'addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.communeCode',
  ],
  ({ localization }) => ({
    title: ({ selectedOption }) => selectedOption?.label,
    additionalTableConfig: {
      width: 150,
      ellipsis: {
        showTitle: false
      },
    },
    dcrInfo: (site, { selectedOption }) => {
      let fields: FieldEnum[] = [];
      if (selectedOption?.code === AddressTypeEnum.HCOVISIT) fields = [
        FieldEnum.HCO_VISIT_CITY,
        FieldEnum.HCO_VISIT_ZIP,
        FieldEnum.HCO_VISIT_STREET,
        FieldEnum.HCO_VISIT_COMMUNE,
      ];
      if (selectedOption?.code === AddressTypeEnum.HCOMAIL) fields = [
        FieldEnum.HCO_MAIL_CITY,
        FieldEnum.HCO_MAIL_ZIP,
        FieldEnum.HCO_MAIL_STREET,
        FieldEnum.HCO_MAIL_COMMUNE,
      ];
      return {
        entityType: EntityTypeEnum.SITE,
        entityAffiliationId: site.id,
        field: fields,
      };
    },
    getOptions: [localization.formatMessage(Locale.Attribute.Address), ({ apolloClient }) => apolloClient
      .query<SiteAddressTypesQueryQuery>({ query: SITE_ADDRESS_TYPES_QUERY })
      .then(res => res.data.addressTypes.nodes.map(type => ({
        code: type.code,
        label: localization.formatMessageByStr(type.headingKey),
      })))],
    render: (record, options) => {
      if (!options.selectedOption) return ' ';
      const address = getVariableField(record, options, 'addresses').nodes[0]?.address;
      const formattedAddress = formatAddress({ ...address });
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: options.isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={formattedAddress}>{formattedAddress}</Tooltip>
      </Typography.Paragraph>);
    },
    preserve: true,
    updateView: ({ endEditing, record: site, options }) => {
      const [form] = Form.useForm<AddressInput>();
      const addressAffiliation = getVariableField(site, options, 'addresses').nodes[0];
      const address: Optional<Address> = addressAffiliation?.address;

      const city = Form.useWatch('city', form);
      const countryCode = Form.useWatch('countryCode', form);
      const countyCode = Form.useWatch('countyCode', form);
      const postalCode = Form.useWatch('postalCode', form);
      const street = Form.useWatch('street', form);
      const addressLine2 = Form.useWatch('addressLine2', form);
      const communeCode = Form.useWatch('communeCode', form);

      useEffect(() => {
        form.setFieldsValue(address || { countryCode: site.countryCode });
      }, [site, form, address]);

      const {
        Renderer,
      } = usePersonFieldMutation<UpdateSiteAddressMutationMutation, UpdateSiteAddressMutationMutationVariables>({
        mutation: UPDATE_MUTATION,
        controlSetting: site.controlSetting,
        asModal: true,
        refetch: options.refetchData,
      });

      return (
        <Renderer
          heading={localization.formatMessage(Locale.Command.Update_address_information)}
          onClose={endEditing}
          variables={{
            existingAffiliationId: addressAffiliation?.id,
            siteId: site.id,
            addressInput: {
              addressTypeCode: address?.typeCode ?? options.selectedOption?.code as string,
              street,
              addressLine2,
              postalCode,
              city,
              countryCode,
              countyCode,
              communeCode,
            },
          }}
        >
          <Form form={form}>
            <AddressForm form={form} />
          </Form>
        </Renderer>
      );
    },
  })
);

const UPDATE_MUTATION = gql`
  mutation UpdateSiteAddressMutation(
    $existingAffiliationId: Int,
    $siteId: Int!,
    $addressInput: AddressInput!
  ) {
    updateOrCreateAddress(
      addressAffiliationId: $existingAffiliationId,
      entityType: SITE,
      entityAffiliationId: $siteId,
      addressInput: $addressInput
    ) {
      id
    }
  }
`;

export const SITE_ADDRESS_TYPES_QUERY = gql`
  query SiteAddressTypesQuery {
    addressTypes(criteria: { entityType: SITE }) {
      hash
      nodes {
        code
        headingKey
      }
    }
  }
`;

