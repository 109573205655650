import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, MenuProps } from 'antd';
import appLogo from '../../img/PureAdvanceLogo.svg';
import Icon from '../components/Icon/Icon';
import { Locale } from '../../localization/LocalizationKeys';
import HeaderIsImpersonating from './HeaderIsImpersonating';
import { useLocalization } from '../util/useLocalization';
import { MyInfoQuery } from '../../gql/typings';


const Header = () => {
  const { data } = useQuery<MyInfoQuery>(DATA_QUERY);
  const localization = useLocalization();
  const username = data?.viewer?.username;
  const isApb = username?.includes('apurebase.com') || username?.includes('mammajogvan');

  const items = [
    {
      key: '0',
      label: (
        <NavLink to="/logout">
          <Icon
            iconType="LOGOUT"
            text={localization.formatMessage(Locale.Command.Logout)}
          />
        </NavLink>
      )
    },
    isApb && {
      key: '1',
      label: (
        <NavLink to="/admin">
          <Icon
            iconType="SETTINGS"
            text="Admin"
          />
        </NavLink>
      ),
    },
  ].filter(e => e) as MenuProps['items'];


  return (
    <div className="header-container">
      <div className="app-logo">
        <NavLink to="/">
          <img src={appLogo} alt="aPureBase Logo" />
        </NavLink>
      </div>


      <HeaderIsImpersonating />

      <Dropdown menu={{ items }} trigger={['click']}>
        <div className="user-menu">
          <div className="user-info">
            <span className="name">{data?.viewer?.fullname ?? ''}</span>
            <span className="role">{data?.viewer?.role?.name ?? ''}</span>
          </div>
          <Icon className="chevron" iconType="CHEVRON_DOWN" />
        </div>
      </Dropdown>
    </div>
  );
};

const DATA_QUERY = gql`
  query MyInfo {
    viewer {
      id
      fullname
      username
      role {
        id
        name: heading
      }
    }
  }
`;

export default Header;
