import React from 'react';
import { Form, notification } from 'antd';
import { flatten, identity, omit } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FormInstance } from 'rc-field-form/lib/interface';
import { Optional } from '../../../util/StateArrayType';
import { AddWorkplaceInput, PersonInput } from '../../../../gql/typings';


export const CreatePersonProvider: React.FC<{
  onCreate: (value: PersonInput) => void;
}> = ({ onCreate, children }) => (
  <Form.Provider
    onFormFinish={(name, { forms: { site, person, workplace } }) => {
      if (name !== 'person') return;

      // Execute all validations
      Promise.all([workplace?.validateFields(), site?.validateFields()].filter(e => e)).finally(() => {
        // extract errors from FormInstance into a string array
        const getErrors = (form: Optional<FormInstance>): string[] => form
          ? flatten(form.getFieldsError().map(field => field.errors))
          : [];

        const personValues = person?.getFieldsValue(true) as PersonInput;
        const siteErrors = getErrors(site);
        const workplaceValues = workplace?.getFieldsValue(true) as Optional<AddWorkplaceInput>;
        const workplaceErrors = getErrors(workplace);

        // if no fields are provided on the workplaceValues, we'll ignore any further errors
        // and only call onCreate with the person form values
        const cleanWorkplaceValues = Object.values(omit(workplaceValues ?? {}, ['contacts', 'phone', 'instantValidation']));
        if (cleanWorkplaceValues.filter(identity).length === 0) {
          return onCreate(omit(personValues, ['workplace']) as PersonInput);
        }

        // If siteInput is provided, we'll make sure no errors are there.
        // If siteInput does not exist, we will ignore all site specific errors.
        const errors = ((workplaceValues?.siteInput && !workplaceValues.siteId)
          ? [...siteErrors, ...workplaceErrors]
          : workplaceErrors).filter(identity);

        if (errors.length > 0) return notification.error({
          message: "Workplace information isn't complete",
          description: 'Please either add missing information or remove workplace from the input',
        });
        onCreate({
          ...personValues,
          workplace: Object.values(workplaceValues ?? {}).filter(e => e).length === 0
            ? undefined
            : {
              ...workplaceValues,
              positionCode: workplaceValues!.positionCode!,
              siteInput: workplaceValues?.siteId ? undefined : workplaceValues?.siteInput ? {
                ...workplaceValues.siteInput,
              } : undefined,
            },
        });
      });
    }}
  >
    {children}
  </Form.Provider>
);
