import { TableFieldsConfig } from '../search_old/types';
import { selectionRowResultRowIdFieldConfig } from './fields/selectionRowResultRowIdFieldConfig';
import { selectionRowResultCustomerNameFieldConfig } from './fields/selectionRowResultCustomerNameFieldConfig';
import {
  selectionRowResultSelectionGroupDescriptionFieldConfig
} from './fields/selectionRowResultSelectionGroupDescriptionFieldConfig';
import { selectionRowResultSelectionDescriptionFieldConfig } from './fields/selectionRowResultSelectionDescriptionFieldConfig';
import { selectionRowResultSelectionTypeFieldConfig } from './fields/selectionRowResultSelectionTypeFieldConfig';

export type SupportedSelectionRowResultTableColumns =
  'rowId'
  |'customerName'
  |'selectionGroupDescription'
  |'selectionDescription'
  |'selectionType';

export const supportedSelectionRowResultTableColumns: TableFieldsConfig<SupportedSelectionRowResultTableColumns>['fields'] = {
  rowId: selectionRowResultRowIdFieldConfig,
  customerName: selectionRowResultCustomerNameFieldConfig,
  selectionGroupDescription: selectionRowResultSelectionGroupDescriptionFieldConfig,
  selectionDescription: selectionRowResultSelectionDescriptionFieldConfig,
  selectionType: selectionRowResultSelectionTypeFieldConfig,
};
