/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Form, Switch } from 'antd';
import { isNil } from 'lodash';
import { criteriaInputKeyGenerator } from '../CriteriaInputFactory';
import { extractBooleanValue } from '../../../util/Util';
import { CriteriaInputProps } from '../CriteriaInputTools';

const CriteriaSwitchInput: React.FC<CriteriaInputProps> = React.memo(({
  form,
  criteria,
  inputPath,
  disabled,
  initialValue,
}) => {
  const key = criteriaInputKeyGenerator(criteria, inputPath);

  useEffect(() => {
    if (!isNil(initialValue)) form.setFieldsValue({ [key]: extractBooleanValue(initialValue) });
  }, [key, initialValue]);

  return (
    <Form.Item name={key} valuePropName="checked">
      <Switch id={key} disabled={disabled} />
    </Form.Item>
  );
});

export default CriteriaSwitchInput;
