import React from 'react';
// import * as PropTypes from 'prop-types';
import { Checkbox } from 'antd';

const Check = ({ label, onChange }) => (
  <Checkbox onChange={onChange}> {label} </Checkbox>
);

// Check.propTypes = {
//   onChange: PropTypes.func,
//   selected: PropTypes.bool,
// };

export default Check;
