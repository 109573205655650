import { Card } from 'antd';
import React, { CSSProperties } from 'react';

type EntityTabCardProps = {
  title?: string;
  className?: string;
  style?: CSSProperties;
  loading?: boolean;
};

const EntityTabCard: React.FC<EntityTabCardProps> = ({
  title,
  className,
  style = {},
  loading,
  children,
}) => (
  <Card
    title={title}
    loading={loading}
    className="entity-tab-card-container"
  >
    <div style={style} className={`inner ${className}`}>
      {children}
    </div>
  </Card>
);

export default EntityTabCard;
