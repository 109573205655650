import React, { useState } from 'react';
import RGL, { Layout, WidthProvider } from 'react-grid-layout';
import { isArray, isBoolean, isEqual, sortBy, trimStart } from 'lodash';
import { useDebounceEffect } from 'ahooks';
import { Empty, Typography } from 'antd';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';

const ReactGridLayout = WidthProvider(RGL);

type SortableElementsProps<T> = {
  dataSource: T[];
  renderItem: (row: T) => string|React.ReactElement;
  rowKey: (row: T) => string;
  rowHeight?: number|undefined;
  onUpdate?: (rows: T[]) => void;
  cancelDraggableClassname?: string|string[];
  loading?: boolean;
  standardItemDesign?: boolean|{ size: 'default'|'small' };
  className?: string;
  itemClassName?: string;
  delayMs?: number;
};

type LayoutElement<T> = Layout & { element: T };

function build<T>(ds: T[], rowKey: (element: T) => string): LayoutElement<T>[] {
  return ds.map((element, index) => ({
    i: rowKey(element),
    isBounded: true,
    resizeHandles: [],
    x: 0,
    y: index,
    h: 1,
    w: 1,
    element,
  }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SortableElements<T = any>({
  dataSource,
  renderItem,
  rowKey,
  standardItemDesign,
  rowHeight = standardItemDesign && !isBoolean(standardItemDesign) && standardItemDesign.size === 'small' ? 34 : 54,
  onUpdate,
  cancelDraggableClassname,
  loading,
  delayMs = 1000,
  itemClassName = '',
  className = '',
}: SortableElementsProps<T>) {
  const localization = useLocalization();
  const initial = build(dataSource, rowKey);
  const [layout, setLayout] = useState<LayoutElement<T>[]>(initial);

  if (layout.length === 0 && initial.length > 0) setLayout(initial);

  useDebounceEffect(() => {
    const rows = build(dataSource, rowKey);
    const oldOrderedIds = layout.map(l => l.i);
    const newOrderedIds = rows.map(r => r.i);
    if (!isEqual(oldOrderedIds, newOrderedIds)) setLayout(rows);
  }, [dataSource], { wait: delayMs });

  /**
   * We only want to call [onUpdate] when the order has actually changed.
   */
  const onLayoutChange = (layouts: Layout[]) => {
    if (!onUpdate) return;
    const rows = sortBy(layouts, l => l.y).map(l => layout.map(l => l.element).filter(ds => rowKey(ds) === l.i)[0]!);
    const newOrderedIds = rows.map(r => rowKey(r));
    const oldOrderedIds = layout.map(l => l.i);
    if (!isEqual(oldOrderedIds, newOrderedIds)) onUpdate(rows);
  };

  if (!loading && dataSource.length === 0) return <Empty
    description={<Typography.Text>
      {localization.formatText(Locale.Text.No_fields_added_to_grid_configuration)}
    </Typography.Text>}
  />;

  const trim = (str: string) => `.${trimStart(str, '.')}`;

  let itemClassNames = 'sortable-element';
  if (itemClassName && itemClassNames.length > 0) itemClassNames += ` ${itemClassNames}`;
  if (loading) itemClassNames += ' loading';
  if (standardItemDesign) {
    itemClassNames += ' standard';
    if (isBoolean(standardItemDesign) || standardItemDesign.size === 'default') itemClassNames += ' size-default';
    else itemClassNames += ' size-small';
  }


  return (
    <div className={className}>
      <ReactGridLayout
        cols={1}
        isBounded
        layout={layout}
        measureBeforeMount
        rowHeight={rowHeight}
        isDraggable={!loading}
        onLayoutChange={onLayoutChange}
        draggableCancel={cancelDraggableClassname
          ? isArray(cancelDraggableClassname)
            ? cancelDraggableClassname.map(trim).join()
            : trim(cancelDraggableClassname)
          : undefined}
        className="sortable-elements-container"
      >
        {layout.map(item => (
          <div key={item.i} className={itemClassNames}>
            {renderItem(item.element)}
          </div>
        ))}
      </ReactGridLayout>
    </div>
  );
}

export default SortableElements;
