// eslint-disable-next-line import/no-extraneous-dependencies
import { Property } from 'csstype';
import React from 'react';
import { Locale } from '../../../localization/LocalizationKeys';
import Icon from '../../components/Icon/Icon';
import { DcrResponseTypeEnum } from '../../../gql/typings';


type DcrStatusConfiguration = {
  localizationKey: { id: string };
  color: Property.Color;
  pendingMessage: React.ReactNode | null;
  status: string;
};

export const DcrResponseConf: Record<DcrResponseTypeEnum, DcrStatusConfiguration> = {
  ACCEPT: {
    localizationKey: Locale.General.Accepted,
    color: '#cdcd23',
    pendingMessage: null,
    status: 'ACCEPT',
  },
  PENDING_REJECT: {
    localizationKey: Locale.Text.Pending_reject,
    color: '#f15d37',
    pendingMessage: null,
    status: 'PENDING_REJECT',

  },
  QUEUE: {
    localizationKey: Locale.General.Queue,
    color: '#77abd3',
    pendingMessage: <Icon iconType="CLOCK" style={{ color: '#77abd3' }} />,
    status: 'QUEUE',
  },
  REJECT: {
    localizationKey: Locale.General.Rejected,
    color: '#b1571c',
    pendingMessage: null,
    status: 'REJECT',

  },
  UNHANDLED: {
    localizationKey: Locale.General.Unhandled,
    color: '#818285',
    pendingMessage: <Icon iconType="CLOCK" style={{ color: '#818285' }} />,
    status: 'UNHANDLED',
  },
};

