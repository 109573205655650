import { useMutation, gql } from '@apollo/client';
import React from 'react';
import { Button, Drawer, Form, Input, Space, Switch } from 'antd';
import { useIntl } from 'react-intl';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  CreateListInModalMutation,
  CreateListInModalMutationVariables,
  EntityTypeEnum,
  ListTypeEnum
} from '../../../../gql/typings';


type CreateListModalProps = {
  visible: boolean;
  onClose: () => void;
  onCreated: (newId: number) => void;
  entityType: EntityTypeEnum;
};

const CreateDynamicListModal: React.FC<CreateListModalProps> = ({
  onClose,
  onCreated,
  entityType,
  visible,
}) => {

  const [createList] = useMutation<CreateListInModalMutation, CreateListInModalMutationVariables>(CreateList_Mutation);
  const { formatMessage } = useIntl();
  const [listForm] = Form.useForm();
  const onAccept = () => listForm.validateFields()
    .then(values => {
      onClose();
      createList({
        variables: {
          input: {
            title: values['list-title'],
            description: values['list-description'],
            isShared: values['list-visability'],
            type: ListTypeEnum.DYN,
            entityType,
            isActive: true,
          },
        },
      })
        .then(res => onCreated(res?.data?.createList?.id ?? -1));
    });


  return (
    <>
      <Drawer
        title={formatMessage(Locale.Command.Create_Dynamic_List)}
        placement="right"
        width="500px"
        closable={false}
        onClose={onClose}
        open={visible}
        className="create-dynamic-list-drawer-container"
      >
        <div className="static-list-container">
          <Form form={listForm}>
            <span className="label">
              {formatMessage(Locale.Attribute.Heading)}
            </span>
            <div className="input">
              <Form.Item
                name="list-title"
                rules={[
                  {
                    required: true,
                    message: formatMessage(Locale.Text.Please_enter_heading)
                  },
                  {
                    max: 40,
                    message: formatMessage(Locale.Text.Heading_must_be_beneath_40_characters)
                  },
                ]}
              >
                <Input
                  className="list-title"
                  autoFocus
                  placeholder={formatMessage(Locale.Command.Enter_title)}
                />
              </Form.Item>
            </div>
            <span className="label">
              {formatMessage(Locale.Attribute.Description)}
            </span>
            <div className="input">
              <Form.Item name="list-description">
                <Input.TextArea
                  id="list-description"
                  placeholder={formatMessage(Locale.Command.Enter_description)}
                />
              </Form.Item>
            </div>

            <span className="label">
              {formatMessage(Locale.Attribute.Description)}

            </span>
            <div className="input">
              <Form.Item name="list-visability" valuePropName='checked'>
                <Switch
                  checkedChildren="Shared"
                  unCheckedChildren="Private"
                  defaultChecked={false}
                />
              </Form.Item>
            </div>

          </Form>
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={onAccept}>
              {formatMessage(Locale.Command.Save)}
            </Button>
          </Space>
        </div>
      </Drawer>
    </>
  );
};

const CreateList_Mutation = gql`
  mutation CreateListInModal($input: ListInput!) {
    createList(input: $input) { id }
  }
`;


export default CreateDynamicListModal;
