import { useQuery, gql } from '@apollo/client';
import React from 'react';
import { useMatch } from 'react-router-dom';
import EnvironmentOutlined from '@ant-design/icons/lib/icons/EnvironmentOutlined';
import { useIntl } from 'react-intl';
import Loading from '../../components/Loading/Loading';
import MainView from '../../components/View/MainView';
import DisplayFields from '../../components/DisplayFields/DisplayFields';
import NameTag from '../../components/ContactCard/NameTag';
import { Locale } from '../../../localization/LocalizationKeys';
import { BrickDetailQueryQuery, BrickDetailQueryQueryVariables } from '../../../gql/typings';


const BrickDetail = () => {
  const match = useMatch('/brick/:id');
  const intl = useIntl();
  const { data, loading } = useQuery<BrickDetailQueryQuery, BrickDetailQueryQueryVariables>(DATA_QUERY, {
    variables: { code: match!.params.id! },
  });

  if (loading) return <MainView className="brick-detail-view-content-container"><Loading /></MainView>;

  return (
    <MainView className="brick-detail-view-content-container">
      <NameTag
        title={data?.brick?.heading}
        // subTitle={data?.brick?.brickType.description}
        image={<EnvironmentOutlined style={{
          fontSize: '28px',
          marginTop: '16px',
        }}
        />}
      />

      <div className='clear' />

      <DisplayFields
        items={[
          // {
          //   label: intl.formatMessage(Locale.Attribute.Country),
          //   value: data?.brick?.countryCode,
          // },
          {
            label: intl.formatMessage(Locale.Attribute.Created),
            value: data?.brick?.ct,
            transform: 'time',
          },
          {
            label: intl.formatMessage(Locale.Attribute.Updated),
            value: data?.brick?.ut,
            transform: 'time',
          },
          // {
          //   label: intl.formatMessage(Locale.Attribute.DB_source),
          //   value: data?.brick?.dbSource,
          // },
        ]}
      />
      {/* <Table */}
      {/*  dataSource={(data?.brick?.sites.nodes ?? [])} */}
      {/*  totalSourceCount={data?.brick?.sites.totalCount ?? 0} */}
      {/*  title='Sites' */}
      {/*  loading={loading} */}
      {/*  columns={[ */}
      {/*    { */}
      {/*      title: intl.formatMessage(Locale.Attribute.Site_name), */}
      {/*      dataIndex: 'name', */}
      {/*      render: (text: string, record: BrickDetailQuery_brick_sites_nodes) => ( */}
      {/*        <NavLink to={`/hco/${record.id}`}>{text}</NavLink> */}
      {/*      ), */}
      {/*    }, */}
      {/*    { */}
      {/*      title: intl.formatMessage(Locale.Attribute.Country), */}
      {/*      dataIndex: 'country', */}
      {/*      render: (text: string) => <DisplayContent>{text}</DisplayContent>, */}
      {/*    }, */}
      {/*    { */}
      {/*      title: intl.formatMessage(Locale.Attribute.Street), */}
      {/*      dataIndex: ['site', 'mailStreet'], */}
      {/*      key: 'site.mailStreet', */}
      {/*      render: (text: string) => <DisplayContent>{text}</DisplayContent>, */}
      {/*    }, */}
      {/*    { */}
      {/*      title: intl.formatMessage(Locale.Attribute.Position), */}
      {/*      dataIndex: ['position', 'label'], */}
      {/*      key: 'position.code', */}
      {/*      render: (text: string) => <DisplayContent>{text}</DisplayContent>, */}
      {/*    }, */}
      {/*    { */}
      {/*      title: intl.formatMessage(Locale.Attribute.Site_specialty), */}
      {/*      dataIndex: 'siteSpecialties', */}
      {/*      key: 'siteSpecialties', */}
      {/*      render: (_: string, record: BrickDetailQuery_brick_sites_nodes) => ( */}
      {/*        <PosistionBadge */}
      {/*          positions={record.siteSpecialties.nodes} */}
      {/*        /> */}
      {/*      ), */}
      {/*    }, */}
      {/*  ]} */}
      {/*  page={page} */}
      {/*  perPage={perPage} */}
      {/* /> */}
    </MainView>
  );
};

const DATA_QUERY = gql`
  query BrickDetailQuery($code: String!) {
    brick(code: $code) {
      code
      territoryCode
      ct
      ut
      heading
#      brickType {
#        code
#        countryCode
#        ct
#        description
#        id
#        status
#        title
#        ut
#      }
#      sites {
#        hash
#        totalCount
#        nodes{
#          id
#          name
#          country: countryCode
#          mailAddress {
#            id
#            city
#            postalCode
#          }
#          siteSpecialties {
#            hash
#            nodes {
#              code
#              description
#              label
#              type
#              labelGb
#            }
#          }
#        }
#      }
    }
  }
`;

export default BrickDetail;
