import React from 'react';
import GlowButton from '../Input/GlowButton';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';

type AddCardProps = {
  label?: string|React.ReactNode;
  onClick?: () => void;
  small?: boolean;
};

const AddCard: React.FC<AddCardProps> = ({
  onClick = () => {},
  label,
  small,
}) => {
  const { formatMessage } = useLocalization();
  return (
    <div className={`contact-card-container ${small ? 'small' : ''}`}>
      <span className="add-card" onClick={onClick}>
        <GlowButton type="primary" label={label ?? formatMessage(Locale.Command.Add)} />
      </span>
    </div>
  );

};

export default AddCard;
