import React from 'react';
import { Badge, FormInstance, Skeleton } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { CreateMassAssignData } from './MassAssignProcess';
import { useLocalization } from '../../util/useLocalization';
import {
  MassAssignPreviewQueryQuery,
  MassAssignPreviewQueryQueryVariables,
  SelectionCriteriaInput,
} from '../../../gql/typings';


type MassAssignPreviewProps = {
  form: FormInstance<CreateMassAssignData>;
  selectionCriteria: SelectionCriteriaInput;
};

const MassAssignPreview: React.FC<MassAssignPreviewProps> = (props) => {
  const localization = useLocalization();
  const { form, selectionCriteria } = props;
  const {
    actionType,
    singleSelectValue,
    actionName,
    entityType,
  } = form.getFieldsValue(true);
  const { data, loading } = useQuery<MassAssignPreviewQueryQuery, MassAssignPreviewQueryQueryVariables>(DATA_QUERY, {
    variables: {
      actionName,
      selectionCriteria,
      input: {
        action: actionType,
        entityType,
        values: singleSelectValue ? singleSelectValue.toString() : ''
      }
    },
  });

  const addLabel = data?.previewMassAssign.addLabel;
  const updateLabel = data?.previewMassAssign.updatedLabel;
  const skipLabel = data?.previewMassAssign.skipLabel;
  const blankOutLabel = data?.previewMassAssign.blankOutLabel;


  return (
    <div className="mass-assign-container">
      <div className="mass-assign-type-description">
        <p>Preview of all changes made by mass assign</p>
        <p>Please press submit to process changes</p>
      </div>
      <Skeleton loading={loading}>
        <div className="dashboard-card-container">
          <div className="mass-assign-content-container">
            <h3>Preview</h3>
            {addLabel && <div>
              <Badge style={{ backgroundColor: 'green' }} showZero count={data?.previewMassAssign.addCount} />
              <span style={{ marginLeft: '10px' }}> {localization.formatMessageByStr(addLabel)}</span>
            </div>}
            {updateLabel && <div>
              <Badge style={{ backgroundColor: 'orange' }} showZero count={data?.previewMassAssign.updatedCount} />
              <span style={{ marginLeft: '10px' }}> {localization.formatMessageByStr(updateLabel)}</span>
            </div>}
            {skipLabel && <div>
              <Badge count={data?.previewMassAssign.skipCount} showZero />
              <span style={{ marginLeft: '10px' }}> {localization.formatMessageByStr(skipLabel || 'New')}</span>
            </div>}
            {blankOutLabel && <div>
              <Badge count={data?.previewMassAssign.blankOutSize} showZero />
              <span style={{ marginLeft: '10px' }}> {localization.formatMessageByStr(blankOutLabel)}</span>
            </div>}


          </div>
        </div>
      </Skeleton>

    </div>
  );
};
const DATA_QUERY = gql`
  query MassAssignPreviewQuery($actionName: String!,
    $selectionCriteria: SelectionCriteriaInput!,
    $input: MassAssignFieldInput!) {
    previewMassAssign(actionName: $actionName, selection: $selectionCriteria, input: $input) {
      tbd
      addCount
      addLabel
      skipCount
      skipLabel
      updatedCount
      updatedLabel
      blankOutLabel
      blankOutSize

    }
  }
`;


export default MassAssignPreview;
