import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Card, Col, message, Popconfirm, Row, Tooltip } from 'antd';
import { PlayCircleOutlined, SettingOutlined, StopOutlined } from '@ant-design/icons';
import ReactJson from 'react-json-view';
import {
  InstanceDeploymentSchedulesQueryQuery,
  InstanceDeploymentSchedulesQueryQueryVariables,
  ScheduleDataLoaderMutationMutation, ScheduleDataLoaderMutationMutationVariables,
  ScheduleDcrSyncMutationMutation,
  ScheduleDcrSyncMutationMutationVariables
} from '../../../../../gql/typings';


type PureAdvanceCustomerSchedulesProps = {
  customerCode: string;
};

const PureAdvanceCustomerSchedules: React.FC<PureAdvanceCustomerSchedulesProps> = ({ customerCode }) => {
  const {
    data,
  } = useQuery<InstanceDeploymentSchedulesQueryQuery, InstanceDeploymentSchedulesQueryQueryVariables>(DATA_QUERY, {
    variables: { customerCode },
  });
  const [syncDcrs] = useMutation<ScheduleDcrSyncMutationMutation, ScheduleDcrSyncMutationMutationVariables>(SyncMutation);
  const [
    loadDataLoader,
  ] = useMutation<ScheduleDataLoaderMutationMutation, ScheduleDataLoaderMutationMutationVariables>(DataLoaderMutation);

  const scheduleDataLoader = () => loadDataLoader({
    variables: { customerCode },
  });
  const scheduleDcrLoader = () => syncDcrs({
    variables: { customerCode },
  });

  return (
    <Row gutter={24}>
      <Col span={8}>
        <Card
          title="Data Loader Schedule"
          actions={[
            <Tooltip title="Disable scheduler" key="stop">
              <StopOutlined onClick={() => message.warning('Disabling a schedule is currently not supported')} />
            </Tooltip>,
            <Popconfirm
              key="play"
              title={<span>
                This will trigger the data loader to run.<br />
                Currently there will be no visualizer showing that it works.<br />
                But it will run silently in the background and may take a few minutes to complete.
              </span>}
              placement="bottom"
              onConfirm={scheduleDataLoader}
            >
              <Tooltip title="Trigger scheduler now">
                <PlayCircleOutlined key="play" />
              </Tooltip>
            </Popconfirm>,
            <Tooltip title="Configure scheduler" key="ellipsis">
              <SettingOutlined onClick={() => message.warning('Configuring a schedule is currently not supported')} />
            </Tooltip>,
          ]}
        >
          <ReactJson
            enableClipboard={false}
            displayDataTypes={false}
            src={data?.pureAdvanceCustomer?.dataSyncSchedule ?? {}}
          />
        </Card>
      </Col>


      <Col span={8}>
        <Card
          title="DCR Synchronization Schedule"
          actions={[
            <Tooltip title="Disable scheduler" key="stop">
              <StopOutlined onClick={() => message.warning('Disabling a schedule is currently not supported')} />
            </Tooltip>,
            <Popconfirm
              key="play"
              title={<span>
                This will trigger the DCR synchronization job to run.<br />
                Currently there will be no visualizer showing that it works.<br />
                But it will run silently in the background and may take a few minutes to complete.
              </span>}
              placement="bottom"
              onConfirm={scheduleDcrLoader}
            >
              <Tooltip title="Trigger scheduler now">
                <PlayCircleOutlined key="play" />
              </Tooltip>
            </Popconfirm>,
            <Tooltip title="Configure scheduler" key="ellipsis">
              <SettingOutlined onClick={() => message.warning('Configuring a schedule is currently not supported')} />
            </Tooltip>,
          ]}
        >
          <ReactJson
            enableClipboard={false}
            displayDataTypes={false}
            src={data?.pureAdvanceCustomer?.dcrSyncSchedule ?? {}}
          />
        </Card>
      </Col>
    </Row>
  );
};


const DATA_QUERY = gql`
  query InstanceDeploymentSchedulesQuery($customerCode: String!) {
    pureAdvanceCustomer(customerCode: $customerCode) {
      code
      heading
      dataSyncSchedule {
        ...ScheduleFragment
      }
      dcrSyncSchedule {
        ...ScheduleFragment
      }
    }
  }

  fragment ScheduleFragment on GitlabSchedule {
    id
    active
    cron
    cron_timezone
    description
    next_run_at
    last_pipeline {
      id
      duration
      finished_at
      started_at
      status
      web_url
    }
  }
`;

const SyncMutation = gql`
  mutation ScheduleDcrSyncMutation($customerCode: String!) {
    runDcrSynchronization(customerCode: $customerCode)
  }
`;
const DataLoaderMutation = gql`
  mutation ScheduleDataLoaderMutation($customerCode: String!) {
    runDataLoader(customerCode: $customerCode)
  }
`;


export default PureAdvanceCustomerSchedules;
