import { gql } from '@apollo/client';


export const FRAGMENT = gql`
  fragment contactPersons on PersonConnection {
    hash
    totalCount
    nodes {
      # These fields should be included in the personTable fragment.
      id
      fullname: fullName
      title
      segmentationCode{
        id
        code
        heading
      }
    }
  }
`;

