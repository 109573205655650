import React from 'react';
import { gql } from '@apollo/client/core';
import { Tooltip, Typography } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  SelectPersonEduSuggestionQueryDocument,
  SelectPersonEduSuggestionQueryQuery,
  SelectPersonEduSuggestionQueryQueryVariables,
  UpdatePersonEducationMutationMutationVariables,
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';

export const personEducationFieldConfig = personFieldConfig(
  'education',
  [
    'id',
    'countryCode',
    'educations.hash',
    'educations.nodes.code',
    'educations.nodes.type',
    'educations.nodes.label',
    'controlSetting.id',
    'controlSetting.createDcr',
    'controlSetting.maintainerSourceCode',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Education),
    additionalTableConfig: {
      ellipsis: {
        showTitle: false
      },
      width: 150
    },
    dcrInfo: record => ({
      entityType: EntityTypeEnum.PERSON,
      entityAffiliationId: record.id,
      field: FieldEnum.EDU_CODE,
    }),
    render: (person, { isViewingFromPage }) => {
      const educationField = person.educations.nodes.map(e => e.label).join(', ');
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={educationField}>{educationField}</Tooltip>
      </Typography.Paragraph>);
    },
    preserve: true,
    updateView: buildSelectUpdateFieldConfig({
      mode: 'multiple',
      modalHeading: localization.formatMessage(Locale.Command.Select_Educational_Specialty),
      selectedKeys: r => r.educations.nodes.map(n => n.code),
      fetchSuggestions: ({ client, record }) => (
        client.query<SelectPersonEduSuggestionQueryQuery, SelectPersonEduSuggestionQueryQueryVariables>({
          query: SelectPersonEduSuggestionQueryDocument,
          variables: { countries: [record.countryCode] },
        }).then(res => res.data.availableSpecialties.nodes)
      ),
      mutation: UPDATE_PERSON_EDUCATION_MUTATION,
      buildVariables: (selectedKeys, person) => ({
        persId: person.id,
        educations: selectedKeys,
      }) as UpdatePersonEducationMutationMutationVariables,
    }),
  })
);


const UPDATE_PERSON_EDUCATION_MUTATION = gql`
  mutation UpdatePersonEducationMutation($persId: Int!, $educations: [String!]!) {
    updatePersonEducations(persId: $persId, educations: $educations) {
      id
      person {
        id
        educations {
          hash
          nodes {
            code
            type
          }
        }
      }
    }
  }
`;

gql`
  query SelectPersonEduSuggestionQuery($countries: [CountryCode!]!) {
    availableSpecialties: educations(criteria: { countries: $countries, fetchSize: { limit: 5000 } }) {
      hash
      nodes {
        code
        label: displayLabel
        type
      }
    }
  }
`;
