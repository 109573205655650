import { useQuery, gql } from '@apollo/client';
import React from 'react';
import { Skeleton } from 'antd';
import { NavLink } from 'react-router-dom';
import { SelectOutlined } from '@ant-design/icons';
import AffiliationCard from '../../../components/Card/AffiliationCard';
import { PersonDetailProjectsQuery } from '../../../../gql/typings';


const PersonProjectContainer: React.FC<{ personId: number }> = ({ personId }) => {
  const { data, loading } = useQuery<PersonDetailProjectsQuery>(DATA_QUERY, {
    variables: { personId },
  });
  const projects = data?.person?.projects.nodes ?? [];

  return (
    <Skeleton loading={loading}>
      {projects.map(it => <AffiliationCard
        key={it.id}
        title={it.title}
        url={<><NavLink
          to={`/project/${it.id}`}
        >
          <SelectOutlined />
        </NavLink></>}
        startDate={it.startDate}
        type={it.projectType}
        note={it.notes.nodes[0]?.text ?? ''}
        users={it.responsibleUsers.nodes}
        createdDate={it.ct}
        status={it.projectStatus.code}
        endDate={it.endDate}
      />)}
    </Skeleton>
  );
};

const DATA_QUERY = gql`
  query PersonDetailProjects($personId: Int!) {
    person(id: $personId) {
      id
      projects{
        hash
        nodes{
          id
          title
          location
          startDate
          endDate
          notes {
            hash
            nodes {
              id
              text
            }
          }
          ct
          responsibleUsers{
            hash
            nodes{
              id
              fullname
            }
          }
          projectStatus{
            id
            heading
            code
          }
          projectType{
            id
            heading
            color{
              colorName: headingKey
              code
            }
          }
        }
      }
    }
  }
`;


PersonProjectContainer.propTypes = {};


export default PersonProjectContainer;

