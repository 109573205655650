import React from 'react';
import { Table, TableProps } from 'antd';
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
  SortableHandle as sortableHandle,
  SortEndHandler
} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { omit } from 'lodash';
import { FormListFieldData, FormListProps } from 'antd/lib/form/FormList';
import { ColumnType } from 'antd/lib/table/interface';
import { LocalizationShape } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';


const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

// @ts-ignore
const SortableItem = sortableElement(props => <tr {...props} />);
// @ts-ignore
const SortableContainer = sortableContainer(props => <tbody {...props} />);

type DraggableFormTableArgs = Omit<TableProps<FormListFieldData>,
'components'|'rowKey'|'dataSource'|'columns'> & {
  localization: LocalizationShape;
  showSortLabel?: true;
  columns: ({ hide?: boolean } & ColumnType<FormListFieldData>)[];
};

export const draggableFormTable = (
  {
    showSortLabel,
    columns,
    localization,
    ...restProps
  }: DraggableFormTableArgs,
): FormListProps['children'] => (fields, operation) => {
  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      operation.move(oldIndex, newIndex);
    }
  };

  const DraggableContainer: React.FC = props => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="draggable-table-container-row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow: React.FC<{ 'data-row-key'?: number }> = (props) => (
    <SortableItem
      index={fields.findIndex(f => f.key === props['data-row-key'])}
      {...omit(props, ['className', 'style'])}
    />
  );

  return (
    <Table
      rowKey="key"
      components={{ body: { wrapper: DraggableContainer, row: DraggableBodyRow } }}
      pagination={false}
      showHeader={false}
      dataSource={fields.map(f => ({ ...f, operation }))}
      className="draggable-table"
      columns={[
        {
          // eslint-disable-next-line no-undef
          title: showSortLabel ? localization.formatMessage(Locale.General.Sort) : '',
          dataIndex: 'sort',
          width: 30,
          className: 'draggable-table-container-drag-visible',
          render: () => <DragHandle />,
        },
        ...columns.filter(column => !column.hide).map(column => ({
          ...column,
          className: `${column.className} draggable-table-container-drag-visible`,
        })),
      ]}
      {...restProps}
    />
  );
};

