import { Locale } from '../../../../localization/LocalizationKeys';
import { selectionRowResultFieldConfig } from '../../person/fields/utils/personFieldsUtils';

export const selectionRowResultSelectionTypeFieldConfig = selectionRowResultFieldConfig(
  'selectionType',
  [
    'selectionRow.id',
    'selectionRow.group.id',
    'selectionRow.group.selection.id',
    'selectionRow.group.selection.type',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Selection_type),
    render: record => record.selectionRow?.group.selection.type ?? localization.formatMessage(Locale.General.Unknown),
  }),
);
