import React from 'react';
import { Button, notification, Space } from 'antd';
import { useUpdate } from 'ahooks';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import { Optional, StateArray } from '../../util/StateArrayType';
import {
  EntityMergeHookValue
} from '../../browse/admin/adminComponents/AdminEntityMergeRequest/MergeRequestDetails/useEntityMerge';
import { useCreateMergeRequestFlowMutation, usePerformMergeRequestFlowMutation } from '../../../gql/typings';
import { EntityMergeStepsPage } from './EntityMergeModal';
import { TableRowSelectionReturnProps } from '../Table/useTableRowSelection';
import MergeEntityLink
  from '../../browse/admin/adminComponents/AdminEntityMergeRequest/MergeRequestDetails/Item/components/MergeEntityLink';
import { useEntityRecord, UseEntityRecordSupportedTypes } from '../../hooks/useEntityRecord';

type EntityMergeModalFooterProps = {
  entityType: UseEntityRecordSupportedTypes;
  loserId: Optional<number>;
  winnerId: Optional<number>;
  setId2: (id2: number) => void;
  againstTableSelection: TableRowSelectionReturnProps;
  stepState: StateArray<EntityMergeStepsPage>;
  modalVisibleState: StateArray<boolean>;
  mergeInfo: EntityMergeHookValue;
  onCompleteHook?: () => void;
};

const EntityMergeModalFooter: React.FC<EntityMergeModalFooterProps> = ({
  entityType,
  loserId,
  winnerId,
  setId2,
  againstTableSelection,
  stepState: [step, setStep],
  modalVisibleState: [_, setModalVisible],
  mergeInfo: { loading, emitter: { $, canSave }, commitEmitterState },
  onCompleteHook,
}) => {
  const localization = useLocalization();
  const navigate = useNavigate();
  const reRender = useUpdate();
  const [create, { loading: isCreating }] = useCreateMergeRequestFlowMutation();
  const [perform, { loading: isMerging }] = usePerformMergeRequestFlowMutation();
  const { records } = useEntityRecord(
    entityType,
    [loserId, winnerId].filter(e => e) as number[],
    !!loserId && !!winnerId,
  );
  const [api, contextHolder] = notification.useNotification();

  $.useSubscription(reRender);

  const onOk = () => {
    switch (step) {
      case 'selectAgainst':
        setId2(againstTableSelection.selection.selected[0]!);
        setStep('selectWinner');
        break;
      case 'selectWinner':
        setStep('overview');
        break;
      case 'overview':
        create({
          variables: {
            entityType,
            loserId: loserId!,
            winnerId: winnerId!,
          },
        }).then(req => {
          if ((req.errors?.length ?? 0) === 0) commitEmitterState()
            .then(() => perform({ variables: { mergeRequestId: req.data?.createMergeRequest.id ?? -1 } }))
            .then(() => {
              const loserRecord = records?.filter(r => r.id === loserId)[0];
              const winnerRecord = records?.filter(r => r.id === winnerId)[0];
              if (!loserRecord || !winnerRecord) return Promise.resolve();
              // MARK: We need to pass the [localization] and [navigate] along,
              //       as this is being applied outside all our providers
              const props = { type: entityType, localization, navigate };
              api.success({
                message: localization.formatMessage(Locale.Text.Merge_successful),
                duration: 15,
                description: localization.formatText(Locale.Text.Merge_successful_message, {
                  record1: (<MergeEntityLink id={loserRecord.id} {...props}>{loserRecord.name}</MergeEntityLink>),
                  record2: <MergeEntityLink id={winnerRecord.id} {...props}>{winnerRecord.name}</MergeEntityLink>,
                })
              });
              onCompleteHook?.();
              setModalVisible(false);
            });
        });
        break;
      default:
        break;
    }
  };

  const getDisabledState = (): boolean => {
    switch (step) {
      case 'selectAgainst':
        return againstTableSelection.selection.count === 0;
      case 'selectWinner':
        return !winnerId;
      case 'overview':
        return !canSave();
      default:
        return true;
    }
  };

  return (
    <Space>
      {contextHolder}
      <Button onClick={() => setModalVisible(false)}>
        {localization.formatMessage(Locale.Command.Cancel)}
      </Button>

      <Button
        type="primary"
        onClick={onOk}
        disabled={getDisabledState()}
        loading={loading || isCreating || isMerging}
      >
        {localization.formatMessage(Locale.Command.Continue)}
      </Button>
    </Space>
  );
};

gql`
  mutation CreateMergeRequestFlow($entityType: EntityTypeEnum!, $loserId: ID!, $winnerId: ID!) {
    createMergeRequest(entityType: $entityType, loserId: $loserId, winnerId: $winnerId) {
      id
    }
  }
`;

gql`
  mutation PerformMergeRequestFlow($mergeRequestId: ID!) {
    performMerge(mergeRequestId: $mergeRequestId) {
      hasConflict
      entityMerge {
        id
        from {
          ... on Person {
            id
            isActive
          }
          ... on Site {
            id
            isActive
          }
        }
      }
    }
  }
`;

export default EntityMergeModalFooter;
