import { gql } from '@apollo/client';

export const ManualExternalUserMapping_FRAGMENT = gql`
  fragment ManualExternalUserMappingFragment on ManualExternalUserIdMapping {
    projectId
    externalIds
    externalIdTypeCode
    __typename
  }
`;
export const UsersForUserMapping_QUERY = gql`
  query UsersForUserMapping {
    usersConnection: users {
      hash
      totalCount
      nodes {
        id
        firstname
        lastname
        email
      }
    }
  }
`;
export const LinkUserWithExternalId_MUTATION = gql`
  mutation LinkUserWithExternalId($input: ExternalIdMutationInput!) {
    setExternalId(input: $input) { id }
  }
`;
