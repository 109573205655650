import { gql, useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useLocalization } from '../../../util/useLocalization';
import { usePermission } from '../../../permission/usePermission';
import {
  InactivateLinkActionQuery,
  InactivateLinkActionQueryVariables,
  SetActiveLinkSiteMutation,
  SetActiveLinkSiteMutationVariables,
  SourceEnum
} from '../../../../gql/typings';
import { Locale } from '../../../../localization/LocalizationKeys';
import { ButtonComponentProps } from '../../../components/Button/ApbButton';


type ReturnProps = {
  hidden: React.ReactNode;
  action: NonNullable<ButtonComponentProps['menuItems']>['0'];
  loading: boolean;
};

export const useInactivateLinkAction = (linkId: number): ReturnProps => {
  const localization = useLocalization();
  const navigate = useNavigate();
  const { delete: deletePermission } = usePermission('Link');
  const { data, loading } = useQuery<InactivateLinkActionQuery, InactivateLinkActionQueryVariables>(DATA_QUERY, {
    variables: { linkId },
  });
  const [
    setActivateMutation,
    { loading: isInactivating },
  ] = useMutation<SetActiveLinkSiteMutation, SetActiveLinkSiteMutationVariables>(SET_ACTIVE_MUTATION);

  const inactivate = () => setActivateMutation({ variables: { linkId, isActive: false } })
    .then(() => message.success(localization.formatMessage(Locale.Text.Successfully_inactivated_record)));

  const activate = () => setActivateMutation({ variables: { linkId, isActive: true } })
    .then(res => {
      navigate(`/link/${res.data?.setSitePersonActiveStatus.id}`, { replace: true });
      message.success(localization.formatMessage(Locale.Text.Successfully_activated_record));
    });

  const isActive = data?.sitePersonAffiliation?.isActive ?? false;

  return {
    loading: loading || isInactivating,
    action: {
      key: 'delete-record',
      hide: data?.sitePersonAffiliation?.maintainerSourceCode !== SourceEnum.LOCAL || !deletePermission,
      label: isActive
        ? localization.formatMessage(Locale.Command.Inactivate_record)
        : localization.formatMessage(Locale.Command.Activate_record),
      onClick: () => isActive ? inactivate() : activate(),
    },
    hidden: <></>,
  };
};

const SET_ACTIVE_MUTATION = gql`
  mutation SetActiveLinkSite($linkId: Int!, $isActive: Boolean!) {
    setSitePersonActiveStatus(id: $linkId, isActive: $isActive) {
      id
      isActive
    }
  }
`;

const DATA_QUERY = gql`
  query InactivateLinkAction($linkId: Int!) {
    sitePersonAffiliation(id: $linkId) {
      id
      isActive
      maintainerSourceCode
    }
  }
`;
