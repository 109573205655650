import React from 'react';
import { Modal } from 'antd';
import Button from '../Input/Button';


type PopupProps = {
  title?: React.ReactNode;

  acceptLabel?: string;
  acceptLoading?: boolean;
  acceptDisabled?: boolean;
  onAccept?: () => void;

  cancelLabel?: string;
  cancelLoading?: boolean;
  cancelDisabled?: boolean;
  onClose?: () => void;

  visible?: boolean;
  className?: string;
  wrapClassName?: string;
  size?: string|number;
  top?: string|number;
  centered?: boolean;
  customFooter?: React.ReactNode;
};

const Popup: React.FC<PopupProps> = ({
  title,
  visible = false,
  children,
  className,
  wrapClassName,
  onClose = () => {},
  onAccept = () => {},
  size = '40%',
  top = 100,
  acceptLabel = 'Yes',
  acceptLoading = false,
  acceptDisabled = false,
  cancelLabel = 'No',
  cancelLoading = false,
  cancelDisabled = false,
  centered = false,
  customFooter = (
    <span>
      <Button
        label={acceptLabel}
        onClick={onAccept}
        loading={acceptLoading}
        disabled={acceptDisabled}
      />
      <Button
        label={cancelLabel}
        color="light"
        onClick={onClose}
        loading={cancelLoading}
        disabled={cancelDisabled}
      />
    </span>
  ),
}) => (
  <Modal
    centered={centered}
    closable={false}
    title={title}
    open={visible}
    onCancel={onClose}
    bodyStyle={{
      maxHeight: 750,
      overflow: 'auto',
    }}
    destroyOnClose
    className={className}
    wrapClassName={wrapClassName}
    width={size}
    style={{ top: centered ? 0 : top }}
    footer={customFooter}
  >
    {children}
  </Modal>
);


export default Popup;
