import React from 'react';
import { gql } from '@apollo/client';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';

const AdminMergeItemPersonGdpr: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const localization = useLocalization();

  return (
    <AdminMergeItemBasicField {...props}>
      {localization.formatMessage(Locale.Text.Merge_GDPR_set_to_true_description)}
    </AdminMergeItemBasicField>
  );
};


gql`
  fragment MergeItemPersonGdpr on PersonGdpr_EntityDataToBeTransferredType {
    code
    newGdprValue
  }
`;

export default AdminMergeItemPersonGdpr;
