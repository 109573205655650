import React from 'react';
import { gql } from '@apollo/client/core';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { TableConfig, TableFieldsConfig } from '../../search_old/types';
import GitlabPipelineIcon from './GitlabPipelineIcon/GitlabPipelineIcon';

export const buildInstanceDeploymentTableFragment = (additionalQuery: string|null) => gql`
  fragment InstanceDeploymentTableFragment on PureAdvanceCustomer {
    code
    ${additionalQuery ?? ''}
  }
`;

export const buildInstanceDeploymentConnectionDataQuery = (additionalQuery: string|null) => gql`
  query InstanceDeploymentQuery($criteria: PureAdvanceCustomerCriteria) {
    connection: pureAdvanceCustomers(criteria: $criteria) {
      hash
      totalCount
      nodes {
        ...InstanceDeploymentTableFragment
      }
    }
  }
  ${buildInstanceDeploymentTableFragment(additionalQuery)}
`;

export const buildInstanceDeploymentRecordDataQuery = (additionalQuery: string|null) => gql`
  query InstanceDeploymentRecordQuery($recordId: String!) {
    record: pureAdvanceCustomer(customerCode: $recordId) {
      code
      ...InstanceDeploymentTableFragment
    }
  }
  ${buildInstanceDeploymentTableFragment(additionalQuery)}
`;

export type SupportedInstanceDeploymentTableFields = 'code'|'version'|'dataSync'|'dcrSync';

export const supportedInstanceDeploymentTableFields: TableFieldsConfig<SupportedInstanceDeploymentTableFields>['fields'] = {
  code: props => ({
    key: 'code',
    queryFields: ['code'],
    title: props.localization.formatMessage(Locale.Attribute.Name),
    render: (record) => (
      <LinkBadge
        url={`/admin/client/${record.code}`}
        label={record.code}
        openInNewTab={props.openInNewTab}
      />
    ),
  }),
  dataSync: () => ({
    key: 'dataSync',
    queryFields: [
      'dataSyncSchedule.id',
      'dataSyncSchedule.next_run_at',
      'dataSyncSchedule.last_pipeline.id',
      'dataSyncSchedule.last_pipeline.status',
      'dataSyncSchedule.last_pipeline.created_at',
      'dataSyncSchedule.last_pipeline.duration',
    ],
    title: 'Data Sync',
    render: (record) => <GitlabPipelineIcon schedule={record.dataSyncSchedule} />,
  }),
  dcrSync: () => ({
    key: 'dcrSync',
    queryFields: [
      'dcrSyncSchedule.id',
      'dcrSyncSchedule.next_run_at',
      'dcrSyncSchedule.last_pipeline.id',
      'dcrSyncSchedule.last_pipeline.status',
      'dcrSyncSchedule.last_pipeline.created_at',
      'dcrSyncSchedule.last_pipeline.duration',
    ],
    title: 'DCR Sync',
    render: (record) => <GitlabPipelineIcon schedule={record.dcrSyncSchedule} />,

  }),
  version: props => ({
    key: 'version',
    queryFields: ['dockerService.ID', 'dockerService.imageVersion'],
    title: props.localization.formatMessageByStr('Version'),
    render: (record) => record?.dockerService?.imageVersion,
  }),
};

export const InstanceDeploymentsTableConfig: TableConfig = {
  buildConnectionQuery: buildInstanceDeploymentConnectionDataQuery,
  buildRecordQuery: buildInstanceDeploymentRecordDataQuery,
  buildFragment: buildInstanceDeploymentTableFragment,
  titleLabel: Locale.Attribute.Client_ID,
  columnConfig: {
    fields: supportedInstanceDeploymentTableFields,
  }
};
