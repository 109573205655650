import { useQuery, gql } from '@apollo/client';
import React from 'react';
import { debounce } from 'lodash';
import { Select } from 'antd';
import { useIntl } from 'react-intl';
import { Locale } from '../../../../localization/LocalizationKeys';
import { SelectPersonTypeSuggestionQueryQuery, SelectPersonTypeSuggestionQueryQueryVariables } from '../../../../gql/typings';

type SelectPersonTypeProps = {
  value?: string;
  onChange?: (value: string) => void;
  countryCode?: string;
  disabled?: boolean;
};

const SelectPersonType: React.FC<SelectPersonTypeProps> = ({
  value,
  onChange,
  disabled,
  countryCode,
}) => {
  const intl = useIntl();
  const {
    data: suggestionData,
    refetch: refetchSuggestions,
    loading: suggestionsLoading,
  } = useQuery<SelectPersonTypeSuggestionQueryQuery, SelectPersonTypeSuggestionQueryQueryVariables>(
    SUGGESTIONS_QUERY,
    { variables: { countries: countryCode ? [countryCode] : [], search: '%' } },
  );
  const searchDebounced = debounce(
    search => {
      const input: SelectPersonTypeSuggestionQueryQueryVariables = { search: `%${search}%` };
      if (countryCode) input.countries = [countryCode];
      return refetchSuggestions(input);
    },
    25,
  );

  return (
    <Select
      placeholder={intl.formatMessage(Locale.Command.Select_person_type)}
      loading={suggestionsLoading}
      showSearch
      onSearch={searchDebounced}
      value={value as string|undefined}
      filterOption={false}
      onChange={e => onChange?.(e)}
      disabled={disabled}
      allowClear
    >
      {(suggestionData?.personTypes.nodes ?? []).map(pt => (
        <Select.Option key={pt!.code} value={pt!.code}>
          {pt!.label}
        </Select.Option>
      ))}
    </Select>
  );
};

const SUGGESTIONS_QUERY = gql`
  query SelectPersonTypeSuggestionQuery($search: String, $countries: [CountryCode!]) {
    personTypes(criteria: { label: $search, countries: $countries, fetchSize: { limit: 1000 } }) {
      hash
      nodes {
        code
        label: displayLabel
        type
      }
    }
  }
`;


export default SelectPersonType;
