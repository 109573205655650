import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { find, partition } from 'lodash';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

export type UrlTabProps = {
  path: string;
  title: string;
  render: React.FC;
};

type UrlTabsProps = {
  /**
   * This path needs to be the same as the url path this is on!
   */
  path: string;
  loading?: boolean;
  items: (false|UrlTabProps)[];
};

function urls(paths: string[], id: string): [string[], string[]] {
  let found = false;
  return partition(paths, (lp) => {
    if (id === lp) {
      found = true;
      return true;
    }
    return !found;
  });
}

const UrlTabs: React.FC<UrlTabsProps> = ({ loading, path: id, items }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationPaths = location.pathname.split('/');

  const index = locationPaths.indexOf(id);
  const selected = locationPaths[index + 1];

  useEffect(() => {
    if (loading) return;
    // If selected is not in the url, we'll inject it right away.
    if (index !== -1 && items.filter(e => e).map(e => (e as UrlTabProps).path).indexOf(selected ?? '_#_') === -1) {
      const [before, after] = urls(locationPaths, id);
      navigate([
        ...before,
        // Return the first non-false tab
        (find(items, e => e) as UrlTabProps)?.path,
        ...after,
      ].join('/'), { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, selected]);

  const Renderer: React.FC<{ toRender: React.FC }> = ({ toRender }) => (
    <>
      {toRender({})}
    </>
  );

  return (
    <Tabs
      type="card"
      size="large"
      activeKey={selected}
      onChange={selected => {
        const [before] = urls(locationPaths, id);
        navigate([...before, selected].join('/'));
      }}
      items={items.filter(e => e).map(item => {
        if (!item || item.path === id) throw Error('You are not allowed to use the same path as the root path provided');
        return {
          key: item.path,
          label: item.title,
          children: <Renderer toRender={item.render} />
        };
      })}
    />
  );
};


export default UrlTabs;
