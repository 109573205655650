import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, List, Typography } from 'antd';
import { useLocalization } from '../../../../../util/useLocalization';
import Icon, { ITE } from '../../../../../components/Icon/Icon';
import CustomFieldIcon from '../../../customFields/components/CustomFieldIcon';
import { SELECTED_GRID_ITEM_CONFIGURATION_FRAGMENT } from './AdminGridRightFieldsQueryFragments';
import {
  AddFieldToFieldsGridMutationMutation,
  AddFieldToFieldsGridMutationMutationVariables,
  EntityGridItemFieldTypeEnum,
  EntityTypeEnum,
  LinkCustomFieldMutationMutation,
  LinkCustomFieldMutationMutationVariables,
  ListAllCustomFieldTypesQueryQuery,
  ListAllCustomFieldTypesQueryQueryVariables
} from '../../../../../../gql/typings';

type AdminGridRightAddFieldToFieldsConfiguratorProps = {
  tabCode: string;
  itemConfigurationId: number;
  entityType: EntityTypeEnum;
};

const icons: Record<EntityGridItemFieldTypeEnum, ITE> = {
  HCP_CONSENT: 'CONSENT',
  HCP_SEGMENTATION: 'SEGMENTATION',
  HCP_EXTERNAL_IDS: 'EXTERNAL_ID',
  HCP_TAGS: 'TAGS',
  HCP_RES_AREAS: 'RES_USERS',
  HCP_CUSTOM_FIELD: 'CUSTOM_FIELD',
  HCO_CUSTOM_FIELD: 'CUSTOM_FIELD',
  LINK_CUSTOM_FIELD: 'CUSTOM_FIELD',
  HCO_EXTERNAL_IDS: 'EXTERNAL_ID',
  HCO_SEGMENTATION: 'SEGMENTATION',
  HCO_TAGS: 'TAGS',
};

const AdminGridRightAddFieldToFieldsConfigurator: React.FC<AdminGridRightAddFieldToFieldsConfiguratorProps> = ({
  tabCode,
  entityType,
  itemConfigurationId,
}) => {
  const localization = useLocalization();
  const {
    data,
    refetch,
    loading,
  } = useQuery<ListAllCustomFieldTypesQueryQuery, ListAllCustomFieldTypesQueryQueryVariables>(ALL_FIELD_TYPES_QUERY, {
    variables: { tabCode, entityType },
  });
  const [
    addMutation,
    { loading: isAdding },
  ] = useMutation<AddFieldToFieldsGridMutationMutation, AddFieldToFieldsGridMutationMutationVariables>(ADD_MUTATION);
  const [
    linkMutation,
    { loading: isLinking },
  ] = useMutation<LinkCustomFieldMutationMutation, LinkCustomFieldMutationMutationVariables>(LINK_CUSTOM_FIELD_MUTATION);

  const add = (type: EntityGridItemFieldTypeEnum) => addMutation({
    variables: {
      groupId: itemConfigurationId,
      fieldType: type,
    }
  }).then(res => res.data!.assignFieldToGroup);

  const link = (customFieldCode: string) => {
    if (entityType == EntityTypeEnum.PERSON
      || entityType === EntityTypeEnum.SITE
      || entityType === EntityTypeEnum.AFFILIATION) {
      return add(entityType === EntityTypeEnum.PERSON
        ? EntityGridItemFieldTypeEnum.HCP_CUSTOM_FIELD
        : EntityGridItemFieldTypeEnum.HCO_CUSTOM_FIELD).then(res => linkMutation({
        variables: {
          fieldId: res.id,
          customFieldCode,
        }
      }));
    }
    return Promise.reject();
  };

  return (
    <div>
      <Typography.Text>
        You can find more fields to add to this card below.
      </Typography.Text>

      <Typography.Title level={5}>aPureBase suggested fields</Typography.Title>
      <List<NonNullable<ListAllCustomFieldTypesQueryQuery['entityGridItemFieldTypes']>['nodes']['0']>
        dataSource={data?.entityGridItemFieldTypes.nodes.filter(n => ![
          EntityGridItemFieldTypeEnum.HCO_CUSTOM_FIELD,
          EntityGridItemFieldTypeEnum.HCP_CUSTOM_FIELD,
          EntityGridItemFieldTypeEnum.LINK_CUSTOM_FIELD,
        ].includes(n.enum)) ?? []}
        renderItem={row => (
          <List.Item actions={[
            <Button
              key="add"
              type={row.isInTab ? 'default' : 'primary'}
              loading={loading || isAdding || isLinking}
              onClick={() => add(row.enum).finally(refetch)}
            >
              Add
            </Button>,
          ]}
          >
            <List.Item.Meta
              avatar={<Icon iconType={icons[row.enum]} />}
              title={localization.formatMessageByStr(row.heading)}
              description={row.description ? localization.formatMessageByStr(row.description) : undefined}
            />
          </List.Item>
        )}
      />

      <Typography.Title level={5}>Previously created fields</Typography.Title>
      <List<NonNullable<ListAllCustomFieldTypesQueryQuery['customFields']>['nodes']['0']>
        dataSource={data?.customFields.nodes ?? []}
        renderItem={row => (
          <List.Item
            actions={[
              <Button
                key="add"
                type="primary"
                loading={loading || isAdding || isLinking}
                onClick={() => link(row.code).finally(refetch)}
              >
                Add
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={<CustomFieldIcon type={row.fieldType.enum} />}
              title={row.heading}
              description={row.description}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

const ADD_MUTATION = gql`
  mutation AddFieldToFieldsGridMutation($fieldType: EntityGridItemFieldTypeEnum!, $groupId: Int!) {
    assignFieldToGroup(fieldType: $fieldType, groupId: $groupId) {
      id
      itemConfiguration {
        id
        ...SelectedGridItemConfigurationFragment
      }
    }
  }
  ${SELECTED_GRID_ITEM_CONFIGURATION_FRAGMENT}
`;

const LINK_CUSTOM_FIELD_MUTATION = gql`
  mutation LinkCustomFieldMutation($customFieldCode: String!, $fieldId: Int!) {
    linkEntityGridFieldToCustomField(customFieldCode: $customFieldCode, fieldId: $fieldId) {
      id
      fieldConfig {
        id
        itemConfiguration {
          id
          ...SelectedGridItemConfigurationFragment
        }
      }
    }
  }
  ${SELECTED_GRID_ITEM_CONFIGURATION_FRAGMENT}
`;

export const ALL_FIELD_TYPES_QUERY = gql`
  query ListAllCustomFieldTypesQuery($tabCode: String!, $entityType: EntityTypeEnum!) {
    entityGridItemFieldTypes(criteria: { entityType: $entityType }) {
      hash
      nodes {
        code
        enum
        heading
        description
        isInTab(tabCode: $tabCode)
      }
    }
    customFields(criteria: { entityType: $entityType, excludeAlreadyAddedOnEntityTabCode: $tabCode }) {
      hash
      nodes {
        code
        heading
        description
        fieldType {
          code
          enum
        }
      }
    }
  }
`;

export default AdminGridRightAddFieldToFieldsConfigurator;
