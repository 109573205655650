import React from 'react';
import { gql } from '@apollo/client';
import { Input } from 'antd';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import {
  GenderEnum,
  MergeItemPersonGenderFragment,
  useUpdatePersonSexMutation
} from '../../../../../../../../../gql/typings';
import { Optional } from '../../../../../../../../util/StateArrayType';

const map: Record<GenderEnum, { id: string }|null> = {
  [GenderEnum.FEMALE]: Locale.General.Female,
  [GenderEnum.MALE]: Locale.General.Male,
  [GenderEnum.UNKNOWN]: null,
};

const AdminMergeItemPersonSex: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonGenderFragment;
  const localization = useLocalization();
  const [updateTitle] = useUpdatePersonSexMutation();

  const genderToString = (value: Optional<GenderEnum>) => {
    const key = value ? map[value] : null;
    return key ? localization.formatMessage(key) : '';
  };


  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.fromGender}
      toValue={info.toGender}
      valueDisplayMapper={genderToString}
      updateMutation={newValue => updateTitle({
        variables: {
          personId: props.into.id,
          newValue,
        },
      })}
      inputRenderer={({ value }) => <Input value={genderToString(value)} disabled />}
    >
      {localization.formatMessage(Locale.Text.Merge_request_gender_mismatch_description, {
        gender1: <strong>{genderToString(info.toGender)}</strong>,
        gender2: <strong>{genderToString(info.fromGender)}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation UpdatePersonSex($personId: Int!, $newValue: GenderEnum!) {
    updatePersonSex(persId: $personId, sex: $newValue) {
      id
      title
    }
  }
`;

gql`
  fragment MergeItemPersonGender on PersonGender_EntityDataToBeTransferredType {
    code
    fromGender: from
    toGender: to
  }
`;

export default AdminMergeItemPersonSex;
