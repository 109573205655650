import React from 'react';
import * as PropTypes from 'prop-types';

const AddButton = ({
  label,
  onClick = () => {},
}) => (
  <div className="add-button-container btn-add-criteria" onClick={onClick}>
    <div className="add-btn" />
    {label && (<span>{label}</span>)}
  </div>
);

// const AddButton = ({
//   label,
//   form,
//   items,
//   onAdd,
//   dropdownActive,
//   onDropdownVisibleChange,
// }) => (
//   <div className="add-button-container btn-add-criteria">
//     <Dropdown
//       form={form}
//       items={items}
//       onSelect={onAdd}
//       active={dropdownActive}
//       onVisibleChange={onDropdownVisibleChange}
//     >
//       <div className="add-btn" />
//       {label && (<span>{label}</span>)}
//     </Dropdown>
//   </div>
// );

AddButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
};

AddButton.defaultProps = {
  label: undefined,
  onClick: () => {},
};

export default AddButton;
