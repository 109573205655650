import React from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Empty,
  InputNumber,
  message,
  Row,
  Select,
  Typography
} from 'antd';
import { omit } from 'lodash';
import { useLocalization } from '../../../../util/useLocalization';
import AddCard from '../../../../components/ContactCard/AddCard';
import { PermissionInfo } from '../../../../permission/permissionUtils';
import {
  ActiveEnum,
  ActivityTypesForConfigurationQuery,
  ActivityTypesForConfigurationQueryVariables,
  AttachmentRuleEnum,
  UpdateActivityTypeMutationMutation,
  useUpdateActivityTypeMutationMutation
} from '../../../../../gql/typings';
import ConfigureCustomFieldsActivityType from './ConfigureCustomFieldsActivityType';
import { CONFIGURE_ACTIVITY_TYPE_FRAGMENT } from './ConfigureActivityTypesFragments';

const ConfigureActivityTypes: React.FC<{ permission: PermissionInfo }> = ({ permission }) => {
  const localization = useLocalization();
  const { data } = useQuery<ActivityTypesForConfigurationQuery, ActivityTypesForConfigurationQueryVariables>(DATA_QUERY, {
    variables: { includeActive: permission.update ? ActiveEnum.BOTH : ActiveEnum.ACTIVE },
  });
  const [update, { loading }] = useUpdateActivityTypeMutationMutation();

  const doUpdate = (updated: NonNullable<UpdateActivityTypeMutationMutation['updateActivityType']>) => update({
    variables: {
      typeCode: updated.code,
      // @ts-ignore
      input: {
        ...omit(updated, ['code', '__typename', 'color']),
        colorCode: updated.color.code,
      },
    }
  }).then(() => message.success('Update successful'));

  const RuleSelect: React.FC<{
    heading: string;
    value: AttachmentRuleEnum;
    onChange: (e: AttachmentRuleEnum) => void;
  }> = ({ heading, value, onChange }) => (
    <>
      <Col span={8}>{heading}:</Col>
      <Col span={16}>
        <Select
          value={value}
          onChange={onChange}
          style={{ width: '100%' }}
          disabled={!permission.update || loading}
          loading={loading}
        >
          <Select.Option value={AttachmentRuleEnum.NONE}>None</Select.Option>
          <Select.Option value={AttachmentRuleEnum.OPTIONAL_ONE}>Optional One</Select.Option>
          <Select.Option value={AttachmentRuleEnum.ONE}>One</Select.Option>
          <Select.Option value={AttachmentRuleEnum.OPTIONAL_MANY}>Optional Many</Select.Option>
          <Select.Option value={AttachmentRuleEnum.MANY}>Many</Select.Option>
        </Select>
      </Col>
    </>
  );

  return (
    <div>
      <Alert message="This page is currently under construction" />
      <Typography.Paragraph>
        Here you can create and modify activity type definition & rules. Activity types are grouped under a activity super type.
      </Typography.Paragraph>
      <Collapse accordion expandIconPosition="end">
        {(data?.activitySuperTypes.nodes ?? []).map(superType => (
          <Collapse.Panel
            key={superType.code}
            header={superType.heading}
            extra={<Badge style={{ backgroundColor: 'green' }} count={superType.types.totalCount} showZero />}
          >
            <Row gutter={[24, 24]}>
              {superType.types.totalCount === 0 && (
                <div style={{ width: '100%' }}>
                  <Empty description={`No Activity types under super type '${superType.heading}'`}>
                    <Button type="primary">Create Activity Type</Button>
                  </Empty>
                </div>
              )}
              {superType.types.nodes.map(type => (
                <Col key={type.code} span={8}>
                  <Badge.Ribbon
                    key={type.code}
                    text={localization.formatMessageByStr(type.color.headingKey)}
                    color={type.color.value}
                  >
                    <Card
                      key={type.code}
                      title={
                        <Typography.Text
                          editable={permission.update && !loading && { onChange: heading => doUpdate({ ...type, heading }) }}
                        >
                          {type.heading}
                        </Typography.Text>
                      }
                    >
                      <Row>
                        <Col span={24}>
                          <Typography.Text
                            editable={permission.update && {
                              onChange: description => doUpdate({ ...type, description }),
                            }}
                          >
                            {type.description}
                          </Typography.Text>
                        </Col>
                        {permission.update && <>
                          <Col span={8}>
                            Enabled:
                          </Col>
                          <Col span={16}>
                            <Checkbox
                              checked={type.isActive}
                              disabled={!permission.update || loading}
                              onChange={e => doUpdate({ ...type, isActive: e.target.checked })}
                            />
                          </Col>
                        </>}
                        <Col span={8}>
                          Standard Time:
                        </Col>
                        <Col span={16}>
                          <InputNumber
                            min={0}
                            value={type.standardTimeLengthMinutes}
                            disabled={!permission.update || loading}
                            onChange={standardTimeLengthMinutes => doUpdate({
                              ...type,
                              standardTimeLengthMinutes: standardTimeLengthMinutes as number,
                            })}
                          />
                        </Col>
                        <RuleSelect
                          heading="Brand"
                          value={type.brandRule}
                          onChange={(r) => doUpdate({ ...type, brandRule: r })}
                        />
                        <RuleSelect
                          heading="Location"
                          value={type.locationRule}
                          onChange={(r) => doUpdate({ ...type, locationRule: r })}
                        />
                        <RuleSelect
                          heading="Participation User"
                          value={type.participationUserRule}
                          onChange={(r) => doUpdate({ ...type, participationUserRule: r })}
                        />
                        <RuleSelect
                          heading="Person"
                          value={type.personRule}
                          onChange={(r) => doUpdate({ ...type, personRule: r })}
                        />
                        <RuleSelect
                          heading="Product"
                          value={type.productRule}
                          onChange={(r) => doUpdate({ ...type, productRule: r })}
                        />
                        <RuleSelect
                          heading="Project"
                          value={type.projectRule}
                          onChange={(r) => doUpdate({ ...type, projectRule: r })}
                        />
                        <RuleSelect
                          heading="Responsible User"
                          value={type.responsibleUserRule}
                          onChange={(r) => doUpdate({ ...type, responsibleUserRule: r })}
                        />
                        <ConfigureCustomFieldsActivityType
                          activityType={type}
                        />
                      </Row>
                    </Card>
                  </Badge.Ribbon>
                </Col>
              ))}
              {superType.types.totalCount > 0 && (
                <Col span={8} style={{ alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
                  <AddCard
                    label="Create New"
                    onClick={() => message.warning('This functionality is currently not supported')}
                  />
                </Col>
              )}
            </Row>
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

const DATA_QUERY = gql`
  query ActivityTypesForConfiguration($includeActive: ActiveEnum!) {
    activitySuperTypes {
      hash
      nodes {
        code
        heading
        types(criteria: { isActive: $includeActive }) {
          hash
          totalCount
          nodes { ...ActivityTypeMutationFragment }
        }
      }
    }
  }
  ${CONFIGURE_ACTIVITY_TYPE_FRAGMENT}
`;

gql`
  mutation UpdateActivityTypeMutation($typeCode: String!, $input: UpdateActivityTypeInput!) {
    updateActivityType(typeCode: $typeCode, input: $input) {
      ...ActivityTypeMutationFragment
    }
  }
  ${CONFIGURE_ACTIVITY_TYPE_FRAGMENT}
`;

export default ConfigureActivityTypes;
