import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, message, Popover, Row, Space, Tooltip } from 'antd';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import Icon from '../../components/Icon/Icon';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import { ClearHopMutationMutation, ClearHopMutationMutationVariables, DcrGroupDetailQuery } from '../../../gql/typings';


export type SiteDcrRenderProps = {
  requestType: NonNullable<DcrGroupDetailQuery['dcrGroup']>['dcrs']['nodes']['0']['requestType'];
  entity: { __typename?: 'Site'; name: string; id: number };
  renderButton: boolean;
};

const SiteDcrRender: React.FC<SiteDcrRenderProps> = ({ renderButton, entity, requestType }) => {
  const localization = useLocalization();
  const [visible, setVisible] = useState<boolean>(false);
  const [
    inactivateSite,
  ] = useMutation<ClearHopMutationMutation, ClearHopMutationMutationVariables>(CLEAR_HCO_DCR_MUTATION);
  const onSubmit = (hcoId: number, fullName: string) => {
    inactivateSite({
      variables: {
        hcoId,

      },

    }).then(() => setVisible(false))
      .finally(() => message.success(`${fullName} has been removed`));

  };

  return (
    <Space>
      <div>
        <Icon
          iconType="SITE"
          text={
            <Tooltip title={localization.formatMessage(Locale.Text.DCR_request_type_info, { requestType: requestType.code })}>
              <NavLink className="dcr-heading-link" data-record-id={entity.id} to={`/hco/${entity.id}`}>
                <span>{entity.name}</span>
              </NavLink>
            </Tooltip>
          }
        />

        {renderButton
        && <Popover
          open={visible}
          content={<Row gutter={[6, 6]}>
            <Col span={24}> This will remove the HCO from the Pure Advance Instance</Col>

            <Col span={24}>
              <Button size="small" onClick={() => onSubmit(entity.id, entity.name)} type="primary">Yes, Remove</Button>
              <Button style={{ marginLeft: 5 }} onClick={() => setVisible(false)} size="small">No</Button>
            </Col>
          </Row>}
          title="Remove HCO?"
          trigger="click"
        >
          <Button onClick={() => setVisible(true)} type="primary">Clear Affiliation</Button>
        </Popover>}
      </div>
    </Space>
  );
};


export default SiteDcrRender;

const CLEAR_HCO_DCR_MUTATION = gql`
  mutation ClearHopMutation($hcoId: Int!) {
    setSiteActiveStatus(siteId: $hcoId, isActive: false){
      id
      isActive
    }
  }
`;
