import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import DashboardCard, { DashboardCardTitle } from '../../DashboardCard';
import { DashboardItemProps } from '../../DashboardItemTypes';
import DashboardItemPieSettings from './DashboardItemPieSettings';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { generateMessageObject } from '../../../../util/generateMessageObject';
import { PieChartDataItem } from '../../../Charts';
import DashboardPieFullscreen from './DashboardPieFullscreen';
import EntityPieCharts, { EntityPieChartsProps } from './EntityPieCharts';
import { useDashboardPieHook } from './useDashboardPieHook';
import { PieDataQueryQuery, PieDataQueryQueryVariables, PieLevelDataQueryQuery } from '../../../../../gql/typings';


export type EntityPieListItem = {
  selected?: PieChartDataItem;
  data?: PieLevelDataQueryQuery;
};

const DashboardPieItem: React.FC<DashboardItemProps> = (props) => {
  const intl = useIntl();
  const { data, refetch } = useQuery<PieDataQueryQuery, PieDataQueryQueryVariables>(DATA_QUERY, {
    variables: { configurationId: props.configuration.id },
  });
  const editModeState = useState(props.recentlyCreatedId === props.configuration.id);
  const fullscreenState = useState(false);
  const [editMode, setEditMode] = editModeState;

  const maxConfigurations = data?.dashboardEntityPieSettings.length ?? 1;

  const settings = data?.dashboardEntityPieSettings as PieDataQueryQuery['dashboardEntityPieSettings'];


  const pie = useDashboardPieHook({
    configurationId: props.configuration.id,
    maxConfigurations,
    settings
  });


  const piesProps: EntityPieChartsProps = {
    configuration: props.configuration,
    editMode,
    size: props.size,
    pie,
    maxConfigurations,
  };

  const breadcrumbs: DashboardCardTitle = editMode || !data?.dashboardEntityPieSettings[0]
    ? intl.formatMessage(Locale.Text.Configure_Dashboard_Chart)
    : pie.listData.filter((_, index) => index < data?.dashboardEntityPieSettings.length).map((item, index) => (
      {
        label: generateMessageObject(item.selected?.label
          ?? settings![index]?.fieldHeadingKey
          ?? settings![index]!.fieldHeading),
        onClick: () => pie.remove(index),
      }
    ));

  return (
    <DashboardCard
      id={props.dashboardItemId}
      editMode={props.editStatus}
      editItemState={editModeState}
      fullscreenState={fullscreenState}
      fullscreen={data?.dashboardEntityPieSettings?.[0]?.entityType ? <DashboardPieFullscreen
        {...piesProps}
        entityType={data.dashboardEntityPieSettings[0].entityType}
        breadcrumbs={breadcrumbs}
      /> : <></>}
      title={breadcrumbs}
    >
      {data?.dashboardEntityPieSettings.length === 0 && !editMode && intl.formatMessage(
        Locale.Text.Dashboard_item_needs_configuration
      )}
      {editMode && settings && <DashboardItemPieSettings
        {...props}
        initialSettings={settings}
        onSaved={() => {
          setEditMode(false);
          return refetch();
        }}
      />}
      <EntityPieCharts {...piesProps} />
    </DashboardCard>
  );
};

const DATA_QUERY = gql`
  query PieDataQuery($configurationId: Int!) {
    dashboardEntityPieSettings(dashboardItemConfigurationId: $configurationId) {
      id
      entityType
      fieldCode
      customFieldCode
      fieldHeading
      fieldHeadingKey
    }
  }
`;

export default DashboardPieItem;
