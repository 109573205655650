import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client/core';
import { MutationTuple, useQuery } from '@apollo/client';
import { Input } from 'antd';
import UserAdminCard from './UserAdminCard';
import { Locale } from '../../../../../localization/LocalizationKeys';
import UserAdminCardRowItem from './UserAdminCardRowItem';
import {
  AdminUserQueryQuery,
  UpdateUserMutationMutation,
  UpdateUserMutationMutationVariables,
  UserAdminGeneralQueryQuery, UserAdminGeneralQueryQueryVariables
} from '../../../../../gql/typings';


type UserAdminGeneralDetailsProps = {
  updateUser: MutationTuple<UpdateUserMutationMutation, UpdateUserMutationMutationVariables>[0];
  user: NonNullable<AdminUserQueryQuery['user']>;
  allowUpdate: boolean;
};

const UserAdminGeneralDetails: React.FC<UserAdminGeneralDetailsProps> = ({ user, updateUser, allowUpdate }) => {
  const { data } = useQuery<UserAdminGeneralQueryQuery, UserAdminGeneralQueryQueryVariables>(DATA_QUERY, {
    variables: { userId: user.id },
  });
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    setFirstName(data?.user?.firstname ?? '');
    setLastName(data?.user?.lastname ?? '');
  }, [data]);

  return (
    <UserAdminCard title="Details" icon="USER">
      <UserAdminCardRowItem label={Locale.Attribute.Title}>
        <i><strong>TBD</strong></i>
      </UserAdminCardRowItem>
      <UserAdminCardRowItem label={Locale.Attribute.First_name}>
        {!allowUpdate
          ? data?.user?.firstname
          : (
            <Input
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              onBlur={() => updateUser({ variables: { input: { userId: user.id, firstName } } })}
            />
          )}
      </UserAdminCardRowItem>
      <UserAdminCardRowItem label={Locale.Attribute.Last_name}>
        {!allowUpdate
          ? data?.user?.lastname
          : (
            <Input
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              onBlur={() => updateUser({ variables: { input: { userId: user.id, lastName } } })}
            />
          )}
      </UserAdminCardRowItem>
      <UserAdminCardRowItem label={Locale.Attribute.Countries}>
        {data?.user?.countries.nodes.map(c => c.label).join(',')}
      </UserAdminCardRowItem>
      <UserAdminCardRowItem label={{ id: 'Timezone' }}>
        <i><strong>TBD</strong></i>
      </UserAdminCardRowItem>
    </UserAdminCard>
  );
};

const DATA_QUERY = gql`
  query UserAdminGeneralQuery($userId: Int!) {
    user(userId: $userId) {
      id
      firstname
      lastname
      email
      countries {
        hash
        nodes {
          code
          label
        }
      }
    }
  }
`;

export default UserAdminGeneralDetails;
