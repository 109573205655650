import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Alert, Skeleton, Timeline } from 'antd';
import moment from 'moment';
import { ClockCircleOutlined } from '@ant-design/icons';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import Icon from '../Icon/Icon';
import { usePermission } from '../../permission/usePermission';
import { EntityTypeEnum, validationLogHistoryQuery, validationLogHistoryQueryVariables } from '../../../gql/typings';
import { DATE_FORMAT } from '../../util/format';

type DataValidationHistoryLogProps = {
  id?: number;
  entity?: EntityTypeEnum;
};


const DataValidationHistoryLog: React.FC<DataValidationHistoryLogProps> = ({ id, entity }) => {
  const localize = useLocalization();
  const { read } = usePermission('VALIDATE_RECORD');

  const {
    data,
    refetch,
    loading
  } = useQuery<validationLogHistoryQuery, validationLogHistoryQueryVariables>(DATA_QUERY, {
    skip: !read || !id || !entity,
    variables: {
      id: id ?? -1,
      entityType: entity ?? EntityTypeEnum.PERSON,
    },
  });
  if (id === undefined || entity === undefined) {
    return <></>;
  }
  if (entity === EntityTypeEnum.POSITION) {
    return <></>;
  }

  useEffect(() => {
    if (!loading) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className="data-validation-history-log-container">
      <Skeleton loading={loading}>
        <div style={{ marginTop: '20px', marginLeft: '5px' }} className="text">
          <Icon
            style={{ fontSize: 20 }}
            iconType="VALIDATE"
            textClassName="title"
            text={localize.formatMessage(Locale.Text.Validation_Logs)}
          />
        </div>
        <div className="timeline-log">
          <Timeline items={data?.entityValidationLogsByValues.map(logItem => (
            {
              key: logItem.id,
              dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
              children: (
                <>
                  <b>{moment(logItem.validatedTime).format(DATE_FORMAT)}</b>
                  <span className="timeline-text">
                    {logItem.user != null
                      ? localize.formatText(Locale.Text.Validation_By_User, { user: logItem.user.fullname })
                      : ''}
                  </span>
                </>
              )
            }
          ))}
          >

            {data?.entityValidationLogsByValues.length === 0 && <Alert message="No validation logs..." type="warning" />}
          </Timeline>
        </div>
      </Skeleton>
    </div>
  );
};

const DATA_QUERY = gql`
  query validationLogHistory($id: Int!, $entityType: EntityTypeEnum!) {
    entityValidationLogsByValues(id: $id, entity: $entityType){
      id
      entityAffiliationId
      entityTypeId
      ct
      ut
      validatedTime
      user {
        id
        email
        fullname
      }
    }

  }
`;


export default DataValidationHistoryLog;
