import { gql } from '@apollo/client';


/**
 * When retrieving values from somewhere, and they have the key prefixed with this value,
 * validates that it is generated via the [dynamicCriteriaKeyGenerator].
 * @type {string}
 * @link dynamicCriteriaKeyGenerator
 */
export const dynamicCriteriaKey = 'dcf';

/**
 *
 * @param {{id: number}} criteria
 * @param {{id: number}} inputPath
 * @return {string}
 */
export const dynamicCriteriaKeyGenerator = (criteria, inputPath) => (
  `${dynamicCriteriaKey}${criteria.id}-${inputPath.id}`
);


/**
 * @typedef dynamicCriteriaInputOptions
 * @property {boolean} disabled
 * @property {object} initialValue
 * @property {boolean} hideTitle
 *
 * @param {{inputPaths: {nodes: {id: number, type: {type: text}}[]}}} criteria - should come from [DynamicInput.FRAGMENT].
 * @param {dynamicCriteriaInputOptions} options
 * @param form
 * @param {function()} onEnter
 * @returns Input.Group
 */
export const DynamicCriteriaFactory = ({
  criteria,
  options = {},
  form,
  onEnter,
}) => {};

DynamicCriteriaFactory.FRAGMENT = gql`
  fragment DynamicCriteriaInput on Criteria {
    id
    heading
    entityType
    inputPaths {
      hash
      nodes {
        id
        service
        defaultOperatorType {
          id
          heading
          type
        }
        type {
          id
          type
        }
      }
    }
  }
`;
