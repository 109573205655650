import { gql } from '@apollo/client';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  EntityTypeEnum,
  FieldEnum,
  SelectSiteSegmentTypeSuggestionQueryQuery,
  SelectSiteSegmentTypeSuggestionQueryQueryVariables,
  SiteCriteriaOrder,
  UpdateSiteSectorTypeMutationMutationVariables,
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';

export const siteSectorTypeFieldConfig = siteFieldConfig(
  'siteSectorType',
  [
    'id',
    'countryCode',
    'siteSectorType.label',
    'siteSectorType.code',
    'controlSetting.id',
    'controlSetting.entityTypeId',
    'controlSetting.maintainerSourceCode',
    'controlSetting.createDcr',
  ],
  ({ localization }) => ({
    sorting: {
      ascend: SiteCriteriaOrder.SITE_SECTOR_TYPE_ASC,
      descend: SiteCriteriaOrder.SITE_SECTOR_TYPE_DESC,
    },
    dcrInfo: site => ({
      field: FieldEnum.HCO_SECTOR_TYPE,
      entityType: EntityTypeEnum.SITE,
      entityAffiliationId: site.id,
    }),
    title: localization.formatMessage(Locale.Attribute.Ownership),
    additionalTableConfig: {
      width: 120
    },
    render: (record) => record.siteSectorType?.label,
    updateView: buildSelectUpdateFieldConfig({
      mode: 'single',
      allowClear: true,
      selectPlaceholder: localization.formatMessage(Locale.Command.Select_ownership_type),
      mutation: UPDATE_SITE_SECTOR_TYPE_MUTATION,
      selectedKey: record => record.siteSectorType?.code,
      buildVariables: (selectedKey, record) => ({
        siteId: record.id,
        sectorType: selectedKey,
      }) as UpdateSiteSectorTypeMutationMutationVariables,
      fetchSuggestions: ({ client, userInput }) => (
        client.query<SelectSiteSegmentTypeSuggestionQueryQuery, SelectSiteSegmentTypeSuggestionQueryQueryVariables>({
          query: SUGGESTIOINS_QUERY,
          variables: { search: `%${userInput}%` },
        }).then(res => res.data.siteSectorTypes.nodes)
      )
    }),
  })
);

const UPDATE_SITE_SECTOR_TYPE_MUTATION = gql`
  mutation UpdateSiteSectorTypeMutation($siteId: Int!, $sectorType: String) {
    updateSiteSectorType(siteId: $siteId, siteSectorType: $sectorType) {
      id
    }
  }
`;

const SUGGESTIOINS_QUERY = gql`
  query SelectSiteSegmentTypeSuggestionQuery($search: String) {
    siteSectorTypes(criteria: { label: $search, fetchSize: { limit: 1000 } }) {
      hash
      nodes {
        code
        label
      }
    }
  }
`;
