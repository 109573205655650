import React from 'react';
import { gql } from '@apollo/client';
import LinkBadge from '../../components/Badge/LinkBandge';
import DcrEntity from '../../components/Badge/DcrEntity';
import DateDisplay from '../../components/Badge/DateDisplay';
import { Locale } from '../../../localization/LocalizationKeys';
import DisplayContent from '../../components/Badge/DisplayContent';
import { DcrResponseConf } from './DcrStatusTag';
import DcrGroupHeading from './components/DcrGroupHeading';
import { TableConfig, TableFieldsConfig } from '../search_old/types';
import { DcrGroupCriteriaOrder, DcrResponseTypeEnum } from '../../../gql/typings';


export const buildDcrGroupTableFragment = (additionalQuery: string|null) => gql`
  fragment DcrGroupTableFragment on DcrGroup {
    id
    ${additionalQuery || ''}
  }
`;

export const buildDcrGroupConnectionDataQuery = (additionalQuery: string|null) => gql`
  query DCRGroupTableQuery($criteria: DcrGroupCriteria) {
    connection: dcrGroups(criteria: $criteria) {
      hash
      totalCount
      nodes { ...DcrGroupTableFragment }
    }
  }
  ${buildDcrGroupTableFragment(additionalQuery)}
`;

export const buildDcrGroupRecordDataQuery = (additionalQuery: string|null) => gql`
  query DCRGroupRecordQuery($recordId: Int!) {
    record: dcrGroup(id: $recordId) {
      id
      ...DcrGroupTableFragment
    }
  }
  ${buildDcrGroupTableFragment(additionalQuery)}
`;

export type SupportedDcrGroupTableFields = 'heading'|'requesterName'|'ct'|'status'|'entities';

export const supportedDcrGroupTableFields: TableFieldsConfig<SupportedDcrGroupTableFields>['fields'] = {
  heading: props => ({
    key: 'heading',
    queryFields: ['id'],
    title: props.localization.formatMessage(Locale.Attribute.Heading),
    render: (record) => <LinkBadge
      url={`/dcr/${record.id}`}
      label={<DcrGroupHeading groupId={record.id} />}
      openInNewTab={props.openInNewTab}
    />,
  }),
  requesterName: props => ({
    key: 'requesterName',
    queryFields: [
      'responsibleUser.id',
      'responsibleUser.fullname',
    ],
    sorting: {
      ascend: DcrGroupCriteriaOrder.RESPONSIBLE_USER_ASC,
      descend: DcrGroupCriteriaOrder.RESPONSIBLE_USER_DESC,
    },
    title: props.localization.formatMessage(Locale.Attribute.DCR_requester),
    render: (record) => (
      <DisplayContent>{record.responsibleUser.fullname}</DisplayContent>
    ),
  }),
  ct: props => ({
    key: 'ct',
    queryFields: ['ct'],
    sorting: {
      ascend: DcrGroupCriteriaOrder.CT_ASC,
      descend: DcrGroupCriteriaOrder.CT_DESC,
    },
    title: props.localization.formatMessage(Locale.Attribute.Created),
    render: (record) => (
      <DateDisplay>{record.ct}</DateDisplay>
    ),
  }),
  status: props => ({
    key: 'status',
    queryFields: [
      'responseStatus.id',
      'responseStatus.responseTypeEnum',
    ],
    sorting: {
      ascend: DcrGroupCriteriaOrder.RESPONSE_STATUS_SORT_ASC,
      descend: DcrGroupCriteriaOrder.RESPONSE_STATUS_SORT_DESC,
    },
    title: props.localization.formatMessage(Locale.General.Status),
    render: (record) => props.localization.formatMessage(
      DcrResponseConf[record.responseStatus.responseTypeEnum as DcrResponseTypeEnum]!.localizationKey,
    ),
  }),
  entities: props => ({
    key: 'entities',
    queryFields: [
      'dcrs.hash',
      'dcrs.nodes.id',
      'dcrs.nodes.entityTypeEnum',
    ],
    title: props.localization.formatMessage(Locale.General.Entities),
    render: (record) => (
      <DcrEntity nodes={record?.dcrs?.nodes ?? []} />
    ),
  }),
};

export const DcrGroupTableConfig: TableConfig = {
  buildConnectionQuery: buildDcrGroupConnectionDataQuery,
  buildRecordQuery: buildDcrGroupRecordDataQuery,
  buildFragment: buildDcrGroupTableFragment,
  titleLabel: Locale.General.Data_Change_Requests_DCR,
  columnConfig: {
    fields: supportedDcrGroupTableFields,
  }
};
