import React, { useEffect, useRef } from 'react';

const Canvas = ({
  draw,
  ...rest
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {

    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    let frameCount = 0;
    let animationFrameId;
    const render = () => {
      frameCount++;
      if (frameCount === 1) draw(context, frameCount);
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();
    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);


  return <canvas ref={canvasRef} {...rest} />;
};

export default Canvas;
