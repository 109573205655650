import { Tooltip, Typography } from 'antd';
import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { SITE_ADDRESS_TYPES_QUERY } from '../../site/fields/siteAddressFieldConfig';
import { getNestedVariableField, linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { AddressTypeEnum, EntityTypeEnum, FieldEnum, SiteAddressTypesQueryQuery } from '../../../../gql/typings';
import { formatAddress } from '../../../util/format';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';

export const linkSiteAddressFieldConfig = linkFieldConfig(
  'siteAddress',
  [
    'id',
    'site.id',
    'site.addresses(criteria: { addressTypeCode: "{{keyCode}}" }).hash',
    'site.addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.id',
    'site.addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.id',
    'site.addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.street',
    'site.addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.postalCode',
    'site.addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.city',
    'site.addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.countryCode',
    'site.addresses(criteria: { addressTypeCode: "{{keyCode}}" }).nodes.address.typeCode',
  ],
  ({ localization }) => ({
    title: ({ selectedOption }) => selectedOption?.label,
    additionalTableConfig: {
      width: 150,
      ellipsis: {
        showTitle: false
      }
    },
    dcrInfo: (record, { selectedOption }) => {
      let fields: FieldEnum[] = [];
      if (selectedOption?.code === AddressTypeEnum.HCOVISIT) fields = [
        FieldEnum.HCO_VISIT_CITY,
        FieldEnum.HCO_VISIT_ZIP,
        FieldEnum.HCO_VISIT_STREET,
        FieldEnum.HCO_VISIT_COMMUNE,
      ];
      if (selectedOption?.code === AddressTypeEnum.HCOMAIL) fields = [
        FieldEnum.HCO_MAIL_CITY,
        FieldEnum.HCO_MAIL_ZIP,
        FieldEnum.HCO_MAIL_STREET,
        FieldEnum.HCO_MAIL_COMMUNE,
      ];
      return {
        entityType: EntityTypeEnum.SITE,
        entityAffiliationId: record.site.id,
        field: fields,
      };
    },
    getOptions: [localization.formatMessage(Locale.Attribute.Site_Address), ({ apolloClient }) => apolloClient
      .query<SiteAddressTypesQueryQuery>({ query: SITE_ADDRESS_TYPES_QUERY })
      .then(res => res.data.addressTypes.nodes.map(type => ({
        code: type.code,
        label: localization.formatMessageByStr(type.headingKey),
      })))],
    render: (record, options) => {
      if (!options.selectedOption) return ' ';
      const address = getNestedVariableField(record, options, 'site', 'addresses').nodes[0]?.address;
      const formattedAddress = formatAddress({ ...address });
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: options.isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={formattedAddress}>{formattedAddress}</Tooltip>
      </Typography.Paragraph>);
    },
  })
);
