import React, { useEffect } from 'react';
import { EntitiesSearch } from '../../../components/entitiesSearch';
import { EntityTypeEnum } from '../../../../gql/typings';
import { useTableRowSelection } from '../../../components/Table/useTableRowSelection';


const InstanceDeploymentsView: React.FC = () => {
  const tableSelection = useTableRowSelection({ virtualSupport: false });

  useEffect(() => {
    tableSelection.tableProps.perPageState[1](20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div style={{ margin: '24px 0' }}>
    <EntitiesSearch
      entityType={EntityTypeEnum.PURE_ADVANCE_CUSTOMER}
      includeInactiveRecords={false}
      tableSize="small"
      tableSelection={tableSelection}
    />
  </div>;
};

// const DATA_QUERY = gql`
//   query InstanceDeploymentsQuery {
//     pureAdvanceCustomers {
//       hash
//       nodes {
//         code
//         dockerService {
//           ID
//           imageVersion
//         }
//       }
//     }
//   }
// `;

export default InstanceDeploymentsView;
