import React from 'react';
import { message, Modal } from 'antd';
import { useMutation } from '@apollo/client';
import { useLocalization } from '../../../../../util/useLocalization';
import { StateArray } from '../../../../../util/StateArrayType';
import { EntitiesSearch } from '../../../../../components/entitiesSearch';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { LinkUserWithExternalId_MUTATION } from '../../../queries/manualExternalUserMappingAction';
import { ImportManualAcctionProps } from '../ImportManualProcessesTypes';
import { useSaveProcessResult } from '../utils';
import {
  CurrentImportProjectStateQuery, EntityTypeEnum,
  LinkUserWithExternalIdMutation,
  LinkUserWithExternalIdMutationVariables, ProcessLineEffectActionEnum
} from '../../../../../../gql/typings';
import { useTableRowSelection } from '../../../../../components/Table/useTableRowSelection';

type ManualPersonSearchMatchingModalProps = Extract<NonNullable<
CurrentImportProjectStateQuery['importProject']>['manual'], { ['__typename']: 'ImportManualPersonStage' }>
& ImportManualAcctionProps
& { visibleState: StateArray<boolean> };

const ManualPersonSearchMatchingModal: React.FC<ManualPersonSearchMatchingModalProps> = ({
  visibleState: [visible, setVisible],
  externalIdValue,
  continueAutomatic,
  externalIdTypeCode,
}) => {
  const localization = useLocalization();
  const { saveProcessResult } = useSaveProcessResult();
  const tableSelection = useTableRowSelection({ virtualSupport: false, type: 'radio' });
  const [
    linkExternalId,
    { loading },
  ] = useMutation<LinkUserWithExternalIdMutation, LinkUserWithExternalIdMutationVariables>(LinkUserWithExternalId_MUTATION);

  return (
    <Modal
      open={visible}
      width="clamp(680px, 85%, 1400px)"
      style={{ top: 42 }}
      destroyOnClose
      onCancel={() => setVisible(false)}
      footer={false}
    >
      <EntitiesSearch
        entityType={EntityTypeEnum.PERSON}
        tableSelection={tableSelection}
        quickActions={[
          {
            key: 'select',
            label: 'Select',
            className: 'select-hcp-for-manual',
            loading,
            disabled: tableSelection.selection.count !== 1,
            onClick: () => {
              setVisible(false);
              linkExternalId({
                variables: {
                  input: {
                    internalId: tableSelection.selection.selected[0]!,
                    externalIdTypeCode,
                    externalId: externalIdValue,
                  }
                },
              }).then(res => {
                if (res.errors && res.errors.length > 0) {
                  message.error(res.errors[0]!.message);
                } else {
                  continueAutomatic();
                  return saveProcessResult({
                    action: ProcessLineEffectActionEnum.UPDATE,
                    entityType: EntityTypeEnum.PERSON,
                    entityAffiliationId: tableSelection.selection.selected[0]!,
                    note: localization.formatMessage(Locale.Text.Linked_externalId_to_person, { value: externalIdValue }),
                  });
                }
              }).finally(() => setVisible(false));
            },
          },
        ]}
      />
    </Modal>
  );
};

export default ManualPersonSearchMatchingModal;
