import { Tooltip, Typography } from 'antd';
import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SiteCriteriaOrder } from '../../../../gql/typings';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';


export const siteMailStreetFieldConfig = siteFieldConfig(
  'mailStreet',
  [
    'mailAddress.id',
    'mailAddress.street',
  ],
  ({ localization }) => ({
    sorting: {
      ascend: SiteCriteriaOrder.MAIL_STREET_ASC,
      descend: SiteCriteriaOrder.MAIL_STREET_DESC,
    },
    title: localization.formatMessage(Locale.Attribute.Mail_street),
    additionalTableConfig: {
      width: 130
    },
    // className: 'apb-antd-table-column-width-no-wrap',
    render: (record, { isViewingFromPage }) => {
      const address = record.mailAddress?.street;
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={address}>{address}</Tooltip>
      </Typography.Paragraph>);
    },
  })
);
