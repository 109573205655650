import React, { useState } from 'react';
import { Typography } from 'antd';
import { FlagCountry } from '../../../../components/Flag/Flag';
import ConfigureReportKPI from './ConfigureReportKPI';
import ActivityTypesInReports from '../activityTypes/ActivityTypesInReports';
import AnchoredSections, { AnchoredItem } from '../../adminComponents/AdminSection/AnchoredSections';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import CycleSetupList from './CycleSetupList';
import { KPIQueryQuery } from '../../../../../gql/typings';

const ReportKPIAnchoredSettings: React.FC = () => {
  const localization = useLocalization();
  const [selectedSetting, setSelectedSetting] = useState<NonNullable<KPIQueryQuery['salesCycleSetups']>['nodes']['0']>();
  const selectedCountryState = useState<FlagCountry>();

  return (
    <div className="report-admin-settings-container">
      <AnchoredSections>
        <AnchoredItem anchor="description" title={localization.formatMessage(Locale.Attribute.Description)}>
          <Typography.Text>
            {localization.formatText(Locale.Text.KPI_long_description)}
          </Typography.Text>
        </AnchoredItem>

        <AnchoredItem anchor="activity" title={localization.formatMessage(Locale.Attribute.Activity_type)}>
          <ActivityTypesInReports reportCode="KPI" selectedCountryState={selectedCountryState} />
        </AnchoredItem>


        <AnchoredItem
          anchor="select-report"
          title={localization.formatMessage(Locale.Command.Select_cycle)}
          hidden={!selectedCountryState[0]}
        >
          {selectedCountryState[0] && <CycleSetupList
            visibleState={[selectedSetting, setSelectedSetting]}
            selectedCountry={selectedCountryState[0]}
          />}
        </AnchoredItem>

        {selectedSetting && <AnchoredItem anchor="kpis" title={localization.formatMessage(Locale.General.KPIs)}>
          <ConfigureReportKPI selectedSetting={selectedSetting} />
        </AnchoredItem>}
      </AnchoredSections>
    </div>
  );
};

export default ReportKPIAnchoredSettings;
