import React from 'react';
import { gql } from '@apollo/client';
import { Space, Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import { Locale } from '../../../../localization/LocalizationKeys';
import { TableConfig, TableFieldsConfig } from '../../search_old/types';
import Icon from '../../../components/Icon/Icon';

export const buildUserAdminTableFragment = (additionalQuery: string|null) => gql`
  fragment UserAdminTableFragment on User {
    id
    ${additionalQuery ?? ''}
  }
`;

export const buildUserAdminConnectionDataQuery = (additionalQuery: string|null) => gql`
  query UserAdminConnectionQuery($criteria: UserCriteria) {
    connection: users(criteria: $criteria) {
      hash
      totalCount
      nodes {
        id
        ...UserAdminTableFragment
      }
    }
  }
  ${buildUserAdminTableFragment(additionalQuery)}
`;

export const buildUserAdminRecordDataQuery = (additionalQuery: string|null) => gql`
  query UserAdminRecordQuery($recordId: Int!) {
    record: user(userId: $recordId) {
        id
        ...UserAdminTableFragment
    }
  }
  ${buildUserAdminTableFragment(additionalQuery)}
`;

export type SupportedUserAdminTableFields = 'id'|'firstname'|'lastname'|'email'|'role'|'additionalInformation';

export const supportedUserAdminTableFields: TableFieldsConfig<SupportedUserAdminTableFields>['fields'] = {
  id: props => ({
    key: 'id',
    queryFields: ['id'],
    title: props.localization.formatMessage(Locale.Attribute.ID),
    render: (record) => (
      <NavLink to={`/admin/user/${record.id}`}>{record.id}</NavLink>
    ),
  }),
  firstname: props => ({
    key: 'firstname',
    queryFields: ['firstname'],
    title: props.localization.formatMessage(Locale.Attribute.First_name),
    render: (record) => record.firstname,
  }),
  lastname: props => ({
    key: 'lastname',
    queryFields: ['lastname'],
    title: props.localization.formatMessage(Locale.Attribute.Last_name),
    render: (record) => record.lastname,
  }),
  email: props => ({
    key: 'email',
    queryFields: ['email'],
    title: props.localization.formatMessage(Locale.General.Username),
    render: (record) => record.email,
  }),
  role: props => ({
    key: 'role',
    queryFields: ['role.id', 'role.heading'],
    title: props.localization.formatMessage(Locale.Attribute.Role),
    render: (record) => record.role.heading,
  }),
  additionalInformation: props => ({
    key: 'additionalInformation',
    queryFields: ['isLocked', 'isHidden'],
    render: (record) => (
      <Space>
        {record.isLocked && <Tooltip title={props.localization.formatMessage(Locale.Text.User_is_locked_information)}>
          <span><Icon iconType="LOCK" /></span>
        </Tooltip>}
        {record.isHidden && <Tooltip title={props.localization.formatMessage(Locale.Text.User_is_hidden_information)}>
          <span><Icon iconType="EYE_INVISIBLE" /></span>
        </Tooltip>}
      </Space>
    )
  }),
};

export const UserAdminTableConfig: TableConfig = {
  buildConnectionQuery: buildUserAdminConnectionDataQuery,
  buildRecordQuery: buildUserAdminRecordDataQuery,
  buildFragment: buildUserAdminTableFragment,
  titleLabel: Locale.General.Users,
  extraConfig: { onRowClick: (record, { navigate }) => navigate(`/admin/user/${record.id}`) },
  columnConfig: {
    fields: supportedUserAdminTableFields,
  },
};
