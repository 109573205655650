import React, { useEffect, useRef, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { max } from 'lodash';
import { useSize } from 'ahooks';
import { gql } from '@apollo/client';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import TabCardConfiguration, { TabCardConfiguration_FRAGMENT } from '../TabCardConfiguration';
import Context, { DynamicEntityTabItem } from './DynamicEntityTabContext';
import { DynamicTabGridFragmentFragment, EntityTypeEnum } from '../../../../gql/typings';


const ResponsiveGridLayout = WidthProvider(Responsive);

type DynamicEntityTabGridContainerProps = {
  entityType: EntityTypeEnum;
  recordId: number;
  items: DynamicTabGridFragmentFragment[];
  refreshEmitter: EventEmitter<void>;
};

const DynamicEntityTab: React.FC<DynamicEntityTabGridContainerProps> = ({ recordId, entityType, items, refreshEmitter }) => {
  const ref = useRef<HTMLDivElement>(null);
  const divSize = useSize(ref);
  const gridState = useState<DynamicEntityTabItem[]>([]);

  useEffect(() => {
    gridState[1](items.map(card => ({
      i: `${card.id}`,
      x: card.x ?? 0,
      y: card.y ?? 0,
      h: card.height ?? 1,
      w: card.width ?? 12,
      static: false,
      moved: false,
      item: card,
    })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <div ref={ref}>
      <Context.Provider value={{ gridState, rootRef: ref }}>
        <ResponsiveGridLayout
          className="layout dynamic-entity-tab-grid-layout"
          compactType="horizontal"
          layouts={{ lg: gridState[0] }}
          margin={[9, 14]}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xss: 10 }}
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xss: 2 }}
          isDraggable={false}
          isResizable={false}
          measureBeforeMount
          style={{ marginLeft: -9, marginTop: -14 }}
        >
          {gridState[0].map(card => (
            <div key={card.i} style={{ width: '100%', height: '100%' }}>
              <TabCardConfiguration
                recordId={recordId}
                entityType={entityType}
                configuration={card.item}
                refreshEmitter={refreshEmitter}
                size={{
                // card item has a padding of 24
                // header is 56
                  width: max([0, divSize ? (divSize.width - 48) : 0])!,
                  height: max([0, divSize ? (divSize.height - 104) : 0])!,
                }}
              />
            </div>
          ))}
        </ResponsiveGridLayout>
      </Context.Provider>
    </div>
  );
};

export const DYNAMIC_ENTITY_TAB_GRID_FRAGMENT = gql`
  fragment DynamicTabGridFragment on EntityGridItemConfiguration {
    id
    typeEnum
    heading
    description
    x
    y
    width
    height
    ...TabCardConfigurationFragment
  }
  ${TabCardConfiguration_FRAGMENT}
`;

export default DynamicEntityTab;
