import React from 'react';
import MainView from '../../../components/View/MainView';
import { EntitiesSearch } from '../../../components/entitiesSearch';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import { EntityTypeEnum } from '../../../../gql/typings';
import { useTableRowSelection } from '../../../components/Table/useTableRowSelection';


const ListView = () => {
  const { formatMessage } = useLocalization();
  const tableSelection = useTableRowSelection({ maintainPageState: { entityType: EntityTypeEnum.LIST } });

  usePageTitle(formatMessage(Locale.General.Lists));
  return (
    <MainView
      className="list-view-container2"
      boundingBox={false}
    >
      <EntitiesSearch
        entityType={EntityTypeEnum.LIST}
        tableSelection={tableSelection}
        urlSearchEnabled
        rowIndicator={{
          label: formatMessage(Locale.Attribute.Shared_lists),
          color: 'orange',
          visible: (record) => !!record.shared,
          overviewVisible: data => {
            if (!data || !data.connection || !data.connection.nodes) return false;
            return data.connection.nodes.filter(l => l.shared).length > 0;
          },
        }}
      />
    </MainView>
  );
};

export default ListView;
