import React from 'react';
import { Button, Col, Row, Select } from 'antd';
import { useIntl } from 'react-intl';
import { Locale } from '../../../../localization/LocalizationKeys';
import { ImportTemplatesQuery } from '../../../../gql/typings';

type ChooseTemplateProps = {
  data: ImportTemplatesQuery | undefined;
  loading: boolean;
  templateId?: number;
  setTemplateId: (id: number) => void;
  done: () => void;
};

const ChooseTemplate: React.FC<ChooseTemplateProps> = ({
  data,
  loading,
  templateId,
  setTemplateId,
  done,
}) => {
  const intl = useIntl();
  if (loading || !data) return <span>Loading...</span>;

  return (
    <Row>
      <Col lg={{ span: 10, offset: 6 }} md={{ span: 18, offset: 4 }} sm={{ span: 24, offset: 0 }}>
        {intl.formatMessage(Locale.Command.Select_template)}
        <Select onSelect={(id: number) => setTemplateId(id)} style={{ width: '100%' }}>
          {data.importTemplates.nodes.map(template => (
            <Select.Option key={template.id} value={template.id}>
              {template.name}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col span={2} style={{ paddingLeft: 8, display: 'flex', alignItems: 'flex-end' }}>
        {templateId && (
          <Button type="primary" onClick={done}>
            {intl.formatMessage(Locale.Command.Continue)}
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default ChooseTemplate;
