import React from 'react';
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router';
import { NavigateFunction } from 'react-router/lib/hooks';
import { EntityTypeEnum } from '../../../../../../../../gql/typings';
import { LocalizationShape, useLocalization } from '../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../localization/LocalizationKeys';
import ExternalNavLink from '../../../../../../../components/ExternalNavLink/ExternalNavLink';

type MergeEntityLinkProps = {
  type: EntityTypeEnum.PERSON|EntityTypeEnum.SITE|EntityTypeEnum.AFFILIATION;
  id: number;
  localization?: LocalizationShape;
  navigate?: NavigateFunction;
};

const linkMap: Record<MergeEntityLinkProps['type'], string> = {
  AFFILIATION: '/link',
  PERSON: '/hcp',
  SITE: '/hco',
};

const MergeEntityLink: React.FC<MergeEntityLinkProps> = ({
  id,
  type,
  localization = useLocalization(),
  navigate = useNavigate(),
  children,
}) => (
  <ExternalNavLink to={`${linkMap[type]}/${id}`} navigate={navigate} openInNewTab>
    <Tooltip title={`${children} (${localization.formatMessage(Locale.Attribute.ID)}: ${id})`}>
      {children}
    </Tooltip>
  </ExternalNavLink>
);

export default MergeEntityLink;
