import React from 'react';
import { Tooltip } from 'antd';
import Icon from '../../components/Icon/Icon';
import PersonDcrRender, { PersonRenderProps } from './PersonDcrRender';
import SiteDcrRender, { SiteDcrRenderProps } from './SiteDcrRender';
import { Optional } from '../../util/StateArrayType';
import { LinkDcrRenderProps } from './LinkDcrRender';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';
import { DcrGroupDetailQuery, DcrResponseTypeEnum } from '../../../gql/typings';

type RenderDCREntityLabelProps = {
  affiliation: Optional<NonNullable<DcrGroupDetailQuery['dcrGroup']>['dcrs']['nodes']['0']['affiliationEntity']>;
  responseType: DcrResponseTypeEnum | undefined;
  requestType: Optional<NonNullable<DcrGroupDetailQuery['dcrGroup']>['dcrs']['nodes']['0']['requestType']>;
};

type ConnectionProps = {
  requestType: Optional<NonNullable<DcrGroupDetailQuery['dcrGroup']>['dcrs']['nodes']['0']['requestType']>;
  entity: Optional<NonNullable<DcrGroupDetailQuery['dcrGroup']>['dcrs']['nodes']['0']['affiliationEntity']>;
  renderButton: boolean;
};

const PersonRender: React.FC<PersonRenderProps> = props => (
  <PersonDcrRender {...props} />
);

const LinkRender: React.FC<LinkDcrRenderProps> = props => {
  const localization = useLocalization();
  return (
    <Tooltip title={localization.formatMessage(Locale.Text.DCR_request_type_info, { requestType: props.requestType.code })}>
      <Icon
        iconType="POSITION"
        text={<span>{props.entity.position?.label}</span>}
      />
      &nbsp;
    </Tooltip>);
};

const SiteRender: React.FC<SiteDcrRenderProps> = props => (
  <SiteDcrRender {...props} />);

const test: Record<string, React.FC<ConnectionProps>> = {
  // @ts-ignore
  Person: PersonRender,
  // @ts-ignore
  Site: SiteRender,
  // @ts-ignore
  SitePerson: LinkRender,
};

const RenderDCREntityLabel: React.FC<RenderDCREntityLabelProps> = (props) => {
  const { affiliation, responseType, requestType } = props;
  const renderButton = (responseType === 'REJECT' && requestType?.code === 'CREATE');
  if (!affiliation) return <></>;
  const Test2 = test[affiliation.__typename]!;

  return (
    <Test2 entity={affiliation} renderButton={renderButton} requestType={requestType} />
  );
};


export default RenderDCREntityLabel;
