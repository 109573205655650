import React, { useState } from 'react';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { Skeleton, Tag, Tooltip } from 'antd';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { PermissionInfo } from '../../../../permission/permissionUtils';
import AdminContactCategoryModal from './AdminContactCategoryModal/AdminContactCategoryModal';
import { ContactCategoriesQueryQuery, ContactCategoriesQueryQueryVariables, EntityTypeEnum } from '../../../../../gql/typings';

type AdminContactCategoriesProps = {
  entityType: EntityTypeEnum;
  permission: PermissionInfo;
};

const AdminContactCategories: React.FC<AdminContactCategoriesProps> = ({ entityType, permission }) => {
  const localization = useLocalization();
  const { data, loading, refetch } = useQuery<ContactCategoriesQueryQuery, ContactCategoriesQueryQueryVariables>(DATA_QUERY, {
    variables: { entityType },
  });
  const selectedState = useState<string>();
  const createNewState = useState(false);

  return (
    <div className="admin-contact-categories-container">
      <Skeleton loading={loading} active={loading}>
        {(data?.contactCategories.nodes ?? []).map(category => (
          <Tag
            key={category.code}
            onClick={() => selectedState[1](category.code)}
            style={{ cursor: 'pointer' }}
            icon={category.isMapped ? <>
              <Tooltip title="This contact category is being maintained externally">
                <SyncOutlined />
              </Tooltip>
            </> : undefined}
          >
            {category.heading}
          </Tag>
        ))}
        {permission.create && <Tag onClick={() => createNewState[1](true)} style={{ cursor: 'pointer' }}>
          <PlusOutlined /> {localization.formatMessage(Locale.Attribute.Create_new)}
        </Tag>}
      </Skeleton>
      <AdminContactCategoryModal
        createNewState={createNewState}
        entityType={entityType}
        selectedState={selectedState}
        permission={permission}
        refetch={refetch}
      />
    </div>
  );
};

const DATA_QUERY = gql`
  query ContactCategoriesQuery($entityType: EntityTypeEnum!) {
    contactCategories(criteria: {entityType: $entityType}) {
      hash
      nodes {
        code
        heading
        isMapped
      }
    }
  }
`;

export default AdminContactCategories;

