import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router';
import { message } from 'antd';
import CreateStaticListModal from './CreateStaticListModal';
import CreateDynamicListModal from './CreateDynamicListModal';
import AddToExistingStaticListModal from './AddToExistingStaticListModal';
import { TableActionItem } from '../../../components/Table/Actions';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import {
  EntityTypeEnum,
  SelectionCriteriaInput,
  useAddDynamicRulesToListMutation,
  useSetListCountriesMutation
} from '../../../../gql/typings';

export type CreateListActionProps = {
  selection: SelectionCriteriaInput;
  entityType: EntityTypeEnum.PERSON|EntityTypeEnum.ACTIVITY|EntityTypeEnum.SITE;

  /**
   * @default true
   */
  dynamicListSupport?: boolean;
};

const useCreateListActions = ({
  selection,
  entityType,
  dynamicListSupport = true,
}: CreateListActionProps): [TableActionItem, React.FC] => {
  const navigate = useNavigate();
  const localization = useLocalization();

  const [addToDynamicList] = useAddDynamicRulesToListMutation();
  const [setCountries] = useSetListCountriesMutation();

  const [showCreateStaticList, setShowCreateStaticList] = useState(false);
  const [showMassAssign, setShowMassAssign] = useState(false);
  const [showCreateList, setShowCreateList] = useState(false);

  const onCreatedList = (listId: number) => {
    const rules = selection
      ?.criterias
      ?.filter(r => r.value?.values?.length !== 0 ?? true) // Filter out empty arrays
      ?? [];

    addToDynamicList({
      variables: {
        input: {
          listId,
          rules,
        },
      },
    }).then(() => setCountries({
      variables: { listId, countries: selection.countries },
    }).then(() => navigate(`/list/${listId}`)));
  };


  const saveAsList = () => {
    if (isEmpty(selection.criterias)) {
      return message.warning(localization.formatMessage(Locale.Text.Cant_save_list));
    }
    setShowCreateList(true);
  };

  const action: TableActionItem = {
    key: 'lists',
    label: localization.formatMessage(Locale.General.List),
    items: [],
  };

  if (dynamicListSupport) action.items!.push({
    key: 'new-dynamic',
    label: localization.formatMessage(Locale.Command.Save_as_dynamic_list),
    tooltip: localization.formatMessage(Locale.Text.Save_search_as_dynamic_list),
    onClick: saveAsList,
  });
  action.items!.push({
    key: 'new-static',
    label: localization.formatMessage(Locale.Command.Add_to_new_static_list),
    disabled: selection.count === 0,
    tooltip: localization.formatMessage(Locale.Text.Add_selected_records_to_new_list),
    onClick: () => setShowCreateStaticList(true),
  });
  if (dynamicListSupport) action.items!.push(null);
  action.items!.push({
    key: 'add-static',
    label: localization.formatMessage(Locale.Command.Add_to_static_list),
    tooltip: localization.formatMessage(Locale.Text.Add_selected_records_to_existing_list),
    disabled: selection.count === 0,
    onClick: () => setShowMassAssign(true),
  });

  return [
    action,
    () => (
      <div>
        {showCreateList && (
          <CreateDynamicListModal
            visible={showCreateList}
            entityType={entityType}
            onClose={() => setShowCreateList(false)}
            onCreated={onCreatedList}
          />
        )}
        {showMassAssign && (
          <AddToExistingStaticListModal
            visible={showMassAssign}
            entityType={entityType}
            selection={selection}
            onClose={() => setShowMassAssign(false)}
          />
        )}
        {showCreateStaticList && (
          <CreateStaticListModal
            visible={showCreateStaticList}
            selection={selection}
            entityType={entityType}
            onClose={() => setShowCreateStaticList(false)}
          />
        )}
      </div>
    ),
  ];
};

gql`
  query GetStaticLists {
    connection: getStaticLists {
      hash
      nodes {
        id
        title
        description
      }
    }
  }
`;


gql`
  mutation AddDynamicRulesToList($input: DynamicRulesInput!) {
    addDynamicRulesToList(input: $input) {
      id
    }
  }
`;

gql`
  mutation SetListCountries($listId: Int!, $countries: [CountryCode!]) {
    updateListCountries(listId: $listId, countries: $countries) {
      id
    }
  }
`;

export default useCreateListActions;
