import { useQuery } from '@apollo/client';
import { entitiesTableConfigs, SupportedEntitySearchTypes } from '../../../browse/search_old/types';
import { useTableRowSelection } from '../../Table/useTableRowSelection';
import { useTableColumns } from './useTableColumns';
import { EntityTableProps } from './EntityTable';

export type EntityTableData = Pick<EntityTableProps, 'entityType'|'data'|'tableSelection'>;

export function useEntityTableData<CRITERIA>(
  entityType: SupportedEntitySearchTypes,
  criteria: CRITERIA,
  hasGlobalSearchPermission = true
): EntityTableData {
  const tableSelection = useTableRowSelection({ virtualSupport: false });
  const { query, sorting, loading: columnsLoading } = useTableColumns(entityType);
  const documentNode = entitiesTableConfigs[entityType]!.buildConnectionQuery(query, hasGlobalSearchPermission);
  const documentNodeVariables = {
    criteria: {
      ...criteria,
      ordering: sorting?.map(s => s.currentEnum),
      fetchSize: {
        limit: tableSelection.tableProps.perPageState[0],
        offset: (tableSelection.tableProps.pageState[0] - 1) * tableSelection.tableProps.perPageState[0],
      },
    },
  };
  const {
    data,
    loading,
  } = useQuery(documentNode, { variables: documentNodeVariables });

  return {
    entityType,
    tableSelection,
    data: {
      nodes: data?.connection?.nodes ?? [],
      totalCount: data?.connection?.totalCount,
      loading: loading || columnsLoading,
    },
  };
}
