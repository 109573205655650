/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Tabs } from 'antd';
import { useIntl } from 'react-intl';
import { Draggable } from '@fullcalendar/interaction';
import CalendarCard from '../../components/Card/CalendarCard';
import CalendarSearchView from '../../components/Card/CalendarSearchView';
import { Locale } from '../../../localization/LocalizationKeys';
import { ActivityPersons_FRAGMENT } from '../activity/ActivityDetails/ActivityPersons/ActivityPersonsFragment';
import { useUser } from '../../util/useUser';
import { usersResponsibleListQuery, usersResponsibleListQueryVariables } from '../../../gql/typings';

const CalendarNavigationMenu: React.FC = () => {
  const intl = useIntl();
  const user = useUser();
  const { data, loading, refetch } = useQuery<usersResponsibleListQuery, usersResponsibleListQueryVariables>(DATA_QUERY, {
    variables: { userId: user.id },
  });
  const avilableUsers = data?.viewer?.responsibleHcps.nodes ?? [];


  useEffect(() => {
    if (!loading) refetch();
  }, []);

  const RenderCard: React.FC<{
    person: NonNullable<usersResponsibleListQuery['viewer']>['responsibleHcps']['nodes']['0'];
  }> = ({ person }) => {
    const ref = useRef();
    useEffect(() => {
      if (ref && person) {
        // @ts-ignore
        // eslint-disable-next-line no-new
        new Draggable(ref.current, {
          eventData: {
            title: person.fullName,
            id: person.id,
            duration: '01:00',
          }
        });
      }
    }, [ref, person]);
    return (
      <CalendarCard
        // @ts-ignore
        ref={ref}
        key={person.id}
        title={person.fullName}
        subTitle={person.primarySite?.name ?? ''}
        amountOfActivities={person.activitiesAffiliatedToMe.totalCount}
        daysAgo={person.daysSinceLastActivity}
      />
    );
  };

  return (
    <div className="activity-planning-container">
      <h2>{intl.formatMessage(Locale.General.Activity_Planning)}</h2>
      <Tabs
        defaultActiveKey="1001"
        items={[
          {
            key: '1001',
            label: intl.formatMessage(Locale.Command.Select),
            children: (
              <>
                <p>{intl.formatMessage(Locale.Text.HCP_Assigned_User)}</p>
                <div className="planning-cards">
                  {avilableUsers.map(person => (
                    <RenderCard key={person.id} person={person} />
                  ))}
                </div>
              </>
            )
          },
          {
            key: '1003',
            label: intl.formatMessage(Locale.Command.Search),
            children: <CalendarSearchView />
          }
        ]}
      />
    </div>
  );
};

const DATA_QUERY = gql`
  query usersResponsibleList($userId: ID!) {
    viewer {
      id
      fullname
      responsibleHcps {
        hash
        totalCount
        nodes {
          id
          # MARK: This fragment needs to be here for the activity drawer to work!
          ...ActivityPersonFragment
          fullName
          daysSinceLastActivity
          activitiesAffiliatedToMe: activities(criteria: { responsibleUserId: $userId }) {
            hash
            totalCount
          }
          primarySite {
            id
            name
          }
        }
      }
    }
  }
  ${ActivityPersons_FRAGMENT}
`;


export default CalendarNavigationMenu;

