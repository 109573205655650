import { gql } from '@apollo/client';

export const CriteriaInputFactory_FRAGMENT = gql`
  fragment CriteriaInputFragment on Criteria {
    id
    heading
    entityType
    code # This needs to be here for entity url search to work!
    criteriaGroupCode
    hasGlobalSupport
    inputPaths {
      hash
      nodes {
        id
        service
        icon
        placeholderHeading
        defaultOperatorType {
          id
          heading
          type
        }
        type {
          id
          type
        }
      }
    }
  }
`;
