/* eslint-disable max-len */

import { repeat } from 'lodash';

/**
 * Taken from: https://www.typescriptlang.org/play?jsx=1#code/JYOwLgpgTgZghgYwgAgILIN4ChnIPp5gCeADhCHALYQBcyARHPQNw7Jx0DOYUoA5qwC+WUJFiIUAIUxsCxMhWp16AIxZsVXHvyFYs8lAGFkAXjTIAPskmssCAPYhuyYJwAqpclRRmAPG+QIAA9IEAATTmRuXhA+AD4ACgNFWmQ3AEpTOORfNgA5QJDyCMx8Qk8UrRi+ZGFEthB7MNS8rHS6RuaXSIBREKhEMF88gBpSuQrvOgDBbJNs7FwoCDAAVygQZE6IADoJhW9TEzNk7yFbBycwZCDOOkMAbQBdU2QHjDLTpQYmMY4f+i1MYffZeb6qehjTQMNS1J4XRzOGDAAA2YkMKlerg8B2oCUY9HSzGQAHoScg+ORoMAEMgYCAsLcdsi0dAEiz0SoiaTyahnnpLs4iJwAIyvJkctnYyZ4gnpdI7ShwEgJIJZG47ODcslRbSxflAA
 *
 * @example
 *  listWithUnions.filter(isTypename('Activity')).map(a => a.heading);
 */
export const isTypename = <T extends string>(typename: T) => <
  N extends { __typename: string }
  >(
    node: N
  ): node is Extract<N, { __typename: T }> => node?.__typename === typename;


/**
 * @param paths dot separated graphql fields
 * @param replacements a pair array of keys to be replaced with
 *
 * @example
 *  generateGraphQL([
 *    'name',
 *    'id',
 *    'primarySite.name',
 *    'abc.qq.yellow',
 *    'primarySite.id',
 *    'abc.super',
 *    'abc.qq.pellow',
 *  ])
 * Results in:
 *    `name
 *     id
 *     primarySite {
 *       name
 *       id
 *     }
 *     abc {
 *       qq {
 *         yellow
 *         pellow
 *       }
 *       super
 *     }`
 * TODO: Figure out how to define recursive types in typescript instead of using any.
 */
export const generateGraphQL = (paths: string[], replacements: [string, string][] = []): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const data: Record<string, any> = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let prev: Record<string, any>;
  paths.map(path => {
    const items = graphqlSplitter(replacements
      .reduce((acc, curr) => acc.replace(curr[0], curr[1]), path));
    prev = data;
    items.map((item, index) => {
      if (index == items.length -1) {
        prev[item] = item;
      } else {
        if (!prev[item]) prev[item] = {};
        prev = prev[item];
      }
    });
  });
  let query = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function buildQuery(input: Record<string, any>, ident = 0) {
    return Object.keys(input).map(key => {
      const space = repeat(' ', ident);
      if (typeof input[key] === 'string') query += `${space}${key}\n`;
      else {
        query += `${space}${key} {\n`;
        buildQuery(input[key], ident + 2);
        query += `${space}}\n`;
      }
    });
  }

  buildQuery(data);
  return query;
};

export function graphqlSplitter(str: string): string[] {
  const list: string[] = [];

  let tmp = '';
  let inQuotes = false;
  let parenthesisCount = 0;
  let bracketsCount = 0;
  let aliasColumnIndex: number|undefined;

  const standard = (char: string) => {
    tmp += char;
  };

  for (let i = 0; i < str.length; i++) {
    const char = str[i]!;
    switch (char) {
      case '(':
        standard(char);
        parenthesisCount++;
        break;
      case ')':
        standard(char);
        parenthesisCount--;
        break;
      case '{':
        standard(char);
        bracketsCount++;
        break;
      case '}':
        standard(char);
        bracketsCount--;
        break;
      case ':':
        if (parenthesisCount === 0 && bracketsCount === 0) {
          if (list.length === 0 && aliasColumnIndex) {
            const test = tmp.split('');
            test.splice(aliasColumnIndex, 1, '_');
            tmp = test.join('');
          }
          aliasColumnIndex = i;
        }
        standard(char);
        break;
      case '.':
        if (inQuotes) standard(char);
        else {
          list.push(tmp);
          tmp = '';
        }
        break;
      case '"':
        standard(char);
        inQuotes = !inQuotes;
        break;
      default:
        standard(char);
    }
  }
  if (tmp.length > 0) list.push(tmp);
  return list;
}
