import React from 'react';
import { NavLink } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert, Button, List, Progress, Result, Skeleton, Space, Typography } from 'antd';
import { useInterval } from 'ahooks';
import { EntityTypeId } from '../../../../../util/entity';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../../util/useLocalization';
import {
  EntityMergeAdminOverviewQueryQuery, EntityMergeAdminOverviewQueryQueryVariables,
  EntityTypeEnum,
  PerformAutomaticMergesMutation,
  PerformAutomaticMergesMutationVariables
} from '../../../../../../gql/typings';

type AdminEntityMergeRequestProps = {
  entityType: EntityTypeEnum;
};

const AdminEntityMergeRequestsOverview: React.FC<AdminEntityMergeRequestProps> = ({ entityType }) => {
  const localization = useLocalization();
  const [
    performAutomatic,
    { loading: automaticLoading },
  ] = useMutation<PerformAutomaticMergesMutation, PerformAutomaticMergesMutationVariables>(AUTOMATIC_MUTATION);
  const {
    data,
    loading,
    error,
    refetch,
  } = useQuery<EntityMergeAdminOverviewQueryQuery, EntityMergeAdminOverviewQueryQueryVariables>(DATA_QUERY, {
    variables: { entityType },
  });

  useInterval(() => {
    if (automaticLoading && !loading) refetch();
  }, 750);

  if (error) return <Result
    status="warning"
    title="Merge information missing"
    subTitle={<span>
      {error.message}<br />
      Please report to <a href="mailto:support@pureadvance.com">support@pureadvance.com</a>
    </span>}
  />;

  const unhandledCount = data?.unhandled?.totalCount ?? 1;
  const handledCount = data?.handled?.totalCount ?? 1;

  const renderItem = (
    mergeRequest: NonNullable<EntityMergeAdminOverviewQueryQuery['unhandled']>['nodes']['0']
  ): React.ReactNode => {
    if (mergeRequest.from?.__typename === 'Person') {
      return <Typography.Text>{mergeRequest.from.fullName}</Typography.Text>;
    }
    if (mergeRequest.from?.__typename === 'Site') {
      return <Typography.Text>{mergeRequest.from.name}</Typography.Text>;
    }
    if (mergeRequest.from?.__typename === 'SitePerson') {
      return <Typography.Text>{mergeRequest.from.id}</Typography.Text>;
    }
    return <Typography.Text>EntityType Currently not supported! Please contact support.</Typography.Text>;
  };

  const percentage = 1 - (unhandledCount / (handledCount + unhandledCount));

  return (
    <Skeleton loading={loading} active={loading}>
      <Alert
        type="warning"
        message="Merging of records is in alpha stage and may be changed in a future release of PureAdvance."
        style={{ width: 'clamp(480px, 75%, 640px)', marginBottom: 12 }}
      />
      <Space direction="vertical" style={{ width: 'clamp(480px, 75%, 640px)' }}>
        <div style={{ display: 'flex' }}>
          <Progress
            percent={percentage * 100}
            showInfo={false}
            status={automaticLoading ? 'active' : 'normal'}
          />
          <span style={{ marginLeft: 8, marginRight: 16 }}>
            {handledCount}&nbsp;/&nbsp;{handledCount + unhandledCount}
          </span>
        </div>
        {(data?.unhandled?.nodes ?? []).length > 0 && <>
          <Button
            loading={automaticLoading}
            onClick={() => performAutomatic({ variables: { entityType } }).finally(() => refetch())}
          >
            {localization.formatMessage(Locale.Command.Perform_automatic_merges_without_conflicts)}
          </Button>
          <List
            size="small"
            dataSource={data?.unhandled?.nodes ?? []}
            renderItem={(mergeRequest: NonNullable<EntityMergeAdminOverviewQueryQuery['unhandled']>['nodes']['0']) => (
              <List.Item
                key={mergeRequest.id}
                extra={<NavLink to={`/admin/merge/${mergeRequest.id}`}>
                  {mergeRequest.overview.hasConflict ? 'Fix conflict' : 'Preview'}
                </NavLink>}
              >
                {renderItem(mergeRequest)}
              </List.Item>
            )}
          />
        </>}
        <NavLink to={`/admin/merge?APB_ENTITY_TYPE=${EntityTypeId[entityType]}`}>View all</NavLink>
      </Space>
    </Skeleton>
  );
};

const AUTOMATIC_MUTATION = gql`
  mutation PerformAutomaticMerges($entityType: EntityTypeEnum!) {
    performAutomaticMerges(entityType: $entityType) {
      entityMergeId
    }
  }
`;

const DATA_QUERY = gql`
  query EntityMergeAdminOverviewQuery($entityType: EntityTypeEnum!) {
    handled: entityMerges(criteria: { entityType: $entityType, status: HANDLED }) {
      hash
      totalCount
    }
    unhandled: entityMerges(criteria: { entityType: $entityType, status: UNHANDLED, fetchSize: { limit: 5 } }) {
      hash
      totalCount
      nodes {
        id
        overview: mergeOverview {
          entityMergeId
          hasConflict
        }
        from { ...EntityFragment }
        into { ...EntityFragment }
      }
    }
  }

  fragment EntityFragment on Model {
    ... on Person {
      id
      fullName
    }
    ... on Site {
      id
      name
    }
    ... on SitePerson {
      id
    }
  }
`;

export default AdminEntityMergeRequestsOverview;
