import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, Col, Image, message, Modal, Row, Skeleton, Tooltip, Typography } from 'antd';
import React, { useRef, useState } from 'react';
import { ColProps } from 'antd/lib/grid/col';
import * as XLSX from 'xlsx';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import { fallbackImgBase64 } from '../../../../util/imageUtils';
import {
  ContactTypeEnum,
  EntityTypeEnum,
  GeneralSystemSettingsQueryQuery,
  InstanceSettingEnum,
  InstanceSettingFragmentFragment,
  UpdateInstanceSettingMutationMutation,
  UpdateInstanceSettingMutationMutationVariables,
  useUpsertContactValuesMutationMutation
} from '../../../../../gql/typings';
import { DCR_POPOVER_QUERY } from '../../../../components/DcrPopOver/DcrPopOver';
import { PersonConsentField_DATA_QUERY } from '../../../person/TabViews/PersonConsentFields';

const left: ColProps = { span: 4 };
const right: ColProps = { span: 20 };

interface ExcelSheetType {
  PureAdvanceID: number;
  Email: string;
}

// TODO: Make sure to add tooltip resources!
const typeNames: Record<'NAME'|'DOMAIN_PATH'|'IMG_PATH', {
  typeLabel: { id: string };
  tooltip?: { id: string };
}> = {
  [InstanceSettingEnum.NAME]: {
    typeLabel: Locale.Attribute.Name,
    // tooltip: { id: 'ab' },
  },
  [InstanceSettingEnum.DOMAIN_PATH]: {
    typeLabel: Locale.Attribute.Domain_path,
    // tooltip: { id: 'ab' },
  },
  [InstanceSettingEnum.IMG_PATH]: {
    typeLabel: Locale.Attribute.Login_footer_image,
    // tooltip: { id: 'ab' },
  },
};

const GeneralSystemSettings: React.FC = () => {
  const localization = useLocalization();
  const { data, loading } = useQuery<GeneralSystemSettingsQueryQuery>(DATA_QUERY);
  const [
    updateSetting,
  ] = useMutation<UpdateInstanceSettingMutationMutation, UpdateInstanceSettingMutationMutationVariables>(INSTANCE_MUTATION);
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setIsloading] = useState(false);
  const [runScript, setRunScript] = useState<boolean>(false);
  const [isRemove, setRemove] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [upsert] = useUpsertContactValuesMutationMutation();

  if (loading) return <Skeleton active />;

  const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    if (!e || !e.target || !e.target.files) return;
    reader.readAsBinaryString(e.target.files[0]!);
    reader.onload = async (er: ProgressEvent<FileReader>) => {
      const data = er!.target!.result;
      const workBook = XLSX.read(data, { type: 'binary' });
      const sheetName = workBook.SheetNames[0]!;
      const sheet = workBook.Sheets[sheetName!];
      const parseData = XLSX.utils.sheet_to_json(sheet!);
      if (parseData.length > 0) {
        setIsloading(true);
        const errorIds: number[] = [];
        for (let i = 0; i< parseData.length; i++) {
          const subData = parseData[i] as ExcelSheetType;
          // eslint-disable-next-line no-await-in-loop
          await upsert({
            refetchQueries: [DCR_POPOVER_QUERY, PersonConsentField_DATA_QUERY],
            variables: {
              type: ContactTypeEnum.EMAIL,
              entityType: EntityTypeEnum.SITE,
              entityAffiliationId: subData.PureAdvanceID,
              values: isRemove ? [] : [
                {
                  valueId: null,
                  value: subData.Email,
                  categoryCode: '5',
                  consentStatus: false
                }
              ]
            }
          }).catch(() => errorIds.push(subData.PureAdvanceID));
        }
        if (errorIds.length > 1) {
          messageApi.error(`Error for ${errorIds.join(',')}`);
        } else {
          messageApi.success('Sites email has been updated');
        }
        setRunScript(false);
        setIsloading(false);
      }
    };
  };

  const onUpdate = (enumType: InstanceSettingEnum) => (value: string) => {
    const type = Object
      .values(data!)
      .filter(e => typeof e === 'string' ? false : e?.enum == enumType)
      ?.[0] as InstanceSettingFragmentFragment|null;

    if (value === type?.value) return;
    updateSetting({
      variables: { type: enumType, value },
    }).then(() => {
      // @ts-ignore
      const messages = typeNames[type?.enum];

      const undo = () => updateSetting({
        variables: { type: enumType, value: type?.value },
      }).then(() => close());
      const close = messageApi.success(
        <span>
          {localization.formatMessage(Locale.Text.Successfully_updated_type_value, {
            type: <strong>{localization.formatMessage(messages.typeLabel)}</strong>,
            previousValue: <strong>{type?.value}</strong>,
            newValue: <strong>{value}</strong>,
          })}
          <Button type="link" onClick={undo}>
            {localization.formatMessage(Locale.Command.Undo)}
          </Button>
        </span>
      );
    });
  };

  return (
    <div className="general-system-settings-container">
      {contextHolder}
      {data && <Row>
        <Col {...left}>
          <Tooltip title={typeNames.NAME.tooltip ? localization.formatMessage(typeNames.NAME.tooltip) : ''}>
            <strong>
              {localization.formatMessage(typeNames.NAME.typeLabel)}
            </strong>:
          </Tooltip>
        </Col>
        <Col {...right}>
          <Typography.Paragraph editable={{ onChange: onUpdate(InstanceSettingEnum.NAME) }}>
            {data.name?.value}
          </Typography.Paragraph>
        </Col>

        <Col {...left}>
          <Tooltip title={typeNames.DOMAIN_PATH.tooltip ? localization.formatMessage(typeNames.DOMAIN_PATH.tooltip) : ''}>
            <strong>
              {localization.formatMessage(typeNames.DOMAIN_PATH.typeLabel)}
            </strong>:
          </Tooltip>
        </Col>
        <Col {...right}>
          <Typography.Paragraph editable={{ onChange: onUpdate(InstanceSettingEnum.DOMAIN_PATH) }}>
            {data.domain?.value}
          </Typography.Paragraph>
        </Col>

        <Col {...left}>
          <Tooltip title={typeNames.IMG_PATH.tooltip ? localization.formatMessage(typeNames.IMG_PATH.tooltip) : ''}>
            <strong>
              {localization.formatMessage(typeNames.IMG_PATH.typeLabel)}
            </strong>:
          </Tooltip>
        </Col>
        <Col {...right}>
          <figure>
            <Image
              style={{ maxHeight: 200, maxWidth: 400 }}
              src={data?.img?.value || ''}
              fallback={fallbackImgBase64}
              preview={false}
            />
            <figcaption>
              <Typography.Paragraph editable={{ onChange: onUpdate(InstanceSettingEnum.IMG_PATH) }}>
                {data.img?.value || <i>{localization.formatMessage(Locale.Text.No_image_provided)}</i>}
              </Typography.Paragraph>
            </figcaption>
          </figure>
        </Col>
      </Row>}
      <Button type='default' onClick={() => setRunScript(true)}>Run Upload Script</Button>
      <Modal
        title='Run Upload Script'
        open={runScript}
        onCancel={() => setRunScript(false)}
        closable
        onOk={() => setRunScript(false)}
      >
        <input
          type="file"
          ref={inputRef}
          onClick={() => inputRef!.current!.value = ''}
          onChange={onUpload}
          hidden
          id='import-excel'
        />
        <Checkbox checked={isRemove} onChange={e => setRemove(e.target.checked)}>Remove</Checkbox> <br />
        <Button
          style={{ marginTop: '20px' }}
          loading={isLoading}
          onClick={() => document?.getElementById('import-excel')?.click()}
        >
          Upload excel file
        </Button>
      </Modal>
    </div>
  );
};

const INSTANCE_MUTATION = gql`
  mutation UpdateInstanceSettingMutation($type: InstanceSettingEnum!, $value: String) {
    updateInstanceSetting(type: $type, newValue: $value) {
      code
      value
    }
  }
`;

const DATA_QUERY = gql`
  query GeneralSystemSettingsQuery {
    name: instanceSetting(type: NAME) { ...InstanceSettingFragment }
    img: instanceSetting(type: IMG_PATH) { ...InstanceSettingFragment }
    domain: instanceSetting(type: DOMAIN_PATH) { ...InstanceSettingFragment }
  }
  fragment InstanceSettingFragment on InstanceSetting {
    code
    value
    enum
  }
`;

export default GeneralSystemSettings;

