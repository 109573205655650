import React from 'react';
import { Form, Input, Modal } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { useLocalization } from '../../../../../util/useLocalization';
import { StateArray } from '../../../../../util/StateArrayType';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import CountryPicker from '../../../../../components/Input/Fields/CountryPicker';
import { CreateTerritoryMutationMutation, CreateTerritoryMutationMutationVariables } from '../../../../../../gql/typings';

type AdminCreateTerritoryModalProps = {
  visibleState: StateArray<boolean>;
  onRefetch: () => void;
};

const AdminCreateTerritoryModal: React.FC<AdminCreateTerritoryModalProps> = ({
  visibleState: [visible, setVisible],
  onRefetch,
}) => {
  const localization = useLocalization();
  const [form] = Form.useForm();
  const [
    create,
    { loading },
  ] = useMutation<CreateTerritoryMutationMutation, CreateTerritoryMutationMutationVariables>(CREATE_MUTATION);

  return (
    <Modal
      closable
      open={visible}
      confirmLoading={loading}
      onCancel={() => setVisible(false)}
      onOk={() => form.submit()}
      okText={localization.formatMessage(Locale.Command.Save)}
      title="Create new territory"
    >
      <Form<CreateTerritoryMutationMutationVariables>
        form={form}
        onFinish={values => create({ variables: values }).then(() => onRefetch()).then(() => setVisible(false))}
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="code"
          label="Code"
          rules={[
            { required: true },
            {
              validator: (rule, value) => {
                if (value.includes(' ')) return Promise.reject(Error('Spaces are not allowed'));
                return Promise.resolve();
              }
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="country"
          label="Country"
          rules={[{ required: true }]}
        >
          <CountryPicker />
        </Form.Item>
        <Form.Item
          name="heading"
          label="Heading"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const CREATE_MUTATION = gql`
  mutation CreateTerritoryMutation($code: String!, $country: CountryCode!, $heading: String!) {
    createTerritory(code: $code, country: $country, heading: $heading) {
      code
      heading
      countryCode
    }
  }
`;

export default AdminCreateTerritoryModal;
