import React from 'react';
import { gql } from '@apollo/client';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { EntityTypeEnum, MergeItemPersonContactValueFragment } from '../../../../../../../../../gql/typings';
import AdminMergeItemContactValue, {
  AdminMergeItemContactValue_FRAGMENT
} from '../../shared/ContactValue/AdminMergeItemContactValue';

const AdminMergeItemPersonContactValue: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonContactValueFragment;

  return (
    <AdminMergeItemContactValue
      {...props}
      entityType={EntityTypeEnum.PERSON}
      entityAffiliationWinnerId={props.into.id}
      value1={info.loserValue}
      value2={info.winnerValue}
    />
  );
};

gql`
  fragment MergeItemPersonContactValue on PersonContactValue_EntityDataToBeTransferredType {
    code
    loserValue { ...ContactValueInfo }
    winnerValue { ...ContactValueInfo }
  }
  
  ${AdminMergeItemContactValue_FRAGMENT}
`;

export default AdminMergeItemPersonContactValue;
