import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  useTableColumns
} from '../../components/entitiesSearch/results/useTableColumns';
import { buildLinkConnectionDataQuery } from '../link/LinkTable';
import EntityTable from '../../components/entitiesSearch/results/EntityTable';
import { useMassEntityValidationLogAction } from './Components/attributeFields/useMassEntityValidationLogAction';
import { useAddWorkplaceToPersonAction } from './hooks/useAddWorkplaceToPersonAction';
import { useRemoveWorkplaceFromPerson } from './hooks/useRemoveWorkplaceFromPerson';
import { useSetPrimaryLinkAction } from './hooks/useSetPrimaryLinkAction';
import {
  ActiveEnum,
  EntityTypeEnum,
  PersonSiteAffiliationsQueryQuery,
  PersonSiteAffiliationsQueryQueryVariables,
  SitePersonCriteria, SitePersonCriteriaOrder
} from '../../../gql/typings';
import { usePermission } from '../../permission/usePermission';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import { useDetailsContext } from '../../components/DetailsView/useDetailsContext';
import { useTableRowSelection } from '../../components/Table/useTableRowSelection';


type PersonSiteAffiliationsProps = {
  personId: number;
};

const PersonSiteAffiliationsTable: React.FC<PersonSiteAffiliationsProps> = ({ personId }) => {
  const localization = useLocalization();
  const tableSelection = useTableRowSelection({ virtualSupport: false });

  const { query, sorting, loading: columnsLoading } = useTableColumns(EntityTypeEnum.AFFILIATION_FROM_PERSON);

  const [includeInactive, setIncludeInactive] = useState(false);
  const { read } = usePermission('SEARCH_INACTIVE');

  const documentNode = buildLinkConnectionDataQuery(query);
  const documentNodeVariables: { criteria: SitePersonCriteria } = {
    criteria: {
      personId,
      isActive: read && includeInactive ? ActiveEnum.BOTH : ActiveEnum.ACTIVE,
      ordering: sorting?.map(s => s.currentEnum as SitePersonCriteriaOrder),
      fetchSize: {
        limit: tableSelection.tableProps.perPageState[0],
        offset: (tableSelection.tableProps.pageState[0] - 1) * tableSelection.tableProps.perPageState[0],
      },
    },
  };
  const {
    data,
    loading,
    error,
    refetch,
  } = useQuery(documentNode, { variables: documentNodeVariables });
  const {
    data: lookupData,
    refetch: lookupDataRefetch,
  } = useQuery<PersonSiteAffiliationsQueryQuery, PersonSiteAffiliationsQueryQueryVariables>(DATA_QUERY, {
    variables: { personId },
  });

  const massValidateAction = useMassEntityValidationLogAction(tableSelection, EntityTypeEnum.AFFILIATION);
  const addWorkplaceToPerson = useAddWorkplaceToPersonAction(personId, lookupData?.person?.countryCode);
  const removeWorkplaceFromPerson = useRemoveWorkplaceFromPerson(
    personId,
    tableSelection,
    lookupData?.person?.maintainerSourceCode === 'APB',
  );
  const primaryLink = useSetPrimaryLinkAction(lookupData, tableSelection);

  useDetailsContext(() => {
    refetch();
    lookupDataRefetch();
  });

  useEffect(() => {
    if (!loading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="person-site-affiliations-container">
      <EntityTable
        entityType={EntityTypeEnum.AFFILIATION_FROM_PERSON}
        tableSelection={tableSelection}
        actionsToTake={[
          {
            key: 'include-inactive',
            hide: !read,
            onClick: () => setIncludeInactive(a => !a),
            label: includeInactive
              ? localization.formatMessage(Locale.Command.Only_active)
              : localization.formatMessage(Locale.Command.Include_inactive),
          },
        ]}
        quickActions={[
          massValidateAction,
          addWorkplaceToPerson.action,
          removeWorkplaceFromPerson.action,
          primaryLink.action,
        ]}
        data={{
          nodes: data?.connection?.nodes ?? [],
          totalCount: data?.connection?.totalCount,
          loading: loading || columnsLoading,
          error
        }}
      />
      {addWorkplaceToPerson.hidden}
      {removeWorkplaceFromPerson.hidden}
      {primaryLink.hidden}
    </div>
  );
};

const DATA_QUERY = gql`
  query PersonSiteAffiliationsQuery($personId: Int!) {
    person(id: $personId) {
      id
      countryCode
      maintainerSourceCode
      affiliations {
        hash
        nodes {
          id
          persId
          siteId
          isPrimary
        }
      }
    }
  }
`;

export default PersonSiteAffiliationsTable;
