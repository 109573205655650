import { useApolloClient } from '@apollo/client';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { isAuthenticated, logout } from './authenticate';

const LogoutPage = () => {
  const client = useApolloClient();
  const navigate = useNavigate();


  useEffect(() => {
    if (isAuthenticated()) logout();
    client.resetStore(); // We'll completely reset our cache
    navigate('/', { replace: true });
  }, []);

  return <></>;
};
export default LogoutPage;
