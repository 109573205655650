import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Locale } from '../../../../../localization/LocalizationKeys';
import TransferComponent, { RecordTypeSpecialtiesDataItem } from '../../../../components/TransferModal/TransferComponent';
import { getVariableField } from '../../../person/fields/utils/personFieldsUtils';
import { usePersonFieldMutation } from '../../../person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import { TableFieldUpdateViewProps } from '../../../search_old/types';
import { useLocalization } from '../../../../util/useLocalization';
import {
  CustomFieldValueFragmentFragment,
  EntityCustomFieldOptionsQueryQuery,
  EntityCustomFieldOptionsQueryQueryVariables
} from '../../../../../gql/typings';
import { CUSTOM_REMOVE_MUTATION, CUSTOM_UPSERT_MUTATION, FIELD_OPTIONS } from './customFieldUtils';

const CustomMultiSelectInput: React.FC<TableFieldUpdateViewProps> = (props) => {
  const values = getVariableField(props!.record, props!.options, 'customFields') as CustomFieldValueFragmentFragment;
  const localization = useLocalization();
  const { data } = useQuery<EntityCustomFieldOptionsQueryQuery, EntityCustomFieldOptionsQueryQueryVariables>(FIELD_OPTIONS, {
    variables: {
      code: props.options.selectedOption!.code as string
    },
    fetchPolicy: 'network-only'
  });
  const selectedValues = values.nodes
    .map(s => s.value!.id.toString());

  const selectedState = useState<string[]>(selectedValues as string[]);

  const { Renderer } = usePersonFieldMutation({
    mutation: selectedState[0].length > 0 ? CUSTOM_UPSERT_MUTATION: CUSTOM_REMOVE_MUTATION,
    controlSetting: props.record?.controlSetting,
    skipDcrWarning: true,
    asModal: true
  });


  const availableValueItems: RecordTypeSpecialtiesDataItem[] = (data?.customField?.options?.nodes ?? []).map(s => ({
    key: s!.value!.id.toString(),
    title: s!.value!.value ?? '',
  }));

  const buildVariables = () => {
    if (selectedState[0].length > 0) {
      return {
        customFieldCode: props.options.selectedOption?.code,
        recordId: props.record.id,
        customValues: selectedState[0].map(id => ({ valueId: parseInt(id, 10) }))
      };
    }
    const toRemoveLinkId = values?.nodes.map(v => v.id);
    return {
      customValues: toRemoveLinkId
    };
  };

  return (
    <Renderer
      heading={localization.formatMessage(Locale.Command.Select)}
      onClose={props.endEditing}
      width="clamp(320px, 75%, 900px)"
      variables={{ ...buildVariables() }}
    >
      <TransferComponent dataSource={availableValueItems} selectedKeys={selectedState} />
    </Renderer>
  );
};

export default CustomMultiSelectInput;
