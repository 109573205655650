import React from 'react';
import { useMatch } from 'react-router';
import { gql } from '@apollo/client/core';
import { toNumber } from 'lodash';
import { useQuery } from '@apollo/client';
import { Divider, Skeleton } from 'antd';
import ReactJson from 'react-json-view';
import MainView from '../../../components/View/MainView';
import FinishedDetails from './FinishedDetails';

const ImportProjectDetails: React.FC = () => {
  const match = useMatch('/import/:id');
  const { data, loading } = useQuery(DATA_QUERY, { variables: { id: toNumber(match?.params?.id) } });
  return (
    <MainView className="import-project-detail-container" title={data?.project?.heading}>
      <Skeleton loading={loading} active={loading}>
        Show information about this imported project!
        <Divider />
        <ReactJson
          src={data?.project}
        />
        <Divider />
        <FinishedDetails projectId={toNumber(match?.params?.id)} />
      </Skeleton>
    </MainView>
  );
};

const DATA_QUERY = gql`
  query ImportProjectDetailsQuery($id: Int!) {
    project: importProject(id: $id) {
      id
      heading
      currentPhase {
        id
        heading
      }
    }
  }
`;

export default ImportProjectDetails;
