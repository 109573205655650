import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import DateDisplay from '../../../components/Badge/DateDisplay';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { PermissionEnum, SitePersonCriteriaOrder } from '../../../../gql/typings';


export const linkValidatedFieldConfig = linkFieldConfig(
  'validated',
  ['imfVt'],
  ({ localization }) => ({
    permission: PermissionEnum.VALIDATE_RECORD,
    title: localization.formatMessage(Locale.Attribute.Validated),
    additionalTableConfig: {
      width: 125
    },
    sorting: {
      ascend: SitePersonCriteriaOrder.IMF_VT_ASC,
      descend: SitePersonCriteriaOrder.IMF_VT_DESC,
    },
    render: record => <DateDisplay>{record.imfVt}</DateDisplay>,
  })
);
