import React from 'react';
import { CreateCustomFieldTypeSectionProps } from './createCustomFieldTypes';


const CreateCustomFreeTextInput: React.FC<CreateCustomFieldTypeSectionProps> = ({
  children,
}) => (
  <div style={{ display: 'flex', justifyContent: 'center', gap: 12, margin: 12 }}>
    {children}
  </div>
);


export default CreateCustomFreeTextInput;
