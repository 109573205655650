import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';

export const linkPersonPureAdvanceIdFieldConfig = linkFieldConfig(
  'personId',
  [
    'id',
    'persId',
  ],
  ({ localization, openInNewTab }) => ({
    title: localization.formatMessage(Locale.Attribute.Person_PureAdvance_ID),
    additionalTableConfig: {
      width: 100
    },
    render: (record) => <LinkBadge
      url={`/hcp/${record.persId}`}
      label={record.persId}
      openInNewTab={openInNewTab}
    />,
  })
);
