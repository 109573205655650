import { gql } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  SiteCriteriaOrder,
  UpdateSiteNumberOfBedsMutationMutationVariables
} from '../../../../gql/typings';
import { buildNumberUpdateFieldConfig } from '../../../components/FieldConfig/buildNumberUpdateFieldConfig';

export const siteNumberOfCBedsFieldConfig = siteFieldConfig(
  'numberOfBeds',
  [
    'id',
    'numberOfBeds',
    'controlSetting.id',
    'controlSetting.entityTypeId',
    'controlSetting.maintainerSourceCode',
    'controlSetting.createDcr',
  ],
  ({ localization }) => ({
    sorting: {
      ascend: SiteCriteriaOrder.NUMBER_OF_BEDS_ASC,
      descend: SiteCriteriaOrder.NUMBER_OF_BEDS_DESC,
    },
    title: localization.formatMessage(Locale.Attribute.Number_of_beds),
    additionalTableConfig: {
      width: 80
    },
    dcrInfo: (site) => ({
      entityType: EntityTypeEnum.SITE,
      entityAffiliationId: site.id,
      field: FieldEnum.HCO_NUMBER_OF_BEDS,
    }),
    render: (site) => site.numberOfBeds ?? '',
    updateView: buildNumberUpdateFieldConfig({
      mutation: UPDATE_SITE_NUMBER_OF_BEDS_MUTATION,
      valuePath: 'numberOfBeds',
      buildVariables: (value, record) => ({
        siteId: record.id,
        beds: value,
      }) as UpdateSiteNumberOfBedsMutationMutationVariables,
    })
  })
);

const UPDATE_SITE_NUMBER_OF_BEDS_MUTATION = gql`
  mutation UpdateSiteNumberOfBedsMutation($siteId: Int!, $beds: Int) {
    updateSiteNumberOfBeds(siteId: $siteId, numberOfBeds: $beds) {
      id
      numberOfBeds
    }
  }
`;
