import { Tooltip, Typography } from 'antd';
import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SiteCriteriaOrder } from '../../../../gql/typings';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';


export const siteVisitCityFieldConfig = siteFieldConfig(
  'visitCity',
  ['visitAddress.id', 'visitAddress.city'],
  ({ localization }) => ({
    sorting: {
      ascend: SiteCriteriaOrder.VISIT_CITY_ASC,
      descend: SiteCriteriaOrder.VISIT_CITY_DESC,
    },
    title: localization.formatMessage(Locale.Attribute.Visit_city),
    additionalTableConfig: {
      width: 150
    },
    // className: 'apb-antd-table-column-width-no-wrap',
    render: (record, { isViewingFromPage }) => {
      const visitCity = record.visitAddress?.city;
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={visitCity}>{visitCity}</Tooltip>
      </Typography.Paragraph>);
    },
  })
);
