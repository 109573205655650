import React from 'react';
import { gql } from '@apollo/client/core';
import { MutationTuple, useQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import { Select } from 'antd';
import UserAdminCard from './UserAdminCard';
import UserAdminCardRowItem from './UserAdminCardRowItem';
import { Locale } from '../../../../../localization/LocalizationKeys';
import {
  AdminUserQueryQuery,
  UpdateUserMutationMutation,
  UpdateUserMutationMutationVariables,
  UserAdminRoleQueryQuery, UserAdminRoleQueryQueryVariables
} from '../../../../../gql/typings';

type UserAdminRoleDetailsProps = {
  updateUser: MutationTuple<UpdateUserMutationMutation, UpdateUserMutationMutationVariables>[0];
  user: NonNullable<AdminUserQueryQuery['user']>;
  allowUpdate: boolean;
};

const UserAdminRoleDetails: React.FC<UserAdminRoleDetailsProps> = ({ user, allowUpdate, updateUser }) => {
  const { data } = useQuery<UserAdminRoleQueryQuery, UserAdminRoleQueryQueryVariables>(DATA_QUERY, {
    variables: { userId: user.id },
  });


  return (
    <UserAdminCard title="Roles" icon={null}>
      <UserAdminCardRowItem label={Locale.Attribute.Role}>
        {!allowUpdate
          ? <NavLink to={`/admin/role/${data?.user?.role.id}`}>{data?.user?.role.heading}</NavLink>
          : (
            <Select
              value={data?.user?.role.id}
              size="small"
              onChange={e => updateUser({ variables: { input: { userId: user.id, roleId: e } } })}
            >
              {data?.roles.nodes.map(role => (
                <Select.Option key={role.id} value={role.id}>{role.heading}</Select.Option>
              ))}
            </Select>
          )}
      </UserAdminCardRowItem>
      <UserAdminCardRowItem label={Locale.Attribute.Department}>
        <i><strong>TBD</strong></i>
      </UserAdminCardRowItem>
      <UserAdminCardRowItem label={{ id: 'Business Unit' }}>
        <i><strong>TBD</strong></i>
      </UserAdminCardRowItem>
      <UserAdminCardRowItem label={Locale.Attribute.Teams}>
        {data?.user?.teams.nodes.map(t => t.heading).join(',')}
      </UserAdminCardRowItem>
      <UserAdminCardRowItem label={{ id: 'Job Nature' }}>
        <i><strong>TBD</strong></i>
      </UserAdminCardRowItem>
      <UserAdminCardRowItem label={{ id: 'Organisational Chart' }}>
        <i><strong>TBD</strong></i>
      </UserAdminCardRowItem>
    </UserAdminCard>
  );
};

const DATA_QUERY = gql`
  query UserAdminRoleQuery($userId: Int!) {
    user(userId: $userId) {
      id
      role {
        id
        heading
      }
      teams {
        hash
        nodes {
          id
          heading: title
        }
      }
    }
    roles {
      hash
      nodes {
        id
        heading
      }
    }
  }
`;

export default UserAdminRoleDetails;
