import React, { useContext, useEffect, useRef } from 'react';
import { BadgeProps } from 'antd/lib/badge';
import { useSize, useThrottleFn } from 'ahooks';
import {
  PERSON_AFFILIATIONS_DATA_QUERY,
  PersonAffiliationsTabsEnum,
  personAffiliationTabConfigs,
} from '../../../browse/person/Components/PersonAffiliationsContainer';
import { EntityTabCardProps } from '../EntityTabTypes';
import {
  SITE_AFFILIATION_DATA_QUERY,
  SiteAffiliationsTabsEnum,
  siteAffiliationTabConfigs
} from '../../../browse/site/components/SiteAffiliationContainer';
import { LocalizationShape } from '../../../util/useLocalization';
import {
  LinkAffiliationsTabsEnum,
  linkAffiliationTabConfigs,
  LinkDetails_DATA_QUERY
} from '../../../browse/link/LinkAffiliationTabs';
import { Optional } from '../../../util/StateArrayType';
import EntityRelationTab from './EntityRelationTab';
import DynamicEntityTabContext from '../DynamicEntityTab/DynamicEntityTabContext';
import {
  EntityTypeEnum, LinkTabDetailQuery, PermissionEnum,
  PersonAffiliationsContainerQueryQuery,
  SiteAffiliationContainerQueryQuery
} from '../../../../gql/typings';

type ConfigProps = {
  recordId: number;
  configurationId: number;
};

const configs: Record<string, React.FC<ConfigProps>> = {
  [EntityTypeEnum.PERSON]: props => (
    <EntityRelationTab<PersonAffiliationsContainerQueryQuery>
      {...props}
      configs={personAffiliationTabConfigs}
      type="person"
      defaultTab={[PersonAffiliationsTabsEnum.activities, PersonAffiliationsTabsEnum.sites]}
      query={PERSON_AFFILIATIONS_DATA_QUERY}
    />
  ),
  [EntityTypeEnum.SITE]: props => (
    <EntityRelationTab<SiteAffiliationContainerQueryQuery>
      {...props}
      configs={siteAffiliationTabConfigs}
      type="site"
      defaultTab={[SiteAffiliationsTabsEnum.personAffiliations]}
      query={SITE_AFFILIATION_DATA_QUERY}
    />
  ),
  [EntityTypeEnum.AFFILIATION]: props => (
    <EntityRelationTab<LinkTabDetailQuery>
      {...props}
      configs={linkAffiliationTabConfigs}
      type="link"
      defaultTab={[LinkAffiliationsTabsEnum.personAffiliations]}
      query={LinkDetails_DATA_QUERY}
    />
  ),
};

export type RelationAffiliationTabConfig<T> = {
  title: { id: string };
  readPermission?: PermissionEnum;
  tab: {
    title?: (localization: LocalizationShape, data: Optional<T>) => string | React.ReactNode;
    show: boolean | ((data: Optional<T>) => boolean);
    badgeProps?: (data: Optional<T>) => BadgeProps;
  };
  render: (recordId: number, data: Optional<T>) => React.ReactNode;
};

const EntityRelationTabs: React.FC<EntityTabCardProps> = ({
  entityType,
  recordId,
  configuration,
}) => {
  const ToDisplay = configs[entityType] ?? (() => <span>'{entityType}' currently not supported</span>);
  const ref = useRef<HTMLDivElement>(null);
  const {
    gridState: [grid, setGrid],
    rootRef,
  } = useContext(DynamicEntityTabContext);
  // So we get it to re-render upon div size changes
  useSize(ref);
  useSize(rootRef);

  const { run, cancel } = useThrottleFn(
    () => setGrid(grid.map(item => {
      if (item.i === `${configuration.id}`) {
        return { ...item, h: item.h + 1 };
      } return item;
    })),
    { wait: 500 },
  );

  const thisBottom = ref.current ? calculateElementBottomPosition(ref.current) : -1;
  const rootBottom = rootRef.current ? calculateElementBottomPosition(rootRef.current) : -1;

  if (thisBottom < rootBottom) cancel();

  useEffect(() => {
    if (thisBottom >= rootBottom && thisBottom > 0 && rootBottom > 0) {
      // This will auto resize itself when more space is needed
      run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisBottom, rootBottom]);

  return (
    <div ref={ref}>
      <ToDisplay
        recordId={recordId}
        configurationId={configuration.id}
      />
    </div>
  );
};

const calculateElementBottomPosition = (element: HTMLDivElement) => {
  const rect = element.getBoundingClientRect();
  return rect.top + window.scrollY + rect.height;
};

export default EntityRelationTabs;
