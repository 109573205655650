import { Locale } from '../../../../localization/LocalizationKeys';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';

export const linkPersonAPureBaseIdFieldConfig = linkFieldConfig(
  'personAPureBaseId',
  [
    'person.id',
    'person.apurebaseId',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Person_aPureBase_ID),
    additionalTableConfig: {
      width: 100
    },
    render: (record) => record.person.apurebaseId,
  })
);
