/* eslint-disable react-hooks/exhaustive-deps */
import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { Dropdown, MenuProps, message, Popconfirm } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import ApbButton from '../../../components/Button/ApbButton';
import { ActivityDetailContext } from '../ActivityDetails/ActivityDetailsTypes';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import {
  ActivityDetailsSectionRightQueryQuery, ActivityDetailsSectionRightQueryQueryVariables,
  CompleteActivityMutationMutation,
  CompleteActivityMutationMutationVariables,
  DeleteActivityMutationMutation, DeleteActivityMutationMutationVariables
} from '../../../../gql/typings';

type ActivitySectionRightProps = {
  activityId: number | null;
  setVisualState?: () => void;
};

const ActivitySectionRight: React.FC<ActivitySectionRightProps> = ({
  activityId,
  setVisualState
}) => {
  const localization = useLocalization();
  const [
    completeActivity,
  ] = useMutation<CompleteActivityMutationMutation, CompleteActivityMutationMutationVariables>(CompleteActivity);
  const [deleteActivity] = useMutation<DeleteActivityMutationMutation, DeleteActivityMutationMutationVariables>(DeleteActivity);
  const navigate = useNavigate();
  const path = window.location.pathname;
  const { editAccess } = useContext(ActivityDetailContext);
  const [messageApi, contextHolder] = message.useMessage();
  const { data } = useQuery<ActivityDetailsSectionRightQueryQuery, ActivityDetailsSectionRightQueryQueryVariables>(DataQuery, {
    skip: !activityId,
    variables: {
      activityId: activityId ?? -1,
    },
  });


  const status = data?.activity?.status?.codeEnum;

  if (!editAccess) {
    return <></>;
  }


  const triggerUpdate = () => {
    completeActivity({
      variables: {
        // @ts-ignore
        activityId,
      },
      refetchQueries: ['ActivityStatusesQuery', 'ViewerActivitiesCalendar', 'ActivityDetail'],
    })
      .finally(() => messageApi.success(localization.formatMessage(Locale.Text.Activity_successfully_completed)));
  };
  const triggerDelete = () => {
    if (activityId) deleteActivity({
      variables: {
        activityId,
      },
      refetchQueries: ['ViewerActivitiesCalendar', 'ActivityDetail'],
    })
      .then(() => messageApi.success(localization.formatMessage(Locale.Text.Activity_successfully_deleted)))
      .finally(() => path.includes('calendar') ? setVisualState?.() : navigate('/activity'));
  };
  
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (<span>
        &nbsp;
        {localization.formatMessage(Locale.Command.Complete_Activity)}
      </span>),
      onClick: () => triggerUpdate(),
      disabled: !editAccess || status === 'DONE'
    },
    {
      key: '2',
      label: (
        <Popconfirm
          title={localization.formatMessage(Locale.Text.Are_you_sure_to_delete_this_task)}
          onConfirm={() => triggerDelete()}
          okText={localization.formatMessage(Locale.General.Yes)}
          cancelText={localization.formatMessage(Locale.General.No)}
        >
          <span>{localization.formatMessage(Locale.Command.Delete_Activity)}</span>
        </Popconfirm>
      ),
      disabled: !editAccess
    }
  ];

  return (
    <div className="content-right-section">
      {contextHolder}
      <div className="button-select-container">
        <Dropdown menu={{ items }} trigger={['click']}>
          <span onClick={e => e.preventDefault()}>
            <ApbButton
              icon={<DownOutlined />}
              size="large"
              buttonType="action"
              text={localization.formatMessage(Locale.Text.Actions_to_take)}
            />
          </span>
        </Dropdown>
      </div>
    </div>
  );
};


const CompleteActivity = gql`
  mutation CompleteActivityMutation(
    $activityId: Int!,
  ) {
    completeActivity(activityId: $activityId){
      id
      statusCode
      status {
        code
        headingKey
      }
    }
  }
`;
const DeleteActivity = gql`
  mutation DeleteActivityMutation(
    $activityId: Int!,
  ) {
    deleteActivity(activityId: $activityId) {
      id
      status {
        code
        headingKey
      }
    }
  }
`;

const DataQuery = gql`
  query ActivityDetailsSectionRightQuery($activityId: Int!) {
    activity(id: $activityId) {
      id
      status {
        code
        codeEnum
      }
    }
  }
`;

export default ActivitySectionRight;
