import { BlockProps } from 'antd/lib/typography/Base';
import { useRef, useState } from 'react';

type BuildEditableArgs<T> = {
  onSave?: (state: T, value: string) => void;
  onCancel?: () => void;
};

type EditableReturnType<T> = {
  editable: (state: T) => BlockProps['editable'];
  isEditing: boolean;
};


export function useEditable<T = unknown>(args?: BuildEditableArgs<T>): EditableReturnType<T> {
  const [isEditing, setIsEditing] = useState(false);
  const valueRef = useRef('');

  return {
    isEditing,
    editable: (state) => ({
      onChange: value => valueRef.current = value,
      onStart: () => {
        setIsEditing(true);
      },
      onEnd: () => {
        setIsEditing(false);
        args?.onSave?.(state, valueRef.current);
      },
      onCancel: () => {
        setIsEditing(false);
      },
    }),
  };
}
