import { useApolloClient } from '@apollo/client';
import { find } from 'lodash';
import { EntityTypeEnum } from '../../gql/typings';

const entityTypeMappings = {
  [EntityTypeEnum.PERSON]: 'Person',
  [EntityTypeEnum.SITE]: 'Site',
  [EntityTypeEnum.AFFILIATION]: 'SitePerson',
};

export const useIsAnyActive = (entityType: EntityTypeEnum, recordIds: number[]): [boolean] => {
  const apollo = useApolloClient();

  const a = find(
    recordIds,
    // @ts-ignore
    rId => apollo.cache.data.data[`${entityTypeMappings[entityType]}:${rId}`]?.isActive,
  );

  return [!!a];
};
