import { useQuery, gql } from '@apollo/client';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Skeleton } from 'antd';
import AffiliationCard from '../../../components/Card/AffiliationCard';
import Icon from '../../../components/Icon/Icon';
import { ActivityScrollListQuery, ActivityScrollListQueryVariables } from '../../../../gql/typings';

const PersonActivityContainer: React.FC<{ personId: number }> = ({
  personId,
}) => {
  const { data, loading } = useQuery<ActivityScrollListQuery, ActivityScrollListQueryVariables>(DATA_QUERY, {
    variables: { personId },
  });
  const activities = data?.person?.activities.nodes ?? [];

  return (
    <Skeleton loading={loading}>
      {activities.map(it => (
        <span key={it.id}>
          <AffiliationCard
            title={it.title}
            url={<><NavLink
              to={`/activity/${it.id}`}
            >
              {/* @ts-ignore */}
              <Icon iconType="LINK" />
            </NavLink></>}
            startDate={it.startDate}
            type={it.type}
            note={it.notes?.nodes[0]?.text ?? ''}
            users={it.responsibleUsers.nodes}
            createdDate={it.ct}
            status={it.status.code}
            endDate={it.endDate}
          />
        </span>
      ))}
    </Skeleton>
  );
};


const DATA_QUERY = gql`
  query ActivityScrollList($personId: Int!) {
    person(id: $personId) {
      id
      activities {
        totalCount
        nodes {
          id
          title: heading
          startDate
          endDate
          ct
          notes {
            hash
            nodes {
              id
              text
            }
          }
          status {
            code
            headingKey
          }
          type {
            code
            heading
            color {
              code
              colorName: headingKey
            }
          }
          responsibleUsers {
            hash
            nodes{
              id
              fullname
            }
          }
        }
      }
    }
  }
`;


export default PersonActivityContainer;
