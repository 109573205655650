import { gql, useMutation, useQuery } from '@apollo/client';
import { Image, Button, List, Modal } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { StateArray } from '../../../util/StateArrayType';
import { Locale } from '../../../../localization/LocalizationKeys';
import cherryPickUsageImage from '../../../../img/dashboard-icons/cherry-pick-usage.png';
import dataManagementImage from '../../../../img/dashboard-icons/Data-management.png';
import dcrImage from '../../../../img/dashboard-icons/DCR.png';
import entityPieChartImage from '../../../../img/dashboard-icons/Entity-pie-chart.png';
import newlyCreatedRecordsImage from '../../../../img/dashboard-icons/newly-created-records.png';
import {
  AddDashboardItemMutationMutation,
  AddDashboardItemMutationMutationVariables,
  AllDashboardItemsQueryQuery
} from '../../../../gql/typings';

type AddNewDashboardModalProps = {
  visibleState: StateArray<boolean>;
  editState: StateArray<boolean>;
  recentlyCreatedState: StateArray<number>;
  dashboardId: number;
};

const dashboardImagePreviews: Record<string, string> = {
  APB_ENTITY_PIE: entityPieChartImage,
  APB_RECORD_IMPORTED: newlyCreatedRecordsImage,
  APB_MASTERDATA_OVERVIEW: dataManagementImage,
  APB_DCR: dcrImage,
  APB_CHERRY: cherryPickUsageImage,
};

const AddNewDashboardModal: React.FC<AddNewDashboardModalProps> = ({
  visibleState: [visible,
    setVisible],
  recentlyCreatedState: [recentlyCreated, setRecentlyCreated],
  editState: [editMode,
    setEditMode], dashboardId
}) => {
  const intl = useIntl();
  const { data, loading } = useQuery<AllDashboardItemsQueryQuery>(DATA_QUERY);
  const [
    addDashboardItem,
  ] = useMutation<AddDashboardItemMutationMutation, AddDashboardItemMutationMutationVariables>(AddNewDashboardItemMutation);
  const addDashboard = (code?: string) => () => {
    setVisible(false);
    addDashboardItem({
      variables: {
        dashboardId,
        dashboardItemCode: code!
      },
      refetchQueries: ['DashboardViewQuery', 'DashboardItemsQuery'],
    })
      .then(res => setRecentlyCreated(res.data?.addDashboardItem.id ?? 0));

  };

  return (
    <Modal
      title={intl.formatMessage(Locale.Text.Add_to_grid)}
      width="clamp(320px, 70%, 760px)"
      open={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <List
        loading={loading}
        dataSource={data?.dashboardItems.nodes}
        renderItem={item => (
          <List.Item
            actions={[
              <Button key="add" onClick={addDashboard(item.code)} type="link">Add</Button>
            ]}
          >
            <List.Item.Meta
              avatar={<Image height={96} src={dashboardImagePreviews[item.code]} />}
              title={intl.formatMessage({
                id: item.headingKey,
                defaultMessage: item.headingKey,
              })}
              description={item.descriptionKey && intl.formatMessage({
                id: item.descriptionKey,
                defaultMessage: item.descriptionKey,
              })}
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

const DATA_QUERY = gql`
  query AllDashboardItemsQuery {
    dashboardItems {
      hash
      nodes {
        code
        headingKey
        descriptionKey
      }
    }
  }
`;

const AddNewDashboardItemMutation = gql`
    mutation AddDashboardItemMutation($dashboardId: Int!, $dashboardItemCode: String!) {
        addDashboardItem(dashboardId: $dashboardId, dashboardItemCode: $dashboardItemCode) {
            id
        }
    }
`;


export default AddNewDashboardModal;
