import { Transfer } from 'antd';
import type { TransferItem } from 'antd/es/transfer';
import React from 'react';
import { StateArray } from '../../util/StateArrayType';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';

export type RecordTypeSpecialtiesDataItem = TransferItem & {
  key: string;
  title: string;
  description?: string|null;
  chosen?: boolean|null;
};

interface TransferComponentsProps {
  dataSource: RecordTypeSpecialtiesDataItem[];
  selectedKeys: StateArray<string[]>;
}


const TransferComponents: React.FC<TransferComponentsProps> = ({
  dataSource,
  selectedKeys: [targetKeys, setTargetKeys],
}) => {
  
  const filterOption = (
    inputValue: string,
    option: RecordTypeSpecialtiesDataItem
  ) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  const handleChange = (newTargetKeys: string[]) => {
    setTargetKeys(newTargetKeys);
  };

  const localization = useLocalization();

  return (
    <Transfer<RecordTypeSpecialtiesDataItem>
      dataSource={dataSource}
      showSearch
      titles={[localization.formatMessage(Locale.General.Available), localization.formatMessage(Locale.General.Selected)]}
      listStyle={{
        width: 600,
        height: 400,
      }}
      filterOption={filterOption}
      oneWay
      showSelectAll={false}
      targetKeys={targetKeys}
      onChange={handleChange}
      render={item => item.title}
    />
  );
};

export default TransferComponents;
