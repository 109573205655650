import React from 'react';
import PersonMatching from './PersonMatching/ManualPersonMatching';
import UserMapping from './UserMapping/UserMapping';
import NoAction from './NoAction';
import SelectExternalSystem from './SelectExternalSystem/SelectExternalSystem';
import { ImportManualAcctionProps } from './ImportManualProcessesTypes';
import { CurrentImportProjectStateQuery } from '../../../../../gql/typings';

type AvailableImportProcesses = NonNullable<NonNullable<
CurrentImportProjectStateQuery['importProject']>['manual']>['__typename']|'ImportManualNoAction';


const availableManualProcesses: Record<AvailableImportProcesses, React.FC<ImportManualAcctionProps>> = {
  ImportManualPersonStage: PersonMatching,
  ManualExternalUserIdMapping: UserMapping,
  ImportManualNoAction: NoAction,
  SelectExternalSystemManualState: SelectExternalSystem,
  ImportManualEventMatchStage: NoAction,
};

export default availableManualProcesses;
