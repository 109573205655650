import React, { useContext, useEffect, useState } from 'react';
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useThrottle } from 'ahooks';
import { message, Select, Tag } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { useIntl } from 'react-intl';
import { useUser } from '../../../../util/useUser';
import { ActivityDetailContext } from '../ActivityDetailsTypes';
import { Locale } from '../../../../../localization/LocalizationKeys';
import {
  AttachmentRuleEnum,
  ResponsibleUsersSearchQueryQuery,
  ResponsibleUsersSearchQueryQueryVariables,
  UpdateActivityResponsibleUsersMutationMutation, UpdateActivityResponsibleUsersMutationMutationVariables
} from '../../../../../gql/typings';


const ActivityResponsibleUsersInput: React.FC<{ currentUserIds: number[] }> = ({ currentUserIds }) => {
  const intl = useIntl();
  const apolloClient = useApolloClient();
  const [search, setSearch] = useState<string>('');
  const throttledValue = useThrottle(search);
  const user = useUser();
  const {
    activityId,
    activityType,
    responsibleUserIds,
    dispatch,
  } = useContext(ActivityDetailContext);
  const { data, loading } = useQuery<ResponsibleUsersSearchQueryQuery, ResponsibleUsersSearchQueryQueryVariables>(DATA_QUERY, {
    variables: { userSearch: throttledValue },
  });
  const [
    setResUsers,
    { loading: mutationLoading },
  ] = useMutation<UpdateActivityResponsibleUsersMutationMutation, UpdateActivityResponsibleUsersMutationMutationVariables>(
    UPDATE_RESPONSIBLE_USERS_MUTATION
  );
  const rule = activityType?.responsibleUserRule;

  useEffect(() => {
    // Default select the logged-in user when creating a new activity
    if (!activityId && !responsibleUserIds) dispatch({
      type: 'updateResponsibleUsers',
      userIds: [user.id],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveOnBlur = (remove?: NonNullable<ResponsibleUsersSearchQueryQuery['users']>['nodes']['0']) => {
    setSearch('');
    const resIds = remove
      ? responsibleUserIds?.filter(id => id !== remove?.id) ?? []
      : responsibleUserIds ?? [];


    if (remove) dispatch({
      type: 'updateResponsibleUsers',
      userIds: resIds,
    });
    resIds !== currentUserIds && activityId && setResUsers({
      variables: {
        activityId,
        responsibleUserIds: resIds,
      }
    }).then(res => {
      if (!res.errors) message.info(intl.formatMessage(Locale.Text.Updated_responsible_users));
    });
  };

  const tagRender = (props: CustomTagProps) => {
    const record: NonNullable<ResponsibleUsersSearchQueryQuery['users']>['nodes']['0'] = apolloClient
      // @ts-ignore
      .cache.data.data[`User:${props.value}`];

    return (
      <Tag closable onClose={() => onSaveOnBlur(record)}>
        <span>
          {record?.fullname}
        </span>
      </Tag>
    );
  };

  return (
    <Select
      size='large'
      mode={rule === AttachmentRuleEnum.ONE || rule === AttachmentRuleEnum.OPTIONAL_ONE ? 'tags' : 'multiple'}
      tagRender={tagRender}
      loading={loading || mutationLoading}
      onSearch={setSearch}
      filterOption={false}
      onBlur={() => onSaveOnBlur()}
      value={responsibleUserIds}
      onChange={userIds => dispatch({ type: 'updateResponsibleUsers', userIds })}
      style={{ width: '100%' }}
    >
      {(data?.users.nodes || []).map(u => (
        <Select.Option key={u.id} value={u.id}>

          {u.fullname}
        </Select.Option>
      ))}
    </Select>
  );
};

const DATA_QUERY = gql`
  query ResponsibleUsersSearchQuery($userSearch: String) {
    users(criteria: { search: $userSearch, fetchSize: {limit: 1000} }) {
      hash
      nodes {
        id
        fullname
        email
      }
    }
  }
`;

const UPDATE_RESPONSIBLE_USERS_MUTATION = gql`
  mutation UpdateActivityResponsibleUsersMutation($activityId: Int!, $responsibleUserIds: [Int!]!) {
    updateActivityResponsibleUsers(activityId: $activityId, responsibleUserIds: $responsibleUserIds) {
      id
      responsibleUsers {
        hash
        nodes {
          id
          fullname
          email
        }
      }
    }
  }
`;

export default ActivityResponsibleUsersInput;
