import React from 'react';
import UserAdminCard from './UserAdminCard';
import { AdminUserQueryQuery } from '../../../../../gql/typings';

type UserAdminResponsibleAndTargetDetailsProps = {
  user: NonNullable<AdminUserQueryQuery['user']>;
  allowUpdate: boolean;
};

const UserAdminResponsibleAndTargetDetails: React.FC<UserAdminResponsibleAndTargetDetailsProps> = () => (
  <UserAdminCard title="Responsible areas & target cycles" icon="ID_CARD">
    <i><strong>TBD</strong></i>
  </UserAdminCard>
);

export default UserAdminResponsibleAndTargetDetails;
