import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { CheckOutlined, MinusOutlined } from '@ant-design/icons';
import { Input, List as ListAntd, Popover, Tooltip } from 'antd';
import { filter, find, includes, isUndefined, some, toLower } from 'lodash';
import * as PropTypes from 'prop-types';
import { Locale } from '../../../localization/LocalizationKeys';


// TODO: Instead of wrapping this from somewhere outside, it should probably do it directly here instead.
const Dropdown = ({
  disabled = false,
  onVisibleChange: onVisibleChangeProp = () => {},
  items,
  onChange = () => {},
  onSelect: onSelectProp = () => {},
  hideOnSelect = true,
  initialKey,
  selectedKey: selectedKeyProp,
  multiSelect = false,
  id,
  children,
  active: activeProp,
  placement = 'bottomLeft',
  allowSearch = true,
  trigger = 'click',
  form,
}) => {
  const [search, setSearch] = useState('');
  const [active, setActive] = useState(false);
  const { formatMessage } = useIntl();

  const onVisibleChange = status => {
    if (disabled) return;
    setActive(status);
    return onVisibleChangeProp(status);
  };

  const onSelect = selected => {
    const item = find(items, ({ key }) => key === selected);

    hideOnSelect && onVisibleChange(false);

    onSelectProp(item);
    onChange(selected); // Used when decorated with antd fieldDecorator

    setSearch('');
  };

  const Search = () => (
    <Input.Search
      autoFocus
      className="dropdown-search"
      placeholder={formatMessage(Locale.Command.Search)}
      size="large"
      value={search}
      onChange={e => setSearch(e.target.value)}
    />
  );

  const List = () => {
    const loweredSearch = toLower(search);

    const dataSource = filter(items, i => includes(toLower(i.label), loweredSearch)
          || includes(toLower(i.key), loweredSearch));

    return (
      <div className="popover-container">
        <ListAntd
          size="small"
          dataSource={dataSource}
          renderItem={item => {
            const selected = multiSelect
              ? (selectedKeyProp || initialKey).some(k => `${k}` === `${item.key}`)
              : `${(selectedKeyProp || initialKey)}` === `${item.key}`;

            return (
              <ListAntd.Item
                key={item.key}
                className={selected ? 'item-selected' : ''}
                onClick={() => onSelect(item.key)}
              >
                {item.label}
                {selected && <CheckOutlined />}
              </ListAntd.Item>
            );
          }}
        />
      </div>
    );
  };


  const show = isUndefined(activeProp) ? active : activeProp;

  let child = children;
  if (!child && form) {
    const selectedKey = form.getFieldValue(id);
    const selected = multiSelect
      ? find(items, ({ key }) => some(selectedKey, key))
      : find(items, ({ key }) => key === selectedKey);

    child = (
      <span style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
        <Tooltip
          title={selected.tooltip}
          placement="topRight"
        >
          {selected ? (selected.label || selected.value) : <MinusOutlined />}
        </Tooltip>
      </span>
    );
  }

  return (
    <Popover
      placement={placement}
      title={allowSearch && <Search />}
      content={<List />}
      trigger={trigger}
      overlayClassName="dropdown-overlay-container"
      onOpenChange={onVisibleChange}
      open={show}
      className="form-dropdown-container"
    >
      {child}
    </Popover>
  );
};

Dropdown.propTypes = {
  id: PropTypes.string,
  allowSearch: PropTypes.bool,
  onSelect: PropTypes.func,
  placement: PropTypes.oneOf([
    'top', 'left', 'right', 'bottom', 'topLeft', 'topRight',
    'bottomLeft', 'bottomRight', 'leftTop', 'leftBottom', 'rightTop', 'rightBottom',
  ]),
  trigger: PropTypes.oneOf([
    'hover', 'focus', 'click', 'contextMenu',
  ]),
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
  form: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  selectedKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  initialKey: PropTypes.any,
  multiSelect: PropTypes.bool,
  hideOnSelect: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  })).isRequired,
  children: PropTypes.node,
};

Dropdown.defaultProps = {
  id: null,
  allowSearch: true,

  // MARK: [selectedKey] Is undefined, but if undefined is provided and is decorated via antd decorator, it'll report errors
  // selectedKey: undefined,

  placement: 'bottomLeft',
  trigger: 'click',
  active: undefined,
  form: undefined,
  initialKey: undefined,
  onVisibleChange: () => {},
  onChange: () => {},
  onSelect: () => {},
  disabled: false,
  multiSelect: false,
  hideOnSelect: true,
  children: undefined,
};

export default Dropdown;
