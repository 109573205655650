import React, { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import NoteDrawer, { NoteDrawer_FRAGMENT } from '../../../components/Note/NoteDrawer';
import AdvanceCard from '../../../components/ContactCard/AdvanceCard';
import { ActivityDetailContext } from './ActivityDetailsTypes';
import HeaderWithValue from '../../../components/DisplayFields/HeaderWithValue';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  ActivityDetailQuery,
  ActivityDetailQueryVariables,
  EntityTypeEnum,
  NoteDrawerFragmentFragment
} from '../../../../gql/typings';


const ActivityNoteContainer: React.FC = () => {
  const { activityId, editAccess } = useContext(ActivityDetailContext);
  if (!activityId) {
    return <></>;
  }
  const [note, setNote] = useState<NoteDrawerFragmentFragment | null>(null);
  const { data } = useQuery<ActivityDetailQuery, ActivityDetailQueryVariables>(ACTIVITY_NOTE_DATA_QUERY, {
    variables: { id: activityId },
  });
  const [displayDrawer, setDisplayDrawer] = useState(false);

  const onClick = (it: NonNullable<ActivityDetailQuery['activity']>['notes']['nodes']['0']) => {
    setDisplayDrawer(true);
    setNote(it);
  };

  const intl = useIntl();
  const notes: NonNullable<ActivityDetailQuery['activity']>['notes']['nodes'] = data?.activity?.notes.nodes ?? [];
  const count = data?.activity?.notes.totalCount ?? 0;

  return (
    <HeaderWithValue heading={intl.formatMessage(Locale.Attribute.Notes_count, { count })}>
      <div className={`cards-container-clear activity-card-notes-list ${notes.length === 0 ? 'border-when-empty' : ''}`}>
        {notes.map(it => <span key={it.id}>
          <AdvanceCard
            title={it.title}
            description={it.text}
            createdUser={it.user?.fullname}
            onClick={() => onClick(it)}
            icon="NOTE"
            date={it.ct}
          />
        </span>)}
        {editAccess && (
          <Button
            type='dashed'
            size='small'
            icon={<PlusOutlined />}
            className='add-button-absolute'
            onClick={() => setDisplayDrawer(true)}
          >
            {intl.formatMessage(Locale.Command.Add_Note)}
          </Button>
        /*  <AddCard
            onClick={() => setDisplayDrawer(true)}
            small
            label={intl.formatMessage(Locale.Command.Add_Note)}
          /> */
        )}
      </div>

      <NoteDrawer
        id={activityId}
        noteState={[note, setNote]}
        entity={EntityTypeEnum.ACTIVITY}
        displayDrawer={displayDrawer}
        setDisplayDrawer={setDisplayDrawer}
      />

    </HeaderWithValue>

  );
};

export const ACTIVITY_NOTE_DATA_QUERY = gql`
  query ActivityDetail($id: Int!) {
    activity(id: $id) {
      id
      notes {
        hash
        totalCount
        nodes {
          id
          ...NoteDrawerFragment
        }
      }
    }
  }
  ${NoteDrawer_FRAGMENT}
`;


export default ActivityNoteContainer;

