import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { LinkColumn } from '../../../components/Table/utils';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SitePersonCriteriaOrder } from '../../../../gql/typings';

export const linkMiddleNameFieldConfig = linkFieldConfig(
  'middleName',
  [
    'id',
    'person.id',
    'person.middleName',
  ],
  ({ localization, openInNewTab }) => ({
    title: localization.formatMessage(Locale.Attribute.Middle_name),
    additionalTableConfig: {
      width: 150
    },
    sorting: {
      ascend: [SitePersonCriteriaOrder.PERSON_MIDDLENAME_ASC],
      descend: [SitePersonCriteriaOrder.PERSON_MIDDLENAME_DESC],
    },
    render: (record) => {
      if (record.person.middleName) {
        <LinkColumn
          text={record.person.middleName}
          url={`/hcp/${record.person.id}`}
          openInNewTab={openInNewTab}
        />;
      }
    },
  })
);

