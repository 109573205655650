import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import OurRoutes from './OurRoutes';


type AppState = {
  error?: Error;
};

class App extends Component<unknown, AppState> {
  constructor(props: Readonly<unknown>) {
    super(props);

    this.state = {
      error: undefined,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        // @ts-ignore
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;
    if (error) {
      Sentry.showReportDialog();
      return <></>;
    }

    return (
      <BrowserRouter>
        <OurRoutes />
      </BrowserRouter>
    );
  }
}

export default App;
