import React from 'react';
import { Col, Row } from 'antd';
import Puffin, { PuffinType } from '../../components/Puffin/Puffin';
import MainView from '../../components/View/MainView';

const RandomPuffin: React.FC = () => (
  <MainView className="random-puffin-container">
    <Row>
      {(['explaining', 'crashLand', 'menu', 'waiting', 'waving'] as PuffinType[]).map(type => (
        <Col key={type} md={24} lg={12} xl={8}>
          <Puffin type={type} loop />
        </Col>
      ))}
    </Row>
  </MainView>
);

export default RandomPuffin;
