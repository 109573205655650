import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { get, isArray, isEmpty, keys } from 'lodash';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { FormInstance, Rule } from 'antd/lib/form';
import { Locale } from '../../../../localization/LocalizationKeys';
import { AnyValueType, Optional } from '../../../util/StateArrayType';
import {
  GenerateReportMutationMutation,
  GenerateReportMutationMutationVariables, ReportInput,
  ReportInputEnum,
  ReportInputsQueryQuery,
  reportsQuery
} from '../../../../gql/typings';


export type ReportInputDef = {
  key: ReportInputEnum;
  labelKey?: string|null;
  placeholderKey?: Optional<string>;
  rules: Rule[];
};

export type ReportControllerType = {
  reportsData: reportsQuery | undefined;
  reportsLoading: boolean;
  form: FormInstance;
  nextStep: () => void;
  prevStep: () => void;
  setStep: (step: number) => void;
  setSelectedReport: (report: NonNullable<reportsQuery['reports']>['nodes']['0']) => void;
  selectedReport?: NonNullable<reportsQuery['reports']>['nodes']['0'];
  currentStep: number;
  isLastStep: () => boolean;
  setInputValue: (labelKey: string, value: AnyValueType) => void;
  allowPreviousStep: () => boolean;
  allowNextStep: () => boolean;
  getInputData: () => { loading: boolean; inputDefs: ReportInputDef[] };
  mergeTheShit: () => void;
  isGeneratingReport: boolean;
};

const ReportController = (): ReportControllerType => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { data: reportsData, loading: reportsLoading } = useQuery<reportsQuery>(DATA_QUERY);
  const [loadInputDef, {
    data: inputData,
    loading: inputLoading,
    called: inputCalled,
    error: inputQueryError,
  }] = useLazyQuery<ReportInputsQueryQuery>(INPUT_QUERY);

  const [currentStep, setCurrentStep] = useState(0);
  const [selected, setSelected] = useState<NonNullable<reportsQuery['reports']>['nodes']['0']>();
  const [inputValues, setInputValues] = useState<Record<string, AnyValueType>>({});
  const [
    generateReport,
    { loading: isGeneratingReport },
  ] = useMutation<GenerateReportMutationMutation, GenerateReportMutationMutationVariables>(REPORT_GENERATE_MUTATION);

  // Whenever a new template is selected we want to load all the input definitions a head of time.
  useEffect(() => {
    if (inputLoading) return;
    const reportCode = get(selected, 'code');
    if (!reportCode) return;
    if (reportCode === get(inputData, 'report.code', 'random')) return;
    if (inputQueryError) return;
    loadInputDef({ variables: { reportCode } });
  }, [inputData, inputLoading, loadInputDef, selected, inputQueryError]);

  function setSelectedReport(record: NonNullable<reportsQuery['reports']>['nodes']['0']) {
    if (selected && record && record.code === selected.code) setSelected(undefined);
    else setSelected(record);
  }

  function setInputValue(labelKey: string, value: AnyValueType) {
    setInputValues({ ...inputValues, [labelKey]: value });
  }

  // function isSelected(record) {
  //   return selected && selected.id === record.id;
  // }
  function allowPreviousStep() {
    return currentStep !== 0;
  }
  function allowNextStep() {
    if (currentStep === 0) {
      return !isEmpty(selected);
    }
    if (currentStep === 1) {
      // We don't care.
    }
    return true;
  }

  /**
   * If we are on step 1 we'll validate that all inputs are valid before allowing to continue.
   */
  function nextStep() {
    if (currentStep === 1) {
      form.validateFields().then(values => {
        // As ant form is removing all non-displayed values,
        // we will save the values into our own state before continuing.
        setInputValues(values);
        setStep(currentStep + 1);
      });
    } else {
      setStep(currentStep + 1);
    }
  }
  function prevStep() {
    setStep(currentStep - 1);
  }
  function setStep(step: number) {
    setCurrentStep(step);
  }

  function isLastStep() {
    return currentStep !== 2;
  }

  function mergeTheShit() {
    if (!selected) return;
    const input: ReportInput = {
      templateCode: selected.code,
      values: keys(inputValues).map(labelKey => {
        const value = inputValues[labelKey];
        return {
          labelKey,
          [isArray(value) ? 'values' : 'value']: value,
        };
      }),
    };

    return generateReport({ variables: { input } })
      .then(res => {
        if (!res.errors && res.data) {
          // Download the file directly
          window.location.href = res.data.generateReport.url;
        }
      });
  }

  function getInputData(): { inputDefs: ReportInputDef[]; loading: boolean } {
    if (!inputCalled || inputLoading || !inputData?.report) return { inputDefs: [], loading: true };

    return {
      loading: false,
      inputDefs: inputData.report.inputDefs.map(e => ({
        ...e,
        rules: e.rules.map(rule => ({
          // TODO: Add support from more rules provided by the backend!
          required: rule.required,
          message: rule.messageKey
            ? formatMessage({ id: rule.messageKey })
            : formatMessage(Locale.General.Invalid_input),
        })),
      })),
    };
  }

  /** @class ReportController */
  return {
    reportsData,
    reportsLoading,
    form,
    nextStep,
    prevStep,
    setStep,
    setSelectedReport,
    selectedReport: selected,
    currentStep,
    isLastStep,
    setInputValue,
    allowPreviousStep,
    allowNextStep,
    getInputData,
    mergeTheShit,
    isGeneratingReport,
  };
};

const REPORT_GENERATE_MUTATION = gql`
  mutation GenerateReportMutation($input: ReportInput!) {
    generateReport(input: $input) {
      url
    }
  }
`;


const DATA_QUERY = gql`
  query reports {
    reports {
      totalCount
      hash
      nodes {
        code
        sort
        heading
        description
        ut
        ct
      }
    }
  }
`;

const INPUT_QUERY = gql`
  query ReportInputsQuery($reportCode: String!) {
    report(code: $reportCode) {
      code
      inputDefs {
        key
        labelKey
        placeholderKey
        rules {
          type
          messageKey
          required
          # TODO: Probably should add all!
        }
      }
    }
  }
`;

export default ReportController;
