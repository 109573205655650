import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import * as Sentry from '@sentry/browser';
import { IntlProvider } from 'react-intl';
import moment from 'moment';

import enUS from 'antd/es/locale/en_US';
import deDE from 'antd/es/locale/de_DE';

import dayjs from 'dayjs';
import App from './js/App';
import apolloClient from './js/apollo/apolloClient';
import './styles/ant.less';
import './styles/main.less';
import '../node_modules/antd/dist/reset.css';
import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';

import 'dayjs/locale/fo';
import 'dayjs/locale/da';
import 'dayjs/locale/sv';
import 'dayjs/locale/nn';
import 'dayjs/locale/fi';
import 'dayjs/locale/de';
import 'dayjs/locale/nl';

import 'moment/locale/fo';
import 'moment/locale/da';
import 'moment/locale/sv';
import 'moment/locale/nn';
import 'moment/locale/fi';
import 'moment/locale/de';
import 'moment/locale/nl';

import messagesEn from './localization/locale/en.json';
import messagesDe from './localization/locale/de.json';
// import messagesSe from './localization/locale/sv_SE.json';
import messagesFo from './localization/locale/fo_FO.json';


Sentry.init({
  dsn: 'https://e05b0a3846e54e5693d18a1951cc0c1c@sentry.io/1381087',
});

const language = navigator.language.split(/[-_]/)[0]; // language without region code

const locales = {
  en: {
    dayjs: 'en',
    moment: 'en',
    antd: enUS,
    messages: messagesEn,
  },
  de: {
    dayjs: 'de',
    moment: 'de',
    antd: deDE,
    messages: messagesDe,
  },
  fo: {
    dayjs: 'fo',
    moment: 'fo',
    antd: enUS,
    messages: messagesFo,
    formats: {

    },
  },
  // Should be re-enabled when PUR-1686 is implemented
  // sv: {
  //   moment: 'sv',
  //   antD: svSE,
  //   messages: messagesSe,
  // },
};

export const localeSettings = locales[language] || locales.en;

moment.locale(localeSettings.moment);
dayjs.locale(localeSettings.dayjs);

const container = document.getElementById('root');
const root = createRoot(container);

const theme = {
  token: {
    fontFamily: 'Manrope',
    colorPrimary: '#C2CE26',
    colorInfo: '#C2CE26',
    colorText: 'rgba(0,0,0, .65)',
    colorSuccess: '#52c41a',
    colorWarning: '#faad14',
    colorError: '#f5222d',
    fontSize: 13,
    lineHeight: 1.5715,
    borderRadius: 5
  }
};

root.render(
  <ConfigProvider locale={localeSettings.antd} theme={theme}>
    <IntlProvider
      locale={language}
      messages={localeSettings.messages}
      formats={localeSettings.formats}
    >
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </IntlProvider>
  </ConfigProvider>
);
