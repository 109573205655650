import React from 'react';
import { minBy, maxBy, times } from 'lodash';
import { PieChart } from '../../../Charts';
import { DashboardPieHook } from './useDashboardPieHook';
import { DashboardItemFragmentFragment } from '../../../../../gql/typings';


export type EntityPieChartsProps = {
  configuration: DashboardItemFragmentFragment;
  editMode: boolean;
  size: { width: number; height: number };
  allowLastSelected?: boolean;
  maxConfigurations: number;
  pie: DashboardPieHook;
};

const EntityPieCharts: React.FC<EntityPieChartsProps> = ({
  editMode,
  size,
  pie,
  maxConfigurations,
  allowLastSelected,
  configuration,
}) => {
  const itemsCount = allowLastSelected
    ? maxConfigurations
    : minBy([pie.listData.length, maxConfigurations])!;

  const { height } = size;
  const width = allowLastSelected
    ? maxBy([size.width, height])
    : size.width * (itemsCount === 1 ? 1 : (0.4 / maxBy([1, itemsCount - 1])!));

  return (
    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      {times(itemsCount).map(i => (
        <PieChart
          key={i}
          interaction
          width={width}
          height={height}
          onSelect={(selected, a) => {
            pie.replace(i, {
              ...pie.listData[i],
              selected: a === 'selected' ? selected : undefined,
            });
          }}
          visible={pie.listData[i] && !editMode}
          data={pie.listData[i]?.data?.dashboardEntityPieData?.values ?? []}
        />
      ))}
    </div>
  );
};

export default EntityPieCharts;
