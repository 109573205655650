import React, { useEffect, useRef } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useDynamicList, useThrottleFn } from 'ahooks';
import { Input, InputRef, Select, Space, Tooltip } from 'antd';
import { isFunction, isString } from 'lodash';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useFieldOptionAttributes } from '../../../../../components/Card/useFieldOptionAttributes';
import {
  TableColumnTreeFnReturn,
  useTableColumnTreeDataFn
} from '../../../../../components/ConfigureTableColumnSettings/useTableColumnTreeDataFn';
import { entitiesTableConfigs, TableFieldReturnedRecordPageType } from '../../../../search_old/types';
import { DynamicList } from '../../../../../util/AHookTypes';
import {
  AdminDetailedGridCardsPreviewQueryDocument,
  ConfigureGridCardsMutationMutation,
  ConfigureGridCardsMutationMutationVariables,
  ConfigureGridCardsQueryQuery,
  ConfigureGridCardsQueryQueryVariables,
  EntityGridItemCardFieldTypeEnum,
  EntityTypeEnum,
  FieldFragmentFragment, LocalizationKeysSearchQuery, LocalizationKeysSearchQueryVariables
} from '../../../../../../gql/typings';

export type SupportGridCardEntityTypes = EntityTypeEnum.PERSON|EntityTypeEnum.AFFILIATION|EntityTypeEnum.SITE;

export type GridCardsDataElement = Pick<FieldFragmentFragment, 'fieldKey'|'heading'|'isHeadingKey'> & { id: string };

export type GridRightCardsDataReturned = {
  loading: boolean;
  isUpdating: boolean;
  dynamicMain: DynamicList<GridCardsDataElement>;
  dynamicMeta: DynamicList<GridCardsDataElement>;
  mainTreeData: TableColumnTreeFnReturn;
  metaTreeData: TableColumnTreeFnReturn;
  onListUpdate: (type: 'main'|'meta') => (newState: GridCardsDataElement[]) => void;
  HeadingRenderer: React.FC<{ record: GridCardsDataElement; type: 'main'|'meta' }>;
};

export function useGridRightCardsData(
  entityType: SupportGridCardEntityTypes,
  itemConfigurationId: number,
): GridRightCardsDataReturned {
  const config = entitiesTableConfigs[entityType];
  const dynamicMain = useDynamicList<GridCardsDataElement>([]);
  const dynamicMeta = useDynamicList<GridCardsDataElement>([]);
  const { items } = useFieldOptionAttributes(entityType, Object.keys(config.columnConfig.fields));
  const { optionMapGet } = useFieldOptionAttributes(entityType);
  const mainTreeData = useTableColumnTreeDataFn({
    items,
    optionMapGet,
    list: dynamicMain.list.map(e => ({ id: e.fieldKey })),
  });
  const metaTreeData = useTableColumnTreeDataFn({
    items,
    optionMapGet,
    list: dynamicMeta.list.map(e => ({ id: e.fieldKey })),
  });


  let main: EntityGridItemCardFieldTypeEnum;
  let meta: EntityGridItemCardFieldTypeEnum;

  switch (entityType) {
    case EntityTypeEnum.PERSON:
      main = EntityGridItemCardFieldTypeEnum.HCP_MAIN;
      meta = EntityGridItemCardFieldTypeEnum.HCP_META;
      break;
    case EntityTypeEnum.SITE:
      main = EntityGridItemCardFieldTypeEnum.HCO_MAIN;
      meta = EntityGridItemCardFieldTypeEnum.HCO_META;
      break;
    case EntityTypeEnum.AFFILIATION:
      main = EntityGridItemCardFieldTypeEnum.LINK_MAIN;
      meta = EntityGridItemCardFieldTypeEnum.LINK_META;
      break;
    default:
      throw Error(`Only the three standard types are currently supported. Provided ${entityType}`);
  }

  const { data, loading } = useQuery<ConfigureGridCardsQueryQuery, ConfigureGridCardsQueryQueryVariables>(DATA_QUERY, {
    variables: {
      itemConfigurationId,
      main,
      meta,
    }
  });
  const [
    update,
    { loading: isUpdating },
  ] = useMutation<ConfigureGridCardsMutationMutation, ConfigureGridCardsMutationMutationVariables>(UPDATE_MUTATION);

  useEffect(() => {
    if (!loading) {
      dynamicMain.resetList(data?.main.nodes?.map(n => ({ ...n, id: n.fieldKey })) ?? []);
      dynamicMeta.resetList(data?.meta.nodes?.map(n => ({ ...n, id: n.fieldKey })) ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);


  const onListUpdate = (type: 'main'|'meta') => (newState: GridCardsDataElement[]) => update({
    refetchQueries: [AdminDetailedGridCardsPreviewQueryDocument],
    variables: {
      entityGridItemCardFieldType: type === 'main' ? main : meta,
      entityGridItemConfigurationId: itemConfigurationId,
      fields: newState.map(e => ({
        fieldKey: e.fieldKey,
        isHeadingKey: e.isHeadingKey,
        heading: e.heading,
      })),
    }
  });

  return {
    loading,
    isUpdating,
    mainTreeData,
    metaTreeData,
    dynamicMain,
    dynamicMeta,
    onListUpdate,
    HeadingRenderer: ({ record, type }) => {
      type Types = 'standard'|'custom'|'resource';
      const mode: Types = record.isHeadingKey
        ? 'resource'
        : isString(record.heading)
          ? 'custom'
          : 'standard';
      const {
        data,
        refetch,
        loading,
      } = useQuery<LocalizationKeysSearchQuery, LocalizationKeysSearchQueryVariables>(LOCALIZATION_KEYS, {
        skip: mode !== 'resource',
      });
      const customRef = useRef<InputRef>(null);
      const { run: resourceSearch } = useThrottleFn((search: string) => {
        refetch({ search: `%${search}%` });
      }, { wait: 300 });

      const getStandardTitle = () => {
        const [key, code] = record.fieldKey.split(':');
        const test = items.filter(([cc, a]) => {
          if (code) return cc.key === key && a.keyCode == code;
          return cc.key === key;
        })[0];
        if (test) {
          return isFunction(test[0].title) ? test[0].title?.({
            refetchData: () => {
            },
            isViewingFromPage: TableFieldReturnedRecordPageType.DETAIL_PAGE,
            isViewingFromEntity: EntityTypeEnum.PERSON,
            selectedOption: code ? { code, label: 'Unknown' } : undefined,
          }) : test[0].title;
        }
        return (
          <Tooltip
            title="Currently admin can't display the standard heading for fields with codes in them or when missing permission"
          >
            <i>Unknown</i> <QuestionCircleOutlined />
          </Tooltip>
        );
      };

      const updateRecord = (heading?: string, isHeadingKey?: boolean) => {
        const l = type === 'main' ? dynamicMain : dynamicMeta;
        const index = l.list.map(e => e.id).indexOf(record.id);
        const newList = [...l.list];

        newList[index]!.heading = heading;
        newList[index]!.isHeadingKey = isHeadingKey ?? false;

        l.resetList(newList);
        onListUpdate(type)(newList);
      };

      const changeMode = (selected: Types) => {
        if (selected === 'standard') updateRecord();
        else if (selected === 'custom') updateRecord('', false);
        else if (selected === 'resource') updateRecord('', true);
      };

      return (
        <Space>
          <Select
            value={mode}
            onChange={changeMode}
            options={[
              { value: 'standard', label: 'Standard' },
              { value: 'custom', label: 'Custom' },
              { value: 'resource', label: 'Text Resource' },
            ]}
          />
          {mode === 'standard' && getStandardTitle()}
          {mode === 'custom' && (
            <Input
              ref={customRef}
              defaultValue={record.heading ?? ''}
              onBlur={() => updateRecord(customRef?.current?.input?.value, false)}
            />
          )}
          {mode === 'resource' && (
            <Select
              style={{ minWidth: '100px' }}
              loading={loading}
              showSearch
              onSearch={resourceSearch}
              dropdownMatchSelectWidth={false}
              options={(data?.localizations.nodes ?? []).map(n => ({ value: n.id.code, label: n.id.code }))}
              onSelect={(headingKey: string) => updateRecord(headingKey, true)}
              value={record.isHeadingKey ? record.heading : undefined}
            />
          )}
        </Space>
      );
    },
  };
}

const LOCALIZATION_KEYS = gql`
  query LocalizationKeysSearch($search: String) {
    localizations(criteria: { languageCode: "en", search: $search }) {
      nodes {
        id {
          code
          languageCode
        }
        resource
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation ConfigureGridCardsMutation(
    $entityGridItemConfigurationId: Int!,
    $entityGridItemCardFieldType: EntityGridItemCardFieldTypeEnum!,
    $fields: [EntityGridItemCardFieldInput!]!
  ) {
    updateEntityGridItemCardFields(
      entityGridItemConfigurationId: $entityGridItemConfigurationId,
      entityGridItemCardFieldType: $entityGridItemCardFieldType,
      fields: $fields
    ) {
      hash
      nodes { id }
    }
  }
`;

const DATA_QUERY = gql`
  query ConfigureGridCardsQuery(
    $itemConfigurationId: ID!,
    $main: EntityGridItemCardFieldTypeEnum!,
    $meta: EntityGridItemCardFieldTypeEnum!
  ) {
    main: entityGridItemCardFields(criteria: {
      entityGridItemConfigurationId: $itemConfigurationId,
      entityGridItemCardFieldType: $main
    }) {
      hash
      nodes { ...FieldFragment }
    }
    meta: entityGridItemCardFields(criteria: {
      entityGridItemConfigurationId: $itemConfigurationId,
      entityGridItemCardFieldType: $meta
    }) {
      hash
      nodes { ...FieldFragment }
    }
  }

  fragment FieldFragment on EntityGridItemCardField {
    id
    fieldKey
    heading
    isHeadingKey
    sort
  }
`;
