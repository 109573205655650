import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { Button } from 'antd';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { MergeItemSiteCustomFieldFragment } from '../../../../../../../../../gql/typings';
import MergeRequestItemPrefixIcon from '../../components/MergeRequestItemPrefixIcon';
import { reactJoin } from '../../../../../../../../util/reactJoin';
import AdminMergeItemCustomFieldModal from '../../shared/CustomField/AdminMergeItemCustomFieldModal';
import { useRandomKey } from '../../../../../../../../hooks/useRandomKey';

const AdminMergeItemSiteCustomField: React.FC<AdminEntityMergeRequestItemProps> = ({
  item,
  emitter,
}) => {
  const info = item.type as MergeItemSiteCustomFieldFragment;
  const localization = useLocalization();
  const emitterKey = useRandomKey('AdminMergeItemPersonCustomField');
  const modalState = useState(false);

  useEffect(() => {
    emitter.emit({
      key: emitterKey,
      state: item.hasConflict ? 'not_started' : 'finished',
      _source: 'AdminMergeItemSiteCustomField:useEffect',
    });
  }, [emitter, emitterKey, item]);

  if (!item.hasConflict) return (
    <span>
      <MergeRequestItemPrefixIcon state="MOVED" />
      <strong>{info.field.heading}</strong> with the values&nbsp;
      [{reactJoin(info.loserExistingAffiliations.map(l => <strong key={l.value.id}>{l.value.value}</strong>))}]
      &nbsp;will be moved over
    </span>
  );

  return (
    <span>
      <MergeRequestItemPrefixIcon state="CONFLICT" />
      The field <strong>{info.field.heading}</strong> has the values&nbsp;
      [{reactJoin(info.loserExistingAffiliations.map(l => <strong key={l.value.id}>{l.value.value}</strong>))}]
      &nbsp;and&nbsp;
      [{reactJoin(info.winnerExistingAffiliations.map(l => <strong key={l.value.id}>{l.value.value}</strong>))}]
      &nbsp;
      <Button type="link" size="small" onClick={() => modalState[1](true)}>
        [{localization.formatMessage(Locale.Command.Fix)}]
      </Button>

      <AdminMergeItemCustomFieldModal
        field={info.field}
        visibleState={modalState}
        existingLoserValues={['value1', 'value2']}
        hasConflict={item.hasConflict}
      />
    </span>
  );
};

gql`
  fragment MergeItemSiteCustomField on SiteCustomField_EntityDataToBeTransferredType {
    code
    field {
      code
      heading
      fieldTypeEnum
    }
    affiliationsToMove { ...FieldData }
    loserExistingAffiliations { ...FieldData }
    winnerExistingAffiliations { ...FieldData }
  }

  fragment FieldData on CustomFieldToBeTransferredInfo {
    value {
      id
      value
    }
    affiliation {
      id
    }
  }
`;

export default AdminMergeItemSiteCustomField;
