import {
  EntitySearchColumnParamTypeWithState,
  FieldTypeConfig,
  TableFieldReturned,
  TableFieldReturnedRecordOptions
} from '../../../search_old/types';
import { SupportedPersonTableColumns } from '../../supportedPersonTableColumns';
import { SupportedSiteTableColumns } from '../../../site/supportedSiteTableColumns';
import { SupportedLinkTableFields } from '../../../link/LinkTable';
import { gqlFieldKeyAliasTransformer } from '../../../../util/gqlFieldKeyAliasTransformer';
import { Person, PersonSelectionRow, SelectionRowResult, Site, SitePerson, SiteSelectionRow } from '../../../../../gql/typings';
import { SupportedPersonSelectionRowTableColumns } from '../../../personSelectionRow/SupportedPersonSelectionRowTableColumns';
import { SupportedSiteSelectionRowTableColumns } from '../../../siteSelectionRow/SupportedSiteSelectionRowTableColumns';
import { SupportedSelectionRowResultTableColumns } from '../../../selectionRowResult/supportedSelectionRowResultTableColumns';

export function personFieldConfig<K extends SupportedPersonTableColumns, Inputs extends string[]>(
  key: K,
  query: [...Inputs],
  callback: (
    props: EntitySearchColumnParamTypeWithState,
  ) => Omit<TableFieldReturned<K, Person, [...Inputs]>, 'key'|'queryFields'>,
): FieldTypeConfig<K, Person, [...Inputs]> {
  return (props) => ({
    key,
    queryFields: query,
    ...callback(props),
  });
}
export function siteFieldConfig<K extends SupportedSiteTableColumns, Inputs extends string[]>(
  key: K,
  query: [...Inputs],
  callback: (
    props: EntitySearchColumnParamTypeWithState,
  ) => Omit<TableFieldReturned<K, Site, [...Inputs]>, 'key'|'queryFields'>,
): FieldTypeConfig<K, Site, [...Inputs]> {
  return (props) => ({
    key,
    queryFields: query,
    ...callback(props),
  });
}
export function linkFieldConfig<K extends SupportedLinkTableFields, Inputs extends string[]>(
  key: K,
  query: [...Inputs],
  callback: (
    props: EntitySearchColumnParamTypeWithState,
  ) => Omit<TableFieldReturned<K, SitePerson, [...Inputs]>, 'key'|'queryFields'>,
): FieldTypeConfig<K, SitePerson, [...Inputs]> {
  return (props) => ({
    key,
    queryFields: query,
    ...callback(props),
  });
}
export function personSelectionRowFieldConfig<K extends SupportedPersonSelectionRowTableColumns, Inputs extends string[]>(
  key: K,
  query: [...Inputs],
  callback: (
    props: EntitySearchColumnParamTypeWithState,
  ) => Omit<TableFieldReturned<K, PersonSelectionRow, [...Inputs]>, 'key'|'queryFields'>,
): FieldTypeConfig<K, PersonSelectionRow, [...Inputs]> {
  return (props) => ({
    key,
    queryFields: query,
    ...callback(props),
  });
}
export function siteSelectionRowFieldConfig<K extends SupportedSiteSelectionRowTableColumns, Inputs extends string[]>(
  key: K,
  query: [...Inputs],
  callback: (
    props: EntitySearchColumnParamTypeWithState,
  ) => Omit<TableFieldReturned<K, SiteSelectionRow, [...Inputs]>, 'key'|'queryFields'>,
): FieldTypeConfig<K, SiteSelectionRow, [...Inputs]> {
  return (props) => ({
    key,
    queryFields: query,
    ...callback(props),
  });
}
export function selectionRowResultFieldConfig<K extends SupportedSelectionRowResultTableColumns, Inputs extends string[]>(
  key: K,
  query: [...Inputs],
  callback: (
    props: EntitySearchColumnParamTypeWithState,
  ) => Omit<TableFieldReturned<K, SelectionRowResult, [...Inputs]>, 'key'|'queryFields'>,
): FieldTypeConfig<K, SelectionRowResult, [...Inputs]> {
  return (props) => ({
    key,
    queryFields: query,
    ...callback(props),
  });
}

export function genericFieldConfig<
  K extends SupportedPersonTableColumns | SupportedSiteTableColumns | SupportedLinkTableFields,
  Inputs extends string[],
>(
  key: K,
  query: [...Inputs],
  callback: (
    props: EntitySearchColumnParamTypeWithState,
  ) => Omit<TableFieldReturned<K, Person | Site | SitePerson, [...Inputs]>, 'key'|'queryFields'>,
): FieldTypeConfig<K, Person | Site | SitePerson, [...Inputs]> {
  return (props) => ({
    key,
    queryFields: query,
    ...callback(props),
  });
}

export function getVariableField<RECORD, PROPERTY extends keyof RECORD>(
  record: RECORD,
  options: TableFieldReturnedRecordOptions,
  field: PROPERTY,
): RECORD[PROPERTY] {
  // @ts-ignore
  return record[gqlFieldKeyAliasTransformer(options.selectedOption!.code, field)];
}

// TODO: Make the [nested] to be an array of strings instead of only a single level
export function getNestedVariableField<RECORD, SINGLE extends keyof RECORD, PROPERTY extends keyof RECORD[SINGLE]>(
  record: RECORD,
  options: TableFieldReturnedRecordOptions,
  nested: SINGLE,
  field: PROPERTY,
): RECORD[SINGLE][PROPERTY] {
  // @ts-ignore
  return record[gqlFieldKeyAliasTransformer(options.selectedOption!.code, field)][field];
}
