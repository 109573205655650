import { Moment } from 'moment';
import { createContext } from 'react';
// eslint-disable-next-line
import Test, { SyncErrorType } from 'async-validator';
import { Dayjs } from 'dayjs';
import { Optional, PartialRecord } from '../../../util/StateArrayType';
import {
  ActivityDetailErrorState,
  ActivityDetailsAction,
  ActivityDetailsStateData,
  ActivityDetailsStateDataOnly
} from './activityReducer';
import { ActivityStatusEnum, ActivityTypesQueryQuery } from '../../../../gql/typings';

export type ActivityTypeProp = NonNullable<ActivityTypesQueryQuery['activityTypes']>['nodes']['0'];

export type ActivityDetailsContentFormInstanceProps = {
  heading: string;
  startDate: Moment;
  endDate: Moment;
  status: ActivityStatusEnum;
  typeCode: string;
  responsibleUserIds?: number[];
  participationUserIds?: number[];
  locations?: Array<string | number>;
  siteId?: number;
  projectIds?: number[];
  productIds?: number[];
  productBrandIds?: number[];
};

export type ActivityDetailsObserverType = keyof ActivityDetailsContentFormInstanceProps;

type ActivityDetailContext = ActivityDetailsStateData & {
  editAccess: boolean;
  /**
   * Will be null if this is a creation activity otherwise it shall read the activity ID from the URL bar.
   */
  recordIds?: [number];
  activityId: number | null;
  activityTypes: Record<string, ActivityTypeProp>;

  allErrors: Optional<ActivityDetailErrorState>;
  dispatch: (action: ActivityDetailsAction) => void;

  personIds?: number[];
} & PartialRecord<`${keyof ActivityDetailsStateDataOnly}Errors`, Optional<SyncErrorType[]>>;

// @ts-ignore
export const ActivityDetailContext = createContext<ActivityDetailContext>();

export type ActivityDetailsInitialState = {
  personIds?: number[];
  startDate?: Dayjs;
  endDate?: Dayjs;
  siteId?: number;
};

