import React from 'react';
import { gql } from '@apollo/client';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import { MergeItemPersonEducationsFragment } from '../../../../../../../../../gql/typings';
import { reactJoin } from '../../../../../../../../util/reactJoin';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';


const AdminMergeItemPersonEducations: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonEducationsFragment;
  const localization = useLocalization();

  if (!props.item.hasConflict) return (
    <AdminMergeItemBasicField {...props}>
      {localization.pluralMessage(Locale.Text.Merge_request_educations_description, info.educationCodesToBeMoved.length, {
        educations: reactJoin(info.educationCodesToBeMoved.map(e => <strong key={e.id}>{e.education.displayLabel}</strong>)),
      })}
    </AdminMergeItemBasicField>
  );

  return <span>Something went wrong. Please contact support at support@pureadvance.com</span>;
};


gql`
  fragment MergeItemPersonEducations on PersonEducations_EntityDataToBeTransferredType {
    code
    entityType
    
    educationCodesToBeMoved {
      id
      education {
        code
        type
        displayLabel
      }
    }
  }
`;

export default AdminMergeItemPersonEducations;
