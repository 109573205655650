import { flatten, identity } from 'lodash';
import { gql } from '@apollo/client';
import { message } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  SelectSiteCountyQueryQuery,
  SelectSiteCountyQueryQueryVariables,
  UpdateAddressMutationMutationVariables
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';

export const siteCountyFieldConfig = siteFieldConfig(
  'county',
  [
    'id',
    'countryCode',
    'controlSetting.id',
    'controlSetting.createDcr',
    'addressInformation.entityType',
    'addressInformation.entityAffiliationId',
    'addressInformation.type.code',
    'addressInformation.affiliations.id',
    'addressInformation.affiliations.address.id',
    'addressInformation.affiliations.address.countryCode',
    'addressInformation.affiliations.address.street',
    'addressInformation.affiliations.address.postalCode',
    'addressInformation.affiliations.address.city',
    'addressInformation.affiliations.address.county.code',
    'addressInformation.affiliations.address.county.type',
    'addressInformation.affiliations.address.county.label',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.County),
    additionalTableConfig: {
      width: 120
    },
    dcrInfo: site => ({
      entityType: EntityTypeEnum.SITE,
      entityAffiliationId: site.id,
      field: FieldEnum.ADDRESS_HCO_COUNTY,
    }),
    render: site => {
      const test = site.addressInformation.map(i => i.affiliations.filter(a => a.address.county));
      const currentCounty = flatten(test).filter(identity)[0];
      return currentCounty?.address?.county?.label;
    },
    updateView: buildSelectUpdateFieldConfig({
      mode: 'single',
      allowClear: true,
      mutation: UPDATE_MUTATION,
      selectPlaceholder: localization.formatMessage(Locale.Command.Select_county),
      selectedKey: record => {
        const test = record.addressInformation.map(i => i.affiliations.filter(a => a.address.county));
        return flatten(test).filter(e => e)[0]?.address?.county?.code;
      },
      buildVariables: (selectedKey, record) => {
        const test = record.addressInformation.map(i => i.affiliations.filter(a => a.address.county));
        const currentCounty = flatten(test).filter(identity)[0];
        if (!currentCounty) message.warning("Currently it's only supported to update existing counties");
        return currentCounty && {
          addressAffiliationId: currentCounty.id,
          input: {
            street: currentCounty.address.street,
            postalCode: currentCounty.address.postalCode,
            city: currentCounty.address.city,
            countryCode: currentCounty.address.countryCode,
            countyCode: selectedKey,
          },
        } as UpdateAddressMutationMutationVariables;
      },
      fetchSuggestions: ({ client, record, userInput }) => (
        client.query<SelectSiteCountyQueryQuery, SelectSiteCountyQueryQueryVariables>({
          query: DATA_QUERY,
          variables: { country: record.countryCode, search: `%${userInput}%` },
        }).then(res => res.data.counties.nodes)
      ),
    }),
  })
);

const DATA_QUERY = gql`
  query SelectSiteCountyQuery($search: String, $country: CountryCode!) {
    counties(criteria: { label: $search, countries: [$country] }) {
      hash
      nodes {
        code
        type
        label
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateAddressMutation($addressAffiliationId: Int!, $input: AddressInput!) {
    updateAddress(addressAffiliationId: $addressAffiliationId, input: $input) {
      id
      city
      countryCode
      countryEnum
      county { code }
      countyCode
      street
      postalCode
    }
  }
`;

