import React from 'react';
import { Tag } from 'antd';
import { gql } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  ConsentInstructionCustomersQuery,
  ConsentInstructionCustomersQueryVariables,
  PermissionEnum, SetConsentInstructionsMutationVariables
} from '../../../../gql/typings';
import { usePermission } from '../../../permission/usePermission';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';

export const personOptOutClientsFieldConfig = personFieldConfig(
  'optOutClients',
  [
    'id',
    'countryCode',
    'apurebaseId',
    'consentInstructions.hash',
    'consentInstructions.nodes.apbId',
    'consentInstructions.nodes.isOptedOutForAll',
    'consentInstructions.nodes.customer.code',
    'consentInstructions.nodes.customer.heading',
    'consentInstructions.nodes.imfCustomer.code',
    'consentInstructions.nodes.imfCustomer.heading',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Opted_out),
    additionalTableConfig: {
      width: 100
    },
    permission: PermissionEnum.HCP_OPT_OUT,
    hasUpdateSupport: ({ record }) => {
      const permissions = usePermission('HCP_OPT_OUT');
      return !!record?.apurebaseId && permissions.create;
    },
    render: record => {
      if (record.consentInstructions?.nodes.find(instruction => instruction.isOptedOutForAll)) {
        return <span>{localization.formatMessage(Locale.Text.Opted_out_for_all_clients)}</span>;
      }
      return record.consentInstructions?.nodes.map(instruction => (
        <Tag key={instruction.customer?.code}>
          {instruction.customer?.code ?? instruction.imfCustomer?.code}
        </Tag>
      ));
    },
    preserve: true,
    updateView: buildSelectUpdateFieldConfig({
      mode: 'multiple',
      mutation: MUTATION,
      selectedKeys: record => record.consentInstructions?.nodes.map(n => n.imfCustomer?.code ?? 'ALL') ?? [],
      modalHeading: '',
      buildVariables: (selectedKeys, record) => ({
        personId: record.id,
        imfCustomerCodes: selectedKeys,
      }) as SetConsentInstructionsMutationVariables,
      fetchSuggestions: ({ client, record }) => (
        client.query<ConsentInstructionCustomersQuery, ConsentInstructionCustomersQueryVariables>({
          query: DATA_QUERY,
          variables: { countryCode: record.countryCode },
        }).then(res => [
          {
            code: 'ALL',
            label: 'aPureBase',
          },
          ...res.data.internationalCustomers.nodes,
        ])
      ),
    }),
  }),
);

const MUTATION = gql`
  mutation SetConsentInstructions($imfCustomerCodes: [String!]!, $personId: Int!, $optedOut: Boolean!) {
    setConsentInstructions(imfCustomerCodes: $imfCustomerCodes, personId: $personId) {
      isOptedOutForAll
      optedOut
    }
  }
`;

const DATA_QUERY = gql`
  query ConsentInstructionCustomers($countryCode: CountryCode!) {
    internationalCustomers(criteria: {
      countries: [$countryCode],
      fetchSize: { limit: 1000 }
    }) {
      hash
      nodes {
        code
        isActive
        label: heading
      }
    }
  }
`;
