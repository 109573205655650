import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Checkbox, Col, message, Popover, Row, Space, Tooltip } from 'antd';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import Icon from '../../components/Icon/Icon';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';
import { ClearHcpMutationMutation, ClearHcpMutationMutationVariables, DcrGroupDetailQuery } from '../../../gql/typings';

type QueriedDcrNode = NonNullable<DcrGroupDetailQuery['dcrGroup']>['dcrs']['nodes']['0'];

export type PersonRenderProps = {
  requestType: QueriedDcrNode['requestType'];
  entity: Extract<QueriedDcrNode['affiliationEntity'], { ['__typename']: 'Person' }>;
  renderButton: boolean;
};

const PersonRender: React.FC<PersonRenderProps> = ({ renderButton, entity, requestType }) => {
  const localization = useLocalization();
  const [visible, setVisible] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [
    inactivateHcpAndAffiliations,
  ] = useMutation<ClearHcpMutationMutation, ClearHcpMutationMutationVariables>(CLEAR_HCP_DCR_MUTATION);

  const onSubmit = (personId: number, fullName: string) => {
    inactivateHcpAndAffiliations({
      variables: {
        hcpId: personId,
        isChecked,
      },
    }).then(() => setVisible(false))
      .finally(() => message.success(`${fullName} has been removed`));
  };


  return (
    <Space>
      <div key={entity.id}>
        <Icon
          iconType="PERSON"
          text={
            <Tooltip title={localization.formatMessage(Locale.Text.DCR_request_type_info, { requestType: requestType.code })}>
              <NavLink className="dcr-heading-link" data-record-id={entity.id} to={`/hcp/${entity.id}`}>
                <span>{entity.fullName}</span>
              </NavLink>
            </Tooltip>
          }
        />

        {renderButton
        && <Popover
          open={visible}
          content={<Row gutter={[6, 6]}>
            <Col span={24}> This will remove the HCP from the Pure Advance Instance</Col>
            <Col span={24}><Space>
              <Checkbox defaultChecked={isChecked} onChange={e => setIsChecked(e.target.checked)}>Remove affiliated
                activities?</Checkbox>
              <Icon
                toolTip="This will remove Activities where this HCP is assigned alone."
                style={{ height: 14 }}
                iconType="INFO"
              />
            </Space>
            </Col>
            <Col span={24}>
              <Button size="small" onClick={() => onSubmit(entity.id, entity.fullName)} type="primary">Yes, Remove</Button>
              <Button style={{ marginLeft: 5 }} onClick={() => setVisible(false)} size="small">No</Button>
            </Col>
          </Row>}
          title="Remove HCP?"
          trigger="click"
        >
          <Button onClick={() => setVisible(true)} type="primary">Clear Affiliation</Button>
        </Popover>}
      </div>
    </Space>
  );
};

const CLEAR_HCP_DCR_MUTATION = gql`
  mutation ClearHcpMutation(
    $hcpId: Int!,
    $isChecked: Boolean!,
  ) {
    inactivateHcpAndActivityAffiliations(persId: $hcpId, removeAffiliations: $isChecked) {
      id
      isActive
    }
  }
`;


export default PersonRender;
