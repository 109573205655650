import React from 'react';
import { useIntl } from 'react-intl';
import { MinusCircleOutlined } from '@ant-design/icons';
import { message, Tooltip } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';

type RemoveRecordProps = {
  render: boolean;
  listId: number;
  recordId: number;
};

const RemoveRecord: React.FC<RemoveRecordProps> = ({
  render,
  listId,
  recordId,
}) => {
  const { formatMessage } = useIntl();
  const [removeRecordMutation] = useMutation(REMOVE_RECORD_MUTATION);

  const removeRecord = () => {
    removeRecordMutation({
      variables: {
        id: recordId,
        listId,
      },
      refetchQueries: ['ListDetailsTableQuery'],
    }).then(() => message.success(formatMessage(Locale.Text.Has_been_removed_from_list)));

  };

  const removeIcon = (
    <span className="remove-from-list">
      <Tooltip placement="left" title={formatMessage(Locale.Text.Remove_from_list_tooltip)}>
        <div onClick={removeRecord}>
          <MinusCircleOutlined style={{ fontSize: 18 }} />
        </div>
      </Tooltip>
    </span>
  );

  return (
    render ? removeIcon : <span />
  );
};

const REMOVE_RECORD_MUTATION = gql`
  mutation RemoveFromStaticListMutation(
    $id: Int!,
    $listId: Int!
  ) {
    removeFromStaticList(id: $id, listId: $listId) {
      id
    }
  }
`;


export default RemoveRecord;
