import React, { useContext } from 'react';
import { FloatButton } from 'antd';
import Navigation from './Navigation/Navigation';
import { AppContext } from './AppTypes';
import Header from './Header';

const AppLayout: React.FC = ({ children }) => {
  const { collapse } = useContext(AppContext);
  return (
    <>
      <Header />
      ,
      <Navigation setCollapse={collapse[1]} />
      ,
      {children}
      <FloatButton.BackTop visibilityHeight={150} type='primary' />
    </>
  );
};


export default AppLayout;
