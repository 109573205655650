import React from 'react';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { Button, Form, notification } from 'antd';
import { useLocalization } from '../../../util/useLocalization';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { Locale } from '../../../../localization/LocalizationKeys';
import MainView from '../../../components/View/MainView';
import CreateSite from './CreateSite';
import {
  DcrResponseTypeEnum,
  SiteInput,
  useCreateSiteMutationMutation
} from '../../../../gql/typings';


const CreateSiteView: React.FC = () => {
  const localization = useLocalization();
  const navigate = useNavigate();
  const [createSite, { loading }] = useCreateSiteMutationMutation();
  const [api, contextHolder] = notification.useNotification();

  usePageTitle(localization.formatMessage(Locale.Command.Create_Site));

  const onCreate = (input: SiteInput) => createSite({ variables: { input } }).then(res => {
    const createSite = res.data?.createSite;
    if (createSite?.site) navigate(`/hco/${createSite.site.id}`);
    if (createSite?.dcrGroup && createSite.dcrGroup.currentResponseCode !== DcrResponseTypeEnum.ACCEPT) api.open({
      message: 'Created a DCR',
      description: 'A DCR was created',
      btn: <Button type="primary" onClick={() => navigate(`/dcr/${createSite.dcrGroup!.id}`)}>Open DCR</Button>
    });
  });

  return (
    <MainView
      className="create-site-container"
      title={localization.formatMessage(Locale.Command.Create_Site)}
    >
      {contextHolder}
      <Form.Provider
        onFormFinish={(name, info) => {
          if (name === 'site' || !name) onCreate(info.values as SiteInput);
        }}
      >
        <CreateSite loading={loading} allowChooseMaintainer />
      </Form.Provider>
    </MainView>
  );
};

gql`
  mutation CreateSiteMutation($input: SiteInput!) {
    createSite(input: $input) {
      site {
        id
        name
      }
      dcrGroup {
        id
        currentResponseCode
      }
    }
  }
`;

export default CreateSiteView;
