import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { List, Typography } from 'antd';
import { useLocalization } from '../../../../util/useLocalization';
import { CustomFieldUsageOverviewQueryQuery, CustomFieldUsageOverviewQueryQueryVariables } from '../../../../../gql/typings';

type CustomFieldUsageOverviewProps = {
  customFieldCode: string;
};

const CustomFieldUsageOverviewTab: React.FC<CustomFieldUsageOverviewProps> = ({ customFieldCode }) => {
  const localization = useLocalization();
  const { data } = useQuery<CustomFieldUsageOverviewQueryQuery, CustomFieldUsageOverviewQueryQueryVariables>(DATA_QUERY, {
    variables: { customFieldCode },
  });

  return (
    <div>
      <Typography.Paragraph>
        This overview currently only shows where this custom field is displayed on a grid card.
      </Typography.Paragraph>
      <List
        dataSource={data?.customField?.fieldGridConfigurations.nodes ?? []}
        renderItem={grid => (
          <List.Item>
            <Typography.Text>
              {localization.formatMessageByStr(grid.fieldConfig.itemConfiguration.tab.headingKey)}
              :&nbsp;
              {grid.fieldConfig.itemConfiguration.heading}
            </Typography.Text>
          </List.Item>
        )}
      />
    </div>
  );
};

const DATA_QUERY = gql`
  query CustomFieldUsageOverviewQuery($customFieldCode: String!) {
    customField(code: $customFieldCode) {
      code
      fieldGridConfigurations {
        hash
        nodes {
          id
          fieldConfig {
            id
            itemConfiguration {
              id
              heading
              tab {
                code
                headingKey
              }
            }
          }
        }
      }
    }
  }
`;

export default CustomFieldUsageOverviewTab;
