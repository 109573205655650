/* eslint-disable react-hooks/exhaustive-deps */
import React, { KeyboardEvent, useEffect, useState } from 'react';
import { Form, Input as InputAnt } from 'antd';
import { FormInstance, Rule } from 'antd/lib/form';

type InputProps = {
  id: string;
  form?: FormInstance;
  onEnter?: (e: KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  type?: string;
  label?: string;
  autoComplete?: string;
  initialValue?: React.ReactNode;
  placeholder?: string;
  icon?: boolean;
  // errorMessage: PropTypes.string,
  preventDefault?: boolean;
  disabled?: boolean;
  rules?: Rule[];
  addonAfter?: React.ReactNode;
  onChange?: () => void;

};

const Input: React.FC<InputProps> = ({
  id,
  form = Form.useForm()[0],
  label,
  type = 'text',
  placeholder,
  autoFocus,
  onEnter = () => {},
  icon = false,
  initialValue,
  preventDefault = false,
  disabled = false,
  rules = [],
  autoComplete = 'off',
  addonAfter,
  onChange,
}) => {
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ [id]: initialValue });
  }, [initialValue]);

  const field = (
    <Form.Item name={id} rules={rules}>
      <InputAnt
        id={id}
        type={type}
        autoComplete={autoComplete}
        placeholder={placeholder}
        onPressEnter={e => {
          if (preventDefault) e.preventDefault();
          onEnter?.(e);
        }}
        autoFocus={autoFocus}
        disabled={disabled}
        suffix={addonAfter}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onChange={onChange}
      />
    </Form.Item>
  );

  return (
    <div className="form-input-container">
      {field}
      {icon && <div className="icon" />}
      {label && <label htmlFor={id} className={focus || form.getFieldValue(id) ? 'has-value' : ''}>{label}</label>}
    </div>
  );
};


export default Input;
