import React from 'react';
import { gql } from '@apollo/client';
import { message, Select } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import { ADMIN_MENU_DATA_QUERY } from './MenuSettings';
import { NAVIGATION_DATA_QUERY } from '../../../layout/Navigation/Navigation';
import { useLocalization } from '../../../util/useLocalization';
import {
  AdminMenuItemFragmentFragment, MenuGroupEnum,
  useUpdateGroupMenuItemMutation
} from '../../../../gql/typings';


type MenuGroupComponentProps = {
  menuItem: AdminMenuItemFragmentFragment;
};

const MenuGroupComponent: React.FC<MenuGroupComponentProps> = ({ menuItem }) => {
  const localization = useLocalization();
  const [create, { loading }] = useUpdateGroupMenuItemMutation({
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      { query: ADMIN_MENU_DATA_QUERY },
      { query: NAVIGATION_DATA_QUERY },
    ],
  });

  const onPress = (menuGroup: MenuGroupEnum) => create({
    variables: { menuItemCode: menuItem.code, menuGroup },
  }).then(() => message.success(localization.formatMessage(Locale.Attribute.Updated)));

  return (
    <Select
      onChange={onPress}
      style={{ width: 120 }}
      loading={loading}
      value={menuItem.menuGroup.enum}
    >
      <Select.Option value={MenuGroupEnum.MENU}>
        {localization.formatMessage(Locale.General.Menu_items)}
      </Select.Option>
      <Select.Option value={MenuGroupEnum.FUNC}>
        {localization.formatMessage(Locale.General.Functions)}
      </Select.Option>
    </Select>
  );
};

gql`
  mutation UpdateGroupMenuItem($menuItemCode: String!, $menuGroup: MenuGroupEnum!) {
    updateMenuItemGroup(menuItemCode: $menuItemCode, menuGroup: $menuGroup) {
      code
      menuGroup { code, enum }
    }
  }
`;


export default MenuGroupComponent;
