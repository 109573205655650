import React from 'react';
import { useIntl } from 'react-intl';
import { Form, DatePicker } from 'antd';
import { DynamicReportInputProps } from './dynamicReportInputUtils';
import { DATE_FORMAT } from '../../../../util/format';

const ReportDaySpanPicker: React.FC<DynamicReportInputProps> = ({
  inputDef,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Form.Item name={inputDef.labelKey!} label={formatMessage({ id: inputDef.labelKey! })} rules={inputDef.rules}>
      <DatePicker.RangePicker format={DATE_FORMAT} />
    </Form.Item>
  );
};

export default ReportDaySpanPicker;
