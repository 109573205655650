import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { Carousel, Col, Row, Skeleton } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import CardWithFields from '../../../components/Card/CardWithFields';
import { useLocalization } from '../../../util/useLocalization';
import { EntityTabCardsProps } from '../../../components/EntityTabs/Cards/EntityTabCards';
import { EntityTypeEnum, PersonCardContainerQueryQuery, PersonCardContainerQueryQueryVariables } from '../../../../gql/typings';
import { FIELD_KEY_FRAGMENT } from '../../../util/useFieldKeysTransformed';

type PersonCardsContainerProps = {
  personId: number;
  entityGridItemConfiguration: EntityTabCardsProps['configuration'];
};

const PersonCardsContainer: React.FC<PersonCardsContainerProps> = ({
  personId,
  entityGridItemConfiguration,
}) => {
  const localization = useLocalization();
  const {
    data,
    loading,
  } = useQuery<PersonCardContainerQueryQuery, PersonCardContainerQueryQueryVariables>(PersonCardContainerDataQuery, {
    variables: { personId, entityGridItemConfigurationId: entityGridItemConfiguration.id },
  });


  const [
    selectedLink,
    setSelectedLink,
  ] = useState<NonNullable<PersonCardContainerQueryQuery['person']>['affiliations']['nodes']['0']>();

  const affiliations = useMemo(() => data?.person?.affiliations.nodes ?? [], [data]);

  const selectLink = (index: number) => {
    setSelectedLink(affiliations[index]);
  };
  useEffect(() => {
    const findPrimarySite = affiliations.find(link => link.isPrimary);
    if (findPrimarySite) setSelectedLink(findPrimarySite);
    else setSelectedLink(affiliations[0]);
  }, [affiliations, data]);

  return (
    <Skeleton loading={loading}>
      {data && <Row gutter={[16, 8]}>
        <Col id="person-card" sm={24} md={8} lg={8} xl={8} xxl={6}>
          <CardWithFields
            title={localization.formatMessage(Locale.General.Person_Information)}
            size={entityGridItemConfiguration.height}
            recordId={personId}
            entityType={EntityTypeEnum.PERSON}
            rootEntityType={EntityTypeEnum.PERSON}
            fields={data.cardFieldKeys.person.fields}
            secondaryFields={data.cardFieldKeys.person.drawerFields}
            icon="PERSON"
            topRightBadgeChar={data.person!.segmentationCode?.heading}
          />
        </Col>
        <Col sm={24} md={8} lg={8} xl={8} xxl={6}>
          <div className="scrollmenu-items-transparent">
            <Carousel afterChange={(e) => selectLink(e)} effect="fade">
              {affiliations.map((it, index) => (
                <div
                  key={index}
                  id="affiliation-card"
                  data-id={it.id}
                >
                  <CardWithFields
                    title={localization.formatMessage(Locale.General.Work_Information)}
                    size={entityGridItemConfiguration.height}
                    icon="POSITION"
                    topRightIcon={it.isPrimary ? 'KEY' : undefined}
                    recordId={it.id}
                    entityType={EntityTypeEnum.AFFILIATION}
                    rootEntityType={EntityTypeEnum.PERSON}
                    fields={data?.cardFieldKeys.link.fields}
                    secondaryFields={data?.cardFieldKeys.link.drawerFields}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </Col>
        <Col sm={24} md={8} lg={8} xl={8} xxl={6} id="site-card" data-id={selectedLink?.siteId}>
          {
            selectedLink && <CardWithFields
              title={localization.formatMessage(Locale.General.Site_Information)}
              size={entityGridItemConfiguration.height}
              icon="SITE"
              topRightIcon={selectedLink.isPrimary ? 'KEY' : undefined}
              recordId={selectedLink.siteId}
              entityType={EntityTypeEnum.SITE}
              rootEntityType={EntityTypeEnum.PERSON}
              fields={data.cardFieldKeys.site.fields}
              secondaryFields={data.cardFieldKeys.site.drawerFields}
            />
          }
        </Col>
      </Row>}

    </Skeleton>
  );
};

const PersonCardContainerDataQuery = gql`
  query PersonCardContainerQuery($personId: Int!, $entityGridItemConfigurationId: Int!) {
    cardFieldKeys(entityGridItemConfigurationId: $entityGridItemConfigurationId) {
      entityGridItemConfigurationId
      person {
        entityGridItemConfigurationId
        entityType
        fields { ...GridCardField }
        drawerFields { ...GridCardField }
      }
      link {
        entityGridItemConfigurationId
        entityType
        fields { ...GridCardField }
        drawerFields { ...GridCardField }
      }
      site {
        entityGridItemConfigurationId
        entityType
        fields { ...GridCardField }
        drawerFields { ...GridCardField }
      }
    }
    person(id: $personId) {
      id
      segmentationCode {
        id
        heading
      }
      affiliations {
        hash
        nodes {
          id
          isPrimary
          siteId
        }
      }
    }
  }
  ${FIELD_KEY_FRAGMENT}
`;


export default PersonCardsContainer;
