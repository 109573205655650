import React, { useState } from 'react';
import { useDynamicList } from 'ahooks';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Modal, Popconfirm, Tag } from 'antd';
import { gql } from '@apollo/client/core';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import { DynamicList } from '../../../util/AHookTypes';
import DraggableTable from '../../../components/DraggableTable/DraggableTable';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  SelectPersonLanguageSuggestionQueryQuery,
  SelectPersonLanguageSuggestionQueryQueryVariables,
  UpdatePersonLanguageMutationMutation,
  UpdatePersonLanguageMutationMutationVariables
} from '../../../../gql/typings';

export const personLanguagesFieldConfig = personFieldConfig(
  'languages',
  [
    'id',
    'languages.hash',
    'languages.nodes.id',
    'languages.nodes.language.code',
    'languages.nodes.language.heading',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Language),
    additionalTableConfig: {
      width: 80
    },
    dcrInfo: person => ({
      entityType: EntityTypeEnum.PERSON,
      field: FieldEnum.LANGUAGE,
      entityAffiliationId: person.id,
    }),
    preserve: true,
    render: person => (
      <span className='comma-list'>
        {(person.languages?.nodes ?? []).map((it: { id: number; language: { heading: string } }) => (
          <span className='comma' key={it.id}>
            {it.language.heading}
          </span>
        ))}
      </span>
    ),
    updateView: ({ endEditing, record: person }) => {
      const localization = useLocalization();
      const [visible, setVisible] = useState(true);

      type Value = {
        id: string;
        heading: string;
      };

      const dynamicList: DynamicList<Value> = useDynamicList(
        person.languages?.nodes.map(it => ({ id: it.language.code, heading: it.language.heading })) ?? []
      );

      const { data } = useQuery<SelectPersonLanguageSuggestionQueryQuery, SelectPersonLanguageSuggestionQueryQueryVariables>(
        SUGGESTIONS_QUERY,
        { variables: { search: '%' } },
      );

      const [
        submit,
        { loading: blocking },
      ] = useMutation<UpdatePersonLanguageMutationMutation, UpdatePersonLanguageMutationMutationVariables>(UPDATE_MUTATION);

      const close = () => {
        if (blocking) return;
        setVisible(false);
        endEditing();
      };

      const add = () => {
        submit({
          variables: {
            persId: person.id,
            languageCodes: dynamicList.list.map(l => l.id),
          },
        }).then(close);
      };

      return (
        <Modal
          open={visible}
          onCancel={close}
          closable={false}
          footer={false}
          width="clamp(320px, 75%, 680px)"
          wrapClassName="person-language-field-modal-container"
        >
          <strong>{localization.formatMessage(Locale.Attribute.Languages)}</strong>:&nbsp;
          {(data?.languages?.nodes ?? []).map(l => (
            <Tag.CheckableTag
              key={l.code}
              checked={dynamicList.list.map(ll => ll.id).includes(l.code)}
              onChange={checked => {
                if (checked) dynamicList.push(l);
                else dynamicList.resetList(dynamicList.list.filter(ll => ll.id !== l.code));
              }}
            >
              {l.heading}
            </Tag.CheckableTag>
          ))}
          <br /><br />
          <DraggableTable<Value>
            className="person-language-field-table"
            showSortLabel
            pagination={false}
            dynamicList={dynamicList}
            columns={[
              {
                title: localization.formatMessage(Locale.Attribute.Language),
                render: (_, record) => <span>{record.heading}</span>,
              },
            ]}
            footer={() => (
              <Popconfirm
                title={localization.formatMessage(Locale.Text.Create_dcr_verification)}
                onConfirm={add}
              >
                <Button className="add_new_value" type="primary">
                  {localization.formatMessage(Locale.Command.Save)}
                </Button>
              </Popconfirm>
            )}
          />
        </Modal>
      );
    },
  })
);

const UPDATE_MUTATION = gql`
  mutation UpdatePersonLanguageMutation($persId: Int!, $languageCodes: [String!]!) {
    updatePersonLanguage(persId: $persId, languageCodes: $languageCodes) {
      id
    }
  }
`;

const SUGGESTIONS_QUERY = gql`
  query SelectPersonLanguageSuggestionQuery($search: String) {
    languages(criteria: { heading: $search, fetchSize: { limit: 1000 } }) {
      hash
      nodes {
        code
        id: code
        heading
      }
    }
  }
`;
