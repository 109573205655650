import React from 'react';
import { useIntl } from 'react-intl';
import { Locale } from '../../../localization/LocalizationKeys';

const DisplayPersons: React.FC<{ slice?: number; nodes: { fullName: string }[] ; totalCount?: number }> = ({
  nodes,
  slice = 5,
  totalCount
}) => {
  const size = nodes.length;
  const adjustSize = size >= slice ? slice : size;
  const items = size > slice ? nodes.slice(0, slice) : nodes;
  const count = !totalCount ? size - slice: totalCount - size;
  const showCount = !totalCount ? size > slice : count > 0;

  const { formatMessage } = useIntl();
  return (
    <span className="display-persons-card">
      {/* eslint-disable-next-line max-len */}
      {adjustSize !== 0 ? items.map((s, index) => `${s.fullName}${adjustSize === 0 || index === adjustSize - 1 ? '' : ', '}`) : ''}
      {showCount && <span style={{ marginLeft: 5 }}>{formatMessage(Locale.General.Count_other, { count })}</span>}
    </span>
  );
};


export default DisplayPersons;
