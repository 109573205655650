import React from 'react';
import { Col, Divider, Row, Switch, Tooltip } from 'antd';
import {
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  ExclamationCircleTwoTone,
  LoadingOutlined,
  StopTwoTone
} from '@ant-design/icons';
import moment from 'moment';
import { AntProgressStatusEnum, SandboxInformationQueryQuery } from '../../../../gql/typings';


type SandboxPopoverInfoProps = {
  deployment: NonNullable<SandboxInformationQueryQuery['instance']>['sandboxes']['nodes']['0'];
};

const progressIcon: Record<AntProgressStatusEnum, React.ReactNode> = {
  ACTIVE: <LoadingOutlined />,
  ERROR: <ExclamationCircleTwoTone twoToneColor="#f5222d" />,
  PENDING: <ClockCircleTwoTone twoToneColor="#faad14" />,
  STOP: <StopTwoTone twoToneColor="#f5222d" />,
  SUCCESS: <CheckCircleTwoTone twoToneColor="#52c41a" />,
};

const TextValue: React.FC<{
  icon?: AntProgressStatusEnum;
  header: React.ReactNode;
  tooltip?: string;
  value: React.ReactNode;
}> = ({ icon, tooltip, header, value }) => {
  const heading = (
    <div>
      {icon && progressIcon[icon]}
      {icon && <span>&nbsp;&nbsp;</span>}
      <strong>{header}</strong>:
    </div>
  );
  return (
    <>
      <Col sm={24} md={9}>
        {!tooltip
          ? heading
          : <Tooltip title={tooltip} placement="left">{heading}</Tooltip>}
      </Col>
      <Col sm={24} md={15}>
        {value}
      </Col>
    </>
  );
};

const SandboxPopoverInfo: React.FC<SandboxPopoverInfoProps> = ({ deployment }) => {
  const status = deployment.deploymentStatus;
  return (
    <>
      <Row style={{ width: 480 }}>
        <TextValue
          icon={status.clone.currentStatus}
          header="Database Clone Job"
          value={status.clone.message}
        />
        <TextValue
          icon={status.activityJob.currentStatus}
          header="Activity Job"
          value={status.activityJob.message}
        />
        <TextValue
          icon={status.app.currentStatus}
          header="Web App"
          value={status.app.message}
        />
        <TextValue
          icon={status.db.currentStatus}
          header="Database"
          tooltip={`Host: ${deployment.serverCode}`}
          value={status.db.message}
        />
      </Row>
      <Divider orientation="left" plain style={{ margin: 0, marginTop: 16 }}>
        <Tooltip
          title={<span>{status.schedule.code}: {status.schedule.status}</span>}
          placement="right"
        >
          Refresh Schedule&nbsp;&nbsp;
          {progressIcon[status.schedule.status]}
        </Tooltip>
      </Divider>
      <Row>
        <TextValue
          header="Active"
          value={<Switch size="small" disabled checked={status.schedule.isActive} />}
        />
        <TextValue
          header="Cron Interval"
          value={status.schedule.cron}
        />
        <TextValue
          header="Last Run"
          value={status.schedule.lastRun && moment(status.schedule.lastRun).format('LLLL')}
        />
      </Row>
    </>
  );
};

export default SandboxPopoverInfo;
