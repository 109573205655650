import { get as getFp } from 'lodash/fp';
import { flatten, uniq } from 'lodash';
import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';
import { PersonCriteriaOrder } from '../../../../gql/typings';


export const personLinkTherapeuticFocusFieldConfig = personFieldConfig(
  'linkTherapeuticFocus',
  [
    'affiliations.hash',
    'affiliations.nodes.id',
    'affiliations.nodes.therapeuticFocuses.hash',
    'affiliations.nodes.therapeuticFocuses.nodes.code',
    'affiliations.nodes.therapeuticFocuses.nodes.type',
    'affiliations.nodes.therapeuticFocuses.nodes.label',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Text.Link_Therapeutic_focus),
    additionalTableConfig: {
      width: 125
    },
    sorting: {
      ascend: PersonCriteriaOrder.LINK_THERAPEUTIC_FOCUS_ASC,
      descend: PersonCriteriaOrder.LINK_THERAPEUTIC_FOCUS_DESC,
    },
    render: person => uniq(flatten(person.affiliations?.nodes?.map(getFp('therapeuticFocuses.nodes')) ?? [])
      .map(getFp('label'))).join(', '),
  })
);
