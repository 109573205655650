import React from 'react';
import { isEmpty } from 'lodash';
import moment, { Moment } from 'moment';
import { Tooltip } from 'antd';
import { DATE_FORMAT } from '../../util/format';

type DateDisplayProps = {
  format?: string;
  tooltipFormat?: string;
  children: string|Date|Moment;
};

const DateDisplay: React.FC<DateDisplayProps> = ({
  children,
  format = DATE_FORMAT,
  tooltipFormat = DATE_FORMAT,
}) => {
  const deliveryDate = moment(children);
  if (isEmpty(children)) {
    return <></>;
  }
  return (
    <Tooltip title={deliveryDate.format(tooltipFormat)}>
      {deliveryDate.format(format)}
    </Tooltip>
  );
};

export default DateDisplay;
