import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import NoteDrawer, { NoteDrawer_FRAGMENT } from '../../../components/Note/NoteDrawer';
import NoteCard from '../../person/Components/NoteCard';
import {
  EntityTypeEnum,
  SitePersonAffiliationNoteDetailQueryQuery,
  SitePersonAffiliationNoteDetailQueryQueryVariables
} from '../../../../gql/typings';

type Query = SitePersonAffiliationNoteDetailQueryQuery;

const LinkNoteContainer: React.FC<{ linkId: number }> = ({
  linkId,
}) => {
  const notesState = useState<NonNullable<Query['sitePersonAffiliation']>['notes']['nodes']['0'] | null>(null);
  const { data } = useQuery<Query, SitePersonAffiliationNoteDetailQueryQueryVariables>(DATA_QUERY, {
    variables: { linkId },
  });
  const [displayDrawer, setDisplayDrawer] = useState(false);

  return (
    <>
      {(data?.sitePersonAffiliation?.notes.nodes ?? []).map(note => (
        <span key={note.id}>
          <NoteCard note={note} setNote={notesState[1]} setDisplayDrawer={setDisplayDrawer} />
        </span>
      ))}
      <NoteDrawer
        id={linkId}
        entity={EntityTypeEnum.AFFILIATION}
        noteState={notesState}
        displayDrawer={displayDrawer}
        setDisplayDrawer={setDisplayDrawer}
      />
    </>
  );
};

const DATA_QUERY = gql`
  query SitePersonAffiliationNoteDetailQuery($linkId: Int!) {
    sitePersonAffiliation(id: $linkId) {
      id
      notes{
        hash
        totalCount
        nodes{
          id
          ...NoteDrawerFragment
        }
      }
    }
  }
  ${NoteDrawer_FRAGMENT}
`;

export default LinkNoteContainer;
