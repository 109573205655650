import { useQuery, gql } from '@apollo/client';
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { FetchSitePositionsGroupsQuery } from '../../../gql/typings';
import { useDetailsContext } from '../../components/DetailsView/useDetailsContext';

type QueriedSite = NonNullable<FetchSitePositionsGroupsQuery['site']>;

export type SiteCardControllerType = {
  getLinksByPosCode: () => QueriedSite['affiliations']['nodes'];
  selectedLink: QueriedSite['affiliations']['nodes']['0']|null;
  selectedGroup: QueriedSite['positionGroups']['0']|null;
  getResultCount: () => number;
  setDetailState: () => void;
  setSingleHcp: (value: QueriedSite['affiliations']['nodes']['0']) => void;
  getPositionGroups: () => {
    loading: boolean;
    groups: QueriedSite['positionGroups'];
  };
  getCount: () => number;
  addSelected: (code?: string|null) => void;
  site: FetchSitePositionsGroupsQuery['site'];
  refetch: () => void;
};

const SiteCardController = (siteId: number): SiteCardControllerType => {
  const {
    data: positionsData,
    loading: positionsLoading,
    refetch,
  } = useQuery<FetchSitePositionsGroupsQuery>(FETCH_POSITIONS, { variables: { siteId } });

  const [selectedGroupCode, setSelectedGroupCode] = useState<string|null>();
  const [selectedLinkId, setSelectedLinkId] = useState<number|null>();

  useDetailsContext(refetch);

  useEffect(() => {
    if (!positionsLoading && positionsData) {
      setSelectedGroupCode(positionsData.site?.positionGroups[0]?.code);
    }
  }, [positionsLoading]);

  function getPositionGroups() {
    return {
      loading: positionsLoading,
      groups: positionsData?.site?.positionGroups ?? [],
    };
  }

  function getResultCount(): number {
    return positionsData?.site?.positionGroups.filter(pg => pg.code === selectedGroupCode)[0]?.count ?? 0;
  }

  function getLinksByPosCode(): QueriedSite['affiliations']['nodes'] {
    return positionsData
      ?.site
      ?.affiliations
      ?.nodes
      ?.filter(v => (v.position?.code ?? null) === selectedGroupCode ?? null)
      ?? [];
  }

  function addSelected(code?: string|null) {
    setSelectedLinkId(null);
    setSelectedGroupCode(positionsData?.site?.positionGroups.filter(g => g.code === code)[0]?.code ?? null);
  }

  function setSingleHcp(value: QueriedSite['affiliations']['nodes']['0']) {
    return setSelectedLinkId(value.id);
  }

  function setDetailState() {
    return setSelectedLinkId(null);
  }


  function getCount(): number {
    return positionsData?.site?.affiliations?.totalCount ?? 0;
  }

  return {
    getLinksByPosCode,
    getResultCount,
    selectedLink: positionsData?.site?.affiliations?.nodes?.filter(l => l.id === selectedLinkId)[0] ?? null,
    setDetailState,
    setSingleHcp,
    getPositionGroups,
    getCount,
    addSelected,
    selectedGroup: positionsData?.site?.positionGroups.filter(g => g.code === selectedGroupCode)[0] ?? null,
    site: positionsData?.site,
    refetch,
  };
};

const FETCH_POSITIONS = gql`
  query FetchSitePositionsGroups($siteId: Int!) {
    site(siteId: $siteId) {
      id
      name
      positionGroups {
        code
        heading
        count
      }
      affiliations {
        hash
        totalCount
        nodes {
          id
          person {
            id
            fullName
          }
          position {
            code
            type
          }
        }
      }
    }
  }
`;

export default SiteCardController;

