import { gql } from '@apollo/client';
import { Locale } from '../../../localization/LocalizationKeys';
import { TableConfig } from '../search_old/types';
import { supportedSiteSelectionRowTableColumns } from './SupportedSiteSelectionRowTableColumns';

export const buildSiteSelectionRowTableFragment = (additionalQuery: string|null) => gql`
  fragment SiteSelectionRowTableBuiltFragment on SiteSelectionRow {
    id
    ${additionalQuery || ''}
  }
`;

export const buildSiteSelectionRowConnectionDataQuery = (additionalQuery: string|null) => gql`
  query SiteSelectionRowTableQuery($criteria: SiteSelectionRowCriteria) {
    connection: siteSelectionRows(criteria: $criteria) {
      hash
      totalCount
      nodes { ...SiteSelectionRowTableBuiltFragment }
    }
  }
  ${buildSiteSelectionRowTableFragment(additionalQuery)}
`;

export const buildSiteSelectionRowRecordDataQuery = (additionalQuery: string|null) => gql`
  query SiteSelectionRowRecordQuery($recordId: Long!) {
    record: siteSelectionRow(id: $recordId) {
      ...SiteSelectionRowTableBuiltFragment
    }
  }
  ${buildSiteSelectionRowTableFragment(additionalQuery)}
`;

export const SiteSelectionRowTableConfig: TableConfig = {
  buildConnectionQuery: buildSiteSelectionRowConnectionDataQuery,
  buildRecordQuery: buildSiteSelectionRowRecordDataQuery,
  buildFragment: buildSiteSelectionRowTableFragment,
  titleLabel: Locale.Attribute.Selections,
  columnConfig: {
    fields: supportedSiteSelectionRowTableColumns,
  },
};
