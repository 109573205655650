import { gql, useApolloClient, useMutation } from '@apollo/client';
import React, { cloneElement, useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Form, Input, Menu, message, Popconfirm, Space } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import EntitiesSearchContext from '../../../EntitiesSearchContext';
import { DEFAULT_CRITERIAS_DATA_QUERY as EntitiesSearchDataQuery } from '../../../index';
import criteriaBuilder from '../../../../../browse/search_old/types/criteriaBuilder';
import { SimpleSavedSearches_FRAGMENT } from '../SimpleSavedSearches';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import {
  DefaultCriteriasQueryQuery, DefaultCriteriasQueryQueryVariables,
  DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables,
  SaveNewSimpleSearchMutation,
  SaveNewSimpleSearchMutationVariables,
  UpdateExistingSearchMutation, UpdateExistingSearchMutationVariables
} from '../../../../../../gql/typings';

const SimpleSearchOverviewHeader: React.FC = () => {
  const [headingForm] = Form.useForm();
  const apollo = useApolloClient();
  const intl = useIntl();
  const {
    entityType,
    selectedSaved,
    setSelectedSaved,
    form,
    setSelectedCriterias,
  } = useContext(EntitiesSearchContext);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [
    createMutation,
    { loading: isCreating },
  ] = useMutation<SaveNewSimpleSearchMutation, SaveNewSimpleSearchMutationVariables>(CREATE_NEW_SEARCH_MUTATION);
  const [
    updateMutation,
    { loading: isUpdating },
  ] = useMutation<UpdateExistingSearchMutation, UpdateExistingSearchMutationVariables>(UPDATE_EXISTING_SEARCH_MUTATION);
  const [
    deleteMutation,
    { loading: isDeleting },
  ] = useMutation<DeleteSavedSearchMutation, DeleteSavedSearchMutationVariables>(DELETE_SAVED_SEARCH_MUTATION);

  const loading = isCreating || isUpdating || isDeleting;

  useEffect(() => {
    if (selectedSaved) {
      headingForm.setFieldsValue({ 'search-name': selectedSaved.heading });
    }
  }, [headingForm, selectedSaved]);

  const onDelete = () => {
    setIsDropdownVisible(false);
    if (!selectedSaved) return;

    deleteMutation({ variables: { id: selectedSaved.id } }).then(res => {
      if (get(res, 'data.deleteSavedSearchCriteria')) {
        // 1. show success message of deletion
        message.success(intl.formatMessage(
          Locale.Text.Successfully_delete_saved_search,
          { name: <b>{selectedSaved.heading})</b> },
        ));

        // 2. Reset workingCriterias to defaults.
        const cache = apollo.readQuery<DefaultCriteriasQueryQuery, DefaultCriteriasQueryQueryVariables>({
          query: EntitiesSearchDataQuery,
          variables: { entityType },
        });
        setSelectedCriterias(cache?.defaultCriterias?.nodes?.map(dc => dc.criteria) ?? []);
        form.resetFields();
        setSelectedSaved(null);
      } else message.error(intl.formatMessage(Locale.Text.Failed_to_delete_saved_search,
        { name: <b>{selectedSaved.heading}</b> }));
    });
  };

  const onSaveAsNew = () => headingForm.validateFields().then(values => {
    createMutation({
      variables: {
        name: values['search-name'],
        entityType,
        criterias: criteriaBuilder({
          searchFilter: form.getFieldsValue(),
          filterOutEmpties: false,
        }).criteria.criterias ?? [],
      },
    }).then(res => {
      const returnedValue = get(res, 'data.saveNewSimpleCriteria');
      if (returnedValue) {
        message.success(intl.formatMessage(Locale.Text.Successfully_created_new_saved_search,
          { name: <b>{values['search-name']}</b> }));
        setSelectedSaved(returnedValue);
      } else message.error(intl.formatMessage(Locale.Text.Failed_to_create_new_saved_search));
    });
  });

  const onSaveUpdates = () => headingForm.validateFields().then(values => {
    if (!selectedSaved) throw Error('onSaveUpdates:failed. No selected criteria???');
    updateMutation({
      variables: {
        id: selectedSaved.id,
        name: values['search-name'],
        criterias: criteriaBuilder({
          searchFilter: form.getFieldsValue(),
          filterOutEmpties: false,
        }).criteria.criterias ?? [],
      },
    }).then(res => {
      if (get(res, 'data.updateSimpleCriteria')) {
        message.success(intl.formatMessage(
          Locale.Text.Successfully_updated_saved_search,
          { name: <b>{selectedSaved.heading}</b> },
        ));
      } else message.error(intl.formatMessage(
        Locale.Text.Failed_to_update_saved_search,
        { name: <b>{values['search-name']}</b> },
      ));
    });
  });

  const SelectedButtons = () => selectedSaved ? (
    <Dropdown.Button
      trigger={['click']}
      size="small" // TODO: Should be 'middle', but for some reason ant makes that into large.
      open={isDropdownVisible}
      onOpenChange={setIsDropdownVisible}
      onClick={onSaveUpdates}
      buttonsRender={([lb, rb]) => [
        lb,
        // @ts-ignore
        cloneElement(rb, { loading }),
      ]}
      overlay={
        <Menu className="simple-search-overview-header-menu-container">
          <Menu.Item onClick={onSaveAsNew}>
            {intl.formatMessage(Locale.Text.Save_as_new_search)}
          </Menu.Item>
          <Menu.Item>
            <Popconfirm
              placement="bottomRight"
              className="simple-search-overview-header-menu-container"
              icon={<ExclamationCircleOutlined className="delete-search-icon" />}
              okText={intl.formatMessage(Locale.General.Yes)}
              onConfirm={onDelete}
              onCancel={() => setIsDropdownVisible(false)}
              title={intl.formatMessage(
                Locale.Text.Are_you_sure_to_delete_saved_search,
                { name: <b>{selectedSaved.heading}</b> },
              )}
            >
              <Space>
                {intl.formatMessage(Locale.Text.Delete_saved_search)}
                <DeleteOutlined className="delete-search-icon" />
              </Space>
            </Popconfirm>
          </Menu.Item>
        </Menu>
      }
    >
      {intl.formatMessage(Locale.Command.Save_changes)}
    </Dropdown.Button>
  ) : (
    <div style={{ textAlign: 'right' }}>
      <Button type="primary" size="middle" style={{ background: '#cdcd23' }} onClick={onSaveAsNew} loading={loading}>
        {intl.formatMessage(Locale.Command.Save_search)}
      </Button>
    </div>

  );

  return (
    <div className="simple-search-overview-header-container">
      <Form form={headingForm}>
        <div className="heading">
          {intl.formatMessage(Locale.Text.Save_favorite_search)}
        </div>
        <Form.Item
          name="search-name"
          rules={[
            {
              required: true,
              message: intl.formatMessage(Locale.Text.Name_required_for_new_saved_search),
            },
          ]}
        >
          <Input
            className="search-name"
            placeholder={intl.formatMessage(Locale.Text.Enter_name_for_new_saved_search)}
          />
        </Form.Item>
        <SelectedButtons />
      </Form>
    </div>
  );
};

const DELETE_SAVED_SEARCH_MUTATION = gql`
  mutation DeleteSavedSearch($id: Int!) {
    deleteSavedSearchCriteria(id: $id)
  }
`;

const UPDATE_EXISTING_SEARCH_MUTATION = gql`
  mutation UpdateExistingSearch($id: Int!, $name: String!, $criterias: [DynamicCriteriaInput!]!) {
    updateSimpleCriteria(id: $id, name: $name, criterias: $criterias) {
      id
      heading
      totalCount
    }
  }
`;

const CREATE_NEW_SEARCH_MUTATION = gql`
  mutation SaveNewSimpleSearch($name: String!, $entityType: EntityTypeEnum!, $criterias: [DynamicCriteriaInput!]!) {
    saveNewSimpleCriteria(entityType: $entityType, name: $name, criterias: $criterias) {
      ...SimpleSavedSearchFragment
    }
  }
  ${SimpleSavedSearches_FRAGMENT}
`;

export default SimpleSearchOverviewHeader;
