import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Radio, Space, Tooltip, Typography } from 'antd';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import {
  EntityMergeFragment_SitePerson_Fragment,
  EntityTypeEnum,
  MergeItemAffiliationIsPrimaryFragment,
  MergeLinksSetPrimaryLinkMutation,
  MergeLinksSetPrimaryLinkMutationVariables,
  MergeRemovePrimaryLinkMutation,
  MergeRemovePrimaryLinkMutationVariables,
} from '../../../../../../../../../gql/typings';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { Optional } from '../../../../../../../../util/StateArrayType';
import MergeEntityLink from '../../components/MergeEntityLink';
import PrimaryKeyIcon from '../../../../../../../../components/ColoredCircle/PrimaryKeyIcon';
import { useRandomKey } from '../../../../../../../../hooks/useRandomKey';
import { MergeCustomEvent } from '../../../useEntityMergeEmitter';

export type AdminMergeItemAffiliationIsPrimaryCustomEvent = MergeCustomEvent & {
  autoResolveWithSiteId: number;
  disableForm?: boolean;
};

export const AdminMergeItemAffiliationIsPrimary_EVENT_KEY = 'AdminMergeItemAffiliationIsPrimary';

const AdminMergeItemAffiliationIsPrimary: React.FC<AdminEntityMergeRequestItemProps> = ({
  item,
  emitter,
  into,
  customEmitter
}) => {
  const info = item.type as MergeItemAffiliationIsPrimaryFragment;
  const localization = useLocalization();
  const toLink = into as Optional<EntityMergeFragment_SitePerson_Fragment>;
  const key = useRandomKey();
  const [selectedLink, setSelectedLink] = useState<null|number|'none'>();
  const [disableForm, setDisableForm] = useState(false);

  customEmitter.useSubscription(e => {
    // It's possible to provide a site id, so we can auto resolve this. Example coming from [useSiteLinksMerge]
    if (e.key === AdminMergeItemAffiliationIsPrimary_EVENT_KEY) {
      const event = (e as AdminMergeItemAffiliationIsPrimaryCustomEvent);
      const linkId = info.allLinksFromWinner.filter(w => w.site.id === event.autoResolveWithSiteId)[0]?.id;
      if (selectedLink !== linkId) {
        setSelectedLink(linkId);
        setDisableForm(event.disableForm ?? false);
      }
    }
  });

  const [set] = useMutation<MergeLinksSetPrimaryLinkMutation, MergeLinksSetPrimaryLinkMutationVariables>(SET_PRIMARY_MUTATION);
  const [remove] = useMutation<MergeRemovePrimaryLinkMutation, MergeRemovePrimaryLinkMutationVariables>(
    REMOVE_PRIMARY_MUTATION
  );

  useEffect(() => {
    emitter.emit({
      key,
      state: selectedLink ? 'finished' : 'not_started',
      _source: AdminMergeItemAffiliationIsPrimary_EVENT_KEY,
      onSave: () => {
        if (!selectedLink) return Promise.reject(
          Error('You need to select the primary link, or select \'none\' as the primary')
        );
        const currentPrimary: undefined|MergeItemAffiliationIsPrimaryFragment['allLinksFromWinner']['0'] = info
          .allLinksFromWinner
          .filter(f => f.isPrimary)[0];

        if (!currentPrimary && selectedLink === 'none') return Promise.resolve();
        if (currentPrimary && selectedLink === 'none') return remove({
          variables: { personId: currentPrimary.personId, currentPrimaryLink: currentPrimary.id },
        });
        return set({
          variables: {
            personId: toLink!.person.id,
            primaryLinkId: selectedLink as number,
          }
        });
      },
    });
  }, [key, emitter, selectedLink, info.allLinksFromWinner, remove, set, toLink]);

  return (
    <div>
      <Typography.Paragraph>
        {localization.formatText(Locale.Text.Merge_request_link_primary_mismatch_description, {
          personName: <MergeEntityLink type={EntityTypeEnum.PERSON} id={toLink!.person.id}>
            {toLink?.person.fullName}
          </MergeEntityLink>,
        })}
      </Typography.Paragraph>
      <Radio.Group
        value={selectedLink}
        onChange={e => setSelectedLink(e.target.value)}
        disabled={disableForm}
      >
        <Space direction="vertical">
          {info.allLinksFromWinner.map(l => (
            <Radio key={l.id} value={l.id}>
              <MergeEntityLink type={EntityTypeEnum.AFFILIATION} id={l.id}>{l.position?.displayLabel}</MergeEntityLink>
              &nbsp;@&nbsp;
              <MergeEntityLink type={EntityTypeEnum.SITE} id={l.site.id}>
                {l.site.name}
              </MergeEntityLink>
              &nbsp;
              {l.isPrimary && (
                <Tooltip title={localization.formatMessage(Locale.Text.This_is_current_primary_link)}>
                  <PrimaryKeyIcon />&nbsp;
                </Tooltip>
              )}
            </Radio>
          ))}
          <Radio value='none'>
            <Typography.Text italic>
              {localization.formatMessage(Locale.Attribute.None)}
            </Typography.Text>
          </Radio>
        </Space>
      </Radio.Group>
    </div>
  );
};

export const SET_PRIMARY_MUTATION = gql`
  mutation MergeLinksSetPrimaryLink($personId: ID!, $primaryLinkId: ID!) {
    setPrimaryLink(personId: $personId, linkId: $primaryLinkId) {
      id
      isPrimary
    }
  }
`;

export const REMOVE_PRIMARY_MUTATION = gql`
  mutation MergeRemovePrimaryLink($personId: ID!, $currentPrimaryLink: ID!) {
    setNonPrimaryLink(personId: $personId, linkId: $currentPrimaryLink) {
      id
      isPrimary
    }
  }
`;

gql`
  fragment MergeItemAffiliationIsPrimary on AffiliationIsPrimary_EntityDataToBeTransferredType {
    code
    allLinksFromWinner {
      id
      isPrimary
      position {
        code
        type
        displayLabel
      }
      personId: persId
      site {
        id
        name
      }
    }
  }
`;

export default AdminMergeItemAffiliationIsPrimary;
