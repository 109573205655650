import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { message, notification } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import ApbButton from '../../../components/Button/ApbButton';
import { Locale } from '../../../../localization/LocalizationKeys';
import NoteDrawer from '../../../components/Note/NoteDrawer';
import { ActivityDetailsInitialState } from '../../activity/ActivityDetails/ActivityDetailsTypes';
import BulkPersonsToActivity from './BulkPersonsToActivity';
import { useAddWorkplaceToPersonAction } from '../hooks/useAddWorkplaceToPersonAction';
import { useLocalization } from '../../../util/useLocalization';
import {
  EntityTypeEnum,
  GenerateGdprReportMutation,
  GenerateGdprReportMutationVariables,
  HcpHeaderQuery,
  PermissionEnum,
  SourceEnum,
} from '../../../../gql/typings';
import { useInactivatePersonAction } from './useInactivatePersonAction';
import useCreateListActions from '../../list/CreateList/useCreateListActions';
import { TableActionItem } from '../../../components/Table/Actions';
import { buildSelectionCriteria } from '../../../components/Table/utils';
import { usePermissions } from '../../../permission/usePermissions';
import EntityMergeModal from '../../../components/EntityMerge/EntityMergeModal';
import { StateArray } from '../../../util/StateArrayType';
import { PersonAffiliationsTabsEnum } from './PersonAffiliationsContainer';


type HcpSectionRightProps = {
  personId: number;
  activeTabKeyState: StateArray<PersonAffiliationsTabsEnum>;
  refreshEmitter: EventEmitter<void>;
  person?: HcpHeaderQuery['person'];
};

const HcpSectionRight: React.FC<HcpSectionRightProps> = ({
  personId,
  person,
  activeTabKeyState,
  refreshEmitter,
}) => {
  const localization = useLocalization();
  const permissions = usePermissions([PermissionEnum.MERGE_RECORD]);
  const [
    generateGdprReport,
    { loading: isGeneratingReport },
  ] = useMutation<GenerateGdprReportMutation, GenerateGdprReportMutationVariables>(GENERATE_GDPR_REPORT);
  const [isNoteDrawerVisible, setIsNoteDrawerVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const showMassAssignPersonsToActivityState = useState(false);
  const workplaceToPerson = useAddWorkplaceToPersonAction(personId, person?.countryCode);
  const inactivatePersonAction = useInactivatePersonAction(personId);
  const [createListAction, CreateListElement] = useCreateListActions({
    dynamicListSupport: false,
    entityType: EntityTypeEnum.PERSON,
    selection: buildSelectionCriteria({ selected: [personId] })
  });
  const mergeModalState = useState(false);

  const staticListItems = [...(createListAction.items ?? [])] as unknown as TableActionItem[];

  const onGenerateGdprReport = () => {
    generateGdprReport({ variables: { hcpId: personId } }).then(res => {
      if (res.data && res.data.report) {
        window.location.href = res.data.report.url;
      } else message.error(localization.formatMessage(Locale.Text.Generation_failed));
    });
  };

  const onClickOnRemoveWorkplaceActionBtn = () => {
    activeTabKeyState[1](PersonAffiliationsTabsEnum.sites);
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
    notification.open({
      message: localization.formatMessage(Locale.Text.Dcr_remove_affiliation_heading),
      description: localization.formatMessage(Locale.Text.Dcr_remove_affiliation_text)
    });
  };


  const menu = [
    {
      key: '2',
      onClick: onGenerateGdprReport,
      label: localization.formatMessage(Locale.General.Generate_GDPR_report)
    },
    {
      key: '3',
      onClick: () => setIsNoteDrawerVisible(true),
      label: localization.formatMessage(Locale.Command.Create_Note),
    },
    workplaceToPerson.action,
    {
      key: '5',
      onClick: onClickOnRemoveWorkplaceActionBtn,
      label: localization.formatMessage(Locale.Command.Remove_workplace),
    },
    {
      key: 'add-to-new-activity',
      label: localization.formatMessage(Locale.Text.Add_to_new_Activity),
      onClick: () => {
        const state: ActivityDetailsInitialState = { personIds: [personId] };
        navigate('/activity/create', { state });
      },
    },
    {
      key: 'add-to-excisting-activity',
      label: localization.formatMessage(Locale.Text.Add_people_to_activity),
      onClick: () => {
        showMassAssignPersonsToActivityState[1](true);
      },
    },
    {
      key: 'merge-record',
      label: localization.formatMessage(Locale.Command.Merge_record),
      hide: !permissions.MERGE_RECORD.create || person?.maintainerSourceCode !== SourceEnum.LOCAL,
      onClick: () => mergeModalState[1](true),
    },
    inactivatePersonAction.action,
    ...staticListItems
  ];

  return (
    <div className="content-right-section">
      <div className="button-select-container">
        <ApbButton
          icon={<DownOutlined />}
          menuItems={menu}
          size="large"
          loading={isGeneratingReport || inactivatePersonAction.loading}
          buttonType="action"
          text={localization.formatMessage(Locale.Text.Actions_to_take)}
        />
        <CreateListElement />
      </div>

      {/*    <AddToExistingStaticListModal
        visible={showMassAssign}
        entityType={EntityTypeEnum.PERSON}
        selection={buildSelectionCriteria({ selected: [personId] })}
        onClose={() => setShowMassAssign(false)}
       /> */}
      <NoteDrawer
        id={personId}
        entity={EntityTypeEnum.PERSON}
        displayDrawer={isNoteDrawerVisible}
        setDisplayDrawer={setIsNoteDrawerVisible}
      />

      <BulkPersonsToActivity
        visibleState={showMassAssignPersonsToActivityState}
        personId={personId}
      />

      <EntityMergeModal
        entityType={EntityTypeEnum.PERSON}
        visibleState={mergeModalState}
        fromId={personId}
        onCompleteHook={refreshEmitter.emit}
      />

      {workplaceToPerson.hidden}
      {inactivatePersonAction.hidden}
    </div>
  );
};


const GENERATE_GDPR_REPORT = gql`
  mutation GenerateGdprReport($hcpId: Int!) {
    report: generateGdprInformation(hcpId: $hcpId) {
      url
    }
  }
`;

export default HcpSectionRight;
