import React from 'react';

const DisplaySites = ({
  nodes,
}) => {
  const size = nodes.length;
  return (
    <span className="display-persons-card">
      {/* eslint-disable-next-line max-len */}
      {size !== 0 ? nodes.map((s, index) => `${s.name}${size === 0 || index === size - 1 ? '' : ', '}`) : ''}
    </span>
  );
};


export default DisplaySites;
