import React, { Suspense } from 'react';
import { Skeleton } from 'antd';
import { RichTextEditorProps } from './RichTextTypes';

const Comp = React.lazy(() => import('./RichTextEditor__LAZY'));

const RichTextEditor: React.FC<RichTextEditorProps> = (props) => (
  <Suspense fallback={<Skeleton loading active />}>
    <Comp {...props} />
  </Suspense>
);

export default RichTextEditor;
