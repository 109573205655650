import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { Badge, Card } from 'antd';
import AdminExternalIdModal from './AdminExternalIdModal';
import { useColors } from '../../../../util/useColor';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import { EntityTypeEnum, ExternalIdAdminQueryQuery, useExternalIdAdminQueryQuery } from '../../../../../gql/typings';

type AdminExternalIdProps = {
  entityType: EntityTypeEnum;
};

const AdminExternalId: React.FC<AdminExternalIdProps> = ({ entityType }) => {
  const localization = useLocalization();
  const selectedCodeState = useState<string>();
  const { colorPrimary: primaryColor } = useColors();
  const { data, loading } = useExternalIdAdminQueryQuery({
    variables: { entityType },
  });

  const displayHeading = (type: NonNullable<ExternalIdAdminQueryQuery['externalIdTypes']>['nodes']['0']) => {
    if (type.isHeadingKey) return localization.formatMessageByStr(type.heading);
    return type.heading;
  };

  return (
    <div>
      <Card loading={loading}>
        {data?.externalIdTypes.nodes.map(eit => (
          <Card.Grid key={eit.code} style={{ cursor: 'pointer' }} onClick={() => selectedCodeState[1](eit.code)}>
            <strong>
              {displayHeading(eit)}
              &nbsp;
              <Badge
                showZero
                count={eit.externalIds.totalCount}
                size="small"
                style={{ backgroundColor: primaryColor }}
              />
            </strong>
          </Card.Grid>
        ))}
        {!loading && data?.externalIdTypes.nodes.length === 0 && (
          <Card.Grid>
            <i>{localization.formatMessage(Locale.Text.No_external_id_types_warning)}</i>
          </Card.Grid>
        )}
      </Card>
      <AdminExternalIdModal selectedCodeState={selectedCodeState} />
    </div>
  );
};

gql`
  query ExternalIdAdminQuery($entityType: EntityTypeEnum!) {
    externalIdTypes(criteria: { entityType: $entityType }) {
      hash
      nodes {
        code
        heading
        isHeadingKey
        description
        externalIds {
          hash
          totalCount
        }
      }
    }
  }
`;

export default AdminExternalId;
