import React from 'react';
import { useIntl } from 'react-intl';
import MainView from '../../components/View/MainView';
import { EntitiesSearch } from '../../components/entitiesSearch';
import { usePageTitle } from '../../hooks/usePageTitle';
import { Locale } from '../../../localization/LocalizationKeys';

const ProjectView = () => {
  const { formatMessage } = useIntl();
  const headerStr = formatMessage(Locale.General.Projects);
  usePageTitle(headerStr);
  return (
    <MainView className="project-view-container" title={headerStr}>
      <EntitiesSearch
        selectedState={[[], () => {}]}
        entityType="PROJECT"
        urlSearchEnabled
      />
    </MainView>
  );
};

export default ProjectView;
