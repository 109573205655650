import { useLazyQuery, gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'antd';
import { endsWith, join, isArray, keys, isString, isNumber } from 'lodash';
import moment from 'moment';
import SingleLineSkeleton from '../../../components/Skeleton/SingleLineSkeleton';
import { Locale } from '../../../../localization/LocalizationKeys';
import DisplayContent from '../../../components/Badge/DisplayContent';
import { ReportControllerType } from '../controller/reportController';
import { AnyValueType } from '../../../util/StateArrayType';
import {
  ProductsForReportOverviewQuery, ProductsForReportOverviewQueryVariables,
  UsersForReportOverviewQuery,
  UsersForReportOverviewQueryVariables
} from '../../../../gql/typings';
import { DATE_FORMAT } from '../../../util/format';


const ReportOverview: React.FC<{ controller: ReportControllerType }> = ({
  controller,
}) => {
  const { formatMessage } = useIntl();
  const reportType = controller.selectedReport?.heading ?? '';
  const reportDesc = controller.selectedReport?.description ?? '';
  const [loadUsers, {
    called: loadUsersCalled,
    loading: usersLoading,
    data: usersData,
  }] = useLazyQuery<UsersForReportOverviewQuery, UsersForReportOverviewQueryVariables>(USERS_DATA);
  const [loadProducts, {
    called: loadProductsCalled,
    loading: productsLoading,
    data: productsData,
  }] = useLazyQuery<ProductsForReportOverviewQuery, ProductsForReportOverviewQueryVariables>(PRODUCTS_DATA);
  const [values, setValues] = useState<Record<string, AnyValueType>>({});

  const formValues = controller.form.getFieldsValue();

  useEffect(() => {
    if (keys(formValues).length > 0) setValues(formValues);
  }, [formValues, setValues]);

  const displayValue = (key: string, value: AnyValueType): React.ReactNode => {
    if (endsWith(key, '.users')) {
      if (!loadUsersCalled) loadUsers({
        variables: {
          userIds: value as number[] | null,
        },
      });
      return (
        <SingleLineSkeleton loading={usersLoading || !loadUsersCalled}>
          {usersData && usersData.users.nodes.map((user, index) => (
            <span key={user.id}>
              <DisplayContent>
                {user.fullname}
              </DisplayContent>

              {index !== usersData.users.nodes.length - 1 && ', '}
            </span>
          ))}
        </SingleLineSkeleton>
      );
    }
    if (endsWith(key, '.Product')) {
      if (!loadProductsCalled) loadProducts({
        variables: {
          productIds: value as number[] | null,
        },
      });
      return (
        <SingleLineSkeleton loading={productsLoading || !loadProductsCalled}>
          {productsData && productsData.products.nodes.map((product, index) => (
            <span key={product.id}>
              {product.heading}
              {/* <LinkBadge */}
              {/*  url={`/product/${product.id}`} */}
              {/*  label={product.heading} */}
              {/*  openInNewTab */}
              {/* /> */}
              {index !== productsData.products.nodes.length - 1 && ', '}
            </span>
          ))}
        </SingleLineSkeleton>
      );
    }
    if (moment.isMoment(value)) {
      if (endsWith(key, '.month')) return value.format('MMMM YYYY');
      if (endsWith(key, '.year')) return value.format('YYYY');
      return value.format(DATE_FORMAT);
    }
    if (isString(value) || isNumber(value)) return value;
    if (isArray(value)) return join((value as AnyValueType[]).map(v => displayValue(key, v)), '; ');

    // eslint-disable-next-line no-console
    console.error(`Couldn't display value [key: ${key}]`, value);
    return 'N/A';
  };

  return (
    <div>
      <h1>
        {formatMessage(Locale.General.Report_overview)}
      </h1>
      <Row style={{ textAlign: 'center' }}>
        <Col span={24}>
          <strong>
            {formatMessage(Locale.General.Report_type)}
          </strong>:
          &nbsp;
          {reportType}
        </Col>
        <Col span={24}>
          <strong>
            {formatMessage(Locale.General.Report_Description)}
          </strong>:
          &nbsp;
          {reportDesc}
        </Col>
        {keys(values).map(key => (
          <Col span={24} key={key}>
            <b>{formatMessage({ id: key })}</b>: {displayValue(key, values[key])}
          </Col>
        ))}
      </Row>
    </div>
  );
};


const USERS_DATA = gql`
  query UsersForReportOverview($userIds: [ID!]) {
    users(criteria: {ids: $userIds}) {
      hash
      nodes {
        id
        fullname
      }
    }
  }
  
`;
const PRODUCTS_DATA = gql`
  query ProductsForReportOverview($productIds: [ID!]) {
    products(criteria: {ids: $productIds}) {
      hash
      nodes {
        id
        heading
      }
    }
  }
`;


export default ReportOverview;

