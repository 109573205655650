import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import DateDisplay from '../../../components/Badge/DateDisplay';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SourceEnum } from '../../../../gql/typings';

export const siteUpdatedFieldConfig = siteFieldConfig(
  'updated',
  ['ut', 'imfUt', 'maintainerSourceCode'],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Updated),
    additionalTableConfig: {
      width: 125
    },
    render: (record) => {
      const isLocalDB = record.maintainerSourceCode === SourceEnum.LOCAL;
      return <DateDisplay>{isLocalDB ? record.ut : record.imfUt}</DateDisplay>;
    },
  })
);

