/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import { isNil, toUpper } from 'lodash';
import { useIntl } from 'react-intl';
import { criteriaInputKeyGenerator } from '../CriteriaInputFactory';
import Icon from '../../Icon/Icon';
import { CriteriaInputProps } from '../CriteriaInputTools';

const CriteriaTextInput: React.FC<CriteriaInputProps> = React.memo(({
  form,
  criteria,
  inputPath,
  disabled,
  initialValue,
}) => {
  const { formatMessage } = useIntl();
  const key = criteriaInputKeyGenerator(criteria, inputPath);
  useEffect(() => {
    if (!isNil(initialValue)) form.setFieldsValue({ [key]: initialValue });
  }, [initialValue]);

  return (
    <Form.Item name={key}>
      <Input
        id={key}
        size='large'
        placeholder={inputPath.placeholderHeading && !disabled
          ? formatMessage({ id: inputPath.placeholderHeading, defaultMessage: inputPath.placeholderHeading })
          : ''}
        disabled={disabled}
        suffix={inputPath.icon && <Icon
          // @ts-ignore
          iconType={toUpper(inputPath.icon)}
          style={{ opacity: 0.5 }}
        />}
        /* Seems that autoComplete={off} is not working for chrome. Why newpassword is beint used */
        autoComplete="newpassword"
      />
    </Form.Item>
  );
});

export default CriteriaTextInput;
