import React from 'react';
import { Col, Row } from 'antd';

type NameTagProps = {
  image: React.ReactNode;
  title?: string;
  subTitle?: string|React.ReactNode;
};

const NameTag: React.FC<NameTagProps> = ({
  image,
  title,
  subTitle,
}) => {
  const valuesComp = (
    <div className={subTitle ? 'name-container multiple' : 'name-container single'}>
      Profile Card:
      <>
        <Row className="name-tag-columns">
          <Col>
            <div>
              <div className="name-tag-label">
                <h1>{title}</h1>
              </div>
            </div>
          </Col>
        </Row>
        {(subTitle) && (
          <div className="site-affiliations">
            {subTitle}
          </div>
        )}
      </>
    </div>
  );

  return (
    <div className="name-tag">
      <div className="content">
        <div className="icon">
          {image}
        </div>
      </div>
      {valuesComp}
    </div>
  );
};

export default NameTag;
