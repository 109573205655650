import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';

export const personIdFieldConfig = personFieldConfig(
  'id',
  ['id'],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.PureAdvance_ID),
    additionalTableConfig: {
      width: 110
    },
    render: record => record.id,
  })
);
