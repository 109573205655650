import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { Table } from 'antd';
import CherryPickForOtherInstanceModal from './CherryPickForOtherInstanceModal';
import { EntityTypeEnum, useEntityCherryPickOverviewQuery } from '../../../gql/typings';
import { useLocalization } from '../../util/useLocalization';
import { usePermission } from '../../permission/usePermission';
import Puffin from '../Puffin/Puffin';
import ApbTableHeader from '../Table/ApbTableHeader';
import EntitiesSearchQuickActions from '../entitiesSearch/top/EntitiesSearchQuickActions';
import { Locale } from '../../../localization/LocalizationKeys';
import DateDisplay from '../Badge/DateDisplay';

type CherryPickTabContainerProps = {
  apurebaseId: number;
  countryCode: string;
  entityType: EntityTypeEnum.PERSON|EntityTypeEnum.SITE;
};

const CherryPickTabContainer: React.FC<CherryPickTabContainerProps> = ({
  apurebaseId,
  countryCode,
  entityType,
}) => {
  const localization = useLocalization();
  const { create } = usePermission('CHERRY_PICK_OTHER_INSTANCE');
  const { data, loading, refetch } = useEntityCherryPickOverviewQuery({
    variables: { entityApbId: apurebaseId, entityType },
  });
  const modalVisibleState = useState(false);

  if ((data?.instancesRecordCherryPickOverview?.length ?? 0) > 1) {
    return <Puffin type="crashLand" message="Something went wrong. Please contact support!" />;
  }

  return (
    <div className="person-cherry-pick-tab-container">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ApbTableHeader
          count={loading ? undefined : data?.instancesRecordCherryPickOverview?.[0]?.cherryPickedForCustomerCodes?.length ?? 0}
        />
        <EntitiesSearchQuickActions quickActions={[
          {
            key: 'cherry-pick',
            label: localization.formatMessage(Locale.Attribute.Cherry_pick),
            hide: !create,
            onClick: () => modalVisibleState[1](true),
          },
        ]}
        />
      </div>
      <Table
        dataSource={data?.instancesRecordCherryPickOverview?.[0]?.cherryPickedForCustomerCodes ?? []}
        size="small"
        pagination={false}
        rowKey={e => e.customerCode}
        columns={[
          {
            title: localization.formatMessage(Locale.Attribute.Customer),
            render: (_, item) => item.customer?.heading ?? item.customerCode,
          },
          {
            title: localization.formatMessage(Locale.Attribute.Timestamp),
            render: (_, item) => <DateDisplay>{item.timestamp}</DateDisplay>
          },
          {
            title: localization.formatMessage(Locale.Attribute.By_system),
            render: (_, item) => item.bySystem,
          },
          {
            title: localization.formatMessage(Locale.Attribute.By_user),
            render: (_, item) => item.byUser?.fullname ?? item.byUser,
          },
        ]}
      />
      <CherryPickForOtherInstanceModal
        visibleState={modalVisibleState}
        apurebaseId={apurebaseId}
        countryCode={countryCode}
        entityType={entityType}
        refetch={refetch}
      />
    </div>
  );
};

gql`
  query EntityCherryPickOverview($entityApbId: Int!, $entityType: EntityTypeEnum!) {
    instancesRecordCherryPickOverview(entityType: $entityType, entityApbId: $entityApbId) {
      apurebaseId
      internationalId
      cherryPickedForCustomerCodes {
        customerCode
        bySystem
        bySystemUserId
        timestamp
        customer {
          code
          heading
        }
        byUser {
          id
          fullname
        }
      }
    }
  }
`;

export default CherryPickTabContainer;
