import React from 'react';
import { gql } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  EntityTypeEnum, FieldEnum, PersonCriteriaOrder,
  UpdateHcpFirstNameMutationMutationVariables
} from '../../../../gql/typings';
import { buildFreeTextUpdateFieldConfig } from '../../../components/FieldConfig/buildFreeTextUpdateFieldConfig';


export const personFirstNameFieldConfig = personFieldConfig(
  'firstName',
  [
    'id',
    'firstName',
    'isLocal',
    'controlSetting.id',
    'controlSetting.createDcr',
  ],
  ({ localization, openInNewTab }) => ({
    dcrInfo: person => ({
      entityType: EntityTypeEnum.PERSON,
      entityAffiliationId: person.id,
      field: FieldEnum.HCP_FIRSTNAME,
    }),
    sorting: {
      ascend: [PersonCriteriaOrder.FIRSTNAME_ASC],
      descend: [PersonCriteriaOrder.FIRSTNAME_DESC],
    },
    additionalTableConfig: {
      width: 150
    },
    title: localization.formatMessage(Locale.Attribute.First_name),
    render: (record, { isViewingFromPage, isViewingFromEntity }) => {
      if (isViewingFromPage == 'DETAIL_PAGE' && isViewingFromEntity === 'PERSON') return record.firstName;
      return <LinkBadge
        url={record.isLocal ? `/hcp/${record.id}` : undefined}
        label={record.firstName}
        openInNewTab={openInNewTab}
      />;
    },
    updateView: buildFreeTextUpdateFieldConfig({
      mutation: UPDATE_MUTATION,
      valuePath: 'firstName',
      buildVariables: (v, record) => ({
        firstName: v,
        persId: record.id,
      }) as UpdateHcpFirstNameMutationMutationVariables,
    }),
  }),
);


const UPDATE_MUTATION = gql`
  mutation UpdateHcpFirstNameMutation($persId: Int!,$firstName: String!) {
    updatePersonFirstName(persId: $persId, firstName: $firstName) {
      id
      firstName
      lastName
      fullName
      title
    }
  }
`;
