import React from 'react';
import { gql } from '@apollo/client';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import {
  MergeItemSiteMailingNamePrefixFragment,
  useUpdateSiteMailingNamePrefixMutation,
} from '../../../../../../../../../gql/typings';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';


const AdminMergeItemSiteMailingNamePrefix: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemSiteMailingNamePrefixFragment;
  const localization = useLocalization();
  const [updatePrefix] = useUpdateSiteMailingNamePrefixMutation();

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.fromPrefixName}
      toValue={info.toPrefixName}
      updateMutation={newValue => updatePrefix({
        variables: {
          siteId: props.into.id,
          newValue,
        },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_mailing_name_prefix_mismatch_description, {
        name1: <strong>{info.fromPrefixName}</strong>,
        name2: <strong>{info.toPrefixName}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation UpdateSiteMailingNamePrefix($siteId: Int!, $newValue: String!) {
    updateSiteMailingNamePrefix(siteId: $siteId, mailingNamePrefix: $newValue) {
      id
      mailingNamePrefix
    }
  }
`;

gql`
  fragment MergeItemSiteMailingNamePrefix on SiteMailingNamePrefix_EntityDataToBeTransferredType {
    code
    fromPrefixName: from
    toPrefixName: to
  }
`;

export default AdminMergeItemSiteMailingNamePrefix;
