import { get } from 'lodash';
import { IntlShape } from 'react-intl';
import { Locale } from '../../localization/LocalizationKeys';

export const logout = () => localStorage.removeItem('token');

export const authenticate = (
  data: { username: string; password: string; rememberSelected: boolean },
  intl: IntlShape,
  urlPrefix = '',
) => fetch(`${urlPrefix}/auth/login`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(data),
})
  .then((res) => {
    if (res.status === 200) {
      return res.json()
        .then(({ token }) => {
          localStorage.setItem('token', `bearer ${token}`);
          return token;
        });
    }
    logout();
    return res
      .json()
      .then((e) => Promise.reject(get(e, 'errors[0].message', intl.formatMessage(Locale.Text.Something_went_wrong))));
  });

export const isAuthenticated = () => !!localStorage.getItem('token');

export const doPasswordsMatch = (newPassword: string, repeatedPassword: string) => newPassword === repeatedPassword;

export const isPasswordValid = (password: string) => {
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  return password.match(regex);
};
