/* eslint-disable no-case-declarations */
import { useEffect, useReducer, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Schema, { SyncErrorType, RuleItem, Rules } from 'async-validator';
import { groupBy } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import Duration from 'dayjs/plugin/duration';
import { Optional, PartialRecord } from '../../../util/StateArrayType';
import { ActivityDetailsInitialState, ActivityTypeProp } from './ActivityDetailsTypes';
import { LocalizationShape, useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { ActivityStatusEnum, AttachmentRuleEnum } from '../../../../gql/typings';

export type ActivityDetailsStateDataOnly = {
  heading?: string;
  startDate: Dayjs;
  endDate: Dayjs;
  status: ActivityStatusEnum;
  activityType?: ActivityTypeProp;
  responsibleUserIds?: number[];
  participationUserIds?: number[];
  locations?: Array<string | number>;
  projectIds?: number[];
  productIds?: number[];
  productBrandIds?: number[];
};

export type ActivityDetailsStateData = ActivityDetailsStateDataOnly & {
  activityId?: Optional<number>;
  localization: LocalizationShape;
  validator: Schema;
  personIds?: number[];
  show: boolean;
};


type InitialState = ActivityDetailsInitialState & {
  activityId?: Optional<number>;
};

export type ActivityDetailsAction =
  | { type: 'reset'; initial: Optional<InitialState> }
  | { type: 'updateHeading'; value: Optional<string> }
  | { type: 'updateType'; value?: ActivityTypeProp }
  | { type: 'updateResponsibleUsers'; userIds: number[] }
  | { type: 'updateLocations'; values: Array<string | number> }
  | { type: 'updateProductIds'; productIds: number[] }
  | { type: 'updateStatus'; value: ActivityStatusEnum }
  | { type: 'updateStartDate'|'updateEndDate'; value: Date|Dayjs|null }
  | { type: 'updateDate'; start: Date|Dayjs; end: Date|Dayjs|null }
  | { type: 'updatePersonIds'; personIds: number[] }
  | { type: 'showAgain' };


const initState = (localization: LocalizationShape, initialState: Optional<InitialState>): ActivityDetailsStateData => ({
  localization,
  activityId: initialState?.activityId,
  startDate: initialState?.startDate ?? dayjs().minute(Math.ceil(dayjs().minute() / 15) * 15).second(0),
  endDate: initialState?.endDate ?? dayjs().minute(Math.ceil(dayjs().minute() / 15) * 15).second(0),
  personIds: initialState?.personIds,
  locations: initialState?.siteId ? [initialState.siteId] : undefined,
  status: ActivityStatusEnum.IN_PROGRESS,
  activityType: undefined,
  validator: new Schema({}),
  show: true,
});

function buildRules(
  localization: LocalizationShape,
  forType: ActivityTypeProp
): Rules {
  const rules: PartialRecord<keyof ActivityDetailsStateDataOnly, RuleItem[]> = {
    heading: [
      {
        type: 'string',
        required: true,
        message: localization.formatMessage(Locale.Text.You_need_to_specify_a_heading),
      },
    ],
  };

  // ** locationRule ** //
  switch (forType.locationRule) {
    case AttachmentRuleEnum.MANY:
    case AttachmentRuleEnum.ONE:
      rules.locations = [
        {
          message: localization.formatMessage(Locale.Text.No_location_added),
          validator: (_, values) => !!(values && values.length > 0),
        }
      ];
      break;
    default:
      break;
  }

  // ** participationUserRule ** //

  // ** personRule ** //


  // ** projectRule ** //


  // ** responsibleUserRule ** //
  switch (forType.responsibleUserRule) {
    case AttachmentRuleEnum.ONE:
      rules.responsibleUserIds = [
        {
          len: 1,
          message: localization.formatMessage(Locale.Text.Activity_requires_exactly_one_responsible_user),
        },
        {
          required: true,
          message: localization.formatMessage(Locale.Text.Responsible_user_is_required),
        },
      ];
      break;
    case AttachmentRuleEnum.MANY:
      rules.responsibleUserIds = [
        {
          required: true,
          message: localization.formatMessage(Locale.Text.Responsible_user_is_required),
        },
      ];
      break;
    default:
      break;
  }


  // ** productRule ** //
  switch (forType.productRule) {
    case AttachmentRuleEnum.ONE:
    case AttachmentRuleEnum.MANY:
      rules.productIds = [
        {
          message: localization.formatMessage(
            Locale.Text.type_required,
            { type: localization.formatMessage(Locale.Attribute.Product) },
          ),
          validator: (_, value) => (value?.length ?? 0) !== 0
        }
      ];
      break;
    default:
      break;
  }

  // ** brandRule ** //


  return rules as Rules;
}

export function activityDataReducer(state: ActivityDetailsStateData, action: ActivityDetailsAction) {
  dayjs.extend(Duration);
  switch (action.type) {
    case 'showAgain':
      return {
        ...state,
        show: true,
      };
    case 'reset':
      return {
        ...initState(state.localization, action.initial),
        show: false,
      };
    case 'updateResponsibleUsers':
      return {
        ...state,
        responsibleUserIds: action.userIds,
      };
    case 'updateLocations':
      return {
        ...state,
        locations: action.values,
      };
    case 'updateProductIds':
      return {
        ...state,
        productIds: action.productIds,
      };
    case 'updateStatus':
      return {
        ...state,
        status: action.value,
      };
    case 'updateHeading':
      return {
        ...state,
        heading: action.value ?? '',
      };
    case 'updateType':
      /**
         * Whenever the user selects a new activity type, and it looks like they haven't changed the endDate,
         * we will automatically change the endDate using the [standardTimeLengthMinutes] from the newly selected activity type.
         */
      let yes = false;
      if (!state.activityId && action.value) {
        if (state.startDate.isSame(state.endDate, 'minute')) yes = true;
        else if (state.activityType?.standardTimeLengthMinutes) {
          const minusPrevious = state.endDate.clone().subtract(state.activityType.standardTimeLengthMinutes, 'minutes');
          if (state.startDate.isSame(minusPrevious, 'minute')) yes = true;
        }
      }
      const endDate = yes
        ? state.startDate.clone().add(action.value!.standardTimeLengthMinutes, 'minutes')
        : state.endDate;

      const validator = action.value
        ? new Schema(buildRules(state.localization, action.value))
        : state.validator;
      return {
        ...state,
        activityType: action.value,
        endDate,
        validator,
      };
    case 'updateStartDate':
      const newStartDate = dayjs(action.value);
      const durationDifference = dayjs.duration(newStartDate.diff(state.startDate));
      const newEndDate = dayjs(state.endDate);
      newEndDate.add(durationDifference);
      return {
        ...state,
        startDate: newStartDate,
        endDate: newEndDate,
      };
    case 'updateEndDate':
      return {
        ...state,
        endDate: dayjs(action.value),
      };
    case 'updateDate':
      return {
        ...state,
        startDate: dayjs(action.start),
        endDate: dayjs(action.end),
      };
    case 'updatePersonIds':
      return {
        ...state,
        personIds: action.personIds,
      };
    default:
      // eslint-disable-next-line no-console
      console.error('No logic implemented for action', action);
      return state;
  }
}

export type ActivityDetailErrorState = {
  [key in keyof ActivityDetailsStateDataOnly]: SyncErrorType[]
};

export const useActivityReducer = (initialState: InitialState): [
  ActivityDetailsStateData,
  Optional<ActivityDetailErrorState>,
  (action: ActivityDetailsAction) => void
] => {
  const localization = useLocalization();
  const [doValidate, setDoValidate] = useState(false);
  const [errors, setErrors] = useState<ActivityDetailErrorState>();
  // TODO: This should be inside the reducer instead of a normal state
  const [state, dispatch] = useReducer(
    activityDataReducer,
    initState(localization, initialState),
  );

  useEffect(() => {
    if (doValidate) state.validator.validate(state, { suppressWarning: true })
      .then(() => setErrors(undefined))
      .catch((res: { errors: SyncErrorType[] }) => {
        setErrors(groupBy(res.errors, 'field') as ActivityDetailErrorState);
      });
  }, [doValidate, state]);

  useEffect(() => {
    if (!state.show) {
      setDoValidate(false);
      dispatch({ type: 'showAgain' });
    } else setTimeout(() => {
      setDoValidate(true);
    }, 1500);
  }, [state.show]);

  return [
    state,
    errors,
    dispatch,
  ];
};

