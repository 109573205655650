import React from 'react';
import { Button, Result, Space } from 'antd';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import MainView from '../../../../components/View/MainView';
import { Locale } from '../../../../../localization/LocalizationKeys';

const ActivityNotFound: React.FC<{ activityId: number }> = ({ activityId }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const onSearch = () => navigate('/activity');
  const onCreate = () => navigate('/activity/create');

  return (
    <MainView className="activity-not-found-container">
      <Result
        status="404"
        title={intl.formatMessage(Locale.Text.Not_found)}
        subTitle={intl.formatMessage(Locale.Text.No_activity_found, { id: activityId })}
        extra={
          <Space>
            <Button onClick={onSearch}>
              {intl.formatMessage(Locale.Command.Search_activities)}
            </Button>
            <Button onClick={onCreate}>
              {intl.formatMessage(Locale.Command.Create_Activity)}
            </Button>
          </Space>
        }
      />
    </MainView>
  );
};

export default ActivityNotFound;
