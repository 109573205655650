import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  UpdatePersonDateOfBirthMutationMutationVariables
} from '../../../../gql/typings';
import { buildDateUpdateFieldConfig } from '../../../components/FieldConfig/buildDateUpdateFieldConfig';
import { DATE_FORMAT } from '../../../util/format';

const dateFormat = DATE_FORMAT;


export const personDateOfBirthFieldConfig = personFieldConfig(
  'dateOfBirth',
  [
    'id',
    'birth',
    'controlSetting.id',
    'controlSetting.createDcr',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Date_of_birth),
    additionalTableConfig: {
      width: 125
    },
    dcrInfo: record => ({
      entityType: EntityTypeEnum.PERSON,
      entityAffiliationId: record.id,
      field: FieldEnum.HCP_BIRTH_YEAR,
    }),
    render: (person) => person.birth ? dayjs(person.birth).format(dateFormat) : '',
    updateView: buildDateUpdateFieldConfig({
      mutation: UPDATE_PERSON_DATE_OF_BIRTH_MUTATION,
      valuePath: 'birth',
      buildVariables: (newDate, record) => ({
        persId: record.id,
        date: newDate,
      }) as UpdatePersonDateOfBirthMutationMutationVariables,
      additionalDateProps: {
        disabledDate: (current) => current && current > dayjs(dayjs().format(DATE_FORMAT), DATE_FORMAT)
      },
    }),
  })
);

const UPDATE_PERSON_DATE_OF_BIRTH_MUTATION = gql`
  mutation UpdatePersonDateOfBirthMutation($persId: Int!, $date: DateTime!) {
    updatePersonDateOfBirth(persId: $persId, date: $date) {
      id
      birth
    }
  }
`;
