import React, { memo, useEffect, useState } from 'react';
import { Col, message, Row, Skeleton } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Layout, Responsive, WidthProvider } from 'react-grid-layout';
import { isEqual, toNumber } from 'lodash';
import { pick as pickFp } from 'lodash/fp';
import { useNavigate } from 'react-router-dom';
import MainView, { MainViewPropsBreadcrumbItem } from '../../../components/View/MainView';
import { Locale } from '../../../../localization/LocalizationKeys';
import AdminGridConfigurationRight from './AdminGridRight/AdminGridConfigurationRight';
import DetailedGridLayout from './DetailedGridLayout';
import { dashboardGridViewDraggableCancelClassName } from '../../dashboards/DashboardGridView/DashboardGridView';
import { useLocalization } from '../../../util/useLocalization';
import DraggableTabs from '../../../components/DraggableTabs/DraggableTabs';
import { isActualNumber } from '../../../util/Util';
import {
  EntityTypeEnum,
  HCPMoreDataQueryQuery,
  HCPMoreDataQueryQueryVariables,
  UpdateEntityGridMutationMutation, UpdateEntityGridMutationMutationVariables
} from '../../../../gql/typings';

type EntityGridConfigurationProps = {
  entity: EntityTypeEnum;
};

const ResponsiveGridLayout = WidthProvider(Responsive);

const EntityGridConfigurationDisplay: React.FC<EntityGridConfigurationProps> = memo(({
  entity,
}) => {
  const localization = useLocalization();
  const navigate = useNavigate();
  const [activeTabKey, setActiveTabKey] = useState<string>();
  const {
    data,
    loading,
    refetch,
  } = useQuery<HCPMoreDataQueryQuery, HCPMoreDataQueryQueryVariables>(ENTITY_GRID_CONFIGURATION_DATA_QUERY, {
    variables: { entityType: entity },
  });
  const [layouts, setLayouts] = useState<Record<string, Layout[]>>({});
  const selectedCardState = useState<Layout>();
  const [
    updateEntityGrid,
  ] = useMutation<UpdateEntityGridMutationMutation, UpdateEntityGridMutationMutationVariables>(UpdateGrid);


  useEffect(() => {
    setLayouts(data?.entityTabs.nodes.reduce((acc, curr) => ({
      ...acc,
      [curr.code]: [
        ...(acc[curr.code] ?? []),
        ...curr.gridItems.nodes.map(card => ({
          i: `${card.id}`,
          x: card.x ?? 0,
          y: card.y ?? 0,
          h: card.height ?? 1,
          w: card.width ?? 12,
          minW: card.type.minWidth ?? 12,
          minH: card.type.minHeight ?? 1,
          static: false,
          moved: false,
        })),
      ],
    }), {} as Record<string, Layout[]>) ?? {});
    if (!activeTabKey) setActiveTabKey(data?.entityTabs?.nodes?.[0]?.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setLayouts]);

  const onSaveUpdates = (l: Layout[]) => {
    if (loading || !activeTabKey || !layouts || !layouts[activeTabKey]) return;
    const fields = ['i', 'h', 'w', 'x', 'y'];
    const newValues = l.map(pickFp(fields));
    const oldValues = layouts[activeTabKey]!.map(pickFp(fields));
    if (isEqual(oldValues, newValues)) return;
    // While dragging, the ID will be some string
    if (l.find(item => !isActualNumber(item.i))) return;
    return updateEntityGrid({
      variables: { input: { gridItems: l } },
    }).finally(() => refetch());
  };

  const entityInfo: Record<string, MainViewPropsBreadcrumbItem> = {
    [EntityTypeEnum.PERSON]: {
      label: 'Person',
      onClick: () => navigate('/admin/entity/hcp'),
    },
    [EntityTypeEnum.SITE]: {
      label: 'Site',
      onClick: () => navigate('/admin/entity/hco'),
    },
    [EntityTypeEnum.AFFILIATION]: {
      label: 'Link',
      onClick: () => navigate('/admin/entity/link'),
    },
  };

  return (
    <MainView
      className="admin-container"
      title={localization.formatMessage(Locale.Text.Grid_Field_Configuration)}
      breadcrumbs={[
        {
          label: 'Admin Settings',
          onClick: () => navigate('/admin'),
        },
        {
          label: 'Entity',
          onClick: () => navigate('/admin/entity'),
        },
        entityInfo[entity]!,
        {
          label: 'Configure layout',
        },
      ]}
    >
      <Row className="admin-grid-configuration-container">
        <Col span={18}>
          <Skeleton active={loading} loading={loading}>
            <DraggableTabs
              type="editable-card"
              activeKey={activeTabKey}
              onChange={setActiveTabKey}
              onEdit={(targetKey, action) => {
                if (loading) return;
                if (action === 'add') {
                  message.info('Add');
                } else {
                  message.info(`Remove ${targetKey} ${layouts[targetKey as string]?.length ?? 0}`);
                }
              }}
              items={(data?.entityTabs.nodes ?? []).map(tab => (
                {
                  key: tab.code,
                  label: localization.formatMessageByStr(tab.headingKey),
                  children: (
                    <ResponsiveGridLayout
                      className="layout"
                      compactType="horizontal"
                      draggableCancel={`.${dashboardGridViewDraggableCancelClassName}`}
                      // @ts-ignore
                      layouts={{ lg: layouts[tab.code] ?? [] }}
                      margin={[9, 14]}
                      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xss: 0 }}
                      cols={{ lg: 12, md: 12, sm: 12, xs: 12, xss: 12 }}
                      // cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                      onLayoutChange={onSaveUpdates}
                      measureBeforeMount
                      isDraggable
                      isResizable
                    >
                      {layouts?.[tab.code]?.map(gridItem => (
                        <div
                          key={gridItem.i}
                          onClick={() => {
                            if (gridItem.i === selectedCardState?.[0]?.i) selectedCardState[1](undefined);
                            else selectedCardState[1](gridItem);
                          }}
                        >
                          <DetailedGridLayout
                            id={toNumber(gridItem.i)}
                            entityType={entity}
                            active={selectedCardState?.[0]?.i === gridItem.i}
                          />
                        </div>
                      ))}
                    </ResponsiveGridLayout>
                  )
                }
              ))}
            />
          </Skeleton>
        </Col>
        {activeTabKey && <AdminGridConfigurationRight
          entityType={entity}
          selectedCardState={selectedCardState}
          entityTabCode={activeTabKey}
        />}
      </Row>
    </MainView>
  );
}, isEqual);

const UpdateGrid = gql`
  mutation UpdateEntityGridMutation($input: GridInput!) {
    updateEntityGrid(input: $input)
  }
`;


export const ENTITY_GRID_CONFIGURATION_DATA_QUERY = gql`
  query HCPMoreDataQuery($entityType: EntityTypeEnum!) {
    entityTabs(criteria: { entityType: $entityType }) {
      hash
      nodes {
        code
        headingKey
        sort
        gridItems {
          hash
          nodes {
            x
            y
            width
            heading
            height
            id
            type {
              code
              headingKey
              minHeight
              minWidth
            }
          }
        }
      }
    }
  }
`;


export default EntityGridConfigurationDisplay;
