/* eslint-disable @typescript-eslint/ban-types,no-console */
import { ApolloClient } from '@apollo/client/core';
import { isNil } from 'lodash';
import { Locale } from '../../../localization/LocalizationKeys';
import { AnyValueType } from '../../util/StateArrayType';
import { DynamicCriteriaInput, DynamicCriteriaValueInput } from '../../../gql/typings';

export function serializeCriteriaInput(
  apollo: ApolloClient<object>,
  inputs?: DynamicCriteriaInput[]
): Record<string, AnyValueType> {
  if (!inputs) return {};
  return inputs.reduce((acc, curr) => {
    if (!curr.value) return acc;
    // @ts-ignore
    const criteria = apollo.cache.data.data[`Criteria:${curr.criteriaId}`];
    if (!criteria) {
      // TODO: Should be formatted via [IntlShape]
      console.error(Locale.Text.Couldnt_load_cached_criteria.id);
      return acc;
    }
    if (!curr.value.pathId) {
      return curr.value!.values!.reduce((a, c, index) => {
        const value = extractValue(c);
        if (!value) return a;
        return {
          ...a,
          [`${criteria.code}*${index + 1}`]: extractValue(c),
        };
      }, acc);
    }
    const value = extractValue(curr.value);
    if (isNil(value)) return acc;

    return {
      ...acc,
      [criteria.code]: extractValue(curr.value),
    };
  }, {});
}

function extractValue(input?: DynamicCriteriaValueInput | null): AnyValueType {
  if (!input) return;
  if (!isNil(input.value)) return input.value;
  if (!isNil(input.values)) return input.values.map(v => extractValue(v)) as AnyValueType;
}

