import { gql, useQuery } from '@apollo/client';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { camelCase, get, range, toUpper } from 'lodash';
import RemoveRecord from '../components/RemoveRecord';
import { SupportedEntitySearchTypes } from '../../search_old/types';
import { useTableColumns } from '../../../components/entitiesSearch/results/useTableColumns';
import EntityTable from '../../../components/entitiesSearch/results/EntityTable';
import { usePersonTableQuickActions } from '../../person/hooks/usePersonTableQuickActions';
import { EntityTypeEnum } from '../../../../gql/typings';

type ListDetailsRecordsTableProps = {
  listId: number;
  entityType: SupportedEntitySearchTypes;
};

export type ListDetailsRecordsTableRef = {
  refetch: () => void;
};

const ListDetailsRecordsTable = forwardRef<ListDetailsRecordsTableRef, ListDetailsRecordsTableProps>(({
  listId,
  entityType,
}, ref) => {
  const {
    tableSelection,
    entitiesSearchProps,
    HiddenRender,
  } = usePersonTableQuickActions(undefined, { listId });
  const { query, sorting, loading: fieldsLoading } = useTableColumns(entityType);

  const { data, loading, refetch } = useQuery(
    buildDataQuery(entityType, query), {
      skip: fieldsLoading,
      variables: {
        listId,
        ordering: sorting?.map(s => s.currentEnum),
        fetchSize: {
          limit: tableSelection.tableProps.perPageState[0],
          offset: (tableSelection.tableProps.pageState[0] - 1) * tableSelection.tableProps.perPageState[0],
        },
      },
    },
  );

  useImperativeHandle(ref, () => ({
    refetch,
  }), [refetch]);

  const isDynamic = data?.list?.listType.code === 'DYN';

  useEffect(() => {
    tableSelection.tableProps.onVisibleKeysChange?.(data?.list?.connection?.nodes?.map((k: { id: number }) => k.id) ?? []);
    if (tableSelection.tableProps.onDataChange && data) tableSelection.tableProps.onDataChange(data.list.connection);
  }, [data, entityType, tableSelection.tableProps]);

  let records = get(data, 'list.connection.nodes', []);
  const totalCount = get(data, 'list.connection.totalCount', 0);
  if (records.length === 0 && loading) {
    const amount = totalCount - (tableSelection.tableProps.pageState[0] * tableSelection.tableProps.perPageState[0]);
    if (amount > 0) {
      records = range(-amount).map(id => ({ id }));
    }
  }

  return (
    <div style={{ width: '100%' }} className="list-details-records-table">
      <HiddenRender />
      <EntityTable
        entityType={entityType}
        tableSelection={tableSelection}
        actionsToTake={entityType === 'PERSON' ? entitiesSearchProps.actions : undefined}
        data={{
          nodes: records,
          totalCount: data?.list?.connection?.totalCount,
          loading: loading || fieldsLoading,
        }}
        additionalColumns={[
          !isDynamic && !loading && {
            title: '',
            key: 'remove',
            render: (_: string, record: { id: number }) => (
              <RemoveRecord
                render={!isDynamic}
                listId={listId}
                recordId={record.id}
              />
            ),
          },
        ]}
      />
    </div>
  );
});

const buildDataQuery = (entityType: EntityTypeEnum, fields: string|null) => gql`
  query ListDetailsTableQuery($listId: Int!, $fetchSize: FetchSize, $ordering: [String!]) {
    list(id: $listId) {
      id
      entityTypeEnum
      listType { code }
      ${buildAffiliationQuery(entityType, fields)}
    }
  }
`;

// TODO: Nasty stuff. Try to figure out some better and typescript solution to this.
const buildAffiliationQuery = (entityType: EntityTypeEnum, fields: string|null) => `
  connection: affiliations(fetchSize: $fetchSize, ordering: $ordering) {
    ... on ${camelCase(entityType).replace(/^(.)/, toUpper)}Connection {
      hash
      totalCount
      ${fields ? `nodes {
        ${fields}
      }` : ''}
    }
  }
`;

export default ListDetailsRecordsTable;
