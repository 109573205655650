import React, { useState } from 'react';
import { Input, Modal } from 'antd';
import { gql, useLazyQuery } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import { Optional } from '../../../util/StateArrayType';
import { EntitiesSearch } from '../../../components/entitiesSearch';
import { EntityTypeEnum, SiteNameQueryQuery, SiteNameQueryQueryVariables } from '../../../../gql/typings';
import { useTableRowSelection } from '../../../components/Table/useTableRowSelection';

type SiteAssignFormInputProps = {
  disabled?: boolean;
  onChange?: (value: Optional<number>) => void;
  onValueSelect?: () => void;
};

const SiteAssignFormInput: React.FC<SiteAssignFormInputProps> = ({
  onValueSelect,
  onChange,
  disabled
}) => {
  const localization = useLocalization();
  const siteTableSelection = useTableRowSelection({ virtualSupport: false, type: 'radio' });
  const [visible, setVisible] = useState<boolean>(false);

  const [loadSiteName, { data }] = useLazyQuery<SiteNameQueryQuery, SiteNameQueryQueryVariables>(SITE_NAME_DATA_QUERY);

  const onSelect = () => {
    const siteId = siteTableSelection.selection.selected[0];
    if (siteId) {
      loadSiteName({
        variables: {
          siteId
        }
      });
      onChange?.(siteId);
      setVisible(false);
      if (onValueSelect) {
        onValueSelect();
      }
    }
  };

  return (
    <>
      <Input
        onClick={() => setVisible(true)}
        value={data?.site?.name}
        placeholder={localization.formatMessage(Locale.Command.Select_Site)}
        disabled={disabled}
        onChange={e => {
          if (e.target.value !== '') return;
          onChange?.(undefined);
        }}
      />
      <Modal
        width="clamp(580px, 85%, 1400px)"
        title={localization.formatMessage(Locale.Command.Select_Site)}
        open={visible}
        footer={false}
        onCancel={() => setVisible(false)}
        closeIcon
      >
        <EntitiesSearch
          globalSupport={false}
          entityType={EntityTypeEnum.SITE}
          tableSelection={siteTableSelection}
          quickActions={[{
            key: 'select',
            disabled: !siteTableSelection.selection.selected[0],
            label: localization.formatMessage(Locale.Command.Select),
            className: 'select-site-quick-action',
            onClick: onSelect,
          }]}
        />
      </Modal>
    </>
  );
};

const SITE_NAME_DATA_QUERY = gql`
  query SiteNameQuery($siteId: Int!) {
    site(siteId: $siteId) {
     name
    }
  }
`;

export default SiteAssignFormInput;
