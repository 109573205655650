import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';
import { ActivityDetailContext } from '../ActivityDetails/ActivityDetailsTypes';
import HeaderWithValue from '../../../components/DisplayFields/HeaderWithValue';
import { Locale } from '../../../../localization/LocalizationKeys';
import { ActivityTimeStampsQueryQuery, ActivityTimeStampsQueryQueryVariables } from '../../../../gql/typings';
import { DATE_FORMAT } from '../../../util/format';


const ActivityTimeStamps = () => {
  const intl = useIntl();
  const {
    activityId,
  } = useContext(ActivityDetailContext);
  const { data } = useQuery<ActivityTimeStampsQueryQuery, ActivityTimeStampsQueryQueryVariables>(DATA_QUERY, {
    skip: !activityId,
    variables: { activityId: activityId ?? -1 },
  });
  const created = data?.activity?.ct;
  const updated = data?.activity?.ut;

  const renderUpdate = created !== updated;
  const displayUpdate = ` , ${moment(updated).format(DATE_FORMAT)}`;
  const displayCreated = renderUpdate
    ? `${renderUpdate ? '' : 'Created:'} ${moment(created).format(DATE_FORMAT)}` : moment(created).format(DATE_FORMAT);

  if (!activityId) {
    return <></>;
  }

  return (
    <HeaderWithValue heading={renderUpdate ? intl.formatMessage(Locale.Attribute.TimestampsCreatedUpdated)
      : intl.formatMessage(Locale.Attribute.Created)}
    >
      <div className='advance-input-data-container'>
        <Tooltip
          title={`${displayCreated}${renderUpdate ? displayUpdate : ''}`}
          placement='bottom'
        >
          {`${displayCreated}${renderUpdate ? displayUpdate : ''}`}
        </Tooltip>
      </div>
    </HeaderWithValue>
  );
};

const DATA_QUERY = gql`
  query ActivityTimeStampsQuery($activityId: Int!) {
    activity(id: $activityId) {
      id
      ct
      ut
    }
  }
`;


ActivityTimeStamps.propTypes = {};


export default ActivityTimeStamps;

