import { theme } from 'antd';

const { useToken } = theme;

export const useColors = () => {
  const { token } = useToken();
  return {
    ...token,
    colorAction: '#f6933d'
  };
};
