/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import { ActivityDetailContext } from '../ActivityDetailsTypes';
import DetailsPageSection from '../DetailsPageSection';
import { Locale } from '../../../../../localization/LocalizationKeys';
import TimeSpan_Edit from './TimeSpanForm/TimeSpan_Edit';
import { ActivityDateDetailsQueryQuery, ActivityDateDetailsQueryQueryVariables } from '../../../../../gql/typings';
import { DATE_FORMAT } from '../../../../util/format';

const ActivityDetailsDateTimeSpan: React.FC = () => {
  const {
    activityId,
    dispatch,
    startDate,
    endDate,
  } = useContext(ActivityDetailContext);
  const {
    data,
  } = useQuery<ActivityDateDetailsQueryQuery, ActivityDateDetailsQueryQueryVariables>(DATA_QUERY, {
    variables: { activityId: activityId! },
    skip: !activityId
  });

  const editState = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (data?.activity) dispatch({
      type: 'updateDate',
      start: data.activity.startDate,
      end: data.activity.endDate,
    });
  }, [data]);

  useEffect(() => {
    editState[1](!activityId);
  }, [activityId]);

  const dateFormat = DATE_FORMAT;
  const hourFormat = 'HH:mm';

  const NonEditMode: React.FC = () => {
    if (startDate.isSame(endDate, 'day')) return (
      <div className="date-timespan-form-container activity-timespan">
        {startDate.format(dateFormat)}
        &nbsp;@&nbsp;
        {startDate.format(hourFormat)}
        &nbsp;-&nbsp;
        {endDate?.format(hourFormat)}
      </div>
    );
    return (
      <div>
        {intl.formatMessage(Locale.Command.Start)}: {startDate.format(dateFormat)}
        &nbsp;@&nbsp;
        {startDate.format(hourFormat)}
        <br />
        {intl.formatMessage(Locale.Command.End)}: {endDate.format(dateFormat)}
        &nbsp;@&nbsp;
        {endDate.format(hourFormat)}
      </div>
    );
  };

  return (
    <div className="advance-input-container">
      <DetailsPageSection heading={intl.formatMessage(Locale.Attribute.Date)}>
        <div
          className={`advance-input-label ${editState[0] ? '' : 'activity-display-value'}`}
          onClick={() => !editState[0] && editState[1](true)}
        >
          {editState[0]
            // eslint-disable-next-line react/jsx-pascal-case
            ? <TimeSpan_Edit onCancel={() => editState[1](false)} />
            : <NonEditMode />}
        </div>
      </DetailsPageSection>
    </div>
  );
};

const DATA_QUERY = gql`
  query ActivityDateDetailsQuery($activityId: Int!) {
    activity(id: $activityId) {
      id
      heading
      typeCode
      statusCode
      startDate
      endDate
    }
  }
`;

export default ActivityDetailsDateTimeSpan;

