import React from 'react';
import { useLocalization } from '../../../../util/useLocalization';
import UserAdminCard from './UserAdminCard';
import { AdminUserQueryQuery } from '../../../../../gql/typings';

type UserAdminCommunicationDetailsProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: AdminUserQueryQuery['user'];
  // eslint-disable-next-line react/no-unused-prop-types
  allowUpdate: boolean;
};

const UserAdminCommunicationDetails: React.FC<UserAdminCommunicationDetailsProps> = (props) => {
  const localization = useLocalization();

  return (
    <UserAdminCard title="Communication" icon="PHONE">
      {localization.formatMessage({
        id: 'hello.world',
        defaultMessage: 'Hello World',
      })}
    </UserAdminCard>
  );
};

export default UserAdminCommunicationDetails;
