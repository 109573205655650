import { useLazyQuery, gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Col, Row, Skeleton, Typography } from 'antd';
import { useNavigate } from 'react-router';
import Popup from '../../components/Popup/Popup';
import multiCherryPickConfirmBtnAction, { MultiCherryPickConfirm_DATA_QUERY } from '../person/MultiCherryPickConfirmBtnAction';
import Card from '../../components/ContactCard/Card';
import { StateArray } from '../../util/StateArrayType';
import { EntitiesSearch } from '../../components/entitiesSearch';
import { buildSelectionCriteria } from '../../components/Table/utils';
import {
  EntityTypeEnum,
  FindNewSiteQueryByApbIdQuery, FindNewSiteQueryByApbIdQueryVariables,
  MultiCherryPickDataQueryQuery,
  MultiCherryPickDataQueryQueryVariables
} from '../../../gql/typings';
import { useTableRowSelection } from '../../components/Table/useTableRowSelection';

const SiteCherryPickPersonsModal: React.FC<{
  visibleState: StateArray<boolean>;
  record: {
    name: string;
    apurebaseId?: number;
    countryCode: string;
    visitAddress?: {
      id: number;
      street?: string;
      postalCode?: string;
    };
  };
}> = ({
  visibleState: [visible, setVisible] = useState<boolean>(true),
  record,
}) => {
  const [
    loadCherryPickConf,
    { data, loading, called },
  ] = useLazyQuery<MultiCherryPickDataQueryQuery, MultiCherryPickDataQueryQueryVariables>(MultiCherryPickConfirm_DATA_QUERY);
  const [
    findSiteByApbId,
    { data: siteData },
  ] = useLazyQuery<FindNewSiteQueryByApbIdQuery, FindNewSiteQueryByApbIdQueryVariables>(NEW_SITE_QUERY);
  const navigate = useNavigate();
  const tableSelection = useTableRowSelection({ virtualSupport: false });

  useEffect(() => {
    if (visible && !called) loadCherryPickConf({ variables: { country: record.countryCode } });
  }, [loadCherryPickConf, called, record.countryCode, visible]);

  useEffect(() => {
    if (siteData && siteData.sites && siteData.sites.nodes.length === 1) {
      navigate(`/hco/${siteData.sites.nodes[0]!.id}`);
    }
  }, [navigate, siteData]);

  const cherryPickBtn = visible && multiCherryPickConfirmBtnAction(
    buildSelectionCriteria({ selected: tableSelection.selection.selected }),
    { onDone: () => findSiteByApbId({ variables: { apbId: record.apurebaseId ?? -1 } }), type: EntityTypeEnum.PERSON },
  );

  const element = visible && (
    <div>
      <Row gutter={24}>
        <Col>
          <Card
            title={record.name}
            description={`${record.visitAddress?.street}, ${record.visitAddress?.postalCode}`}
            icon="site"
            iconRounded
          />
        </Col>
        <Col>
          <Skeleton active={loading || !called} loading={loading || !called}>
            {data && data.cherryPickOverview && (
              <>
                <Typography.Paragraph>
                  To be able to cherry pick a HCO, you'll need to have at least one HCP with it.
                </Typography.Paragraph>
                <Typography.Paragraph>
                  You currently have&nbsp;
                  <Typography.Text strong>
                    {data.cherryPickOverview.amountAvailable} out of {data.cherryPickOverview.amount}</Typography.Text>&nbsp;
                  cherry picks left in
                  <Typography.Text strong>{record.countryCode}</Typography.Text>.
                </Typography.Paragraph>
              </>
            )}
          </Skeleton>
        </Col>
      </Row>
      <hr />
      <Row>
        <EntitiesSearch
          entityType={EntityTypeEnum.PERSON}
          urlSearchEnabled={false}
          openInNewTab={false}
          tableSelection={tableSelection}
          quickActions={cherryPickBtn ? [cherryPickBtn] : []}
          globalState={[true, () => {}]}
          defaultCriterias={[
            { criteriaCode: 'APB_SNAME', value: record.name, disabled: true },
          ]}
        />
      </Row>
    </div>
  );

  return (
    <div>
      <Popup
        title={<span>Cherry picking of <b>{record.name}</b></span>}
        visible={visible}
        onClose={() => setVisible(false)}
        size="70%"
        top={10}
        customFooter={<></>}
      >
        {element || 'Loading...'}
      </Popup>
    </div>
  );
};

const NEW_SITE_QUERY = gql`
  query FindNewSiteQueryByApbId($apbId: Int!) {
    sites(criteria: {aPureBaseId: $apbId}) {
      hash
      nodes { id }
    }
  }
`;

export default SiteCherryPickPersonsModal;
