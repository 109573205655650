import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { Checkbox, Drawer, List, Row } from 'antd';
import { find } from 'lodash';
import { useIntl } from 'react-intl';
import { DynamicCriteriaFactory } from '../../components/Form/Dynamic/dynamicCriteriaInputFactory';
import Button from '../../components/Input/Button';
import AddButton from '../../components/Form/AddButton';
import { Locale } from '../../../localization/LocalizationKeys';


const CriteriasChooserAddButton = ({
  drawerVisibleState: [drawerVisible, setDrawerVisible] = useState(false),
  criteriasState: [criterias, setCriterias] = useState([]),
  loading,
  criteriaGroups,
  reset = () => setDrawerVisible(false),
  customOperatorSupport = false,
}) => {
  const { formatMessage } = useIntl();
  const addCriteria = item => {
    const test = {
      criteria: item,
      /** @type dynamicCriteriaInputOptions */
      options: { customOperatorSupport },
    };
    if (item.inputPaths.nodes.length === 1 && item.inputPaths.nodes[0].type.type === 'switch') {
      // MARK: fixes #PUR-843
      test.options.initialValue = false;
    }
    setCriterias([
      ...criterias,
      test,
    ]);
  };
  const removeCriteria = item => {
    setCriterias(criterias.filter(c => c.criteria.id !== item.id));
  };

  const criteriasChooser = loading
    ? <span>Loading...</span>
    : (
      <div>
        <div className="criteria-group-container">
          <Row type="flex" justify="center">
            {criteriaGroups.map(group => (
              <div className="criteria-groups" key={`${group.id}-${group.entityId}`}>
                <h3>{group.heading}</h3>
                <div className="criteria-group">
                  <List
                    size="small"
                    dataSource={group.options.nodes}
                    renderItem={item => (
                      <List.Item key={item.id}>
                        <Checkbox
                          checked={!!find(criterias, ['criteria.id', item.id])}
                          onClick={e => e.target.checked ? addCriteria(item) : removeCriteria(item)}
                        >
                          {formatMessage(Locale.Attribute.Heading)}
                        </Checkbox>
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            ))}
          </Row>
        </div>
        <div className="criteria-grouping-button">
          <Button
            color="light"
            onClick={reset}
            label={formatMessage(Locale.Command.Clear_search)}
          />
          <Button
            onClick={() => setDrawerVisible(false)}
            label={formatMessage(Locale.Command.Add_Criteria)}
          />
        </div>
      </div>
    );

  return (
    <div>
      <Drawer
        title={formatMessage(Locale.Command.Add_Criteria)}
        placement="top"
        height={500}
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
      >
        {criteriasChooser}
      </Drawer>
      <AddButton
        label={formatMessage(Locale.Command.Add_Criteria)}
        onClick={() => setDrawerVisible(!drawerVisible)}
      />
    </div>
  );
};

CriteriasChooserAddButton.FRAGMENT = gql`
  fragment CriteriasGroupsFragment on CriteriaGroup {
    id: code
    code
    heading
    entityId
    options {
      hash
      nodes {
        ...DynamicCriteriaInput
      }
    }
  }
  ${DynamicCriteriaFactory.FRAGMENT}
`;

export default CriteriasChooserAddButton;
