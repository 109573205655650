import React from 'react';
import { gql } from '@apollo/client';
import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { AdminGridConfigurationItemProps } from '../AdminGridConfigurationRight';
import { siteAffiliationTabConfigs } from '../../../../site/components/SiteAffiliationContainer';
import { linkAffiliationTabConfigs } from '../../../../link/LinkAffiliationTabs';
import { RelationAffiliationTabConfig } from '../../../../../components/EntityTabs/RelationTabs/EntityRelationTabs';
import { personAffiliationTabConfigs } from '../../../../person/Components/PersonAffiliationsContainer';
import { useLocalization } from '../../../../../util/useLocalization';
import SortableElements from '../../../../../components/SortableElements/SortableElements';

import './_adminGridRightRelationsConfigurator.less';
import {
  AdminGridRightRelationsConfiguratorQueryDocument,
  EntityTypeEnum,
  useAdminGridRightRelationsConfiguratorQueryQuery,
  useUpdateGridRightRelationsSortingMutationMutation,
  useUpdateGridRightRelationStatusMutationMutation
} from '../../../../../../gql/typings';

type SupportedTypes = EntityTypeEnum.PERSON|EntityTypeEnum.SITE|EntityTypeEnum.AFFILIATION;

export const gridRelationsConfigurators: Record<SupportedTypes, Record<string, RelationAffiliationTabConfig<never>>> = {
  PERSON: personAffiliationTabConfigs,
  SITE: siteAffiliationTabConfigs,
  AFFILIATION: linkAffiliationTabConfigs,
};

type ElementType = { key: string } & RelationAffiliationTabConfig<never>;

const AdminGridRightRelationsConfigurator: React.FC<AdminGridConfigurationItemProps> = ({
  entityType,
  itemConfigurationId,
}) => {
  if (entityType !== EntityTypeEnum.PERSON && entityType !== EntityTypeEnum.SITE && entityType !== EntityTypeEnum.AFFILIATION) {
    return <span>{entityType} currently not supported!</span>;
  }
  const localization = useLocalization();
  const {
    data,
    loading: isLoading,
  } = useAdminGridRightRelationsConfiguratorQueryQuery({ variables: { itemConfigurationId } });
  const [
    updateSorting,
    { loading: isUpdatingSorting }
  ] = useUpdateGridRightRelationsSortingMutationMutation();
  const [
    updateStatus,
    { loading: isUpdatingStatus },
  ] = useUpdateGridRightRelationStatusMutationMutation();
  const loading = isLoading || isUpdatingStatus || isUpdatingSorting;

  const onSortUpdate = (rows: { key: string }[]) => updateSorting({
    variables: { itemConfigurationId, fieldKeys: rows.map(r => r.key) },
    refetchQueries: [AdminGridRightRelationsConfiguratorQueryDocument],
  });

  const onStatusChange = (fieldKey: string): SwitchChangeEventHandler => (enabled) => updateStatus({
    variables: { itemConfigurationId, fieldKey, enabled },
    refetchQueries: [AdminGridRightRelationsConfiguratorQueryDocument],
  });

  const configs = gridRelationsConfigurators[entityType]!;

  const nodes = data?.entityGridItemRelationFields.nodes ?? [];
  const ds: ElementType[] = Object.keys(configs).length === nodes.length
    ? nodes.map(({ relationTabKey }) => ({ key: relationTabKey, ...configs[relationTabKey]! }))
    : Object.keys(configs).map(key => ({ key, ...configs[key]! }));

  return (
    <div className="admin-grid-right-relations-configurator-container">
      <SortableElements<ElementType>
        loading={loading}
        standardItemDesign
        rowKey={item => item.key}
        cancelDraggableClassname={['left', 'right']}
        dataSource={ds}
        onUpdate={onSortUpdate}
        renderItem={item => (
          <div className="grid-item">
            <span className="left">
              {localization.formatMessage(item.title)}
            </span>
            <div className="right">
              <Switch
                onChange={onStatusChange(item.key)}
                disabled={loading}
                checked={!!(data?.entityGridItemRelationFields.nodes ?? [])
                  .filter(n => n.isActive && n.relationTabKey === item.key)[0]}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
};

gql`
  mutation UpdateGridRightRelationsSortingMutation(
    $itemConfigurationId: Int!,
    $fieldKeys: [String!]!
  ) {
    updateEntityGridItemRelationFieldUpdateSorting(
      entityGridItemConfigurationId: $itemConfigurationId,
      fieldKeys: $fieldKeys
    ) {
      id
      sort
      relationTabKey
      isActive
    }
  }
`;
gql`
   mutation UpdateGridRightRelationStatusMutation(
     $itemConfigurationId: Int!,
     $fieldKey: String!,
     $enabled: Boolean!
   ) {
     updateEntityGridItemRelationFieldSwitchStatus(
       entityGridItemConfigurationId: $itemConfigurationId,
       fieldKey: $fieldKey,
       enabled: $enabled
     ) {
       id
       sort
       relationTabKey
       isActive
     }
   }
`;

gql`
  query AdminGridRightRelationsConfiguratorQuery($itemConfigurationId: ID!) {
    entityGridItemRelationFields(criteria: {
      entityGridItemConfigurationId: $itemConfigurationId,
      isActive: null
    }) {
      hash
      nodes {
        id
        sort
        relationTabKey
        isActive
      }
    }
  }
`;

export default AdminGridRightRelationsConfigurator;
