import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Form, Input, message, Modal } from 'antd';
import { StateArray } from '../../../util/StateArrayType';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import {
  CreateEntityGridItemConfigurationMutation,
  CreateEntityGridItemConfigurationMutationVariables,
  EntityGridItemTypeEnum
} from '../../../../gql/typings';

type CreateEntityGroupModalProps = {
  entityTabCode: string;
  modalModeState: StateArray<EntityGridItemTypeEnum|undefined>;
};

const CreateEntityGroupModal: React.FC<CreateEntityGroupModalProps> = ({
  entityTabCode,
  modalModeState: [modalVisible, setModalVisible],
}) => {
  const localization = useLocalization();
  const [form] = Form.useForm();
  const [
    createEntityGridItemConfiguration,
  ] = useMutation<CreateEntityGridItemConfigurationMutation, CreateEntityGridItemConfigurationMutationVariables>(
    AddNewGridItemGroup
  );


  const onSubmit = () => modalVisible && form.validateFields().then(values => {
    createEntityGridItemConfiguration({
      variables: {
        heading: values['group-heading'],
        description: values['group-description'],
        itemType: modalVisible,
        entityTabCode,
      },
      refetchQueries: ['HCPMoreDataQuery'],
    })
      .then(() => setModalVisible(undefined))
      .finally(() => message.success(localization.formatMessage(
        Locale.Text.Group_successfully_created,
        { groupName: values['group-heading'] },
      )));
  });

  return (
    <Modal
      title={localization.formatMessage(Locale.Text.Grid_Field_Configuration_Add_New_Group)}
      open={!!modalVisible}
      onOk={onSubmit}
      onCancel={() => setModalVisible(undefined)}
    >
      <Form form={form}>
        <Form.Item
          name="group-heading"
          rules={[
            {
              max: 20,
              message: localization.formatMessage(Locale.Text.Heading_must_be_beneath_20_characters)
            },
          ]}
        >
          <Input
            autoFocus
            placeholder={localization.formatMessage(Locale.Command.Enter_heading)}
          />
        </Form.Item>
        <Form.Item name="group-description">
          <Input
            placeholder={localization.formatMessage(Locale.Command.Enter_description)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const AddNewGridItemGroup = gql`
  mutation CreateEntityGridItemConfiguration(
    $entityTabCode: String!,
    $itemType: EntityGridItemTypeEnum!,
    $heading: String,
    $description: String,
  ) {
    createEntityGridItemConfiguration(
      entityTabCode: $entityTabCode,
      itemType: $itemType,
      heading: $heading,
      description: $description
    ) {
      id
    }
  }
`;


export default CreateEntityGroupModal;
