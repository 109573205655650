/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumb, Button, Card, message, Modal, Space } from 'antd';
import React, { CSSProperties, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { SettingOutlined } from '@ant-design/icons';
import { isString } from 'lodash';
import Icon from '../Icon/Icon';
import { Locale } from '../../../localization/LocalizationKeys';
import { FormattedMessage, NoValue, StateArray } from '../../util/StateArrayType';
import { dashboardGridViewDraggableCancelClassName } from '../../browse/dashboards/DashboardGridView/DashboardGridView';
import { RemoveDashboardItemMutationMutation, RemoveDashboardItemMutationMutationVariables } from '../../../gql/typings';


export type DashboardCardProps = {
  title: DashboardCardTitle;
  className?: string;
  id?: number;
  editMode?: boolean;
  editItemState?: StateArray<boolean>;
  style?: CSSProperties;
  fullscreen?: React.ReactNode;
  fullscreenState?: StateArray<boolean>;
};

export type DashboardCardTitle = string | (DashboardBreadcrumbItem|NoValue)[];

type DashboardBreadcrumbItem = {
  label: FormattedMessage;
  onClick?: () => void;
};

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  className,
  id,
  editMode,
  editItemState,
  style,
  fullscreen,
  fullscreenState: [showFullscreen, setShowFullscreen] = useState<boolean>(false),
  children,
}) => {
  const [
    removeDashboardItem,
  ] = useMutation<RemoveDashboardItemMutationMutation, RemoveDashboardItemMutationMutationVariables>(RemoveDashboardItem);
  const { formatMessage } = useIntl();
  const triggerDelete = () => removeDashboardItem({
    variables: {
      id: id!
    },
    refetchQueries: ['DashboardViewQuery', 'DashboardItemsQuery'],
  }).finally(() => message.success(formatMessage(Locale.Text.DashboardItem_successfully_deleted)));

  // TODO: Make sure that breadcrumb fontsize will be the same as when only using normal title!
  const renderTitle = (): React.ReactNode => {
    if (isString(title)) return title;
    const items = (title.filter(e => e)) as DashboardBreadcrumbItem[];
    return (
      <Breadcrumb separator=">">
        {items.map((item, index) => (
          <Breadcrumb.Item key={item.label.id} onClick={item.onClick}>
            <span className={`breadcrumb-label ${item.onClick && index !== items.length - 1 ? 'clickable' : ''}`}>
              {formatMessage(item.label)}
            </span>
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    );
  };

  return (
    <Card
      title={renderTitle()}
      className={`dashboard-card-container ${className}`}
      extra={
        <div style={{ height: 34 }}>
          {editMode && <Space size="small" className={dashboardGridViewDraggableCancelClassName}>
            <Button
              key="edit"
              onClick={() => editItemState?.[1](!editItemState[0])}
              hidden={!editItemState}
              icon={<SettingOutlined />}
            />
            <Button
              key="delete"
              onClick={triggerDelete}
              icon={<Icon iconType="DELETE" />}
            />
          </Space>}
          {!editMode && fullscreen && <Space size="small" className={dashboardGridViewDraggableCancelClassName}>
            <Button
              key="fullscreen"
              onClick={() => setShowFullscreen(true)}
              icon={<Icon iconType="FULLSCREEN" />}
            />
          </Space>}
        </div>
      }
    >
      <div style={style}>
        {children}
      </div>
      {fullscreen && (
        <Modal
          open={showFullscreen}
          onCancel={() => setShowFullscreen(false)}
          width="clamp(320px, 80%, 1200px)"
          footer={false}
        >
          {fullscreen}
        </Modal>
      )}
    </Card>
  );
};

const RemoveDashboardItem = gql`
  mutation RemoveDashboardItemMutation($id: Int!) {
    removeDashboardItem(id: $id) {
      id
    }
  }
`;

export default DashboardCard;

