import React, { CSSProperties } from 'react';

type ColoredShadowIconProps = {
  hex: string|undefined;
  onClick?: () => void;
  style?: CSSProperties;
};

const ColoredShadowIcon: React.FC<ColoredShadowIconProps> = ({ hex = '#a6a6a6', style = {}, onClick }) => (
  <div
    style={{
      display: 'inline-grid',
      justifyItems: 'center',
      alignItems: 'center',
      ...style,
    }}
    onClick={onClick}
  >
    <div style={{
      height: 16,
      width: 16,
      borderRadius: '50%',
      background: `${hex}4C`,
      gridRow: 1,
      gridColumn: 1,
    }}
    />
    <div
      style={{
        height: 10,
        width: 10,
        borderRadius: '50%',
        background: hex,
        gridRow: 1,
        gridColumn: 1,
      }}
    />
  </div>
);

export default ColoredShadowIcon;
