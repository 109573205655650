import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Table } from 'antd';
import ApbPagination from '../../../components/Table/ApbPagination';
import { defaultPerPage } from '../../../components/Table/utils';
import { buildDcrGroupTableFragment } from '../../dcr/DcrGroupTable';
import { useTableColumns } from '../../../components/entitiesSearch/results/useTableColumns';
import {
  DcrGroupContainsRecordDcr,
  EntityTypeEnum,
  RecordTabDcrGroupsQueryQuery,
  RecordTabDcrGroupsQueryQueryVariables
} from '../../../../gql/typings';
import { useBroadcastStorage } from '../../../util/useBroadcastStorage';

const PersonDcrGroupContainer: React.FC<{ record: DcrGroupContainsRecordDcr }> = ({ record }) => {
  const pageState = useState(1);
  const perPageState = useBroadcastStorage<number>('per-page', defaultPerPage);
  const { columns, query } = useTableColumns(EntityTypeEnum.DCR_GROUP);
  const { data, loading } = useQuery<RecordTabDcrGroupsQueryQuery, RecordTabDcrGroupsQueryQueryVariables>(
    buildDataQuery(query),
    {
      variables: {
        record,
        limit: perPageState[0],
        offset: (pageState[0] - 1) * perPageState[0],
      },
    },
  );

  return (
    <div>
      <Table
        loading={loading}
        rowKey="id"
        dataSource={data?.dcrGroups.nodes}
        columns={columns}
        pagination={false}
      />
      <ApbPagination
        pageState={pageState}
        perPageState={perPageState}
        totalCount={data?.dcrGroups.totalCount ?? 0}
      />
    </div>
  );
};

const buildDataQuery = (fields: string|null) => gql`
  query RecordTabDcrGroupsQuery($record: DcrGroupContainsRecordDcr!, $limit: Int!, $offset: Int!) {
    dcrGroups(criteria: { hasRecord: $record, fetchSize: { limit: $limit, offset: $offset } }) {
      hash
      totalCount
      nodes { ...DcrGroupTableFragment }
    }
  }
  ${buildDcrGroupTableFragment(fields)}
`;

export default PersonDcrGroupContainer;
