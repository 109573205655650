/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { Space, Typography } from 'antd';


export type PuffinType = 'crashLand'|'explaining'|'menu'|'waiting'|'waving';

const typesSrc: Record<PuffinType, () => Promise<any>> = {
  crashLand: () => import('../../../lottie/crashland.json'),
  explaining: () => import('../../../lottie/explaining.json'),
  menu: () => import('../../../lottie/menu.json'),
  waiting: () => import('../../../lottie/waiting.json'),
  waving: () => import('../../../lottie/waving.json'),
};

type PuffinProps = {
  type: PuffinType;
  loop?: boolean;
  style?: CSSProperties;
  title?: React.ReactNode;
  className?: string;
  message?: string | React.ReactNode;
  subTitle?: React.ReactNode;
};

const Puffin: React.FC<PuffinProps> = ({
  type,
  style,
  title,
  subTitle,
  children,
  className,
  message,
  loop = false,
}) => {
  const [animationData, setAnimationData] = useState<any>();
  const [description, setDescription] = useState(message || '');

  useEffect(() => {
    typesSrc[type]().then(data => {
      setAnimationData(data.default);
    });
  }, [type]);
  useEffect(() => {
    if (message) setDescription(message);
  }, [message]);

  return (
    <Space direction="vertical" align="center">
      {animationData && <Lottie
        loop={loop}
        animationData={animationData}
        renderer="svg"
        className={className}
        style={style}
      />}
      {title && (
        <Typography.Title level={2}>
          {title}
        </Typography.Title>
      )}
      {subTitle && (
        <Typography.Title level={4}>
          {subTitle}
        </Typography.Title>
      )}
      {description && description}
      {children}
    </Space>
  );
};

export default Puffin;
