import { Locale } from '../../../../localization/LocalizationKeys';
import { personSelectionRowFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { PersonSelectionRowCriteriaOrder } from '../../../../gql/typings';

export const personSelectionRowSelectionGroupDescriptionFieldConfig = personSelectionRowFieldConfig(
  'selectionGroupDescription',
  [
    'selectionRow.id',
    'selectionRow.group.id',
    'selectionRow.group.description',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Group_description),
    sorting: {
      ascend: PersonSelectionRowCriteriaOrder.SELECTION_GROUP_DESCRIPTION_ASC,
      descend: PersonSelectionRowCriteriaOrder.SELECTION_GROUP_DESCRIPTION_DESC,
    },
    render: record => record.selectionRow?.group.description ?? localization.formatMessage(Locale.General.Unknown),
  }),
);
