import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Col, Modal, Row, Select } from 'antd';
import { useLocalization } from '../../../../../util/useLocalization';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { ProductSegmentationCodeAddCard, ProductSegmentationCodeCard } from './ProductSegmentationCodeCard';
import { extractBooleanValue } from '../../../../../util/Util';
import {
  AddProductSegmentationTabMutation,
  AddProductSegmentationTabMutationVariables,
  PersonDetailSegmentationTabQueryQuery,
  PersonDetailSegmentationTabQueryQueryVariables,
  PersonProductSegmentationModalDataQuery,
  RemoveProductSegmentationTabMutation,
  RemoveProductSegmentationTabMutationVariables
} from '../../../../../../gql/typings';

type PersonProductSegmentationTabFieldProps = {
  personId: number;
  updateAccess: boolean;
};

const PersonProductSegmentationTabField: React.FC<PersonProductSegmentationTabFieldProps> = ({
  personId,
  updateAccess
}) => {
  const localization = useLocalization();
  const {
    data,
    refetch,
  } = useQuery<PersonDetailSegmentationTabQueryQuery, PersonDetailSegmentationTabQueryQueryVariables>(DATA_QUERY, {
    variables: { personId },
    fetchPolicy: 'cache-and-network',
  });
  const { data: selectOptionsData } = useQuery<PersonProductSegmentationModalDataQuery>(MODAL_DATA_QUERY);
  const [
    addMutation,
    { loading },
  ] = useMutation<AddProductSegmentationTabMutation, AddProductSegmentationTabMutationVariables>(ADD_MUTATION);
  const [
    removeMutation
  ] = useMutation<RemoveProductSegmentationTabMutation, RemoveProductSegmentationTabMutationVariables>(REMOVE_MUTATION);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [segmentationCodeId, setSegmentationCodeId] = useState<number|null>(null);
  const [productId, setProductId] = useState<number|null>(null);

  useEffect(() => {
    setProductId(null);
    setSegmentationCodeId(null);
  }, [isModalVisible]);

  if (!updateAccess && (data?.person?.productSegmentations.nodes ?? []).length === 0) return <></>;

  const remove = (productId: number) => removeMutation({
    variables: {
      personId,
      productId,
    },
  }).finally(() => refetch({ personId }));

  const create = () => {
    if (productId && segmentationCodeId) {
      setIsModalVisible(false);
      return addMutation({
        variables: {
          personId,
          productId,
          segmentationCode: segmentationCodeId,
        },
      }).finally(() => refetch({ personId }));
    }
  };

  const products = selectOptionsData?.products.nodes ?? [];
  const segmentations = selectOptionsData?.segmentationCodes.nodes ?? [];
  const sortList = products.filter(({ id }) => (data?.person?.productSegmentations.nodes ?? [])
    .map(({ product }) => product?.id)
    .indexOf(id));

  const renderAddBtn = sortList.length > 0;
  const isAddMoreAllowed = data?.person?.productSegmentations.totalCount === 0
    || extractBooleanValue(data?.allowMultiple?.value);

  if (!renderAddBtn && (data?.person?.productSegmentations.nodes ?? []).length === 0) return <></>;

  return (
    <>
      {(data?.person?.productSegmentations.nodes ?? []).map(node => (
        <Col key={node.id} span={8}>
          <ProductSegmentationCodeCard
            {...node}
            onRemove={updateAccess && node.product && (() => remove(node.product!.id))}
          />
        </Col>
      ))}
      {(updateAccess || renderAddBtn) && isAddMoreAllowed && (
        <Col span={8}>
          <ProductSegmentationCodeAddCard
            loading={loading}
            onClick={() => setIsModalVisible(true)}
          />
        </Col>
      )}
      <Modal
        title={localization.formatMessage(Locale.Text.Add_product_with_Segmentation_Code)}
        open={isModalVisible}
        onOk={create}
        onCancel={() => setIsModalVisible(false)}
        okText={localization.formatMessage(Locale.Command.Add)}
        cancelText={localization.formatMessage(Locale.Command.Cancel)}
      >
        <Row justify="center" align="top">
          <Col span={8}>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder={localization.formatMessage(Locale.Command.Add_Product)}
              optionFilterProp="children"
              onSelect={(id: number) => setProductId(id)}
              value={productId || undefined}
              filterOption={(input, option) => option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {sortList.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.heading}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row justify="center" align="top">
          <Col span={8}>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder={localization.formatMessage(Locale.Text.Add_segmentation_code_here)}
              optionFilterProp="children"
              onSelect={(id: number) => setSegmentationCodeId(id)}
              value={segmentationCodeId || undefined}
              filterOption={(input, option) => (
                option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              )}
            >
              {segmentations.map(segmentation => (
                <Select.Option key={segmentation.id} value={segmentation.id}>
                  {segmentation.heading}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const fragment = gql`
  fragment PersonDetailSegmentationFragment on PersonProductSegmentation {
    id
    segmentation: segmentationCode {
      id
      heading
    }
    product: product {
      id
      heading
      strengthUnitType {
        id
        abbreviation
        description
        name
      }
    }
  }
`;

const DATA_QUERY = gql`
  query PersonDetailSegmentationTabQuery($personId: Int!) {
    allowMultiple: instanceSetting(type: PRODUCT_SEGMENTATION_MULTIPLE) {
      code
      value
    }
    person(id: $personId) {
      id
      fullName
      productSegmentations {
        hash
        totalCount
        nodes {
          ...PersonDetailSegmentationFragment
        }
      }
    }
  }
  ${fragment}
`;


const REMOVE_MUTATION = gql`
  mutation RemoveProductSegmentationTab($personId: Int!, $productId: Int!) {
    removeProductSegmentation(personId: $personId, productId: $productId)
  }
`;

const ADD_MUTATION = gql`
  mutation AddProductSegmentationTab($personId: Int!, $productId: Int!, $segmentationCode: Int!) {
    addProductSegmentation(personId: $personId, productId: $productId, segmentationCodeId: $segmentationCode) {
      id
      person {
        id
        productSegmentations {
          hash
          nodes {
            ...PersonDetailSegmentationFragment
          }
        }
      }
    }
  }
  ${fragment}
`;

const MODAL_DATA_QUERY = gql`
  query PersonProductSegmentationModalData {
    products(criteria: {}) {
      hash
      nodes {
        id
        heading
        strength
      }
    }
    segmentationCodes {
      hash
      nodes {
        id
        code
        description
        heading
      }
    }
  }
`;

export default PersonProductSegmentationTabField;
