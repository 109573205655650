import { useCallback, useEffect, useState } from 'react';
import { isArray, isEqual, flatten } from 'lodash';
import { TransferProps } from 'antd/es/transfer';
import { useUpdate, useUpdateEffect } from 'ahooks';
import { gql, useApolloClient } from '@apollo/client';
import { EntityMergeHookValue, useEntityMerge } from '../../../useEntityMerge';
import {
  EntityTypeEnum,
  MergeCreateSubMergeMutation,
  MergeCreateSubMergeMutationVariables, MergeInactivateLinkMutation, MergeInactivateLinkMutationVariables,
  MergeItemSiteLinksFragment, MergeMoveLinkToWinnerSiteMutation, MergeMoveLinkToWinnerSiteMutationVariables
} from '../../../../../../../../../gql/typings';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useRandomKey } from '../../../../../../../../hooks/useRandomKey';
import { MergeRequestPrefixIconState } from '../../components/MergeRequestItemPrefixIcon';
import {
  AdminMergeItemAffiliationIsPrimary_EVENT_KEY,
  AdminMergeItemAffiliationIsPrimaryCustomEvent
} from '../../link/LinkIsPrimary/AdminMergeItemAffiliationIsPrimary';

export const useSiteLinksMerge = (props: AdminEntityMergeRequestItemProps) => {
  const info = props.item.type as MergeItemSiteLinksFragment;
  const emitterKey = useRandomKey();
  const update = useUpdate();
  const apolloClient = useApolloClient();
  const [targetKeys, setTargetKeys] = useState<string[]>(
    info.alreadyOnWinner.map(l => `${l.id}`)
  );
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [mergeHandle, setMergeHandle] = useState<EntityMergeHookValue>();
  const subMerges = info.linksToBeMerged.map(tbm => useEntityMerge(
    EntityTypeEnum.AFFILIATION,
    tbm.loser.id,
    tbm.winner.id,
  ));

  // Initial state is not skip
  useEffect(() => {
    props.emitter.emit({
      key: emitterKey,
      state: 'not_started',
      _source: 'useSiteLinksMerge:useEffect',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emitCurrentState = useCallback(() => {
    const toMergeHooks = subMerges.filter(sm => targetKeys.includes(`M${sm.into?.id}`));
    const subStates = flatten(
      toMergeHooks.map(sm => Object.values(sm.emitter.stateRef.current!))
    ).filter(s => s.state === 'not_started' || s.state === 'in_progress');

    const prevState = props._stateRef.current?.[emitterKey]?.state;
    const newState = subStates.length > 0 ? 'in_progress' : 'finished';

    props.emitter.emit({
      key: emitterKey,
      state: newState,
      _source: 'useSiteLinksMerge:emitCurrentState',
      onSave: () => {
        const linksToMove = info.linksToBeMoved.filter(tbm => targetKeys.includes(`${tbm.id}`));
        const linksToInactivate = info.linksToBeMoved.filter(tbm => !targetKeys.includes(`${tbm.id}`));

        const mergePromises = toMergeHooks.map(sm => (
          apolloClient.mutate<MergeCreateSubMergeMutation, MergeCreateSubMergeMutationVariables>({
            mutation: CREATE_MERGE,
            variables: {
              fromLinkId: sm.from!.id,
              toLinkId: sm.into!.id,
            },
          }).then(res => sm.doMerge(res.data!.createMergeRequest.id))
        ));

        const movePromises = linksToMove
          .map(ltm => apolloClient.mutate<MergeMoveLinkToWinnerSiteMutation, MergeMoveLinkToWinnerSiteMutationVariables>({
            mutation: MOVE_LINK,
            variables: {
              linkId: ltm.id,
              siteId: props.into.id,
            },
          }));

        const inactivatePromises = linksToInactivate
          .map(lti => apolloClient.mutate<MergeInactivateLinkMutation, MergeInactivateLinkMutationVariables>({
            mutation: INACTIVATE_LINK,
            variables: { linkId: lti.id },
          }));

        return Promise.all([
          ...mergePromises,
          ...movePromises,
          ...inactivatePromises,
        ]);
      },
    });
    if (prevState !== newState) update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subMerges, targetKeys]);


  subMerges.map(sm => {
    sm.emitter.$.useSubscription(e => {
      // We'll listen for all changes to any sub merges events and emit them all to the main site merge
      const state = targetKeys.includes(`M${sm.into?.id}`) ? e.state : 'skip';
      if (e._source === AdminMergeItemAffiliationIsPrimary_EVENT_KEY) {
        subMerges.map(sm => sm.emitter.customEmitter.emit({
          key: AdminMergeItemAffiliationIsPrimary_EVENT_KEY,
          autoResolveWithSiteId: props.into.id,
          disableForm: true,
        } as AdminMergeItemAffiliationIsPrimaryCustomEvent));
      }
      props.emitter.emit({
        ...e,
        key: `${sm.into?.id}_${e.key}`,
        state,
      });
      if (state !== 'skip') emitCurrentState();
    });
  });

  useUpdateEffect(() => {
    Object.keys(props._stateRef.current!).filter(k => {
      // Example for  [k] = 13501_randomKey
      // We'll only look at sub merge states, by checking if there is an underscore in the key
      if (k.includes('_')) {
        const kClean = k.split('_')[0]!;
        // if this effect is selected for merge, we'll load the correct state in
        // otherwise mark the effect as ignored
        if (targetKeys.includes(`M${kClean}`)) {
          const subState = subMerges.filter(sm => `${sm.into?.id}` === kClean)[0]!.emitter.stateRef.current![kClean]!;
          props.emitter.emit({
            ...subState,
            key: k,
          });
        } else {
          props.emitter.emit({
            key: k,
            state: 'skip',
            onSave: undefined,
            _source: 'useSiteLinksMerge:useUpdateEffect',
          });
        }
      }
    });
    emitCurrentState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetKeys]);

  /**
   * *********************
   *      Functions      *
   * *********************
   */


  const handleTargetSelectedKeys = (targetSelectedKeys: string[]|string) => {
    const keys = isArray(targetSelectedKeys) ? targetSelectedKeys : [targetSelectedKeys];
    if (keys.length === 1) {
      setMergeHandle(subMerges.filter(sm => `${sm.into?.id}` === keys[0])[0]);
    } else setMergeHandle(undefined);
    if (!isEqual(keys, selectedKeys)) setSelectedKeys(keys);
  };

  const onTransferChange: TransferProps<unknown>['onChange'] = (newKeys, direction, moveKeys) => {
    // MARK: Because when removing a merged record, we need to modify the `newKeys`
    // to only filter out the ID with a prefix of 'M'.
    if (direction === 'right') {
      setTargetKeys(newKeys);
    } else {
      setTargetKeys(newKeys.map(nk => moveKeys.filter(mk => `M${mk}` === nk)[0] ? nk.substring(1) : nk));
    }
  };

  return {
    subMerges,
    targetKeys,
    setTargetKeys,
    selectedKeys,
    setSelectedKeys,
    handleTargetSelectedKeys,
    mergeHandle,
    onTransferChange,
    state: (props._stateRef.current?.[emitterKey]?.state === 'finished' ? 'OK' : 'CONFLICT') as MergeRequestPrefixIconState,
  };
};


const MOVE_LINK = gql`
  mutation MergeMoveLinkToWinnerSite($linkId: ID!, $siteId: ID!) {
    newLinkFromExisting(linkId: $linkId, siteId: $siteId) {
      id
    }
  }
`;

const INACTIVATE_LINK = gql`
  mutation MergeInactivateLink($linkId: Int!) {
    setSitePersonActiveStatus(id: $linkId, isActive: false) {
      id
      isActive
    }
  }
`;

const CREATE_MERGE = gql`
  mutation MergeCreateSubMerge($fromLinkId: ID!, $toLinkId: ID!) {
    createMergeRequest(entityType: AFFILIATION, loserId: $fromLinkId, winnerId: $toLinkId) {
      id
    }
  }
`;
