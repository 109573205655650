import React from 'react';
import moment from 'moment';

const CalendarDateDisplay: React.FC<{
  startTime?: Date | null;
  endTime?: Date | null;
}> = ({
  startTime,
  endTime,
}) => {
  const start = moment(startTime).format('dddd, MMMM Do HH:mm');
  const end = moment(endTime).format('HH:mm');

  return <div>
    {start} - {end}
  </div>;
};


export default CalendarDateDisplay;
