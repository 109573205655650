import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useLocalization } from '../../../util/useLocalization';
import SortableElements from '../../../components/SortableElements/SortableElements';
import MenuGroupComponent from './MenuGroupComponent';
import MenuToggleStatus from './MenuToggleStatus';
import { Locale } from '../../../../localization/LocalizationKeys';
import Icon, { ITE } from '../../../components/Icon/Icon';
import { NAVIGATION_DATA_QUERY } from '../../../layout/Navigation/Navigation';
import {
  AdminMenuDataQueryQuery, AdminMenuItemFragmentFragment,
  UpdateMenuSortingMutationMutation,
  UpdateMenuSortingMutationMutationVariables
} from '../../../../gql/typings';


const MenuSettings: React.FC = () => {
  const localization = useLocalization();
  const { data, loading } = useQuery<AdminMenuDataQueryQuery>(ADMIN_MENU_DATA_QUERY);
  const [
    updateSorting,
    { loading: isUpdating },
  ] = useMutation<UpdateMenuSortingMutationMutation, UpdateMenuSortingMutationMutationVariables>(UPDATE_SORTING);

  const onUpdateSorting = (items: AdminMenuItemFragmentFragment[]) => updateSorting({
    variables: {
      menuGroup: items[0]!.menuGroup.enum,
      menuItemCodes: items.map(i => i.code),
    },
    refetchQueries: [
      { query: ADMIN_MENU_DATA_QUERY },
      { query: NAVIGATION_DATA_QUERY },
    ],
  });

  const Generate: React.FC<{ items: AdminMenuItemFragmentFragment[] }> = props => (
    <div>
      <SortableElements<AdminMenuItemFragmentFragment>
        loading={loading || isUpdating}
        dataSource={props.items}
        rowKey={item => item.code}
        standardItemDesign
        cancelDraggableClassname={['left', 'right']}
        onUpdate={onUpdateSorting}
        renderItem={menuItem => (
          <div className="menu-item">
            <div className="left">
              {menuItem.iconKey ? <Icon
                style={{ fontSize: 20 }}
                iconType={menuItem.iconKey as ITE}
                text={localization.formatMessageByStr(menuItem.headingKey)}
              /> : localization.formatMessageByStr(menuItem.headingKey)}
            </div>
            <div className="right">
              <MenuGroupComponent menuItem={menuItem} />
              <MenuToggleStatus menuItem={menuItem} />
            </div>
          </div>
        )}
      />
    </div>
  );

  return (
    <div className="menu-settings-container">
      <h3>{localization.formatMessage(Locale.General.Menu_items)}</h3>
      <Generate items={data?.menuItems.nodes ?? []} />
      <h3>{localization.formatMessage(Locale.General.Functions)}</h3>
      <Generate items={data?.functionMenuItems.nodes ?? []} />
    </div>
  );
};

const UPDATE_SORTING = gql`
  mutation UpdateMenuSortingMutation($menuGroup: MenuGroupEnum!, $menuItemCodes: [String!]!) {
    updateMenuItemsSorting(menuGroup: $menuGroup, menuItemCodes: $menuItemCodes) {
      code
      sort
    }
  }
`;

export const ADMIN_MENU_DATA_QUERY = gql`
  query AdminMenuDataQuery {
    menuItems(criteria: { group: MENU, isActive: null }) {
      hash
      nodes { ...AdminMenuItemFragment }
    }
    functionMenuItems: menuItems(criteria: { group: FUNC, isActive: null }) {
      hash
      nodes { ...AdminMenuItemFragment }
    }
  }
  fragment AdminMenuItemFragment on MenuItem {
    code
    headingKey
    tooltipKey
    iconKey
    url
    isActive
    menuGroup {
      code
      enum
    }
  }
`;

export default MenuSettings;
