import React, { useState } from 'react';
import { Button, TreeSelect, Typography } from 'antd';
import { isNull } from 'lodash';
import moment from 'moment';
import { entitiesTableConfigs, SupportedEntitySearchTypes, } from '../../browse/search_old/types';
import DraggableTable from '../DraggableTable/DraggableTable';
import { useLocalization } from '../../util/useLocalization';
import { isActualNumber } from '../../util/Util';
import { Locale } from '../../../localization/LocalizationKeys';
import { TableColumnConfigureSettingType, useTableColumnConfigureSettings } from './useTableColumnConfigureSettings';
import ConfigureTableColumnOverviewModal from './ConfigureTableColumnOverviewModal';

type UserTableColumnSettingsModalProps = {
  entityType: SupportedEntitySearchTypes;
  type: TableColumnConfigureSettingType;
};

const ConfigureTableColumnSettings: React.FC<UserTableColumnSettingsModalProps> = ({
  entityType,
  type,
}) => {
  const localization = useLocalization();
  const config = entitiesTableConfigs[entityType];
  const {
    loading,
    dynamicList,
    resetToDefaults,
    updateList,
    treeData,
    isNotUsingDefaults,
    updatePermission,
    valueFromKey,
  } = useTableColumnConfigureSettings(entityType, type);
  const [lastClicked, setLastClicked] = useState(moment());
  const overviewVisibleState = useState(false);

  const titleClick = () => {
    if (type !== TableColumnConfigureSettingType.SYSTEM) return;
    if (lastClicked.add(1, 'seconds').isAfter(moment())) {
      overviewVisibleState[1](true);
    }
    setLastClicked(moment());
  };

  return (
    <div>
      <Typography.Paragraph onClick={titleClick}>
        {localization.formatMessage(
          type === TableColumnConfigureSettingType.SYSTEM
            ? Locale.Text.Configure_default_table_columns_description
            : Locale.Text.Configure_user_table_columns_description,
          { type: localization.formatMessage(config.titleLabel) },
        )}
      </Typography.Paragraph>
      {!isNull(isNotUsingDefaults) && (isNotUsingDefaults ? (
        <Button onClick={resetToDefaults}>{localization.formatMessage(Locale.Command.Reset_to_system_defaults)}</Button>
      ) : <i>{localization.formatMessage(Locale.Text.You_are_using_the_system_defaults)}</i>)}
      <DraggableTable<{ id: string }>
        loading={loading}
        dynamicList={dynamicList}
        size="small"
        pagination={false}
        onListUpdate={updateList}
        draggable={updatePermission}
        columns={[
          {
            title: localization.formatMessage(Locale.Attribute.Column_key),
            hide: type === TableColumnConfigureSettingType.PERSONAL,
            width: '20%',
            render: (_, { id }) => <Typography.Text code>{id}</Typography.Text>,
          },
          {
            title: localization.formatMessage(Locale.Attribute.Column_heading),
            render: (_, record) => (
              updatePermission ? <TreeSelect
                value={isActualNumber(record.id) && record.id == -1 ? undefined : record.id}
                dropdownMatchSelectWidth={false}
                placeholder={localization.formatMessage(Locale.Command.Please_select_column)}
                treeData={treeData(record)}
                showSearch
                onChange={e => {
                  const index = dynamicList.list.map(e => e.id).indexOf(record.id);
                  const newList = [...dynamicList.list];
                  newList.splice(index, 1, { id: e });
                  dynamicList.resetList(newList);
                  updateList(newList);
                }}
              /> : valueFromKey(record.id))
          },
          {
            width: '20%',
            hide: !updatePermission,
            render: (_, { id }) => (
              <Button
                danger
                type="primary"
                onClick={() => {
                  const newList = dynamicList.list.filter(i => i.id !== id);
                  updateList(newList);
                  dynamicList.resetList(newList);
                }}
              >
                {localization.formatMessage(Locale.Command.Remove)}
              </Button>
            )
          }
        ]}
        footer={updatePermission ? (() => (
          <Button
            type="primary"
            onClick={() => dynamicList.push({ id: '-1' })}
            disabled={dynamicList.list.map(l => l.id).indexOf('-1') >= 0}
          >
            {localization.formatMessage(Locale.Command.Add_new_column)}
          </Button>
        )) : undefined}
      />
      <ConfigureTableColumnOverviewModal visibleState={overviewVisibleState} config={config} />
    </div>
  );
};


export default ConfigureTableColumnSettings;
