import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Alert, Button, Empty, message, Modal, Popconfirm, Space, Table } from 'antd';
import { CheckOutlined, CloseOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { get, identity, isArray, keys, omit, toNumber } from 'lodash';
import { ColumnsType } from 'antd/lib/table/interface';
import { gql } from '@apollo/client/core';
import { diffs } from './utils';
import { useSaveProcessResult } from '../utils';
import { DownloadPersonByApbId_MUTATION } from '../../../queries/manualPersonActionQueries';
import { LinkUserWithExternalId_MUTATION } from '../../../queries/manualExternalUserMappingAction';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { ImportManualAcctionProps } from '../ImportManualProcessesTypes';
import { AnyValueType, Optional, RecursivePartial } from '../../../../../util/StateArrayType';
import ManualPersonSearchMatchingModal from './ManualPersonSearchMatchingModal';
import QuickActionButton from '../../../../../components/QuickActions/QuickActionButton';
import CreatePerson from '../../../../person/create/CreatePerson';
import { useLocalization } from '../../../../../util/useLocalization';
import { CreatePersonProvider } from '../../../../person/create/CreatePersonProvider';
import { CREATE_PERSON_MUTATION } from '../../../../person/create/CreatePersonView';
import {
  AddressTypeEnum,
  AddWorkplaceInput,
  ContactTypeEnum,
  CreatePersonMutationMutation,
  CreatePersonMutationMutationVariables,
  CurrentImportProjectStateQuery,
  DownloadPersonByApbIdMutation,
  DownloadPersonByApbIdMutationVariables,
  EntityTypeEnum,
  FieldEnum,
  LinkUserWithExternalIdMutation,
  LinkUserWithExternalIdMutationVariables,
  ManualPersonActionFragmentFragment,
  ManualPersonMatchingDataQuery,
  AddressInput,
  PersonInput,
  ProcessLineEffectActionEnum,
  SiteInput
} from '../../../../../../gql/typings';


type ColumnRecordType = Record<string, AnyValueType>;

const ManualPersonMatching: React.FC<ImportManualAcctionProps> = ({
  importProject: project,
  continueAutomatic,
}) => {
  const localization = useLocalization();
  const { saveProcessResult, skipProcessResult } = useSaveProcessResult();
  const [selectedKey, setSelected] = useState<string>();
  const [isCreatePersonVisible, setIsCreatePersonVisible] = useState(false);
  const visibleHCPSearchState = useState(false);
  const [, setIsFromGlobal] = useState(false);
  const { data } = useQuery<ManualPersonMatchingDataQuery>(DATA_QUERY);
  const [
    create,
    { loading },
  ] = useMutation<CreatePersonMutationMutation, CreatePersonMutationMutationVariables>(CREATE_PERSON_MUTATION);


  const manual = project?.manual as Extract<NonNullable<
  CurrentImportProjectStateQuery['importProject']>['manual'], { ['__typename']: 'ImportManualPersonStage' }
  > | null;
  const [
    downloadPerson,
    { loading: isDownloading },
  ] = useMutation<DownloadPersonByApbIdMutation, DownloadPersonByApbIdMutationVariables>(DownloadPersonByApbId_MUTATION);
  const [
    linkExternalId,
    { loading: isLinkingExternalId },
  ] = useMutation<LinkUserWithExternalIdMutation, LinkUserWithExternalIdMutationVariables>(LinkUserWithExternalId_MUTATION);

  const fieldMap: Record<string, string> = manual?.candidatesFor?.reduce((acc, f) => ({
    ...acc,
    [f.fieldName]: f.fieldNameKey,
  }), {}) ?? {};

  const matchingAgainst: ColumnRecordType = manual?.candidatesFor?.reduce((acc, f) => ({
    ...acc,
    [f.fieldName]: f.value,
  }), {}) ?? {};

  const candidates: ColumnRecordType[] = manual
    ?.candidates
    ?.map(c => c.fields.reduce((acc, field) => ({
      ...acc,
      [field.fieldName]: field.value,
    }), {
      apurebaseId: c.apurebaseId,
      inLocalDb: c.inLocalDb,
      score: c.score,
    }))
    ?? [];

  const columns: ColumnsType<ColumnRecordType> = keys(matchingAgainst).map(k => ({
    title: fieldMap[k] || k,
    dataIndex: k,
  }));

  const selected = candidates.find(c => c.apurebaseId === selectedKey);

  const onSave = () => {
    downloadPerson({ variables: { apbId: toNumber(selectedKey) } })
      .then((res) => {
        if (get(res, 'errors', []).length > 0) return;
        const personId = res.data?.person[0]?.id ?? -1;
        return linkExternalId({
          variables: {
            input: {
              externalIdTypeCode: manual?.externalIdTypeCode,
              internalId: personId,
              externalId: manual?.externalIdValue ?? '-1',
            },
          },
        })
          .then(() => {
            continueAutomatic();
            return saveProcessResult({
              action: selected?.inLocalDb ? ProcessLineEffectActionEnum.UPDATE : ProcessLineEffectActionEnum.CREATE,
              entityType: EntityTypeEnum.PERSON,
              entityAffiliationId: personId,
              note: selected?.inLocalDb
                ? localization.formatMessage(Locale.Text.Linked_externalId_to_person, { value: manual?.externalIdValue })
                : localization.formatMessage(Locale.Text.Cherry_picked_person, { value: manual?.externalIdValue }),
            });
          });
      });
  };


  const saveBtn = (
    <Button
      type="primary"
      icon={<CheckOutlined />}
      disabled={!selectedKey}
      loading={isDownloading || isLinkingExternalId}
      className="action-button"
    >
      {localization.formatMessage(Locale.Command.Save_Match)}
    </Button>
  );

  return (
    <div className="manual-person-matching-container">
      {!manual?.fuzzySearchAvailable && (
        <Alert
          type="error"
          showIcon
          style={{ margin: '8px 0' }}
          description={localization.formatMessage(Locale.Text.Cannot_establish_fuzzy_search_connection)}
          message={localization.formatMessage(Locale.Text.Nothing_found)}
        />
      )}
      <h2>
        {localization.formatMessage(Locale.Attribute.Now_Matching)}
      </h2>
      <div className="now-matching-table">
        <Table
          pagination={false}
          dataSource={[matchingAgainst]}
          columns={columns}
          rowKey={e => {
            const idColumn = Object.keys(e).find(e => e.toLowerCase().includes('id'));
            return e[idColumn ?? 'key'] as string;
          }}
          style={{ overflow: 'auto hidden' }}
        />
      </div>
      {candidates.length > 0 && <>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 14 }}>
          <h2>
            {localization.formatMessage(Locale.Attribute.Select_match)}
          </h2>
          <div>
            <QuickActionButton
              label={localization.formatMessage(Locale.Command.Manual_search)}
              onClick={() => visibleHCPSearchState[1](true)}
              className="do-manual-search"
            />
            <QuickActionButton
              label={localization.formatMessage(Locale.Command.Create_Person)}
              onClick={() => setIsCreatePersonVisible(true)}
              className="create-new-record"
            />
            <QuickActionButton
              label={localization.formatMessage(Locale.Attribute.Skip)}
              confirm={{ label: localization.formatMessage(Locale.Text.Are_you_sure_to_skip_this_person) }}
              className="skip-match"
              onClick={() => {
                skipProcessResult({ entityType: EntityTypeEnum.IMPORT_PROCESS_LINE }).then(continueAutomatic);
                return message.info(localization.formatMessage(Locale.Text.Skipping_this_person));
              }}
            />
          </div>
        </div>
        <div className="select-match-table">
          <Table
            rowClassName={(record: { inLocalDb?: boolean }) => {
              if (!record.inLocalDb) {
                setIsFromGlobal(true);
                return 'test-stuff';
              }
              return '';
            }}
            style={{ overflow: 'auto hidden' }}
            rowSelection={{
              type: 'radio',
              selectedRowKeys: selectedKey ? [selectedKey] : [],
              // @ts-ignore
              onSelect: row => setSelected(row.apurebaseId),
            }}
            // @ts-ignore
            rowKey={e => (e.key || e.id || e.code || e.apurebaseId) as string}
            dataSource={candidates}
            pagination={false}
            columns={diffs(
              columns,
              matchingAgainst,
            )}
          />
        </div>
        <div className="centered-item">
          {!(selected && selected.inLocalDb)
            ? (
              <Popconfirm
                title={localization.formatMessage(Locale.Text.This_HCP_is_not_in_local_data)}
                disabled={!selectedKey}
                onConfirm={onSave}
              >
                {saveBtn}
              </Popconfirm>
            )
            : <span onClick={onSave} role="button" tabIndex={0}>{saveBtn}</span>}
        </div>
      </>}
      {candidates.length === 0 && (
        <Empty
          style={{ marginTop: 42 }}
          description="No candidate records were found by using fuzzy search on existing data"
        >
          <Space direction="horizontal" size="middle">
            <Popconfirm
              title={localization.formatMessage(Locale.Text.Are_you_sure_to_skip_this_person)}
              onConfirm={() => {
                skipProcessResult({ entityType: EntityTypeEnum.IMPORT_PROCESS_LINE }).then(continueAutomatic);
                return message.info(localization.formatMessage(Locale.Text.Skipping_this_person));
              }}
            >
              <Button
                danger
                type="primary"
                icon={<CloseOutlined />}
                className="skip-match"
                style={{ width: 240 }}
              >
                {localization.formatMessage(Locale.Attribute.Skip)}
              </Button>
            </Popconfirm>
            <Button
              icon={<SearchOutlined />}
              onClick={() => visibleHCPSearchState[1](true)}
              className="do-manual-search"
              style={{ width: 240 }}
            >
              {localization.formatMessage(Locale.Command.Manual_search)}
            </Button>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setIsCreatePersonVisible(true)}
              className="create-new-record"
              style={{ width: 240 }}
            >
              {localization.formatMessage(Locale.Command.Create_Person)}
            </Button>
          </Space>
        </Empty>
      )}
      {manual && project && (
        <ManualPersonSearchMatchingModal
          {...manual}
          importProject={project}
          continueAutomatic={continueAutomatic}
          visibleState={visibleHCPSearchState}
        />
      )}
      {isCreatePersonVisible && (
        <Modal
          open={isCreatePersonVisible}
          onCancel={() => setIsCreatePersonVisible(false)}
          width="clamp(580px, 85%, 1400px)"
          style={{ top: 42 }}
          title={localization.formatMessage(Locale.Command.Create_Person)}
          footer={false}
        >
          <CreatePersonProvider
            onCreate={input => {

              create({ variables: { input } }).then(res => {
                // TODO: What should happen if only a DCR was made and no Person record got created?

                // TODO: Something is causing the returned person to be incorrect when working fast
                //       (example selenium test [BestPractiseImportBrowserTest])
                const createdPerson = res.data?.createPerson.person;
                if (createdPerson && manual?.externalIdValue) {
                  linkExternalId({
                    variables: {
                      input: {
                        externalIdTypeCode: manual?.externalIdTypeCode,
                        internalId: createdPerson.id,
                        externalId: manual?.externalIdValue,
                      },
                    },
                  }).then(() => {
                    setIsCreatePersonVisible(false);
                    continueAutomatic();
                    return saveProcessResult({
                      action: ProcessLineEffectActionEnum.UPDATE,
                      entityType: EntityTypeEnum.PERSON,
                      entityAffiliationId: createdPerson.id,
                      note: localization.formatMessage(Locale.Text.Linked_externalId_to_person, {
                        value: manual?.externalIdValue,
                      }),
                    }).then(() => saveProcessResult({
                      action: ProcessLineEffectActionEnum.CREATE,
                      entityType: EntityTypeEnum.PERSON,
                      entityAffiliationId: createdPerson.id,
                      note: localization.formatMessage(Locale.Text.Created_new_person, { fullName: createdPerson.fullName }),
                    }));
                  });
                }
              });
            }}
          >
            <CreatePerson
              initialValues={manual?.candidatesFor ? prepareForCreate(data, manual.candidatesFor) : undefined}
              loading={loading}
              allowAddWorkplace
            />
          </CreatePersonProvider>
        </Modal>
      )}
    </div>
  );
};

function prepareForCreate(
  reference: Optional<ManualPersonMatchingDataQuery>,
  values: ManualPersonActionFragmentFragment['candidatesFor'],
): RecursivePartial<PersonInput> {
  const map: Record<string, string> = values.reduce((acc, curr) => ({
    ...acc,
    [curr.fieldName]: curr.value,
  }), {});

  const linkEmailCategoryCode = reference?.contactCategories?.nodes
    ?.filter(n => n.entityType === EntityTypeEnum.AFFILIATION && n.type === ContactTypeEnum.EMAIL)[0]?.code;
  const hcpPhoneCategoryCode = reference?.contactCategories?.nodes
    ?.filter(n => n.entityType === EntityTypeEnum.PERSON && n.type === ContactTypeEnum.PHONE)[0]?.code;
  const linkPhoneCategoryCode = reference?.contactCategories?.nodes
    ?.filter(n => n.entityType === EntityTypeEnum.AFFILIATION && n.type === ContactTypeEnum.PHONE)[0]?.code;
  const addressTypeCode = reference?.addressTypes?.nodes
    ?.filter(n => n.entityType === EntityTypeEnum.PERSON)[0]?.code;

  const getValue = (field: FieldEnum|FieldEnum[]) => {
    const fields = isArray(field) ? field : [field];
    const values = fields.map(f => (map[f]?.length ?? 0) > 0 ? map[f] : undefined).filter(e => e);
    return values[0];
  };

  const siteMailAddress: Partial<AddressInput> = {
    addressTypeCode: AddressTypeEnum.HCOMAIL,
    city: getValue(FieldEnum.HCO_MAIL_CITY),
    communeCode: getValue(FieldEnum.HCO_MAIL_COMMUNE),
    postalCode: getValue(FieldEnum.HCO_MAIL_CITY),
    street: getValue(FieldEnum.HCO_MAIL_STREET),
  };
  const siteVisitAddress: Partial<AddressInput> = {
    addressTypeCode: AddressTypeEnum.HCOVISIT,
    city: getValue(FieldEnum.HCO_VISIT_CITY),
    communeCode: getValue(FieldEnum.HCO_VISIT_COMMUNE),
    postalCode: getValue(FieldEnum.HCO_VISIT_CITY),
    street: getValue(FieldEnum.HCO_VISIT_STREET),
  };

  const siteAddress: Partial<AddressInput> = {
    street: getValue(FieldEnum.ADDRESS_HCO_STREET),
    postalCode: getValue(FieldEnum.ADDRESS_HCO_ZIP),
    city: getValue(FieldEnum.ADDRESS_HCO_CITY),
    communeCode: getValue(FieldEnum.ADDRESS_HCO_COMMUNE),
    // county: getValue(FieldEnum.ADDRESS_HCO_COUNTY),
    // country: getValue(FieldEnum.ADDRESS_HCO_COUNTRY),
    // latitude: getValue(FieldEnum.ADDRESS_HCO_LATITUDE),
    // longitude: getValue(FieldEnum.ADDRESS_HCO_LONGITUDE),
  };

  const siteAddresses = [
    Object.values(omit(siteMailAddress, 'addressTypeCode')).filter(identity).length > 0 ? siteMailAddress : undefined,
    Object.values(omit(siteVisitAddress, 'addressTypeCode')).filter(identity).length > 0 ? siteVisitAddress : undefined,
    Object.values(omit(siteAddress, 'addressTypeCode')).filter(identity).length > 0 ? siteAddress : undefined,
  ].filter(identity);

  let siteInput: RecursivePartial<SiteInput>|undefined = {
    name: getValue(FieldEnum.HCO_NAME),
    // TODO: Department??
    contacts: [
      ...(getValue(FieldEnum.CONTACT_HCO_EMAIL_VALUE) ? [{
        categoryCode: '5', // HCOEMAIL
        value: getValue(FieldEnum.CONTACT_HCO_EMAIL_VALUE),
      }] : []),
    ],
    country: getValue([FieldEnum.HCO_COUNTRY, FieldEnum.HCP_COUNTRY_CODE]),
    addresses: siteAddresses.length > 0 ? siteAddresses : undefined,
    webPage: getValue(FieldEnum.HCO_WEBPAGE),
    typeCode: getValue(FieldEnum.HCO_TYPE_CODE),
  };
  if (Object.values(siteInput).filter(identity).length === 0) siteInput = undefined;

  let workplace: RecursivePartial<AddWorkplaceInput>|undefined = {
    siteId: getValue(FieldEnum.HCO_ID) ? toNumber(getValue(FieldEnum.HCO_ID)) : undefined,
    siteInput,
    isPrimary: !!map[FieldEnum.LINK_IS_PRIMARY],
    positionCode: getValue(FieldEnum.HCP_WORK_POS_CODE),
    contacts: [
      ...(linkEmailCategoryCode ? [{
        categoryCode: linkEmailCategoryCode,
        value: getValue(FieldEnum.HCP_WORK_EMAIL),
      }] : []),
      ...(linkPhoneCategoryCode ? [{
        categoryCode: linkPhoneCategoryCode,
        value: getValue(FieldEnum.HCP_WORK_PHONE),
      }] : []),
    ],
  };

  if (Object.values(workplace).filter(identity).length === 0) workplace = undefined;

  const address: AddressInput|undefined = addressTypeCode ? {
    addressTypeCode,
    city: getValue(FieldEnum.ADDRESS_HCP_CITY),
    street: getValue(FieldEnum.ADDRESS_HCP_STREET),
    postalCode: getValue(FieldEnum.ADDRESS_HCP_ZIP),
    communeCode: getValue(FieldEnum.ADDRESS_HCP_COMMUNE),
  } : undefined;

  return {
    firstName: getValue(FieldEnum.HCP_FIRSTNAME),
    lastName: getValue(FieldEnum.HCP_LASTNAME),
    countryCode: getValue([FieldEnum.HCP_COUNTRY_CODE, FieldEnum.HCO_COUNTRY]),
    birth: getValue(FieldEnum.HCP_BIRTH_YEAR),
    examYear: getValue(FieldEnum.HCP_EXAMYEAR),
    persTypeCode: [getValue(FieldEnum.HCP_TYPE_CODE)],
    sex: getValue(FieldEnum.HCP_GENDER),
    title: getValue(FieldEnum.HCP_TITLE),
    contacts: hcpPhoneCategoryCode ? [{
      categoryCode: hcpPhoneCategoryCode,
      value: getValue(FieldEnum.CONTACT_HCP_PHONE_VALUE)
    }] : undefined,
    addresses: [address],
    workplace,
  };
}

const DATA_QUERY = gql`
  query ManualPersonMatchingData {
    contactCategories {
      hash
      nodes {
        code
        heading
        type: typeEnum
        entityType
      }
    }
    addressTypes {
      hash
      nodes {
        code
        headingKey
        entityType
      }
    }
  }
`;

export default ManualPersonMatching;
