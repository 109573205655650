import { Button, Col, Form, Input, message, Row, Select, Space, Tooltip } from 'antd';
import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { EyeInvisibleOutlined, EyeTwoTone, InfoCircleOutlined } from '@ant-design/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Rule } from 'rc-field-form/lib/interface';
import { useNavigate } from 'react-router';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import {
  createUserMutationMutation,
  createUserMutationMutationVariables,
  GetAllCountiresQueryQuery
} from '../../../../gql/typings';


export type CreateUser = {
  firstName: string;
  lastName: string;
  password: string;
  reEnterPassword: string;
  countries: string[];
  username: string;
  roleId: number;
};
const InputField: React.FC<{
  name: string;
  nameResource: { id: string };
  tooltipResource: { id: string };
  rules?: Rule[];
}> = ({
  children,
  name,
  rules,
  nameResource,
  tooltipResource,
}) => {
  const localization = useLocalization();
  return (
    <>
      <Col span={4} style={{ margin: 'auto' }}>
        {localization.formatMessage(nameResource)}
      </Col>
      <Col span={20}>
        <Space style={{ width: '100%' }}>
          <Form.Item name={name} rules={rules} noStyle>
            {/* @ts-ignore */}
            {React.cloneElement(children, {
              style: {
                width: '100%',
                minWidth: 240,
              },
            })}
          </Form.Item>
          <Tooltip title={localization.formatMessage(tooltipResource)}>
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      </Col>
    </>
  );
};


const CreateUser: React.FC = () => {
  const [form] = Form.useForm<CreateUser>();
  const localization = useLocalization();
  const [
    create,
  ] = useMutation<createUserMutationMutation, createUserMutationMutationVariables>(CREATE_MUTATION);

  const navigate = useNavigate();
  const { data, loading } = useQuery<GetAllCountiresQueryQuery>(Country_QUERY);


  const onFinish = () => {
    const {
      firstName,
      lastName,
      password,
      countries,
      username,
      reEnterPassword

    } = form.getFieldsValue();


    return create({
      variables: {
        input: {
          firstName,
          lastName,
          password,
          reEnterPassword,
          roleId: 1,
          username,
          countries
        }
      }
    }).then(() => message.success('User has successfully been created'))
      .finally(() => navigate('/admin/users/list'));

  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <div className="user-create-container">
        <h3>Create User</h3>
        <Row gutter={[0, 12]}>
          <InputField
            name="username"
            nameResource={Locale.Attribute.User_name}
            tooltipResource={Locale.Attribute.User_name}
            rules={[{ required: true, message: 'Password required' }]}
          >
            <Input placeholder={localization.formatMessage(Locale.Attribute.User_name)} />
          </InputField>
        </Row>
        <Row gutter={[0, 12]}>
          <InputField
            name="firstName"
            nameResource={Locale.Attribute.First_name}
            tooltipResource={Locale.Attribute.First_name}
            rules={[{ required: true, message: localization.formatMessage(Locale.Text.Name_required) }]}
          >
            <Input placeholder={localization.formatMessage(Locale.Attribute.First_name)} />
          </InputField>
        </Row>
        <Row gutter={[0, 12]}>
          <InputField
            name="lastName"
            nameResource={Locale.Attribute.Last_name}
            tooltipResource={Locale.Attribute.Last_name}
            rules={[{ required: true, message: localization.formatMessage(Locale.Text.Name_required) }]}
          >
            <Input placeholder={localization.formatMessage(Locale.Attribute.Last_name)} />
          </InputField>
        </Row>
        <Row gutter={[0, 12]}>
          <InputField
            name="password"
            nameResource={Locale.General.Password}
            tooltipResource={Locale.General.Password}
            rules={[{ required: true, message: 'Password required' }]}
          >
            <Input.Password
              placeholder="input password"
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </InputField>
        </Row>
        <Row gutter={[0, 12]}>
          <InputField
            name="reEnterPassword"
            nameResource={Locale.Command.Password_confirm}
            tooltipResource={Locale.General.Password}
            rules={[{ required: true, message: 'Password required' }]}
          >
            <Input.Password
              placeholder={localization.formatMessage(Locale.Command.Password_confirm)}
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </InputField>
        </Row>

        <Row gutter={[0, 12]}>
          <InputField
            name="countries"
            nameResource={Locale.Attribute.Countries}
            tooltipResource={Locale.Attribute.Countries}
            rules={[{ required: true, message: localization.formatMessage(Locale.Text.Name_required) }]}
          >
            <Select
              mode="multiple"
              placeholder={localization.formatMessage(Locale.Text.Select_Country)}
              loading={loading}
              style={{ width: 400 }}
              filterOption={false}
              allowClear
            >
              {((data && data.countries && data.countries.nodes) || []).map(country => (
                <Select.Option key={country.code} value={country.code}>
                  {country.label}
                </Select.Option>
              ))}
            </Select>
          </InputField>
        </Row>

        <Button
          type="primary"
          htmlType="submit"
          className="submit-admin-btn"

        >Create</Button>
      </div>
    </Form>
  );
};

const CREATE_MUTATION = gql`
  mutation createUserMutation($input: CreateUserInputdata!) {
    createUser(input: $input) {
      id
    }
  }
`;

const Country_QUERY = gql`
  query GetAllCountiresQuery {
    countries {
      hash
      nodes{
        code
        label
        labelGb
      }
    }
  }
`;


export default CreateUser;

