import { gql, useQuery } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { message, Select } from 'antd';
import { useIntl } from 'react-intl';
import Icon from '../../../components/Icon/Icon';
import { ActivityDetailContext } from '../ActivityDetails/ActivityDetailsTypes';
import HeaderWithValue from '../../../components/DisplayFields/HeaderWithValue';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  ActivityStatusEnum,
  ActivityStatusesQueryQuery,
  ActivityStatusesQueryQueryVariables,
  useUpdateActivityStatusMutation
} from '../../../../gql/typings';


const ActivityStatus: React.FC = () => {
  const intl = useIntl();
  const {
    activityId,
    status,
    dispatch,
  } = useContext(ActivityDetailContext);
  const { data } = useQuery<ActivityStatusesQueryQuery, ActivityStatusesQueryQueryVariables>(DATA_QUERY, {
    variables: { activityId: activityId ?? -1 },
  });
  const [updateActivityStatus] = useUpdateActivityStatusMutation();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (data?.activity) {
      dispatch({ type: 'updateStatus', value: data.activity.status.codeEnum });
    }
  }, [dispatch, data]);

  useEffect(() => {
    if (!activityId) dispatch({ type: 'updateStatus', value: ActivityStatusEnum.IN_PROGRESS });
  }, [dispatch, activityId]);

  const onSave = (e: ActivityStatusEnum) => {
    dispatch({ type: 'updateStatus', value: e });
    if (activityId) {
      updateActivityStatus({
        variables: {
          activityId,
          status: e,
        },
        refetchQueries: ['ViewerActivitiesCalendar'],
      }).then(res => {
        res.errors
          ? dispatch({ type: 'updateStatus', value: data?.activity?.status.codeEnum ?? ActivityStatusEnum.IN_PROGRESS })
          : messageApi.success(intl.formatMessage(Locale.Text.Status_has_been_updated));
      });
    }

  };

  const DisplayIcon: React.FC<{
    status: NonNullable<ActivityStatusesQueryQuery['activityStatuses']>['nodes']['0'];
  }> = (props) => (
    <span>
      <Icon
        iconType={props.status.codeEnum}
        text={intl.formatMessage({ id: props.status.headingKey })}
      />
    </span>
  );

  // TODO: Status should have a custom rule which makes sure that an activity can't be created as done in the future.
  return (
    <HeaderWithValue heading={intl.formatMessage(Locale.Attribute.Activity_status)}>
      {contextHolder}
      <Select
        size='large'
        style={{ width: '100%' }}
        placeholder={intl.formatMessage(Locale.Text.Select_Status)}
        onChange={onSave}
        value={status}
        filterOption={(input, option) => option?.props.children.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0}
      >
        {data?.activityStatuses.nodes.map(status => (
          <Select.Option key={status.code} value={status.codeEnum}>
            <DisplayIcon status={status} />
          </Select.Option>
        ))}
      </Select>
    </HeaderWithValue>
  );
};

gql`
  mutation UpdateActivityStatus($activityId: Int!, $status: ActivityStatusEnum!) {
    updateActivity(input: { activityId: $activityId, status: $status }) {
      id
      status { code }
      statusCode
      statusLabel
    }
  }
`;

const DATA_QUERY = gql`
  query ActivityStatusesQuery($activityId: Int!) {
    activity(id: $activityId) {
      id
      status {
        code
        codeEnum
        headingKey
      }
    }
    activityStatuses {
      hash
      nodes {
        code
        codeEnum
        headingKey
      }
    }
  }
`;

export default ActivityStatus;
