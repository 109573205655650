import React from 'react';
import { TableFieldUpdateViewProps } from '../../../search_old/types';
import CustomCheckboxInput from './CustomCheckboxInput';
import CustomRadioInput from './CustomRadioInput';

const CustomCheckInputMultiSupport: React.FC<TableFieldUpdateViewProps> = (props) => (
  <>
    {
      props.options.selectedOption?.allowMultiple
        ? <CustomCheckboxInput {...props} />
        : <CustomRadioInput {...props} />
    }
  </>
);
export default CustomCheckInputMultiSupport;
