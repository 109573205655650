import { DocumentNode, useQuery } from '@apollo/client';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { get, omit } from 'lodash';
import { QueryResult } from '@apollo/client/react/types/types';
import DisplayContent from '../Badge/DisplayContent';
import LinkBadge from '../Badge/LinkBandge';
import { AnyValueType, StateArray } from '../../util/StateArrayType';
import {
  PersonCriteria,
  SelectionCriteriaInput,
  SelectionCriteriaStyle,
  SiteCriteria,
  SitePersonCriteria
} from '../../../gql/typings';

export const defaultPerPage = 20;
export const defaultPageNumber = 1;

export const buildSelectionCriteria = ({
  selected = [],
  count = selected?.length,
  style = SelectionCriteriaStyle.STANDARD,
  criterias = [],
  excluded = [],
}: Partial<SelectionCriteriaInput>): SelectionCriteriaInput => ({
  selected,
  criterias,
  style,
  excluded,
  count,
});

export const LinkColumn: React.FC<{
  text: string;
  url: string;
  openInNewTab?: boolean;
}> = ({ text, url, openInNewTab = false }) => (
  <LinkBadge url={url} openInNewTab={openInNewTab}>
    <DisplayContent>{text}</DisplayContent>
  </LinkBadge>
);

export const criteriaTransformer = (criteria?: {
  page?: number;
  perPage?: number;
  ordering?: string[];
}) => {
  const criterias = omit(criteria, ['perPage', 'page', 'ordering']);

  const perPage = criteria?.perPage ?? 20;
  const page = criteria?.page ?? 1;

  return {
    criteria: {
      ...criterias,
      fetchSize: {
        limit: perPage,
        offset: (page - 1) * perPage,
      },
    },
  };
};

type UseConnectionQueryReturnType = QueryResult & {
  paginate: (page: number, perPage?: number) => void;
  pageState: StateArray<number>;
  perPageState: StateArray<number>;
};

/**
 * @param query - Query should always match the format of {connection: { nodes: [....] } }
 * @param {{ ..., perPage: number|null|?, page: number|null|? }} criteria
 * @param {object=} extraVariables — any extra variables outside [criteria] that will be added to the variables
 * @param {string=} nesting
 * @param {boolean} onlyFetchSizeCriteria - if set to true, this will only return [order] instead of [criteria]
 * @param {boolean} skip
 * @param pageState
 * @param perPageState
 * @param onVisibleKeysChange
 * @param onDataChange
 */
export const useConnectionQuery = (
  query: DocumentNode,
  criteria: (PersonCriteria | SiteCriteria | SitePersonCriteria) & { page?: number; perPage?: number } = {},
  extraVariables?: Record<string, AnyValueType>,
  nesting?: string|null,
  onlyFetchSizeCriteria?: boolean,
  skip?: boolean,
  pageState: StateArray<number> = useState(criteria.page || 1),
  perPageState: StateArray<number> = useState(criteria.perPage || 20),
): UseConnectionQueryReturnType => {
  const page = pageState[0];
  const perPage = perPageState[0];

  const buildCriterias = (p = pageState[0], pp = perPageState[0]) => {
    const criterias = criteriaTransformer({
      ordering: criteria?.ordering || undefined,
      page: p,
      perPage: pp,
    });
    return onlyFetchSizeCriteria
      ? { fetchSize: criterias.criteria.fetchSize ?? {} }
      : criterias;
  };


  const res = useQuery(query, {
    skip,
    variables: {
      ...extraVariables,
      ...buildCriterias(),
    },
  });

  useEffect(() => {
    if (!res.loading) {
      res.refetch();
    }
    // eslint-disable-next-line
  }, []);

  const paginate = (p: number, pp?: number) => res.refetch({
    ...buildCriterias(p, pp),
  });

  useEffect(() => {
    paginate(page, perPage);
  }, [page, perPage]);

  const result = res;
  if (!res.loading && nesting) {
    result.data = get(res, `data.${nesting}`);
  }

  // @ts-ignore
  return {
    ...result,
    paginate,
    pageState,
    perPageState,
  };
};

