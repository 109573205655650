import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Card, Divider } from 'antd';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import {
  CreateNestedMergeRequestMutation,
  CreateNestedMergeRequestMutationVariables,
  EntityTypeEnum,
  MergeItemPersonLinkFragment,
  PerformNestedMergeRequestMutation,
  PerformNestedMergeRequestMutationVariables
} from '../../../../../../../../../gql/typings';
import { useEntityMerge } from '../../../useEntityMerge';
import MergeRequestItemPrefixIcon, { MergeRequestPrefixIconState } from '../../components/MergeRequestItemPrefixIcon';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import MergeEntityLink from '../../components/MergeEntityLink';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { useRandomKey } from '../../../../../../../../hooks/useRandomKey';
import AdminMergeItemPersonLinkPrimaryStatusResolver from './AdminMergeItemPersonLinkPrimaryStatusResolver';


const AdminMergeItemPersonLink: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonLinkFragment;
  const localization = useLocalization();
  const key = useRandomKey();

  if (info.primaryConflict) return (
    <AdminMergeItemPersonLinkPrimaryStatusResolver
      emitterKey={key}
      emitter={props.emitter}
      fromPersonId={props.from.id}
      intoPersonId={props.into.id}
    />
  );

  if (!info.toLink) return (
    <AdminMergeItemBasicField {...props}>
      {localization.formatText(Locale.Text.Merge_request_person_link_description, {
        positionAtSite: <MergeEntityLink type={EntityTypeEnum.AFFILIATION} id={info.fromLink.id}>
          {info.fromLink.position?.displayLabel} @ {info.fromLink.site.name}
        </MergeEntityLink>,
      })}
    </AdminMergeItemBasicField>
  );


  const [create] = useMutation<CreateNestedMergeRequestMutation, CreateNestedMergeRequestMutationVariables>(CREATE_MUTATION);
  const [
    perform,
  ] = useMutation<PerformNestedMergeRequestMutation, PerformNestedMergeRequestMutationVariables>(PERFORM_MUTATION);
  const subMerge = useEntityMerge(EntityTypeEnum.AFFILIATION, info.fromLink.id, info.toLink.id);
  const [state, setState] = useState<MergeRequestPrefixIconState>('MOVED');

  subMerge.emitter.$.useSubscription(e => {
    props.emitter.emit(e);
    Promise.resolve().then(() => setState(subMerge.emitter.canSave() ? 'OK' : 'CONFLICT'));
  });

  const { loading } = subMerge;

  useEffect(() => {
    props.emitter.emit({
      key: `link${key}`,
      state: subMerge.loading ? 'in_progress' : (subMerge.emitter.canSave() ? 'finished' : 'not_started'),
      _source: 'AdminMergeItemPersonLink:useEffect',
      onSave: () => create({
        variables: { fromLinkId: info.fromLink.id, toLinkId: info.toLink!.id }
      }).then(res => perform({
        variables: { mergeId: res.data!.createMergeRequest.id },
      }))
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, state]);

  return (
    <Card title={<span><MergeRequestItemPrefixIcon state={state} />Merging two links</span>}>
      {subMerge.generateDescription()}
      <Divider />
      {subMerge.render()}
    </Card>
  );
};

const CREATE_MUTATION = gql`
  mutation CreateNestedMergeRequest($fromLinkId: ID!, $toLinkId: ID!) {
    createMergeRequest(entityType: AFFILIATION, loserId: $fromLinkId, winnerId: $toLinkId) {
      id
    }
  }
`;

const PERFORM_MUTATION = gql`
  mutation PerformNestedMergeRequest($mergeId: ID!) {
    performMerge(mergeRequestId: $mergeId) {
      entityMergeId
      hasConflict
      fromId
      intoId
    }
  }
`;

gql`
  fragment MergeItemPersonLink on PersonLink_EntityDataToBeTransferredType {
    code
    primaryConflict
    fromLink: from {
      id
      position {
        code
        type
        displayLabel
      }
      site {
        id
        name
      }
    }
    toLink: into { id }
  }
`;

export default AdminMergeItemPersonLink;
