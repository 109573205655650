import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { ProjectOutlined } from '@ant-design/icons';
import SingleLineSkeleton from '../Skeleton/SingleLineSkeleton';
import personIcon from '../../../img/icon-contact-person.svg';
import activityIcon from '../../../img/Activity_Icon.svg';
import siteIcon from '../../../img/icon-sites-grey.svg';
import pillGreyIcon from '../../../img/icon-pill-grey.svg';
import pillWhiteIcon from '../../../img/icon-pill-white.svg';
import position from '../../../img/position.svg';
import key from '../../../img/icon-key.svg';
import { Locale } from '../../../localization/LocalizationKeys';


const badgeIcons = {
  pill: pillWhiteIcon,
  key,
};

export type CardIconType = 'person'|'pill'|'activity'|'project'|'site'|'position'|'key';
export type CardBadgeType = 'pill'|'key'|'';

export type CardProps = {
  url?: string;
  title?: string|React.ReactNode;
  description?: string|null;
  iconBadge?: string;
  icon?: CardIconType;
  iconRounded?: boolean;
  cardBadge?: CardBadgeType;
  onRemove?: null|undefined|boolean|(() => void);
  onChange?: null|boolean|(() => void);
  editAccess?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
  size?: 'large'|'medium' | 'small' | 'xsmall';
  badgeToolTip?: string|React.ReactNode;
  collapsData?: React.ReactNode;
};

const Card: React.FC<CardProps> = ({
  url,
  title,
  description,
  iconBadge,
  icon,
  iconRounded,
  cardBadge,
  onRemove,
  onChange,
  editAccess,
  loading,
  children,
  size = 'medium',
  badgeToolTip,
  collapsData,
}) => {
  const { formatMessage } = useIntl();
  const icons = {
    person: <img src={personIcon} alt={formatMessage(Locale.Attribute.Person_HCP)} height={37} />,
    pill: <img src={pillGreyIcon} alt="Pill" height={37} />,
    site: <img src={siteIcon} alt={formatMessage(Locale.Attribute.Site)} height={37} />,
    activity: <img src={activityIcon} alt={formatMessage(Locale.General.Activity)} height={37} />,
    project: <ProjectOutlined style={{ fontSize: 37, paddingTop: 11 }} />,
    position: <img src={position} alt={formatMessage(Locale.Attribute.Position)} height={37} />,
  };
  const element = (
    <>
      {cardBadge && (
        <Tooltip title={badgeToolTip}>
          <div className="card-badge">
            <img
              src={badgeIcons[cardBadge]}
              alt={`${cardBadge} card badge`}
            />
          </div>
        </Tooltip>
      )}

      <div className={`icon ${iconRounded ? 'icon-rounded' : ''}`}>
        {/* @ts-ignore */}
        {icon && (icons[icon])}
        {iconBadge && <div className="icon-badge">{iconBadge}</div>}
      </div>

      <SingleLineSkeleton
        loading={loading}
        blink={false}
        width={125}
        centered
      >
        <h3>{title}</h3>
        {
          description && <div className="sub-title">
            {description || '\u00A0'}
          </div>
        }
      </SingleLineSkeleton>

    </>
  );

  return (
    <div className={`contact-card-container ${loading ? 'loading' : ''} ${size}`}>
      {url
        ? <NavLink to={url} className="content">{element}</NavLink>
        : <div className="content">
          {element}
          {children && <div className="content-container">
            {children}
            {collapsData}
          </div>}
        </div>}
      {(onRemove && editAccess) && (
        <span className="remove" onClick={onRemove instanceof Function ? onRemove : () => {}}>
          {formatMessage(Locale.Command.Remove)}
        </span>
      )}
      {(onChange && editAccess) && (
        <span className="remove" onClick={onChange instanceof Function ? onChange : () => {}}>
          {formatMessage(Locale.Command.Change)}
        </span>
      )}
    </div>
  );
};


export default Card;
