import React, { useState } from 'react';
import { get, size } from 'lodash';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import TimeSpan from '../Date/TimeSpan';
import Icon from '../Icon/Icon';
import { Locale } from '../../../localization/LocalizationKeys';
import { DATE_FORMAT } from '../../util/format';


interface AffiliationCardProps {
  title: string;
  startDate?: Date;
  endDate?: Date;
  createdDate: Date;
  type?: {
    color?: null|{
      code?: string;
    };
  };
  users: {
    id: number;
    fullname: string;
  }[];
  note: string;
  status?: string;
  url: React.ReactNode;
}

const AffiliationCard: React.FC<AffiliationCardProps> = (props) => {
  const { formatMessage } = useIntl();
  const mStartDateState = useState<Dayjs|null>(dayjs(props.startDate));
  const mEndDateState = useState<Dayjs|null>(dayjs(props.endDate));
  const mCreatedDate = useState(dayjs(props.createdDate)
    .format(DATE_FORMAT));

  const getUsers = <span className='comma-list'>
    {props.users.map(it => (
      <span className='comma' key={`${it.id}-fil`}>
        {it.fullname}
      </span>
    ))}
  </span>;

  const color = get(props.type, 'color.code', '');
  const typeName = get(props.type, 'heading', '');


  const statuses = (e: string|undefined) => {
    switch (e) {
      case 'DONE':
        return <span className="status icon-done">
          <Tooltip title={formatMessage(Locale.Attribute.Done)}>
            {/* @ts-ignore */}
            <Icon iconType="DONE" />
          </Tooltip>
        </span>;
      case 'OPEN':
        return <span className="status icon-open">
          <Tooltip title={formatMessage(Locale.Attribute.Open)}>
            {/* @ts-ignore */}
            <Icon style={{ height: '16px' }} iconType="OPEN" />
          </Tooltip>
        </span>;
      case 'IN_PROGRESS':
        return <span className="status icon-progress">
          <Tooltip title={formatMessage(Locale.General.In_progress)}>
            {/* @ts-ignore */}
            <Icon iconType="IN_PROGRESS" />
          </Tooltip></span>;
      case 'CANCELLED':
        return <span className="status icon-cancel">
          <Tooltip title={formatMessage(Locale.Command.Cancelled)}>
            {/* @ts-ignore */}
            <Icon iconType="CANCELLED" />
          </Tooltip></span>;
      case 'INACTIVE':
        return <span className="status icon-inactive">
          <Tooltip title={formatMessage(Locale.General.Inactive)}>
            {/* @ts-ignore */}
            <Icon iconType="INACTIVE" />
          </Tooltip>
        </span>;
      default:
        return <span className="status icon-open" />;
    }
  };
  const statusClassName = (e: string) => {
    switch (e) {
      case 'GRAY':
        return 'color grey';
      case 'GREEN':
        return 'color green';
      case 'LIGHTGREEN':
        return 'color light-green';
      case 'DARKGREEN':
        return 'color dark-green';
      case 'BLUE':
        return 'color blue';
      case 'RED':
        return 'color red';
      case 'ORANGE':
        return 'color orange';
      case 'YELLOW':
        return 'color yellow';
      default:
        return <span className="status icon-open" />;
    }
  };

  const length = size(props.title);
  const noteLength = size(props.note);

  return (
    <div className="affiliation-card-container" key={props.title}>
      <div className="affiliation-container">
        <div className="left">
          {statuses(props.status)}
        </div>
        <div className={`right ${color ? statusClassName(color) : 'no-color'}`}>
          <div className="card-information">
            {(props.startDate && props.endDate) && <div className="card-date">
              <TimeSpan
                startState={mStartDateState}
                endState={mEndDateState}
              />
            </div>}
            <div className={`card-title ${length > 35 ? 'small-font' : 'normal'}`}>
              {length > 75 ? `${props.title.substring(0, 75)}...` : props.title}
            </div>
            <div className="card-type">
              {typeName}
            </div>
          </div>
          <div className="card-context">
            <div className="card-context-header">
              <span className="date-created">
                {formatMessage(Locale.Attribute.Created_date, { created: mCreatedDate })}
              </span>
              <span className="created-by">
                {formatMessage(Locale.Attribute.Created_by_user, { user: getUsers })}
              </span>
              <div className="link">
                {props.url}
              </div>
            </div>
            <div className="card-content">
              <div className="note-heading">{formatMessage(Locale.Attribute.Note)}</div>
              <div className="note-content">
                {noteLength > 250
                  ? `${props.note.substring(0, 250)}...`
                  : noteLength > 0
                    ? props.note
                    : <span className="note-content-empty">
                      {formatMessage(Locale.Text.No_note_added)}
                    </span>}
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};


export default AffiliationCard;

