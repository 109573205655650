import React from 'react';
import { gql } from '@apollo/client';
import { Tooltip, Typography } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  FieldWorkingSpecialtiesQueryQuery,
  FieldWorkingSpecialtiesQueryQueryVariables, SitePersonCriteriaOrder,
  UpdateSitePersonWorkingSpecialtiesMutationMutationVariables
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';

export const linkWorkSpecialitiesFieldConfig = linkFieldConfig(
  'workSpc',
  [
    'id',
    'countryCode',
    'specialties.hash',
    'specialties.nodes.id',
    'specialties.nodes.specialty.code',
    'specialties.nodes.specialty.type',
    'specialties.nodes.specialty.label',
    'specialties.nodes.specialty.displayLabel',
    'controlSetting.id',
    'controlSetting.createDcr',
    'controlSetting.maintainerSourceCode',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Working_specialty),
    additionalTableConfig: {
      width: 150,
      ellipsis: {
        showTitle: false
      }
    },
    sorting: {
      ascend: [SitePersonCriteriaOrder.WORKING_SPC_ASC],
      descend: [SitePersonCriteriaOrder.WORKING_SPC_DESC],
    },
    render: (record, { isViewingFromPage }) => {
      const workingSpField = record.specialties.nodes.map(s => s.specialty.displayLabel).join(', ');
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={workingSpField}>{workingSpField}</Tooltip>
      </Typography.Paragraph>);
    },
    preserve: true,
    updateView: buildSelectUpdateFieldConfig({
      mode: 'multiple',
      mutation: UPDATE_LINK_WORKING_SPECIALTY_MUTATION,
      selectedKeys: record => record.specialties.nodes.map(n => n.specialty.code),
      modalHeading: localization.formatMessage(Locale.Command.Select_Working_Specialty),
      buildVariables: (selectedKeys, record) => ({
        linkId: record.id,
        specialties: selectedKeys,
      }) as UpdateSitePersonWorkingSpecialtiesMutationMutationVariables,
      fetchSuggestions: ({ client, record }) => (
        client.query<FieldWorkingSpecialtiesQueryQuery, FieldWorkingSpecialtiesQueryQueryVariables>({
          query: DATA_QUERY,
          variables: { countries: [record.countryCode] },
        }).then(res => res.data.availableSpecialties.nodes)
      )
    })
  })
);

const DATA_QUERY = gql`
  query FieldWorkingSpecialtiesQuery($countries: [CountryCode!]!) {
    availableSpecialties: workingSpecialties(criteria: { countries: $countries, fetchSize: { limit: 5000 } }) {
      hash
      nodes {
        code
        label: displayLabel
        type
      }
    }
  }
`;

const UPDATE_LINK_WORKING_SPECIALTY_MUTATION = gql`
  mutation UpdateSitePersonWorkingSpecialtiesMutation($linkId: Int!, $specialties: [String!]!) {
    updateSitePersonWorkingSpecialties(linkId: $linkId, workingSpecialtyCodes: $specialties) {
      id
      specialty {
        type
        code
        label
      }
    }
  }
`;
