import { gql } from '@apollo/client';
import { Locale } from '../../../localization/LocalizationKeys';
import { TableConfig } from '../search_old/types';
import { supportedPersonSelectionRowTableColumns } from './SupportedPersonSelectionRowTableColumns';

export const buildPersonSelectionRowTableFragment = (additionalQuery: string|null) => gql`
  fragment PersonSelectionRowTableBuiltFragment on PersonSelectionRow {
    id
    ${additionalQuery || ''}
  }
`;

export const buildPersonSelectionRowConnectionDataQuery = (additionalQuery: string|null) => gql`
  query PersonSelectionRowTableQuery($criteria: PersonSelectionRowCriteria) {
    connection: personSelectionRows(criteria: $criteria) {
      hash
      totalCount
      nodes { ...PersonSelectionRowTableBuiltFragment }
    }
  }
  ${buildPersonSelectionRowTableFragment(additionalQuery)}
`;

export const buildPersonSelectionRowRecordDataQuery = (additionalQuery: string|null) => gql`
  query PersonSelectionRowRecordQuery($recordId: Long!) {
    record: personSelectionRow(id: $recordId) {
      id
      ...PersonSelectionRowTableBuiltFragment
    }
  }
  ${buildPersonSelectionRowTableFragment(additionalQuery)}
`;

export const PersonSelectionRowTableConfig: TableConfig = {
  buildConnectionQuery: buildPersonSelectionRowConnectionDataQuery,
  buildRecordQuery: buildPersonSelectionRowRecordDataQuery,
  buildFragment: buildPersonSelectionRowTableFragment,
  titleLabel: Locale.Attribute.Selections,
  columnConfig: {
    fields: supportedPersonSelectionRowTableColumns,
  },
};
