import { DocumentNode, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Col, Input, message, Row } from 'antd';

export type MainViewInlineEditProps = {
  entityId: number;
  title: string;
  editAccess?: boolean;
  size?: 'medium' | 'large';
  valueTypeLabel?: string;

  /**
   * This should be a GraphQL mutation that takes 2 arguments [id, value].
   */
  gqlMutation: DocumentNode;
};

const MainViewInlineEdit: React.FC<MainViewInlineEditProps> = ({
  entityId,
  title,
  editAccess,
  gqlMutation,
  size = 'large',
  valueTypeLabel = 'value',
}) => {
  if (!entityId) throw Error('[MainViewInlineEditTitle]: `entityId` is required');
  if (!gqlMutation) throw Error('[MainViewInlineEditTitle]: `gqlMutation` is required');

  const [edit, setEdit] = useState(false);
  const [inputValue, setInputValue] = useState(title);
  const [updateMutation] = useMutation(gqlMutation);
  const [messageApi, contextHolder] = message.useMessage();


  const onSave = () => {
    setEdit(false);
    if (inputValue === title) return;
    updateMutation({
      variables: { id: entityId, value: inputValue },
    }).then(() => {
      const undoSave = () => {
        updateMutation({
          variables: {
            id: entityId,
            value: title,
          },
          // @ts-ignore
        }).then(() => close());
      };
      const close = messageApi.success((
        <span>
          Successfully updated {valueTypeLabel} from <b>{title}</b> to <b>{inputValue}</b>.
          <Button type="link" onClick={undoSave}>Undo</Button>
        </span>
      ), 5);
    });
  };

  const titleElement = size === 'large' ? <h2>{title}</h2> : <span>{title}</span>;

  return (
    <div className="main-view-inline-edit">
      { contextHolder }
      {edit
        ? <Row align="middle" justify="start">
          <Col>
            <Input
              defaultValue={title}
              autoFocus={edit}
              onBlur={onSave}
              onPressEnter={onSave}
              onChange={e => setInputValue(e.target.value)}
            />
          </Col>
        </Row>
        : <Row justify="start">
          {editAccess
            ? <Col className="main-content" onClick={() => setEdit(true)}>{titleElement}</Col>
            : <Col className="main-content">{titleElement}</Col>}
        </Row>}
    </div>
  );
};


export default MainViewInlineEdit;
