import { gql } from '@apollo/client';


export const PERSON_SITES_FRAGMENT = gql`
  fragment PersonSitesFragment on SitePerson {
    id
    person {
      id
      affiliations {
        hash
        totalCount
        nodes { id }
      }
    }
  }
`;
