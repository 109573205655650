import React from 'react';
import { EntityTabCardProps } from '../EntityTabTypes';
import PersonCardsContainer from '../../../browse/person/Components/PersonCardsContainer';
import SiteCardsContainer from '../../../browse/site/components/SiteCardsContainer';
import LinkCardsContainer from '../../../browse/link/LinkCardsContainer';
import { EntityTypeEnum } from '../../../../gql/typings';


export type EntityTabCardsProps = {
  recordId: number;
  configuration: EntityTabCardProps['configuration'];
};

const configs: Record<string, React.FC<EntityTabCardsProps>> = {
  [EntityTypeEnum.PERSON]: ({ recordId, configuration }) => (
    <PersonCardsContainer personId={recordId} entityGridItemConfiguration={configuration} />
  ),
  [EntityTypeEnum.SITE]: ({ recordId, configuration }) => (
    <SiteCardsContainer siteId={recordId} entityGridItemConfiguration={configuration} />
  ),
  [EntityTypeEnum.AFFILIATION]: ({ recordId, configuration }) => (
    <LinkCardsContainer linkId={recordId} entityGridItemConfiguration={configuration} />
  ),
};

const EntityTabCards: React.FC<EntityTabCardProps> = ({ recordId, entityType, configuration }) => {
  const ToDisplay = configs[entityType] ?? (() => (
    <span>{entityType} currently not supported</span>
  ));

  return (
    <div className="entity-tab-cards-container">
      <ToDisplay recordId={recordId} configuration={configuration} />
    </div>
  );
};

export default EntityTabCards;
