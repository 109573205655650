import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { personFieldConfig } from './utils/personFieldsUtils';
import { PersonCriteriaOrder } from '../../../../gql/typings';

export const personMainSiteNameFieldConfig = personFieldConfig(
  'mainSiteName',
  [
    'primaryOrFirstAffiliation.id',
    'primaryOrFirstAffiliation.site.id',
    'primaryOrFirstAffiliation.site.name',
  ],
  ({ localization, openInNewTab }) => ({
    sorting: {
      ascend: PersonCriteriaOrder.PRIMARY_SITE_NAME_ASC,
      descend: PersonCriteriaOrder.PRIMARY_SITE_NAME_DESC,
    },
    additionalTableConfig: {
      width: 150
    },
    title: localization.formatMessage(Locale.Attribute.Site_name),
    render: record => (
      <LinkBadge
        url={record.primaryOrFirstAffiliation ? `/hco/${record.primaryOrFirstAffiliation.site.id}` : undefined}
        label={record.primaryOrFirstAffiliation?.site?.name || ''}
        openInNewTab={openInNewTab}
      />
    ),
  })
);
