import React from 'react';
import { Col, Row } from 'antd';
import { useLocalization } from '../../../../util/useLocalization';

type UserAdminCardRowItemProps = {
  label: { id: string };
};

const UserAdminCardRowItem: React.FC<UserAdminCardRowItemProps> = ({ label, children }) => {
  const localization = useLocalization();

  return (
    <Row>
      <Col span={6}>
        {localization.formatMessage(label)}:
      </Col>
      <Col span={18}>
        {children}
      </Col>
    </Row>
  );
};

export default UserAdminCardRowItem;
