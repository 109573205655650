import { Col, Form, Radio, Row, Select, Space } from 'antd';
import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useLocalization } from '../../../util/useLocalization';
import { MassAssignFieldTypesProps } from '../MassAssignFieldTypes';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  MassAssignFieldAction,
  MassAssignMultiOptionsQueryQuery,
  MassAssignMultiOptionsQueryQueryVariables
} from '../../../../gql/typings';

const MassAssignMultiSelectType: React.FC<MassAssignFieldTypesProps> = ({
  heading,
  isHeadingKey,
  entityType,
  actionName,
  valueSelected: [valueSelected, setValueSelected],
  actionSelected: [actionSelected, setActionSelected],
}) => {
  const localization = useLocalization();
  const [search] = useState('');
  const { data } = useQuery<MassAssignMultiOptionsQueryQuery, MassAssignMultiOptionsQueryQueryVariables>(DATA_QUERY, {
    variables: { search, actionName },
  });

  const onChange = (it: string[]) => {
    if (it.length > 0) {
      return setValueSelected(true);
    }

    return setValueSelected(false);

  };

  return (
    <div className="mass-assign-container">
      <div className="mass-assign-type-description">
        <p>
          {localization.formatMessage(Locale.Text.Mass_assign_description_selectionType,
            { value: isHeadingKey ? localization.formatMessageByStr(heading) : heading })}
        </p>
      </div>
      <div className="dashboard-card-container">
        <div className="mass-assign-content-container">
          <Row>
            <Col span={12}>
              <h3>{localization.formatMessage(Locale.Text.Action)}</h3>
              <Form.Item
                name="actionName"
                style={{ display: 'none' }}
                initialValue={actionName}
              />
              <Form.Item
                name="entityType"
                style={{ display: 'none' }}
                initialValue={entityType}

              />
              <Form.Item
                name="actionType"
                initialValue={MassAssignFieldAction.SetValueToSelected}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio
                      onClick={() => setActionSelected(MassAssignFieldAction.SetValueToSelected)}
                      value={MassAssignFieldAction.SetValueToSelected}
                    >{localization.formatMessage(Locale.Text.Mass_assign_action_set_value_to_selected)}</Radio>
                    <Radio
                      onClick={() => setActionSelected(MassAssignFieldAction.RemoveSelectedFromCurrentState)}
                      value={MassAssignFieldAction.RemoveSelectedFromCurrentState}
                    >Remove Selected Value</Radio>
                    <Radio
                      onClick={() => setActionSelected(MassAssignFieldAction.BlankOutField)}
                      value={MassAssignFieldAction.BlankOutField}
                    >{localization.formatMessage(Locale.Text.Mass_assign_action_set_blank_out)}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>

            </Col>
            <Col span={12}>
              <h3>{localization.formatMessage(Locale.Text.Select_value)}</h3>
              <Form.Item
                name="singleSelectValue"
              >
                <Select
                  mode="multiple"
                  disabled={actionSelected === MassAssignFieldAction.BlankOutField}
                  placeholder={localization.formatMessage(Locale.Text.Select_value_placeholder)}
                  onChange={onChange}
                  style={{ width: '100%' }}
                >
                  {(data?.massAssignFieldOptions ?? []).map(option => (
                    <Select.Option key={option.key} value={option.key}>
                      {option.isHeadingKey ? localization.formatMessageByStr(option.heading) : option.heading}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

          </Row>
        </div>

      </div>


    </div>
  );
};

const DATA_QUERY = gql`
  query MassAssignMultiOptionsQuery($actionName: String!, $search: String!) {
    massAssignFieldOptions(actionName: $actionName, search: $search) {
      key
      heading
      isHeadingKey
    }
  }
`;


export default MassAssignMultiSelectType;
