import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { isUndefined } from 'lodash';
import { Tag } from 'antd';
import { useLocalization } from '../../../util/useLocalization';
import { useEntityTableData } from '../../../components/entitiesSearch/results/useEntityTableData';
import {
  EntityTypeEnum, LinkSelectionRowResultContainerQuery,
  LinkSelectionRowResultContainerQueryVariables, SelectionRowResultCriteria,
  SlicerSelectionType
} from '../../../../gql/typings';
import EntityTable from '../../../components/entitiesSearch/results/EntityTable';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useSystemPermission } from '../../../permission/useSystemPermission';

type LinkSelectionRowResultContainerProps = {
  linkId: number;
  apurebaseId: number;
};

const LinkSelectionRowResultContainer: React.FC<LinkSelectionRowResultContainerProps> = ({ apurebaseId }) => {
  const localization = useLocalization();
  const [showAll, setShowAll] = useState(false);
  const [selectedCustomerCodes, setSelectedCustomerCodes] = useState<string[]>();
  const hasGlobalSearchPermission = useSystemPermission('globalSearch');
  const entityTableProps = useEntityTableData<SelectionRowResultCriteria>(
    EntityTypeEnum.SELECTION_ROW_RESULT,
    {
      aPureBaseId: apurebaseId,
      selectionType: showAll ? null : SlicerSelectionType.DYNAMIC,
      imfCustomerCodes: selectedCustomerCodes,
    },
    hasGlobalSearchPermission
  );
  const {
    data,
    loading,
  } = useQuery<LinkSelectionRowResultContainerQuery, LinkSelectionRowResultContainerQueryVariables>(DATA_QUERY, {
    variables: {
      linkApureBaseID: apurebaseId,
      selectionType: showAll ? null : SlicerSelectionType.DYNAMIC,
    },
  });
  useEffect(() => {
    if (isUndefined(selectedCustomerCodes) && data?.selectionImfCustomers) {
      setSelectedCustomerCodes(data.selectionImfCustomers.nodes.map(n => n.code));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <EntityTable
        {...entityTableProps}
        loading={loading}
        actionsToTake={[
          {
            key: 'show-selection-types',
            label: showAll
              ? localization.formatMessage(Locale.Command.Only_show_dynamic_selection_types)
              : localization.formatMessage(Locale.Command.Show_all_selection_types),
            onClick: () => setShowAll(c => !c),
          }
        ]}
        quickActions={[
          ...(data?.selectionImfCustomers.nodes ?? []).map(customer => (
            <Tag.CheckableTag
              key={customer.code}
              checked={selectedCustomerCodes?.includes(customer.code) ?? false}
              onChange={checked => {
                if (checked) {
                  setSelectedCustomerCodes([...selectedCustomerCodes ?? [], customer.code]);
                } else {
                  setSelectedCustomerCodes(selectedCustomerCodes?.filter(c => c !== customer.code));
                }
              }}
            >
              {customer.callName ?? customer.name}
            </Tag.CheckableTag>
          )),
        ]}
      />
    </div>
  );
};

const DATA_QUERY = gql`
  query LinkSelectionRowResultContainer($linkApureBaseID: Int!, $selectionType: SlicerSelectionType) {
    selectionImfCustomers(criteria: {
      linkApbIdInSelection: [$linkApureBaseID],
      selectionType: $selectionType
    }) {
      hash
      nodes {
        code
        callName
        name
      }
    }
  }
`;

export default LinkSelectionRowResultContainer;
