import React from 'react';
import { gql } from '@apollo/client';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import {
  MergeItemPersonMailToPrivateFragment,
  useUpdatePersonMailToPrivateMutationMutation
} from '../../../../../../../../../gql/typings';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';

const AdminMergeItemPersonMailToPrivate: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonMailToPrivateFragment;
  const localization = useLocalization();
  const [update] = useUpdatePersonMailToPrivateMutationMutation();


  return (
    <AdminMergeItemBasicConflictField<boolean>
      {...props}
      fromValue={info.mailToPrivateFrom}
      toValue={info.mailToPrivateTo}
      updateMutation={nv => update({ variables: { persId: props.into.id, mailToPrivate: nv } })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_mail_to_private_mismatch_description)}
    </AdminMergeItemBasicConflictField>
  );
};


gql`
  fragment MergeItemPersonMailToPrivate on PersonMailToPrivate_EntityDataToBeTransferredType {
    code
    mailToPrivateFrom: from
    mailToPrivateTo: to
  }
`;

export default AdminMergeItemPersonMailToPrivate;
