import { gql, useQuery } from '@apollo/client';
import React, { CSSProperties } from 'react';
import { debounce } from 'lodash';
import { Select } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import Flag, { FlagCountry } from '../../Flag/Flag';
import { CountriesQueryQuery, CountriesQueryQueryVariables } from '../../../../gql/typings';

type CountryPickerProps = {
  showFlag?: boolean;
  style?: CSSProperties;
  value?: FlagCountry;
  onChange?: (v: FlagCountry) => void;
  disabled?: boolean;
};

const CountryPicker: React.FC<CountryPickerProps> = ({
  value,
  onChange,
  disabled,
  showFlag,
  style,
}) => {
  const localization = useLocalization();
  const { data, loading, refetch } = useQuery<CountriesQueryQuery, CountriesQueryQueryVariables>(DATA_QUERY);

  const searchDebounced = debounce(
    search => refetch({ search: `%${search}%` }),
    25,
  );

  return (
    <Select
      placeholder={localization.formatMessage(Locale.Command.Select_country)}
      loading={loading}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSelect={() => {
        searchDebounced('');
      }}
      value={value as FlagCountry}
      onChange={onChange}
      filterOption={false}
      showSearch
      onSearch={searchDebounced}
      allowClear
      disabled={disabled}
      className="country-selector"
      style={style}
    >
      {(data?.countries.nodes ?? []).map(country => (
        <Select.Option key={country.code} value={country.code}>
          {showFlag && <span><Flag country={country.code as FlagCountry} rectangle height={22} />&nbsp;</span>}
          {country.label}
        </Select.Option>
      ))}
    </Select>
  );
};

const DATA_QUERY = gql`
  query CountriesQuery($search: String) {
    countries(criteria: {
      label: $search,
      ordering: [ALPHABETICALLY],
      fetchSize: { limit: 1000 }
    }) {
      hash
      nodes {
        code
        id: code
        label
      }
    }
  }
`;

export default CountryPicker;
