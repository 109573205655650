import React from 'react';
import { gql } from '@apollo/client';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  PersonCriteriaOrder, UpdatePersonLastNameMutationMutationVariables,
} from '../../../../gql/typings';
import { buildFreeTextUpdateFieldConfig } from '../../../components/FieldConfig/buildFreeTextUpdateFieldConfig';

export const personLastNameFieldConfig = personFieldConfig(
  'lastName',
  [
    'id',
    'lastName',
    'isLocal',
    'controlSetting.id',
    'controlSetting.createDcr',
  ],
  ({ localization, openInNewTab }) => ({
    title: localization.formatMessage(Locale.Attribute.Last_name),
    sorting: {
      ascend: [PersonCriteriaOrder.LASTNAME_ASC],
      descend: [PersonCriteriaOrder.LASTNAME_DESC],
    },
    dcrInfo: person => ({
      entityType: EntityTypeEnum.PERSON,
      entityAffiliationId: person.id,
      field: FieldEnum.HCP_LASTNAME,
    }),
    additionalTableConfig: {
      width: 150
    },
    render: (record, { isViewingFromPage, isViewingFromEntity }) => {
      if (isViewingFromPage == TableFieldReturnedRecordPageType.DETAIL_PAGE
      && isViewingFromEntity === EntityTypeEnum.PERSON) return record.lastName;
      return <LinkBadge
        url={record.isLocal ? `/hcp/${record.id}` : undefined}
        label={record.lastName}
        openInNewTab={openInNewTab}
      />;
    },
    updateView: buildFreeTextUpdateFieldConfig({
      mutation: UPDATE_MUTATION,
      valuePath: 'lastName',
      buildVariables: (v, record) => ({
        lastName: v,
        persId: record.id,
      }) as UpdatePersonLastNameMutationMutationVariables,
    }),
  }),
);

const UPDATE_MUTATION = gql`
  mutation UpdatePersonLastNameMutation($persId: Int!, $lastName: String!) {
    updatePersonLastName(persId: $persId, lastName: $lastName) {
      id
      firstName
      lastName
      fullName
      title
    }
  }
`;
