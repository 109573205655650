import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { Input, notification, Radio, Space } from 'antd';
import Modal from 'antd/lib/modal';
import { EntitiesSearchQuickActionItem } from '../../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  DeleteReasonDcrEnum,
  RemoveSiteModalQueryQuery,
  RemoveSiteModalQueryQueryVariables,
  removeSiteMutationMutation, removeSiteMutationMutationVariables, SourceEnum
} from '../../../../gql/typings';
import { useDetailsContext } from '../../../components/DetailsView/useDetailsContext';

type ReturnProps = {
  hidden: React.ReactNode;
  action: EntitiesSearchQuickActionItem;
  loading: boolean;
};

export const useRemoveSiteAction = (siteId: number): ReturnProps => {
  const { formatMessage } = useIntl();

  const [visible, setVisible] = useState<boolean>(false);
  const [warningVisible, setWarningVisible] = useState<boolean>(false);
  const [reason, setReason] = useState<DeleteReasonDcrEnum>(DeleteReasonDcrEnum.HCOISCLOSEDDOWN);
  const [comment, setComment] = useState<string>('');

  const {
    data,
    loading,
    refetch,
  } = useQuery<RemoveSiteModalQueryQuery, RemoveSiteModalQueryQueryVariables>(DATA_QUERY, {
    variables: {
      siteId,
    },
  });
  const [
    removeSiteMutation,
    { loading: isRemoving },
  ] = useMutation<removeSiteMutationMutation, removeSiteMutationMutationVariables>(REMOVE_HCO_AFFILIATION);

  const { broadcastShouldRefetchData } = useDetailsContext(refetch);

  const affiations = data?.site?.affiliations;
  const count = affiations?.totalCount ? affiations?.totalCount : 0;

  const onClick = () => {
    setVisible(false);
    if (count === 0) {
      removeSiteMutation({
        variables: {
          siteId,
          comment,
          reason,
        },
        refetchQueries: ['SiteAffiliationScrollList', 'PersonAffiliationsTableQuery'],
      }).then(() => {
        broadcastShouldRefetchData();
        notification.open({
          message: formatMessage(Locale.Command.Remove_site),
          description: formatMessage(Locale.Text.Dcr_remove_succesion_text)
        });
      });
    } else {
      setWarningVisible(true);
    }
  };

  const submit = () => {
    setWarningVisible(false);
    removeSiteMutation({
      variables: {
        siteId,
        comment,
        reason,
      },
      refetchQueries: ['SiteAffiliationScrollList', 'PersonAffiliationsTableQuery'],
    }).then(() => {
      broadcastShouldRefetchData();
      notification.open({
        message: formatMessage(Locale.Command.Remove_site),
        description: formatMessage(Locale.Text.Dcr_remove_succesion_text)
      });
    });
  };

  return {
    loading,
    action: {
      key: 'remove_site',
      onClick: () => setVisible(true),
      label: formatMessage(Locale.Command.Remove_site),
      hide: data?.site?.maintainerSource.code === SourceEnum.LOCAL,
    },
    hidden: (
      <>
        <Modal
          title={formatMessage(Locale.Command.Remove_site)}
          open={visible}
          onOk={onClick}
          okButtonProps={{ loading: loading || isRemoving }}
          okText={formatMessage(Locale.Command.Submit)}
          cancelText={formatMessage(Locale.Command.Cancel)}
          onCancel={() => setVisible(false)}
        >
          <p> {formatMessage(Locale.Text.HCO_remove_site_description_text)}</p>
          <Radio.Group value={reason} onChange={(e) => setReason(e.target.value)}>
            <Space direction="vertical">
              <Radio value={DeleteReasonDcrEnum.HCOISCLOSEDDOWN}>{formatMessage(Locale.Text.HCO_is_closed_down)}</Radio>
              <Radio value={DeleteReasonDcrEnum.OTHER}>
                {formatMessage(Locale.Text.Other)}
                {reason === DeleteReasonDcrEnum.OTHER
                  ? <Input autoFocus onChange={(e) => setComment(e.target.value)} style={{ marginTop: 5 }} /> : null}
              </Radio>
            </Space>
          </Radio.Group>
        </Modal>

        <Modal
          title={formatMessage(Locale.Command.Remove_site)}
          open={warningVisible}
          okText={formatMessage(Locale.Command.Submit)}
          cancelText={formatMessage(Locale.Command.Cancel)}
          onOk={submit}
          onCancel={() => setWarningVisible(false)}
        >
          <p>{formatMessage(Locale.Text.HCO_remove_site_affiliated_data)}</p>

          <b>Affiliated HCPs</b>

          <ul>
            {data?.site?.affiliations.nodes.map(it => <li key={it.id}>{it.person.fullName} ({it.person.title})</li>)}
          </ul>


        </Modal>
      </>
    )
  };
};

const DATA_QUERY = gql`
  query RemoveSiteModalQuery($siteId: Int!) {
    site(siteId: $siteId){
      id
      name
      maintainerSource{
        code
        heading
      }
      affiliations{
        totalCount
        nodes{
          id
          person{
            id
            fullName
            title
          }
        }
      }
    }
  }
`;

const REMOVE_HCO_AFFILIATION = gql`
  mutation removeSiteMutation($siteId: Int!, $comment: String!, $reason: DeleteReasonDcrEnum!) {
    removeSite(siteId: $siteId, comment: $comment, reason: $reason)
  }
`;
