import React, { useContext } from 'react';
import { get } from 'lodash';
import EntitiesSearchContext from '../EntitiesSearchContext';
import ApbPagination from '../../Table/ApbPagination';

const EntitiesSearchResultPagination: React.FC = () => {
  const {
    tableSelection,
    data: {
      data,
    },
  } = useContext(EntitiesSearchContext);

  return (
    <ApbPagination
      perPageState={tableSelection.tableProps.perPageState}
      pageState={tableSelection.tableProps.pageState}
      totalCount={get(data, 'connection.totalCount')}
    />
  );
};

export default EntitiesSearchResultPagination;
