import React from 'react';
import { gql } from '@apollo/client';
import { Tooltip, Typography } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  SiteCriteriaOrder,
  UpdateSiteNameMutationMutationVariables
} from '../../../../gql/typings';
import { buildFreeTextUpdateFieldConfig } from '../../../components/FieldConfig/buildFreeTextUpdateFieldConfig';

export const siteNameFieldConfig = siteFieldConfig(
  'name',
  [
    'id',
    'name',
    'isLocal',
    'controlSetting.id',
    'controlSetting.entityTypeId',
    'controlSetting.maintainerSourceCode',
    'controlSetting.createDcr',
  ],
  ({ localization, openInNewTab }) => ({
    sorting: {
      ascend: SiteCriteriaOrder.NAME_ASC,
      descend: SiteCriteriaOrder.NAME_DESC,
    },
    title: localization.formatMessage(Locale.Attribute.Site_name),
    additionalTableConfig: {
      width: 150
    },
    dcrInfo: (site) => ({
      entityType: EntityTypeEnum.SITE,
      entityAffiliationId: site.id,
      field: FieldEnum.HCO_NAME,
    }),
    // className: 'site-antd-table-column-width mw-275',
    render: (record, { isViewingFromEntity, isViewingFromPage }) => {
      if (isViewingFromPage == 'DETAIL_PAGE' && isViewingFromEntity === 'SITE') {
        return <Tooltip title={record.name} placement='right'>
          <Typography.Text ellipsis>{record.name}</Typography.Text>
        </Tooltip>;
      }
      return <LinkBadge
        url={record.isLocal ? `/hco/${record.id}` : undefined}
        label={record.name}
        openInNewTab={openInNewTab}
        className="open-site-link"
      />;
    },
    updateView: buildFreeTextUpdateFieldConfig({
      valuePath: 'name',
      mutation: UPDATE_SITE_NAME_MUTATION,
      buildVariables: (value, record) => ({
        siteId: record.id,
        siteName: value,
      }) as UpdateSiteNameMutationMutationVariables,
    }),
  })
);

const UPDATE_SITE_NAME_MUTATION = gql`
  mutation UpdateSiteNameMutation($siteId: Int!, $siteName: String!) {
    updateSiteName(siteId: $siteId, title: $siteName) {
      id
      name
    }
  }
`;
