import React, { useState } from 'react';
import { Input, List } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { useThrottle } from 'ahooks';
import { StateArray } from '../../util/StateArrayType';
import { UserType } from '../../util/useUser';
import { AvailableUsersForCalendarQuery, AvailableUsersForCalendarQueryVariables } from '../../../gql/typings';

type CalendarUserSelectorProps = {
  selectedUserState: StateArray<UserType>;
  close: () => void;
};

const CalendarUserSelector: React.FC<CalendarUserSelectorProps> = ({
  selectedUserState: [selectedUser, setSelectedUser],
  close,
}) => {
  const [userSearch, setUserSearch] = useState('');
  const userSearchThrottled = useThrottle(userSearch, { wait: 500 });
  const {
    data,
    loading,
  } = useQuery<AvailableUsersForCalendarQuery, AvailableUsersForCalendarQueryVariables>(DATA_QUERY, {
    variables: { search: userSearchThrottled },
  });

  return (
    <div className="calendar-user-selector-container">
      <Input.Search
        autoFocus
        value={userSearch}
        loading={loading || userSearch !== userSearchThrottled}
        onChange={e => setUserSearch(e.target.value)}
      />
      <List
        className="list-container"
        dataSource={data?.users.nodes ?? []}
        loading={(data?.users.nodes ?? []).length === 0 && loading}
        renderItem={user => (
          <List.Item
            key={user.id}
            className={`list-item ${selectedUser.id === user.id ? 'active' : ''}`}
            onClick={() => {
              setSelectedUser(user);
              close();
            }}
          >
            <List.Item.Meta title={user.fullname} />
          </List.Item>
        )}
      />
    </div>
  );
};

const DATA_QUERY = gql`
  query AvailableUsersForCalendar($search: String) {
    users(criteria: { search: $search }) {
      hash
      nodes {
        id
        username: email
        fullname
        roleId
      }
    }
  }
`;

export default CalendarUserSelector;
