import React, { useContext, useEffect } from 'react';
import { gql, TypedDocumentNode, useQuery } from '@apollo/client';
import { Badge, Tabs } from 'antd';
import { isFunction } from 'lodash';
import EntityTabsContext, { EntityTabsContextType } from '../EntityTabsContextType';
import { RelationAffiliationTabConfig } from './EntityRelationTabs';
import { useLocalization } from '../../../util/useLocalization';
import { EntityRelationTabsQueryQuery, EntityRelationTabsQueryQueryVariables, PermissionEnum } from '../../../../gql/typings';
import { usePermissions } from '../../../permission/usePermissions';


type EntityRelationTabProps<T> = {
  recordId: number;
  type: keyof EntityTabsContextType['relationTabs'];
  query: TypedDocumentNode<T, { recordId: number }>;
  defaultTab: string[];
  configs: Record<string, RelationAffiliationTabConfig<T>>;
  configurationId: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function EntityRelationTab<T = any>({
  recordId,
  query,
  type,
  defaultTab,
  configs,
  configurationId,
}: EntityRelationTabProps<T>) {
  const localization = useLocalization();
  const { relationTabs } = useContext(EntityTabsContext);
  const {
    data: tabsData,
  } = useQuery<EntityRelationTabsQueryQuery, EntityRelationTabsQueryQueryVariables>(DATA_QUERY, {
    variables: { configurationId },
  });
  const { data } = useQuery(query, {
    variables: { recordId },
  });
  const [activeKey, setActiveKey] = relationTabs[type];

  const permissionCodes: PermissionEnum[] = [];
  const toShow: Record<string, boolean> = Object.keys(configs).reduce((acc, key) => {
    const c = configs[key];

    let allowed: boolean;
    if (!c) {
      allowed = false;
    } else if (isFunction(c.tab.show)) {
      allowed = c.tab.show(data);
    } else {
      allowed = c.tab.show;
    }

    if (c && c.readPermission) permissionCodes.push(c.readPermission);

    return {
      ...acc,
      [key]: allowed,
    };
  }, {});

  const permissions = usePermissions(permissionCodes);
  useEffect(() => {
    // @ts-ignore
    if (!activeKey) setActiveKey(defaultTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey]);

  const show = (key: string): boolean => {
    const c = configs[key];
    if (c?.readPermission && !permissions[c.readPermission].read) return false;
    return toShow[key] ?? false;
  };

  const activeKeyToShow = show(activeKey) ? activeKey : defaultTab.find(show);

  return (
    <Tabs
      type="card"
      onChange={(e) => setActiveKey(e as never)}
      activeKey={activeKeyToShow}
      // activeKey="sites"
      items={(tabsData?.entityGridItemRelationFields.nodes ?? []).filter(tab => show(tab.relationTabKey)).map(tab => {
        const conf = configs[tab.relationTabKey]!;
        return {
          key: tab.relationTabKey,
          label: <>
            {conf.tab.title ? conf.tab.title(localization, data) : localization.formatMessage(conf.title)}
            {conf.tab.badgeProps && <Badge className="primary-badge" {...conf.tab.badgeProps(data)} />}
          </>,
          children: tab.relationTabKey === activeKeyToShow && conf.render(recordId, data),
        };
      })}
    />
  );
}

const DATA_QUERY = gql`
  query EntityRelationTabsQuery($configurationId: ID!) {
    entityGridItemRelationFields(criteria: { entityGridItemConfigurationId: $configurationId }) {
      hash
      nodes {
        id
        relationTabKey
      }
    }
  }
`;

export default EntityRelationTab;
