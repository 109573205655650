import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Drawer } from 'antd';
import { toNumber } from 'lodash';
import Icon from '../Icon/Icon';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';
import { isActualNumber } from '../../util/Util';

const sizes = {
  small: 'size-small',
  medium: 'size-medium',
  large: 'size-large',
  noSize: 'size-none',
};

type CardProps = {
  title?: string | React.ReactNode;
  className?: string;
  icon?: 'PERSON' | 'KEY' | 'ACTIVITY' | 'PROJECT' | 'SITE' | 'POSITION';
  url?: string;
  size?: (keyof typeof sizes) | number;
  onBack?: (() => void) | false;
  extra?: React.ReactNode;
  metaDataElement?: React.ReactNode;
};


const Card: React.FC<CardProps> = ({
  title,
  className,
  icon,
  url,
  size = 'medium',
  onBack,
  extra,
  metaDataElement,
  children,
}) => {
  const localization = useLocalization();

  const [drawerEnabled, setDrawerEnabled] = useState(false);
  const length = typeof title === 'string' ? title.length : 0;


  return (
    <div className={`card-container ${className || ''}`}>
      <div
        className={`card-body ${isActualNumber(size) ? '' : sizes[size]}`}
        style={isActualNumber(size) ? { height: (150 * toNumber(size)) -48 } : {}}
      >
        {title && <div>
          <div className={`card-title ${length > 25 ? 'small-font' : ''}`.trim()}>
            <div className="left">
              {onBack && (
                <div className="icon back">
                  <span onClick={onBack}>
                    <Icon style={{ color: '#C2CE26', fontSize: '14px' }} iconType="LEFT_ARROW" />
                  </span>
                </div>
              )}
              {icon && <Icon iconType={icon} text={url ? <NavLink to={url}>{title}</NavLink> : title} />}
            </div>
            <div className="right">
              <div className="badge-container">
                {metaDataElement && <Icon iconType="INFO" onClick={() => setDrawerEnabled(true)} />}
                {extra}
                {/* {topRightIcon && <ColoredCircle type="primary"> */}
                {/*   <Icon iconType={topRightIcon} style={{ padding: 2 }} /> */}
                {/* </ColoredCircle>} */}
                {/* {topRightBadgeChar && <ColoredCircle type="primary" style={{ color: 'white' }}> */}
                {/*   {topRightBadgeChar} */}
                {/* </ColoredCircle>} */}
              </div>
            </div>

          </div>
        </div>}
        {children}
      </div>
      {metaDataElement && <Drawer
        title={localization.formatMessage(Locale.Attribute.Meta_Data)}
        placement="right"
        className="card-drawer-container"
        closable={false}
        onClose={() => {
          setDrawerEnabled(false);
          // disabling scroll for 400ms to fix drawer scroll issue #3392
          window.document.body.style.position = 'fixed';
          setTimeout(() => {
            window.document.body.style.position = 'initial';
          }, 400);
        }}
        open={drawerEnabled}
        width={280}
      >
        <div className="text">
          {icon && <Icon iconType={icon} />}
          <span className="title">{title}</span>
        </div>
        {metaDataElement}
      </Drawer>}
    </div>
  );
};

export default Card;
