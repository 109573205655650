import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';
import { EntityTypeEnum, FieldEnum, PersonCriteriaOrder } from '../../../../gql/typings';

export const personCountryCodeFieldConfig = personFieldConfig(
  'countryCode',
  ['id', 'countryCode', 'isLocal'],
  props => ({
    title: props.localization.formatMessage(Locale.Attribute.Country),
    additionalTableConfig: {
      width: 80
    },
    dcrInfo: person => ({
      entityType: EntityTypeEnum.PERSON,
      entityAffiliationId: person.id,
      field: FieldEnum.HCP_COUNTRY_CODE,
    }),
    sorting: {
      ascend: PersonCriteriaOrder.COUNTRY_CODE_ASC,
      descend: PersonCriteriaOrder.COUNTRY_CODE_DESC,
    },
    render: (record) => record.countryCode,
    // onCell: (record) => ({
    //   className: record.isLocal ? '' : 'global-database',
    // })
  }),
);
