import { gql } from '@apollo/client';

export const ActivityPersons_FRAGMENT = gql`
  fragment ActivityPersonFragment on Person {
    id
    fullName
    title
    segmentationCode {
      code
      heading
    }
  }
`;
