import React from 'react';
import moment from 'moment';
import { get, orderBy, values } from 'lodash';
import { spans, spansDefaultIndex } from './ActivityTableSpans';
import ActivityNestedTable from './ActivityNestedTable';

type ActivityDsType = {
  id: number;
  startDate: Date;
  persons: { totalCount: number };
};

export function activityNestHCPData<T extends ActivityDsType>(ds: T[]) {
  return ds.map(record => ({
    ...record,
    expandable: get(record, 'persons.totalCount', 0) > 0,
    childrenFn: () => <ActivityNestedTable record={record} />,
  }));
}

export const activityTableMapGroup = (span = spans[spansDefaultIndex]!) => (ds: ActivityDsType[]) => {
  if (!span.groupBy) return ds;
  const map = ds.reduce((previousValue, currentValue) => {
    const group = span.groupBy!(moment(currentValue.startDate));
    if (!previousValue[group]) previousValue[group] = {
      key: group,
      children: [],
      // expandable: false,
    };
    previousValue[group].children.push({
      ...currentValue,
      // expandable: currentValue.persons.totalCount > 0,
    });
    return previousValue;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as Record<any, any>);
  return orderBy(values(map), 'key', 'desc');
};
