import React from 'react';
import * as PropTypes from 'prop-types';
import { Col, Row, Tooltip } from 'antd';
import { flatMap } from 'lodash';
import transformers from './transformers';

const transformValue = ({
  value,
  transform,
  tooltip,
}) => {
  if (!value) return '';
  if (transform) return transformers[transform](value);
  if (tooltip) {
    return <Tooltip title={tooltip}>{value}</Tooltip>;
  }
  return value;
};


const DisplayFields = ({
  items,
}) => {

  const flatten = flatMap(items, item => {
    if (item.items) {
      return item.items;
    }
    return [item];
  });

  return (
    <div className="display-fields-container">
      <div className="display-fields">
        <Row>
          {flatten.map(item => (

            <Col span={4} key={item.key || item.label}>
              <div className="field">
                <div className="field-header">
                  {item.label}
                </div>
                <div className="field-label">
                  {transformValue(item)}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

DisplayFields.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    items: PropTypes.array,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    transform: PropTypes.oneOf([
      'trunc30', 'webPage', 'nav', 'email', 'time',
    ]),
  })).isRequired,
};

export default DisplayFields;
