import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import DateDisplay from '../../../components/Badge/DateDisplay';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SitePersonCriteriaOrder, SourceEnum } from '../../../../gql/typings';

export const linkCreatedFieldConfig = linkFieldConfig(
  'created',
  ['ct', 'imfCt', 'maintainerSourceCode'],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Date_of_creation),
    additionalTableConfig: {
      width: 125
    },
    sorting: {
      ascend: [SitePersonCriteriaOrder.CT_ASC],
      descend: [SitePersonCriteriaOrder.CT_DESC],
    },
    render: (record) => {
      const isLocalDB = record.maintainerSourceCode === SourceEnum.LOCAL;
      return <DateDisplay>{isLocalDB ? record.ct: record.imfCt}</DateDisplay>;
    },
  })
);
