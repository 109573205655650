import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { flatten, toNumber } from 'lodash';
import { useMatch } from 'react-router';
import { Alert, Dropdown, MenuProps, message, Popconfirm, Space, Tabs, TabsProps, Tag, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import MainView from '../../components/View/MainView';
import DcrAttributes from './components/DcrAttributes';
import DcrTableView from './components/DcrTableView';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';
import { DcrResponseConf } from './DcrStatusTag';
import DcrRelatedDataTab from './DcrRelatedDataTab';
import DcrHistoryTab from './DcrHistoryTab';
import ApbButton from '../../components/Button/ApbButton';
import Icon from '../../components/Icon/Icon';
import DcrGroupHeading from './components/DcrGroupHeading';
import {
  DcrGroupDetailQuery,
  DcrGroupDetailQueryVariables, DcrRequestTypeEnum, DcrResponseTypeEnum,
  ManageGroupDcrMutationMutation,
  ManageGroupDcrMutationMutationVariables
} from '../../../gql/typings';


const DcrGroupDetails = () => {
  const localization = useLocalization();
  const match = useMatch('/dcr/:id');
  const dcrGroupId = toNumber(match?.params?.id);


  const {
    data,
    loading
  } = useQuery<DcrGroupDetailQuery, DcrGroupDetailQueryVariables>(DATA_QUERY, {
    variables: { id: dcrGroupId },
  });
  const [
    manageGroupDcrMutation,
  ] = useMutation<ManageGroupDcrMutationMutation, ManageGroupDcrMutationMutationVariables>(Manage_Group_Dcr_Mutation);

  const [mdm, setMdm] = useState(false);

  useEffect(() => {
    if (data) {
      setMdm(data?.hasAccessToModule);
    }
  }, [data]);
  const renderRelatedData = flatten((data?.dcrGroup?.dcrs?.nodes ?? []).map(d => d.dcrSnapshotInfo)).length > 0;
  const lockDdl = data?.dcrGroup?.responseStatus.responseTypeEnum === DcrResponseTypeEnum.REJECT;
  const isMdmModuleEnabled = data?.hasAccessToModule;
  const onSubmit = (maintainRecord: boolean, isMdmEnabled: boolean) => {
    manageGroupDcrMutation({
      variables: {
        id: data?.dcrGroup ? data?.dcrGroup.id : 0,
        maintainRecord,
        isMdmEnabled
      },
      refetchQueries: ['DcrGroupDetail'],
    })
      .finally(() => message.success(localization.formatMessage(Locale.Text.Action_was_successful)));
  };

  const items = [
    mdm && {
      key: '1',
      label: localization.formatMessage(Locale.Text.Retain_dcr_records),
      onClick: () => onSubmit(true, mdm)
    },
    {
      keu: '2',
      label: (
        <Popconfirm
          placement="top"
          title={localization.formatMessage(Locale.Text.Dcr_inactivate_record_message)}
          onConfirm={() => onSubmit(false, mdm)}
          okText="Yes"
          cancelText="No"
        >
          {mdm
            ? localization.pluralMessage(
              Locale.Text.Inactivate_dcr_records,
              data?.dcrGroup?.dcrs.nodes?.filter(d => d?.requestTypeEnum === DcrRequestTypeEnum.CREATE)?.length ?? 0,
            ) : localization.formatMessage(Locale.Text.Handle_inactivate_dcr_record)}
        </Popconfirm>
      )
    }
  ] as MenuProps['items'];

  const statusEnum = data?.dcrGroup?.responseStatus.responseTypeEnum;

  return (
    <MainView
      className="dcr-details-container"
      titleLoading={loading}
      title={<div className="title" style={{ paddingTop: 0 }}>
        <DcrGroupHeading groupId={dcrGroupId} withLinks />
      </div>}
      rightActions={<Space>

        {data?.dcrGroup?.responseStatus.responseComment && (
          <Alert
            style={{ height: 32, marginRight: 9 }}
            message={<Icon iconType="MESSAGE" text={data.dcrGroup.responseStatus.responseComment} />}
            type={renderRelatedData ? 'error' : 'success'}
          />
        )}
        {statusEnum && (
          <Tag
            color={DcrResponseConf[statusEnum].color}
            style={{ height: 30, fontSize: '12px', paddingTop: '4px' }}
            className="dcr-group-status"
            data-dcr-status={statusEnum}
          >
            {localization.formatMessage(DcrResponseConf[statusEnum].localizationKey)}
          </Tag>
        )}
        {statusEnum === DcrResponseTypeEnum.PENDING_REJECT
        && <Dropdown disabled={lockDdl} menu={{ items }} trigger={['click']}>
          <Tooltip title={lockDdl ? localization.formatMessage(Locale.Text.Handle_inactivate_dcr_record_tooltip) : ''}>
            <span onClick={e => e.preventDefault()}>
              <ApbButton
                icon={<DownOutlined />}
                size="middle"
                buttonType="action"
                text={localization.formatMessage(Locale.Text.Actions_take)}
              />
            </span>
          </Tooltip>
        </Dropdown>}
      </Space>}
    >
      <DcrAttributes group={data?.dcrGroup} />
      <Tabs
        className="dcr-details-tabs"
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: localization.formatMessage(Locale.General.Field),
            children: <DcrTableView
              responseType={data?.dcrGroup?.responseStatus.responseTypeEnum}
              dcrList={data?.dcrGroup?.dcrs.nodes ?? []}
              loading={loading}
            />
          },
          {
            key: '2',
            label: localization.formatMessage(Locale.General.History),
            children: <DcrHistoryTab hasRelatedData={renderRelatedData} group={data?.dcrGroup} />
          },
          renderRelatedData && mdm != null && {
            key: '3',
            className: 'related-dcr-tab',
            label: localization.formatMessage(Locale.General.Related_data),
            children: <DcrRelatedDataTab mdmEnabled={mdm} group={data?.dcrGroup} />
          }
        ] as TabsProps['items']}
      />
      {!isMdmModuleEnabled
      && <div className="dcr-own-module-upsell-1">{localization.formatMessage(Locale.Text.Dcr_upsell_text)}</div>}
      {!isMdmModuleEnabled
      && <div className="dcr-own-module-upsell-1">
        {localization.formatMessage(Locale.Text.Dcr_upsell_text_info, {
          value: <a href="mailto:sales@apurebase.com">sales@apurebase.com</a>
        })}
      </div>}
    </MainView>
  );
};

const Manage_Group_Dcr_Mutation = gql`
  mutation ManageGroupDcrMutation(
    $id: Int!
    $maintainRecord: Boolean!
    $isMdmEnabled: Boolean!
  ) {
    manageGroupDcrMutation(groupId: $id, maintainRecord: $maintainRecord, isMdmEnabled: $isMdmEnabled){
      id
    }
  }
`;

const DATA_QUERY = gql`
  query DcrGroupDetail($id: Int!) {
    hasAccessToModule(module: MDM)
    dcrGroup(id: $id) {
      id
      ct
      areYouDcrOwner
      responsibleUserId
      isDcrGroupKeptAsLocalRecord
      GroupComment{
        id
        byUserId
        comment
        ct
        ut
      }
      responses {
        hash
        nodes {
          id
          comment: responseComment
          ct
          responseTypeEnum
          dcrNoteTemplate{
            id
            key
            status
            sort
            addedBy
            ct
            ut
          }
          user {
            id
            fullname
          }
        }
      }
      responsibleUser {
        id
        fullname
      }
      responseStatus {
        id
        responseTypeCode
        responseTypeEnum
        responseComment
      }
      dcrs {
        hash
        totalCount
        nodes {
          id
          requestTypeEnum
          dcrSnapshotInfo {
            id
            dcrId
            entityAffiliationId
            entityTypeEnum
            affiliationEntity(nullIfMissingPermission: true) {
              id
              ... on Activity {
                heading
              }
              ... on List {
                title
                isShared
                createdUser{
                  id
                  fullname
                }
                listType {
                  id
                  heading
                  code
                }
              }
            }
          }
          entityTypeEnum
          requestTypeEnum
          requestType {
            code
            heading
          }
          ct
          entityAffiliationId
          ut
          lines {
            hash
            totalCount
            nodes {
              id
              newValue
              dcrNewValue
              dcrOldValue
              newValueMapped
              oldValueMapped
              oldValue
              fieldCode
              fieldLabel
              fieldLabelKey
            }
          }
          affiliationEntity {
            id
            ... on Person {
              fullName
            }
            ... on Site {
              name
            }
            ... on SitePerson {
              siteId
              persId
              position{
                code
                type
                label
              }
            }
          }
        }
      }
    }
  }
`;


export default DcrGroupDetails;
