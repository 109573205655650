import React from 'react';
import { gql } from '@apollo/client';
import { Switch } from 'antd';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { MergeItemPersonConsentFragment, useUpdatePersonConsentMutation } from '../../../../../../../../../gql/typings';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';

const AdminMergeItemPersonConsent: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonConsentFragment;
  const localization = useLocalization();
  const [setValue] = useUpdatePersonConsentMutation();

  if (!props.item.hasConflict) return (
    <AdminMergeItemBasicField {...props}>
      {localization.formatMessage(Locale.Text.Merge_request_consent_value_transfer_description, {
        consentType: <strong>{localization.formatMessageByStr(info.value.type.headingKey)}</strong>,
        status: <strong>{info.value.isEnabled ? 'true' : 'false'}</strong>,
      })}
    </AdminMergeItemBasicField>
  );

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.value.isEnabled}
      toValue={!info.value.isEnabled}
      inputRenderer={({ value, ...rest }) => <Switch {...rest} checked={value} />}
      updateMutation={isEnabled => setValue({
        variables: {
          isEnabled,
          personId: props.into.id,
          typeCode: info.value.consentTypeCode,
        }
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_consent_value_mismatch_description, {
        consentType: <strong>{localization.formatMessageByStr(info.value.type.headingKey)}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation UpdatePersonConsent($personId: Int!, $typeCode: String!, $isEnabled: Boolean!) {
    setConsentValue(entityAffiliationId: $personId, typeCode: $typeCode, isEnabled: $isEnabled) {
      entityAffiliationId
      entityType
      consentTypeCode
      isEnabled
    }
  }
`;


gql`
  fragment MergeItemPersonConsent on PersonConsent_EntityDataToBeTransferredType {
    code
    value {
      id
      consentTypeCode
      isEnabled
      type {
        code
        headingKey
      }
    }
  }
`;

export default AdminMergeItemPersonConsent;
