import React from 'react';
import { Button, Form, FormInstance, Input } from 'antd';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import AddPositionForm from '../AddPositionForm';
import { AddWorkplaceInput, SiteInput } from '../../../../../gql/typings';
import { Optional, RecursivePartial } from '../../../../util/StateArrayType';

type CreatePersonAddWorkspacePositionStepProps = {
  form: FormInstance<AddWorkplaceInput>;
  countryCode: string|undefined;
  loading: boolean|undefined;
  initialState: Optional<RecursivePartial<AddWorkplaceInput>>;
  clear: () => void;
  onFinish: (siteId: Optional<number>, siteInput: Optional<RecursivePartial<SiteInput>>) => void;
  setVisible: (visible: boolean) => void;
};

const CreatePersonAddWorkspacePositionStep: React.FC<CreatePersonAddWorkspacePositionStepProps> = ({
  form,
  countryCode,
  initialState,
  onFinish,
  clear,
  loading,
  setVisible,
}) => {
  const localization = useLocalization();

  return (
    <AddPositionForm
      form={form}
      initialState={initialState}
      countryCode={countryCode}
      onFinish={values => onFinish(values.siteId, values.siteInput)}
    >
      <Form.Item name="siteInput" hidden><Input /></Form.Item>
      <Form.Item name="siteId" hidden><Input /></Form.Item>
      <div style={{ display: 'flex', justifyContent: 'center', columnGap: 16 }}>
        <Button onClick={clear}>
          {localization.formatMessage(Locale.Command.Clear)}
        </Button>
        <Button
          type="primary"
          className="position-step-add-workplace-button"
          loading={loading}
          onClick={() => {
            form.submit();
            setVisible(false);
          }}
        >
          {localization.formatMessage(Locale.Command.Add_workplace)}
        </Button>
      </div>
    </AddPositionForm>
  );
};

export default CreatePersonAddWorkspacePositionStep;
