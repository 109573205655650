/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Form, Input, message } from 'antd';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import Checkbox from '../components/Input/Check';
import LinkBadge from '../components/Badge/LinkBandge';
import { authenticate, isAuthenticated } from './authenticate';
import advanceLogo from '../../img/png/advance-logo-white.png';
import LoginFooter from './LoginFooter';
import Icon from '../components/Icon/Icon';
import Button from '../components/Input/Button';
import { Locale } from '../../localization/LocalizationKeys';


const LoginPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isSelected, setIsSelected] = useState(false);


  useEffect(() => {
    if (isAuthenticated()) navigate('/', { replace: true });
  }, []);

  const intl = useIntl();

  const login = (values?: { username?: string; password?: string }) => {
    setIsLoggingIn(true);
    const stopLoading = () => setIsLoggingIn(false);
    return authenticate({
      username: get(values, 'username', ''),
      password: get(values, 'password', ''),
      rememberSelected: isSelected,
    }, intl)
      .then(stopLoading)
      .then(() => navigate('/', { replace: true }))
      .catch(errorMessage => {
        stopLoading();
        message.error(errorMessage);
      });
  };


  return (
    <section id="login-screen">
      <div className="login-container">
        <img alt="advance logo" className="advance-logo" src={advanceLogo} />
        <div className="login-card">
          <Form id="login-form" onFinish={login} form={form}>
            <Form.Item name="username" className="login-field login-username">
              <Input
                size="large"
                suffix={<Icon iconType="USER" />}
                autoFocus
                placeholder={intl.formatMessage(Locale.General.Username)}
              />
            </Form.Item>
            <Form.Item name="password" className="login-field login-password">
              <Input.Password
                placeholder={intl.formatMessage(Locale.General.Password)}
                size="large"
              />
            </Form.Item>

            <div className="login-field remember-me">
              <Checkbox
                label={intl.formatMessage(Locale.Command.Remember_me)}
                onChange={() => setIsSelected(!isSelected)}
              />
            </div>

            <div className="login-field button-submit">
              <Form.Item>
                <Button
                  label={
                    intl.formatMessage(Locale.Command.Login)
                  }
                  color="green"
                  size="small"
                  loading={isLoggingIn}
                  className="login-button"
                  style={{ width: '50%' }}
                />
              </Form.Item>
            </div>

            <div className="login-field forgot-password">
              <LinkBadge
                url="/password/reset"
                label={intl.formatMessage(Locale.Text.Forgot_password)}
                style={{
                  margin: '30px auto',
                }}
              />
            </div>

          </Form>
        </div>

      </div>
      <LoginFooter />

    </section>
  );
};

export default LoginPage;
