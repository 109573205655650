import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Form, Select } from 'antd';
import { gql } from '@apollo/client/core';
import { useApolloClient, useMutation } from '@apollo/client';
import { DashboardItemProps } from '../../DashboardItemTypes';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { generateMessageObject } from '../../../../util/generateMessageObject';
import { dashboardGridViewDraggableCancelClassName } from '../../../../browse/dashboards/DashboardGridView/DashboardGridView';
import {
  EntityPieSettingsQueryQuery, EntityPieSettingsQueryQueryVariables,
  EntityTypeEnum,
  PieDataQueryQuery,
  UpdateEntityPieSettingsMutationMutation, UpdateEntityPieSettingsMutationMutationVariables
} from '../../../../../gql/typings';


type DashboardItemPieSettingsProps = DashboardItemProps & {
  onSaved: () => void;
  initialSettings: NonNullable<PieDataQueryQuery['dashboardEntityPieSettings']>;
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 16, offset: 8 },
  },
};

const DashboardItemPieSettings: React.FC<DashboardItemPieSettingsProps> = (props) => {
  const intl = useIntl();
  const client = useApolloClient();
  const [
    fieldOptions,
    setFieldOptions,
  ] = useState<NonNullable<EntityPieSettingsQueryQuery['dashboardEntityPieSupportedFields']>>([]);
  const [selectedType, setSelectedType] = useState<EntityTypeEnum|undefined>(props.initialSettings[0]?.entityType);
  const [selectedField1, setSelectedField1] = useState<string|undefined>(
    props.initialSettings[0]?.customFieldCode ?? props.initialSettings[0]?.fieldCode ?? undefined
  );
  const [selectedField2, setSelectedField2] = useState<string|undefined>(
    props.initialSettings[1]?.customFieldCode ?? props.initialSettings[1]?.fieldCode ?? undefined
  );
  const [
    update,
  ] = useMutation<UpdateEntityPieSettingsMutationMutation, UpdateEntityPieSettingsMutationMutationVariables>(
    UPDATE_MUTATION
  );

  useEffect(() => {
    if (!selectedType) setFieldOptions([]);
    else client.query<EntityPieSettingsQueryQuery, EntityPieSettingsQueryQueryVariables>({
      query: DATA_QUERY,
      variables: { entityType: selectedType }
    }).then(res => setFieldOptions(res?.data?.dashboardEntityPieSupportedFields));
  }, [client, selectedType]);

  const onSave = () => update({
    variables: {
      input: {
        dashboardItemConfigurationId: props.configuration.id,
        entityType: selectedType!,
        fields: [selectedField1, selectedField2].filter(e => e) as string[],
      },
    }
  }).then(props.onSaved);

  return (
    <div className={`dashboard-item-pie-settings-container ${dashboardGridViewDraggableCancelClassName}`}>
      <Form {...formItemLayout}>
        <Form.Item label={intl.formatMessage(Locale.Command.Select_Entity_Type)} valuePropName="">
          <Select
            allowClear
            value={selectedType}
            onChange={(v: EntityTypeEnum) => {
              setSelectedType(v);
              setSelectedField1(undefined);
              setSelectedField2(undefined);
            }}
          >
            <Select.Option value={EntityTypeEnum.PERSON}>
              {intl.formatMessage(Locale.Attribute.Person_HCP)}
            </Select.Option>
            <Select.Option value={EntityTypeEnum.SITE}>
              {intl.formatMessage(Locale.Attribute.Site_HCO)}
            </Select.Option>
            <Select.Option value={EntityTypeEnum.ACTIVITY}>
              {intl.formatMessage(Locale.General.Activity)}
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label={intl.formatMessage(Locale.General.Field)}>
          <Select allowClear value={selectedField1} onChange={setSelectedField1} disabled={fieldOptions.length === 0}>
            {fieldOptions.map(option => (
              <Select.Option key={option.code} value={option.code}>
                {intl.formatMessage(generateMessageObject(option.headingKey || option.heading))}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...formItemLayoutWithOutLabel}>
          <Select allowClear value={selectedField2} onChange={setSelectedField2} disabled={fieldOptions.length < 2}>
            {fieldOptions.filter(o => o.code !== selectedField1).map(option => (
              <Select.Option key={option.code} value={option.code}>
                {intl.formatMessage(generateMessageObject(option.headingKey || option.heading))}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item {...formItemLayoutWithOutLabel}>
          <Button type="primary" htmlType="submit" disabled={!selectedField1} onClick={onSave}>
            {intl.formatMessage(Locale.Command.Save)}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const DATA_QUERY = gql`
  query EntityPieSettingsQuery($entityType: EntityTypeEnum!) {
    dashboardEntityPieSupportedFields(entityType: $entityType) {
      code
      entityType
      heading
      headingKey
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateEntityPieSettingsMutation($input: DashboardEntityPieUpdateInput!) {
    updateDashboardEntityPieSettings(input: $input)
  }
`;

export default DashboardItemPieSettings;
