import React, { useState } from 'react';
import { Modal, Select, Space, Typography } from 'antd';
import { gql } from '@apollo/client';
import { WarningOutlined } from '@ant-design/icons';
import { StateArray } from '../../util/StateArrayType';
import { useLocalization } from '../../util/useLocalization';
import {
  EntityTypeEnum,
  useCherryPickOnBeHalfOfCustomerMutation,
  useInternationalCustomersForCherryPickQuery
} from '../../../gql/typings';
import { Locale } from '../../../localization/LocalizationKeys';
import { CountryLabel, FlagCountry } from '../Flag/Flag';


type CherryPickForOtherInstanceModalProps = {
  visibleState: StateArray<boolean>;
  refetch: () => void;
  countryCode: string;
  apurebaseId: number;
  entityType: EntityTypeEnum.PERSON|EntityTypeEnum.SITE;
};

const CherryPickForOtherInstanceModal: React.FC<CherryPickForOtherInstanceModalProps> = ({
  visibleState: [visible, setVisible],
  refetch,
  countryCode,
  apurebaseId,
  entityType,
}) => {
  const localization = useLocalization();
  const [selectedCustomerCode, setSelectedCustomerCode] = useState<string>();
  const [performCherryPick, { loading: isCherryPicking }] = useCherryPickOnBeHalfOfCustomerMutation();
  const { data, refetch: reload, loading } = useInternationalCustomersForCherryPickQuery({
    variables: {
      criteria: {
        hasPersonCherryPickSupport: entityType === EntityTypeEnum.PERSON ? true : undefined,
        hasSiteCherryPickSupport: entityType === EntityTypeEnum.SITE ? true : undefined,
        excludeWhenPersonApbIdIsCherryPicked: entityType === EntityTypeEnum.PERSON ? apurebaseId : undefined,
        excludeWhenSiteApbIdIsCherryPicked: entityType === EntityTypeEnum.SITE ? apurebaseId : undefined,
        countries: [countryCode],
      },
    },
    skip: !visible,
  });

  const selectedCustomer = selectedCustomerCode
    ? data?.internationalCustomers.nodes.find(n => n.code == selectedCustomerCode)
    : null;

  return (
    <Modal
      title={localization.formatMessage(Locale.Attribute.Cherry_pick)}
      open={visible}
      onCancel={() => setVisible(false)}
      width="clamp(480px, 75%, 640px)"
      okButtonProps={{ disabled: (selectedCustomer?.cherryPickOverview?.amountAvailable ?? 0) === 0, loading: isCherryPicking }}
      okText={localization.formatMessage(Locale.Attribute.Cherry_pick)}
      onOk={() => selectedCustomerCode && performCherryPick({
        variables: { entityApbId: apurebaseId, customerCode: selectedCustomerCode, entityType }
      }).then(() => { setVisible(false); refetch(); return reload(); })}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Typography.Text>
          {localization.formatText(Locale.Text.Cherry_pick_for_customer_description)}
        </Typography.Text>
        <Typography.Paragraph>
          <Select
            loading={loading}
            style={{ width: '100%' }}
            placeholder={localization.formatMessage(Locale.Command.Select_customer)}
            value={selectedCustomerCode}
            onChange={setSelectedCustomerCode}
            options={data?.internationalCustomers.nodes.map(node => ({
              value: node.code,
              label: <span>
                {(node.cherryPickOverview?.amountAvailable ?? 0) === 0 && (
                  <><WarningOutlined style={{ color: '#ef9036' }} />&nbsp;</>
                )}
                {node.heading}
              </span>,
            }))}
          />
        </Typography.Paragraph>
        <Typography.Paragraph>
          {selectedCustomer && localization.formatMessage(Locale.Text.Select_customer_for_cherry_picking_description, {
            customer: <strong>{selectedCustomer.heading}</strong>,
            amountLeft: selectedCustomer.cherryPickOverview?.amountAvailable ?? 0,
            totalAmount: selectedCustomer.cherryPickOverview?.amount ?? 0,
            country: <strong>{localization.formatMessage(CountryLabel[countryCode.toLocaleUpperCase() as FlagCountry])}</strong>
          })}
        </Typography.Paragraph>
      </Space>
    </Modal>
  );
};

gql`
  query InternationalCustomersForCherryPick($criteria: InternationalCustomerCriteria!) {
    internationalCustomers(criteria: $criteria) {
      hash
      nodes {
        code
        heading
        countryCode
        cherryPickOverview {
          customerCode
          country
          amount
          amountAvailable
          amountUsed
        }
      }
    }
  }
`;

gql`
  mutation CherryPickOnBeHalfOfCustomer($entityType: EntityTypeEnum!, $customerCode: String!, $entityApbId: Int!) {
    cherryPick(customerCode: $customerCode, entityType: $entityType, apurebaseIds: [$entityApbId]) {
      id
    }
  }
`;

export default CherryPickForOtherInstanceModal;
