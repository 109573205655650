import { gql } from '@apollo/client';
import React from 'react';
import moment from 'moment';
import ListDetailsStar from './ListDetailsStar';
import { LinkColumn } from '../../../components/Table/utils';
import { Locale } from '../../../../localization/LocalizationKeys';
import { TableConfig, TableFieldsConfig } from '../../search_old/types';
import { activityNestHCPData } from '../../activity/ActivityTable/ActivityTableUtils';
import { ListCriteriaOrder } from '../../../../gql/typings';
import { DATE_FORMAT_TIME } from '../../../util/format';


export const buildListTableFragment = (additionalQuery: string|null) => gql`
  fragment ListTableFragment on List {
    id
    ${additionalQuery ?? ''}
  }
`;

export const buildListConnectionDataQuery = (additionalQuery: string|null) => gql`
  query ListTableQuery($criteria: ListCriteria) {
    connection: lists(criteria: $criteria) {
      hash
      totalCount
      nodes { ...ListTableFragment }
    }
  }
  ${buildListTableFragment(additionalQuery)}
`;

export const buildListRecordDataQuery = (additionalQuery: string|null) => gql`
  query ListRecordQuery($recordId: Int!) {
    record: list(id: $recordId) {
      id
      ...ListTableFragment
    }
  }
  ${buildListTableFragment(additionalQuery)}
`;

export type SupportedListTableFields = 'title'|'ct'|'createdUser'|'listType'|'entityType'|'count'|'star';

export const supportedListTableFields: TableFieldsConfig<SupportedListTableFields>['fields'] = {
  title: props => ({
    key: 'title',
    queryFields: ['title', 'id'],
    sorting: {
      ascend: ListCriteriaOrder.HEADING_ASC,
      descend: ListCriteriaOrder.HEADING_DESC,
    },
    title: props.localization.formatMessage(Locale.Attribute.Heading),
    render: (record) => (
      <LinkColumn
        text={record.title}
        url={`/list/${record.id}`}
        openInNewTab={props.openInNewTab}
      />
    ),
  }),
  ct: props => ({
    key: 'ct',
    queryFields: ['ct'],
    sorting: {
      ascend: ListCriteriaOrder.CT_ASC,
      descend: ListCriteriaOrder.CT_DESC,
    },
    title: props.localization.formatMessage(Locale.Attribute.Date_of_creation),
    render: (record) => moment(record.ct).format(DATE_FORMAT_TIME),
  }),
  createdUser: props => ({
    key: 'createdUser',
    queryFields: ['createdUser.id', 'createdUser.fullname'],
    sorting: {
      ascend: ListCriteriaOrder.CREATED_BY_USER_ASC,
      descend: ListCriteriaOrder.CREATED_BY_USER_DESC,
    },
    title: props.localization.formatMessage(Locale.Attribute.Created_by),
    render: (record) => record.createdUser?.fullname,
  }),
  listType: props => ({
    key: 'listType',
    queryFields: ['listType.code', 'listType.heading'],
    sorting: {
      ascend: ListCriteriaOrder.LIST_TYPE_ASC,
      descend: ListCriteriaOrder.LIST_TYPE_DESC,
    },
    title: props.localization.formatMessage(Locale.General.List_type),
    render: (record) => record.listType.heading,
  }),
  entityType: props => ({
    key: 'entityType',
    queryFields: ['entityType.code', 'entityType.heading'],
    sorting: {
      ascend: ListCriteriaOrder.ENTITY_TYPE_ASC,
      descend: ListCriteriaOrder.ENTITY_TYPE_DESC,
    },
    title: props.localization.formatMessage(Locale.General.Entity_type),
    render: (record) => props.localization.formatMessageByStr(record.entityType.heading),
  }),
  count: props => ({
    key: 'count',
    queryFields: ['amountAffiliated'],
    title: props.localization.formatMessage(Locale.General.Count),
    render: (record) => record.amountAffiliated,
  }),
  star: props => ({
    key: 'star',
    queryFields: ['id'],
    render: (record) => <ListDetailsStar listId={record.id} />,
  }),
};


export const ListTableConfig: TableConfig = {
  buildConnectionQuery: buildListConnectionDataQuery,
  buildRecordQuery: buildListRecordDataQuery,
  buildFragment: buildListTableFragment,
  titleLabel: Locale.General.Lists,
  columnConfig: {
    fields: supportedListTableFields,
  },
  extraConfig: {
    expandable: true,
    mapData: activityNestHCPData,
  },
};
