import { gql, useLazyQuery, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { get } from 'lodash';
import { defineMessage, useIntl } from 'react-intl';
import { Skeleton, Tabs } from 'antd';
import ActivityColumn from './ActivityColumn';
import OverviewMenu from '../components/ActivityOverviewMenu';
import { usePageTitle } from '../../../hooks/usePageTitle';
import Loading from '../../../components/Loading/Loading';
import MainView from '../../../components/View/MainView';
import EstherEasterEgg from '../../../components/EasterEggs/EstherEasterEgg';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  ActivityCriteriaOrder,
  ActivityOverviewQuery,
  ActivityOverviewQueryVariables,
  ActivityStatusEnum,
  UserActivityOverViewQuery
} from '../../../../gql/typings';
import { useResponsive } from '../../../util/useResponsive';


const variablesBuilder = (
  responsibleUserId: number,
  limit = 20,
  showCompleted = false,
  personLimit = 10
): ActivityOverviewQueryVariables => {
  const now = moment();

  const excludePreviousStatuses = showCompleted ? [] : [ActivityStatusEnum.DONE];

  return {
    limit,
    excludePreviousStatuses,
    responsibleUserId,
    startDate: now.startOf('day').toDate(),
    endDate: now.endOf('day').toDate(),
    personLimit,
    pastActivityOrder: ActivityCriteriaOrder.START_DATE_ASC,
    todayActivityOrder: ActivityCriteriaOrder.START_DATE_ASC,
    futureActivityOrder: ActivityCriteriaOrder.START_DATE_ASC,
  };
};

const ActivityDashboard = () => {
  const { formatMessage } = useIntl();
  const [isListViewSelected, setIsListViewSelected] = useState(false);
  const [isCompletedSelected, setIsCompletedSelected] = useState(false);
  const [activeTab, setActiveTab] = useState('2');
  usePageTitle(formatMessage(defineMessage({
    id: 'Dashboard',
    defaultMessage: 'Dashboard',
  })));

  const { data: me, loading: meLoading } = useQuery<UserActivityOverViewQuery>(ME_QUERY);
  const [firstFetch, {
    data,
    called,
    refetch,
    loading: dataLoading,
  }] = useLazyQuery<ActivityOverviewQuery, ActivityOverviewQueryVariables>(DATA_QUERY, { pollInterval: 10000 });
  const selectedUserId = me?.viewer?.id;
  const intl = useIntl();
  const loading = data?.user?.id !== selectedUserId;
  const personLimit = 5;
  const { isDesktop } = useResponsive();


  useEffect(() => {
    if (!meLoading && !called && selectedUserId) firstFetch({
      variables: variablesBuilder(selectedUserId, 20, isCompletedSelected, personLimit),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meLoading, selectedUserId]);

  useEffect(() => {
    if (!dataLoading) refetch?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doRefetch = () => {
    if (called && selectedUserId) refetch?.(variablesBuilder(
      selectedUserId,
      20,
      isCompletedSelected,
      personLimit
    ));
  };
  useEffect(() => {
    doRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListViewSelected, isCompletedSelected, selectedUserId]);

  if (meLoading) return <Loading wrapWithMain />;

  const onMenuChange = (type: string) => {
    if (type === 'completed') {
      setIsCompletedSelected(!isCompletedSelected);
    } else {
      setIsListViewSelected(type === 'list');
    }
  };


  const OverviewTitle = () => (
    <div className="person-activity-display-name-section">
      <h1>
        <Skeleton loading={loading} title={{ width: 280 }} active={loading} paragraph={false}>
          <span>{intl.formatMessage(Locale.General.Activity_own)}</span>
        </Skeleton>
      </h1>

    </div>
  );

  const DesktopView = () => (
    <>
      <ActivityColumn
        connection={data?.user?.previous}
        title={intl.formatMessage(Locale.General.Previous)}
      />
      <ActivityColumn
        connection={get(data, 'user.today')}
        title={intl.formatMessage(Locale.General.Today)}
        bold
      />
      <ActivityColumn
        connection={get(data, 'user.next')}
        title={intl.formatMessage(Locale.General.Future)}
      />
    </>
  );

  const MobileView = () => (
    <Tabs
      type="card"
      className="activity-overview-tabs"
      activeKey={activeTab}
      onChange={e => setActiveTab(e)}
      items={[
        {
          key: '1',
          label: intl.formatMessage(Locale.General.Previous),
          children: <ActivityColumn
            connection={data?.user?.previous}
          />
        },
        {
          key: '2',
          label: intl.formatMessage(Locale.General.Today),
          children: <ActivityColumn
            connection={get(data, 'user.today')}
          />,
        },
        {
          key: '3',
          label: intl.formatMessage(Locale.General.Future),
          children: <ActivityColumn
            connection={get(data, 'user.next')}
          />
        }
      ]}
    />
  );

  const content = loading
    ? <Loading />
    : (
      <>
        {
          isDesktop ? <DesktopView /> : <MobileView />
        }
      </>
    );

  return (
    <MainView
      title={<OverviewTitle />}
      rightActions={(
        <OverviewMenu
          onChange={onMenuChange}
          isCompletedActive={isCompletedSelected}
        />
      )}
      className="activities-overview-container"
    >
      <div className="overview-columns">
        {content}
      </div>
      <EstherEasterEgg />
    </MainView>
  );
};

const DATA_QUERY = gql`
  query ActivityOverview(
    $limit: Int,
    $excludePreviousStatuses: [ActivityStatusEnum!],
    $responsibleUserId: Int!,
    $startDate: DateTime!,
    $endDate: DateTime!,
    $personLimit: Int,
    $pastActivityOrder: [ActivityCriteriaOrder!]!,
    $todayActivityOrder: [ActivityCriteriaOrder!],
    $futureActivityOrder: [ActivityCriteriaOrder!],
  ) {
    user(userId: $responsibleUserId) {
      id
      firstname
      fullname
      previous: activities(criteria: {
        ordering: $pastActivityOrder,
        startDate: { from: null, to: $startDate },
        statusNotIn: $excludePreviousStatuses,
        fetchSize: {limit: $limit }
      }) {
        ...activityConnectionOverview
      }
      today: activities(criteria: {
        ordering: $todayActivityOrder,
        startDate: {
          from: $startDate,
          to: $endDate
        },
        fetchSize: { limit: $limit }
      }) {
        ...activityConnectionOverview
      }
      next: activities(criteria: {
        ordering: $futureActivityOrder,
        startDate: { from: $endDate, to: null },
        fetchSize: { limit: $limit }
      }) {
        ...activityConnectionOverview
      }
    }
  }

  fragment activityConnectionOverview on ActivityConnection {
    hash
    totalCount
    nodes {
      id
      heading
      status {
        code
        headingKey
        codeEnum
      }
      time: startDate
      end: endDate
      type {
        label: heading
        code
        color {
          code
          value
        }
      }
      sites {
        hash
        nodes {
          id
          name
          visitAddress {
            id
            city
            commune {
              code
              label
              type
            }
            street
            postalCode
          }
          siteTypeCode
          phone: mainContact(type: PHONE) { id, value }
          fax: mainContact(type: FAX) { id, value }
          email: mainContact(type: EMAIL) { id, value }
        }
      }
      ...activityConnectionOverviewPersons
    }
  }

  fragment activityConnectionOverviewPersons on Activity {
    persons(criteria:{fetchSize:{limit: $personLimit}}) {
      hash
      totalCount
      nodes {
        id
        fullName
        title
      }
    }
  }
`;

const ME_QUERY = gql`
  query UserActivityOverView {
    viewer {
      id
      user {
        id
        firstname
      }
    }
  }
`;

export default ActivityDashboard;
