import React from 'react';
import { Skeleton } from 'antd';


type SingleLineSkeletonProps = {
  loading?: boolean;
  blink?: boolean;
  width?: number;
  centered?: boolean;
  style?: React.CSSProperties;
};

const SingleLineSkeleton: React.FC<SingleLineSkeletonProps> = props => (
  <Skeleton
    className={`single-line-skeleton-container width-${props.width} ${props.centered ? 'centered' : ''}`}
    loading={props.loading}
    active={props.blink}
    paragraph={false}
    // @ts-ignore
    style={props.style}
  >
    {props.children ?? ''}
  </Skeleton>
);

SingleLineSkeleton.defaultProps = {
  loading: false,
  blink: true,
  width: 240,
  centered: false,
};

export default SingleLineSkeleton;
