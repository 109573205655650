import { TableFieldsConfig } from '../search_old/types';
import { personSelectionRowRowIdFieldConfig } from './fields/personSelectionRowRowIdFieldConfig';
import { personSelectionRowCustomerNameFieldConfig } from './fields/personSelectionRowCustomerNameFieldConfig';
import {
  personSelectionRowSelectionGroupDescriptionFieldConfig
} from './fields/personSelectionRowSelectionGroupDescriptionFieldConfig';
import { personSelectionRowSelectionDescriptionFieldConfig } from './fields/personSelectionRowSelectionDescriptionFieldConfig';
import { personSelectionRowSelectionTypeFieldConfig } from './fields/personSelectionRowSelectionTypeFieldConfig';
import { personSelectionRowAddedFieldConfig } from './fields/personSelectionRowAddedFieldConfig';

export type SupportedPersonSelectionRowTableColumns =
  |'rowId'
  |'customerName'
  |'selectionGroupDescription'
  |'selectionDescription'
  |'selectionType'
  |'added';

export const supportedPersonSelectionRowTableColumns: TableFieldsConfig<SupportedPersonSelectionRowTableColumns>['fields'] = {
  rowId: personSelectionRowRowIdFieldConfig,
  customerName: personSelectionRowCustomerNameFieldConfig,
  selectionGroupDescription: personSelectionRowSelectionGroupDescriptionFieldConfig,
  selectionDescription: personSelectionRowSelectionDescriptionFieldConfig,
  selectionType: personSelectionRowSelectionTypeFieldConfig,
  added: personSelectionRowAddedFieldConfig,
};

