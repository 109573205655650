import React, { useEffect, useState } from 'react';
import { Breadcrumb, Col, Input, Typography } from 'antd';
import { Layout } from 'react-grid-layout';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toNumber } from 'lodash';
import { useDebounce } from 'ahooks';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { StateArray } from '../../../../util/StateArrayType';
import AddCard from '../../../../components/ContactCard/AddCard';
import AddNewGridItemConfigurationRightTab from './AddNewGridItemConfigurationRightTab';
import AdminGridRightFieldsConfigurator from './AdminGridRightFieldsConfigurator/AdminGridRightFieldsConfigurator';
import AdminGridRightCardsConfigurator from './AdminGridRightCardsConfigurator/AdminGridRightCardsConfigurator';
import AdminGridRightRelationsConfigurator from './AdminGridRightRelationsConfigurator/AdminGridRightRelationsConfigurator';
import {
  EntityGridItemTypeEnum,
  EntityTypeEnum,
  SelectedGridItemQueryQuery,
  SelectedGridItemQueryQueryVariables, UpdateGridConfigurationHeadingMutation, UpdateGridConfigurationHeadingMutationVariables
} from '../../../../../gql/typings';


type AdminGridConfigurationRightProps = {
  entityType: EntityTypeEnum;
  entityTabCode: string;
  selectedCardState: StateArray<Layout|undefined>;
};

export type AdminGridConfigurationItemProps = {
  layout: Layout;
  entityType: EntityTypeEnum;
  tabCode: string;
  itemConfigurationId: number;
};

const typeViews: Record<EntityGridItemTypeEnum, React.FC<AdminGridConfigurationItemProps>> = {
  FIELDS: AdminGridRightFieldsConfigurator,
  RELATIONS: AdminGridRightRelationsConfigurator,
  CARDS: AdminGridRightCardsConfigurator,
};

const AdminGridConfigurationRight: React.FC<AdminGridConfigurationRightProps> = ({
  entityType,
  entityTabCode,
  selectedCardState,
}) => {
  const localization = useLocalization();
  const showAddCardState = useState(false);
  const cardTitleInputState = useState('');
  const deferredTitleInput = useDebounce(cardTitleInputState[0]);
  const [showAddCard, setShowAddCard] = showAddCardState;
  const [selectedCard] = selectedCardState;
  const { data } = useQuery<SelectedGridItemQueryQuery, SelectedGridItemQueryQueryVariables>(DATA_QUERY, {
    variables: { gridItemId: selectedCard ? toNumber(selectedCard.i) : -1 },
    skip: !selectedCard,
  });
  const [
    updateHeading,
  ] = useMutation<UpdateGridConfigurationHeadingMutation, UpdateGridConfigurationHeadingMutationVariables>(UPDATE_CARD_HEADING);

  useEffect(() => {
    cardTitleInputState[1](data?.entityGridItemConfiguration?.heading ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const conf = data?.entityGridItemConfiguration;
    if (conf && deferredTitleInput != conf.heading) updateHeading({
      variables: {
        id: conf.id,
        heading: deferredTitleInput,
        description: conf.description,
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deferredTitleInput]);

  useEffect(() => {
    if (showAddCard && selectedCard) {
      setShowAddCard(false);
    }
  }, [showAddCard, selectedCard, setShowAddCard]);

  const props = {
    selectedCardState,
    showAddCardState,
    cardTitleInputState,
    data,
  };

  if (showAddCardState[0]) return (
    <Wrapper {...props}>
      <AddNewGridItemConfigurationRightTab tabCode={entityTabCode} />
    </Wrapper>
  );

  if (!selectedCardState[0]) return (
    <Wrapper
      {...props}
      paragraph={localization.formatMessage(Locale.Text.Entity_grid_layout_right_description_none_selected)}
    >
      <AddCard
        label={localization.formatMessage(Locale.Text.Grid_Field_Configuration_Add_New_Group)}
        onClick={() => showAddCardState[1](true)}
      />
    </Wrapper>
  );

  if (!data?.entityGridItemConfiguration?.type.code) return (
    <Wrapper {...props}>
      Loading...
    </Wrapper>
  );

  const ViewToRender = typeViews[data.entityGridItemConfiguration.type.enum];

  return (
    <Wrapper {...props} editable>
      {!ViewToRender
        ? <span>No view implemented for grid item type of '{data?.entityGridItemConfiguration.type.code}'</span>
        : (
          <ViewToRender
            layout={selectedCardState[0]}
            entityType={entityType}
            tabCode={entityTabCode}
            itemConfigurationId={toNumber(selectedCardState[0]!.i)}
          />
        )}
    </Wrapper>
  );
};

const Wrapper: React.FC<{
  title?: string|null;
  editable?: boolean;
  paragraph?: string;
  selectedCardState: StateArray<Layout|undefined>;
  showAddCardState: StateArray<boolean>;
  cardTitleInputState: StateArray<string>;
  data: SelectedGridItemQueryQuery|undefined;
}> = React.memo(({
  title,
  paragraph,
  editable,
  selectedCardState: [selectedCard, setSelectedCard],
  showAddCardState: [showAddCard, setShowAddCard],
  cardTitleInputState: [cardTitleInput, setCardTitleInput],
  data,
  children,
}) => {
  const localization = useLocalization();
  const breadcrumbs = [
    <Breadcrumb.Item
      key="root"
      {...(selectedCard || showAddCard) ? { href: '#' } : {}}
      onClick={() => {
        setSelectedCard(undefined);
        setShowAddCard(false);
      }}
    >
      {localization.formatMessage(Locale.General.Home)}
    </Breadcrumb.Item>
  ];
  if (selectedCard && data?.entityGridItemConfiguration) breadcrumbs.push(
    <Breadcrumb.Item key="fields">
      Configure card
    </Breadcrumb.Item>
  );
  if (showAddCard) breadcrumbs.push(
    <Breadcrumb.Item key="new-group">
      Choose group
    </Breadcrumb.Item>
  );
  return (
    <Col className="admin-grid-configuration-right-container" span={6}>
      <Breadcrumb className="crumbs">
        {breadcrumbs}
      </Breadcrumb>
      <div className="title-and-children">
        {editable
          ? <Input size="large" value={cardTitleInput} onChange={e => setCardTitleInput(e.target.value)} addonBefore="Title:" />
          : <Typography.Title level={4}>{title}</Typography.Title>}
        {paragraph && <Typography.Paragraph style={{ textAlign: 'center' }}>{paragraph}</Typography.Paragraph>}
        {children}
      </div>
    </Col>
  );
});

const UPDATE_CARD_HEADING = gql`
  mutation UpdateGridConfigurationHeading($id: Int!, $heading: String!, $description: String) {
    updateEntityGridItemConfiguration(id: $id, heading: $heading, description: $description) {
      id
      heading
      description
    }
  }
`;

const DATA_QUERY = gql`
  query SelectedGridItemQuery($gridItemId: Int!) {
    entityGridItemConfiguration(id: $gridItemId) {
      id
      heading
      description
      type {
        code
        enum
        headingKey
        descriptionKey
      }
      # This is only used for the type code of 'FIELDS'
      fields {
        hash
        totalCount
        nodes {
          id
        }
      }
    }
  }
`;

export default AdminGridConfigurationRight;
