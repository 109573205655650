import React from 'react';
import { CustomFieldTypeEnum } from '../../../../../gql/typings';
import CustomTextInput from './CustomTextInput';
import CustomTagInput from './CustomTagInput';
import CustomNumberInput from './CustomNumberInput';
import CustomSwitchInput from './CustomSwitchInput';
import CustomSelectInputMultiSupport from './CustomSelectInputMultiSupport';
import { TableFieldUpdateViewProps } from '../../../search_old/types';
import CustomCheckInputMultiSupport from './CustomCheckInputMultiSupport';

export const customFieldTypes: Record<CustomFieldTypeEnum, React.FC<TableFieldUpdateViewProps>> = {
  CHECK: CustomCheckInputMultiSupport,
  NUMBER: CustomNumberInput,
  SWITCH: CustomSwitchInput,
  TAG: CustomTagInput,
  TEXT: CustomTextInput,
  SELECT: CustomSelectInputMultiSupport,
};
