import React, { useState } from 'react';
import { Tooltip } from 'antd';
import Dropdown from '../Form/Dropdown';
import Icon, { ITE } from '../Icon/Icon';

export type StatusIconProps = {
  title?: React.ReactNode;
  type?: ITE;
  editMode?: boolean;
  tooltip?: string;
  onSelect?: () => void;
  selectedKey?: string|number;
  items?: {
    key: string|number;
    label: string;
  }[];
  visible?: boolean;
};

const StatusIcon: React.FC<StatusIconProps> = ({
  title,
  onSelect,
  selectedKey,
  items,
  type = 'STATUS_ICON_GREY',
  editMode = true,
  visible = true,
  tooltip,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  if (!visible) return <></>;

  const text = tooltip
    ? <Tooltip title={tooltip}>{title}</Tooltip>
    : title;


  const iconAndText = (
    <div>
      <Icon text={text} iconType={type} />
    </div>
  );


  if (items && editMode) {
    return (
      <div className="status-icon-container cursor-pointer">
        <Dropdown
          allowSearch={false}
          selectedKey={selectedKey}
          onSelect={onSelect}
          active={showDropdown}
          // @ts-ignore TODO: Convert Dropdown to tsx or move to standard Antd dropdown
          onVisibleChange={setShowDropdown}
          items={items}
        >
          {iconAndText}
        </Dropdown>
      </div>
    );
  }

  return (
    <div className="status-icon-container">
      {iconAndText}
    </div>
  );
};

export default StatusIcon;
