import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { List, Modal } from 'antd';
import { NavLink } from 'react-router-dom';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import {
  SiteEntityMergeActivityModalQuery, SiteEntityMergeActivityModalQueryVariables
} from '../../../../../../../../../gql/typings';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';

type AdminMergeItemSiteActivitiesModalProps = {
  open: boolean;
  close: () => void;
  activityIds: number[];
};

const AdminMergeItemSiteActivitiesModal: React.FC<AdminMergeItemSiteActivitiesModalProps> = ({
  open,
  close,
  activityIds,
}) => {
  const localization = useLocalization();
  const {
    data,
    loading,
  } = useQuery<SiteEntityMergeActivityModalQuery, SiteEntityMergeActivityModalQueryVariables>(DATA_QUERY, {
    variables: { activityIds },
  });


  return (
    <Modal
      open={open}
      onCancel={close}
      footer={<></>}
    >
      {localization.pluralMessage(Locale.Text.Merge_request_activities_move_modal, activityIds.length)}
      <List
        loading={loading}
        dataSource={data?.activities.nodes ?? []}
        renderItem={activity => (
          <List.Item>
            <NavLink to={`/activity/${activity.id}`} target="_blank">
              {activity.heading}
            </NavLink>
          </List.Item>
        )}
      />
    </Modal>
  );
};

const DATA_QUERY = gql`
  query SiteEntityMergeActivityModal($activityIds: [ID!]!) {
    activities(criteria: { ids: $activityIds }) {
      hash
      nodes {
        id
        heading
      }
    }
  }
`;

export default AdminMergeItemSiteActivitiesModal;
