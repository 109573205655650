import React from 'react';
import { useIntl } from 'react-intl';
import MainView from '../../components/View/MainView';
import { EntitiesSearch } from '../../components/entitiesSearch';
import { usePageTitle } from '../../hooks/usePageTitle';
import { Locale } from '../../../localization/LocalizationKeys';
import { EntityTypeEnum } from '../../../gql/typings';


const DcrView = () => {
  const { formatMessage } = useIntl();
  usePageTitle(formatMessage(Locale.General.Data_Change_Requests_DCR));

  return (
    <MainView
      className="dcr-view-container"
      boundingBox={false}
    >
      <EntitiesSearch
        entityType={EntityTypeEnum.DCR_GROUP}
        urlSearchEnabled
      />
    </MainView>
  );
};

export default DcrView;
