import React from 'react';
import { gql } from '@apollo/client';
import { Select } from 'antd';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import {
  MergeItemPersonResponsibleAreasFragment, UsersListOverviewQuery,
  useUpdateResponsibleUserForPersonMutation, useUsersListOverviewQuery
} from '../../../../../../../../../gql/typings';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';

const AdminMergeItemPersonResponsibleAreas: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonResponsibleAreasFragment;
  const localization = useLocalization();
  const [updateResponsibleArea] = useUpdateResponsibleUserForPersonMutation();
  const { data } = useUsersListOverviewQuery({
    variables: {
      criteria: {
        fetchSize: { limit: 1000 },
        isLocked: null,
      }
    }
  });
  const map: Record<number, NonNullable<UsersListOverviewQuery['users']>['nodes']['0']> = data
    ?.users
    .nodes
    .reduce((acc, curr) => ({ ...acc, [curr.id]: curr, }), {})
    ?? {};

  if (!props.item.hasConflict) return (
    <AdminMergeItemBasicField {...props}>
      {localization.formatMessage(Locale.Text.Merge_responsible_area_description, {
        area: <strong>{info.responsibleArea.heading}</strong>,
        user: <strong>{info.fromUser.fullname}</strong>,
      })}
    </AdminMergeItemBasicField>
  );

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.fromUser.id}
      toValue={info.toUser?.id}
      valueDisplayMapper={id => id ? map[id]?.fullname : ''}
      inputRenderer={props => (
        <Select
          {...props}
          options={data?.users.nodes.map(user => ({ value: user.id, label: user.fullname }))}
        />
      )}
      updateMutation={userId => updateResponsibleArea({
        variables: {
          userId,
          areaId: info.responsibleArea.id,
          recordId: info.toUser!.id,
        }
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_responsible_areas_mismatch_description, {
        area: <strong>{info.responsibleArea.heading}</strong>,
        user1: <strong>{info.fromUser.fullname}</strong>,
        user2: <strong>{info.toUser?.fullname}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  fragment MergeItemPersonResponsibleAreas on PersonResponsibleArea_EntityDataToBeTransferredType {
    code
    dataType
    entityType
    responsibleArea {
      id
      heading
    }
    fromUser {
      id
      fullname
      email
    }
    toUser {
      id
      fullname
      email
    }
  }
`;

gql`
  mutation UpdateResponsibleUserForPerson($recordId: Int!, $areaId: Int!, $userId: Int!) {
    upsertResponsibleArea(persId: $recordId, areaId: $areaId, userId: $userId) {
      id
    }
  }
`;

gql`
  query UsersListOverview($criteria: UserCriteria!) {
    users(criteria: $criteria) {
      hash
      nodes {
        id
        fullname
      }
    }
  }
`;

export default AdminMergeItemPersonResponsibleAreas;
