import { selectionRowResultFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { Locale } from '../../../../localization/LocalizationKeys';

export const selectionRowResultCustomerNameFieldConfig = selectionRowResultFieldConfig(
  'customerName',
  [
    'selectionRow.id',
    'selectionRow.group.id',
    'selectionRow.group.selection.id',
    'selectionRow.group.selection.customer.code',
    'selectionRow.group.selection.customer.callName',
    'selectionRow.group.selection.customer.name',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Customer),
    render: record => {
      if (!record.selectionRow) return localization.formatMessage(Locale.General.Unknown);
      const { customer } = record.selectionRow.group?.selection;
      return customer.callName ?? customer.name;
    },
  }),
);
