import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { Button } from 'antd';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import {
  EntityMergeFragment_Person_Fragment,
  MergeItemPersonActivitiesFragment
} from '../../../../../../../../../gql/typings';
import AdminMergeItemPersonActivitiesModal from './AdminMergeItemPersonActivitiesModal';
import { Optional } from '../../../../../../../../util/StateArrayType';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';


const AdminMergeItemPersonActivities: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonActivitiesFragment;
  const fromPerson = props.from as EntityMergeFragment_Person_Fragment;
  const toPerson = props.into as Optional<EntityMergeFragment_Person_Fragment>;
  const localization = useLocalization();
  const [open, setOpen] = useState(false);


  return (
    <AdminMergeItemBasicField {...props}>
      {localization.pluralMessage(Locale.Text.Merge_request_activities_move_description, info.activityIds.length, {
        name1: <strong>{fromPerson.fullName}</strong>,
        name2: <strong>{toPerson?.fullName}</strong>,

      })}
      <Button type="link" onClick={() => setOpen(true)}>[{localization.formatMessage(Locale.Command.Show)}]</Button>
      <AdminMergeItemPersonActivitiesModal
        open={open}
        close={() => setOpen(false)}
        activityIds={info.activityIds}
      />
    </AdminMergeItemBasicField>
  );
};

gql`
  fragment MergeItemPersonActivities on PersonActivity_EntityDataToBeTransferredType {
    code
    entityType
    activityIds
  }
`;

export default AdminMergeItemPersonActivities;
