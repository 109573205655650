import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Checkbox, Col, Row } from 'antd';
import { useIntl } from 'react-intl';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { EntityTabFieldProps } from './EntityTabFieldsTypes';
import {
  PersonConsentTabQueryQuery,
  PersonConsentTabQueryQueryVariables,
  UpdatePersonConsentTabMutationMutation, UpdatePersonConsentTabMutationMutationVariables
} from '../../../../../gql/typings';

const PersonConsentTabField: React.FC<EntityTabFieldProps> = (props) => {
  const localization = useLocalization();
  const intl = useIntl();
  const { data } = useQuery<PersonConsentTabQueryQuery, PersonConsentTabQueryQueryVariables>(DATA_QUERY, {
    variables: { personId: props.recordId },
  });
  const [update] = useMutation<UpdatePersonConsentTabMutationMutation, UpdatePersonConsentTabMutationMutationVariables>(
    UPDATE_CONSENT_MUTATION
  );

  const doUpdate = (consentTypeCode: string) => (e: CheckboxChangeEvent) => update({
    variables: {
      consentTypeCode,
      personId: props.recordId,
      enabled: e.target.checked,
    }
  });

  return <Row style={{ width: '100%' }}>
    <Col className="custom-field-heading" span={8}>{intl.formatMessage(Locale.General.Consent_Given)}</Col>
    <Col span={16}>
      <Row gutter={[3, 3]}>
        {(data?.person?.consents ?? []).map(consent => (
          <Col key={consent.consentTypeCode} span={24}>
            <Checkbox
              onChange={doUpdate(consent.consentTypeCode)}
              checked={consent.isEnabled}
              disabled={consent.type.isManaged}
            />
            <span className="custom-field-consent-value">
              {localization.formatMessageByStr(consent.type.headingKey)}
            </span>
          </Col>
        ))}
      </Row>
    </Col>
  </Row>;
};


const DATA_QUERY = gql`
  query PersonConsentTabQuery($personId: Int!) {
    person(id: $personId) {
      id
      consents: virtualConsents {
        entityType
        entityAffiliationId
        consentTypeCode
        isEnabled
        type {
          code
          headingKey
          isManaged
        }
      }
    }
  }
`;

const UPDATE_CONSENT_MUTATION = gql`
  mutation UpdatePersonConsentTabMutation($personId: Int!, $consentTypeCode: String!, $enabled: Boolean!) {
    setConsentValue(entityAffiliationId: $personId, typeCode: $consentTypeCode, isEnabled: $enabled) {
      entityType
      entityAffiliationId
      consentTypeCode
      type { code, headingKey }
      isEnabled
    }
  }
`;

export default PersonConsentTabField;
