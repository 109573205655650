import { RefObject, useRef } from 'react';
import { useEventEmitter } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';


export type MergeEventValue = {
  key: string;
  state: 'finished'|'in_progress'|'not_started'|'skip'|'removed';
  onSave?: () => Promise<unknown>;
  _source?: string;
};
export interface MergeCustomEvent {
  key: string;
}
export type MergeEventsState = Record<string, MergeEventValue>;

export type MergeEventEmitter = {
  stateRef: RefObject<MergeEventsState>;
  $: EventEmitter<MergeEventValue>;
  customEmitter: EventEmitter<MergeCustomEvent>;
  canSave: () => boolean;
};

export const useEntityMergeEmitter = (): MergeEventEmitter => {
  const stateRef = useRef<MergeEventsState>({});
  const emitter = useEventEmitter<MergeEventValue>();
  const customEmitter = useEventEmitter<MergeCustomEvent>();

  emitter.useSubscription(v => {
    stateRef.current = ({ ...stateRef.current, [v.key]: v });
  });

  const canSave = () => !Object.values(stateRef.current ?? {})
    .find(e => e.state === 'not_started' || e.state === 'in_progress');

  return { $: emitter, customEmitter, stateRef, canSave };
};
