import React, { useState } from 'react';
import { Col, Space, Tooltip } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import DatePicker from './DatePicker';
import { StateArray } from '../../util/StateArrayType';
import Icon from '../Icon/Icon';
import { DATE_FORMAT, DATE_FORMAT_DAY } from '../../util/format';

type TimeSpanProps = {
  startState: StateArray<Dayjs|null>;
  endState: StateArray<Dayjs|null>;
  dayWithYearFormat?: string;
  dateFormat?: string;
  hourFormat?: string;
  hasEditAccess?: boolean;
  onSave?: (() => void) | undefined;
  onCancel?: (() => void) | undefined;
  editState?: [boolean, ((value: boolean) => void) | null];
};

const TimeSpan: React.FC<TimeSpanProps> = ({
  dateFormat = DATE_FORMAT_DAY,
  hourFormat = 'HH:mm',
  dayWithYearFormat = DATE_FORMAT,
  startState,
  endState,

  hasEditAccess = false,
  onSave,
  onCancel,

  editState: [edit, setEditMode] = useState<boolean>(false),
}) => {
  const mStart = dayjs(startState[0]);
  const mEnd = dayjs(endState[0]);
  const trigger = () => {
    onSave?.();
    setEditMode?.(false);
  };

  return (
    <div className="date-timespan-container">
      {!edit
        ? (
          !mStart.isSame(mEnd, 'date')
            ? <div className="start">
              {hasEditAccess
                ? <Col className="activity-edit-main-view" onClick={() => setEditMode?.(true)}>
                  Start: {mStart.format(dateFormat)}
                  {' '}
                  @
                  {' '}
                  {mStart.format(hourFormat)}
                  {' '}
                  <br />
                  End: {mEnd.format(dateFormat)}
                  {' '}
                  @
                  {' '}
                  {mEnd.format(hourFormat)}
                  {' '}
                </Col>
                : <Col>
                  {mStart.format(dayWithYearFormat)}
                  {' '}
                  {mStart.format(hourFormat)}
                  {' - '}
                  {mEnd.format(dayWithYearFormat)}
                  {' '}
                  {mEnd.format(hourFormat)}
                </Col>}


            </div>
            : <div className="end">
              {hasEditAccess
                ? <Col className="activity-edit-main-view" onClick={() => setEditMode?.(true)}>
                  {mStart.format(dateFormat)}
                  {' '}
                  @
                  {' '}
                  {mStart.format(hourFormat)}
                  {' '}
                  -
                  {' '}
                  {mEnd.format(hourFormat)}
                </Col>
                : <Col>
                  {`${mStart.format(dayWithYearFormat)} (${mStart.format(hourFormat)} - ${mEnd.format(hourFormat)})`}
                </Col>}
            </div>
        )
        : (
          <div className="timespan-fields-container">
            <div className="subTitle-date-inputs">
              <DatePicker startDateState={startState} endDateState={endState} spanStart={6} spanEnd={18} />
            </div>
            <div className="subTitle-date-inputs-actions">
              <Space>
                {setEditMode && onSave && <Tooltip title="Update Activity Date">
                  <Icon style={{ fontSize: '22px' }} iconType="CHECK_OUTLINED" onClick={trigger} />
                </Tooltip>}
                {setEditMode && <Tooltip title="Cancel">
                  <Icon style={{ fontSize: '22px' }} iconType="CANCEL_OUTLINED" onClick={() => setEditMode(false)} />
                </Tooltip>}
              </Space>

            </div>
          </div>
        )}
      <div className="clearfix" />
    </div>
  );
};

export default TimeSpan;
