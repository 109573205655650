import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import Icon, { ITE } from '../Icon/Icon';

type Props = Omit<InputProps, 'suffix'> & {
  suffix: ITE;
};

const AdvanceInputOnly: React.FC<Props> = ({ suffix, ...rest }) => (
  <Input
    className="advance-input-only-container"
    suffix={<Icon iconType={suffix} />}
    autoComplete="off"
    {...rest}
  />
);


export default AdvanceInputOnly;

