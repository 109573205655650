import React from 'react';
import { gql } from '@apollo/client/core';
import { EntityTabCardProps } from './EntityTabTypes';
import EntityTabFieldsCard from './Fields/EntityTabFieldsCard';
import EntityTabCards from './Cards/EntityTabCards';
import EntityRelationTabs from './RelationTabs/EntityRelationTabs';
import { EntityGridItemTypeEnum } from '../../../gql/typings';

const types: Record<EntityGridItemTypeEnum, React.FC<EntityTabCardProps>> = {
  FIELDS: EntityTabFieldsCard,
  CARDS: EntityTabCards,
  RELATIONS: EntityRelationTabs,
};

const TabCardConfiguration: React.FC<EntityTabCardProps> = (props) => {
  const ToDisplay = types[props.configuration.typeEnum]!;
  return (
    <ToDisplay {...props} />
  );
};

export const TabCardConfiguration_FRAGMENT = gql`
  fragment TabCardConfigurationFragment on EntityGridItemConfiguration {
    id
    heading
    typeEnum
    height
    width
    type {
      code
      headingKey
      minHeight
      minWidth
    }
  }
`;

export default TabCardConfiguration;
