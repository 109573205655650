import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Badge, Form, InputNumber, message, Modal, Select, Switch, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import AnchoredSections, { AnchoredItem } from '../../adminComponents/AdminSection/AnchoredSections';
import Flag from '../../../../components/Flag/Flag';
import {
  AddFieldDayMutationMutation,
  AddFieldDayMutationMutationVariables,
  RemoveFieldDayMutationMutation,
  RemoveFieldDayMutationMutationVariables,
  ReportCallFreqDataQueryQuery,
  SetDailyTargetMutationMutation,
  SetDailyTargetMutationMutationVariables,
  SetHalfDayMutationMutation,
  SetHalfDayMutationMutationVariables
} from '../../../../../gql/typings';

const ReportCallFrequencyAnchoredSettings: React.FC = () => {
  const localization = useLocalization();
  const {
    data,
    loading: dataLoading,
    refetch,
  } = useQuery<ReportCallFreqDataQueryQuery>(DATA_QUERY);
  const [
    updateHalfDay,
    { loading: halfDayLoading },
  ] = useMutation<SetHalfDayMutationMutation, SetHalfDayMutationMutationVariables>(HALF_DAY_MUTATION);
  const [
    setDailyTarget,
    // { loading: dailyTargetLoading },
  ] = useMutation<SetDailyTargetMutationMutation, SetDailyTargetMutationMutationVariables>(DAILY_TARGET_MUTATION);
  const [
    addFieldDay,
    { loading: addFieldDayLoading },
  ] = useMutation<AddFieldDayMutationMutation, AddFieldDayMutationMutationVariables>(ADD_FIELD_DAY_MUTATION);
  const [
    removeFieldDay,
    // { loading: removeFieldDayLoading },
  ] = useMutation<RemoveFieldDayMutationMutation, RemoveFieldDayMutationMutationVariables>(REMOVE_FIELD_DAY_MUTATION);
  const [showModal, setShowModal] = useState(false);
  const [selectedActivityTypeCode, setSelectedActivityTypeCode] = useState<string>();
  const [amountInput, setAmountInput] = useState(1);

  return (
    <div className="report-admin-settings-container">
      <AnchoredSections>
        <AnchoredItem anchor="description" title={localization.formatMessage(Locale.Attribute.Description)}>
          <i>Description missing</i>
        </AnchoredItem>
        <AnchoredItem anchor="half-day" title="Half Day">
          {localization.formatText(Locale.Text.Call_frequency_report_half_day_description)}
          <br /><br />
          {localization.formatText(Locale.Text.Half_day_based)}:&nbsp;
          <Switch
            checked={data?.reportSettingIsHalfDay}
            loading={dataLoading || halfDayLoading}
            onChange={isHalfDay => updateHalfDay({ variables: { isHalfDay } }).finally(() => refetch())}
          />
        </AnchoredItem>
        <AnchoredItem anchor="daily-target" title="Daily Target">
          {localization.formatText(Locale.Text.Call_frequency_report_daily_target_configuration)}
          {(data?.reportSettingDailyCallTarget ?? []).map(target => (
            <div key={target.id}>
              <Flag
                country={target.country}
                style={{ margin: '8px 16px 8px 0' }}
                rectangle
              />
              <InputNumber
                defaultValue={target.amount}
                onChange={amount => setDailyTarget({
                  variables: { amount: amount as number, country: target.country }
                }).finally(() => refetch())}
                min={0}
                max={100}
              />
            </div>
          ))}
        </AnchoredItem>
        <AnchoredItem anchor="activity-types" title="Activity Types">
          {(data?.reportSettingFieldDays.nodes ?? []).map(conf => (
            <Tag
              key={conf.id}
              color={conf.activityType.colorCode}
              style={{ padding: 4, margin: 4 }}
              closable
              onClose={() => removeFieldDay({ variables: { id: conf.id } })
                .then(() => message.success('Successfully removed activity type'))
                .finally(() => refetch())}
            >
              {conf.activityType.heading}
              &nbsp;&nbsp;
              <Badge count={conf.amountForOne} size="small" />
            </Tag>
          ))}
          <Tag style={{ padding: 4, margin: 4, cursor: 'pointer' }} onClick={() => setShowModal(true)}>
            {localization.formatMessage(Locale.Command.Add_activity_type)} <PlusOutlined />
          </Tag>
          <Modal
            open={showModal}
            onCancel={() => setShowModal(false)}
            title={localization.formatMessage(Locale.Command.Add_activity_type)}
            okText={localization.formatMessage(Locale.Command.Add)}
            cancelText={localization.formatMessage(Locale.Command.Cancel)}
            okButtonProps={{ disabled: !selectedActivityTypeCode, loading: addFieldDayLoading }}
            onOk={() => addFieldDay({
              variables: {
                activityTypeCode: selectedActivityTypeCode!,
                amountForOne: amountInput,
              },
            }).finally(() => {
              setShowModal(false);
              refetch();
            })}
          >
            <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} labelAlign="left">
              <Form.Item label={localization.formatMessage(Locale.Attribute.Activity_type)}>
                <Select style={{ width: '100%' }} value={selectedActivityTypeCode} onChange={setSelectedActivityTypeCode}>
                  {(data?.activitySuperTypes.nodes ?? []).map(superType => (
                    <Select.OptGroup key={superType.code} label={superType.heading}>
                      {superType.types.nodes.map(type => (
                        <Select.Option
                          key={type.code}
                          disabled={data?.reportSettingFieldDays.nodes.map(e => e.activityTypeCode).includes(type.code)}
                        >
                          {type.heading}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Weight">
                <InputNumber min={0} max={100} value={amountInput} onChange={e => setAmountInput(e as number)} />
              </Form.Item>
            </Form>
          </Modal>
        </AnchoredItem>
        <AnchoredItem anchor="field-days" title="Field Days">
          {localization.formatText(
            Locale.Text.Report_based_on_field_days_configuration,
            {
              here: <NavLink to="/admin/entity/activity#field-days">
                {localization.formatMessage(Locale.General.Here).toLowerCase()}
              </NavLink>,
            },
          )}
        </AnchoredItem>
      </AnchoredSections>
    </div>
  );
};

const DATA_QUERY = gql`
  query ReportCallFreqDataQuery {
    reportSettingIsHalfDay(reportCode: "No3")
    reportSettingDailyCallTarget(reportCode: "No3") {
      id
      country
      amount
    }
    reportSettingFieldDays(criteria: { reportCode: "No3" }) {
      hash
      nodes {
        id
        activityTypeCode
        amountForOne
        activityType {
          code
          heading
          colorCode
        }
      }
    }
    activitySuperTypes {
      hash
      nodes {
        code
        heading
        types {
          hash
          nodes {
            code
            heading
          }
        }
      }
    }
  }
`;

const HALF_DAY_MUTATION = gql`
  mutation SetHalfDayMutation($isHalfDay: Boolean!) {
    setReportSettingIsHalfDay(reportCode: "No3", isHalfDay: $isHalfDay)
  }
`;

const DAILY_TARGET_MUTATION = gql`
  mutation SetDailyTargetMutation($country: CountryCode!, $amount: Int!) {
    setReportSettingDailyCallTarget(reportCode: "No3", country: $country, amount: $amount) {
      id
      country
      amount
    }
  }
`;

const ADD_FIELD_DAY_MUTATION = gql`
  mutation AddFieldDayMutation($activityTypeCode: String!, $amountForOne: Int!) {
    addReportSettingFieldDay(reportCode: "No3", activityTypeCode: $activityTypeCode, amountForOne: $amountForOne) {
      id
      activityTypeCode
      amountForOne
    }
  }
`;

const REMOVE_FIELD_DAY_MUTATION = gql`
  mutation RemoveFieldDayMutation($id: Int!) {
    removeReportSettingFieldDay(id: $id)
  }
`;


export default ReportCallFrequencyAnchoredSettings;
