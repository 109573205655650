import React, { CSSProperties } from 'react';

type ColoredCircleProps = {
  type?: 'primary'|'default';
  size?: 'small'|'medium'|'large';
  className?: string;
  style?: CSSProperties;
};

export const ColoredCircle: React.FC<ColoredCircleProps> = ({
  type = 'default',
  size = 'medium',
  children,
  className,
  style,
}) => (
  <div className={`colored-circle-container ${type} ${size} ${className}`.trim()} style={style}>
    {children}
  </div>
);
