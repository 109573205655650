import React from 'react';
import { Col, Row } from 'antd';
import * as PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ColSize } from 'antd/lib/grid/col';
import dayjs, { Dayjs } from 'dayjs';
import Duration from 'dayjs/plugin/duration';
import DateTimePicker from './DateTimePicker';
import { Locale } from '../../../localization/LocalizationKeys';
import { StateArray } from '../../util/StateArrayType';

type DatePickerProps = {
  startDateState: StateArray<Dayjs|null>;
  endDateState: StateArray<Dayjs|null>;
  spanStart?: ColSize['span'];
  spanEnd?: ColSize['span'];
};

const DatePicker: React.FC<DatePickerProps> = ({
  startDateState: [startDate, setStartDate],
  endDateState: [endDate, setEndDate],
  spanStart,
  spanEnd,
}) => {
  dayjs.extend(Duration);
  const { formatMessage } = useIntl();
  const customStartState: StateArray<Dayjs|null> = [
    startDate,
    // @ts-ignore
    (newValue: Dayjs|null) => {
      if (!newValue) setStartDate(null);
      else {
        const durationDifference = dayjs.duration(newValue.diff(startDate));
        setStartDate(newValue);
        const newEndDate = dayjs(endDate);
        newEndDate.add(durationDifference);
        setEndDate(newEndDate);
      }
    },
  ];

  return (<>
    <Row align="middle" justify="start">
      <Col span={spanStart}>
        { formatMessage(Locale.Attribute.Start_date) }
      </Col>
      <Col span={spanEnd}>
        <DateTimePicker id="test-1" valueState={customStartState} />
      </Col>
    </Row>
    <Row align="middle" justify="start">
      <Col span={spanStart}>
        { formatMessage(Locale.Attribute.End_date) }
      </Col> <Col span={spanEnd}>
        <DateTimePicker id="test-2" valueState={[endDate, setEndDate]} disableUntil={startDate} />
      </Col>
    </Row>

  </>
  );
};


DatePicker.propTypes = {
  spanStart: PropTypes.number,
  spanEnd: PropTypes.number,
};

DatePicker.defaultProps = {
  spanStart: 1,
  spanEnd: 6,
};

export default DatePicker;
