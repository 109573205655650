import React from 'react';
import { Row, Col } from 'antd';
import Icon from '../Icon/Icon';


const LabelWithIcon = ({
  label,
  icon,
}) => (
  <Row className="label-with-icon-container">
    <Col span={20} className="text">
      {label}
    </Col>
    <Col span={4} className="icon">
      <Icon iconType={icon} />
    </Col>
  </Row>
);


LabelWithIcon.propTypes = {};


export default LabelWithIcon;

