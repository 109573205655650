import React, { useRef, useState } from 'react';
import { Modal } from 'antd';
import { useEventListener } from 'ahooks';
import AnimatedText from './AnimatedText';

const EstherEasterEgg: React.FC<{ text?: string[] }> = ({ text }) => {
  const [showModal, setShowModal] = useState(false);
  const ref = useRef('');
  useEventListener('keydown', (ev: KeyboardEvent) => {
    ref.current += ev.key.toLowerCase();
    if (ref.current.slice(-3) === 'mdm') {
      setShowModal(!showModal);
    }
  });


  return (
    <Modal
      open={showModal}
      footer={null}
      onCancel={() => setShowModal(false)}
      className="apb-transparent-ant-modal"
    >
      <AnimatedText
        texts={text ?? ['Launching', 'PureAdvance!']}
        width={680}
        height={480}
      />
    </Modal>
  );
};

export default EstherEasterEgg;
