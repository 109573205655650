import { InMemoryCache, } from '@apollo/client';
import { isArray, join } from 'lodash';
import possibleTypes from '../../gql/fragmentTypes.json';
import {
  AdminEntityMergeRequestCacheTypes
} from '../browse/admin/adminComponents/AdminEntityMergeRequest/MergeRequestDetails/AdminEntityMergeRequestCacheTypes';


export default new InMemoryCache({
  possibleTypes, // <-- https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
  typePolicies: {
    Query: {
      fields: {
        suggestions: { keyArgs: (record) => `${record?.countryCodes?.join('_')}:${record?.service}:${record?.search}` },
      },
    },
    SearchableGqlValueWithKey: { keyFields: ['key', 'cacheKey'] },
    SearchableGqlValueConnection: {
      keyFields: ['service', 'countryCodes', 'search'],
      fields: {
        nodes: { merge: (existing = [], incoming = []) => [...existing, ...incoming] },
      },
    },
    FormValidationErrorResponse: { keyFields: false },
    ImportProjectMeta: { keyFields: ['projectId'] },
    ImportProjectValuePreview: { keyFields: ['code', 'index', 'value'] },
    ImportProjectLinesPreview: { keyFields: ['projectId'] },
    ValueModel: { keyFields: ['id', 'heading'] },
    Resource: { keyFields: ['url'] },
    CriteriaGroup: { keyFields: ['code', 'entityId'] },
    CriteriaCountSuggestionFor: { keyFields: ['criteriaId', 'value', 'globalSearch'] },
    ChartItemValue: { keyFields: ['code', 'label', 'value'] },
    ChartTimestampValue: { keyFields: ['time', 'value'] },
    ChartColumnSeriesValue: { keyFields: ['entity', 'state', 'value'] },
    MatchCandidateValue: { keyFields: ['fieldName', 'value'] },
    FormInputRule: { keyFields: ['messageKey', 'required', 'type'] },
    FieldTypeInformation: { keyFields: ['fieldEnum'] },
    ChartColumnItemValue: { keyFields: ['label', 'seriesLabel', 'value'] },
    DeploymentStatus: { keyFields: ['currentStatus', 'message'] },
    DataValue: { keyFields: ['code', 'type'] },
    ImfDataValue: { keyFields: ['code', 'type'] },
    ChartResults: { keyFields: false },
    ChartResultCriteria: { keyFields: false },
    VirtualRolePermission: { keyFields: ['permissionEnum', 'roleId'] },
    VirtualConsentValue: { keyFields: ['entityType', 'entityAffiliationId', 'consentTypeCode'] },
    VirtualContactCategory: { keyFields: false },
    VirtualContactTypeWithValue: { keyFields: ['entityType', 'entityAffiliationId', 'contactType', ['code']] },
    VirtualAddressTypeWithValues: { keyFields: ['entityType', 'entityAffiliationId', 'type', ['code']] },
    DockerService: { keyFields: ['ID'] },
    MassAssignFieldInfo: { keyFields: ['actionName'] },
    VirtualEntityMerge: { keyFields: false },
    VirtualEntityMergeInfo: { keyFields: false },
    VirtualEntityMergeFieldInfo: { keyFields: false },
    SiteClosure: { keyFields: ['ancestorId', 'descendantId'] },
    AntTableColumn: { keyFields: ['columnKey'] },
    CustomFieldPermissionOption: { keyFields: ['customFieldCode'] },
    ExternalPostcodeResponse: { keyFields: false },
    PureAdvanceCustomerModule: { keyFields: ['id', ['moduleId', 'customerCode']] },
    SiteChain: { keyFields: ['id', ['siteId', 'chainCode']] },
    PureAdvanceCustomerModuleId: { keyFields: ['moduleId', 'customerCode'] },
    EntityCardFields: { keyFields: ['entityGridItemConfigurationId'] },
    CardFieldKeys: { keyFields: ['entityGridItemConfigurationId', 'entityType'] },
    ...AdminEntityMergeRequestCacheTypes,
    CustomFieldToBeTransferredInfo: { keyFields: false, },
    ExternalCherryPickCustomerOverview: { keyFields: ['customerCode', 'country'] },
    ConsentInstruction: { keyFields: false },
    Localization: { keyFields: ['id', ['code', 'languageCode']] },
    SiteLinkToBeMergedVirtualOverview: { keyFields: false },
    JiraReleaseConnection: {
      keyFields: ['self'],
      merge: (a, b) => {
        if (a?.self && b?.self) {
          return {
            self: b.self,
            values: [
              ...a.values,
              ...b.values,
            ],
          };
        }
        return b;
      },
    },
    JiraIssueFields: { keyFields: false },
    CustomValueConnection: {
      keyFields: (connection) => {
        if (!connection.valueNodes || !isArray(connection.valueNodes)) {
          throw Error('Custom Value Connection requires value nodes');
        }
        // @ts-ignore
        return `CustomValueConnection:${join(connection.valueNodes.map(vn => `${vn.key}_${vn.value}`), ':')}`;
      }
    },
  },
  dataIdFromObject: obj => `${obj.__typename}:${obj.key || obj.id || obj.code || obj.hash || obj.apurebaseId}`,
});
