// eslint-disable-next-line import/no-extraneous-dependencies
import { MessageDescriptor } from '@formatjs/intl/src/types';
import { Locale } from '../../../localization/LocalizationKeys';

export type MonthValue = 1|2|3|4|5|6|7|8|9|10|11|12;

export const MonthTextResourceByMonthValue: Record<MonthValue, MessageDescriptor> = {
  1: Locale.General.January,
  2: Locale.General.February,
  3: Locale.General.March,
  4: Locale.General.April,
  5: Locale.General.May,
  6: Locale.General.June,
  7: Locale.General.July,
  8: Locale.General.August,
  9: Locale.General.September,
  10: Locale.General.October,
  11: Locale.General.November,
  12: Locale.General.December,
};
