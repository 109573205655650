import React, { useContext, useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import ActivityResponsibleUsersInput from './ActivityResponsibleUsersInput';
import { ActivityDetailContext } from '../ActivityDetailsTypes';
import HeaderWithValue from '../../../../components/DisplayFields/HeaderWithValue';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import {
  ActivityResponsibleUsersQueryQuery,
  ActivityResponsibleUsersQueryQueryVariables,
  AttachmentRuleEnum
} from '../../../../../gql/typings';

const ActivityResponsibleUsers: React.FC = () => {
  const localization = useLocalization();
  const {
    activityId,
    dispatch,
    activityType,
    responsibleUserIdsErrors,
  } = useContext(ActivityDetailContext);
  const { data } = useQuery<ActivityResponsibleUsersQueryQuery, ActivityResponsibleUsersQueryQueryVariables>(DATA_QUERY, {
    skip: !activityId,
    variables: { activityId: activityId ?? -1 },
  });
  const moduleEnabled = activityType && activityType.responsibleUserRule !== AttachmentRuleEnum.NONE;

  const responsibleUserIds = useMemo(
    () => data?.activity?.responsibleUsers.nodes.map(ru => ru.id) ?? [],
    [data],
  );

  useEffect(() => {
    if (data?.activity?.responsibleUsers) {
      dispatch({ type: 'updateResponsibleUsers', userIds: responsibleUserIds });
    }
  }, [dispatch, data, responsibleUserIds]);

  if (!moduleEnabled) return <></>;

  return (
    <div>
      <HeaderWithValue
        heading={
          localization.pluralMessage(Locale.Text.Responsible_user,
            [
              AttachmentRuleEnum.OPTIONAL_MANY,
              AttachmentRuleEnum.MANY,
            ].includes(activityType!.responsibleUserRule) ? 2 : 1)
        }
        errors={responsibleUserIdsErrors}
      >
        <ActivityResponsibleUsersInput currentUserIds={responsibleUserIds} />
      </HeaderWithValue>
    </div>
  );
};


const DATA_QUERY = gql`
  query ActivityResponsibleUsersQuery($activityId: Int!) {
    activity(id: $activityId) {
      id
      responsibleUsers {
        hash
        nodes {
          id
          fullname
          email
        }
      }
    }
  }
`;


export default ActivityResponsibleUsers;
