import React from 'react';
import { gql } from '@apollo/client';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import { MergeItemPersonIsActiveFragment } from '../../../../../../../../../gql/typings';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';


const AdminMergeItemPersonIsActive: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonIsActiveFragment;
  const { formatMessage } = useLocalization();

  return (
    <AdminMergeItemBasicField {...props}>
      {info.willBeInactivated && formatMessage(Locale.Text.Merge_request_person_inactivated_description, {
        person: <strong>
          {info.willBeInactivated.fullName} ({formatMessage(Locale.Attribute.ID)}: {info.willBeInactivated.id})
        </strong>,
      })}
      {info.willBeActivated && formatMessage(Locale.Text.Merge_request_person_activated_description, {
        person: <strong>
          {info.willBeActivated.fullName} ({formatMessage(Locale.Attribute.ID)}: {info.willBeActivated.id})
        </strong>
      })}
    </AdminMergeItemBasicField>
  );
};

gql`
  fragment MergeItemPersonIsActive on PersonIsActive_EntityDataToBeTransferredType {
    code
    entityType
    willBeActivated {
      id
      fullName
    }
    willBeInactivated {
      id
      fullName
    }
  }
`;

export default AdminMergeItemPersonIsActive;
