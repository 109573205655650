import { gql } from '@apollo/client';
import React from 'react';
import { Switch } from 'antd';
import { useListSharedDataQueryQuery, useSetSharedStatusForListMutation } from '../../../../gql/typings';

const ListDetailsShared: React.FC<{ listId: number }> = ({
  listId,
}) => {
  const {
    data,
    loading,
  } = useListSharedDataQueryQuery({ variables: { listId } });
  const [updateSharedStatus] = useSetSharedStatusForListMutation();

  return (
    <Switch
      checked={data?.list?.isShared ?? false}
      loading={loading}
      onChange={checked => updateSharedStatus({
        variables: {
          listId,
          isShared: checked,
        },
      })}
    />
  );
};

gql`
  mutation SetSharedStatusForList($listId: Int!, $isShared: Boolean!) {
    updateList(input: {id: $listId, isShared: $isShared}) {
      id
      isShared
    }
  }
`;

gql`
  query ListSharedDataQuery($listId: Int!) {
    list(id: $listId) {
      id
      isShared
    }
  }
`;

export default ListDetailsShared;
