import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { Button, message, Modal } from 'antd';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { EntitiesSearch } from '../../../components/entitiesSearch';
import { LIST_TYPE_ID } from '../utils';
import { EntityTypeId } from '../../../util/entity';
import { Locale } from '../../../../localization/LocalizationKeys';
import { EntityTypeEnum, SelectionCriteriaInput } from '../../../../gql/typings';
import { useTableRowSelection } from '../../../components/Table/useTableRowSelection';


type AddToExistingStaticListModalProps = {
  selection: SelectionCriteriaInput;
  entityType: EntityTypeEnum.PERSON | EntityTypeEnum.SITE |
  EntityTypeEnum.ACTIVITY | EntityTypeEnum.PERSON_ACTIVITY_AFFILIATION;
  visible?: boolean;
  onClose?: () => void;
};

const AddToExistingStaticListModal: React.FC<AddToExistingStaticListModalProps> = ({
  selection,
  entityType,
  visible = false,
  onClose = () => {},
}) => {
  const navigate = useNavigate();
  const [addToList] = useMutation(ADD_TO_STATIC_LIST);
  const tableSelection = useTableRowSelection({ virtualSupport: false, type: 'radio' });
  const { formatMessage } = useIntl();


  const onAccept = () => {
    onClose();
    const listId = tableSelection.selection.selected[0];
    return addToList({
      variables: { selection, listId },
    }).then(() => {
      message.success(formatMessage(Locale.Text.Selected_records_have_been_added_to_list));
      navigate(`/list/${listId}`);
    });
  };

  return (
    <Modal
      title={formatMessage(Locale.Text.Add_to_existing_list)}
      open={visible}
      width="80%"
      footer={null}
      onCancel={onClose}
      destroyOnClose
      className="bulkPersProj"
    >
      <EntitiesSearch
        entityType={EntityTypeEnum.LIST}
        tableSelection={tableSelection}
        defaultCriterias={[
          { criteriaCode: 'APB_ENTITY_TYPE', value: EntityTypeId[entityType], disabled: true },
          { criteriaCode: 'APB_TYPE', value: LIST_TYPE_ID.STATIC, disabled: true },
        ]}
      />
      <div className="list-button-steps">
        <div className="steps-action">

          <Button type="primary" onClick={onAccept}>
            {formatMessage(Locale.Command.Confirm)}
          </Button>

          <Button style={{ marginLeft: 8 }} onClick={onClose}>
            {formatMessage(Locale.Command.Cancel)}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const ADD_TO_STATIC_LIST = gql`
  mutation AddToStaticListMutation($listId: Int!, $selection: SelectionCriteriaInput!) {
    addToStaticList(listId: $listId, selection: $selection) {
      id
    }
  }
`;

export default AddToExistingStaticListModal;
