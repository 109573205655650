import React from 'react';
import { personSelectionRowFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { Locale } from '../../../../localization/LocalizationKeys';
import DateDisplay from '../../../components/Badge/DateDisplay';

export const personSelectionRowAddedFieldConfig = personSelectionRowFieldConfig(
  'added',
  ['ct'],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Added),
    render: record => <DateDisplay>{record.ct}</DateDisplay>,
  }),
);
