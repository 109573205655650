import React from 'react';
import { Button, Form, FormInstance } from 'antd';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { createCustomFieldTypeSections } from '../fieldTypes/createCustomFieldTypes';
import {
  createCustomFieldTypeConfigurations,
  CreateCustomFieldTypeConfigurationsField
} from '../createCustomFieldTypeConfigurations';
import { CreateCustomFieldFormData } from '../CreateCustomField';
import { Optional } from '../../../../util/StateArrayType';
import { CustomFieldTypeEnum, EntityTypeEnum } from '../../../../../gql/typings';

type CustomFieldConfigurationTabProps = {
  fieldType: CustomFieldTypeEnum;
  entityType: EntityTypeEnum;
  form: FormInstance<CreateCustomFieldFormData>;
  loading?: boolean;
  field?: Optional<CreateCustomFieldTypeConfigurationsField>;
};

const CustomFieldConfigurationTab: React.FC<CustomFieldConfigurationTabProps> = ({
  fieldType,
  entityType,
  form,
  loading,
  field,
}) => {
  const localization = useLocalization();

  const ToDisplay = createCustomFieldTypeSections[fieldType]!;
  const ConfigurationToDisplay = createCustomFieldTypeConfigurations[fieldType]!;

  return (
    <Form.Item shouldUpdate={(prev, next) => prev?.['custom-field-type'] !== next?.['custom-field-type']}>
      <ConfigurationToDisplay
        form={form}
        entityType={entityType}
        customFieldCode={field?.code}
        field={field}
      />
      <ToDisplay
        form={form}
        entityType={entityType}
        customFieldCode={field?.code}
      >
        <Form.Item noStyle>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            {localization.formatMessage(Locale.Command.Save)}
          </Button>
        </Form.Item>

      </ToDisplay>
    </Form.Item>
  );
};

export default CustomFieldConfigurationTab;
