import React from 'react';
import { gql } from '@apollo/client';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { MergeItemSiteNameFragment, useUpdateSiteNameMutation } from '../../../../../../../../../gql/typings';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';


const AdminMergeItemSiteName: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemSiteNameFragment;
  const localization = useLocalization();
  const [updateName] = useUpdateSiteNameMutation();

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.fromName}
      toValue={info.toName}
      updateMutation={newValue => updateName({
        variables: {
          siteId: props.into.id,
          newValue,
        },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_name_mismatch_description, {
        name1: <strong>{info.fromName}</strong>,
        name2: <strong>{info.toName}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation UpdateSiteName($siteId: Int!, $newValue: String!) {
    updateSiteName(siteId: $siteId, title: $newValue) {
      id
      name
    }
  }
`;

gql`
  fragment MergeItemSiteName on SiteName_EntityDataToBeTransferredType {
    code
    fromName: from
    toName: to
  }
`;

export default AdminMergeItemSiteName;
