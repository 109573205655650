import { gql, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { flatMap } from 'lodash';
import { DashboardItemProps } from '../DashboardItemTypes';
import DashboardCard from '../DashboardCard';
import { PercentageColumnChart, PercentageColumnChartDataItem } from '../../Charts';
import { DashboardCherryPickOverviewQueryQuery } from '../../../../gql/typings';


const DashboardCherryPickOverviewItem: React.FC<DashboardItemProps> = ({
  configuration,
  editStatus,
  dashboardId,
  size,
}) => {
  const intl = useIntl();
  const { data, loading, refetch } = useQuery<DashboardCherryPickOverviewQueryQuery>(DATA_QUERY);


  const abc = flatMap((data?.cherryPickOverviews ?? []), (c => [
    {
      label: c.country,
      seriesLabel: 'Unused', // Unused first, so it'll get the main color
      percentage: 1 - (c.amountUsed / c.amount),
      value: c.amountAvailable,
    },
    {
      label: c.country,
      seriesLabel: 'Used',
      percentage: c.amountUsed / c.amount,
      value: c.amountUsed,
    },
  ] as PercentageColumnChartDataItem[]) ?? []).filter(e => e.percentage > 0);

  useEffect(() => {
    if (!loading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headingKey = configuration.headingKey ?? configuration.dashboardItem.headingKey;

  return (
    <DashboardCard
      editMode={editStatus}
      id={configuration.id}
      title={intl.formatMessage({ id: headingKey, defaultMessage: headingKey })}
    >
      <PercentageColumnChart
        data={abc}
        width={size.width * 0.7}
        height={size.height * 0.7}
      />
    </DashboardCard>
  );
};

const DATA_QUERY = gql`
  query DashboardCherryPickOverviewQuery {
    cherryPickOverviews {
      customerCode
      country
      amount
      amountAvailable
      amountUsed
    }
#    cherryPickConfigurations {
#      hash
#      amountLeft
#      nodes {
#        id
#        countryCode
#        amount
#        existingCherryPicks {
#          hash
#          totalCount
#        }
#      }
#    }
  }
`;

export default DashboardCherryPickOverviewItem;
