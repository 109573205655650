import { gql, useQuery } from '@apollo/client';
import { isArray } from 'lodash';
import {
  CreateEntityFieldQueryQuery,
  CreateEntityFieldQueryQueryVariables, EntityTypeEnum
} from '../../gql/typings';


export function useEntityFieldData(
  countryCode= 'DK',
  entityType: EntityTypeEnum
) {

  const { data, loading } = useQuery<CreateEntityFieldQueryQuery,
  CreateEntityFieldQueryQueryVariables>(CREATE_ENTITY_FIELD_QUERY,
    { variables: { country: countryCode, entityType } });

  const formattedFieldKey = data?.getCreateEntityFields
    .reduce((acc: { [key: string]: string | Array<string> }, curr) => {
      const [keyName = '', code] = curr.fieldKey.split(':');
      if (!code) {
        return { ...acc, [keyName]: '' };
      }
      const keyCodeValue = acc[keyName] || [];
      if (Object.prototype.hasOwnProperty.call(acc, keyName) && isArray(keyCodeValue)) {

        return { ...acc, [keyName]: [...keyCodeValue, code] };
      }
      return { ...acc, [keyName]: [code] };

    }, {});

  return {
    fieldKeys: formattedFieldKey,
    fieldLoading: loading
  };
}
const CREATE_ENTITY_FIELD_QUERY = gql`
  query CreateEntityFieldQuery($country: CountryCode!, $entityType: EntityTypeEnum!) {
    getCreateEntityFields(country: $country, entityType: $entityType) {
      id
      fieldKey
    }
  }
`;
