import React from 'react';
import { Form, DatePicker } from 'antd';
import { DynamicReportInputProps } from './dynamicReportInputUtils';
import { useLocalization } from '../../../../util/useLocalization';

const ReportDatePicker: React.FC<DynamicReportInputProps & { pickerType: 'month'|'year' }> = ({
  inputDef,
  pickerType,
}) => {
  const localization = useLocalization();
  const dateFormat = {
    year: 'YYYY',
    month: 'MMMM YYYY',
  }[pickerType];

  return (
    <Form.Item name={inputDef.labelKey!} label={localization.formatMessageByStr(inputDef.labelKey!)} rules={inputDef.rules}>
      <DatePicker
        format={dateFormat}
        style={{ width: 400 }}
        placeholder={inputDef.placeholderKey ? localization.formatMessageByStr(inputDef.placeholderKey) : undefined}
        picker={pickerType}
      />
    </Form.Item>
  );
};


export default ReportDatePicker;
