import React from 'react';
import key from '../../../img/icon-key.svg';

type PrimaryKeyIconProps = {
  size?: 'default';
};

const PrimaryKeyIcon: React.FC<PrimaryKeyIconProps> = ({
  size = 'default',
}) => (
  <span className="primary-key-icon-container">
    <img src={key} alt="primary icon" className={size} />
  </span>
);

export default PrimaryKeyIcon;
