import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SiteCriteriaOrder } from '../../../../gql/typings';


export const siteVisitZipFieldConfig = siteFieldConfig(
  'visitZip',
  [
    'visitAddress.id',
    'visitAddress.postalCode',
  ],
  ({ localization }) => ({
    sorting: {
      ascend: SiteCriteriaOrder.VISIT_POSTAL_CODE_ASC,
      descend: SiteCriteriaOrder.VISIT_POSTAL_CODE_DESC,
    },
    title: localization.formatMessage(Locale.Attribute.Postal_code),
    additionalTableConfig: {
      width: 125
    },
    render: record => record.visitAddress?.postalCode,
  })
);
