import React from 'react';
import { gql } from '@apollo/client';
import { Button, Space } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { usePersonFieldMutation } from '../../person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import {
  SitePersonCriteriaOrder,
  UpdateLinkIsPrimaryMutationMutation,
  UpdateLinkIsPrimaryMutationMutationVariables,
} from '../../../../gql/typings';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';

export const linkIsPrimaryFieldConfig = linkFieldConfig(
  'isPrimary',
  [
    'id',
    'isPrimary',
    'person.id',
    'person.controlSetting.id',
    'person.controlSetting.entityTypeId',
    'person.controlSetting.maintainerSourceCode',
    'person.controlSetting.createDcr',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Is_primary),
    additionalTableConfig: {
      width: 100
    },
    sorting: {
      ascend: [SitePersonCriteriaOrder.PRIMARY_FIRST],
      descend: [SitePersonCriteriaOrder.PRIMARY_LAST],
    },
    render: (link, options) => {
      /* We do not have support for using hooks in render yet. Therefore, only showing label in tables */
      if (options.isViewingFromPage == TableFieldReturnedRecordPageType.VIEW_PAGE) return (
        link.isPrimary ? localization.formatMessage(Locale.General.Yes) : localization.formatMessage(Locale.General.No)
      );

      const {
        Renderer,
        submit,
        loading
      } = usePersonFieldMutation<UpdateLinkIsPrimaryMutationMutation, UpdateLinkIsPrimaryMutationMutationVariables>({
        mutation: UPDATE_MUTATION,
        controlSetting: link.person.controlSetting,
      });
      return (
        <Renderer>
          {link.isPrimary ? (
            localization.formatMessage(Locale.General.Yes)
          ) : (
            <span>
              <Space>
                {localization.formatMessage(Locale.General.No)}
                <Button
                  loading={loading}
                  size="small"
                  onClick={() => submit(
                    { personId: link.person.id, linkId: link.id }
                  )}
                >
                  {localization.formatMessage(Locale.Command.Set_as_primary)}
                </Button>
              </Space>
            </span>
          )}
        </Renderer>
      );
    }
  })
);

const UPDATE_MUTATION = gql`
  mutation UpdateLinkIsPrimaryMutation($linkId: ID!, $personId: ID!) {
    setPrimaryLink(linkId: $linkId, personId: $personId) {
      id
      isPrimary
    }
  }
`;
