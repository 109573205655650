import React from 'react';
import { sortBy } from 'lodash';
import { useUpdate } from 'ahooks';
import { Progress } from 'antd';
import { green, yellow, grey } from '@ant-design/colors';
import { MergeEventEmitter, MergeEventValue } from './useEntityMergeEmitter';
import { Optional } from '../../../../../util/StateArrayType';


type AdminEntityMergeRequestProgressProps = {
  emitter: MergeEventEmitter;
};


const AdminEntityMergeRequestProgress: React.FC<AdminEntityMergeRequestProgressProps> = ({ emitter: { $, stateRef } }) => {
  const reRender = useUpdate();

  $.useSubscription(reRender);

  let total = 0;
  let finished = 0;
  const obj: { [p: string]: MergeEventValue } = Object.keys(stateRef.current ?? {}).reduce((acc, key) => {
    const inner: Optional<MergeEventValue> = stateRef.current?.[key];
    if (!inner || inner.state === 'removed') return acc;
    total++;
    if (inner.state === 'finished' || inner.state === 'skip') finished++;
    return {
      ...acc,
      [key]: inner,
    };
  }, {});

  return (
    <div style={{ display: 'flex', columnGap: 8, justifyContent: 'flex-end', justifyItems: 'center' }}>
      <Progress
        percent={100}
        steps={total === 0 ? 1 : total}
        showInfo={false}
        style={{ margin: 'auto 0' }}
        strokeColor={total === 0 ? [green['primary'!]!] :sortBy(Object.values(obj).map(r => {
          switch (r.state) {
            case 'finished':
              return green['primary'!]!;
            case 'in_progress':
              return yellow['primary'!]!;
            case 'skip':
              return green['primary'!]!;
            case 'not_started':
            default:
              return grey[2]!;
          }
        }), e => e === grey[2] ? 1 : 0)}
      />
      <span>
        {finished}&nbsp;/&nbsp;{total}
      </span>
    </div>
  );
};

export default AdminEntityMergeRequestProgress;
