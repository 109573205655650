import React, { useState } from 'react';
import { message, Modal } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { EntitiesSearchQuickActionItem } from '../../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import { StateArray } from '../../../util/StateArrayType';
import { usePermission } from '../../../permission/usePermission';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  InactivateSiteActionQuery,
  InactivateSiteActionQueryVariables, InactivateSiteLinkMutation, InactivateSiteLinkMutationVariables,
  SetActiveStatusSiteMutation,
  SetActiveStatusSiteMutationVariables,
  SourceEnum,
} from '../../../../gql/typings';
import { useLocalization } from '../../../util/useLocalization';
import { useDetailsContext } from '../../../components/DetailsView/useDetailsContext';

type ReturnProps = {
  hidden: React.ReactNode;
  action: EntitiesSearchQuickActionItem;
  showModalState: StateArray<boolean>;
  loading: boolean;
};

export const useInactivateSiteAction = (siteId: number): ReturnProps => {
  const localization = useLocalization();
  const { delete: deletePermission } = usePermission('Site');
  const showModalState = useState(false);
  const { data, loading, refetch } = useQuery<InactivateSiteActionQuery, InactivateSiteActionQueryVariables>(DATA_QUERY, {
    variables: { siteId },
  });
  const [
    setActivateMutation,
    { loading: isInactivating },
  ] = useMutation<SetActiveStatusSiteMutation, SetActiveStatusSiteMutationVariables>(SET_ACTIVE_MUTATION);
  const [
    inactivateLink,
    { loading: isInactivatingLink },
  ] = useMutation<InactivateSiteLinkMutation, InactivateSiteLinkMutationVariables>(INACTIVATE_LINK_MUTATION);

  const { broadcastShouldRefetchData } = useDetailsContext(refetch);

  const inactivateLinks = () => Promise.all(data?.site?.affiliations?.nodes?.map(link => inactivateLink({
    variables: { linkId: link.id },
  })) ?? [Promise.resolve()]).then(() => broadcastShouldRefetchData());

  const inactivate = () => setActivateMutation({ variables: { siteId, isActive: false } })
    .then(() => {
      broadcastShouldRefetchData();
      return message.success(localization.formatMessage(Locale.Text.Successfully_inactivated_record));
    });

  const activate = () => setActivateMutation({ variables: { siteId, isActive: true } })
    .then(() => {
      broadcastShouldRefetchData();
      return message.success(localization.formatMessage(Locale.Text.Successfully_activated_record));
    });

  const isActive = data?.site?.isActive ?? false;

  return {
    showModalState,
    loading: loading || isInactivating || isInactivatingLink,
    action: {
      key: 'delete-record',
      hide: data?.site?.maintainerSourceCode !== SourceEnum.LOCAL || !deletePermission,
      disabled: !isActive && (data?.site?.mergeRequests?.totalCount ?? 0) > 0,
      label: isActive
        ? localization.formatMessage(Locale.Command.Inactivate_record)
        : localization.formatMessage(Locale.Command.Activate_record),
      onClick: () => !isActive
        ? activate()
        : data?.site?.affiliations.totalCount === 0
          ? inactivate()
          : showModalState[1](true),
    },
    hidden: (
      <Modal
        open={showModalState[0]}
        onCancel={() => showModalState[1](false)}
        okText={localization.formatMessage(Locale.General.Yes)}
        cancelText={localization.formatMessage(Locale.Command.Cancel)}
        okButtonProps={{ loading: loading || isInactivating || isInactivatingLink }}
        onOk={() => inactivateLinks().then(() => inactivate())}
      >
        {localization.pluralMessage(
          Locale.Text.Inactivate_with_links_description,
          data?.site?.affiliations.totalCount ?? 0,
        )}
      </Modal>
    ),
  };
};

const SET_ACTIVE_MUTATION = gql`
  mutation SetActiveStatusSite($siteId: Int!, $isActive: Boolean!) {
    setSiteActiveStatus(siteId: $siteId, isActive: $isActive) {
      id
      isActive
    }
  }
`;

const INACTIVATE_LINK_MUTATION = gql`
  mutation InactivateSiteLink($linkId: Int!) {
    setSitePersonActiveStatus(id: $linkId, isActive: false) {
      id
      isActive
      site {
        id
        affiliations {
          hash
          totalCount
          nodes { id }
        }
      }
    }
  }
`;

const DATA_QUERY = gql`
  query InactivateSiteAction($siteId: Int!) {
    site(siteId: $siteId) {
      id
      isActive
      maintainerSourceCode
      mergeRequests {
        hash
        totalCount
      }
      affiliations {
        hash
        totalCount
        nodes {
          id
        }
      }
    }
  }
`;
