import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { List, Modal } from 'antd';
import { NavLink } from 'react-router-dom';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import {
  EntityMergeListsModalQuery, EntityMergeListsModalQueryVariables
} from '../../../../../../../../../gql/typings';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';

type AdminMergeItemListsModalProps = {
  visible: boolean;
  close: () => void;
  listIds: number[];
};

const AdminMergeItemListsModal: React.FC<AdminMergeItemListsModalProps> = ({
  visible,
  close,
  listIds,
}) => {
  const localization = useLocalization();
  const {
    data,
    loading,
  } = useQuery<EntityMergeListsModalQuery, EntityMergeListsModalQueryVariables>(DATA_QUERY, {
    variables: { listIds },
  });

  return (
    <Modal
      open={visible}
      onCancel={close}
      footer={<></>}
    >
      {localization.pluralMessage(Locale.Text.Merge_request_lists_move_modal, listIds.length)}
      <List
        loading={loading}
        dataSource={data?.lists.nodes ?? []}
        renderItem={list => (
          <List.Item>
            <NavLink to={`/list/${list.id}`} target="_blank">
              {list.title}
            </NavLink>
          </List.Item>
        )}
      />
    </Modal>
  );
};

const DATA_QUERY = gql`
  query EntityMergeListsModal($listIds: [ID!]!) {
    lists(criteria: { ids: $listIds }) {
      hash
      nodes {
        id
        title
      }
    }
  }
`;

export default AdminMergeItemListsModal;
