import React from 'react';
import { TableFieldUpdateViewProps } from '../../../search_old/types';
import CustomMultiSelectInput from './CustomMultiSelectInput';
import CustomSelect from './CustomSelect';

const CustomSelectInputMultiSupport: React.FC<TableFieldUpdateViewProps> = (props) => (
  <>
    {
      props.options.selectedOption?.allowMultiple
        ? <CustomMultiSelectInput {...props} />
        : <CustomSelect {...props} />
    }
  </>
);
export default CustomSelectInputMultiSupport;
