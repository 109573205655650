import { get, toLower } from 'lodash';
import { AnyValueType } from '../../../util/StateArrayType';
import { DynamicRulesQueryQuery } from '../../../../gql/typings';

type CriteriaAffiliationNode = NonNullable<NonNullable<DynamicRulesQueryQuery['list']>['criterias']>['nodes']['0'];

export type DynamicRuleReturnMap = {
  criteria: CriteriaAffiliationNode['criteria'];
  options: {
    initialValue: Record<number, AnyValueType>;
  };
};

export const dynamicRulesMap = (node: CriteriaAffiliationNode): DynamicRuleReturnMap => {
  const initialValue: Record<number, AnyValueType> = {};
  node.values.nodes.forEach(v => {
    switch (v.path.type?.type) {
      case 'multiSelect':
      case 'multiGroupSelect':
        if (!initialValue[v.path.id]) initialValue[v.path.id] = [];
        (initialValue[v.path.id] as AnyValueType[]).push(v.value);
        break;
      case 'select':
      case 'date':
      case 'text':
        initialValue[v.path.id] = v.value;
        break;
      case 'switch':
        initialValue[v.path.id] = v.value === '1' || toLower(v.value || '') === 'true';
        break;
      default:
        // eslint-disable-next-line no-console
        console.error('type not supported!', v, get(v, 'path.type.type'));
    }
  });

  return {
    criteria: node.criteria,
    options: {
      initialValue,
    },
  };
};
