import { Tooltip, Typography } from 'antd';
import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SiteCriteriaOrder } from '../../../../gql/typings';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';


export const siteVisitStreetFieldConfig = siteFieldConfig(
  'visitStreet',
  [
    'visitAddress.id',
    'visitAddress.street',
  ],
  ({ localization }) => ({
    sorting: {
      ascend: SiteCriteriaOrder.VISIT_STREET_ASC,
      descend: SiteCriteriaOrder.VISIT_STREET_DESC,
    },
    title: localization.formatMessage(Locale.Attribute.Visit_street),
    additionalTableConfig: {
      width: 120
    },
    // className: 'apb-antd-table-column-width-no-wrap',
    render: (record, { isViewingFromPage }) => {
      const street = record.visitAddress?.street;
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={street}>{street}</Tooltip>
      </Typography.Paragraph>);
    },
  })
);
