import React from 'react';
import { Alert, Card, Timeline } from 'antd';
import moment from 'moment';
import DcrStatusMessage from './DcrStatusMessage';
import { DcrResponseConf } from './DcrStatusTag';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import { DcrGroupDetailQuery } from '../../../gql/typings';
import { DATE_FORMAT_TIME } from '../../util/format';

type DcrHistoryTabProps = {
  group: DcrGroupDetailQuery['dcrGroup'] | null | undefined;
  hasRelatedData: boolean | null | undefined;
};

// TODO: https://stackoverflow.com/questions/68821299/how-to-align-antdesign-timeline-to-the-left

const DcrHistoryTab: React.FC<DcrHistoryTabProps> = ({ group, hasRelatedData }) => {
  const localization = useLocalization();

  const currentState = group && DcrResponseConf[group?.responseStatus.responseTypeEnum];
  const isRejected = currentState?.status === 'REJECT';

  return (<div className="dcr-tab-history-container">
    <Card>

      <Timeline
        pendingDot={currentState?.pendingMessage}
        pending={currentState?.pendingMessage && localization.formatMessage(currentState.localizationKey)}
        style={{ marginTop: 20 }}
        mode="left"
        items={(group?.responses?.nodes ?? []).map(response => (
          {
            key: response.id,
            color: DcrResponseConf[response.responseTypeEnum].color,
            children: (
              <DcrStatusMessage
                date={<b>{moment(response.ct).format(DATE_FORMAT_TIME)}</b>}
                response={response}
                group={group}
              />
            )
          }
        ))}
      />
      {(hasRelatedData && !isRejected) && (
        <Alert message={localization.formatMessage(Locale.Text.DCR_related_data_take_action_text_warning)} type="info" />
      )}

    </Card>
  </div>);
};


export default DcrHistoryTab;
