import { gql } from '@apollo/client';
import React, { useContext, useEffect, useMemo } from 'react';
import { message, Select } from 'antd';
import { useIntl } from 'react-intl';
import { groupBy } from 'lodash';
import ColoredShadowIcon from '../../../components/StatusIcon/ColoredShadowIcon';
import { ActivityDetailContext, ActivityTypeProp } from '../ActivityDetails/ActivityDetailsTypes';
import { isActualNumber } from '../../../util/Util';
import { Locale } from '../../../../localization/LocalizationKeys';
import HeaderWithValue from '../../../components/DisplayFields/HeaderWithValue';
import {
  AttachmentRuleEnum,
  useUpdateActivityTypeMutation
} from '../../../../gql/typings';


const ActivityType: React.FC = () => {
  const {
    activityId,
    activityType,
    activityTypes,
    dispatch,
    editAccess,
    personIds,
  } = useContext(ActivityDetailContext);
  const intl = useIntl();
  const [UpdateActivityType] = useUpdateActivityTypeMutation();

  useEffect(() => {
    if (!activityType && activityTypes[0]) dispatch({
      type: 'updateType',
      value: activityTypes[0],
    });
  }, [activityType, activityTypes, dispatch]);

  const types = useMemo(() => Object
    .keys(activityTypes)
    .map(key => activityTypes[key]!)
    .map(type => {
      const attach = { gray: false };
      if (personIds && personIds.length > 0) {
        if (type.personRule === AttachmentRuleEnum.NONE) attach.gray = true;
        if (type.personRule === AttachmentRuleEnum.ONE && personIds.length > 1) attach.gray = true;
      }
      return { ...type, ...attach };
    })
    .sort((a, b) => {
      const n1 = (a.gray ? (isActualNumber(a.sort) ? a.sort : 1000) * 1000 : (isActualNumber(a.sort) ? a.sort : 1000));
      const n2 = (b.gray ? (isActualNumber(b.sort) ? b.sort : 1000) * 1000 : (isActualNumber(b.sort) ? b.sort : 1000));

      if (n1 > n2) return 1;
      if (n2 > n1) return -1;
      return 0;
    }), [activityTypes, personIds]);

  const superTypes = useMemo(() => Object.values(groupBy(types, t => t.superType.code)), [types]);

  useEffect(() => {
    if (!activityId && !activityType && types[0]) {
      dispatch({ type: 'updateType', value: types[0] });
    }
  }, [types, activityId, activityType, dispatch]);

  const onSave = (activityTypeCode: string) => {
    if (activityId) {
      UpdateActivityType({
        variables: {
          activityId,
          activityTypeCode,
        },
        refetchQueries: ['ViewerActivitiesCalendar'],
      }).then(() => message.success(intl.formatMessage(Locale.Text.Type_updated_to_new_type,
        { newType: <b>{activityTypes[activityTypeCode]!.heading}</b> })));
    } else dispatch({ type: 'updateType', value: activityTypes[activityTypeCode] });
  };


  const DisplayType: React.FC<{ type: ActivityTypeProp }> = (props) => (
    <div id={`activity-type-${props.type.code}`}>
      <span style={{ alignItems: 'center' }}>
        <ColoredShadowIcon hex={props.type.color.value} />
        &nbsp;&nbsp;
        {intl.formatMessage({ id: props.type.heading, defaultMessage: props.type.heading })}
      </span>
    </div>
  );

  if (!editAccess) return (
    <div className="activity-display-value">
      {activityType && <DisplayType type={activityType} />}
    </div>
  );

  return (
    <HeaderWithValue heading={intl.formatMessage(Locale.Attribute.Activity_type)}>
      <Select
        size='large'
        autoFocus
        className="select-activity-type"
        style={{ width: '100%' }}
        placeholder={intl.formatMessage(Locale.Command.Select_type)}
        onChange={onSave}
        filterOption={(input, option) => option?.props.children.toLowerCase()
          .indexOf(input.toLowerCase()) >= 0}
        value={activityType?.code}
      >
        {superTypes.map(group => (
          <Select.OptGroup key={group[0]!.superType.code}>
            {group.map(type => (
              <Select.Option key={type.code} value={type.code} disabled={type.gray}>
                <DisplayType type={type} />
              </Select.Option>
            ))}
          </Select.OptGroup>
        ))}
      </Select>
    </HeaderWithValue>
  );
};
gql`
  mutation UpdateActivityType($activityId: Int!, $activityTypeCode: String!) {
    updateActivity(input: { activityId: $activityId, activityTypeCode: $activityTypeCode }) {
      id
      typeCode
      type {
        code
      }
      heading
    }
  }
`;

export default ActivityType;
