import { gql, useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { Checkbox, Col, Row, Skeleton, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import ContentSection from '../../../components/ContentSection/ContentSection';
import {
  PersonConsentQueryQuery, PersonConsentQueryQueryVariables,
  updatePersonConsentMutationMutation,
  updatePersonConsentMutationMutationVariables
} from '../../../../gql/typings';

const PersonConsentFields: React.FC<{ personId: number }> = ({ personId }) => {
  const localization = useLocalization();
  const {
    data,
    loading,
  } = useQuery<PersonConsentQueryQuery, PersonConsentQueryQueryVariables>(PersonConsentField_DATA_QUERY, {
    variables: { personId },
  });
  const [
    update,
  ] = useMutation<updatePersonConsentMutationMutation, updatePersonConsentMutationMutationVariables>(UPDATE_CONSENT_MUTATION);

  const doUpdate = (consentTypeCode: string) => (e: CheckboxChangeEvent) => update({
    variables: {
      consentTypeCode,
      enabled: e.target.checked,
      personId,
    },
  });

  return (
    <Skeleton loading={loading || !data} active={loading} paragraph={{ rows: 6 }}>
      {(!loading && data && update) && (
        <ContentSection
          title={localization.formatMessage(Locale.General.Consent)}
          tooltip={localization.formatMessage(Locale.Command.Add_consent)}
        >
          {/* @ts-ignore */}
          <Row type="flex" justify="start" gutter={{ xs: [8, 8], sm: [16, 16], md: [24, 24], lg: [32, 32] }}>
            {(data.person?.consents ?? []).map(consent => (
              <Col key={consent.consentTypeCode} xs={12} sm={5} md={4} lg={4}>
                <span className='detail-view-label'>
                  {localization.formatMessageByStr(consent.type.headingKey)}
                </span>
                <span className='detail-view-value'>
                  &nbsp;
                  <Tooltip title={consent.type.isManaged
                    ? localization.formatMessage(Locale.Text.Consent_email_checkbox_tooltip)
                    : ''}
                  >
                    <Checkbox
                      disabled={consent.type.isManaged}
                      onChange={doUpdate(consent.consentTypeCode)}
                      checked={consent.isEnabled}
                    />
                  </Tooltip>
                </span>
              </Col>
            ))}
          </Row>
        </ContentSection>
      )}
    </Skeleton>
  );
};

export const PersonConsentField_DATA_QUERY = gql`
  query PersonConsentQuery($personId: Int!) {
    person(id: $personId) {
      id
      hasConsentedEmail
      consents: virtualConsents {
        entityType
        entityAffiliationId
        consentTypeCode
        isEnabled
        type {
          code
          headingKey
          isManaged
        }
      }
    }
  }
`;

const UPDATE_CONSENT_MUTATION = gql`
  mutation updatePersonConsentMutation($personId: Int!, $consentTypeCode: String!, $enabled: Boolean!) {
    setConsentValue(entityAffiliationId: $personId, typeCode: $consentTypeCode, isEnabled: $enabled) {
      entityType
      entityAffiliationId
      consentTypeCode
      type { code, headingKey }
      isEnabled
    }
  }
`;

export default PersonConsentFields;
