import React, { useEffect, useState } from 'react';
import Paragraph from 'antd/es/typography/Paragraph';
import { Modal, Select } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { EntitiesSearchQuickActionItem } from '../../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import { Optional, StateArray } from '../../../util/StateArrayType';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import { ActivityDetailsInitialState } from '../../activity/ActivityDetails/ActivityDetailsTypes';
import { FindHcpAffiliationsQuery, FindHcpAffiliationsQueryVariables } from '../../../../gql/typings';
import { useDetailsContext } from '../../../components/DetailsView/useDetailsContext';

type ReturnProps = {
  hidden: React.ReactNode;
  action: EntitiesSearchQuickActionItem;
  showModalState: StateArray<boolean>;
  loading: boolean;
};

export const useAddSiteToActivityAction = (siteId: Optional<number>): ReturnProps => {
  const localization = useLocalization();
  const navigate = useNavigate();

  const showModalState = useState(false);
  const [personIds, setPersonIds] = useState<number[]>([]);

  const {
    data,
    loading,
    refetch
  } = useQuery<FindHcpAffiliationsQuery, FindHcpAffiliationsQueryVariables>(DATA_AFF_QUERY, {
    skip: !siteId,
    variables: { siteId: siteId ?? -1 },
  });

  useDetailsContext(refetch);

  useEffect(() => {
    if (!loading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.site?.affiliations.nodes.length === 1 && personIds.length === 0) setPersonIds(
      [data?.site.affiliations.nodes[0]!.id]
    );
  }, [data, personIds]);

  const onClick = () => {
    if (!siteId) return;
    const state: ActivityDetailsInitialState = { personIds, siteId };
    navigate('/activity/create', { state });
  };

  const handleChange = (value: number[]) => {
    setPersonIds(value);
  };

  return {
    loading,
    showModalState,
    action: {
      key: 'add_to_new_activity',
      onClick: () => showModalState[1](true),
      hide: !siteId,
      label: localization.formatMessage(Locale.Text.Add_to_new_Activity),
    },
    hidden: (
      <Modal
        title={localization.formatMessage(Locale.Text.Activity_add_site_to_activity)}
        open={showModalState[0]}
        onOk={onClick}
        okText={localization.formatMessage(Locale.Command.Continue)}
        cancelText={localization.formatMessage(Locale.Command.Cancel)}
        onCancel={() => showModalState[1](false)}
      >
        {data?.site?.affiliations.nodes.length === 0 ? <Paragraph>
          {localization.formatMessage(Locale.Text.Activity_add_site_hcp_no_result)}
        </Paragraph>
          : <Paragraph>
            {localization.formatMessage(Locale.Text.Activity_add_site_hcp_results)}
          </Paragraph>}


        <Select
          mode="multiple"
          allowClear
          onChange={handleChange}
          disabled={data?.site?.affiliations.nodes.length === 0}
          loading={loading}
          style={{ width: '100%' }}
          defaultValue={data?.site?.affiliations.nodes.length === 1 ? [data?.site?.affiliations.nodes[0]!.person.id] : []}
          filterOption={false}
          placeholder={localization.formatMessage(Locale.Text.Activity_add_site_to_activity_placeholder)}
        >
          {(data?.site?.affiliations.nodes ?? []).map(it => (
            <Select.Option
              key={it.person.id}
              value={it.person.id}
            >
              {it.person.fullName} {it.person.persType ? `(${it.person.persType.label})` : ''}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    )
  };
};

const DATA_AFF_QUERY = gql`
  query FindHcpAffiliations($siteId: Int!) {
    site(siteId: $siteId) {
      id
      name
      affiliations {
        totalCount
        nodes {
          id
          person {
            id
            fullName
            title
            persType {
              type
              code
              label
            }
          }
        }
      }
    }
  }
`;
