import { CalendarApi, CalendarListeners, DurationInput } from '@fullcalendar/common';
import { useEffect } from 'react';
import { Optional } from '../../util/StateArrayType';

export type FullcalendarDisplayMode = 'day'|'3day'|'workWeek'|'week'|'month';

export type ApbCalendarApi = Omit<CalendarApi, 'incrementDate'|'gotoDate'> & {
  incrementDate: (delta: DurationInput) => void;

  /**
   * @param iso8601Date - moment().toISOString()
   */
  gotoDate: (iso8601Date: string) => void;
};

export const useCalendarOn = (cb: () => void, on: keyof CalendarListeners, api: Optional<ApbCalendarApi>) => {
  useEffect(() => {
    if (api) api.on(on, cb);
    return () => {
      if (api) api.off(on, cb);
    };
  }, [api, cb, on]);
};
