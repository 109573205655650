import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { Table } from 'antd';
import { buildPersonTableFragment } from '../../person/PersonTable';
import { useTableColumns } from '../../../components/entitiesSearch/results/useTableColumns';
import { stringToAbsHash } from '../../../apollo/util';
import { EntityTypeEnum } from '../../../../gql/typings';

const ActivityNestedTable: React.FC<{ record: { id: number } }> = ({ record }) => {
  const { query, columns } = useTableColumns(EntityTypeEnum.PERSON);
  const {
    data,
    loading,
  } = useQuery(buildDataQuery(query), { variables: { activityId: record.id } });

  const ds = loading ? [] : data.persons.nodes;

  return (
    <div className="activity-nested-table-container">
      <Table
        loading={loading}
        rowKey="id"
        dataSource={ds}
        size="small"
        pagination={false}
        bordered
        columns={columns}
        style={{ margin: 0 }}
      />
    </div>
  );
};

const buildDataQuery = (fields: string|null) => gql`
  query ActivityNestedHCPTableQuery($activityId: ID!) {
    persons(criteria: { activityId: $activityId }) {
      hash
      nodes {
        ...PersonTableBuiltFragment${stringToAbsHash(fields)}
      }
    }
  }
  ${buildPersonTableFragment(fields)}
`;

export default ActivityNestedTable;
