import { Moment } from 'moment';
import { PermissionEnum } from '../../gql/typings';
import { makeRandomKey } from '../util/makeRandomKey';

export type PermissionInfo = Record<'loading'|'create'|'read'|'update'|'delete', boolean>;
export type AppContextSystemPermissionType = 'globalSearch';

export const _PermissionCallbacksStore: {
  [K in PermissionEnum]?: Record<string, () => void>;
} = {};

export const _PermissionStore: {
  [K in PermissionEnum]?: {
    last: Moment;
    create: boolean;
    read: boolean;
    update: boolean;
    delete: boolean;
  };
} = {};

export function permissionCallbackRegister(permission: `${PermissionEnum}`, cb: () => void): string {
  const key = makeRandomKey();
  if (!_PermissionCallbacksStore[permission]) _PermissionCallbacksStore[permission] = {};
  _PermissionCallbacksStore[permission]![key] = cb;
  return key;
}
export function permissionCallbackUnregister(permission: `${PermissionEnum}`, key: string) {
  if (_PermissionCallbacksStore[permission]?.[key]) {
    delete _PermissionCallbacksStore[permission]?.[key];
  }
}
export function permissionCallbackPublish(permission: `${PermissionEnum}`) {
  Object.values(_PermissionCallbacksStore[permission] ?? {}).map(cb => {
    cb();
  });
}
