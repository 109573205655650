/* eslint-disable max-len */
import React, { useCallback, useContext } from 'react';
import { Form } from 'antd';
import { DragOutlined } from '@ant-design/icons';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import EntitiesSearchContext from '../../../EntitiesSearchContext';
import { CriteriaInputFactory } from '../../../../CriteriaInput/CriteriaInputFactory';
import SimpleSearchOverviewHeader from './SimpleSearchOverviewHeader';
import { StateArray } from '../../../../../util/StateArrayType';
import { useLocalization } from '../../../../../util/useLocalization';
import { Locale } from '../../../../../../localization/LocalizationKeys';

const SimpleSearchOverview: React.FC<{
  drawerState: StateArray<boolean>;
  header?: React.ReactNode;
}> = ({
  drawerState,
  header,
}) => {
  const localisation = useLocalization();
  const {
    selectedCriterias,
    setSelectedCriterias,
    form,
    selectedCountriesState: [selectedCountries],
  } = useContext(EntitiesSearchContext);

  const onDragEnd = useCallback((result: DropResult) => {
    const { source, destination } = result;

    if (source.droppableId === 'items' && destination && destination.droppableId === 'items') {
      // Re-order the list based on drop location index
      const copied = [...selectedCriterias];
      const [removed] = copied.splice(source.index, 1);
      copied.splice(destination.index, 0, removed!);
      setSelectedCriterias(copied);
    } else if (!destination) {
      // Remove item
      const copied = [...selectedCriterias];
      copied.splice(source.index, 1);
      setSelectedCriterias(copied);
    }
  }, [setSelectedCriterias, selectedCriterias]);

  return (
    <div className="person-view-search-center-drawer-container" style={{ opacity: drawerState[0] ? 1 : 0 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="container">
          <Form className="container-form" wrapperCol={{ span: 24 }} form={form}>
            {header || <SimpleSearchOverviewHeader />}
            <Droppable droppableId="items">
              {(droppableP) => (
                <div
                  {...droppableP.droppableProps}
                  ref={droppableP.innerRef}
                  className="droppable-container"
                >
                  {selectedCriterias.map((criteria, index) => (
                    <Draggable key={`${criteria.id}`} draggableId={`${criteria.id}`} index={index}>
                      {(draggableP, snapshotP) => (
                        <div
                          ref={draggableP.innerRef}
                          {...draggableP.draggableProps}
                          style={{
                            ...draggableP.draggableProps.style,
                            ...(snapshotP.isDropAnimating ? { transitionDuration: '0.001s' } : {}),
                          }}
                        >
                          <CriteriaInputFactory
                            criteria={criteria}
                            form={form}
                            countryCodes={selectedCountries}
                            options={{
                              transparentBackground: true,
                              hasMaxWidth: false,
                              icon: (
                                <DragOutlined
                                  className="drag-icon"
                                  {...draggableP.dragHandleProps}
                                />
                              ),
                            }}
                            initialValueOptionsLoad
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  <div className={`criteria-overview-container ${selectedCriterias.length > 4 ? 'limit' : 'default'}`}>
                    {localisation.formatMessage(Locale.Text.Drag_drop_placeholder_simple_search)}
                  </div>

                  {droppableP.placeholder}

                </div>
              )}
            </Droppable>
          </Form>
        </div>
      </DragDropContext>
    </div>
  );
};

export default SimpleSearchOverview;
