import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Button, List } from 'antd';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import CreateEntityGroupModal from '../CreateEntityGroupModal';
import { EntityGridItemTypeEnum, GridItemTypesQueryQuery } from '../../../../../gql/typings';

type AddNewGridItemConfigurationModalProps = {
  tabCode: string;
};

const AddNewGridItemConfigurationRightTab: React.FC<AddNewGridItemConfigurationModalProps> = ({
  tabCode,
}) => {
  const localization = useLocalization();
  const { data } = useQuery<GridItemTypesQueryQuery>(DATA_QUERY);
  const selectedTypeState = useState<EntityGridItemTypeEnum>();

  return (
    <div style={{ width: '100%' }}>
      <List
        style={{ width: '100%' }}
        dataSource={data?.entityGridItemTypes.nodes ?? []}
        renderItem={row => (
          <List.Item
            style={{ width: '100%' }}
            actions={[
              <Button key="add" onClick={() => selectedTypeState[1](row.enum)}>
                {localization.formatMessage(Locale.Command.Add)}
              </Button>
            ]}
          >
            <List.Item.Meta
              title={localization.formatMessageByStr(row.headingKey)}
              description={row.descriptionKey ? localization.formatMessageByStr(row.descriptionKey) : undefined}
            />
          </List.Item>
        )}
      />
      <CreateEntityGroupModal modalModeState={selectedTypeState} entityTabCode={tabCode} />
    </div>
  );
};

const DATA_QUERY = gql`
  query GridItemTypesQuery {
    entityGridItemTypes {
      hash
      nodes {
        code
        enum
        headingKey
        descriptionKey
        minHeight
        minWidth
      }
    }
  }
`;

export default AddNewGridItemConfigurationRightTab;
