import React from 'react';
import { Select } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import {
  ValueChainQueryQuery, ValueChainQueryQueryVariables
} from '../../../../gql/typings';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';

type ValueChainsFormInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  countryCode: string;
  disabled?: boolean;
};

const ValueChainsFormInput: React.FC<ValueChainsFormInputProps> = ({
  value,
  onChange,
  disabled,
  countryCode
}) => {
  const localization = useLocalization();
  const {
    data,
    loading,
    refetch,
  } = useQuery<ValueChainQueryQuery, ValueChainQueryQueryVariables>(VALUE_CHAIN_DATA_QUERY,
    { variables: { countries: countryCode ? [countryCode] : [], search: '%' } });
  const searchDebounced = debounce(
    search => {
      const input: ValueChainQueryQueryVariables = { search: `%${search}%` };
      if (countryCode) input.countries = [countryCode];
      return refetch(input);
    },
    25,
  );

  return (
    <Select
      placeholder={localization.formatMessage(Locale.Command.Select_Value_Chain)}
      style={{ minWidth: 120 }}
      loading={loading}
      value={value as string|undefined}
      showSearch
      onSearch={searchDebounced}
      filterOption={false}
      onChange={e => {
        onChange?.(e);
        searchDebounced('');
      }}
      disabled={disabled}
      allowClear
    >
      {
        (data?.valueChains.nodes ?? []).map(vc => (
          <Select.Option key={vc!.code} value={vc!.code}>
            {
              vc!.label
            }
          </Select.Option>
        ))
      }
    </Select>
  );
};

const VALUE_CHAIN_DATA_QUERY = gql`
  query ValueChainQuery($countries: [CountryCode!], $search: String) {
    valueChains(criteria:{
      label: $search,
      countries: $countries, 
      fetchSize: {limit: 1000}
    }) {
      hash
      nodes {
        code
        label: displayLabel
        type
      }
    }
  }
`;

export default ValueChainsFormInput;
