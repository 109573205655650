import React from 'react';
import { message, Switch } from 'antd';
import { gql } from '@apollo/client';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import { NAVIGATION_DATA_QUERY } from '../../../layout/Navigation/Navigation';
import { ADMIN_MENU_DATA_QUERY } from './MenuSettings';
import {
  AdminMenuItemFragmentFragment,
  useUpdateStatusMenuItemMutation,
} from '../../../../gql/typings';

type MenuToggleStatusProps = {
  menuItem: AdminMenuItemFragmentFragment;
};

const MenuToggleStatus: React.FC<MenuToggleStatusProps> = ({ menuItem }) => {
  const localization = useLocalization();
  const [updateStatus, { loading }] = useUpdateStatusMenuItemMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: ADMIN_MENU_DATA_QUERY },
      { query: NAVIGATION_DATA_QUERY },
    ],
  });

  const onChange: SwitchChangeEventHandler = (checked) => updateStatus({
    variables: { menuItemCode: menuItem.code, isActive: checked },
  }).then(() => message.success(localization.formatMessage(Locale.Attribute.Updated)));

  return (
    <Switch
      loading={loading}
      checked={menuItem.isActive}
      onChange={onChange}
    />
  );
};

gql`
  mutation UpdateStatusMenuItem($menuItemCode: String!, $isActive: Boolean!) {
    updateMenuItemStatus(menuItemCode: $menuItemCode, isActive: $isActive) {
      code
      isActive
    }
  }
`;


export default MenuToggleStatus;
