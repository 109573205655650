import React from 'react';
import { Optional } from '../../util/StateArrayType';

type DisplayAddressProps = {
  street?: Optional<string>;
  postalCode?: Optional<string>;
  city?: Optional<string>;
  countryCode?: Optional<string>;
};

const DisplayAddress: React.FC<DisplayAddressProps> = ({
  street,
  postalCode,
  city,
  countryCode,
}) => (
  <span>
    {[street, postalCode, city, countryCode].filter(e => e).join(', ')}
  </span>
);

export default DisplayAddress;
