import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { get } from 'lodash';
import { message } from 'antd';
import { decodeJWT } from './util';


export const authenticationMiddleware = new ApolloLink((operation, forward) => {
  if (!window.location.pathname.includes('login')) {
    const token = localStorage.getItem('token');
    if (!token) {
      redirectToLogin();
      return null;
    }
    const user = decodeJWT(token.substr(7));
    if (user.exp > (+new Date())) {
      redirectToLogin();
      return null;
    }
  }
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('token');
    if (token) {
      return ({
        headers: {
          ...headers,
          authorization: localStorage.getItem('token') || null,
        },
      });
    }


    return headers;
  });

  return forward(operation);
});

export const authenticationAfterware = onError((errorHandler) => {
  if (get(errorHandler, 'networkError.statusCode', 200) === 401) {
    localStorage.removeItem('token');
    window.location.replace('/login');
  }
  errorHandler.graphQLErrors?.map(e => message.error(e.message));
});


function redirectToLogin() {
  localStorage.removeItem('token');
  return window.location.replace('/login');
}
