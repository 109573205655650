import React from 'react';
import { Form, Input, Select } from 'antd';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import {
  ContactFormQueryQuery,
  ContactFormQueryQueryVariables,
  EntityTypeEnum,
} from '../../../../gql/typings';

type ContactFormInputProps = {
  entityType: EntityTypeEnum;
  placeholderText?: string;
  namePath: string[];
};

const ContactFormInput: React.FC<ContactFormInputProps> = ({
  entityType,
  namePath,
  placeholderText,
}) => {
  const {
    data,
    loading,
  } = useQuery<ContactFormQueryQuery, ContactFormQueryQueryVariables>(DATA_QUERY, { variables: { entityType } });


  return <Input.Group compact>
    <Form.Item noStyle name={[...namePath, 'categoryCode']}>
      <Select
        style={{ minWidth: 120 }}
        loading={loading}
      >
        {data?.contactCategories.nodes.map(category => (
          <Select.Option key={category.code} value={category.code}>
            {category.heading}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
    <Form.Item style={{ marginBottom: 0 }} name={[...namePath, 'value']}>
      <Input
        autoComplete="newpassword"
        placeholder={placeholderText || ''}
      />
    </Form.Item>
  </Input.Group>;
};

const DATA_QUERY = gql`
  query ContactFormQuery($entityType: EntityTypeEnum, $contactType: ContactTypeEnum) {
    contactCategories(criteria: { entityType: $entityType, contactType: $contactType }) {
      hash
      nodes {
        code
        heading
      }
    }
  }
`;

export default ContactFormInput;
