import React, { useContext } from 'react';
import ActivitySinglePersonDetails from './ActivitySinglePersonDetails';
import ActivityMultiPersonsDetails from './ActivityMultiPersonsDetails';
import { ActivityDetailContext } from '../ActivityDetailsTypes';
import { useActivityPersonSelection } from './useActivityPersonSelection';
import { AttachmentRuleEnum } from '../../../../../gql/typings';

const ActivityPersonsDetails: React.FC = () => {
  const { activityType } = useContext(ActivityDetailContext);
  const moduleEnabled = activityType && activityType.personRule !== AttachmentRuleEnum.NONE;

  const use = useActivityPersonSelection();

  if (!moduleEnabled) return <></>;

  return (
    <div className="activity-persons-details-container" data-rule={activityType?.personRule}>
      {activityType?.personRule === AttachmentRuleEnum.ONE || activityType?.personRule === AttachmentRuleEnum.OPTIONAL_ONE
        ? <ActivitySinglePersonDetails use={use} />
        : <ActivityMultiPersonsDetails use={use} />}
    </div>
  );
};


export default ActivityPersonsDetails;
