import { useQuery, gql } from '@apollo/client';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { debounce } from 'lodash';
import { Form, Select } from 'antd';
import { useIntl } from 'react-intl';
import { DynamicReportInputProps } from './dynamicReportInputUtils';
import { CountriesQueryForReportsQuery, CountriesQueryForReportsQueryVariables } from '../../../../../gql/typings';

const ReportCountries: React.FC<DynamicReportInputProps & { initialAllSelected?: boolean }> = ({
  controller,
  inputDef,
  initialAllSelected = false,
}) => {
  const { formatMessage } = useIntl();
  const {
    data,
    loading,
    refetch,
  } = useQuery<CountriesQueryForReportsQuery, CountriesQueryForReportsQueryVariables>(DATA_QUERY);

  const searchDebounced = debounce(
    search => refetch({ search: `%${search}%` }),
    25,
  );

  useEffect(() => {
    if (initialAllSelected
      && !loading
      && !controller.form.getFieldValue(inputDef.labelKey!)
      && data && data.countries && data.countries.nodes
    ) controller.form.setFieldsValue({
      [inputDef.labelKey!]: data.countries.nodes.map(u => u.code),
    });
  }, [loading]);

  return (
    <Form.Item name={inputDef.labelKey!} label={formatMessage({ id: inputDef.labelKey! })} rules={inputDef.rules}>
      <Select
        mode="multiple"
        placeholder={inputDef.placeholderKey}
        onSearch={searchDebounced}
        loading={loading}
        style={{ width: 400 }}
        filterOption={false}
        allowClear
      >
        {((data && data.countries && data.countries.nodes) || []).map(country => (
          <Select.Option key={country.code} value={country.code}>
            {country.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const DATA_QUERY = gql`
  query CountriesQueryForReports($search: String) {
    countries(criteria: {
      label: $search,
      ordering: [ALPHABETICALLY],
      fetchSize: { limit: 1000 }
    }) {
      hash
      nodes {
        code
        label
      }
    }
  }
`;

export default ReportCountries;
