import React, { useState } from 'react';
import { Button, DatePicker, message, Popconfirm, Space } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import Icon from '../../../../components/Icon/Icon';
import { usePermission } from '../../../../permission/usePermission';
import { useColors } from '../../../../util/useColor';
import {
  EntityTypeEnum, hasMdmAccessQuery, hasMdmAccessQueryVariables,
  InsertEntityValidationLogMutation,
  InsertEntityValidationLogMutationVariables
} from '../../../../../gql/typings';
import { DATE_FORMAT } from '../../../../util/format';


type ValidationLogLogProps = {
  affiliationId: number;
  title?: React.ReactNode | string | null | undefined;
  entity: EntityTypeEnum;
};

const ValidationLog: React.FC<ValidationLogLogProps> = ({ affiliationId, entity, title }) => {
  const localization = useLocalization();
  const { colorPrimary: primaryColor } = useColors();
  const [vTime, setVTime] = useState<Dayjs | null>(dayjs());
  const { read, create } = usePermission('VALIDATE_RECORD');
  const [
    insertEntityValidationLog,
  ] = useMutation<InsertEntityValidationLogMutation, InsertEntityValidationLogMutationVariables>(INSERT_VALIDATION);

  const {
    data,
    loading
  } = useQuery<hasMdmAccessQuery, hasMdmAccessQueryVariables>(DATA_QUERY, {
    skip: !read,
    variables: {
      id: affiliationId,
      entityType: entity
    },
  });

  const format = DATE_FORMAT;
  const [addNewDate, setNewDate] = useState<boolean>(false);
  const date = dayjs(data?.getAffiliationByEntity?.imfVt).format(format);

  const onSubmit = () => {
    insertEntityValidationLog({
      variables: {
        id: affiliationId,
        valDate: addNewDate ? vTime : dayjs(),
        entity,
      },
      refetchQueries: ['PersonCardContainerQuery', 'validationLogHistory', 'entityValidationLogsByValues'],
    })
      .finally(() => message.success(`${title} Has successfully been validated`));
  };

  const oneYearAgo = dayjs().subtract(1, 'years').format(format);
  const fiveYearsAgo = dayjs().subtract(5, 'years').format(format);

  if (!read) {
    return <></>;
  }


  const isPrevValidated = data?.getAffiliationByEntity?.imfVt != null;


  const isLessThanYear = dayjs(dayjs(date, format)).isAfter(dayjs(oneYearAgo, format), 'day');

  const isMoreThanFiveYears = dayjs(dayjs(date, format)).isBefore(dayjs(fiveYearsAgo, format), 'day');

  const buttonClassName = !isPrevValidated
    ? 'button-type-warning' : isMoreThanFiveYears
      ? 'button-type-warning' : !isLessThanYear
        ? 'button-type-action': 'ant-btn-primary';

  const label = isPrevValidated
    ? localization.formatText(Locale.Text.Validation_Date_Found, { date })
    : localization.formatText(Locale.Text.Validation_Date_Not_Found);

  const div = <div>{label}<br />
    {addNewDate && <Space>
      <DatePicker
        format={DATE_FORMAT}
        disabledDate={(current) => {
          const customDate = dayjs().format(format);
          return current && current > dayjs(customDate, format);
        }}
        defaultValue={vTime || dayjs()}
        onChange={(value: Dayjs | null) => setVTime(value)}
        placeholder="Validation date"
      />
      <Button onClick={() => setNewDate(false)}>
        {localization.formatMessage(Locale.Command.Cancel)}
      </Button>
    </Space>}
    {!addNewDate && <Icon
      iconType="PLUS"
      text="Select other date"
      onClick={() => setNewDate(true)}
      style={{ marginTop: 5, color: primaryColor }}
    />}
  </div>;
  return (
    <div className="button-component-container">
      <Popconfirm
        title={div}
        disabled={!create}
        onConfirm={onSubmit}
        okText="Yes"
        cancelText="No"
      >
        <Button
          className={buttonClassName}
          loading={loading}
          size="small"
          disabled={!create}
        >
          {isPrevValidated ? localization.formatMessage(Locale.Attribute.Validated)
            : localization.formatMessage(Locale.Command.Validate)}
        </Button>
      </Popconfirm>
    </div>

  );
};
const INSERT_VALIDATION = gql`
  mutation InsertEntityValidationLog($id: Int!, $entity: EntityTypeEnum!, $valDate: DateTime!) {
    insertEntityValidationLog(affiliationId: $id, entityType: $entity, validationDate: $valDate) {
      id
      entityAffiliationId
      entityTypeId
      validatedTime
      ct
      ut
      validatedByUser
      affiliationEntity {
        id
        ... on Person { imfVt }
        ... on Site { imfVt }
        ... on SitePerson { imfVt }
      }
    }
  }
`;
const DATA_QUERY = gql`
  query hasMdmAccess($id: Int!, $entityType: EntityTypeEnum!) {
    getAffiliationByEntity(id: $id, entityType: $entityType){
      id
      imfVt
      ct
      maintainerSourceCode
    }
  }
`;


export default ValidationLog;
