import React, { FC, Suspense, lazy, CSSProperties } from 'react';
import { StateName } from '@antv/g2plot';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Property } from 'csstype';

// TODO: Improve this loading screen
//       Preferably by using inheritance within the chartProps with width/height
const Loading: FC = () => (<span>Loading...</span>);


// ======================================================= //
//                                                         //
//                      PIE CHART                          //
//                                                         //
// ======================================================= //
const Pie = lazy(() => import('./PieChart_NON_LAZY'));

export type PieChartDataItem = {
  code?: string|null;
  label: string;
  value: number;
};

export type PieChartProps = {
  data: PieChartDataItem[];
  onSelect?: (item: PieChartDataItem, state: StateName, selectedItems: PieChartDataItem[]) => void;
  interaction?: boolean;
  visible?: boolean;
  style?: CSSProperties;

  width?: number;
  height?: number;
};

export const PieChart: FC<PieChartProps> = props => (
  <Suspense fallback={<Loading />}>
    <Pie {...props} />
  </Suspense>
);


// ======================================================= //
//                                                         //
//                 COLUMN SERIES CHART                     //
//                                                         //
// ======================================================= //
const ColumnSeries = lazy(() => import('./ColumnSeriesChart_NON_LAZY'));

export type ColumnChartDataItem = { label: string; seriesLabel: string; value: number };
export type ColumnChartProps = {
  data: ColumnChartDataItem[];
  width?: number;
  height?: number;
  style?: CSSProperties;
};

export const ColumnSeriesChart: FC<ColumnChartProps> = props => (
  <Suspense fallback={<Loading />}>
    <ColumnSeries {...props} />
  </Suspense>
);


// ======================================================= //
//                                                         //
//                     LINE CHART                          //
//                                                         //
// ======================================================= //
const Line = lazy(() => import('./LineChart_NON_LAZY'));


export type LineChartDataItem = {
  label: string;
  value: number;
};
export type LineChartProps = {
  data: LineChartDataItem[];
  height?: number;
  width?: number;
};

export const LineChart: FC<LineChartProps> = props => (
  <Suspense fallback={<Loading />}>
    <Line {...props} />
  </Suspense>
);


// ======================================================= //
//                                                         //
//               PERCENTAGE COLUMN CHART                   //
//                                                         //
// ======================================================= //
const PercentageColumn = lazy(() => import('./PercentageColumnChart_NON_LAZY'));

export type PercentageColumnChartDataItem = {
  label: string;
  seriesLabel: string;
  percentage: number;
  value: number;
};
export type PercentageColumnChartProps = {
  data: PercentageColumnChartDataItem[];
  width?: number;
  height?: number;
  style?: CSSProperties;
};

export const PercentageColumnChart: FC<PercentageColumnChartProps> = props => (
  <Suspense fallback={<Loading />}>
    <PercentageColumn {...props} />
  </Suspense>
);

// ======================================================= //
//                                                         //
//               PERCENTAGE COLUMN CHART                   //
//                                                         //
// ======================================================= //
const StackedColumn = lazy(() => import('./StackedColumnChart_NON_LAZY'));

export type StackedColumnChartDataItem = {
  label: string;
  seriesLabel: string;
  value: number;
  color: string;
};

export type StackedColumnChartProps = {
  data: StackedColumnChartDataItem[];
  width?: number;
  height?: number;
  style?: CSSProperties;

  legend?: boolean;
  theme?: {
    colors10: Property.Color[];
    colors20?: Property.Color[];
    colors30?: Property.Color[];
  };
};

export const StackedColumnChart: FC<StackedColumnChartProps> = props => (
  <Suspense fallback={<Loading />}>
    <StackedColumn {...props} />
  </Suspense>
);
