import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Button, Col, Form, Result, Row, Skeleton } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { FormProps } from 'antd/lib/form/Form';
import { isArray, isEmpty } from 'lodash';
import MainView from '../../../components/View/MainView';
import LiveSiteMatchSearch from '../../../components/LiveSearch/LiveSiteMatchSearch';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { Optional, RecursivePartial } from '../../../util/StateArrayType';
import { CreateSiteDataQueryQuery, EntityTypeEnum, SiteInput } from '../../../../gql/typings';
import { useSystemCountriesState } from '../../../util/useSystemCountriesState';
import { createSiteFields } from './CreateSiteFields';
import Loading from '../../../components/Loading/Loading';
import { useEntityFieldData } from '../../../util/useEntityFieldData';
import { useQueryParams } from '../../../util/useQueryParams';

type CreateSiteProps = {
  loading?: boolean;
  initialState?: Optional<RecursivePartial<SiteInput>>;
  allowChooseMaintainer: boolean;
};

export const SiteInputCodeMap = {
  APB_NAME: 'name',
  APB_TYPE: 'typeCode'
};

export type CreateSiteRef = {
  clear: () => void;
};

const CreateSite = forwardRef<CreateSiteRef, CreateSiteProps>(({
  loading,
  initialState,
  allowChooseMaintainer,
}, ref) => {
  const [form] = Form.useForm();
  const localization = useLocalization();
  const noDepartmentState = useState(false);
  const [systemCountries] = useSystemCountriesState();
  const { data, loading: isDataLoading } = useQuery<CreateSiteDataQueryQuery>(DATA_QUERY);
  const queryObject = useQueryParams();

  const { fieldKeys, fieldLoading } = useEntityFieldData(systemCountries?.[0], EntityTypeEnum.SITE);


  const initialValues = useMemo(() => ({
    ...(initialState ?? {}),
    country: systemCountries?.length === 1 ? systemCountries[0] : undefined,
  }), [initialState, systemCountries]);

  useEffect(() => {
    if (!isEmpty(queryObject)) {
      const tempInitValues = Object.keys(queryObject).reduce((acc, curr) => {
        if (!curr) return acc;
        if (!Object.prototype.hasOwnProperty.call(SiteInputCodeMap, curr)) return acc;
        // @ts-ignore
        const mappedKey = SiteInputCodeMap[curr];
        return { ...acc, [mappedKey]: queryObject[curr] };
      }, {});
      form.setFieldsValue({ ...initialValues, ...tempInitValues });
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [queryObject, initialValues, form]);

  useImperativeHandle(ref, () => ({
    clear: () => {
      const emptyInitialValues = initialValues
        ? Object.keys(initialValues).reduce((acc, curr) => ({ ...acc, [curr]: undefined }), {})
        : {};
      form.setFieldsValue(emptyInitialValues);
      noDepartmentState[1](false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [form, initialValues]);

  if (data && !data.canICreate) return <MainView className="create-site-container">
    <Result
      status="403"
      title={localization.formatMessage(Locale.General.Permission_denied)}
      subTitle={localization.formatMessage(Locale.Text.Missing_permission_to_create_site)}
    />
  </MainView>;

  const formItemLayout: Pick<FormProps, 'labelCol'|'wrapperCol'> = {
    labelCol: { xs: { span: 24 }, sm: { span: 6 }, md: { span: 5 }, lg: { span: 3 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
  };
  const formItemLayoutWithOutLabel: Pick<FormProps, 'wrapperCol'> = {
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16, offset: 6 },
      md: { span: 16, offset: 5 },
      lg: { span: 16, offset: 3 },
    },
  };

  if (fieldLoading) return <Loading />;

  return (
    <Skeleton loading={isDataLoading} active={isDataLoading}>
      <Form
        id="create-site-container"
        name="site"
        form={form}
        {...formItemLayout}
        initialValues={initialValues}
      >
        <Row>
          <Col span={16}>
            {
              fieldKeys && Object.keys(fieldKeys).map(f => {
                const key = f as keyof SiteInput;
                const Component = createSiteFields[key];
                if (isArray(fieldKeys[f])) {
                  return <Component
                    key={f}
                    keyCodes={fieldKeys[f] as string[]}
                    formItemLayoutWithOutLabel={formItemLayoutWithOutLabel}
                    allowChooseMaintainer={allowChooseMaintainer}
                    formItemLayout={formItemLayout}
                    noDepartmentState={noDepartmentState}
                  />;
                }
                return <Component
                  key={f}
                  formItemLayoutWithOutLabel={formItemLayoutWithOutLabel}
                  allowChooseMaintainer={allowChooseMaintainer}
                  formItemLayout={formItemLayout}
                  noDepartmentState={noDepartmentState}
                />;
              })
            }
            <Button
              id="create-site-button"
              loading={loading}
              htmlType="submit"
              type="primary"
            >
              {localization.formatMessage(Locale.Command.Create)}
            </Button>
          </Col>
          <Col span={8}>
            <Form.Item shouldUpdate={() => true} noStyle>
              {({ getFieldsValue }) => (
                <LiveSiteMatchSearch
                  buildSearch={() => {
                    const values = getFieldsValue() as RecursivePartial<SiteInput>;
                    const name = `${values.name ?? ''} ${noDepartmentState[0] ? (values.departmentName ?? '') : ''}`.trim();
                    return {
                      name: name.length > 0 ? name : undefined,
                      countryCode: values.country,
                      city: values.addresses?.[0]?.city,
                      zip: values.addresses?.[0]?.postalCode,
                      type: values.typeCode,
                    };
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Skeleton>
  );
});

const DATA_QUERY = gql`
  query CreateSiteDataQuery {
    canICreate(entityType: SITE)
  }
`;


export default CreateSite;
