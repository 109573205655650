import React from 'react';
import { useMutation } from '@apollo/client';
import { Col, Row, Switch, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { CustomFieldProps, UPSERT_MUTATION } from './CustomFieldUtils';
import { extractBooleanValue } from '../../util/Util';
import Icon from '../Icon/Icon';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';


const CustomFieldSwitch: React.FC<CustomFieldProps> = ({ recordId, field, values, refreshData }) => {
  const localization = useLocalization();
  const [
    upsert
  ] = useMutation(UPSERT_MUTATION);

  const selectedValue = !!values.nodes[0];
  const value = selectedValue ? extractBooleanValue(values.nodes[0]!.value!.value) : false;

  const onChange = (checked: boolean) => {
    upsert({
      variables: {
        recordId,
        customFieldCode: field.code,
        customValues: [{ newValue: checked ? 'true' : 'false' }],
      }
    }).finally(refreshData);
  };


  const settings = field.customFieldControlSetting;

  return (
    <Row className="custom-field-select-grid">
      <Col span={settings?.isSynced ? 23 : 24}>
        <Tooltip
          title={field.accessRules?.allowMutation
            ? '' : localization.formatMessage(Locale.Text.Mutation_permission_denied)}
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            disabled={!field?.accessRules?.allowMutation}
            onChange={onChange}
            checked={value}
          /> </Tooltip></Col>

      {
        settings?.isSynced
        && <Col span={1}><Icon toolTip="is synchronized" style={{ marginTop: 8, marginLeft: 8 }} iconType="SYNC" /></Col>
      }
    </Row>
  );
};
export default CustomFieldSwitch;
