import { Tooltip, Typography } from 'antd';
import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { EntityTypeEnum, FieldEnum, SitePersonCriteriaOrder } from '../../../../gql/typings';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';

export const linkPersonEducationsFieldConfig = linkFieldConfig(
  'personEducations',
  [
    'id',
    'person.id',
    'person.educations.hash',
    'person.educations.nodes.code',
    'person.educations.nodes.type',
    'person.educations.nodes.label',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Person_Education),
    additionalTableConfig: {
      width: 150
    },
    sorting: {
      ascend: [SitePersonCriteriaOrder.LINK_PERSON_EDUCATION_ASC],
      descend: [SitePersonCriteriaOrder.LINK_PERSON_EDUCATION_DESC],
    },
    dcrInfo: record => ({
      entityType: EntityTypeEnum.PERSON,
      entityAffiliationId: record.person.id,
      field: FieldEnum.EDU_CODE,
    }),
    render: (record, { isViewingFromPage }) => {
      const educationField = record.person.educations.nodes.map(e => e.label).join(', ');
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={educationField}>{educationField}</Tooltip>
      </Typography.Paragraph>);
    },
  })
);

