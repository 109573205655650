import React from 'react';
import { flatten, isFunction } from 'lodash';
import { useQuery } from '@apollo/client';
import { Skeleton } from 'antd';
import {
  entitiesTableConfigs,
  SupportedEntitySearchTypes,
  TableFieldReturnedRecordOptions,
  TableFieldReturnedRecordPageType,
} from '../../browse/search_old/types';
import AttributeLabel from '../Label/AttributeLabel';
import { generateGraphQL } from '../../util/graphqlUtils';
import { useFieldOptionAttributes } from './useFieldOptionAttributes';
import { GridCardFieldFragment } from '../../../gql/typings';


type CardWithFieldsDrawerItemProps = {
  recordId: number;
  entityType: SupportedEntitySearchTypes;
  rootEntityType?: SupportedEntitySearchTypes;
  fields: GridCardFieldFragment[];
};

const CardWithFieldsDrawerItems: React.FC<CardWithFieldsDrawerItemProps> = ({
  recordId,
  entityType,
  rootEntityType,
  fields,
}) => {
  const typeConfig = entitiesTableConfigs[entityType];
  const { optionMapGet, items } = useFieldOptionAttributes(entityType, fields);
  const query = generateGraphQL(flatten(items.map(i => i[0].updatedQueries)).filter(e => e) as string[]);
  const { data, refetch } = useQuery(typeConfig.buildRecordQuery(query), { variables: { recordId } });

  return (
    <>
      {items.map(([config, { keyCode }]) => {
        const optionData = optionMapGet(config.key);

        // MARK: We're wrapping this, as we want the render to be able to hold its own hooks and states.
        const Render: React.FC = () => <>{config.render(data.record, props)}</>;

        const props: TableFieldReturnedRecordOptions = {
          selectedOption: optionData?.filter(o => o.code == keyCode)[0],
          refetchData: refetch,
          isViewingFromEntity: rootEntityType ?? entityType,
          isViewingFromPage: TableFieldReturnedRecordPageType.DETAIL_PAGE,
        };

        return (
          <AttributeLabel
            key={config.key + (keyCode ?? '')}
            label={isFunction(config.title) ? config.title(props) : config.title}
            value={data?.record ? <Render /> : <Skeleton.Input active size="small" block />}
          />
        );
      })}
    </>
  );
};

export default CardWithFieldsDrawerItems;
