import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';

export const siteMaintainerSourceFieldConfig = siteFieldConfig(
  'maintainerSource',
  [
    'maintainerSource.code',
    'maintainerSource.heading',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Maintainer_Source),
    additionalTableConfig: {
      width: 100
    },
    render: (record) => record.maintainerSource.heading,
  })
);

