import { siteSelectionRowFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { Locale } from '../../../../localization/LocalizationKeys';
import { SiteSelectionRowCriteriaOrder } from '../../../../gql/typings';

export const siteSelectionRowCustomerNameFieldConfig = siteSelectionRowFieldConfig(
  'customerName',
  [
    'selectionRow.id',
    'selectionRow.group.id',
    'selectionRow.group.selection.id',
    'selectionRow.group.selection.customer.code',
    'selectionRow.group.selection.customer.callName',
    'selectionRow.group.selection.customer.name',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Customer),
    sorting: {
      ascend: SiteSelectionRowCriteriaOrder.CUSTOMER_NAME_ASC,
      descend: SiteSelectionRowCriteriaOrder.CUSTOMER_NAME_DESC,
    },
    render: record => {
      if (!record.selectionRow) return localization.formatMessage(Locale.General.Unknown);
      const { customer } = record.selectionRow.group?.selection;
      return customer.callName ?? customer.name;
    },
  }),
);
