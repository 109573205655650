import React from 'react';
import { gql } from '@apollo/client';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { MergeItemPersonFirstNameFragment, useUpdatePersonNameMutation } from '../../../../../../../../../gql/typings';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../../../../../util/useLocalization';


const AdminMergeItemPersonFirstName: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonFirstNameFragment;
  const localization = useLocalization();
  const [updateName] = useUpdatePersonNameMutation();

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.from}
      toValue={info.to}
      updateMutation={newValue => updateName({
        variables: {
          personId: props.into.id,
          newValue,
        },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_first_names_mismatch_description, {
        name1: <strong>{info.from}</strong>,
        name2: <strong>{info.to}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation UpdatePersonName($personId: Int!, $newValue: String!) {
    updatePersonFirstName(persId: $personId, firstName: $newValue) {
      id
      firstName
    }
  }
`;

gql`
  fragment MergeItemPersonFirstName on PersonFirstName_EntityDataToBeTransferredType {
    code
    from
    to
  }
`;

export default AdminMergeItemPersonFirstName;
