import React, { useEffect, useState } from 'react';
import { Radio, Space } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import MergeEntityLink from '../../components/MergeEntityLink';
import { REMOVE_PRIMARY_MUTATION } from '../../link/LinkIsPrimary/AdminMergeItemAffiliationIsPrimary';
import { MergeEventValue } from '../../../useEntityMergeEmitter';
import {
  EntityTypeEnum,
  MergeRemovePrimaryLinkMutation,
  MergeRemovePrimaryLinkMutationVariables,
  MRPersonLinkPrimaryFragment,
  MRPersonLinksPrimaryResolverQuery,
  MRPersonLinksPrimaryResolverQueryVariables,
} from '../../../../../../../../../gql/typings';
import MergeRequestItemPrefixIcon from '../../components/MergeRequestItemPrefixIcon';

type AdminMergeItemPersonLinkPrimaryStatusResolverProps = {
  emitterKey: string;
  emitter: EventEmitter<MergeEventValue>;
  fromPersonId: number;
  intoPersonId: number;
};

const AdminMergeItemPersonLinkPrimaryStatusResolver: React.FC<AdminMergeItemPersonLinkPrimaryStatusResolverProps> = ({
  emitterKey,
  emitter,
  fromPersonId,
  intoPersonId,
}) => {
  const localization = useLocalization();
  const [selectedLinkId, setSelectedLinkId] = useState<number>();
  const { data } = useQuery<MRPersonLinksPrimaryResolverQuery, MRPersonLinksPrimaryResolverQueryVariables>(DATA_QUERY, {
    variables: { fromPersonId, intoPersonId }
  });
  const [remove] = useMutation<MergeRemovePrimaryLinkMutation, MergeRemovePrimaryLinkMutationVariables>(
    REMOVE_PRIMARY_MUTATION,
  );

  const fromLink = data?.from.nodes[0];
  const toLink = data?.into.nodes[0];

  useEffect(() => {
    emitter.emit({
      key: emitterKey,
      state: selectedLinkId ? 'finished' : 'not_started',
      _source: 'AdminMergeItemPersonLinkPrimaryStatusResolver:useEffect',
      onSave: () => {
        if (!selectedLinkId) return Promise.reject(
          Error("You need to select the primary link, or select 'none' as the primary")
        );

        if (selectedLinkId === toLink?.id) return remove({
          variables: { personId: fromPersonId, currentPrimaryLink: fromLink!.id },
        });

        return remove({
          variables: {
            personId: intoPersonId,
            currentPrimaryLink: toLink!.id,
          }
        });
      },
    });
  }, [emitter, emitterKey, fromLink, fromPersonId, intoPersonId, remove, selectedLinkId, toLink]);

  const radio = (link: MRPersonLinkPrimaryFragment) => (
    <Radio key={link.id} value={link.id}>
      <MergeEntityLink type={EntityTypeEnum.AFFILIATION} id={link.id}>{link.position?.displayLabel}</MergeEntityLink>
      &nbsp;@&nbsp;
      <MergeEntityLink type={EntityTypeEnum.SITE} id={link.site.id}>
        {link.site.name}
      </MergeEntityLink>
    </Radio>
  );

  return (
    <Space direction="vertical">
      <MergeRequestItemPrefixIcon state={selectedLinkId ? 'OK' : 'CONFLICT'}>
        {fromLink && localization.formatText(Locale.Text.Merge_request_person_link_primary_description, {
          positionAtSite: <MergeEntityLink type={EntityTypeEnum.AFFILIATION} id={fromLink.id}>
            {fromLink.position?.displayLabel} @ {fromLink.site.name}
          </MergeEntityLink>,
        })}
      </MergeRequestItemPrefixIcon>
      <Radio.Group
        value={selectedLinkId}
        onChange={e => setSelectedLinkId(e.target.value)}
      >
        <Space direction="vertical">
          {fromLink && radio(fromLink)}
          {toLink && radio(toLink)}
        </Space>
      </Radio.Group>
    </Space>
  );
};

const DATA_QUERY = gql`
  query MRPersonLinksPrimaryResolver($fromPersonId: ID!, $intoPersonId: ID!) {
    from: sitePersonAffiliations(criteria: { personId: $fromPersonId, primaryOnly: true }) {
      hash
      nodes { ...MRPersonLinkPrimary }
    }
    into: sitePersonAffiliations(criteria: { personId: $intoPersonId, primaryOnly: true }) {
      hash
      nodes { ...MRPersonLinkPrimary }
    }
  }

  fragment MRPersonLinkPrimary on SitePerson {
    id
    isPrimary
    person {
      id
      fullName
    }
    site {
      id
      name
    }
    position {
      code
      type
      displayLabel
    }
  }
`;

export default AdminMergeItemPersonLinkPrimaryStatusResolver;
