import React from 'react';
import { Form } from 'antd';
import { EntitiesSearch } from '../../components/entitiesSearch';
import MainView from '../../components/View/MainView';
import { useTableRowSelection } from '../../components/Table/useTableRowSelection';
import { EntityTypeEnum } from '../../../gql/typings';
import { useLinkTableQuickActions } from './hooks/useLinkTableQuickActions';

const LinkView: React.FC = () => {
  const [form] = Form.useForm();
  const { tableProps } = useTableRowSelection({ form });
  const { entitiesSearchProps, tableSelection, HiddenRender } = useLinkTableQuickActions(form, { maintainPageState: true });

  return (
    <MainView
      className="activity-view-container2"
      boundingBox={false}
    >

      <EntitiesSearch
        {...tableProps}
        entityType={EntityTypeEnum.AFFILIATION}
        form={form}
        urlSearchEnabled
        tableSelection={tableSelection}
        {...entitiesSearchProps}
      />
      <HiddenRender />
    </MainView>
  );
};


export default LinkView;
