import { Locale } from '../../../../localization/LocalizationKeys';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';

export const linkOriginSourceFieldConfig = linkFieldConfig(
  'originSource',
  [
    'originSource.code',
    'originSource.heading',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Origin_Source),
    additionalTableConfig: {
      width: 100
    },
    render: (record) => record.originSource.heading,
  })
);
