import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Select } from 'antd';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import {
  MergeItemPersonTagsFragment,
  MRFilterCodesQuery,
  MRSetTagsMutation,
  MRSetTagsMutationVariables
} from '../../../../../../../../../gql/typings';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { reactJoin } from '../../../../../../../../util/reactJoin';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';


const AdminMergeItemPersonTags: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonTagsFragment;
  const localization = useLocalization();
  const { data, loading } = useQuery<MRFilterCodesQuery>(DATA_QUERY);
  const [update] = useMutation<MRSetTagsMutation, MRSetTagsMutationVariables>(SET_TAGS_MUTATION);

  if (!props.item.hasConflict) return (
    <AdminMergeItemBasicField {...props}>
      {localization.formatMessage(Locale.Text.Merge_request_person_tags_description, {
        tags: reactJoin(info.loserCodes.map(lc => <strong key={lc.id}>{lc.filterCode.header}</strong>))
      })}
    </AdminMergeItemBasicField>
  );

  return (
    <AdminMergeItemBasicConflictField<number[]>
      {...props}
      fromValue={info.loserCodes.map(lc => lc.filterCode.id)}
      toValue={info.winnerCodes.map(lc => lc.filterCode.id)}
      updateMutation={filterCodeIds => update({
        variables: {
          personId: props.into.id,
          filterCodeIds,
        }
      })}
      valueDisplayMapper={ids => reactJoin((data?.filterCodes?.nodes ?? []).filter(n => ids?.includes(n.id)).map(fc => (
        <strong key={fc.id}>{fc.header}</strong>
      )))}
      inputRenderer={props => (
        <Select
          {...props}
          loading={loading}
          mode="tags"
          options={(data?.filterCodes.nodes ?? []).map(fc => ({ value: fc.id, label: fc.header }))}
        />
      )}
    >
      {localization.formatMessage(Locale.Text.Merge_request_person_tags_mismatch_description, {
        tags1: reactJoin(info.loserCodes.map(lc => <strong key={lc.id}>{lc.filterCode.header}</strong>)),
        tags2: reactJoin(info.winnerCodes.map(lc => <strong key={lc.id}>{lc.filterCode.header}</strong>)),
      })}
    </AdminMergeItemBasicConflictField>
  );
};

const SET_TAGS_MUTATION = gql`
  mutation MRSetTags($personId: Int!, $filterCodeIds: [Int!]!) {
    updatePersonFilterCode(personId: $personId, filterCodeIds: $filterCodeIds) {
      id
      personId
    }
  }
`;

const DATA_QUERY = gql`
  query MRFilterCodes {
    filterCodes(criteria: { fetchSize: { limit: 10000 } }) {
      hash
      nodes {
        id
        header
      }
    }
  }
`;

gql`
  fragment MergeItemPersonTags on PersonTags_EntityDataToBeTransferredType {
    code
    entityType
    
    loserCodes {
      id
      filterCode { id, header }
    }
    winnerCodes {
      id
      filterCode { id, header }
    }
  }
`;

export default AdminMergeItemPersonTags;
