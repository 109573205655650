import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import DateDisplay from '../../../components/Badge/DateDisplay';
import { personFieldConfig } from './utils/personFieldsUtils';
import { SourceEnum } from '../../../../gql/typings';

export const personCreatedFieldConfig = personFieldConfig(
  'created',
  ['imfCt', 'ct', 'maintainerSourceCode'],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Created),
    additionalTableConfig: {
      width: 125
    },
    render: (record) => {
      const isLocalDB = record.maintainerSourceCode === SourceEnum.LOCAL;
      return <DateDisplay>{isLocalDB ? record.ct : record.imfCt}</DateDisplay>;
    },
  })
);

