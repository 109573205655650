import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Property } from 'csstype';
import moment from 'moment';
import { Col, Popover, Row } from 'antd';
import { useIntl } from 'react-intl';
import CalendarCardCount from '../Badge/CalenderCardCount';
import { NoValue } from '../../util/StateArrayType';
import { isActualNumber } from '../../util/Util';
import Icon from '../Icon/Icon';
import ActivitySiteAddress from '../../browse/activity/components/ActivitySiteAddress';
import CalendarDateDisplay from './CalendarDateDisplay';
import DisplayPersons from '../Badge/DisplayPersons';
import { ActivityStatusesQueryQuery, ViewerActivitiesCalendarQuery } from '../../../gql/typings';


type CalendarCardProps = {
  title: string;
  status?: NonNullable<ActivityStatusesQueryQuery['activityStatuses']>['nodes']['0'];
  phone?: string;
  site?: NonNullable<ViewerActivitiesCalendarQuery['activities']>['nodes']['0']['sites']['nodes']['0'] | undefined;
  persons?: NonNullable<ViewerActivitiesCalendarQuery['activities']>['nodes']['0']['persons'] | undefined;
  activityId?: number;
  startTime?: Date | null;
  endTime?: Date | null;
  daysAgo?: number | NoValue;
  amountOfActivities?: number | NoValue;
  containerHeight?: Property.Height;
  borderColor?: Property.Color;
};

const CalendarCard: React.FC<CalendarCardProps> = React.forwardRef(({
  title,
  site,
  activityId,
  startTime,
  endTime,
  persons,
  phone,
  daysAgo,
  amountOfActivities,
  containerHeight,
  borderColor,
  status,
}, ref) => {

  // @ts-ignore
  const difference = (endTime - startTime) / 36e5;
  const personLength = persons ? persons.nodes.length : 0;
  const intl = useIntl();
  const hoverCard = <div>
    <Row>
      {status
      && <Col span={14}>
        <Icon
          iconType={status.codeEnum}
          style={{ height: '12px' }}
          text={intl.formatMessage({ id: status.headingKey })}
        />
      </Col>}
      {site
      && <Col span={14}>
        <Icon
          iconType="LOCATION"
          style={{ height: '12px' }}
          text={<ActivitySiteAddress site={site} />}
        /></Col>}
      {site?.phone
      && <Col span={14}>
        <Icon iconType="PHONE" style={{ height: '12px' }} text={site?.phone?.value} /></Col>}
      {site && <Col span={14}><Icon
        iconType="CLOCK"
        style={{ height: '12px' }}
        text={<CalendarDateDisplay startTime={startTime} endTime={endTime} />}
      />
      </Col>}
      {personLength > 0 && <Col span={14}>
        <Icon
          iconType="PERSON"
          style={{ height: '12px' }}
          text={<span className="comma-list">{(persons?.nodes ?? []).map(it => (
            <span key={it.id} className="comma">
              {it.fullName}
            </span>
          ))}</span>}
        />
      </Col>}
    </Row>

  </div>;

  return (
    <Popover
      placement="right"
      mouseEnterDelay={2}
      style={{ maxWidth: '400px' }}
      title={
        <div
          style={{
            borderLeft: `2px solid ${borderColor}`,
            backgroundColor: `${borderColor}19`,
            padding: '10px'
          }}
        >
          {title}
        </div>
      }
      content={hoverCard}
    >
      <div
        // @ts-ignore
        ref={ref}
        className="calender-card-container"
        style={{
          height: containerHeight,
          ...(borderColor ? {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            borderLeft: `6px solid ${borderColor}`,
            backgroundColor: `${borderColor}19`
          } : {})
        }}
      >

        <Row>
          <Col className="left" span={18}>
            <h4><b>{title}</b></h4>
          </Col>
          <Col className="right" span={6}>
            {startTime
            && <div className="right-time">
              <Icon iconType="CLOCK" text={moment(startTime).format('HH:mm')} />
            </div>}
          </Col>
          <Col className="left" span={18}>
            {personLength > 0 && <Icon
              iconType="PERSON"
              style={{ height: '14px' }}
              text={<DisplayPersons nodes={persons?.nodes ?? []} />}
            />}
          </Col>

          <Col className="right" span={6}>
            {status
            && <div className="right-time">
              <Icon
                iconType={status.codeEnum}
                style={{ height: '10px', fontSize: '10px' }}
              />
            </div>}
          </Col>

          {(difference > 1 && site != null)
          && <Col className="left" span={24}>
            <Icon iconType="SITE" style={{ height: '14px' }} text={site.name} />
          </Col>}

          {(difference > 1.5 && site?.phone != null)
          && <Col className="left" span={24}><Icon iconType="PHONE" text={site?.phone?.value} /></Col>}
        </Row>
        <div className="bottom">

          <div className="card-count">
            {isActualNumber(daysAgo) && <CalendarCardCount count={daysAgo} />}
            {isActualNumber(amountOfActivities)
            && <Icon iconType="ACTIVITY" style={{ height: 12 }} text={amountOfActivities} />}
          </div>
        </div>
      </div>
    </Popover>);
});

export default CalendarCard;

