import React from 'react';
import { FormInstance } from 'antd';
import CreateCustomFieldTagConfig from './congifuration/CreateCustomFieldTagConfiguration';
import { CreateCustomFieldFormData } from './CreateCustomField';
import CreateCustomFieldSelectConfiguration from './congifuration/CreateCustomFieldSelectConfiguration';
import CreateBasicCustomFieldConfiguration from './congifuration/CreateBasicCustomFieldConfiguration';
import CreateCustonFieldCheckConfiguration from './congifuration/CreateCustonFieldCheckConfiguration';
import { Optional } from '../../../util/StateArrayType';
import { CustomFieldTypeEnum, EntityTypeEnum } from '../../../../gql/typings';


export type CreateCustomFieldTypeConfigurationsField = {
  code: string;
  isActive: boolean;
  allowMultiple: Optional<boolean>;
  allowUserCreate: Optional<boolean>;
  isCheckableTag: Optional<boolean>;
  color: Optional<string>;
};

export type CreateCustomFieldTypeConfigurationsProps = {
  customFieldCode?: string;
  entityType: EntityTypeEnum;
  form: FormInstance<CreateCustomFieldFormData>;
  field: Optional<CreateCustomFieldTypeConfigurationsField>;
};

export const createCustomFieldTypeConfigurations:
Record<CustomFieldTypeEnum, React.FC<CreateCustomFieldTypeConfigurationsProps>> = {
  CHECK: CreateCustonFieldCheckConfiguration,
  // DATE: CreateBasicCustomFieldConfiguration,
  // MULTI_TAG: CreateBasicCustomFieldConfiguration,
  NUMBER: CreateBasicCustomFieldConfiguration,
  SWITCH: CreateBasicCustomFieldConfiguration,
  // PHONE: CreateBasicCustomFieldConfiguration,
  TAG: CreateCustomFieldTagConfig,
  TEXT: CreateBasicCustomFieldConfiguration,
  // RADIO: CreateBasicCustomFieldConfiguration,
  SELECT: CreateCustomFieldSelectConfiguration,
  // MULTI_SELECT: CreateBasicCustomFieldConfiguration,
};
