import { Locale } from '../../../../localization/LocalizationKeys';
import { selectionRowResultFieldConfig } from '../../person/fields/utils/personFieldsUtils';

export const selectionRowResultSelectionGroupDescriptionFieldConfig = selectionRowResultFieldConfig(
  'selectionGroupDescription',
  [
    'selectionRow.id',
    'selectionRow.group.id',
    'selectionRow.group.description',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Group_description),
    render: record => record.selectionRow?.group.description ?? localization.formatMessage(Locale.General.Unknown),
  }),
);
