import { siteSelectionRowFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { Locale } from '../../../../localization/LocalizationKeys';
import { SiteSelectionRowCriteriaOrder } from '../../../../gql/typings';

export const siteSelectionRowSelectionDescriptionFieldConfig = siteSelectionRowFieldConfig(
  'selectionDescription',
  [
    'selectionRow.id',
    'selectionRow.group.id',
    'selectionRow.group.selection.id',
    'selectionRow.group.selection.description',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Selection_description),
    sorting: {
      ascend: SiteSelectionRowCriteriaOrder.SELECTION_DESCRIPTION_ASC,
      descend: SiteSelectionRowCriteriaOrder.SELECTION_DESCRIPTION_DESC,
    },
    render: record => record.selectionRow?.group.selection.description ?? localization.formatMessage(Locale.General.Unknown),
  }),
);
