import React from 'react';
import { gql } from '@apollo/client';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { MergeItemPersonLastNameFragment, useUpdatePersonLastNameMutation } from '../../../../../../../../../gql/typings';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';


const AdminMergeItemPersonLastName: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonLastNameFragment;
  const localization = useLocalization();
  const [updateLastName] = useUpdatePersonLastNameMutation();

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.from}
      toValue={info.to}
      updateMutation={newValue => updateLastName({
        variables: {
          personId: props.into.id,
          newValue,
        },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_last_names_mismatch_description, {
        name1: <strong>{info.from}</strong>,
        name2: <strong>{info.to}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation UpdatePersonLastName($personId: Int!, $newValue: String!) {
    updatePersonLastName(persId: $personId, lastName: $newValue) {
      id
      lastName
    }
  }
`;

gql`
  fragment MergeItemPersonLastName on PersonLastName_EntityDataToBeTransferredType {
    code
    from
    to
  }
`;

export default AdminMergeItemPersonLastName;
