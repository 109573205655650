import { createContext, useContext } from 'react';

type MainViewContext = {
  isBoundingBox: boolean;
};

// @ts-ignore
export const MainViewContext = createContext<MainViewContext>();

export const useMainView = () => useContext(MainViewContext);
