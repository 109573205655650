import React from 'react';
import { Pagination } from 'antd';
import { StateArray } from '../../util/StateArrayType';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';

type ApbPaginationProps = {
  perPageState: StateArray<number>;
  pageState: StateArray<number>;
  totalCount: number;
};

const ApbPagination: React.FC<ApbPaginationProps> = ({
  perPageState,
  pageState,
  totalCount,
  children,
}) => {
  const [page, setPage] = pageState;
  const [perPage, setPerPage] = perPageState;
  const localization = useLocalization();

  const changePage = (p: number, pp?: number) => {
    setPage(p);
    if (pp) setPerPage(pp);
  };

  return (
    <div className="apb-pagination-container">
      {children || <span />}
      <Pagination
        current={page}
        pageSize={perPage}
        pageSizeOptions={['4', '8', '10', '15', '20', '50', '100', '250']}
        total={totalCount}
        showQuickJumper
        size="small"
        showTotal={(total, ranges) => localization.formatMessage(
          Locale.General.Items_Visible,
          { from: ranges[0], to: ranges[1], total },
        )}
        showSizeChanger
        onChange={changePage}
        onShowSizeChange={changePage}
      />
    </div>
  );
};

export default ApbPagination;
