import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Space } from 'antd';
import Icon from '../../../../components/Icon/Icon';
import AdminAddressTypes from '../../adminComponents/AdminAddressTypes/AdminAddressTypes';
import AdminContactCategories from '../../adminComponents/AdminContactCategories/AdminContactCategories';
import AdminSiteBrickTerritories from './bricks/AdminSiteBrickTerritories';
// eslint-disable-next-line max-len
import AdminEntityMergeRequestsOverview
  from '../../adminComponents/AdminEntityMergeRequest/overview/AdminEntityMergeRequestsOverview';
import AnchoredSections, { AnchoredItem } from '../../adminComponents/AdminSection/AnchoredSections';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import ConfigureTableColumnSettings from '../../../../components/ConfigureTableColumnSettings/ConfigureTableColumnSettings';
import {
  TableColumnConfigureSettingType
} from '../../../../components/ConfigureTableColumnSettings/useTableColumnConfigureSettings';
import AdminEntityCustomFields from '../../adminComponents/AdminEntityCustomFields/AdminEntityCustomFields';
import AdminConfigureSearch from '../../adminComponents/AdminConfigureSearch/AdminConfigureSearch';
import { usePermission } from '../../../../permission/usePermission';
import AdminExternalId from '../../adminComponents/AdminExternalId/AdminExternalId';
import { EntityTypeEnum } from '../../../../../gql/typings';


const SiteAdminSettings: React.FC = () => {
  const navigate = useNavigate();
  const localization = useLocalization();
  const categoryPermission = usePermission('CONTACT_CATEGORY');

  return (
    <div className="person-admin-settings-container">
      <AnchoredSections>
        <AnchoredItem anchor="general" title="General">
          <Space size="large" direction="vertical">
            <Button onClick={() => navigate('/admin/hco/layout')}>
              Configure layout
              &nbsp;
              {/* MARK: https://github.com/ant-design/ant-design-icons/issues/71#issuecomment-816735899 */}
              <Icon iconType="LINK" />
            </Button>
          </Space>
        </AnchoredItem>

        <AnchoredItem anchor="search" title={localization.formatMessage(Locale.Command.Search)}>
          <AdminConfigureSearch entityType={EntityTypeEnum.SITE} />
        </AnchoredItem>

        <AnchoredItem anchor="merge" title="Merge requests">
          <AdminEntityMergeRequestsOverview entityType={EntityTypeEnum.SITE} />
        </AnchoredItem>

        <AnchoredItem anchor="custom" title="Own Fields">
          <AdminEntityCustomFields entityType={EntityTypeEnum.SITE} />
        </AnchoredItem>

        <AnchoredItem anchor="brick" title="Brick & Territories">
          <AdminSiteBrickTerritories />
        </AnchoredItem>


        <AnchoredItem anchor="address" title="Address types">
          <AdminAddressTypes entityType={EntityTypeEnum.SITE} />
        </AnchoredItem>

        <AnchoredItem anchor="external-id" title={localization.formatMessage(Locale.Attribute.External_IDs)}>
          <AdminExternalId entityType={EntityTypeEnum.SITE} />
        </AnchoredItem>

        <AnchoredItem anchor="contact" title="Contact categories">
          <AdminContactCategories entityType={EntityTypeEnum.SITE} permission={categoryPermission} />
        </AnchoredItem>

        <AnchoredItem anchor="table-columns" title={localization.formatMessage(Locale.General.Table_columns)}>
          <ConfigureTableColumnSettings
            entityType={EntityTypeEnum.SITE}
            type={TableColumnConfigureSettingType.SYSTEM}
          />
        </AnchoredItem>

      </AnchoredSections>
    </div>
  );
};

export default SiteAdminSettings;
