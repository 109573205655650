import { Col, Row } from 'antd';
import React from 'react';
import Icon, { ITE } from '../../../../components/Icon/Icon';

type UserAdminCardProps = {
  // TODO: Should only be {id:string}!
  title: string|{ id: string };
  icon: ITE|null;
};

const UserAdminCard: React.FC<UserAdminCardProps> = ({
  title,
  icon,
  children,
}) => (
  <Col lg={12} md={24} className="user-admin-card-container">
    <Row>
      <Col className="title">
        {title}
      </Col>
    </Row>
    <Row className="card-content">
      <Col span={4} className="icon-area">
        {icon && <Icon iconType={icon} style={{ fontSize: 48 }} />}
      </Col>
      <Col span={20} className="content">
        {children}
      </Col>
    </Row>
  </Col>
);

export default UserAdminCard;
