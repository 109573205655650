import React, { useMemo, useState } from 'react';
import { Button, DatePicker, message, Space } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import Icon from '../../../../components/Icon/Icon';
import { usePermission } from '../../../../permission/usePermission';
import { useColors } from '../../../../util/useColor';
import { EntitiesSearchQuickActionItem } from '../../../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import {
  EntityTypeEnum,
  globalHasMdmAccessQuery,
  InsertMassEntityValidationLogMutation,
  InsertMassEntityValidationLogMutationVariables
} from '../../../../../gql/typings';
import { useDetailsContext } from '../../../../components/DetailsView/useDetailsContext';
import { useIsAnyInactive } from '../../../../util/useIsAnyInactive';
import { TableRowSelectionReturnProps } from '../../../../components/Table/useTableRowSelection';
import { DATE_FORMAT } from '../../../../util/format';


export const useMassEntityValidationLogAction = (
  tableSelection: TableRowSelectionReturnProps,
  entityType: EntityTypeEnum,
): EntitiesSearchQuickActionItem => {
  const localization = useLocalization();
  const { colorPrimary: primaryColor } = useColors();
  const [addNewDate, setNewDate] = useState<boolean>(false);
  const [vTime, setVTime] = useState<Dayjs | null>(dayjs());
  const { read, create } = usePermission('VALIDATE_RECORD');
  const { data } = useQuery<globalHasMdmAccessQuery>(HasMdmAccess);
  const [
    InsertMassEntityValidationLog,
    { loading },
  ] = useMutation<InsertMassEntityValidationLogMutation, InsertMassEntityValidationLogMutationVariables>(
    INSERT_VALIDATION
  );

  const [hasSelectedInactivateRecord] = useIsAnyInactive(entityType, tableSelection.selection.selected);
  const { broadcastShouldRefetchData } = useDetailsContext();

  return useMemo(() => ({
    key: 'mass-validate',
    hide: hasSelectedInactivateRecord || !read || !data?.hasAccessToModule || tableSelection.selection.count === 0,
    disabled: !create,
    label: localization.formatMessage(Locale.Command.Validate_link),
    loading,
    confirm: {
      okLabel: localization.formatMessage(Locale.General.Yes),
      cancelLabel: localization.formatMessage(Locale.General.No),
      label: (
        <div>
          {localization.formatMessage(Locale.Text.Validation_Mass_Assign_Label)}
          {!addNewDate
            && <Icon
              iconType="PLUS"
              text={localization.formatMessage(Locale.Command.Select_other_date)}
              onClick={() => setNewDate(true)}
              style={{ marginTop: 5, color: primaryColor }}
            />}
          <br />
          {addNewDate && <div className="validation-confirm-container">
            <Space>
              <DatePicker
                format={DATE_FORMAT}
                disabledDate={(current) => {
                  const customDate = dayjs().format(DATE_FORMAT);
                  return current && current > dayjs(customDate, DATE_FORMAT);
                }}
                defaultValue={vTime || dayjs()}
                onChange={(value: Dayjs | null) => setVTime(value)}
                placeholder="Validation date"
              />
              <Button type="link" onClick={() => setNewDate(false)}>
                {localization.formatMessage(Locale.Command.Cancel)}
              </Button>
            </Space>
          </div>}
        </div>
      )
    },
    onClick: () => InsertMassEntityValidationLog({
      variables: {
        id: tableSelection.selection.selected,
        valDate: addNewDate ? vTime : dayjs(),
        entity: entityType,
      },
      refetchQueries: [
        'PersonCardContainerQuery',
        'validationLogHistory',
        'entityValidationLogsByValues',
      ],
    }).then(() => {
      broadcastShouldRefetchData();
      tableSelection.clearState();
      return message.success(localization.formatText(Locale.Text.Validation_Mass_assign_no_title_confirm));
    }),
  }), [
    InsertMassEntityValidationLog,
    addNewDate,
    broadcastShouldRefetchData,
    create,
    data,
    entityType,
    hasSelectedInactivateRecord,
    loading,
    localization,
    primaryColor,
    read,
    tableSelection,
    vTime,
  ]);
};

const HasMdmAccess = gql`
  query globalHasMdmAccess {
    hasAccessToModule(module: MDM)
  }
`;

const INSERT_VALIDATION = gql`
  mutation InsertMassEntityValidationLog($id: [Int!]!, $entity: EntityTypeEnum!, $valDate: DateTime!) {
    insertMassEntityValidationLog(ids: $id, entityType: $entity, validationDate: $valDate)
  }
`;

