import React, { useEffect, useState } from 'react';
import { Badge, Col, Popover, Row, Space, Tag } from 'antd';
import { NavLink } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { get, isArray } from 'lodash';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MessageDescriptor } from '@formatjs/intl/src/types';
import Icon from '../Icon/Icon';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import {
  AttributeLabelDcrInfoQuery,
  AttributeLabelDcrInfoQueryVariables,
  EntityTypeEnum,
  FieldEnum,
} from '../../../gql/typings';


export type DcrPopOverProps = {
  entityType: EntityTypeEnum;
  entityAffiliationId: number;
  field:MessageDescriptor|MessageDescriptor[]|FieldEnum|FieldEnum[];
};

const DcrPopOver: React.FC<DcrPopOverProps> = ({ entityType, entityAffiliationId, field }) => {
  const localization = useLocalization();
  const [popover, setPopover] = useState(false);
  const fields = isArray(field)
    ? field
    : [field];

  const {
    data,
    loading,
    refetch,
  } = useQuery<AttributeLabelDcrInfoQuery, AttributeLabelDcrInfoQueryVariables>(DCR_POPOVER_QUERY, {
    variables: {
      entityType,
      entityAffiliationId,
      fields: fields.map(f => ({
        field: get(f, 'id') ? null : f as FieldEnum,
        stringField: get(f, 'id') ? (f as MessageDescriptor).id : null,
      })),
    },
  });

  useEffect(() => {
    if (!loading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!data?.line.hasActiveLines) return <></>;

  const content = (
    <Row gutter={[4, 4]}>
      <Col span={10}>
        {localization.formatMessage(Locale.General.New_value)}:
      </Col>
      <Col span={14}>
        <Badge
          status="success"
          className="new-value"
          text={data?.line?.newValue}
        />
      </Col>
      <Col span={10}>
        {localization.formatMessage(Locale.General.Old_value)}:
      </Col>
      <Col span={14}>
        <Badge
          status="error"
          className="old-value"
          text={data?.line?.oldValue}
        />
      </Col>
    </Row>
  );

  return (
    <div className="dcr-popover-container">
      {data?.line && <Popover
        className="popover-edit-field"
        overlayClassName={`dcr-popover-overlay-container line-hash-${data.line.hash}`}
        open={popover}
        trigger="click"
        onOpenChange={setPopover}
        content={content}
        title={<Space>
          <div>
            {localization.formatMessage(Locale.Text.Pending_DCR)}
          </div>
          <NavLink className="dcr-group-link" to={`/dcr/${data?.line?.nodes[0]!.dcr.dcrGroupId}`}>
            <Icon iconType="HYPERLINK" />
          </NavLink>
        </Space>}
      >
        <Tag className="tag-edit-field" color="blue">
          {localization.formatMessage(Locale.General.DCR)}
        </Tag>
      </Popover>}
    </div>
  );
};


export const DCR_POPOVER_QUERY = gql`
  query AttributeLabelDcrInfo($entityType: EntityTypeEnum!, $entityAffiliationId: Int!, $fields: [DcrLineCriteriaField!]!) {
    line: findDcrLine(entityType: $entityType, entityAffiliationId: $entityAffiliationId, fields: $fields) {
      hash
      hasActiveLines
      newValue
      oldValue
      nodes {
        id
        dcr {
          id
          dcrGroupId
        }
      }
    }
  }
`;

export default DcrPopOver;
