import React from 'react';
import { Tooltip } from 'antd';
import { truncate } from 'lodash';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { DATE_FORMAT } from '../../util/format';

export const trunc = ({
  text,
  separator = ' ',
  length = 30,
}) => (
  <Tooltip title={text}>
    {truncate(text, { length, separator })}
  </Tooltip>
);

export default {
  trunc30: text => trunc({ text, length: 30 }),
  webPage: url => (
    <Tooltip title={url}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        Link to external site
      </a>
    </Tooltip>
  ),
  nav: ({ nav, text }) => (
    <NavLink to={nav}>
      {text}
    </NavLink>
  ),
  email: url => (
    <Tooltip title={url}>
      <a href={`mailto:${url}`}>
        {truncate(url, { length: 30, separator: ' ' })}
      </a>
    </Tooltip>
  ),
  time: (timeStr) => {
    const m = moment(timeStr);

    const date = m.format(DATE_FORMAT);
    const time = m.format('LT');

    return (
      <Tooltip title={`${date} ${time}`}>
        {date}
      </Tooltip>
    );
  },
};
