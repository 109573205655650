import { useQuery, gql } from '@apollo/client';
import React from 'react';
import { Tabs } from 'antd';
import { useIntl } from 'react-intl';
import { useMatch } from 'react-router-dom';
import im from '../../../img/icon-pill-grey.svg';
import ProductFields from './components/productFields';
import DeliveryTable from './components/deliveryTable';
import Loading from '../../components/Loading/Loading';
import MainView from '../../components/View/MainView';
import NameTag from '../../components/ContactCard/NameTag';
import ContentSeperator from '../../components/ContentSeperator/ContentSeperator';
import { Locale } from '../../../localization/LocalizationKeys';

const { TabPane } = Tabs;

const ProductDetails = () => {
  const { formatMessage } = useIntl();
  const match = useMatch('/product/:id');
  const { data, loading } = useQuery(ProductDetailQuery, { variables: { productId: match.params.id } });
  if (loading) return (<MainView className="person-detail-view-content-container"><Loading /></MainView>);

  return (
    <MainView className="person-detail-view-content-container">
      <NameTag
        title={data.product.name}
        image={<img height="30px" src={im} alt="name tag" />}
        subTitle={data.product.strengthUnitType.name}
      />
      <div className="clear">
        <ContentSeperator>
          {formatMessage(Locale.Attribute.aPureBase_Fields)}
        </ContentSeperator>
      </div>

      <ProductFields product={data.product} />

      <div className="product-tab-container">
        <Tabs onChange={() => {}} type="card">
          <TabPane
            key="1"
            tab={
              <div className="icon-list-type deliveries">
                {formatMessage(Locale.Attribute.Product_Deliveries)} ({data.product.productDeliveries.totalCount})
              </div>
            }
          >
            <DeliveryTable
              links={data.product.productDeliveries.nodes}
              loading={loading}
            />
          </TabPane>
        </Tabs>
      </div>
    </MainView>
  );
};

const ProductDetailQuery = gql`
  query ProductDetailQuery($productId: Int!) {
    product: getProductById(id:$productId) {
      id
      code
      name: heading
      ct
      ut
      strength
      strengthUnitType {
        id
        abbreviation
        description
        name
      }
      productDeliveries{
        totalCount
        nodes{
          person {
            id
            fullname: fullName
          }
        }
      }
    }
  }
`;

export default ProductDetails;
