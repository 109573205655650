import React, { Fragment } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Checkbox, Col, Divider, Form, Input, Modal, Row, Switch, Tabs, Tooltip, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Optional, StateArray } from '../../../../util/StateArrayType';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import {
  AddExternalIdRoleMutation,
  AddExternalIdRoleMutationVariables,
  ExternalIdModalQueryQuery,
  ExternalIdModalQueryQueryVariables,
  RemoveExternalIdRoleMutation,
  RemoveExternalIdRoleMutationVariables,
  UpdateExternalIdRoleMutation, UpdateExternalIdRoleMutationVariables
} from '../../../../../gql/typings';
import Flag, { CountryLabel, FlagCountry } from '../../../../components/Flag/Flag';

type AdminExternalIdModalProps = {
  selectedCodeState: StateArray<string|undefined>;
};

const AdminExternalIdModal: React.FC<AdminExternalIdModalProps> = ({
  selectedCodeState: [selectedCode, setSelectedCode],
}) => {
  const localization = useLocalization();
  const { data, refetch } = useQuery<ExternalIdModalQueryQuery, ExternalIdModalQueryQueryVariables>(DATA_QUERY, {
    skip: !selectedCode,
    variables: { code: selectedCode ?? '-1' }
  });
  const [add] = useMutation<AddExternalIdRoleMutation, AddExternalIdRoleMutationVariables>(ADD_MUTATION);
  const [remove] = useMutation<RemoveExternalIdRoleMutation, RemoveExternalIdRoleMutationVariables>(REMOVE_MUTATION);
  const [update] = useMutation<UpdateExternalIdRoleMutation, UpdateExternalIdRoleMutationVariables>(UPDATE_MUTATION);

  const onCheckClicked = (roleId: number, externalIdTypeRoleId: Optional<number>, checked: boolean) => {
    (checked
      ? add({ variables: { roleId, externalIdTypeCode: selectedCode! } })
      : remove({ variables: { externalIdTypeRoleId: externalIdTypeRoleId ?? -1 } })).then(() => refetch());
  };

  return (
    <Modal
      open={!!selectedCode}
      onCancel={() => setSelectedCode(undefined)}
      title={localization.formatMessage(Locale.Attribute.External_ID)}
      footer={null}
    >
      <Form labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} labelAlign="left">
        <Form.Item label={localization.formatMessage(Locale.Attribute.Code)}>
          <Input disabled value={data?.externalIdType?.code} />
        </Form.Item>
        <Form.Item label={localization.formatMessage(Locale.Attribute.Heading)}>
          <Input disabled value={data?.externalIdType?.heading} />
        </Form.Item>
      </Form>
      <Divider style={{ margin: '8px 0 0 0' }} />
      <Tabs
        defaultActiveKey="permission"
        items={[
          {
            key: 'permission',
            label: 'Field Permissions',
            children: (
              <>
                <Typography.Paragraph>
                  {localization.formatMessage(Locale.Text.External_ID_Type_permission_control_description)}
                </Typography.Paragraph>
                <Row>
                  {data?.roles.nodes.map(role => {
                    const conf = data?.externalIdType?.roleRules.nodes.find(n => n.role.id === role.id);
                    return (
                      <Fragment key={role.id}>
                        <Col span={10}>
                          <Checkbox
                            checked={!!conf}
                            onChange={(e: CheckboxChangeEvent) => onCheckClicked(role.id, conf?.id, e.target.checked)}
                          >
                            {role.heading}
                          </Checkbox>
                        </Col>
                        <Col span={7}>
                          {localization.formatMessage(Locale.General.Read)}:
                          &nbsp;
                          <Switch
                            size="small"
                            disabled={!conf}
                            checked={conf?.allowRead ?? true}
                            onChange={checked => update({
                              variables: {
                                allowRead: checked,
                                allowMutation: conf!.allowMutation,
                                externalIdTypeRoleId: conf!.id
                              }
                            })}
                          />
                        </Col>
                        <Col span={7}>
                          {localization.formatMessage(Locale.General.Mutation)}:
                          &nbsp;
                          <Switch
                            size="small"
                            disabled={!conf}
                            checked={conf?.allowMutation}
                            onChange={checked => update({
                              variables: {
                                allowRead: conf!.allowRead,
                                allowMutation: checked,
                                externalIdTypeRoleId: conf!.id
                              }
                            })}
                          />
                        </Col>
                      </Fragment>
                    );
                  })}
                </Row>
              </>
            )
          },
          {
            key: 'configuration',
            label: 'Field Configuration',
            children: (
              <>
                <Row>
                  <Checkbox checked={data?.externalIdType?.allowDuplicateValues}>
                    {localization.formatMessage(Locale.Text.Allow_duplicate_values)}
                  </Checkbox>
                </Row>
                <Row>
                  <Checkbox checked={data?.externalIdType?.isDuplicateRuleAcrossCountries}>
                    {localization.formatMessage(Locale.Text.Duplicate_Rule_across_countries)}
                  </Checkbox>
                </Row>
                {!data?.externalIdType?.allowDuplicateValues
                  && data?.externalIdType?.exceptionCountries
                  && data?.externalIdType?.exceptionCountries?.nodes.length > 0
                  && <Row style={{ marginTop: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography.Text>
                        {localization.formatMessage(Locale.Text.Excluded_countries)}:
                      </Typography.Text>
                      {data?.externalIdType?.exceptionCountries?.nodes
                        .map(cnt => (
                          <Tooltip
                            key={cnt.countryCode}
                            title={localization.formatMessage(CountryLabel[cnt.countryCode as FlagCountry])}
                          >
                            <span style={{ margin: '0 5px' }}><Flag country={cnt.countryCode as FlagCountry} rectangle /></span>
                          </Tooltip>))}
                    </div>
                  </Row>}
              </>
            )
          }
        ]}
      />
    </Modal>
  );
};

const DATA_QUERY = gql`
  query ExternalIdModalQuery($code: String!) {
    roles {
      hash
      nodes {
        id
        heading
      }
    }
    externalIdType(code: $code) {
      code
      heading
      description
      allowDuplicateValues
      isDuplicateRuleAcrossCountries
      exceptionCountries {
        hash
        nodes {
          id
          country
          countryCode
        }
      }
      roleRules {
        hash
        nodes {
          id
          allowRead
          allowMutation
          role {
            id
            heading
            isAdminRole
          }
        }
      }
    }
  }
`;

const ADD_MUTATION = gql`
  mutation AddExternalIdRole($externalIdTypeCode: String!, $roleId: Int!) {
    addExternalIdTypeRole(externalIdTypeCode: $externalIdTypeCode, roleId: $roleId) {
      id
    }
  }
`;

const REMOVE_MUTATION = gql`
  mutation RemoveExternalIdRole($externalIdTypeRoleId: Int!) {
    removeExternalIdTypeRole(externalIdTypeRoleId: $externalIdTypeRoleId)
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateExternalIdRole(
    $externalIdTypeRoleId: Int!,
    $allowRead: Boolean!,
    $allowMutation: Boolean!
  ) {
    setExternalIdTypeRoleRules(
      externalIdTypeRoleId: $externalIdTypeRoleId,
      allowRead: $allowRead,
      allowMutation: $allowMutation
    ) {
      id
      allowRead
      allowMutation
    }
  }
`;

export default AdminExternalIdModal;
