import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Divider, Select as Sel, Spin } from 'antd';
import { find, isObject } from 'lodash';

const Select = ({
  id,
  items = [],
  selected,
  onSelect = () => {},
  loading = false,
  placeholder = 'Select an item',
  showSearch = false,
  allowClear = false,
  onSearch,
  disabled = false,
}) => {
  const [selId, setSelId] = useState(undefined);


  // Has support for: "object with [id]", "just [id]" and "null"
  const currentValue = selected
    ? (isObject(selected) ? selected.id : selected)
    : selId;

  return (
    <Sel
      id={id}
      placeholder={placeholder}
      loading={loading}
      onSearch={onSearch}
      value={currentValue}
      style={{ width: '100%' }}
      onChange={(sId) => {
        setSelId(sId);
        onSelect(find(items, i => i && `${i.id}` === `${sId}`));
      }}
      showSearch={showSearch}
      notFoundContent={loading ? <Spin size="small" /> : <span>Nothing found</span>}
      allowClear={allowClear}
      filterOption={showSearch && ((input, option) => (
        option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0)
      )}
      disabled={disabled}
    >
      {items.map((item, index) => item ? (
        <Sel.Option key={item.id} title={item.label}>
          {item.label}
        </Sel.Option>
      ) : <Divider key={index} />)}
    </Sel>
  );
};

const itemProp = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.node.isRequired,
});

Select.propTypes = {
  id: PropTypes.string,
  items: PropTypes.arrayOf(itemProp).isRequired,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string, itemProp]),
  onSelect: PropTypes.func,
  loading: PropTypes.bool,
  showSearch: PropTypes.bool,
  allowClear: PropTypes.bool,
  onSearch: PropTypes.func,
};

Select.defaultProps = {
  id: null,
  onSelect: () => {},
  loading: false,
  showSearch: false,
  allowClear: false,
  onSearch: undefined,
};

export default Select;
