import { gql } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  GenderEnum,
  PersonCriteriaOrder,
  UpdatePersonGenderMutationMutationVariables
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';

export const personGenderFieldConfig = personFieldConfig(
  'gender',
  [
    'id',
    'sex',
    'genderEnum',
    'controlSetting.id',
    'controlSetting.createDcr',
    'controlSetting.maintainerSourceCode',
  ],
  ({ localization }) => ({
    sorting: {
      ascend: [PersonCriteriaOrder.GENDER_ASC],
      descend: [PersonCriteriaOrder.GENDER_DESC],
    },
    title: localization.formatMessage(Locale.Attribute.Gender),
    additionalTableConfig: {
      width: 100
    },
    dcrInfo: person => ({
      entityType: EntityTypeEnum.PERSON,
      entityAffiliationId: person.id,
      field: FieldEnum.HCP_GENDER,
    }),
    render: person => {
      if (person.genderEnum === GenderEnum.MALE) return localization.formatMessage(Locale.General.Male);
      if (person.genderEnum === GenderEnum.FEMALE) return localization.formatMessage(Locale.General.Female);
      return '';
    },
    updateView: buildSelectUpdateFieldConfig({
      mode: 'single',
      allowClear: true,
      mutation: UPDATE_PERSON_GENDER,
      selectPlaceholder: localization.formatMessage(Locale.Command.Select_gender),
      selectedKey: r => r.genderEnum,
      fetchSuggestions: () => Promise.resolve([
        { code: GenderEnum.FEMALE, label: localization.formatMessage(Locale.General.Female) },
        { code: GenderEnum.MALE, label: localization.formatMessage(Locale.General.Male) },
      ]),
      buildVariables: (selectedKey, record) => ({
        persId: record.id,
        gender: selectedKey,
      }) as UpdatePersonGenderMutationMutationVariables,
    }),
  })
);

const UPDATE_PERSON_GENDER = gql`
  mutation UpdatePersonGenderMutation($persId: Int!, $gender: GenderEnum!) {
    updatePersonSex(persId: $persId, sex: $gender) {
      id
      sex
      genderEnum
    }
  }
`;
