import React from 'react';
import { useIntl } from 'react-intl';
import { Col } from 'antd';
import { NavLink } from 'react-router-dom';
import CardWithFields from '../../../components/Card/CardWithFields';
import { SiteCardControllerType } from '../SiteCardController';
import { Locale } from '../../../../localization/LocalizationKeys';
import LabelWithIcon from '../../../components/Label/LabelWithIcon';
import Card from '../../../components/Card/Card';
import { EntityTypeEnum, SiteCardContainerQueryQuery } from '../../../../gql/typings';

type PersonByPosCodeViewProps = {
  controller: SiteCardControllerType;
  fields: NonNullable<SiteCardContainerQueryQuery['cardFieldKeys']>;
  height: number;
};

const PersonByPosCodeView: React.FC<PersonByPosCodeViewProps> = ({
  controller,
  fields,
  height
}) => {
  const { formatMessage } = useIntl();
  const { selectedLink, selectedGroup } = controller;
  const resultCount = controller.getResultCount();

  const title = selectedLink?.person.fullName ?? formatMessage(Locale.Text.people, { resultCount });

  return (
    <Col sm={24} md={8} lg={8} xl={8} xxl={6}>
      {!selectedLink && <Card
        title={title}
        size={height}
        icon="PERSON"
        url={selectedGroup?.code
          ? `/hcp?APB_POS=${selectedGroup.code}&APB_SNAME=${encodeURI(controller.site?.name ?? '')}`
          : '#'}
      >
        {controller.getLinksByPosCode().map(link => (
          <span
            className="position-card-col"
            key={link.id}
            onClick={() => controller.setSingleHcp(link)}
          >
            <LabelWithIcon label={link.person.fullName} icon="RIGHT_ARROW" />
          </span>
        ))}
      </Card>}
      {selectedLink && <CardWithFields
        title={<NavLink to={`/hcp/${selectedLink.person.id}`}>{selectedLink.person.fullName}</NavLink>}
        size={height}
        icon="PERSON"
        entityType={EntityTypeEnum.PERSON}
        rootEntityType={EntityTypeEnum.SITE}
        recordId={selectedLink.person.id}
        fields={fields.person.fields}
        secondaryFields={fields.person.drawerFields}
        onBack={controller.setDetailState}
      />}
    </Col>
  );
};


export default PersonByPosCodeView;

