import React from 'react';
import {
  CheckOutlined,
  FieldNumberOutlined, FontColorsOutlined,
  SettingOutlined,
  SwapOutlined, TagsOutlined,
} from '@ant-design/icons';
import { CustomFieldTypeEnum } from '../../../../../gql/typings';

type CustomFieldIconProps = {
  type?: CustomFieldTypeEnum|null|'UNKNOWN';
};


const icons: Record<CustomFieldTypeEnum|'UNKNOWN', React.FC> = {
  CHECK: CheckOutlined,
  NUMBER: FieldNumberOutlined,
  TAG: TagsOutlined,
  TEXT: FontColorsOutlined,
  SWITCH: SwapOutlined,
  // No icons yet, so default to this
  SELECT: SettingOutlined,
  UNKNOWN: SettingOutlined,
};

const CustomFieldIcon: React.FC<CustomFieldIconProps> = ({ type }) => {
  const ToRender = icons[type ?? 'UNKNOWN'];
  return <ToRender />;
};

export default CustomFieldIcon;
