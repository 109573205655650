import React from 'react';
import { Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { TableFieldUpdateViewProps } from '../../../search_old/types';
import { getVariableField } from '../../../person/fields/utils/personFieldsUtils';
import { usePersonFieldMutation } from '../../../person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import { extractBooleanValue } from '../../../../util/Util';
import Loading from '../../../../components/Loading/Loading';
import { CUSTOM_UPSERT_MUTATION } from './customFieldUtils';

const CustomSwitchInput: React.FC<TableFieldUpdateViewProps> = (props) => {
  const values = getVariableField(props!.record, props!.options, 'customFields');
  const selectedValue = !!values.nodes[0];

  const value = selectedValue ? extractBooleanValue(values.nodes[0]!.value!.value) : false;

  const { Renderer, submit, blocking, loading } = usePersonFieldMutation({
    mutation: CUSTOM_UPSERT_MUTATION,
    controlSetting: props.record?.controlSetting,
    skipDcrWarning: true,
  });

  const buildVariable = (checked: boolean) => ({
    recordId: props.record.id,
    customFieldCode: props.options.selectedOption?.code,
    customValues: [{ newValue: checked ? 'true' : 'false' }]
  });

  const onBlur = () => !blocking && props.endEditing();

  const onChange = (checked: boolean) => {
    submit(buildVariable(checked)).then(onBlur);
  };

  if (loading) return <Loading spinSize='small' />;

  return (
    <Renderer>
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        onChange={onChange}
        checked={value}
      />
    </Renderer>
  );
};

export default CustomSwitchInput;
