import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Col, InputNumber, Row, Space } from 'antd';
import { CustomFieldProps, UPSERT_MUTATION } from './CustomFieldUtils';
import Icon from '../Icon/Icon';
import { UpsertCustomFieldValueMutation, UpsertCustomFieldValueMutationVariables } from '../../../gql/typings';


const CustomFieldNumber: React.FC<CustomFieldProps> = ({ recordId, field, values, refreshData }) => {
  const selectedValue = !!values.nodes[0]?.value?.value;
  const val = selectedValue && typeof values.nodes[0]?.value?.value === 'string' ? values.nodes[0]?.value?.value : '';
  const [selected, setSelected] = useState(selectedValue ? parseInt(val, 10) : 0);

  const [editable, setEditable] = useState(false);
  const [upsert] = useMutation <UpsertCustomFieldValueMutation,
  UpsertCustomFieldValueMutationVariables>(UPSERT_MUTATION);

  const onChange = (newNumber: number | null) => {
    if (newNumber !== null) {
      setSelected(newNumber);
    }
  };

  const onSave = () => {
    if (selected != null) {
      upsert({
        variables: {
          recordId,
          customFieldCode: field.code,
          customValues: [{ newValue: (selected ?? 0).toString() }],
        }
      })
        .then(() => setEditable(false))
        .finally(refreshData);
    }
  };
  if (!field.accessRules?.allowMutation) return (
    <span>
      {values.nodes[0]?.value?.value}
    </span>
  );

  return (
    !editable ? <Space><p>{selectedValue ? selected : ''}</p>
      <Icon
        onClick={() => setEditable(true)}
        className="primary"
        style={{ paddingBottom: '12px' }}
        toolTip={selectedValue ? 'Edit' : 'Create'}
        iconType={selectedValue ? 'EDIT' : 'PLUS'}
      />
    </Space>
      : <Row className="custom-field-select-grid">
        <Col span={16}>
          <InputNumber onChange={e => onChange(e)} min={1} max={10} keyboard defaultValue={selected} /></Col>
        {editable
        && <Col span={8}>
          <div className="center">
            <Space>
              <Button
                className="button-cancel"
                type="primary"
                onClick={onSave}
                shape="round"
                size="small"
              >Save
              </Button>
              <Button
                className="button-cancel"
                onClick={() => setEditable(false)}
                shape="round"
                size="small"
              >
                Cancel
              </Button>
            </Space>
          </div>
        </Col>}
      </Row>
  );
};

export default CustomFieldNumber;
