import { Col, Form, Input, Radio, Row, Space } from 'antd';
import React from 'react';
import { useLocalization } from '../../../util/useLocalization';
import { MassAssignFieldTypesProps } from '../MassAssignFieldTypes';
import { Locale } from '../../../../localization/LocalizationKeys';
import { MassAssignFieldAction } from '../../../../gql/typings';

const MassAssignInputType: React.FC<MassAssignFieldTypesProps> = ({
  entityType,
  actionName,
  valueSelected: [valueSelected, setValueSelected],
  actionSelected: [actionSelected, setActionSelected],
}) => {
  const localization = useLocalization();
  const onChange = (it: string) => {
    if (it.length > 0) {
      return setValueSelected(true);
    }

    return setValueSelected(false);

  };
  return (
    <div className="mass-assign-container">
      <div className="mass-assign-type-description">
        <p>{localization.formatMessage(Locale.Text.Mass_assign_description_selectionType)}</p>
      </div>
      <div className="dashboard-card-container">
        <div className="mass-assign-content-container">
          <Row>
            <Form.Item
              name="actionName"
              style={{ display: 'none' }}
              initialValue={actionName}
            />
            <Form.Item
              name="entityType"
              style={{ display: 'none' }}
              initialValue={entityType}

            />
            <Col span={12}>
              <h3>{localization.formatMessage(Locale.Text.Action)}</h3>
              <Form.Item
                name="actionType"
                initialValue={MassAssignFieldAction.ReplaceTextInField}
                rules={[{ required: true, message: localization.formatMessage(Locale.Text.Options_cant_be_blank) }]}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio
                      defaultChecked
                      onClick={() => setActionSelected(MassAssignFieldAction.ReplaceTextInField)}
                      value={MassAssignFieldAction.ReplaceTextInField}
                    >{localization.formatMessage(Locale.Text.Mass_assign_action_replace_text_in_field)}</Radio>
                    <Radio
                      onClick={() => setActionSelected(MassAssignFieldAction.PrependTextToField)}
                      value={MassAssignFieldAction.PrependTextToField}
                    >{localization.formatMessage(Locale.Text.Mass_assign_action_prepend_text)}</Radio>
                    <Radio
                      onClick={() => setActionSelected(MassAssignFieldAction.AppendTextToField)}
                      value={MassAssignFieldAction.AppendTextToField}
                    >{localization.formatMessage(Locale.Text.Mass_assign_action_append_text)}</Radio>
                    <Radio
                      onClick={() => setActionSelected(MassAssignFieldAction.BlankOutField)}
                      value={MassAssignFieldAction.BlankOutField}
                    >{localization.formatMessage(Locale.Text.Mass_assign_action_set_blank_out)}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <h3>{localization.formatMessage(Locale.Text.Input_value)}</h3>
              <Form.Item
                name="singleSelectValue"
              >
                <Input
                  onChange={(e) => onChange(e.target.value)}
                  placeholder={localization.formatMessage(Locale.Command.Input)}
                />
              </Form.Item>
            </Col>


          </Row>
        </div>

      </div>


    </div>
  );
};


export default MassAssignInputType;
