import { useQuery, gql } from '@apollo/client';
import React from 'react';
import { Col, Row } from 'antd';
import { get } from 'lodash';
import apbLogo from '../../img/puffin-advance-logo.png';
import advanceLogo from '../../img/png/advance-logo-white.png';
import { InstanceQuery } from '../../gql/typings';

const LoginFooter = () => {
  const { data } = useQuery<InstanceQuery>(INSTANCE);
  const imgPath = get(data, 'imgPath', advanceLogo);


  return (
    <>
      <div className="icon background" />
      <div className="footer">
        <div className="footer-container">
          <Row>
            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              {imgPath && <img className="customer-logo" alt={data?.instanceName ?? ''} src={imgPath} />}
            </Col>
            <Col sm={24} md={24} lg={12} xl={12} xxl={12}>
              <img alt="apurebase-logo" className="apurebase-logo" src={apbLogo} />
            </Col>
          </Row>

        </div>
      </div>
    </>
  );
};

const INSTANCE = gql`
  query Instance {
    instanceName
    imgPath
  }
`;


export default LoginFooter;

