import React, { ReactElement } from 'react';
import { Anchor, Col, Row } from 'antd';
import { isArray } from 'lodash';
import AdminSection from './AdminSection';

type AnchoredSectionsProps = {
  children: (ReactElement<AnchoredItemProps>|false|undefined)[]|ReactElement<AnchoredItemProps>|false|undefined;
};

const AnchoredSections: React.FC<AnchoredSectionsProps> = ({ children }) => {
  const items = (isArray(children)
    ? children as ReactElement<AnchoredItemProps>[]
    : [children] as ReactElement<AnchoredItemProps>[])
    .filter(e => e);

  // TODO: Somehow not working after CRA 5.0
  // if (process?.env?.NODE_ENV === 'development') {
  //   items.map((item) => {
  //     // @ts-ignore
  //     if (item?.type?.name !== 'AnchoredItem') {
  //       throw Error('Children of AnchoredSections are required to be AnchoredItem!');
  //     }
  //   });
  // }

  return (
    <Row style={{ marginBottom: 360 }}>
      <Col span={20}>
        {items.filter(item => !item.props.hidden).map(item => item)}
      </Col>
      <Col span={4}>
        <Anchor
          offsetTop={64}
          items={items.filter(item => !item.props.hidden).map(item => (
            {
              key: item.props.anchor,
              href: `#${item.props.anchor}`,
              title: item.props.title
            }
          ))}
        />
      </Col>
    </Row>
  );
};

type AnchoredItemProps = {
  anchor: string;
  title: string;
  hidden?: boolean;
};

export const AnchoredItem: React.FC<AnchoredItemProps> = ({ anchor, title, children, hidden }) => (
  <AdminSection id={anchor} title={title} key={anchor}>
    {children}
  </AdminSection>
);

export default AnchoredSections;
