import { gql } from '@apollo/client/core';
import { Tooltip } from 'antd';
import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  PersonCriteriaOrder,
  SelectPersonTherapeuticFocusesSuggestionQueryDocument,
  SelectPersonTherapeuticFocusesSuggestionQueryQuery,
  SelectPersonTherapeuticFocusesSuggestionQueryQueryVariables,
  UpdatePersonTherapeuticFocusesMutationMutationVariables
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';


export const personTherapeuticFocusFieldConfig = personFieldConfig(
  'therapeuticFocus',
  [
    'id',
    'countryCode',
    'therapeuticFocuses.hash',
    'therapeuticFocuses.nodes.code',
    'therapeuticFocuses.nodes.type',
    'therapeuticFocuses.nodes.label',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Text.Therapeutic_focus),
    additionalTableConfig: {
      width: 150,
      ellipsis: {
        showTitle: false
      }
    },
    sorting: {
      ascend: PersonCriteriaOrder.PERSON_THERAPEUTIC_FOCUS_ASC,
      descend: PersonCriteriaOrder.PERSON_THERAPEUTIC_FOCUS_DESC,
    },
    render: person => {
      const therapeuticField = person.therapeuticFocuses.nodes.map(e => e.label).join(', ');
      return <Tooltip placement='left' title={therapeuticField}>{therapeuticField}</Tooltip>;
    },
    preserve: true,
    updateView: buildSelectUpdateFieldConfig({
      mode: 'multiple',
      modalHeading: localization.formatMessage(Locale.Command.Select_therapeutic_focus),
      selectedKeys: r => r.therapeuticFocuses.nodes.map(n => n.code),
      fetchSuggestions: ({ client, record }) => (
        client.query<SelectPersonTherapeuticFocusesSuggestionQueryQuery,
        SelectPersonTherapeuticFocusesSuggestionQueryQueryVariables>({
          query: SelectPersonTherapeuticFocusesSuggestionQueryDocument,
          variables: { countries: [record.countryCode] },
        }).then(res => res.data.therapeuticFocuses.nodes)
      ),
      mutation: UPDATE_THERAPEUTIC_FOCUSES_MUTATION,
      buildVariables: (selectedKeys, person) => ({
        persId: person.id,
        therapeuticFocuses: selectedKeys,
      }) as UpdatePersonTherapeuticFocusesMutationMutationVariables,
    }),
  })
);

const UPDATE_THERAPEUTIC_FOCUSES_MUTATION = gql`
  mutation UpdatePersonTherapeuticFocusesMutation($persId: ID!, $therapeuticFocuses: [String!]!) {
    updatePersonTherapeuticFocuses(personId: $persId, therapeuticFocusCodes: $therapeuticFocuses) {
      id
      person {
        id
        therapeuticFocuses {
          hash
          nodes {
            code
            type
          }
        }
      }
    }
  }
`;

gql`
  query SelectPersonTherapeuticFocusesSuggestionQuery($countries: [CountryCode!]!) {
    
    therapeuticFocuses(criteria: {countries: $countries,fetchSize: {limit: 5000}}) {
      hash
      nodes {
        code
        label
        type
      }
    }
  }
`;
