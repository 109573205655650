import React from 'react';
import Icon, { ITE } from '../Icon/Icon';
import { ColoredCircle } from '../ColoredCircle/ColoredCircle';
import { SupportedEntitySearchTypes } from '../../browse/search_old/types';
import CardAttributes from './CardAttributes';
import Card from './Card';
import CardWithFieldsDrawerItems from './CardWithFieldsDrawerItems';
import ValidationLog from '../../browse/person/Components/attributeFields/ValidationLog';
import { usePermission } from '../../permission/usePermission';
import DataValidationHistoryLog from '../DataValidationHistoryLog/DataValidationHistoryLog';
import { GridCardFieldFragment } from '../../../gql/typings';


type CardProps = {
  title?: string | React.ReactNode;
  onBack?: (() => void) | false;
  icon?: 'PERSON' | 'KEY' | 'ACTIVITY' | 'PROJECT' | 'SITE' | 'POSITION';
  children?: never;
  recordId: number;
  url?: string;
  topRightIcon?: ITE;
  className?: string;
  topRightBadgeChar?: string;
  size?: ('small' | 'medium' | 'large' | 'noSize') | number;
  // gradientFooter?: boolean;
  fields: GridCardFieldFragment[];
  secondaryFields?: GridCardFieldFragment[];
  entityType: SupportedEntitySearchTypes;
  rootEntityType?: SupportedEntitySearchTypes;

};

function CardWithFields({
  title,
  onBack,
  icon,
  url,
  topRightIcon,
  topRightBadgeChar,
  size = 'medium',
  className,
  recordId,
  fields,
  secondaryFields,
  entityType,
  rootEntityType,
}: CardProps) {
  const { read } = usePermission('VALIDATE_RECORD');

  return (
    <Card
      className={className}
      title={title}
      icon={icon}
      url={url}
      size={size}
      onBack={onBack}
      extra={
        <>
          {topRightIcon && <ColoredCircle type="primary">
            <Icon iconType={topRightIcon} style={{ padding: 2 }} />
          </ColoredCircle>}
          {topRightBadgeChar && <ColoredCircle type="primary" style={{ color: 'white' }}>
            {topRightBadgeChar}
          </ColoredCircle>}
          {read && <ValidationLog
            affiliationId={recordId}
            entity={entityType}
            title={title}
          />}
        </>
      }
      metaDataElement={<>
        {secondaryFields && <CardWithFieldsDrawerItems
          fields={secondaryFields}
          entityType={entityType}
          rootEntityType={rootEntityType}
          recordId={recordId}
        />}
        {read && <DataValidationHistoryLog id={recordId} entity={entityType} />}
      </>}
    >
      <CardAttributes
        recordId={recordId}
        fields={fields}
        entityType={entityType}
        rootEntityType={rootEntityType}
        gradientFooter={false}
      />
    </Card>
  );
}


export default CardWithFields;

