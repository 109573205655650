import React, { useEffect, useState } from 'react';
import { Form, Input, Skeleton, Space, Spin, Switch, Table } from 'antd';
import { isEqual, uniq } from 'lodash';
import { LoadingOutlined } from '@ant-design/icons';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ColumnsType } from 'antd/lib/table/interface';
import { Locale } from '../../../../localization/LocalizationKeys';
import Icon from '../../Icon/Icon';
import { isActualNumber } from '../../../util/Util';
import ApbTableHeader from '../../Table/ApbTableHeader';
import ApbPagination from '../../Table/ApbPagination';
import { useLocalization } from '../../../util/useLocalization';
import { defaultPerPage } from '../../Table/utils';
import { UserPreferEnglishQueryQuery } from '../../../../gql/typings';
import ApbTextHighlighter from '../../ApbTextHighlighter/ApbTextHighlighter';
import { CriteriaLovModalProps } from './CriteriaLovModal';
import { CriteriaSuggestionRecord } from '../useSuggestionDropdownValues';
import { useBroadcastStorage } from '../../../util/useBroadcastStorage';

const CriteriaLovModalContent: React.FC<Omit<CriteriaLovModalProps, 'isVisible'|'title'|'setVisibleAction'>> = ({
  form,
  fromValueKey,
  searchState: [search, setSearch] = ['', () => {}],
  suggestionData: {
    records,
    hasMoreResults,
    fetchMore,
    loading,
    totalCount,
    searchCount,
  },
}) => {
  const localization = useLocalization();
  const pageState = useState(1);
  const perPageState = useBroadcastStorage<number>('per-page', defaultPerPage);
  const [perPage] = perPageState;
  const [page] = pageState;
  const { data: userQuery } = useQuery<UserPreferEnglishQueryQuery>(UserQuery);
  const [updatePreferEnglish] = useMutation(UPDATE_USER_PREFER_ENGLISH);
  const setSelected = (selectedKeys: string[]) => {
    form.setFields([{
      name: fromValueKey,
      value: selectedKeys,
    }]);
  };

  const dataLength = records.length;
  useEffect(() => {
    if (!loading && dataLength - (page * perPage) <= 0) {
      fetchMore();
    } else if (!loading && dataLength < (perPage * (page - 1)) && page > 1) {
      pageState[1](1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLength, hasMoreResults, page, perPage]);

  const columns: ColumnsType<CriteriaSuggestionRecord> = [
    {
      title: localization.formatMessage(Locale.Attribute.Country),
      render: (_, r) => r.key.substring(0, 2),
    }, {
      title: localization.formatMessage(Locale.General.Label),
      render: (_, r) => <ApbTextHighlighter text={r.value} search={search} />
    },
    {
      title: localization.formatMessage(Locale.General.English_label),
      render: (_, r) => <ApbTextHighlighter text={r.valueGb ?? ''} search={search} />
    },
    // {
    //   title: localization.formatMessage(Locale.General.Count),
    //   render: (_, r) => <Badge
    //     overflowCount={Number.MAX_VALUE}
    //     showZero
    //     count={numeral(r.count).format()}
    //     className="local-badge"
    //   />,
    // },
    // {
    //   title: (
    //     <Tooltip
    //       title={localization.formatMessage(Locale.Text.Global_additional_count_info_tooltip)}
    //       zIndex={10500}
    //       placement="topRight"
    //     >
    //       {localization.formatMessage(Locale.General.Additional)} <GlobalOutlined className="globe" />
    //     </Tooltip>
    //   ),
    //   render: (_, r) => <Badge
    //     overflowCount={Number.MAX_VALUE}
    //     count={numeral(r.globalCount ?? 0).format('0,0')}
    //     className="global-badge"
    //   />,
    // },
  ];

  return (
    <div className="lov-modal-criteria-search-builder">
      <Skeleton loading={!totalCount && loading}>
        <div className="lov-modal-search-criteria">
          <Space>
            <Input
              autoFocus
              onChange={(e) => setSearch(e.target.value)}
              width="500px"
              allowClear
              placeholder={localization.formatMessage(Locale.Command.Search)}
            />
            <div>
              <Switch
                style={{ paddingLeft: '5px' }}
                size="default"
                onChange={() => updatePreferEnglish()}
                defaultChecked={userQuery?.viewer?.user.hasEnglishAsDefault}
              />
              <span style={{ paddingLeft: '10px' }}>
                {localization.formatMessage(Locale.Text.Show_English_by_default)}
              </span>
            </div>
            <Icon
              toolTip={localization.formatMessage(Locale.Text.English_lov_as_default_tooltip)}
              toolTipZIndex={15000}
              iconType="INFO"
            />
          </Space>
        </div>

        <Form.Item shouldUpdate={(prev, curr) => !isEqual(prev[fromValueKey], curr[fromValueKey])} noStyle>
          {({ getFieldValue }) => {
            const selected = getFieldValue(fromValueKey) || [];

            const currentCount = loading ? records.length
              : (isActualNumber(searchCount)
                ? searchCount
                : (totalCount ?? records.length));

            return (
              <>
                <ApbTableHeader
                  count={currentCount}
                  selectedLabel={selected.length > 0 ? localization.pluralMessage(
                    Locale.General.Search_results_selected,
                    selected.length,
                  ) : ''}
                >
                  {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} />}
                </ApbTableHeader>
                <Table
                  dataSource={records.slice(
                    (pageState[0] - 1) * perPageState[0],
                    ((pageState[0] - 1) * perPageState[0]) + perPageState[0],
                  )}
                  columns={columns}
                  scroll={{ x: 'max-content' }}
                  pagination={false}
                  rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: getFieldValue(fromValueKey),
                    onSelect: (record, isSelected) => {
                      if (isSelected) {
                        setSelected(uniq([...selected, record.key]));
                      } else {
                        setSelected(selected.filter((key: string) => key !== record.key));
                      }
                    },
                    onSelectAll: (selected, selectedRows) => setSelected(selectedRows?.map(r => r?.key)),
                  }}
                />
                <ApbPagination
                  perPageState={perPageState}
                  pageState={pageState}
                  totalCount={currentCount}
                />
              </>
            );
          }}
        </Form.Item>
      </Skeleton>
    </div>
  );
};


const UserQuery = gql`
  query UserPreferEnglishQuery {
    viewer {
      id
      user {
        id
        hasEnglishAsDefault
      }
    }
  }
`;

const UPDATE_USER_PREFER_ENGLISH = gql`
  mutation preferEnglishLov {
    preferEnglishLov {
      id
      user {
        id
        hasEnglishAsDefault
      }
    }
  }
`;

export default CriteriaLovModalContent;
