import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Col, Input, Row, Space, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { CustomFieldProps, REMOVE_MUTATION, UPSERT_MUTATION } from './CustomFieldUtils';
import Icon from '../Icon/Icon';
import {
  UpsertCustomFieldValueMutation, UpsertCustomFieldValueMutationVariables,
  RemoveCustomFieldValueMutation,
  RemoveCustomFieldValueMutationVariables
} from '../../../gql/typings';

const CustomFieldTag: React.FC<CustomFieldProps> = ({ recordId, field, values, refreshData }) => {
  const [editable] = useState(false);
  const [inputVisible, setInputVisible] = useState(false);
  const [
    insert,
  ] = useMutation<UpsertCustomFieldValueMutation,
  UpsertCustomFieldValueMutationVariables>(UPSERT_MUTATION);
  const [
    removeCustomFieldValue
  ] = useMutation<RemoveCustomFieldValueMutation,
  RemoveCustomFieldValueMutationVariables>(REMOVE_MUTATION);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCreate = (value: any) => {
    if (value.value.length > 0) {
      const existingTags = values.nodes.length > 0 ? values.nodes.map(val => ({ valueId: val.value!.id })) : [];
      insert({
        variables: {
          recordId,
          customFieldCode: field.code,
          customValues: [...existingTags, { newValue: value.value }],
        }
      }).then(() => setInputVisible(false))
        .finally(refreshData);
    } else {
      setInputVisible(false);
    }
  };


  const onClose = (customOptionId: number) => {
    const toRemoveLinkId = values?.nodes?.filter(v => v.value?.id == customOptionId)[0]!.id;
    removeCustomFieldValue({
      variables: {
        customValues: [toRemoveLinkId],
      }
    }).finally(refreshData);


  };
  if (!field.accessRules?.allowMutation) return (
    <span>
      {values.nodes[0]?.value?.value}
    </span>
  );
  const selectedValue = !!values.nodes[0];

  const settings = field.customFieldControlSetting;

  if (!field.isActive) {
    return <></>;
  }


  return (
    <Row>
      <Col span={settings?.isSynced ? 23 : 24}>
        <Space>
          <div className="custom-field-tag">
            {selectedValue ? values.nodes.map(it => <Tag
              color={field.color || 'default'}
              closable
              onClose={() => onClose(it.value!.id)}
              key={it.value?.id}
            >
              {it.value?.value}
            </Tag>) : <></>}
            {!editable && <>
              {inputVisible && (
                <Input
                  type="text"
                  size="small"
                  onPressEnter={e => onCreate(e)}
                  onBlur={e => onCreate(e)}
                  style={{ width: 78 }}
                />
              )}
              {!inputVisible && (
                <Tag className="site-tag-plus" color={field.color || 'default'} onClick={() => setInputVisible(true)}>
                  <PlusOutlined /> New Tag
                </Tag>
              )}
            </>}
          </div>
        </Space>
      </Col>
      {settings?.isSynced
      && <Col span={1}><Icon toolTip="is synchronized" style={{ marginTop: 8, marginLeft: 8 }} iconType="SYNC" /></Col>}

    </Row>
  );
};

export default CustomFieldTag;
