import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { RouteObject } from 'react-router/lib/router';
import PasswordResetPage from './login/PasswordResetPage';
import CreatePasswordPage from './login/CreatePasswordPage';
import LoginPage from './login/LoginPage';
import DashboardView from './browse/dashboards/DashboardView';
import ActivityOverview from './browse/activity/ActivityDashboard/ActivityOverview';
import PersonView from './browse/person/PersonView';
import PersonDetails from './browse/person/PersonDetails/PersonDetails';
import CreatePersonView from './browse/person/create/CreatePersonView';
import SiteView from './browse/site/SiteView';
import SiteDetails from './browse/site/SiteDetails';
import CreateSiteView from './browse/site/create/CreateSiteView';
import ListView from './browse/list/ListView/ListView';
import ListDetails from './browse/list/ListDetail/ListDetails';
import CreateListView from './browse/list/CreateList/CreateListView';
import AdminView from './browse/admin/AdminView';
import AdminEntityMergeRequestView from './browse/admin/adminComponents/AdminEntityMergeRequest/AdminEntityMergeRequestView';
import RoleDetails from './browse/admin/users/roles/RoleDetails';
import UserAdminDetails from './browse/admin/users/userAdminDetails/UserAdminDetails';
import InstanceDeploymentDetails from './browse/admin/clients/InstanceDeploymentDetails';
import ProjectView from './browse/project/ProjectView';
import ProjectCreate from './browse/project/ProjectCreate';
import ActivityView from './browse/activity/ActivityView/ActivityView';
import ActivityDetails from './browse/activity/ActivityDetails/ActivityDetails';
import BrickView from './browse/brick/BrickView';
import BrickDetail from './browse/brick/BrickDetail';
import ReportView from './browse/report/ReportView';
import ProductDetails from './browse/product/ProductDetails';
import ImportProjectView from './browse/importer/ImportProjectView';
import Uploader from './browse/importer/Uploader/Uploader';
import ImportProjectProcessingDetails from './browse/importer/ImportDetails/ImportProjectProcessingDetails';
import ImportProjectDetails from './browse/importer/ImportDetails/ImportProjectDetails';
import DcrView from './browse/dcr/DcrView';
import DcrGroupDetails from './browse/dcr/DcrGroupDetails';
import LogoutPage from './login/LogoutPage';
import Calendar from './browse/Calendar/Calendar';
import RandomPuffin from './browse/randomPuffin/RandomPuffin';
import { Auth } from './util/Authenticated';
import LinkView from './browse/link/LinkView';
import LinkDetails from './browse/link/LinkDetails';
import EntityGridConfigurationDisplay from './browse/admin/GridConfiguration/EntityGridConfigurationDisplay';
import { EntityTypeEnum } from '../gql/typings';
import AdminEntityMergeRequestDetails
  from './browse/admin/adminComponents/AdminEntityMergeRequest/MergeRequestDetails/AdminEntityMergeRequestDetails';

export const Routes: RouteObject[] = [
  {
    path: '/password/reset',
    element: <PasswordResetPage />,
  },
  {
    path: '/password/create/:token',
    element: <CreatePasswordPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/logout',
    element: <Auth><LogoutPage /></Auth>,
  },

  {
    path: '/',
    element: <Auth><Navigate to={{ pathname: '/overview' }} /></Auth>,
  },
  {
    path: '/dashboard',
    element: <Auth><DashboardView /></Auth>,
  },
  {
    path: '/overview',
    element: <Auth><ActivityOverview /></Auth>,
  },
  {
    path: '/hcp',
    element: <Auth><PersonView /></Auth>,
  },
  {
    path: '/hcp/:id',
    element: <Auth><PersonDetails /></Auth>,
  },
  {
    path: '/hcp/create',
    element: <Auth><CreatePersonView /></Auth>,
  },
  {
    path: '/hco',
    element: <Auth><SiteView /></Auth>,
  },
  {
    path: '/hco/:id',
    element: <Auth><SiteDetails /></Auth>,
  },
  {
    path: '/hco/create',
    element: <Auth><CreateSiteView /></Auth>,
  },
  {
    path: '/link',
    element: <Auth><LinkView /></Auth>,
  },
  {
    path: '/link/:id',
    element: <Auth><LinkDetails /></Auth>,
  },
  {
    path: '/list',
    element: <Auth><ListView /></Auth>,
  },
  {
    path: '/list/:id',
    element: <Auth><ListDetails /></Auth>,
  },
  {
    path: '/list/create',
    element: <Auth><CreateListView /></Auth>,
  },
  {
    path: '/admin',
    children: [
      {
        path: '*',
        index: true,
        element: <Auth><AdminView /></Auth>,
      },
      {
        path: 'merge',
        element: <Auth><AdminEntityMergeRequestView /></Auth>,
      },
      {
        path: 'merge/:id',
        element: <Auth><AdminEntityMergeRequestDetails /></Auth>,
      },
      {
        path: 'role/:id',
        element: <Auth><RoleDetails /></Auth>,
      },
      {
        path: 'user/:id',
        element: <Auth><UserAdminDetails /></Auth>,
      },
      {
        path: 'client/:id',
        element: <Auth><InstanceDeploymentDetails /></Auth>,
      },
      {
        path: 'hcp/layout',
        element: <Auth><EntityGridConfigurationDisplay entity={EntityTypeEnum.PERSON} /></Auth>,
      },
      {
        path: 'hco/layout',
        element: <Auth><EntityGridConfigurationDisplay entity={EntityTypeEnum.SITE} /></Auth>,
      },
      {
        path: 'link/layout',
        element: <Auth><EntityGridConfigurationDisplay entity={EntityTypeEnum.AFFILIATION} /></Auth>,
      },
      // {
      //   path: '*',
      //   element: requireAuth(<AdminView />),
      // },
    ],
  },
  {
    path: '/project',
    element: <Auth><ProjectView /></Auth>,
  },
  {
    path: '/project/create',
    element: <Auth><ProjectCreate /></Auth>,
  },
  {
    path: '/activity',
    element: <Auth><ActivityView /></Auth>,
  },
  {
    path: '/activity/:id',
    element: <Auth><ActivityDetails /></Auth>,
  },
  {
    path: '/brick',
    element: <Auth><BrickView /></Auth>,
  },
  {
    path: '/brick/:id',
    element: <Auth><BrickDetail /></Auth>,
  },
  {
    path: '/report',
    element: <Auth><ReportView /></Auth>,
  },
  {
    path: '/product/:id',
    element: <Auth><ProductDetails /></Auth>,
  },
  {
    path: '/import',
    element: <Auth><ImportProjectView /></Auth>,
  },
  {
    path: '/import/select',
    element: <Auth><Uploader /></Auth>,
  },
  {
    path: '/import/:id',
    element: <Auth><ImportProjectDetails /></Auth>,
  },
  {
    path: '/import/:id/process',
    element: <Auth><ImportProjectProcessingDetails /></Auth>,
  },
  {
    path: '/dcr',
    element: <Auth><DcrView /></Auth>,
  },
  {
    path: '/dcr/:id',
    element: <Auth><DcrGroupDetails /></Auth>,
  },
  {
    path: '/calendar',
    element: <Auth><Calendar /></Auth>,
  },
  {
    path: '/puffin',
    element: <Auth><RandomPuffin /></Auth>,
  },
  // {
  //   path: '/*',
  //   element: <Navigate to="/" />
  // }
];

const OurRoutes: React.FC = () => useRoutes(Routes);

export default OurRoutes;
