import React from 'react';
import { Card } from 'antd';
import { useIntl } from 'react-intl';
import MainView from '../../components/View/MainView';
import GenerateReport from './components/GenerateReport';
import useReportController from './controller/reportController';
import { usePageTitle } from '../../hooks/usePageTitle';
import { Locale } from '../../../localization/LocalizationKeys';


const ReportView: React.FC = () => {
  const { formatMessage } = useIntl();
  const controller = useReportController();

  usePageTitle(formatMessage(Locale.General.Reports));

  return (
    <MainView className="report-view-container">
      <h1>{formatMessage(Locale.General.Report)}</h1>
      <Card>
        <GenerateReport controller={controller} />
      </Card>
    </MainView>
  );
};


export default ReportView;
