import { useQuery, gql } from '@apollo/client';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { useDebounce } from 'use-debounce';
import { DynamicReportInputProps } from './dynamicReportInputUtils';
import { useLocalization } from '../../../../util/useLocalization';
import { ReportUsersInputQueryQuery, ReportUsersInputQueryQueryVariables } from '../../../../../gql/typings';


const ReportUsers: React.FC<DynamicReportInputProps & { initialAllSelected?: boolean }> = ({
  controller,
  inputDef,
  initialAllSelected = false,
}) => {
  const localization = useLocalization();
  const [search, setSearch] = useState('');
  const [searchDebounced] = useDebounce(search, 500, { maxWait: 1000 });
  const { data, loading, refetch } = useQuery<ReportUsersInputQueryQuery, ReportUsersInputQueryQueryVariables>(DATA_QUERY);

  useEffect(() => {
    refetch({ search: `%${searchDebounced}%` }).then(() => {});
  }, [refetch, searchDebounced]);

  // noinspection DuplicatedCode
  useEffect(() => {
    if (initialAllSelected
      && !loading
      && !controller.form.getFieldValue(inputDef.labelKey!)
      && data && data.users && data.users.nodes
    ) controller.form.setFieldsValue({
      [inputDef.labelKey!]: data.users.nodes.map(u => u.id),
    });
  }, [loading]);

  return (
    <Form.Item
      name={inputDef.labelKey!}
      label={localization.formatMessageByStr(inputDef.labelKey!)}
      rules={inputDef.rules}
    >
      <Select
        mode="multiple"
        placeholder={inputDef.placeholderKey ? localization.formatMessageByStr(inputDef.placeholderKey) : undefined}
        onSearch={setSearch}
        onDeselect={() => setSearch('')}
        style={{ width: 400 }}
        loading={loading}
        filterOption={false}
        allowClear
      >
        {((data && data.users && data.users.nodes) || []).map(user => (
          <Select.Option key={user.id} value={user.id}>
            {user.fullname}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const DATA_QUERY = gql`
  query ReportUsersInputQuery($search: String) {
    users(criteria: { name: $search, fetchSize: { limit: 250 }  }) {
      hash
      nodes {
        id
        fullname
      }
    }
  }
`;


export default ReportUsers;
