import { Col, Collapse, Divider, Input, Modal, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { toLower } from 'lodash';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import Icon from '../../components/Icon/Icon';


import { StateArray } from '../../util/StateArrayType';
import MassAssignProcess from './MassAssignProcess';
import {
  AvailableMassAssignFieldsQueryQuery,
  AvailableMassAssignFieldsQueryQueryVariables,
  EntityTypeEnum, SelectionCriteriaInput
} from '../../../gql/typings';

const MassAssign: React.FC<{
  entityType: EntityTypeEnum;
  visibleState: StateArray<boolean>;
  selection: SelectionCriteriaInput;
}> = ({ entityType, visibleState, selection }) => {
  const localization = useLocalization();
  const [search, setSearch] = useState('');
  const { Panel } = Collapse;
  const {
    data,
    loading,
    refetch,
  } = useQuery<AvailableMassAssignFieldsQueryQuery, AvailableMassAssignFieldsQueryQueryVariables>(DATA_QUERY, {
    variables: { entityType },
  });

  const [
    selectedField,
    setSelectedField,
  ] = useState<AvailableMassAssignFieldsQueryQuery['availableMassAssignFields']['0'] | null>(null);

  useEffect(() => {
    if (!loading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open={visibleState[0]}
      onCancel={() => visibleState[1](false)}
      destroyOnClose
      width="40%"
      className="mass-assign-modal"
      footer={false}
      title={localization.formatMessage(Locale.Attribute.Mass_assign)}
    >
      {!selectedField && <Collapse defaultActiveKey={['1']} activeKey="1">
        <Panel header={localization.formatMessage(Locale.Text.Search_avilable_fields)} key="1">
          <div className="mass-assign-container">
            <p>
              {localization.formatMessage(Locale.Text.Mass_assign_description_text)}
            </p>
            <Input
              size="large"
              onChange={e => setSearch(e.target.value)}
              suffix={
                <Tooltip title="Extra information">
                  <Icon iconType="SEARCH" />
                </Tooltip>
              }
              placeholder="Search across all fields"
            />
            <Divider />
            <Row>
              <Col span={18}><b>{localization.formatMessage(Locale.Text.Mass_assign_categories_and_names)}</b></Col>
              <Col
                style={{ textAlign: 'right' }}
                span={6}
              ><b>{localization.formatMessage(Locale.Text.Mass_assign_field_types)}</b></Col>
            </Row>
            <div className="mass-assign-content">
              {(data?.availableMassAssignFields ?? []).filter(field => {
                if (field.accessRules?.allowRead) {
                  if (search === '') return true;
                  if (field.isHeadingKey) {
                    return toLower(localization.formatMessageByStr(field.heading)).includes(toLower(search));
                  }
                  return toLower(field.heading).includes(toLower(search));
                }

              }).map(field => (
                <div key={field.actionName}>
                  {(field.accessRules?.allowRead && field.accessRules?.allowMutation)
                  && <Row className="mass-assign-row" key={field.actionName}>
                    <Col className="mass-assign-heading-name" span={18}>
                      <div onClick={() => setSelectedField(field)}>
                        {field.isHeadingKey ? localization.formatMessageByStr(field.heading) : field.heading}
                      </div>
                    </Col>
                    <Col span={6}>{field.fieldType}</Col>
                  </Row>}

                </div>
              ))}
            </div>
          </div>
        </Panel>

      </Collapse>}

      {selectedField && <div className="mass-assign-container">
        <MassAssignProcess
          entityType={entityType}
          selectionCriteria={selection}
          fieldState={[selectedField, setSelectedField]}
          visibleState={visibleState}
        />
      </div>}


    </Modal>
  );
};

const DATA_QUERY = gql`
  query AvailableMassAssignFieldsQuery($entityType: EntityTypeEnum!) {
    availableMassAssignFields(filter: { entityTypes: [$entityType] }) {
      heading
      isHeadingKey
      fieldType
      entityTypes
      actionName
      accessRules{
        customFieldCode
        allowMutation
        allowRead
      }
    }
  }
`;

export default MassAssign;
