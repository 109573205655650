import { gql } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  PersonCriteriaOrder,
  UpdatePersonMailToPrivateMutationMutationVariables
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';


export const mailToPrivateFieldConfig = personFieldConfig(
  'mailToPrivate',
  ['id', 'mailToPrivate'],
  ({ localization }) => ({
    sorting: {
      ascend: [PersonCriteriaOrder.MAILTOPRIVATE_ASC],
      descend: [PersonCriteriaOrder.MAILTOPRIVATE_DESC],
    },
    title: localization.formatMessage(Locale.Attribute.Mail_To_Private),
    additionalTableConfig: {
      width: 100
    },
    render: (record) => record.mailToPrivate
      ? localization.formatMessage(Locale.General.Yes)
      : localization.formatMessage(Locale.General.No),
    updateView: buildSelectUpdateFieldConfig({
      mode: 'single',
      allowClear: true,
      mutation: UPDATE_PERSON_MAIL_TO_PRIVATE,
      selectPlaceholder: localization.formatMessage(Locale.Command.Select),
      selectedKey: record => record.mailToPrivate ? 'true' : 'false',
      buildVariables: (selectedKey, record) => ({
        persId: record.id,
        mailToPrivate: selectedKey === 'true',
      }) as UpdatePersonMailToPrivateMutationMutationVariables,
      fetchSuggestions: () => Promise.resolve([
        { code: 'true', label: localization.formatMessage(Locale.General.Yes) },
        { code: 'false', label: localization.formatMessage(Locale.General.No) },
      ]),
    }),
  })
);

export const UPDATE_PERSON_MAIL_TO_PRIVATE = gql`
  mutation UpdatePersonMailToPrivateMutation($persId: Int!, $mailToPrivate: Boolean!) {
    updateMailToPrivate(persId: $persId, mailToPrivate: $mailToPrivate) {
      id
      mailToPrivate
    }
  }
`;
