import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { LinkColumn } from '../../../components/Table/utils';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SitePersonCriteriaOrder } from '../../../../gql/typings';

export const linkFullNameFieldConfig = linkFieldConfig(
  'fullName',
  [
    'id',
    'person.id',
    'person.fullname',
  ],
  ({ localization, openInNewTab }) => ({
    title: localization.formatMessage(Locale.Attribute.Full_name),
    additionalTableConfig: {
      width: 300
    },
    sorting: {
      ascend: [SitePersonCriteriaOrder.PERSON_FIRSTNAME_ASC, SitePersonCriteriaOrder.PERSON_LASTNAME_ASC],
      descend: [SitePersonCriteriaOrder.PERSON_FIRSTNAME_DESC, SitePersonCriteriaOrder.PERSON_LASTNAME_DESC],
    },
    render: (record) => <LinkColumn
      text={record.person.fullname}
      url={`/hcp/${record.person.id}`}
      openInNewTab={openInNewTab}
    />,
  })
);

