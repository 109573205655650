import { isActualNumber } from './Util';

export const gqlFieldKeyAliasTransformer = (keyCode: undefined|number|string, field: string) => {
  if (!keyCode) return field.split('.')[0]!.split('(')[0]!;
  let kc = `${keyCode}`
    .replaceAll('-', '_')
    .replaceAll('.', '_')
    .replaceAll(':', '_')
    .replaceAll('(', '_')
    .replaceAll(')', '_');
  if (isActualNumber(kc[0])) kc = `d${kc}`;
  const splits = field.split('.');
  const index = splits.findIndex(v => v.includes('{{keyCode}}'));
  return `${kc}_${splits[index > 0 ? index : 0]!.split('(')[0]!}`;
};
