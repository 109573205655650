import React from 'react';
import { Col, Row, Badge } from 'antd';


const LabelWithCount = ({
  label,
  count,
  isSelected,
}) => (
  <Row className={`label-with-count-container ${isSelected ? 'active' : ''}`}>
    <Col span={20} className="text">
      {label}
    </Col>
    <Col span={4} className="count">
      <Badge
        className="primary-badge"
        count={count}
      />
    </Col>
  </Row>
);


export default LabelWithCount;

