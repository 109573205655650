import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Select } from 'antd';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import {
  MergeItemPersonSegmentationFragment,
  MergeRequestSegmentationQuery,
  MRSetSegmentationCodeMutation, MRSetSegmentationCodeMutationVariables
} from '../../../../../../../../../gql/typings';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';

const AdminMergeItemPersonSegmentation: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonSegmentationFragment;
  const localization = useLocalization();
  const { data, loading } = useQuery<MergeRequestSegmentationQuery>(DATA_QUERY);
  const [setNewValue] = useMutation<MRSetSegmentationCodeMutation, MRSetSegmentationCodeMutationVariables>(SET_MUTATION);

  if (!props.item.hasConflict) return (
    <AdminMergeItemBasicField {...props}>
      Segmentation code of {info.loser.heading} will be moved over.
    </AdminMergeItemBasicField>
  );

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.loser.id}
      toValue={info.winner?.id}
      valueDisplayMapper={id => data?.segmentationCodes?.nodes?.filter(n => n.id === id)?.[0]?.heading}
      inputRenderer={props => (
        <Select
          {...props}
          loading={loading}
          options={(data?.segmentationCodes?.nodes ?? []).map(n => ({ value: n.id, label: n.heading }))}
        />
      )}
      updateMutation={newValue => setNewValue({
        variables: {
          personId: props.into.id,
          segmentationCodeId: newValue,
        },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_person_segmentation_code_conflict_mismatch_description, {
        segmentation1: <strong>{info.loser.heading}</strong>,
        segmentation2: <strong>{info.winner?.heading}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

const SET_MUTATION = gql`
  mutation MRSetSegmentationCode($personId: Int!, $segmentationCodeId: Int!) {
    upsertPersonSegmentationCode(id: $personId, segmentationId: $segmentationCodeId) {
      id
    }
  }
`;

const DATA_QUERY = gql`
  query MergeRequestSegmentation {
    segmentationCodes {
      hash
      nodes {
        id
        heading
      }
    }
  }
`;

gql`
  fragment MergeItemPersonSegmentation on PersonSegmentation_EntityDataToBeTransferredType {
    code
    dataType
    entityType
    loser {
      id
      heading
    }
    winner {
      id
      heading
    }
  }
`;

export default AdminMergeItemPersonSegmentation;
