import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Tabs } from 'antd';
import { sortBy } from 'lodash';
import { DetailedGridLayoutItemProps } from '../DetailedGridLayout';
import {
  gridRelationsConfigurators
} from '../AdminGridRight/AdminGridRightRelationsConfigurator/AdminGridRightRelationsConfigurator';
import { useLocalization } from '../../../../util/useLocalization';
import {
  AdminGridRightRelationsPreviewQueryQuery,
  AdminGridRightRelationsPreviewQueryQueryVariables,
  EntityTypeEnum
} from '../../../../../gql/typings';

const AdminDetailedGridRelationsPreview: React.FC<DetailedGridLayoutItemProps> = ({
  entityType,
  entityGridItemConfigurationId,
}) => {
  if (entityType !== EntityTypeEnum.PERSON && entityType !== EntityTypeEnum.SITE && entityType !== EntityTypeEnum.AFFILIATION) {
    return <span>Preview for {entityType} is currently not supported</span>;
  }
  const localization = useLocalization();
  const {
    data,
  } = useQuery<AdminGridRightRelationsPreviewQueryQuery, AdminGridRightRelationsPreviewQueryQueryVariables>(DATA_QUERY, {
    variables: { entityGridItemConfigurationId },
  });

  const configs = gridRelationsConfigurators[entityType]!;

  const tabs = sortBy((data?.entityGridItemRelationFields.nodes ?? []).filter(n => n.isActive), 'sort');

  return (
    <Tabs
      activeKey="none"
      items={tabs.map(tab => (
        {
          key: tab.id.toString(),
          label: localization.formatMessage(configs[tab.relationTabKey]!.title)
        }
      ))}
    />
  );
};


const DATA_QUERY = gql`
  query AdminGridRightRelationsPreviewQuery($entityGridItemConfigurationId: ID!) {
    entityGridItemRelationFields(criteria: {
      entityGridItemConfigurationId: $entityGridItemConfigurationId,
      isActive: null
    }) {
      hash
      nodes {
        id
        sort
        relationTabKey
        isActive
      }
    }
  }
`;

export default AdminDetailedGridRelationsPreview;
