import { Form, Switch } from 'antd';
import React from 'react';
import { CreateCustomFieldTypeConfigurationsProps } from '../createCustomFieldTypeConfigurations';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import Icon from '../../../../components/Icon/Icon';


const CreateCustomFieldCheckConfiguration: React.FC<CreateCustomFieldTypeConfigurationsProps> = ({
  field,
}) => {
  const defaultStatus = field?.isActive || true;
  const defaultMultipleStatus = field?.allowMultiple || true;
  const defaultCheckableTags = field?.isCheckableTag || false;
  const localization = useLocalization();
  return (
    <div>

      <Form.Item
        label={<Icon
          iconType="INFO"
          text={localization.formatMessage(Locale.Text.Allow_multiple)}
          iconBeforeText={false}
          toolTip={localization.formatMessage(Locale.Text.Allow_Multiple_Values_ToolTip)}
        />}
        name="allowMultiple"
        initialValue={defaultMultipleStatus}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label={<Icon
          iconType="INFO"
          text="Display as chackable tags"
          iconBeforeText={false}
          toolTip={localization.formatMessage(Locale.Text.Allow_Multiple_Values_ToolTip)}
        />}
        name="isCheckableTag"
        initialValue={defaultCheckableTags}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>

      <Form.Item
        label={<Icon
          iconType="INFO"
          text={localization.formatMessage(Locale.General.Status)}
          iconBeforeText={false}
          toolTip={localization.formatMessage(Locale.Text.Custom_Field_Status_ToolTip)}
        />}
        valuePropName="checked"
        initialValue={defaultStatus}
        name="isActive"
      >
        <Switch />
      </Form.Item>
    </div>
  );
};

export default CreateCustomFieldCheckConfiguration;
