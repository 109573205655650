import React from 'react';
import { isEmpty } from 'lodash';
import Icon from '../Icon/Icon';
import { DcrGroupDetailQuery } from '../../../gql/typings';

type DcrEntityProps = {
  nodes: NonNullable<DcrGroupDetailQuery['dcrGroup']>['dcrs']['nodes'];
};

const DcrEntity: React.FC<DcrEntityProps> = ({
  nodes,
}) => {
  if (!nodes || isEmpty(nodes)) {
    return <></>;
  }

  return (
    <div className="dcr-entity-container" style={{ display: 'flex', columnGap: 4 }}>
      {nodes
        .filter(n => n.entityTypeEnum)
        .map(it => <Icon key={it.id} iconType={it.entityTypeEnum!} />)
        // @ts-ignore TODO: Please find a non ignore way of doing this!
        .reduce((prev, curr) => [prev, ' / ', curr])}
    </div>
  );
};


export default DcrEntity;
