import { gql } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  PersonCriteriaOrder,
  UpdatePersonExamYearMutationMutationVariables
} from '../../../../gql/typings';
import { buildDateUpdateFieldConfig } from '../../../components/FieldConfig/buildDateUpdateFieldConfig';

export const personExamYearFieldConfig = personFieldConfig(
  'examYear',
  ['id', 'examYear', 'examYearAsDate', 'controlSetting.id', 'controlSetting.createDcr'],
  ({ localization }) => ({
    sorting: {
      ascend: [PersonCriteriaOrder.EXAM_YEAR_ASC],
      descend: [PersonCriteriaOrder.EXAM_YEAR_DESC],
    },
    title: localization.formatMessage(Locale.Attribute.Exam_year),
    additionalTableConfig: {
      width: 80
    },
    dcrInfo: record => ({
      entityType: EntityTypeEnum.PERSON,
      entityAffiliationId: record.id,
      field: FieldEnum.HCP_EXAMYEAR
    }),
    render: (person) => person.examYear ?? '',
    updateView: buildDateUpdateFieldConfig({
      mode: 'year',
      valuePath: 'examYearAsDate',
      mutation: UPDATE_PERSON_EXAM_YEAR_MUTATION,
      buildVariables: (newDate, record) => ({
        persId: record.id,
        year: newDate?.year(),
      }) as UpdatePersonExamYearMutationMutationVariables,
    }),
  })
);

const UPDATE_PERSON_EXAM_YEAR_MUTATION = gql`
  mutation UpdatePersonExamYearMutation($persId: Int!, $year: Year) {
    updatePersonExamYear(persId: $persId, date: $year) {
      id
      examYear
      examYearAsDate
    }
  }
`;
