import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Badge, Form, Input, message, Modal, Popconfirm, Tabs, TabsProps } from 'antd';
import { useIntl } from 'react-intl';
import MainView from '../../components/View/MainView';
import { usePageTitle } from '../../hooks/usePageTitle';
import EstherEasterEgg from '../../components/EasterEggs/EstherEasterEgg';
import { Locale } from '../../../localization/LocalizationKeys';
import DashboardGridView from './DashboardGridView/DashboardGridView';
import SimpleFlex from '../../components/SimpleFlex/SimpleFlex';
import Icon from '../../components/Icon/Icon';
import {
  CreateNewDashboardMutationMutation,
  CreateNewDashboardMutationMutationVariables,
  DashboardViewQueryQuery, DisableDashboardMutationMutation, DisableDashboardMutationMutationVariables
} from '../../../gql/typings';


const ActivityDashboard = () => {
  const intl = useIntl();

  const { data } = useQuery<DashboardViewQueryQuery>(DATA_QUERY);
  usePageTitle(intl.formatMessage(Locale.General.Dashboard));
  const [
    insertDashboard,
  ] = useMutation<CreateNewDashboardMutationMutation, CreateNewDashboardMutationMutationVariables>(createNewDashboard);
  const [
    removeDashboard,
  ] = useMutation<DisableDashboardMutationMutation, DisableDashboardMutationMutationVariables>(disableDashboard);

  const [addMode, setAddMode] = useState(false);
  const [form] = Form.useForm();

  const onEdit: TabsProps['onEdit'] = (targetKey, action) => {
    if (action === 'add') {
      setAddMode(true);
    }
  };

  const onSubmit = () => form.validateFields()
    .then(values => {
      insertDashboard({
        variables: {
          title: values['dashboard-heading'],
        },
        refetchQueries: ['DashboardViewQuery', 'DashboardItemsQuery'],
      })
        .then(() => setAddMode(false));
    });

  const onDelete = (id: number) => {
    removeDashboard({
      variables: {
        dashboardId: id,
      },
      refetchQueries: ['DashboardViewQuery', 'DashboardItemsQuery'],
    })
      .finally(() => message.info(intl.formatMessage(Locale.Text.Dashboard_has_been_deleted)));
  };

  return (
    <MainView
      title={intl.formatMessage(Locale.General.Dashboard)}
      className="dashboard-view-container"
      withoutPadding
    >
      <Tabs
        defaultActiveKey="1"
        type="editable-card"
        onEdit={onEdit}
        items={(data?.viewer?.dashboards?.nodes ?? []).map(dashboard => ({
          key: dashboard.id.toString(),
          label: <SimpleFlex>
            {intl.formatMessage({ id: dashboard.headingKey, defaultMessage: dashboard.headingKey })}
            <Badge
              className="primary-badge"
              count={dashboard.dashboardItemConfigurations.totalCount}
            />
          </SimpleFlex>,
          closable: !dashboard.isDefaultDashboard,
          closeIcon: <Popconfirm
            placement="right"
            onConfirm={() => onDelete(dashboard.id)}
            okText={intl.formatMessage(Locale.Command.Delete)}
            cancelText={intl.formatMessage(Locale.Command.Cancel)}
            title={intl.formatMessage(Locale.Text.Delete_Dashboard_Are_You_Sure)}
          >
            <Icon iconType="CLOSE" />
          </Popconfirm>,
          children: <DashboardGridView
            dashboardId={dashboard.id}
            totalItemsCount={dashboard.dashboardItemConfigurations.totalCount}
          />

        }))}
      />
      <section className="calendar-dashboard-section">
        <EstherEasterEgg />
      </section>

      <Modal
        title={intl.formatMessage(Locale.Text.Create_Dashboard_New_Dashboard)}
        open={addMode}
        okText={intl.formatMessage(Locale.Command.Create)}
        cancelText={intl.formatMessage(Locale.Command.Cancel)}
        onOk={onSubmit}
        onCancel={() => setAddMode(false)}
      >
        <Form form={form}>
          <Form.Item
            name="dashboard-heading"
            rules={[
              {
                required: true,
                message: intl.formatMessage(Locale.Text.Please_enter_heading)
              },
              {
                max: 20,
                message: intl.formatMessage(Locale.Text.Heading_must_be_beneath_20_characters)
              },
            ]}
          >
            <Input
              autoFocus
              placeholder={intl.formatMessage(Locale.Command.Enter_heading)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </MainView>
  );
};

const createNewDashboard = gql`
    mutation CreateNewDashboardMutation($title: String!) {
        createNewDashboard(title: $title) {
            id
        }
    }
`;

const disableDashboard = gql`
    mutation DisableDashboardMutation($dashboardId: Int!) {
        RemoveDashboard(dashboardId: $dashboardId) {
            id
        }
    }
`;


const DATA_QUERY = gql`
    query DashboardViewQuery {
        viewer {
            id
            dashboards {
                hash
                nodes {
                    id
                    headingKey
                    isDefaultDashboard
                    dashboardItemConfigurations {
                        hash
                        totalCount
                    }
                }
            }
        }
    }
`;

export default ActivityDashboard;
