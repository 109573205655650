import React from 'react';
import { CheckCircleTwoTone, ExclamationCircleTwoTone, RightCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { yellow, green, red } from '@ant-design/colors';
import { LocalizationShape, useLocalization } from '../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../localization/LocalizationKeys';

export type MergeRequestPrefixIconState = 'OK'|'CONFLICT'|'MOVED';


type MergeRequestItemPrefixIconProps = {
  state: MergeRequestPrefixIconState;
};

const icons: Record<MergeRequestPrefixIconState, React.FC<{ localization: LocalizationShape }>> = {
  OK: () => <CheckCircleTwoTone twoToneColor={green[5]!} />,
  MOVED: ({ localization }) => (
    <Tooltip title={localization.formatMessage(Locale.Text.Data_transfer)}>
      <RightCircleTwoTone twoToneColor={yellow[6]!} />
    </Tooltip>
  ),
  CONFLICT: ({ localization }) => (
    <Tooltip title={localization.formatMessage(Locale.Text.Data_conflict)}>
      <ExclamationCircleTwoTone twoToneColor={red[5]!} />
    </Tooltip>
  ),
};

const MergeRequestItemPrefixIcon: React.FC<MergeRequestItemPrefixIconProps> = ({ state, children }) => {
  const localization = useLocalization();
  const Icon = icons[state]!;

  return (
    <>
      <Icon localization={localization} />
      &nbsp;&nbsp;
      {children}
    </>
  );
};

export default MergeRequestItemPrefixIcon;
