import React, { useEffect, useRef, useState } from 'react';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { Button, Timeline } from 'antd';
import { useInViewport } from 'ahooks';
import moment from 'moment';
import InstanceReleaseModal from './InstanceReleaseModal';
import { PureAdvanceReleasesQueryQuery, PureAdvanceReleasesQueryQueryVariables } from '../../../../../gql/typings';
import { DATE_FORMAT } from '../../../../util/format';

const perLoadLimit = 10;
const InstanceReleases: React.FC = () => {
  const [limit, setLimit] = useState(perLoadLimit);
  const ref = useRef();
  const inViewPort = useInViewport(ref);
  const {
    data,
    loading,
    fetchMore,
    previousData,
  } = useQuery<PureAdvanceReleasesQueryQuery, PureAdvanceReleasesQueryQueryVariables>(DATA_QUERY, {
    variables: { limit, offset: 0 },
  });
  const selectedVersionState = useState<NonNullable<PureAdvanceReleasesQueryQuery['releases']>['values']['0']>();

  useEffect(() => {
    if (inViewPort && data && !loading && limit < data?.releases.total) fetchMore({
      variables: {
        offset: data!.releases.values.length,
        limit: perLoadLimit,
      },
    }).then(res => {
      setLimit(data!.releases.values.length + res.data.releases.values.length);
    });
  }, [data, loading, inViewPort, limit, fetchMore]);

  return (
    <div style={{ margin: 12 }}>
      <div>
        <Timeline
          mode="left"
          pending={loading}
          items={(data?.releases.values ?? previousData?.releases.values ?? []).map(release => (
            {
              key: release.id,
              label: <Button type="link" onClick={() => selectedVersionState[1](release)}>{release.version}</Button>,
              color: release.releaseDate ? undefined : 'orange',
              children: release.releaseDate ? moment(release.releaseDate).format(DATE_FORMAT) : 'In Progress'
            }
          ))}
        />
      </div>
      {/* @ts-ignore */}
      <span ref={ref} />
      <InstanceReleaseModal selectedState={selectedVersionState} />
    </div>
  );
};

const DATA_QUERY = gql`
  query PureAdvanceReleasesQuery($limit: Int!, $offset: Int!) {
    releases(criteria: { limit: $limit, offset: $offset }) {
      self
      total
      values {
        id
        version: name
        description
        releaseDate
      }
    }
  }
`;

export default InstanceReleases;
