import React from 'react';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Popover, Row } from 'antd';
import moment from 'moment';
import { Optional } from '../../../../util/StateArrayType';
import { DATE_FORMAT } from '../../../../util/format';

type PipelineStatus = 'running'|'success'|'failed';

export type GitlabPipelineIconProps = {
  schedule: Optional<{
    id: number;
    next_run_at?: string;
    last_pipeline?: {
      id: number;
      duration?: number;
      created_at: string;
      status: PipelineStatus;
    };
  }>;
};

const GitlabPipelineIcon: React.FC<GitlabPipelineIconProps> = ({ schedule }) => {
  const test: Record<PipelineStatus|'missing', React.ReactNode> = {
    missing: <QuestionCircleOutlined />,
    failed: <CloseCircleOutlined style={{ color: 'red' }} />,
    running: <ClockCircleOutlined style={{ color: 'yellow' }} />,
    success: <CheckCircleOutlined style={{ color: 'green' }} />,
  };
  const last_run_str = schedule?.last_pipeline?.created_at;
  const next_run_str = schedule?.next_run_at;

  return (
    <Popover
      content={<Row>
        <Col span={8}><strong>Schedule ID</strong>:</Col>
        <Col span={12}>{schedule?.id ?? 'Unknown'}</Col>
        <Col span={8}><strong>Pipeline ID</strong>:</Col>
        <Col span={12}>{schedule?.last_pipeline?.id ?? ''}</Col>
        <Col span={8}><strong>Last Status</strong>:</Col>
        <Col span={12}>{schedule?.last_pipeline?.status ?? ''}</Col>
        <Col span={8}><strong>Last Run</strong>:</Col>
        <Col span={12}>{last_run_str ? moment(last_run_str).format(DATE_FORMAT) : 'Never'}</Col>
        <Col span={8}><strong>Next Run</strong>:</Col>
        <Col span={12}>{next_run_str ? moment(next_run_str).format(DATE_FORMAT) : 'Not scheduled'}</Col>
      </Row>}
    >
      {test[schedule?.last_pipeline?.status ?? 'missing']}
    </Popover>
  );
};

export default GitlabPipelineIcon;

