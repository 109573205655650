import React, { useState } from 'react';
import cherrypickIcon from '../../../../../img/icon-download-blue.svg';
import SiteCherryPickPersonsModal from '../../SiteCherryPickPersonsModal';

type SiteCherryPickTableButtonProps = {
  record: {
    isLocal: boolean;
    name: string;
    apurebaseId?: number;
    countryCode: string;
    visitAddress?: {
      id: number;
      street?: string;
      postalCode?: string;
    };
  };
};

const SiteCherryPickTableButton: React.FC<SiteCherryPickTableButtonProps> = ({ record }) => {
  const visibleState = useState(false);
  if (record && record.isLocal) return <span />;
  return (
    <div className="site-cherry-pick-table-button-container">
      <img
        className="cherrypick"
        src={cherrypickIcon}
        alt={`Cherrypick ${record.name}`}
        onClick={() => visibleState[1](true)}
      />
      {visibleState[0] && <SiteCherryPickPersonsModal visibleState={visibleState} record={record} />}
    </div>
  );
};

export default SiteCherryPickTableButton;
