import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Modal, Space } from 'antd';
import CheckableTag from 'antd/es/tag/CheckableTag';
import { TableFieldUpdateViewProps } from '../../../search_old/types';
import { getVariableField } from '../../../person/fields/utils/personFieldsUtils';
import {
  CustomFieldFragmentFragment,
  EntityCustomFieldCheckableOptionsQueryQuery,
  EntityCustomFieldCheckableOptionsQueryQueryVariables
} from '../../../../../gql/typings';
import { useLocalization } from '../../../../util/useLocalization';
import { usePersonFieldMutation } from '../../../person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import { CUSTOM_UPSERT_MUTATION, FIELD_OPTIONS } from './customFieldUtils';
import { Locale } from '../../../../../localization/LocalizationKeys';
import Loading from '../../../../components/Loading/Loading';

const CustomFieldCheckableTagInput: React.FC<TableFieldUpdateViewProps> = (props) => {
  const values = getVariableField(props!.record, props!.options, 'customFields');
  const selected = values.nodes
    .map((it: NonNullable<CustomFieldFragmentFragment['options']>['nodes']['0']) => it.value!.id) as number[];
  const [visible, setVisible] = useState(true);
  const localization = useLocalization();

  const { data, loading } = useQuery<EntityCustomFieldCheckableOptionsQueryQuery
  , EntityCustomFieldCheckableOptionsQueryQueryVariables>(FIELD_OPTIONS, {
    variables: {
      code: props.options.selectedOption!.code as string
    }
  });
  const { submit, blocking } = usePersonFieldMutation({
    mutation: CUSTOM_UPSERT_MUTATION,
    controlSetting: props.record?.controlSetting,
  });

  const onChange = (tag: NonNullable<CustomFieldFragmentFragment['options']>['nodes']['0'], checked: boolean) => {
    if (!tag.value) return;
    if (!blocking) {
      const selectedIds = (checked ? props.options.selectedOption?.allowMultiple
        ? [...selected, tag.value!.id] : [tag.value!.id]
        : selected.filter(val => val !== tag.value!.id)).map(val => ({ valueId: val }));
      submit({
        customFieldCode: props.options.selectedOption?.code,
        recordId: props.record.id,
        customValues: selectedIds,
      }).finally(props.options.refetchData);
    }
  };

  const close = () => {
    if (!blocking) {
      setVisible(false);
      props.endEditing();
    }
  };

  if (loading) return <Loading spinSize='small' />;

  return (
    <Modal
      title={props?.options.selectedOption?.label}
      open={visible}
      onCancel={close}
      closable={false}
      footer={false}
      width="clamp(320px, 75%, 480px)"
      wrapClassName='custom-field-modal-container'
    >
      {
        data?.customField?.options?.nodes.map(tag => (
          <CheckableTag
            key={tag.value!.id}
            checked={selected.includes(tag.value!.id)}
            onChange={checked => onChange(tag, checked)}
          >
            {tag.value?.value}
          </CheckableTag>
        ))
      }

      <Space style={{ width: '100%', justifyContent: 'center', marginTop: 20 }}>
        {
          blocking ? <Loading spinSize='small' />
            : <Button key="2" type="primary" className="save-button" onClick={close}>
              {localization.formatMessage(Locale.General.Ok)}
            </Button>
        }
      </Space>
    </Modal>
  );

};

export default CustomFieldCheckableTagInput;
