import React from 'react';
import { DatePicker, Form } from 'antd';
import { gql, useQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import { DynamicReportInputProps } from '../dynamicReportInputUtils';
import { useLocalization } from '../../../../../util/useLocalization';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { FlagCountry } from '../../../../../components/Flag/Flag';
import CountryPicker from '../../../../../components/Input/Fields/CountryPicker';
import { Optional } from '../../../../../util/StateArrayType';
import { KPIReportInputQueryQuery, KPIReportInputQueryQueryVariables } from '../../../../../../gql/typings';


const KpiReportInput: React.FC<DynamicReportInputProps> = () => {
  const localization = useLocalization();


  return (
    <div style={{ width: 'clamp(400px, 75%, 680px)' }}>
      <Form.Item name="country" label={localization.formatMessage(Locale.Attribute.Country)}>
        <CountryPicker />
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(prev, next) => prev.country !== next.country}>
        {({ getFieldValue }) => <Form.Item name="year" label={localization.formatMessage(Locale.Attribute.Year)}>
          <CountryComponent country={getFieldValue('country')} />
        </Form.Item>}
      </Form.Item>
    </div>
  );
};

type CountryProps = {
  country: Optional<FlagCountry>;
  value?: Optional<number>;
  onChange?: (newValue: Optional<number>) => void;
};

const CountryComponent: React.FC<CountryProps> = ({ country, value, onChange }) => {
  const localization = useLocalization();
  const { data, loading } = useQuery<KPIReportInputQueryQuery, KPIReportInputQueryQueryVariables>(DATA_QUERY, {
    variables: { country },
    skip: !country,
  });

  return <DatePicker
    disabled={loading || !country || !data || data.salesCycleSetups.nodes.length === 0}
    style={{ width: '100%' }}
    placeholder={localization.formatMessage(Locale.Command.Select_Year)}
    picker="year"
    value={value ? dayjs().set('year', value) : undefined}
    onChange={newValue => onChange?.(newValue?.get('year') ?? null)}
    disabledDate={(curr: Dayjs) => !data?.salesCycleSetups?.nodes?.map(e => e.year)?.includes(curr.get('year'))}
  />;
};

const DATA_QUERY = gql`
  query KPIReportInputQuery($country: CountryCode!) {
    salesCycleSetups(criteria: { countries: [$country] }) {
      hash
      nodes {
        id
        year
      }
    }
  }
`;

export default KpiReportInput;
