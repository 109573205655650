import { gql } from '@apollo/client';
import { DashboardItemFragmentFragment } from '../../../gql/typings';

export type DashboardItemProps = {
  configuration: DashboardItemFragmentFragment;
  recentlyCreatedId: number;
  dashboardId: number;
  dashboardItemId: number;
  editStatus: boolean;
  size: { width: number; height: number };
};

export const DashboardItem_FRAGMENT = gql`
  fragment DashboardItemFragment on DashboardItemConfiguration {
    id
    dashboardItemCode
    headingKey
    dashboardItem {
      code
      headingKey
      minHeight
      minWidth
    }
  }
`;
