import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Rule } from 'rc-field-form/lib/interface';
import { Col, Form, Space, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useLocalization } from '../../../util/useLocalization';

type CreateCustomFieldInputItemFieldProps = {
  name: string;
  nameResource: { id: string };
  tooltipResource: { id: string };
  rules?: Rule[];
};

const CreateCustomFieldInputItemField: React.FC<CreateCustomFieldInputItemFieldProps> = ({
  children,
  name,
  rules,
  nameResource,
  tooltipResource,
}) => {
  const localization = useLocalization();
  return (
    <>
      <Col span={4} style={{ margin: 'auto' }}>
        {localization.formatMessage(nameResource)}
      </Col>
      <Col span={20}>
        <Space style={{ width: '100%' }}>
          <Form.Item name={name} rules={rules} noStyle>
            {/* @ts-ignore */}
            {React.cloneElement(children, {
              style: {
                width: '100%',
                minWidth: 240,
              },
            })}
          </Form.Item>
          <Tooltip title={localization.formatMessage(tooltipResource)}>
            <InfoCircleOutlined />
          </Tooltip>
        </Space>
      </Col>
    </>
  );
};

export default CreateCustomFieldInputItemField;
