import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';
import { PermissionEnum, PersonCriteriaOrder } from '../../../../gql/typings';

export const personSegmentationFieldConfig = personFieldConfig(
  'segmentation',
  [
    'segmentationCode.id',
    'segmentationCode.heading',
  ],
  ({ localization }) => ({
    sorting: {
      ascend: PersonCriteriaOrder.SEGMENTATION_ASC,
      descend: PersonCriteriaOrder.SEGMENTATION_DESC,
    },
    permission: PermissionEnum.HCP_SEGMENTATION,
    title: localization.formatMessage(Locale.Attribute.HCP_Segment),
    additionalTableConfig: {
      width: 90
    },
    render: record => record.segmentationCode?.heading,
  })
);

