/* eslint-disable @typescript-eslint/no-explicit-any */
import { Badge } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
// @ts-ignore - there is no typescript support
import Diff from 'react-stylable-diff';
import { ColumnsType } from 'antd/lib/table/interface';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { AnyValueType } from '../../../../../util/StateArrayType';
import { CurrentImportProjectStateQuery } from '../../../../../../gql/typings';


const getScoreStatus = (score: number): 'error'|'warning'|'success' => {
  if (score > 90) return 'success';
  if (score > 65) return 'warning';
  return 'error';
};

export function diffs<T>(
  columns: ColumnsType<T>,
  matchingAgainst: T,
): ColumnsType<T> {
  const intl = useIntl();
  return (
    [
      ...columns.map((column: any) => ({
        ...column,
        render: (text: string) => (
          <Diff
            type="words"
            // @ts-ignore
            inputA={matchingAgainst[column.dataIndex]}
            inputB={text}
          />
        ),
      })),
      {
        title: intl.formatMessage(Locale.Attribute.Score),
        dataIndex: 'score',
        render: (score: number) => (
          <Badge
            text={score}
            status={getScoreStatus(score)}
          />
        ),
      },
    ]
  );
}

type CandidateType = Extract<NonNullable<
CurrentImportProjectStateQuery['importProject']>['manual'], { ['__typename']: 'ImportManualPersonStage' }>['candidates']['0'];

export function candidateFieldsToTableObject<T extends { apurebaseId?: string; inLocalDb?: boolean; score?: number }>(
  fields: CandidateType['fields'],
  accumulator: T
): T & Record<string, AnyValueType> {
  return fields.reduce((acc, { fieldName, value }) => ({
    ...acc,
    [fieldName]: value
  }), accumulator);
}

export function candidatesToTableObject(
  candidates: CandidateType[]
) {
  return candidates.map(c => candidateFieldsToTableObject(c.fields, {
    apurebaseId: c.apurebaseId,
    inLocalDb: c.inLocalDb,
    score: c.score,
  }));
}

// export const candidatesToTableObject = candidates => candidates.map(c => candidateFieldsToTableObject(c.Fields, {
// }));
