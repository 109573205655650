import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SitePersonCriteriaOrder } from '../../../../gql/typings';

export const linkPureAdvanceIdFieldConfig = linkFieldConfig(
  'id',
  ['id'],
  ({ localization, openInNewTab }) => ({
    title: localization.formatMessage(Locale.Attribute.PureAdvance_ID),
    additionalTableConfig: {
      width: 100
    },
    sorting: {
      ascend: [SitePersonCriteriaOrder.ID_ASC],
      descend: [SitePersonCriteriaOrder.ID_DESC],
    },
    render: (record) => <LinkBadge
      url={`/link/${record.id}`}
      label={record.id}
      openInNewTab={openInNewTab}
    />,
  })
);
