import { values, isArray, isNil, mapValues, toNumber, trimStart, groupBy, map, isString } from 'lodash';
import moment, { isMoment } from 'moment';
import { criteriaInputKey } from '../../../components/CriteriaInput/CriteriaInputFactory';
import { DynamicCriteriaInput, DynamicCriteriaValueInput } from '../../../../gql/typings';

type FactoryArgs = {
  filterOutEmpties?: boolean;
};

/**
 * Values object where the `key` is the `criteriaId` and `pathId`. Example "ci25-522"
 */
type SearchFilter = Record<string, boolean|string|number|string[]|number[]|null>;

type Value = { criteriaId: number; pathId: number; value: string };

/**
 * Converts antd data to a criteria input object.
 * TODO: This function should support custom operatorTypes.
 */
export function criteriasToArrayFactory(factoryArgs: FactoryArgs = { filterOutEmpties: true }) {
  return (searchFilter: SearchFilter): DynamicCriteriaInput[] => {
    // 2. mapValuesWithKey
    const mappedValues = mapValues(searchFilter, (value, key) => {
      const [criteriaId, pathId] = trimStart(key, criteriaInputKey).split('-');
      return {
        criteriaId: toNumber(criteriaId),
        pathId: toNumber(pathId),
        value: isMoment(value) ? moment(value).toISOString() : value,
      };
    }) as Record<string, Value>;

    // 3. valuesFp
    const valuesList = values(mappedValues) as Value[];

    // 4. groupByFp
    const groupped = groupBy(valuesList, c => c.criteriaId) as Record<number, Value[]>;

    // 5. mapFp
    const final = map(groupped, (list: Value[]) => {
      if (list.length === 1) return {
        criteriaId: list[0]!.criteriaId,
        value: extract(list[0]!),
      };
      return {
        criteriaId: list[0]!.criteriaId,
        value: { values: list.map(extract) },
      };
    }) as DynamicCriteriaInput[];

    // 1. omitByFp
    if (factoryArgs.filterOutEmpties) {
      return final.map(input => {
        if (groupped[input.criteriaId]!.length > 1) {
          if ((input.value?.values?.length ?? 0) > 0) {
            const test = {
              criteriaId: input.criteriaId,
              value: {
                values: input.value!.values!.filter(v => {
                  if (v.value) return true;
                  return !!(v.values && v.values.length > 0);
                }),
              }
            };
            if (test.value.values.length === 0) return null;
            return test;
          }
          return null;
        }
        if (isNil(input.value?.value) && !input.value?.values) return null;
        if (input.value?.values && input.value.values.length === 0) return null;
        return input.value?.value === '' ? null : input;
      }).filter(e => e) as DynamicCriteriaInput[];
    }
    return final;
  };
}


const extract = (item: Value): DynamicCriteriaValueInput => {
  if (isArray(item.value)) return {
    pathId: item.pathId,
    values: item.value.map(v => ({ value: v })),
  };

  if (isString(item.value)) {
    // replace() removes any extra white spaces inside the string itself.
    item.value = (item.value).replace(/\s+/g, ' ').trim();
  }

  return {
    pathId: item.pathId,
    value: item.value,
  };
};
