import React from 'react';
import { Select } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import {
  SelectWorkingSpecialtiesQueryQuery, SelectWorkingSpecialtiesQueryQueryVariables
} from '../../../../gql/typings';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';

type WorkingSpecialityFormInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  countryCode: string;
  disabled?: boolean;
};

const WorkingSpecialityFormInput: React.FC<WorkingSpecialityFormInputProps> = ({
  value,
  onChange,
  disabled,
  countryCode
}) => {
  const localization = useLocalization();
  const {
    data,
    loading,
    refetch,
  } = useQuery<SelectWorkingSpecialtiesQueryQuery, SelectWorkingSpecialtiesQueryQueryVariables>(WORKING_SPECIALITY_DATA_QUERY,
    { variables: { countries: countryCode ? [countryCode] : [], search: '%' } });
  const searchDebounced = debounce(
    search => {
      const input: SelectWorkingSpecialtiesQueryQueryVariables = {
        countries: countryCode ? [countryCode] : [],
        search: `%${search}%`,
      };
      if (countryCode) input.countries = [countryCode];
      return refetch(input);
    },
    25,
  );

  return (
    <Select
      placeholder={localization.formatMessage(Locale.Command.Select_Working_Specialty)}
      mode='multiple'
      style={{ minWidth: 120 }}
      loading={loading}
      value={value as string|undefined}
      showSearch
      onSearch={searchDebounced}
      filterOption={false}
      onChange={e => {
        onChange?.(e);
        searchDebounced('');
      }}
      disabled={disabled}
      allowClear
    >
      {
        (data?.availableSpecialties.nodes ?? []).map(ws => (
          <Select.Option key={ws!.code} value={ws!.code}>
            {
              ws!.label
            }
          </Select.Option>
        ))
      }
    </Select>
  );
};

export const WORKING_SPECIALITY_DATA_QUERY = gql`
  query SelectWorkingSpecialtiesQuery($countries: [CountryCode!]!, $search: String) {
    availableSpecialties: workingSpecialties(
      criteria: {
        label: $search,
        countries: $countries, 
        fetchSize: {limit: 5000}
      }
    ) {
      hash
      nodes {
        code
        label: displayLabel
        type
      }
    }
  }
`;

export default WorkingSpecialityFormInput;
