import React from 'react';
import { Alert, Checkbox, Divider, message, Result, Skeleton, Table, Tooltip, Typography } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { isNull } from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useMatch, useNavigate } from 'react-router-dom';
import { usePermission } from '../../../../permission/usePermission';
import MainView from '../../../../components/View/MainView';
import { isActualNumber } from '../../../../util/Util';
import {
  PermissionLevel,
  RoleOverviewQueryQuery,
  RoleOverviewQueryQueryVariables,
  UpdatePermissionMutationMutation, UpdatePermissionMutationMutationVariables
} from '../../../../../gql/typings';

type virtualPermission = NonNullable<RoleOverviewQueryQuery['role']>['permissions']['0'];

const RoleDetails: React.FC = () => {
  const match = useMatch('/admin/role/:id');
  const navigate = useNavigate();
  const {
    read,
    loading: permissionLoading,
  } = usePermission('RolePermission');

  if (!isActualNumber(match?.params?.id)) return <MainView className="user-admin-details-container">
    <Result status="500" />
  </MainView>;
  const roleId = match!.params.id!;

  const { data, loading, refetch } = useQuery<RoleOverviewQueryQuery, RoleOverviewQueryQueryVariables>(DATA_QUERY, {
    variables: { roleId },
  });
  const [updatePermission] = useMutation<UpdatePermissionMutationMutation, UpdatePermissionMutationMutationVariables>(
    PERMISSION_UPDATE_MUTATION,
  );


  if (permissionLoading || loading) return <MainView className="role-details-container"><Skeleton /></MainView>;
  if (!read) return <Result status={403} subTitle="You do not have access to be in this area!" />;

  const sorted: virtualPermission[][] = [];
  const map: Record<string, number> = {};
  data?.role?.permissions?.map(permission => {
    const key = permission.module ?? '-1';
    if (!(key in map)) {
      map[key] = sorted.length;
      sorted.push([]);
    }
    sorted[map[key]!]!.push(permission);
  });

  const onUpdate = (record: virtualPermission, level: PermissionLevel) => (checked: CheckboxChangeEvent) => updatePermission({
    variables: {
      roleId: record.roleId,
      level,
      permission: record.permissionEnum,
      allow: checked.target.checked,
    },
  }).then(() => message.success('Successfully updated permission rule'))
    .finally(() => refetch());

  const rowKey = () => new Date().getTime() * Math.random();

  return (
    <MainView
      className="role-details-container"
      title={data?.role?.heading}
      breadcrumbs={[
        {
          label: 'Admin Settings',
          onClick: () => navigate('/admin'),
        },
        {

          label: 'Users',
          onClick: () => navigate('/admin/users'),
        },
        {
          label: 'Roles',
          onClick: () => navigate('/admin/users/roles'),
        },
        {
          label: data?.role?.heading ?? '',
        },
      ]}
    >
      <Typography.Text>
        {data?.role?.description}
      </Typography.Text>
      {data?.role?.isAdminRole && (
        <Alert
          message={
            'This is an admin role. '
            + 'Which gives access to all permissions no matter what configured permissions are set here'
          }
        />
      )}
      {sorted.map(group => (
        <div key={new Date().getTime() * Math.random()}>
          <Table
            rowKey={rowKey}
            title={() => <Typography.Title level={4}>{group[0]!.module ?? 'Core'}</Typography.Title>}
            dataSource={group}
            pagination={false}
            size="small"
            columns={[
              {
                title: 'Permission',
                render: (record: virtualPermission) => (
                  <span>
                    {record.heading}
                    &nbsp;
                    {record.description && (
                      <Tooltip title={record.description}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    )}
                  </span>
                ),
              },
              {
                title: 'Create',
                width: '10%',
                render: (record: virtualPermission) => (
                  <Checkbox
                    checked={record.create || false}
                    disabled={isNull(record.create)}
                    onChange={onUpdate(record, PermissionLevel.CREATE)}
                  />
                ),
              },
              {
                title: 'Read',
                width: '10%',
                render: (record: virtualPermission) => (
                  <Checkbox
                    checked={record.read || false}
                    disabled={isNull(record.read)}
                    onChange={onUpdate(record, PermissionLevel.READ)}
                  />
                ),
              },
              {
                title: 'Update',
                width: '10%',
                render: (record: virtualPermission) => (
                  <Checkbox
                    checked={record.update || false}
                    disabled={isNull(record.update)}
                    onChange={onUpdate(record, PermissionLevel.UPDATE)}
                  />
                ),
              },
              {
                title: 'Delete',
                width: '10%',
                render: (record: virtualPermission) => (
                  <Checkbox
                    checked={record.delete || false}
                    disabled={isNull(record.delete)}
                    onChange={onUpdate(record, PermissionLevel.DELETE)}
                  />
                ),
              },
            ]}
          />
          <Divider />
        </div>
      ))}
    </MainView>
  );
};

const DATA_QUERY = gql`
  query RoleOverviewQuery($roleId: Int!) {
    role(id: $roleId) {
      id
      heading
      description: note
      isAdminRole
      users {
        hash
        totalCount
      }
      permissions {
        permissionEnum
        roleId
        module
        heading
        description
        create
        read
        update
        delete
      }
    }
  }
`;

const PERMISSION_UPDATE_MUTATION = gql`
  mutation UpdatePermissionMutation($roleId: Int!, $permission: PermissionEnum!, $level: PermissionLevel!, $allow: Boolean!) {
    updateRolePermission(roleId: $roleId, permission: $permission, level: $level, allow: $allow) {
      permissionEnum
      roleId
      module
      heading
      description
      create
      read
      update
      delete
    }
  }
`;

export default RoleDetails;
