import React, { useEffect, useState } from 'react';
import { Col, Row, Select, Tooltip } from 'antd';
import { useMutation } from '@apollo/client';
import { CustomFieldProps, REMOVE_MUTATION, UPSERT_MUTATION } from './CustomFieldUtils';
import Icon from '../Icon/Icon';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import {
  RemoveCustomFieldValueMutation, RemoveCustomFieldValueMutationVariables,
  UpsertCustomFieldValueMutation,
  UpsertCustomFieldValueMutationVariables
} from '../../../gql/typings';

const CustomFieldSelect: React.FC<CustomFieldProps> = ({ recordId, field, values, refreshData }) => {
  const [selected, setSelected] = useState<number>();
  const localization = useLocalization();
  const [, setEditble] = useState(false);
  const [
    upsert,
    { loading },
  ] = useMutation<UpsertCustomFieldValueMutation,
  UpsertCustomFieldValueMutationVariables>(UPSERT_MUTATION);
  const [
    removeCustomFieldValue
  ] = useMutation<RemoveCustomFieldValueMutation,
  RemoveCustomFieldValueMutationVariables>(REMOVE_MUTATION);

  useEffect(() => {
    setSelected(values.nodes[0]?.value?.id ?? undefined);
  }, [values]);

  const settings = field.customFieldControlSetting;

  const onChange = (newSelected: number) => {
    if (newSelected != null) {
      upsert({
        variables: {
          recordId,
          customFieldCode: field.code,
          customValues: [{ valueId: newSelected }],
        }
      }).then(() => setEditble(false))
        .finally(refreshData);
    } else {
      const toRemoveLinkId = values?.nodes[0]!.id;
      removeCustomFieldValue({
        variables: {
          customValues: [toRemoveLinkId]
        }
      }).then(() => setEditble(false))
        .finally(refreshData);
    }

  };

  return (
    <Row gutter={[4, 4]} className="custom-field-select-grid">
      <Col span={settings?.isSynced ? 23 : 24}>
        <Tooltip
          title={field.accessRules?.allowMutation
            ? '' : localization.formatMessage(Locale.Text.Mutation_permission_denied)}
        >
          <Select
            value={selected}
            disabled={!field.accessRules?.allowMutation}
            onChange={onChange}
            loading={loading}
            style={{ width: '100%' }}
            // @ts-ignore
            filterOption={(input, option) => option?.children.toLowerCase().trim().indexOf(input.toLowerCase().trim()) >= 0}
            allowClear
          >
            {(field.options?.nodes ?? []).map(option => (
              <Select.Option key={option.value!.id} value={option.value!.id}>
                {option.value!.value}
              </Select.Option>
            ))}
          </Select>
        </Tooltip>

      </Col>
      {settings?.isSynced
      && <Col span={1}><Icon toolTip="is synchronized" style={{ marginTop: 8, marginLeft: 8 }} iconType="SYNC" /></Col>}
    </Row>
  );
};

export default CustomFieldSelect;
