import { gql } from '@apollo/client';
import { Tooltip, Typography } from 'antd';
import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum, SelectSiteTypeSuggestionQueryQuery, SelectSiteTypeSuggestionQueryQueryVariables,
  SiteCriteriaOrder,
  UpdateSiteTypeMutationMutationVariables
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';

export const siteTypeFieldConfig = siteFieldConfig(
  'type',
  [
    'id',
    'countryCode',
    'type.label',
    'type.code',
    'type.type',
    'controlSetting.id',
    'controlSetting.entityTypeId',
    'controlSetting.maintainerSourceCode',
    'controlSetting.createDcr',
  ],
  ({ localization }) => ({
    sorting: {
      ascend: SiteCriteriaOrder.SITE_TYPE_ASC,
      descend: SiteCriteriaOrder.SITE_TYPE_DESC,
    },
    dcrInfo: site => ({
      field: FieldEnum.HCO_TYPE_CODE,
      entityType: EntityTypeEnum.SITE,
      entityAffiliationId: site.id,
    }),
    title: localization.formatMessage(Locale.Attribute.Site_type),
    additionalTableConfig: {
      width: 120
    },
    // className: 'apb-antd-table-column-width-no-wrap',
    render: (record, { isViewingFromPage }) => {
      const siteType = record.type?.label;
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={siteType}>{siteType}</Tooltip>
      </Typography.Paragraph>);
    },
    updateView: buildSelectUpdateFieldConfig({
      mode: 'single',
      allowClear: false,
      selectPlaceholder: localization.formatMessage(Locale.Command.Select_Site_Type),
      mutation: UPDATE_SITETYPE_MUTATION,
      selectedKey: record => record.type?.code,
      buildVariables: (selectedKey, record) => ({
        siteId: record.id,
        typeCode: selectedKey,
      }) as UpdateSiteTypeMutationMutationVariables,
      fetchSuggestions: ({ client, record, userInput }) => (
        client.query<SelectSiteTypeSuggestionQueryQuery, SelectSiteTypeSuggestionQueryQueryVariables>({
          query: SUGGESTIONS_QUERY,
          variables: { country: record.countryCode, search: `%${userInput}%` },
        }).then(res => res.data.siteTypes.nodes)
      ),
    }),
  })
);

const UPDATE_SITETYPE_MUTATION = gql`
  mutation UpdateSiteTypeMutation($siteId: Int!, $typeCode: String) {
    updateSiteTypeCode(siteId: $siteId, typeCode: $typeCode) {
      id
    }
  }
`;

const SUGGESTIONS_QUERY = gql`
  query SelectSiteTypeSuggestionQuery($search: String, $country: CountryCode!) {
    siteTypes(criteria: { country: $country, label: $search, fetchSize: { limit: 1000 } }) {
      hash
      nodes {
        code
        label: displayLabel
        type
      }
    }
  }
`;
