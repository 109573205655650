import React, { useState } from 'react';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import { useTableRowSelection } from '../../../components/Table/useTableRowSelection';
import useCreateListActions, { CreateListActionProps } from '../../list/CreateList/useCreateListActions';
import { TableActionItem } from '../../../components/Table/Actions';
import { EntitiesSearchQuickActionItem } from '../../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import { Locale } from '../../../../localization/LocalizationKeys';
import MassAssign from '../../massAssign/MassAssign';
import { useAddSiteToActivityAction } from './useAddSiteToActivityAction';
import {
  EntityTypeEnum
} from '../../../../gql/typings';
import { EntityTableQuickActions } from '../../person/hooks/usePersonTableQuickActions';
import { usePermission } from '../../../permission/usePermission';
import EntityMergeModal from '../../../components/EntityMerge/EntityMergeModal';

type SiteTableQuickActionOptions = {
  dynamicListSupport?: CreateListActionProps['dynamicListSupport'];
  maintainPageState?: boolean;
};

export const useSiteTableQuickActions: EntityTableQuickActions<SiteTableQuickActionOptions> = (
  form = Form.useForm()[0],
  options,
) => {
  const tableSelection = useTableRowSelection({
    form,
    ...(options?.maintainPageState && { maintainPageState: { entityType: EntityTypeEnum.SITE } })
  });
  const showMassassignState = useState(false);
  const showMergeRequestState = useState(false);
  const { formatMessage } = useIntl();
  const { create: allowCreateMergeRequest } = usePermission('MERGE_RECORD');

  const [createListAction, CreateListElement] = useCreateListActions({
    entityType: EntityTypeEnum.SITE,
    selection: tableSelection.selection,
    dynamicListSupport: options?.dynamicListSupport,
  });
  const addSiteToActivity = useAddSiteToActivityAction(tableSelection.selection.selected[0]);

  const actions: TableActionItem[] = [
    {
      key: 'mass-assign',
      label: formatMessage(Locale.Attribute.Mass_assign),
      onClick: () => showMassassignState[1](true),
      disabled: tableSelection.selection.count === 0,
    },
    {
      key: 'merge-request',
      label: formatMessage(
        tableSelection.selection.count === 1
          ? Locale.Command.Merge_record
          : Locale.Command.Merge_records
      ),
      hide: !allowCreateMergeRequest,
      disabled: tableSelection.selection.count === 0 || tableSelection.selection.count > 2,
      onClick: () => showMergeRequestState[1](true),
    },
    addSiteToActivity.action,
    createListAction,
  ];

  return {
    HiddenRender: () => (
      <>
        <MassAssign visibleState={showMassassignState} selection={tableSelection.selection} entityType={EntityTypeEnum.SITE} />
        <CreateListElement />
        {tableSelection.selection.selected[0] && <EntityMergeModal
          visibleState={showMergeRequestState}
          entityType={EntityTypeEnum.SITE}
          fromId={tableSelection.selection.selected[0]}
          intoId={tableSelection.selection.selected[1]}
        />}
        {addSiteToActivity.hidden}
      </>
    ),
    tableSelection,
    entitiesSearchProps: {
      actions: actions.filter(e => e) as EntitiesSearchQuickActionItem[],
    },
  };
};

