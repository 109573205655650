import { gql } from '@apollo/client/core';
import { Locale } from '../../../../localization/LocalizationKeys';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SitePersonCriteriaOrder, UpdateNumberOfPatientsMutationMutationVariables } from '../../../../gql/typings';
import { buildNumberUpdateFieldConfig } from '../../../components/FieldConfig/buildNumberUpdateFieldConfig';

export const linkNumberOfPatientsFieldConfig = linkFieldConfig(
  'numberOfPatients',
  [
    'id',
    'numberOfPatients',
    'person.id',
    'person.controlSetting.id',
    'person.controlSetting.entityTypeId',
    'person.controlSetting.maintainerSourceCode',
    'person.controlSetting.createDcr',
  ],
  ({ localization }) => ({
    sorting: {
      ascend: SitePersonCriteriaOrder.NUMBER_OF_PATIENTS_ASC,
      descend: SitePersonCriteriaOrder.NUMBER_OF_PATIENTS_DESC,
    },
    title: localization.formatMessage(Locale.Attribute.Number_of_patients),
    additionalTableConfig: {
      width: 80
    },
    render: (sitePers) => sitePers.numberOfPatients ?? '',
    updateView: buildNumberUpdateFieldConfig({
      mutation: UPDATE_SITE_PERSON_NUMBER_OF_PATIENTS_MUTATION,
      valuePath: 'numberOfPatients',
      buildVariables: (value, record) => ({
        linkId: record.id,
        numberOfPatients: value,
      }) as UpdateNumberOfPatientsMutationMutationVariables,
    }),
  }),
);

const UPDATE_SITE_PERSON_NUMBER_OF_PATIENTS_MUTATION = gql`
  mutation UpdateNumberOfPatientsMutation($linkId: Int!, $numberOfPatients: Int) {
    updateNumberOfPatients(id: $linkId, numberOfPatients: $numberOfPatients) {
      id
      numberOfPatients
    }
  }
`;
