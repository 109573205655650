import React from 'react';
import { Spin } from 'antd';
import MainView from '../View/MainView';

export default ({ wrapWithMain = false, spinSize = 'large' }) => {
  if (wrapWithMain) return (
    <MainView className="loading-main-container">
      <div className="loading-container">
        <Spin size="large" />
      </div>
    </MainView>
  );
  return (
    <div className="loading-container">
      <Spin size={spinSize} />
    </div>
  );
};
