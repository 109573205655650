import { createContext } from 'react';
import { StateArray } from '../../util/StateArrayType';
import {
  PersonAffiliationsTabsEnum
} from '../../browse/person/Components/PersonAffiliationsContainer';
import { SiteAffiliationsTabsEnum } from '../../browse/site/components/SiteAffiliationContainer';
import { LinkAffiliationsTabsEnum } from '../../browse/link/LinkAffiliationTabs';

export type EntityTabsContextType = {
  relationTabs: {
    person: StateArray<PersonAffiliationsTabsEnum>;
    site: StateArray<SiteAffiliationsTabsEnum>;
    link: StateArray<LinkAffiliationsTabsEnum>;
  };
};

// @ts-ignore
export default createContext<EntityTabsContextType>({});
