import React from 'react';
import { Divider, Form, FormInstance } from 'antd';
import { isArray } from 'lodash';
import { Optional, RecursivePartial } from '../../../util/StateArrayType';
import { AddWorkplaceInput, EntityTypeEnum } from '../../../../gql/typings';
import { useEntityFieldData } from '../../../util/useEntityFieldData';
import { useSystemCountriesState } from '../../../util/useSystemCountriesState';
import Loading from '../../../components/Loading/Loading';
import { createLinkFields } from './CreateLinkFields';

type AddPositionFormProps = {
  form: FormInstance<AddWorkplaceInput>;
  onFinish?: (values: AddWorkplaceInput) => void;
  initialState?: Optional<RecursivePartial<AddWorkplaceInput>>;
  countryCode: string|undefined;
};

const AddPositionForm: React.FC<AddPositionFormProps> = ({ form, initialState, countryCode, onFinish, children }) => {
  const [systemCountries] = useSystemCountriesState();
  const { fieldKeys, fieldLoading } = useEntityFieldData(systemCountries?.[0], EntityTypeEnum.AFFILIATION);

  if (fieldLoading) return <Loading />;

  return (
    <Form
      name="workplace"
      form={form}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 17 }}
      className="add-workspace"
      preserve
      // @ts-ignore
      initialValues={initialState}
      onFinish={() => {
        onFinish?.(form.getFieldsValue(true));
      }}
    >
      {fieldKeys && Object.keys(fieldKeys).map(f => {
        const key = f as keyof Omit<AddWorkplaceInput, 'siteInput'|'siteId'>;
        const Component = createLinkFields[key];
        if (isArray(fieldKeys[f])) {
          return <Component key={f} countryCode={countryCode} keyCodes={fieldKeys[f] as string[]} />;
        }
        return <Component key={f} countryCode={countryCode} />;
      })}

      {children && <Divider />}
      {children}
    </Form>
  );
};

export default AddPositionForm;
