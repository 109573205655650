import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { Button } from 'antd';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import { EntityMergeFragment_Person_Fragment, MergeItemPersonListsFragment } from '../../../../../../../../../gql/typings';
import { Optional } from '../../../../../../../../util/StateArrayType';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import AdminMergeItemListsModal from '../../shared/Lists/AdminMergeItemListsModal';


const AdminMergeItemPersonLists: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonListsFragment;
  const fromPerson = props.from as EntityMergeFragment_Person_Fragment;
  const toPerson = props.into as Optional<EntityMergeFragment_Person_Fragment>;
  const localization = useLocalization();
  const [visible, setVisible] = useState(false);


  return (
    <AdminMergeItemBasicField {...props}>
      {localization.pluralMessage(Locale.Text.Merge_request_lists_move_description, info.listIds.length, {
        name1: <strong>{fromPerson.fullName}</strong>,
        name2: <strong>{toPerson?.fullName}</strong>,

      })}
      <Button type="link" onClick={() => setVisible(true)}>[{localization.formatMessage(Locale.Command.Show)}]</Button>
      <AdminMergeItemListsModal
        visible={visible}
        close={() => setVisible(false)}
        listIds={info.listIds}
      />
    </AdminMergeItemBasicField>
  );
};

gql`
  fragment MergeItemPersonLists on PersonLists_EntityDataToBeTransferredType {
    code
    entityType
    listIds
  }
`;

export default AdminMergeItemPersonLists;
