import React from 'react';
import Flag, { FlagCountryWithUnknown } from '../Flag/Flag';
import { Optional } from '../../util/StateArrayType';

type EntityHeaderContainerProps = {
  title: Optional<React.ReactNode|string>;
  country?: FlagCountryWithUnknown;
};

const EntityHeaderContainer: React.FC<EntityHeaderContainerProps> = ({
  title,
  country,
}) => (
  <div>
    <div className="entity-header-container">
      <span className="title">{title}</span>
      <Flag rectangle country={country} />
    </div>
  </div>
);


export default EntityHeaderContainer;
