import { useApolloClient, gql } from '@apollo/client';
/* eslint-disable no-console */
import { useContext } from 'react';
import { values } from 'lodash';
import { ImportProcessingContext } from '../ImportProcessingContext';
import {
  EntityTypeEnum, ProcessLineEffectActionEnum,
  SaveImportLineProcessMutation,
  SaveImportLineProcessMutationVariables, SkipImportLineProcessMutation, SkipImportLineProcessMutationVariables
} from '../../../../../gql/typings';

const SaveImportLineProcess_MUTATION = gql`
  mutation SaveImportLineProcess($input: ImportSaveProcessInput!) {
    saveImportLineProcess(input: $input) { id }
  }

`;
const SkipImportLineProcess_MUTATION = gql`
  mutation SkipImportLineProcess($input: ImportSkipProcessInput!) {
    skipImportLineProcess(input: $input) { id }
  }
`;

/**
 * Uses the `ImportProcessingContext`, so needs to be a child of it's Provider.
 */
export const useSaveProcessResult = () => {
  const {
    lineId,
    projectId,
    templateProcessId,
    refetch,
  } = useContext(ImportProcessingContext);
  const client = useApolloClient();
  // const intl = useIntl();


  function saveProcessResult(props: {
    action: ProcessLineEffectActionEnum;
    entityType: EntityTypeEnum;
    entityAffiliationId: number;
    note?: string;
  }) {
    if (!values(ProcessLineEffectActionEnum).includes(props?.action)) {
      console.error(`Action ${props?.action} not supported`);
    }
    return client.mutate<SaveImportLineProcessMutation, SaveImportLineProcessMutationVariables>({
      mutation: SaveImportLineProcess_MUTATION,
      variables: {
        input: {
          actionCode: props?.action,
          entityType: props?.entityType,
          entityAffiliationId: props?.entityAffiliationId,
          note: props?.note,
          lineId,
          projectId,
          templateProcessId,
        },
      },
    }).then(() => refetch());
  }

  function skipProcessResult(props?: { entityType: EntityTypeEnum; entityAffiliationId?: number; note?: string }) {
    if (![...values(EntityTypeEnum), undefined].includes(props?.entityType)) {
      console.error(`EntityType ${props?.entityType} not supported`);
    }
    return client.mutate<SkipImportLineProcessMutation, SkipImportLineProcessMutationVariables>({
      mutation: SkipImportLineProcess_MUTATION,
      variables: {
        input: {
          entityType: props?.entityType,
          entityAffiliationId: props?.entityAffiliationId,
          note: props?.note,
          lineId,
          projectId,
          templateProcessId,
        },
      },
    }).then(() => refetch());
  }

  return { saveProcessResult, skipProcessResult };
};
