import React from 'react';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { usePermission } from '../../../../../permission/usePermission';
import PersonSegmentationTabField from './PersonSegmentationTabField';
import PersonProductSegmentationTabField from './PersonProductSegmentationTabField';
import { EntityTabFieldProps } from '../EntityTabFieldsTypes';
import { extractBooleanValue } from '../../../../../util/Util';
import { SegmentationsettingsQueryQuery } from '../../../../../../gql/typings';

const index: React.FC<EntityTabFieldProps> = (props) => {
  const { read: segRead, update: segUpdate } = usePermission('HCP_SEGMENTATION');
  const { read: prodRead, update: prodUpdate } = usePermission('HCP_PROD_SEGMENTATION');
  const { data } = useQuery<SegmentationsettingsQueryQuery>(DATA_QUERY);

  return (
    <>
      {segRead && extractBooleanValue(data?.person?.value) && (
        <PersonSegmentationTabField
          personId={props.recordId}
          updateAccess={segUpdate}
        />
      )}
      {prodRead && extractBooleanValue(data?.product?.value) && (
        <PersonProductSegmentationTabField
          updateAccess={prodUpdate}
          personId={props.recordId}
        />
      )}
    </>
  );
};

const DATA_QUERY = gql`
  query SegmentationsettingsQuery {
    product: instanceSetting(type: PRODUCT_SEGMENTATION) { code, value }
    person: instanceSetting(type: PERSON_SEGMENTATION) { code, value }
  }
`;

export default index;
