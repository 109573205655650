import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';

export const sitePureAdvanceIdFieldConfig = siteFieldConfig(
  'id',
  ['id'],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.PureAdvance_ID),
    additionalTableConfig: {
      width: 120
    },
    render: (record) => record.id,
  })
);

