import React from 'react';
import { gql } from '@apollo/client';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import {
  EntityTypeEnum, MergeItemAffiliationContactValueFragment,
} from '../../../../../../../../../gql/typings';
import AdminMergeItemContactValue, {
  AdminMergeItemContactValue_FRAGMENT,
} from '../../shared/ContactValue/AdminMergeItemContactValue';

const AdminMergeItemAffiliationContactValue: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemAffiliationContactValueFragment;
  return (
    <AdminMergeItemContactValue
      {...props}
      entityType={EntityTypeEnum.AFFILIATION}
      entityAffiliationWinnerId={props.into.id}
      value1={info.loserValue}
      value2={info.winnerValue}
    />
  );
};

gql`
  fragment MergeItemAffiliationContactValue on AffiliationContactValue_EntityDataToBeTransferredType {
    code
    loserValue { ...ContactValueInfo }
    winnerValue { ...ContactValueInfo }
  }

  ${AdminMergeItemContactValue_FRAGMENT}`;

export default AdminMergeItemAffiliationContactValue;
