import React from 'react';
import { useIntl } from 'react-intl';
import { Space } from 'antd';
import { useNavigate } from 'react-router';
import CalendarDateDisplay from './CalendarDateDisplay';
import Icon from '../Icon/Icon';
import DisplayPersons from '../Badge/DisplayPersons';
import DisplaySites from '../Badge/DisplaySites';
import ColoredShadowIcon from '../StatusIcon/ColoredShadowIcon';
import { activityConnectionOverviewFragment } from '../../../gql/typings';

type ActivityOverViewCardProps = {
  title: string;
  id: number;
  type: NonNullable<activityConnectionOverviewFragment['nodes']>['0']['type'];
  time: Date;
  end: Date;
  persons: NonNullable<activityConnectionOverviewFragment['nodes']>['0']['persons'];
  sites?: NonNullable<activityConnectionOverviewFragment['nodes']>['0']['sites'];
  status: NonNullable<activityConnectionOverviewFragment['nodes']>['0']['status'];
};

const ActivityOverViewCard: React.FC<ActivityOverViewCardProps> = (props) => {
  const intl = useIntl();
  const personLength = props.persons.nodes ? props.persons.nodes.length : 0;
  const siteLength = props.sites?.nodes ? props.sites?.nodes.length : 0;
  const navigate = useNavigate();

  const gotoActivity = () => {
    navigate(`/activity/${props.id}`);
  };
  return (
    <div className="activity-overview-card-container" onClick={gotoActivity}>

      <div
        className={`card-body ${props.type.color.code}`}
        style={{ borderLeftColor: `${props.type.color.value}`, borderLeftStyle: 'solid' }}
      >
        <div className="card-title">
          {props.title}
        </div>
        <div className="card-date">
          <Icon iconType="CLOCK" text={<CalendarDateDisplay startTime={props.time} endTime={props.end} />} />
        </div>
        <Space style={{ rowGap: '1px', columnGap: '4px', marginTop: '4px' }} wrap>
          {personLength > 0 && <div className="card-persons">
            <Icon
              iconType="PERSON"
              style={{ height: '14px' }}
              text={<DisplayPersons nodes={props.persons.nodes ?? []} totalCount={props.persons.totalCount} />}
            />
          </div>}
          {siteLength > 0 && <div className="card-sites">
            <Icon
              iconType="SITE"
              style={{ height: '14px' }}
              text={<DisplaySites nodes={props.sites?.nodes ?? []} />}
            />
          </div>}
          <div className="card-status">
            <Space>
              <div>
                <Space>
                  <div className="type-icon">
                    <ColoredShadowIcon style={{ height: '13px' }} hex={props.type.color.value} /></div>
                  {intl.formatMessage({ id: props.type.label, defaultMessage: props.type.label })}</Space>
              </div>
              <Icon
                iconType={props.status.codeEnum}
                style={{ height: '13px' }}
                text={intl.formatMessage({ id: props.status.headingKey })}
              />

            </Space>
          </div>
        </Space>


      </div>

    </div>
  );
};


export default ActivityOverViewCard;
