import React from 'react';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import { useLocalization } from '../../../util/useLocalization';
import { EntityTabCardProps } from '../EntityTabTypes';
import PersonConsentTabField from './fieldTypes/PersonConsentTabField';
import PersonSegmentationTabField from './fieldTypes/PersonSegmentationTabField';
import PersonTagsTabField from './fieldTypes/PersonTagsTabField';
import PersonResponsibleAreasTabField from './fieldTypes/PersonResponsibleAreasTabField';
import PersonExternalIdsTabField from './fieldTypes/PersonExternalIdsTabField';
import EntityTabCard from '../EntityTabCard/EntityTabCard';
import CustomFieldTabField from './fieldTypes/CustomField';
import { EntityTabFieldProps } from './fieldTypes/EntityTabFieldsTypes';
import SiteExternalIdsTabField from './fieldTypes/SiteExternalIdsTabField';
import SiteTagsTabField from './fieldTypes/SiteTagsTabField';
import SiteSegmentationTabField from './fieldTypes/SiteSegmentationTabField';
import { EntityCardFieldsQueryQuery, EntityCardFieldsQueryQueryVariables } from '../../../../gql/typings';

const types: Record<string, React.FC<EntityTabFieldProps>> = {
  HCP_CONSENT: PersonConsentTabField,
  HCP_SEGMENTATION: PersonSegmentationTabField,
  HCO_SEGMENTATION: SiteSegmentationTabField,
  HCP_TAGS: PersonTagsTabField,
  HCO_TAGS: SiteTagsTabField,
  HCP_RES_AREAS: PersonResponsibleAreasTabField,
  HCP_EXTERNAL_IDS: PersonExternalIdsTabField,
  HCO_EXTERNAL_IDS: SiteExternalIdsTabField,
  HCP_CUSTOM_FIELD: CustomFieldTabField,
  HCO_CUSTOM_FIELD: CustomFieldTabField,
  LINK_CUSTOM_FIELD: CustomFieldTabField,
};

const EntityTabFieldsCard: React.FC<EntityTabCardProps> = (props) => {
  const localization = useLocalization();
  const { data, loading } = useQuery<EntityCardFieldsQueryQuery, EntityCardFieldsQueryQueryVariables>(DATA_QUERY, {
    variables: { configurationId: props.configuration.id },
  });

  return (
    <EntityTabCard
      title={props.configuration.heading ? localization.formatMessageByStr(props.configuration.heading) : undefined}
      loading={loading}
      className="entity-tab-fields-card-container"
    >
      {data?.entityTabGridConfigurationFields.nodes.map(field => {
        const ToDisplay = types[field.typeCode] ?? (() => (
          <Col span={24}>
            <span>No implementation for field type '{field.typeCode}' was found</span>
          </Col>
        ));
        return (
          <Row key={field.id} style={{ marginBottom: 24 }}>
            <ToDisplay {...props} field={field} />
          </Row>
        );
      })}
    </EntityTabCard>
  );
};

const DATA_QUERY = gql`
  query EntityCardFieldsQuery($configurationId: ID!) {
    entityTabGridConfigurationFields(criteria: { configurationId: $configurationId }) {
      hash
      nodes {
        id
        typeCode: gridItemFieldTypeCode
      }
    }
  }
`;

export default EntityTabFieldsCard;
