import { gql } from '@apollo/client';
import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { isFunction } from 'lodash';
import LinkBadge from '../../../components/Badge/LinkBandge';
import transformers from '../../../components/DisplayFields/transformers';
import { spans, spansDefaultIndex } from './ActivityTableSpans';
import ListValueColumn from '../../../components/Table/ListValueColumn';
import Icon from '../../../components/Icon/Icon';
import { Locale } from '../../../../localization/LocalizationKeys';
import { TableConfig, TableFieldsConfig } from '../../search_old/types';
import { ActivityCriteriaOrder } from '../../../../gql/typings';


export const buildActivityTableFragment = (additionalQuery: string|null) => gql`
  fragment ActivityTableBuiltFragment on Activity {
    id
    ${additionalQuery || ''}
  }
`;

export const buildActivityTableDataConnectionQuery = (additionalQuery: string|null) => gql`
  query ActivitiesTableConnectionQueryBuilt($criteria: ActivityCriteria) {
    connection: activities(criteria: $criteria) {
      hash
      totalCount
      nodes { 
        id
        heading
        startDate
        endDate
        responsibleUsers {
          hash
          nodes {
            id
            fullname
          }
        }
        persons {
          hash
          totalCount
          nodes {
            id
            fullName
          }
        }
        sites {
          hash
          totalCount
          nodes {
            id
            name
          }
        }
        ...ActivityTableBuiltFragment
      }
    }
  }
  ${buildActivityTableFragment(additionalQuery)}
`;

export type SupportedActivityTableFields = 'status'|'type'|'startDate'|'heading'|'resUsers'|'hcpAmount'|'location';

export const supportedActivityTableFields: TableFieldsConfig<SupportedActivityTableFields>['fields'] = {
  status: props => ({
    key: 'status',
    queryFields: [
      'status.code',
      'status.headingKey',
    ],
    sorting: {
      ascend: ActivityCriteriaOrder.STATUS_ASC,
      descend: ActivityCriteriaOrder.STATUS_DESC,
    },
    title: props.localization.formatMessage((Locale.General.Status)),
    render: row => {
      const span = props.span ?? spans[spansDefaultIndex];
      if (row.children && !isFunction(row.children) && row.children.length > 0) {
        const dateValue = moment(row.children[0].startDate);
        const displayValue = isFunction(span.format)
          ? span.format(dateValue)
          : dateValue.format(span.format);
        return <span style={{ fontSize: 18, fontWeight: 'bold' }}>{displayValue} ({row.children.length})</span>;
      }
      return props.localization.formatMessage({
        id: row.status.headingKey,
        defaultMessage: row.status.headingKey,
      });
    },
  }),
  type: props => ({
    key: 'type',
    queryFields: [
      'type.code',
      'type.heading',
    ],
    sorting: {
      ascend: ActivityCriteriaOrder.TYPE_ASC,
      descend: ActivityCriteriaOrder.TYPE_DESC,
    },
    title: props.localization.formatMessage(Locale.Attribute.Type),
    render: (record) => record?.type?.heading,
  }),
  startDate: props => ({
    key: 'startDate',
    queryFields: ['startDate'],
    sorting: {
      ascend: ActivityCriteriaOrder.START_DATE_ASC,
      descend: ActivityCriteriaOrder.START_DATE_DESC,
    },
    title: props.localization.formatMessage(Locale.Attribute.Date),
    render: (record) => (record.children
      ? ''
      : transformers.time(record.startDate)
    ), // renderContent(v => transformers.time(v)),
  }),
  heading: props => ({
    key: 'heading',
    queryFields: ['id', 'startDate', 'heading'],
    sorting: {
      ascend: ActivityCriteriaOrder.HEADING_ASC,
      descend: ActivityCriteriaOrder.HEADING_DESC,
    },
    title: props.localization.formatMessage(Locale.Attribute.Heading),
    onCell: (record) => {
      if (moment(record.startDate) > moment()) {
        return { className: 'hasFutureDate' };
      }
      return { className: 'date' };
    },
    render: (record) => (
      moment(record.startDate) > moment()
        ? (
          <LinkBadge
            url={`/activity/${record.id}`}
            label={record.heading}
            openInNewTab={props.openInNewTab}
          />
        ) : <NavLink to={`/activity/${record.id}`}>{record.heading}</NavLink>
    )
  }),
  resUsers: props => ({
    key: 'resUsers',
    queryFields: [
      'responsibleUsers.hash',
      'responsibleUsers.nodes.id',
      'responsibleUsers.nodes.fullname',
    ],
    sorting: {
      ascend: ActivityCriteriaOrder.RESPONSIBLE_USER_ASC,
      descend: ActivityCriteriaOrder.RESPONSIBLE_USER_DESC,
    },
    title: props.localization.formatMessage(Locale.Attribute.Responsible_users),
    dataIndex: ['responsibleUsers', 'nodes'],
    render: (record) => (
      (record?.responsibleUsers?.nodes || '').length > 0
        ? (
          <ListValueColumn
            max={2}
            disableLink
            items={record.responsibleUsers.nodes.map((user: { id: number; fullname: string }) => ({
              id: user.id,
              label: user.fullname,
              url: `/dashboard/${user.id}`,
            }))}
          />
        ) : ''
    ),
  }),
  hcpAmount: props => ({
    key: 'hcpAmount',
    queryFields: [
      'persons.hash',
      'persons.totalCount',
    ],
    sorting: {
      ascend: ActivityCriteriaOrder.HCP_COUNT_ASC,
      descend: ActivityCriteriaOrder.HCP_COUNT_DESC,
    },
    title: props.localization.formatMessage(Locale.Attribute.HCPs),
    render: (record) => (record.children
      ? ''
      : <span style={{ display: 'flex' }}>
        {record.persons.totalCount}x&nbsp;
        <Icon iconType="PERSON" />
      </span>
    ),
  }),
  location: props => ({
    key: 'location',
    queryFields: [
      'sites.hash',
      'sites.totalCount',
      'sites.nodes.id',
      'sites.nodes.name',
    ],
    sorting: {
      ascend: ActivityCriteriaOrder.LOCATION_NAME_ASC,
      descend: ActivityCriteriaOrder.LOCATION_NAME_DESC,
    },
    title: props.localization.formatMessage(Locale.Attribute.Location),
    render: (record) => (record.children || record.sites.nodes.length === 0
      ? ''
      : <NavLink to={`/hco/${record.sites.nodes[0].id}`}>{record.sites.nodes[0].name}</NavLink>
    ),
  }),
};

export const ActivityTableConfig: TableConfig = {
  buildConnectionQuery: buildActivityTableDataConnectionQuery,
  buildRecordQuery: buildActivityTableDataConnectionQuery,
  buildFragment: buildActivityTableFragment,
  titleLabel: Locale.General.Activities,
  columnConfig: {
    fields: supportedActivityTableFields,
  },
};

