import React from 'react';
import { Button, Form, notification, Steps } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { massAssignFieldTypes } from './MassAssignFieldTypes';
import { StateArray } from '../../util/StateArrayType';
import MassAssignPreview from './MassAssignPreview';

import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import Puffin from '../../components/Puffin/Puffin';
import {
  AvailableMassAssignFieldsQueryQuery,
  EntityTypeEnum,
  MassAssignFieldAction,
  SelectionCriteriaInput, SubmitMassAssignSingleSelectMutationMutation, SubmitMassAssignSingleSelectMutationMutationVariables
} from '../../../gql/typings';


export type CreateMassAssignData = {
  actionType: string;
  singleSelectValue: string;
  actionName: string;
};


type MassAssignProcessProps = {
  fieldState: StateArray<NonNullable<AvailableMassAssignFieldsQueryQuery['availableMassAssignFields']>['0']|null>;
  entityType: EntityTypeEnum;
  selectionCriteria: SelectionCriteriaInput;
  visibleState: StateArray<boolean>;
};

const MassAssignProcess: React.FC<MassAssignProcessProps> = ({
  entityType,
  fieldState: [selectedField, setSelectedField],
  selectionCriteria,
  visibleState,
}) => {
  const localization = useLocalization();
  const [form] = Form.useForm<CreateMassAssignData>();
  const [current, setCurrent] = React.useState(0);

  const [actionSelected, setActionSelected] = React.useState<MassAssignFieldAction>(MassAssignFieldAction.SetValueToSelected);
  const [valueSelected, setValueSelected] = React.useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  if (!selectedField?.accessRules?.allowMutation) {
    api.open({
      message: <Puffin
        type="explaining"
        loop
        message={localization.formatMessage(Locale.Text.Field_Permission_Access_Denied)}
      />,
    });
  }
  const [
    create,
  ] = useMutation<SubmitMassAssignSingleSelectMutationMutation, SubmitMassAssignSingleSelectMutationMutationVariables>(
    SUBMIT_SINGLE_SELECT_MASS_ASSIGN
  );
  const next = () => {
    setCurrent(current + 1);
  };

  if (selectedField === null) {
    return <></>;
  }

  const prev = () => {
    setCurrent(current - 1);
  };

  const FieldToDisplay = massAssignFieldTypes[selectedField.fieldType]!;

  const steps = [
    {
      title: 'Assign Values',
      content: (
        <FieldToDisplay
          form={form}
          heading={selectedField.heading}
          isHeadingKey={selectedField.isHeadingKey}
          entityType={entityType}
          actionName={selectedField.actionName}
          actionSelected={[actionSelected, setActionSelected]}
          valueSelected={[valueSelected, setValueSelected]}
        />
      ),
    },
    {
      title: 'Preview',
      content: <MassAssignPreview form={form} selectionCriteria={selectionCriteria} />,
    },
  ];

  const onFinish = () => {
    const {
      actionType,
      singleSelectValue,
      actionName,
      entityType,
    } = form.getFieldsValue(true);
    return create({
      variables: {
        actionName,
        selectionCriteria,
        input: {
          action: actionType,
          entityType,
          values: singleSelectValue !== undefined ? singleSelectValue.toString() : ''
        },
      },
      refetchQueries: ['PersonsBuiltQuery', 'DefaultCriteriasQuery', 'HCPMoreData', 'HCOMoreData'],

    }).then(() => api.open({
      message: 'Mass Assign has successfully been updated',
      description: 'Your actions have been added to the selected records.'
    }))
      .finally(() => visibleState[1](false));
  };

  return (
    <Form form={form} preserve onFinish={onFinish}>
      {contextHolder}
      <Steps
        className="mass-assign-steps-container"
        current={current}
        size="small"
        items={steps.map(item => (
          {
            title: item.title
          }
        ))}
      />
      <div className="steps-content">{steps[current]?.content}</div>

      <div className="steps-action">
        {current < steps.length - 1 && (
          <Button
            disabled={!valueSelected
            && actionSelected
            !== MassAssignFieldAction.BlankOutField && !selectedField.accessRules?.allowMutation}
            type="primary"
            onClick={() => next()}
          >
            {localization.formatMessage(Locale.Command.Next)}
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button htmlType="submit" type="primary">
            {localization.formatMessage(Locale.Command.Submit)}
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            {localization.formatMessage(Locale.Command.Back)}
          </Button>
        )}
        {current == 0 && (
          <Button onClick={() => setSelectedField(null)} style={{ margin: '0 8px' }}>
            {localization.formatMessage(Locale.Command.Back)}
          </Button>
        )}
      </div>
    </Form>

  );
};

const SUBMIT_SINGLE_SELECT_MASS_ASSIGN = gql`
  mutation SubmitMassAssignSingleSelectMutation($actionName: String!,
    $selectionCriteria: SelectionCriteriaInput!,
    $input: MassAssignFieldInput!
  ) {
    executeMassAssign(actionName: $actionName, selection: $selectionCriteria, input: $input)
  }
`;


export default MassAssignProcess;
