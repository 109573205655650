import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';

export const siteOriginSourceFieldConfig = siteFieldConfig(
  'originSource',
  [
    'originSource.code',
    'originSource.heading',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Origin_Source),
    additionalTableConfig: {
      width: 120
    },
    render: (record) => record.originSource.heading,
  })
);

