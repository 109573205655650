import React from 'react';
import { Card, Skeleton, Table } from 'antd';
import * as JsDiff from 'diff';
import { ColumnsType } from 'antd/lib/table/interface';
import { Locale } from '../../../../localization/LocalizationKeys';
import RenderDCREntityLabel from '../RenderDCREntityLabel';
import { useLocalization } from '../../../util/useLocalization';
import { DcrGroupDetailQuery, DcrResponseTypeEnum } from '../../../../gql/typings';


const split = (value: string) => {
  if (!value) return { label: '' };
  const parts = value.split('||');
  return {
    label: parts[0]!,
    id: parts[1]!,
  };
};

const renderValue = (old: undefined | string | null, oldMap: undefined | string | null) => oldMap || old;


const DcrTableView: React.FC<{
  loading: boolean;
  responseType: DcrResponseTypeEnum | undefined;
  dcrList: NonNullable<DcrGroupDetailQuery['dcrGroup']>['dcrs']['nodes'];
}> = ({
  dcrList,
  loading,
  responseType
}) => {
  const localization = useLocalization();
  const columns: ColumnsType<NonNullable<DcrGroupDetailQuery['dcrGroup']>['dcrs']['nodes']['0']['lines']['nodes']['0']> = [
    {
      title: localization.formatMessage(Locale.General.Field),
      dataIndex: 'fieldCode',
      render: (_, record) => (
        <span data-field-code={record.fieldCode}>
          {record.fieldLabelKey
            ? localization.formatMessageByStr(record.fieldLabelKey)
            : record.fieldCode}
        </span>
      ),
    },
    {
      title: localization.formatMessage(Locale.General.New_value),
      dataIndex: 'dcrNewValue',
      render: (_, record) => <span>{record.newValueMapped ? record.newValueMapped : record.newValue}</span>
    },
    {
      title: localization.formatMessage(Locale.General.Old_value),
      dataIndex: 'dcrOldValue',
      render: (_, record) => <span>{record.oldValueMapped ? record.oldValueMapped : record.oldValue}</span>
    },
    {
      title: localization.formatMessage(Locale.Attribute.Difference),
      render: (_, record) => JsDiff
        .diffChars(
          split(renderValue(record.dcrOldValue, record.oldValueMapped) || '').label,
          split(renderValue(record.dcrNewValue, record.newValueMapped) || '').label,
        )
        .map((part, i) => {
          const color = part.added ? 'green' : part.removed ? 'red' : 'grey';
          return <span key={i} style={{ color }}>{part.value}</span>;
        }),
    },
  ];

  return (
    <Card>
      <Skeleton loading={loading}>
        {dcrList.map(it => (
          <div className="dcr-group-dcr-item" data-dcr-id={it.id} data-entity-type={it.entityTypeEnum} key={it.id}>
            <RenderDCREntityLabel
              responseType={responseType}
              requestType={it.requestType}
              affiliation={it.affiliationEntity}
            />
            <br />

            <Table
              size="small"
              dataSource={it.lines.nodes}
              pagination={false}
              columns={columns}
              rowKey={r => r.id}
            />
          </div>
        ))}
      </Skeleton>
    </Card>
  );
};


export default DcrTableView;
