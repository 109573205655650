import React from 'react';
import { Button, Col, Row, Steps } from 'antd';
import { useIntl } from 'react-intl';
import ReportCard from './ReportCard';
import ReportInput from './ReportInput';
import ReportOverview from './ReportOverview';
import { Locale } from '../../../../localization/LocalizationKeys';
import { ReportControllerType } from '../controller/reportController';

/**
 *
 * @param {ReportController} controller
 */
const GenerateReport: React.FC<{ controller: ReportControllerType }> = ({
  controller,
}) => {
  const { formatMessage } = useIntl();
  const steps = [
    {
      title: formatMessage(Locale.Command.Select_template),
      content: <ReportCard controller={controller} />,
    },
    {
      title: formatMessage(Locale.Command.Add_input),
      content: <ReportInput controller={controller} />,
    },
    {
      title: formatMessage(Locale.Command.Generate_Report),
      content: <ReportOverview controller={controller} />,
    },
  ];

  const onChangeStep = (newStep: number) => {
    if (newStep < controller.currentStep) controller.setStep(newStep);
  };

  return (
    <>
      <Steps
        current={controller.currentStep}
        onChange={onChangeStep}
        items={steps.map((item, index) => ({
          key: item.title,
          title: item.title,
          disabled: controller.currentStep < index
        }))}
      />
      <div>{steps[controller.currentStep]!.content}</div>
      <div className="button-container">
        {/* @ts-ignore */}
        <Row type="flex" justify="center">
          {/* @ts-ignore */}
          <Col align="middle">

            <Button
              className="last-step-button"
              disabled={!controller.allowPreviousStep()}
              onClick={() => controller.prevStep()}
            >
              {formatMessage(Locale.Command.Back)}
            </Button>
            <Button
              hidden={controller.isLastStep()}
              disabled={!controller.allowPreviousStep()}
              type="primary"
              onClick={() => controller.mergeTheShit()}
              loading={controller.isGeneratingReport}
            >
              {formatMessage(Locale.Command.Generate_Report)}
            </Button>


            <Button
              hidden={!controller.isLastStep()}
              disabled={!controller.allowNextStep()}
              type="primary"
              onClick={() => controller.nextStep()}
            >
              {formatMessage(Locale.Command.Next)}
            </Button>


          </Col>
        </Row>
      </div>

    </>
  );
};

export default GenerateReport;
