import React from 'react';
import { Button, FormInstance, Popconfirm, Space } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import AddPositionForm from '../../../sitePerson/create/AddPositionForm';
import { AddPersonsToSiteModalQueryQuery, AddWorkplaceInput } from '../../../../../gql/typings';
import { StateArray } from '../../../../util/StateArrayType';

type AddPersonsToSiteStep2Props = {
  form: FormInstance<AddWorkplaceInput>;
  countryCode: string|undefined;
  tabState: StateArray<'existing-person'|'create-person'>;
  onCreate: () => void;
  onCancel: () => void;
  loading: boolean;
};

const AddPersonsToSiteStep2: React.FC<AddPersonsToSiteStep2Props> = ({
  form,
  countryCode,
  tabState: [currentTab],
  onCreate,
  onCancel,
  loading,
}) => {
  const localization = useLocalization();
  const { data } = useQuery<AddPersonsToSiteModalQueryQuery>(DATA_QUERY);

  const willCreateDcr = currentTab === 'create-person'
    ? data?.personSetting?.createDcr
    : data?.affiliationSetting?.createDcr;

  const createBtn = (
    <Button
      type="primary"
      htmlType="submit"
      onClick={willCreateDcr ? undefined : onCreate}
      loading={loading}
    >
      {localization.formatMessage(Locale.Command.Add_Person)}
    </Button>
  );

  return (
    <AddPositionForm form={form} countryCode={countryCode}>
      <Space style={{ width: '100%', justifyContent: 'center' }}>
        <Button onClick={onCancel}>
          {localization.formatMessage(Locale.Command.Cancel)}
        </Button>
        {willCreateDcr ? <Popconfirm
          title={localization.formatMessage(Locale.Text.Create_dcr_verification)}
          onConfirm={onCreate}
        >{createBtn}</Popconfirm> : createBtn}
      </Space>
    </AddPositionForm>
  );
};

const DATA_QUERY = gql`
  query AddPersonsToSiteModalQuery {
    affiliationSetting: entityControlSetting(entityType: AFFILIATION) {
      id
      createDcr
    }
    personSetting: entityControlSetting(entityType: PERSON) {
      id
      createDcr
    }
  }
`;

export default AddPersonsToSiteStep2;
