import React from 'react';
import UrlTabs from '../adminComponents/UrlTabs/UrlTabs';
import ActivityAdminSettings from './ActivityAdminSettings/ActivityAdminSettings';
import PersonAdminSettings from './PersonAdminSettings/PersonAdminSettings';
import SiteAdminSettings from './SiteAdminSettings/SiteAdminSettings';
import LinkAdminSettings from './LinkAdminSettings/LinkAdminSettings';
import DcrAdminSettings from './DcrAdminSettings/DcrAdminSettings';
import ListAdminSettings from './ListAdminSettings/ListAdminSettings';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import AddressAdminSettings from './AddressAdminSettings/AddressAdminSettings';

const EntitySettingRoutes: React.FC = () => {
  const localization = useLocalization();
  return (
    <UrlTabs
      path="entity"
      items={[
        {
          path: 'hcp',
          title: localization.formatMessage(Locale.Attribute.Person_HCP),
          render: PersonAdminSettings,
        },
        {
          path: 'hco',
          title: localization.formatMessage(Locale.Attribute.Site_HCO),
          render: SiteAdminSettings,
        },
        {
          path: 'link',
          title: localization.formatMessage(Locale.General.Site_person),
          render: LinkAdminSettings,
        },
        {
          path: 'activity',
          title: localization.formatMessage(Locale.General.Activity),
          render: ActivityAdminSettings,
        },
        {
          path: 'dcr',
          title: localization.formatMessage(Locale.General.DCR),
          render: DcrAdminSettings,
        },
        {
          path: 'list',
          title: localization.formatMessage(Locale.General.List),
          render: ListAdminSettings,
        },
        {
          path: 'address',
          title: localization.formatMessage(Locale.Attribute.Address),
          render: AddressAdminSettings,
        },
      ]}
    />
  );
};

export default EntitySettingRoutes;
