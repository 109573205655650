import { useQuery, gql } from '@apollo/client';
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Select, Button, Space } from 'antd';
import { useIntl } from 'react-intl';
import { Locale } from '../../../../localization/LocalizationKeys';
import { getRestClient } from '../../../util/rest';
import Icon from '../../../components/Icon/Icon';

const onFilter = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
const formatToOption = f => <Select.Option key={f.id} value={f.id}>{f.heading}</Select.Option>;

const FormatSelect = ({
  listId,
}) => {
  const { data, loading } = useQuery(FormatSelect.DATA_QUERY, { variables: { listId } });

  const [value, setValue] = useState(null);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!value && !loading && data.list.availableFormats && data.list.availableFormats.nodes.length > 0) {
      setValue(data.list.availableFormats.nodes[0].id);
    }
  }, [loading]);

  const onDownload = (formatId) => {
    setIsDownloadingFile(true);
    const variables = {
      request: { listId, formatId },
    };
    getRestClient().post('/download', variables.request)
      .then(res => {
        if (res.data && res.data.url) {
          window.location.href = res.data.url;
        } else Promise.reject(Error(formatMessage(Locale.Text.Download_failed)));
      })
      .finally(() => setIsDownloadingFile(false));
  };

  return (
    <div className="import-lists">

      <Button size="middle" type="primary" loading={loading || isDownloadingFile} onClick={() => onDownload(value)}>
        <Space>
          <Icon iconType="EXPORT" style={{ fontSize: 18 }} />
          {formatMessage(Locale.Command.Export)}
        </Space>
      </Button>
      <Select
        showSearch
        onChange={setValue}
        value={value}
        placeholder={formatMessage(Locale.Command.Select_list_format)}
        style={{ width: 175 }}
        filterOption={onFilter}
      >
        {loading ? null : data.list.availableFormats.nodes.map(formatToOption)}
      </Select>
    </div>
  );
};

FormatSelect.DATA_QUERY = gql`
  query AvailableListFormats($listId: Int!) {
    list(id: $listId) {
      id
      affiliations {
        ... on ActivityConnection {
          hash
          totalCount
        }
        ... on SiteConnection {
          hash
          totalCount
        }
        ... on PersonConnection {
          hash
          totalCount
        }
      }
      availableFormats {
        hash
        nodes {
          id
          heading
        }
      }
    }
  }
`;

FormatSelect.propTypes = {
  listId: PropTypes.number.isRequired,
};

export default FormatSelect;
