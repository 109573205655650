import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Checkbox, Col, Row } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CustomFieldProps, UPSERT_MUTATION } from './CustomFieldUtils';
import Icon from '../Icon/Icon';
import { isActualNumber } from '../../util/Util';
import CustomFieldCheckableTag from './CustomFieldCheckableTag';
import {
  UpdateSelectCustomFieldValueForRecord, UpsertCustomFieldValueMutation, UpsertCustomFieldValueMutationVariables
} from '../../../gql/typings';


const CustomFieldCheck: React.FC<CustomFieldProps> = ({ recordId, field, values, refreshData }) => {
  const [selected, setSelected] = useState<number[]>([]);
  const [
    update,
  ] = useMutation<UpsertCustomFieldValueMutation,
  UpsertCustomFieldValueMutationVariables>(UPSERT_MUTATION);

  useEffect(() => {
    setSelected(values.nodes.map(it => it.value!.id));
  }, [values]);

  const settings = field.customFieldControlSetting;

  const onChange = (newSelected: CheckboxValueType[]) => {
    const selectedValues: UpdateSelectCustomFieldValueForRecord[] = newSelected.map(ns => {
      if (isActualNumber(ns)) return { valueId: ns };
      return { newValue: ns as string };
    });

    update({
      variables: {
        customFieldCode: field.code,
        recordId,
        customValues: selectedValues,
      }
    }).finally(refreshData);
  };


  return (
    <Row className="custom-field-select-grid">
      <Col span={settings?.isSynced ? 23 : 24}>
        {!field.isCheckableTag
          ? (
            <Checkbox.Group
              disabled={!field.accessRules?.allowMutation}
              style={{ width: '100%' }}
              value={selected}
              onChange={onChange}
            >
              <Row>
                {(field.options?.nodes ?? []).map(option => (
                  <Col key={option.value!.id} span={24}>
                    <Checkbox key={option.value!.id} value={option.value!.id}>
                      {option.value!.value}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          ) : <CustomFieldCheckableTag recordId={recordId} field={field} values={values} refreshData={refreshData} />}


      </Col>
      {settings?.isSynced && <Col span={1}>
        <Icon
          toolTip="is synchronized"
          style={{ marginTop: 8, marginLeft: 8 }}
          iconType="SYNC"
        />
      </Col>}
    </Row>
  );
};

export default CustomFieldCheck;
