import { TypePolicy } from '@apollo/client/cache/inmemory/policies';
import { EntityDataToBeTransferredType } from '../../../../../../gql/typings';


// MARK: These are just for caching to work properly
export const AdminEntityMergeRequestCacheTypes: Record<EntityDataToBeTransferredType['__typename'], TypePolicy> = {
  AffiliationTherapeuticFocus_EntityDataToBeTransferredType: { keyFields: false },
  AffiliationWorkingSpecialities_EntityDataToBeTransferredType: { keyFields: false },
  PersonTherapeuticFocus_EntityDataToBeTransferredType: { keyFields: false },
  AffiliationContactValue_EntityDataToBeTransferredType: { keyFields: false },
  AffiliationCountryCode_EntityDataToBeTransferredType: { keyFields: false },
  AffiliationCustomField_EntityDataToBeTransferredType: { keyFields: false },
  AffiliationExternalId_EntityDataToBeTransferredType: { keyFields: false },
  AffiliationIsPrimary_EntityDataToBeTransferredType: { keyFields: false },
  AffiliationNotes_EntityDataToBeTransferredType: { keyFields: false },
  AffiliationPositionCode_EntityDataToBeTransferredType: { keyFields: false },
  PersonActivity_EntityDataToBeTransferredType: { keyFields: false },
  PersonAddress_EntityDataToBeTransferredType: { keyFields: false },
  PersonBirth_EntityDataToBeTransferredType: { keyFields: false },
  PersonConsent_EntityDataToBeTransferredType: { keyFields: false },
  PersonContactValue_EntityDataToBeTransferredType: { keyFields: false },
  PersonCountry_EntityDataToBeTransferredType: { keyFields: false },
  PersonCustomField_EntityDataToBeTransferredType: { keyFields: false },
  PersonEducations_EntityDataToBeTransferredType: { keyFields: false },
  PersonExamYear_EntityDataToBeTransferredType: { keyFields: false },
  PersonExternalId_EntityDataToBeTransferredType: { keyFields: false },
  PersonFirstName_EntityDataToBeTransferredType: { keyFields: false },
  PersonGdpr_EntityDataToBeTransferredType: { keyFields: false },
  PersonGender_EntityDataToBeTransferredType: { keyFields: false },
  PersonIsActive_EntityDataToBeTransferredType: { keyFields: false },
  PersonLastName_EntityDataToBeTransferredType: { keyFields: false },
  PersonLink_EntityDataToBeTransferredType: { keyFields: false },
  PersonLists_EntityDataToBeTransferredType: { keyFields: false },
  PersonMailToPrivate_EntityDataToBeTransferredType: { keyFields: false },
  PersonNotes_EntityDataToBeTransferredType: { keyFields: false },
  PersonProductSegmentation_EntityDataToBeTransferredType: { keyFields: false },
  PersonProjects_EntityDataToBeTransferredType: { keyFields: false },
  PersonResponsibleArea_EntityDataToBeTransferredType: { keyFields: false },
  PersonSegmentation_EntityDataToBeTransferredType: { keyFields: false },
  PersonTags_EntityDataToBeTransferredType: { keyFields: false },
  PersonTitle_EntityDataToBeTransferredType: { keyFields: false },
  PersonType_EntityDataToBeTransferredType: { keyFields: false },
  SiteActivity_EntityDataToBeTransferredType: { keyFields: false },
  SiteAddress_EntityDataToBeTransferredType: { keyFields: false },
  SiteContactValue_EntityDataToBeTransferredType: { keyFields: false },
  SiteCountry_EntityDataToBeTransferredType: { keyFields: false },
  SiteCustomField_EntityDataToBeTransferredType: { keyFields: false },
  SiteExternalId_EntityDataToBeTransferredType: { keyFields: false },
  SiteIsUnplaced_EntityDataToBeTransferredType: { keyFields: false },
  SiteLists_EntityDataToBeTransferredType: { keyFields: false },
  SiteMailingNamePrefix_EntityDataToBeTransferredType: { keyFields: false },
  SiteName_EntityDataToBeTransferredType: { keyFields: false },
  SiteNotes_EntityDataToBeTransferredType: { keyFields: false },
  SiteParent_EntityDataToBeTransferredType: { keyFields: false },
  SiteSegmentation_EntityDataToBeTransferredType: { keyFields: false },
  SiteTags_EntityDataToBeTransferredType: { keyFields: false },
  SiteType_EntityDataToBeTransferredType: { keyFields: false },
  SiteWebpage_EntityDataToBeTransferredType: { keyFields: false },
  SiteLinks_EntityDataToBeTransferredType: { keyFields: false },
};

