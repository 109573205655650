import { gql, useQuery } from '@apollo/client';
import React, { useEffect, useRef } from 'react';
import { Draggable } from '@fullcalendar/interaction';
import { FRAGMENT } from '../../browse/activity/ActivityDetails/ActivityPersons';
import CalendarCard from './CalendarCard';
import { personsCalendarQueryQuery, personsCalendarQueryQueryVariables } from '../../../gql/typings';


const CalendarSearchPersons: React.FC<{ personId: number }> = ({
  personId
}) => {
  const { data } = useQuery<personsCalendarQueryQuery, personsCalendarQueryQueryVariables>(DATA_QUERY, {
    variables: { personId },
  });
  const ref = useRef();

  useEffect(() => {
    if (ref && data?.person) {
      // @ts-ignore
      // eslint-disable-next-line no-new
      new Draggable(ref.current, {
        eventData: {
          title: data.person.fullName,
          id: data.person.id,
          duration: '01:00',
        }
      });
    }
  }, [data, ref]);

  return (
    <div style={{ margin: '0 16px 0 6px' }}>
      <CalendarCard
        // @ts-ignore
        ref={ref}
        title={data?.person?.fullName ?? ''}
        subTitle={data?.person?.primarySite?.name}
        daysAgo={data?.person?.daysSinceLastActivity}
      />
    </div>
  );
};

const DATA_QUERY = gql`
  query personsCalendarQuery($personId: Int!) {
    person(id: $personId) {
      id
      fullName
      daysSinceLastActivity
      primarySite{
        id
        name
      }
    }
  }
  ${FRAGMENT}
`;


export default CalendarSearchPersons;

