import React from 'react';
import { Button } from 'antd';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../../util/useLocalization';
import {
  AdminUserQueryQuery,
  useGenerateImpersonateTokenMutationMutation
} from '../../../../../../gql/typings';

type UserAdminTitleDetailsProps = {
  user: AdminUserQueryQuery['user'];
};

const UserAdminDetailsRightActions: React.FC<UserAdminTitleDetailsProps> = ({ user }) => {
  const navigate = useNavigate();
  const localization = useLocalization();
  const [gen] = useGenerateImpersonateTokenMutationMutation();

  const impersonate = () => gen({
    variables: { userId: user!.id },
  }).then(res => {
    if (res.data?.impersonateUser) {
      localStorage.setItem('myToken', localStorage.getItem('token')!);
      localStorage.setItem('token', `bearer ${res.data.impersonateUser.token}`);
      navigate('/');
      window.location.reload();
    }
  });

  return (
    <div>
      <Button onClick={impersonate} loading={!user} disabled={!user} type="primary">
        {localization.formatMessage(Locale.Command.Impersonate)}
      </Button>
    </div>
  );
};

gql`
  mutation GenerateImpersonateTokenMutation($userId: Int!) {
    impersonateUser(userId: $userId) {
      token
      expireDate
    }
  }
`;

export default UserAdminDetailsRightActions;
