import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, Form, Modal, Space, Typography } from 'antd';
import { omit } from 'lodash';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import {
  AddressInput,
  EntityTypeEnum,
  MergeAddressFragment,
  MergeAddressUpdateAddressMutation,
  MergeAddressUpdateAddressMutationVariables,
} from '../../../../../../../../../gql/typings';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import DisplayAddress from '../../../../../../../../components/Address/DisplayAddress';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import MergeRequestItemPrefixIcon from '../../components/MergeRequestItemPrefixIcon';
import { Optional } from '../../../../../../../../util/StateArrayType';
import AddressForm from '../../../../../../../../components/Address/AddressForm';
import { useColors } from '../../../../../../../../util/useColor';
import { useRandomKey } from '../../../../../../../../hooks/useRandomKey';

type AdminMergeItemAddressProps = AdminEntityMergeRequestItemProps & {
  entityType: EntityTypeEnum;
  addressAffiliation: MergeAddressFragment;
  conflictedAddressAffiliation: Optional<MergeAddressFragment>;
};

const AdminMergeItemAddress: React.FC<AdminMergeItemAddressProps> = (props) => {
  const localization = useLocalization();
  if (!props.item.hasConflict) return (
    <AdminMergeItemBasicField {...props}>
      {localization.formatMessage(Locale.Text.Merge_request_address_transfer_description, {
        addressType: localization.formatMessageByStr(props.addressAffiliation.address.type.headingKey),
        address: <strong><DisplayAddress {...props.addressAffiliation.address} /></strong>,
      })}
    </AdminMergeItemBasicField>
  );
  if (!props.conflictedAddressAffiliation) return (
    <span>Error</span>
  );

  const emitterKey = useRandomKey();
  const [update] = useMutation<MergeAddressUpdateAddressMutation, MergeAddressUpdateAddressMutationVariables>(UPDATE_MUTATION);
  const [modalVisible, setModalVisible] = useState(false);
  const [form1] = Form.useForm<AddressInput>();
  const [form2] = Form.useForm<AddressInput>();
  const [formFinal] = Form.useForm<AddressInput>();
  const [isFinished, setIsFinished] = useState(false);
  const { colorBorder } = useColors();

  useEffect(() => {
    props.emitter.emit({
      key: emitterKey,
      state: 'not_started',
      _source: 'AdminMergeItemAddress:useEffect',
    });
    const transformer = (v?: MergeAddressFragment['address']) => v ? {
      addressTypeCode: props.addressAffiliation.address.type.code,
      ...omit(v, [
        '__typename',
        'type',
        'id',
      ])
    } : {};
    form1.setFieldsValue(transformer(props.addressAffiliation.address));
    form2.setFieldsValue(transformer(props.conflictedAddressAffiliation?.address));
    formFinal.setFieldsValue(transformer(props.addressAffiliation.address));

  }, [
    emitterKey,
    form1,
    form2,
    formFinal,
    props.addressAffiliation.address,
    props.conflictedAddressAffiliation?.address,
    props.emitter,
  ]);

  return (
    <div>
      <MergeRequestItemPrefixIcon state={isFinished ? 'OK' : 'CONFLICT'} />
      <Space direction="horizontal" align="start">
        <div>
          {localization.formatText(Locale.Text.Merge_request_address_transfer_mismatch_description, {
            addressType: <strong>{localization.formatMessageByStr(props.addressAffiliation.address.type.headingKey)}</strong>,
            address1: <strong><DisplayAddress {...props.addressAffiliation.address} /></strong>,
            address2: <strong><DisplayAddress {...props.conflictedAddressAffiliation.address} /></strong>,
          })}
          <br />
          {isFinished && localization.formatMessage(Locale.Text.Will_be_value, {
            value: <strong><DisplayAddress {...formFinal.getFieldsValue()} /></strong>,
          })}
        </div>
        <Button type="link" onClick={() => setModalVisible(true)}>
          [Fix]
        </Button>
      </Space>
      <Modal
        open={modalVisible}
        closable
        onCancel={() => setModalVisible(false)}
        onOk={() => {
          setModalVisible(false);
          setIsFinished(true);
          props.emitter.emit({
            key: emitterKey,
            state: 'finished',
            _source: 'AdminMergeItemAddress:onOk',
            onSave: () => update({
              variables: {
                addressAffiliationId: props.conflictedAddressAffiliation!.id,
                input: formFinal.getFieldsValue(true),
              }
            }),
          });
        }}
        width="clamp(65%, 1200px, 85%)"
      >
        <Typography.Paragraph>
          {localization.formatMessage(Locale.Text.Merge_request_conflict_write_in_value)}
        </Typography.Paragraph>
        <Space>
          <AddressForm disabled form={form1} style={{ paddingRight: 8, borderRight: `1px solid ${colorBorder}` }} />
          <AddressForm disabled form={form2} />
          <AddressForm form={formFinal} style={{ paddingLeft: 8, borderLeft: `1px solid ${colorBorder}` }} />
        </Space>
      </Modal>
    </div>
  );
};

const UPDATE_MUTATION = gql`
  mutation MergeAddressUpdateAddress($addressAffiliationId: Int!, $input: AddressInput!) {
    updateAddress(addressAffiliationId: $addressAffiliationId, input: $input) {
      id
    }
  }
`;

export const mergeAddress_FRAGMENT = gql`
  fragment MergeAddress on AddressAffiliation {
    id
    address {
      id
      type {
        code
        headingKey
      }
      city
      postalCode
      street
      countryCode
      countyCode
    }
  }
`;

export default AdminMergeItemAddress;
