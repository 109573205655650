import { useMutation, useQuery, gql } from '@apollo/client';
import React, { useState } from 'react';
import { Button, Select, Card, Space, Spin } from 'antd';
import { useIntl } from 'react-intl';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { ImportManualAcctionProps } from '../ImportManualProcessesTypes';
import {
  CurrentImportProjectStateQuery,
  SaveSelectedExternalSystemMutation,
  SaveSelectedExternalSystemMutationVariables,
  SelectExternalSystemForImportQuery
} from '../../../../../../gql/typings';


const SelectExternalSystem: React.FC<ImportManualAcctionProps> = ({
  importProject: project,
  loading: externalLoading,
  continueAutomatic,
}) => {
  const intl = useIntl();
  const { data, loading } = useQuery<SelectExternalSystemForImportQuery>(DATA_QUERY);
  const [
    saveData,
    { loading: isSaving },
  ] = useMutation<SaveSelectedExternalSystemMutation, SaveSelectedExternalSystemMutationVariables>(SAVE_SYSTEM_MUTATION);
  const [selected, setSelected] = useState<string>();

  const manual = project?.manual as Extract<NonNullable<
  CurrentImportProjectStateQuery['importProject']>['manual'], { ['__typename']: 'SelectExternalSystemManualState' }
  >|null;

  if (externalLoading || loading) return <Spin />;

  const onContinue = () => {
    if (!manual) return;
    saveData({
      variables: {
        processorId: manual.processorId,
        projectId: manual.projectId,
        value: selected,
      },
    })
      .then(continueAutomatic);
  };

  return (
    <div className="select-external-system-container">
      <Card>
        <Space className="spacing" size="middle" direction="vertical">
          {intl.formatMessage(Locale.Text.Please_select_which_system_the_Ids_within_this_file_are_coming_from)}
          <Select
            loading={loading}
            placeholder={intl.formatMessage(Locale.Command.Select_External_System)}
            onChange={setSelected}
            value={selected}
            style={{ minWidth: 240 }}
            disabled={isSaving}
          >
            {data?.externalIdSystems.nodes.map(idType => (
              <Select.Option key={idType.id} value={idType.id}>{idType.heading}</Select.Option>
            ))}
          </Select>
          <Button onClick={onContinue} type="primary" disabled={!selected} loading={isSaving}>
            {intl.formatMessage(Locale.Command.Continue)}
          </Button>
        </Space>
      </Card>
    </div>
  );
};

const DATA_QUERY = gql`
  query SelectExternalSystemForImport {
    externalIdSystems {
      hash
      nodes {
        id
        heading
      }
    }
  }
`;

const SAVE_SYSTEM_MUTATION = gql`
  mutation SaveSelectedExternalSystem($processorId: Int!, $projectId: Int!, $value: String) {
    saveImportProcessorData(key: "SelectExternalSystem", processorId: $processorId, projectId: $projectId, value: $value) {
      id
      value
    }
  }
`;

export default SelectExternalSystem;
