import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { EntitiesSearch } from '../entitiesSearch';
import CalendarSearchPersons from './CalendarSearchPersons';
import { Locale } from '../../../localization/LocalizationKeys';
import { EntityTypeEnum } from '../../../gql/typings';
import { useTableRowSelection } from '../Table/useTableRowSelection';


const CalendarSearchView = () => {
  const tableSelection = useTableRowSelection({ virtualSupport: false });
  const [criteriaState, setCriteriaState] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <>
      {tableSelection.selection.selected.length > 0
        ? tableSelection.selection.selected.map(it => <CalendarSearchPersons key={it} personId={it} />)
        : <p className="caleendar-search-placeholder">{formatMessage(Locale.Text.No_HCP_added)}</p>}
      <div className="calendar-criteria-button">
        <Button onClick={() => setCriteriaState(true)} type="primary">{formatMessage(Locale.Command.Add_Criteria)}</Button>
      </div>
      <Modal
        open={criteriaState}
        onCancel={() => setCriteriaState(false)}
        width="80%"
        footer={false}
      >
        <EntitiesSearch
          entityType={EntityTypeEnum.PERSON}
          tableSelection={tableSelection}
          quickActions={[{
            key: 'select-person',
            onClick: () => {
              setCriteriaState(false);
            },
            label: formatMessage(Locale.Command.Add_to_search),
          }]}
        />
      </Modal>


    </>
  );
};


export default CalendarSearchView;

