import { selectionRowResultFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { Locale } from '../../../../localization/LocalizationKeys';

export const selectionRowResultSelectionDescriptionFieldConfig = selectionRowResultFieldConfig(
  'selectionDescription',
  [
    'selectionRow.id',
    'selectionRow.group.id',
    'selectionRow.group.selection.id',
    'selectionRow.group.selection.description',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Selection_description),
    render: record => record.selectionRow?.group.selection.description ?? localization.formatMessage(Locale.General.Unknown),
  }),
);
