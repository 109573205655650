import React from 'react';
import { gql } from '@apollo/client';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import {
  EntityTypeEnum,
  MergeItemPersonAddressFragment
} from '../../../../../../../../../gql/typings';
import AdminMergeItemAddress, { mergeAddress_FRAGMENT } from '../../shared/Address/AdminMergeItemAddress';

const AdminMergeItemPersonAddress: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonAddressFragment;

  return (
    <AdminMergeItemAddress
      {...props}
      entityType={EntityTypeEnum.PERSON}
      addressAffiliation={info.addressAffiliation}
      conflictedAddressAffiliation={info.conflictedWith}
    />
  );
};

gql`
  fragment MergeItemPersonAddress on PersonAddress_EntityDataToBeTransferredType {
    code
    addressAffiliation { ...MergeAddress }
    conflictedWith { ...MergeAddress }
  }

  ${mergeAddress_FRAGMENT}
`;

export default AdminMergeItemPersonAddress;
