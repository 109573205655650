import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import DateDisplay from '../../../components/Badge/DateDisplay';
import { personFieldConfig } from './utils/personFieldsUtils';
import { PermissionEnum, PersonCriteriaOrder } from '../../../../gql/typings';


export const personValidatedFieldConfig = personFieldConfig(
  'validated',
  ['imfVt'],
  ({ localization }) => ({
    permission: PermissionEnum.VALIDATE_RECORD,
    title: localization.formatMessage(Locale.Attribute.Validated),
    additionalTableConfig: {
      width: 125
    },
    sorting: {
      ascend: PersonCriteriaOrder.IMF_VT_ASC,
      descend: PersonCriteriaOrder.IMF_VT_DESC,
    },
    render: (record) => <DateDisplay>{record?.imfVt}</DateDisplay>,
  })
);

