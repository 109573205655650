import { useQuery, gql } from '@apollo/client';
import React, { CSSProperties, useState } from 'react';
import { ExclamationCircleOutlined, LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm, Spin } from 'antd';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { max } from 'lodash';
import icon from '../../../../../img/icon-download-blue.svg';
import useCherryPickMutation from '../useCherryPickMutation';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { CherryPickDataQueryQuery, CherryPickDataQueryQueryVariables, EntityTypeEnum } from '../../../../../gql/typings';


type DownloadBadgeProps = {
  type: 'PERSON';
  entity: {
    id: number;
    countryCode: string;
  };
  name: string;
  visible?: boolean;
  loading?: boolean;
  style?: CSSProperties;
};

const DownloadBadge: React.FC<DownloadBadgeProps> = ({
  visible = true,
  name,
  type,
  entity: { id, countryCode },
  loading,
  style,
}) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [cherryPick] = useCherryPickMutation();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const { data, loading: dataLoading } = useQuery<CherryPickDataQueryQuery, CherryPickDataQueryQueryVariables>(DATA_QUERY, {
    variables: { country: countryCode },
    fetchPolicy: 'network-only',
  });

  if (!dataLoading && (!data || !data.cherryPickOverview)) return <></>;

  const allowed = dataLoading || data!.cherryPickOverview.amountAvailable > 0;
  const contact = <a href="mailto:sales@apurebase.com">Contact sales</a>;

  const text = allowed
    ? <span>
      You have&nbsp;
      {
        dataLoading
          ? <Spin size="small" spinning indicator={<LoadingOutlined />} />
          : <b>{data!.cherryPickOverview!.amountAvailable} out of {data!.cherryPickOverview.amount}&nbsp;</b>
      }
      cherry picks left in <b>{countryCode}</b>.<br />
      Are you sure you wish to cherrypick <b>{name}</b> to your local database?
      {!dataLoading
        && (data!.cherryPickOverview.amountAvailable / max([1, data!.cherryPickOverview.amount])!) <= 0.1
        && (<span><br />{contact}</span>)}
    </span>
    : <span>
      You don't have any cherry picks left in <b>{countryCode}</b>.<br />
      {contact}
    </span>;

  const confirmIcon = allowed
    ? <ExclamationCircleOutlined />
    : <QuestionCircleOutlined style={{ color: 'red' }} />;

  return (
    <span className="download-badge-container" style={style}>
      {visible && (
        <Popconfirm
          placement="left"
          title={text}
          icon={confirmIcon}
          onConfirm={() => {
            setIsDownloading(true);
            cherryPick({
              variables: {
                type: type as EntityTypeEnum,
                apurebaseIds: [id],
              },
            })
              .then(res => {
                if (res.data?.cherryPick[0]?.id) navigate(`/hcp/${res.data.cherryPick[0].id}`);
              })
              .catch(() => setIsDownloading(false));
          }}
          okText={formatMessage(Locale.Command.Confirm)}
          cancelText={formatMessage(Locale.Command.Cancel)}
          okButtonProps={{ disabled: !allowed }}
        >
          {(isDownloading || loading)
            ? <Spin indicator={<LoadingOutlined style={{ fontSize: 19 }} spin />} />
            : <img className="cherrypick" src={icon} alt={`Cherrypick ${name}`} />}
        </Popconfirm>
      )}
    </span>
  );
};

const DATA_QUERY = gql`
  query CherryPickDataQuery($country: CountryCode!) {
    cherryPickOverview(country: $country) {
      customerCode
      country
      amount
      amountAvailable
      amountUsed
    }
#    conf: cherryPickConfigurations(criteria: { country: $country }) {
#      hash
#      amountLeft
#      totalAmount
#    }
  }
`;

export default DownloadBadge;
