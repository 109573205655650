import { gql, useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, message, Popconfirm, Space, Switch } from 'antd';
import { Locale } from '../../../localization/LocalizationKeys';
import { StateArray } from '../../util/StateArrayType';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { RichTextEditorInstance } from '../RichTextEditor/RichTextTypes';
import { useLocalization } from '../../util/useLocalization';
import {
  DeletePersonNoteMutationMutation, DeletePersonNoteMutationMutationVariables,
  EntityTypeEnum,
  NoteDrawerFragmentFragment, PersonCreateNoteMutationMutation, PersonCreateNoteMutationMutationVariables,
  PersonUpdateNoteMutationMutation,
  PersonUpdateNoteMutationMutationVariables
} from '../../../gql/typings';


const NoteDrawer: React.FC<{
  noteState?: StateArray<NoteDrawerFragmentFragment | null>;
  id: number;
  displayDrawer: boolean;
  setDisplayDrawer: (value: boolean) => void;
  entity: EntityTypeEnum;
}> = ({
  noteState,
  id,
  displayDrawer,
  setDisplayDrawer,
  entity,
}) => {
  const [headingForm] = Form.useForm();
  const localization = useLocalization();
  const [editor, setEditor] = useState<RichTextEditorInstance>();
  const [updateNote] = useMutation<PersonUpdateNoteMutationMutation, PersonUpdateNoteMutationMutationVariables>(UpdateNote);
  const [deleteNote] = useMutation<DeletePersonNoteMutationMutation, DeletePersonNoteMutationMutationVariables>(DeleteNote);
  const [createNote] = useMutation<PersonCreateNoteMutationMutation, PersonCreateNoteMutationMutationVariables>(CreateNote);

  const [note] = noteState ?? [];

  const refreshQueries = [
    'ActivityDetail',
    'PersonAffiliationsContainerQuery',
    'LinkTabDetail',
    'SiteAffiliationContainerQuery'
  ];

  useEffect(() => {
    if (note) {
      headingForm.setFieldsValue({
        'note-title': note.title,
        'note-private': !note.private,
        'note-text': note.text
      });
    }
  }, [note, headingForm]);


  useEffect(() => {
    if (editor && note) {
      editor.setData(note.text ?? '');
    }
  }, [editor, note]);

  const onSaveUpdates = () => {
    if (!note) return;
    headingForm.validateFields().then(values => {
      updateNote({
        variables: {
          id: note.id,
          title: values['note-title'],
          text: editor?.getData() ?? '',
          private: !values['note-private'],
        },
        refetchQueries: refreshQueries,
      }).finally(() => {
        setDisplayDrawer(false);
        message.success(localization.formatMessage(Locale.Text.Note_successfully_updated));
      });
    });

  };

  const onSaveCreate = () => headingForm.validateFields()
    .then(values => {

      createNote({
        variables: {
          id,
          title: values['note-title'] ? values['note-title'] : 'untitled note',
          text: editor?.getData() ?? '',
          private: !values['note-private'],
          entity,
        },
        refetchQueries: refreshQueries,
      })
        .then(() => setDisplayDrawer(false))
        .finally(() => message.success(localization.formatMessage(Locale.Text.Note_successfully_created)));

      headingForm.resetFields();
    });


  const triggerDelete = () => {
    if (!note) return;
    deleteNote({
      variables: {
        id: note.id,
      },
      refetchQueries: refreshQueries,
    })
      .then(() => setDisplayDrawer(false))
      .finally(() => message.success(localization.formatMessage(Locale.Text.Note_successfully_deleted)));
  };

  const onClose = () => {
    setDisplayDrawer(false);
    headingForm.resetFields();
  };

  const Test = (
    <>
      <h3><span className="label">
        {localization.formatMessage(Locale.Attribute.Title)}
      </span></h3>
      <div className="input">
        <Form.Item name="note-title">
          <Input
            className="search-name"
            placeholder={localization.formatMessage(Locale.Command.Enter_title)}
          />
        </Form.Item>
      </div>
      <br />
      <h3><span className="label">
        {localization.formatMessage(Locale.General.Visibility)}
      </span>
      </h3>
      <div className="input" />


      <Form.Item name="note-private" valuePropName="checked">
        <Switch
          style={{ width: '75px' }}
          className="search-name"
          checkedChildren="Public"
          unCheckedChildren="Private"
        />

      </Form.Item>
      <h3><span className="label">
        {localization.formatMessage(Locale.Attribute.Created)}

      </span>
      </h3>
      <div className="input editor">
        <RichTextEditor
          width="397px"
          onInstance={setEditor}
        />
      </div>
    </>
  );

  return (
    <Drawer
      title={note ? localization.formatMessage(Locale.Attribute.Note_Details)
        : localization.formatMessage(Locale.Command.Create_Note)}
      width="500px"
      /* closeIcon={<Icon iconType="CLOSE" />} */
      placement="right"
      onClose={onClose}
      open={displayDrawer}
    >
      <div className="note-drawer-container">


        <Form form={headingForm}>
          {Test}
        </Form>

        <br />

        <Space>
          {note && <Button onClick={onClose}>{localization.formatMessage(Locale.Command.Cancel)}</Button>}
          {note && <Popconfirm
            title={localization.formatMessage(Locale.Text.Are_you_sure_to_delete)}
            onConfirm={triggerDelete}
            onCancel={() => setDisplayDrawer(false)}
            okText={localization.formatMessage(Locale.General.Yes)}
            cancelText={localization.formatMessage(Locale.General.No)}
          >
            <Button disabled={!note}>
              {localization.formatMessage(Locale.Command.Delete)}
            </Button>
          </Popconfirm>}
          <Button onClick={note ? onSaveUpdates : onSaveCreate} type="primary">
            {localization.formatMessage(Locale.Command.Save)}
          </Button>
        </Space>
      </div>
    </Drawer>
  );
};

const UpdateNote = gql`
  mutation PersonUpdateNoteMutation(
    $id: Int!,
    $title: String!,
    $text: String!,
    $private: Boolean!,
  ) {
    updateNote(id: $id, text: $text, title: $title, private: $private) {
      id
      text
      title
    }
  }
`;

const CreateNote = gql`
  mutation PersonCreateNoteMutation(
    $id: Int!,
    $title: String!,
    $text: String!,
    $private: Boolean!,
    $entity: EntityTypeEnum!,
  ) {
    createNote(entityType: $entity, entityAffiliationId: $id, text: $text, title: $title, private: $private) {
      id
      text
    },
  }
`;

const DeleteNote = gql`
  mutation DeletePersonNoteMutation(
    $id: Int!,
  ) {
    deleteNote(id: $id)
  }
`;

export const NoteDrawer_FRAGMENT = gql`
  fragment NoteDrawerFragment on Note {
    id
    text
    ct
    ut
    status
    private
    title
    user {
      id
      fullname
    }
  }
`;

export default NoteDrawer;

