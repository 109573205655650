import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Select } from 'antd';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import {
  MergeItemAffiliationPositionCodeFragment,
  MRAffiliationPositionCodeQuery,
  MRAffiliationPositionCodeQueryVariables,
  useUpdateAffiliationPositionCodeMutation,
} from '../../../../../../../../../gql/typings';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';

const AdminMergeItemAffiliationPositionCode: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemAffiliationPositionCodeFragment;


  const localization = useLocalization();
  const { data, loading } = useQuery<MRAffiliationPositionCodeQuery, MRAffiliationPositionCodeQueryVariables>(DATA_QUERY, {
    variables: { countryCode: info.loserLink.countryCode },
  });
  const [updatePosition] = useUpdateAffiliationPositionCodeMutation();

  if (!props.item.hasConflict) return (
    <AdminMergeItemBasicField {...props}>
      {localization.formatMessage(Locale.Text.Merge_request_link_position_description, {
        position: <strong>{info.winnerType?.displayLabel}</strong>
      })}
    </AdminMergeItemBasicField>
  );

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.loserType?.code}
      toValue={info.winnerType?.code}
      updateMutation={newCode => updatePosition({ variables: { linkId: info.winnerLink!.id, newCode } })}
      valueDisplayMapper={code => data?.positions.nodes.filter(pt => pt.code === code)[0]?.displayLabel}
      inputRenderer={props => (
        <Select
          {...props}
          loading={loading}
          options={(data?.positions.nodes ?? []).map(pt => ({ value: pt.code, label: pt.displayLabel }))}
        />
      )}
    >
      {localization.formatMessage(Locale.Text.Merge_request_link_position_mismatch_description, {
        position1: <strong>{info.loserType?.displayLabel}</strong>,
        position2: <strong>{info.winnerType?.displayLabel}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

const DATA_QUERY = gql`
  query MRAffiliationPositionCode($countryCode: CountryCode!) {
    positions(criteria: { countries: [$countryCode], fetchSize: { limit: 10000 } }) {
      hash
      nodes {
        code
        label
        type
        displayLabel
      }
    }
  }
`;

gql`
  mutation UpdateAffiliationPositionCode($linkId: Int!, $newCode: String) {
    updatePosition(id: $linkId, positionCode: $newCode) {
      id
      positionCode
      position { code, type }
    }
  }
`;

gql`
  fragment MergeItemAffiliationPositionCode on AffiliationPositionCode_EntityDataToBeTransferredType {
    code
    loserLink { id, countryCode }
    winnerLink { id }
    loserType {
      code
      type
      displayLabel
    }
    winnerType {
      code
      type
      displayLabel
    }
  }
`;

export default AdminMergeItemAffiliationPositionCode;
