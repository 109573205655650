/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Avatar, Card, Col, Popconfirm, Popover, Progress, Row, Statistic, Tooltip } from 'antd';
import {
  DeleteOutlined,
  ExportOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { FormattedDate } from 'react-intl';
import puffinIcon from '../../../../img/puffin-icon.png';
import GlowButton from '../../../components/Input/GlowButton';
import CreateSandboxModal from './CreateSandboxModal';
import { usePermission } from '../../../permission/usePermission';
import SandboxPopoverInfo from './SandboxPopoverInfo';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  AntProgressStatusEnum,
  DestroySandboxMutationMutation,
  DestroySandboxMutationMutationVariables, ResetSandboxDbMutationMutation, ResetSandboxDbMutationMutationVariables,
  SandboxInformationQueryQuery
} from '../../../../gql/typings';


const SandboxView: React.FC = () => {
  const localization = useLocalization();
  const { data, refetch, loading, startPolling, stopPolling } = useQuery<SandboxInformationQueryQuery>(DATA_QUERY);
  const [
    destroySandbox,
    { loading: isDeleting },
  ] = useMutation<DestroySandboxMutationMutation, DestroySandboxMutationMutationVariables>(DELETE_MUTATION);
  const [
    resetDb,
    { loading: resetLoading },
  ] = useMutation<ResetSandboxDbMutationMutation, ResetSandboxDbMutationMutationVariables>(RESET_DB_MUTATION);
  const { loading: permissionLoading, create, read, update, delete: canDelete } = usePermission('SANDBOX');
  const createNewState = useState(false);

  useEffect(() => {
    startPolling(12500); // Every 12.5seconds
    return () => { stopPolling(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!read && !permissionLoading) return (
    <span>You do not have permission to be here!</span>
  );

  const onDelete = (sandboxId: number) => destroySandbox({
    variables: { sandboxId },
  }).finally(refetch);


  return (
    <div className="sandbox-view-container">
      <Row>
        <Statistic
          title="Available"
          value={data?.instance.sandboxes.totalCount}
          suffix={`/ ${data?.instance.maxSandboxes}`}
          loading={loading}
        />
      </Row>
      <br />
      <Row gutter={[8, 8]}>
        {(data?.instance.sandboxes.nodes ?? []).map(s => {
          const url = `https://${s.heading}.${data?.instance.code}.pureadvance.com`.replace('_', '-');
          const ds = s.deploymentStatus;
          const items = [
            ds.db.currentStatus,
            ds.app.currentStatus,
            ds.clone.currentStatus,
            ds.activityJob.currentStatus,
          ];
          const currentStatus = items.indexOf(AntProgressStatusEnum.ERROR) > -1
          || items.indexOf(AntProgressStatusEnum.STOP) > -1
            ? 'exception'
            : 'normal';
          return (
            <Col key={s.id} md={8} lg={4}>
              <Card
                loading={isDeleting}
                actions={[
                  <Tooltip key="open" title="Open in new tab">
                    <ExportOutlined
                      onClick={() => window.open(url, '_blank')}
                      disabled={ds.app.currentStatus !== AntProgressStatusEnum.SUCCESS}
                    />
                  </Tooltip>,
                  update && <Tooltip key="sync" title={localization.formatMessage(Locale.Text.Reset_sandbox_database)}>
                    <SyncOutlined
                      onClick={() => {
                        if (!resetLoading) {
                          resetDb({ variables: { sandboxId: s.id } });
                          refetch();
                        }
                      }}
                      spin={resetLoading}
                      style={{ lineHeight: 0 }}
                    />
                  </Tooltip>,
                  canDelete && <Tooltip key="delete" title="Destroy sandbox environment">
                    <Popconfirm
                      title="Are you sure you want to destroy & remove this sandbox environment?"
                      onConfirm={() => onDelete(s.id)}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  </Tooltip>,
                ]}
              >
                <div className="card-content">
                  <Avatar src={puffinIcon} />
                  <div className="main-content">
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {s.heading}
                    </a>
                    <FormattedDate
                      value={s.ct}
                      year="numeric"
                      month="long"
                      day="2-digit"
                    />
                  </div>
                  <div className="progress-bar">
                    <Popover
                      mouseLeaveDelay={1}
                      placement="topLeft"
                      content={<SandboxPopoverInfo deployment={s} />}
                    >
                      <Progress
                        percent={s.deploymentStatus.currentPercentage}
                        steps={s.deploymentStatus.totalSteps}
                        size="small"
                        status={currentStatus}
                      />
                    </Popover>
                  </div>
                </div>
              </Card>
            </Col>
          );
        })}
        {(data?.instance.maxSandboxes ?? 0) > (data?.instance.sandboxes.totalCount ?? 0) && create && (
          <Col md={8} lg={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <GlowButton
              type="primary"
              label="Add New"
              onClick={() => createNewState[1](true)}
            />
          </Col>
        )}
      </Row>
      <CreateSandboxModal
        visibleState={createNewState}
        customerCode={data?.instance.code ?? ''}
        onCreated={refetch}
      />
    </div>
  );
};

const DATA_QUERY = gql`
  query SandboxInformationQuery {
    instance {
      code
      maxSandboxes
      sandboxes {
        hash
        totalCount
        nodes {
          id
          heading
          # Inactive are filtered out by default, but when something has been deleted from the cache, this will be used
          isActive
          serverCode
          ct
          ut
          deploymentStatus {
            code
            currentPercentage
            totalSteps
            app { currentStatus, message }
            clone { currentStatus, message }
            db { currentStatus, message }
            activityJob { currentStatus, message }
            schedule {
              code
              cron
              isActive
              lastRun
              status
            }
          }
        }
      }
    }
  }
`;

const DELETE_MUTATION = gql`
  mutation DestroySandboxMutation($sandboxId: Int!) {
    destroySandbox(sandboxId: $sandboxId) {
      id
      isActive
    }
  }
`;

const RESET_DB_MUTATION = gql`
  mutation ResetSandboxDbMutation($sandboxId: Int!) {
    resetSandboxDb(sandboxId: $sandboxId) {
      id
      isActive
    }
  }
`;

export default SandboxView;
