import React from 'react';
import { Select } from 'antd';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { CustomFieldTypeEnum } from '../../../../../gql/typings';

type CustomFieldSelectTypeProps = {
  value?: CustomFieldTypeEnum;
  onChange?: (newValue: CustomFieldTypeEnum) => void;
  isCreated: boolean;
};

const CustomFieldSelectType: React.FC<CustomFieldSelectTypeProps> = ({ value, onChange, isCreated, ...rest }) => {
  const localization = useLocalization();
  return (
    <Select disabled={isCreated} value={value} onChange={onChange} {...rest}>
      <Select.Option value={CustomFieldTypeEnum.CHECK}>
        {localization.formatMessage(Locale.General.Check)}
      </Select.Option>
      <Select.Option value={CustomFieldTypeEnum.NUMBER}>
        {localization.formatMessage(Locale.General.Number)}
      </Select.Option>
      <Select.Option value={CustomFieldTypeEnum.SELECT}>
        {localization.formatMessage(Locale.General.Select)}
      </Select.Option>
      <Select.Option value={CustomFieldTypeEnum.TAG}>
        {localization.formatMessage(Locale.General.Tag)}
      </Select.Option>
      <Select.Option value={CustomFieldTypeEnum.SWITCH}>
        {localization.formatMessage(Locale.General.Switch)}
      </Select.Option>
      <Select.Option value={CustomFieldTypeEnum.TEXT}>
        {localization.formatMessage(Locale.General.Text)}
      </Select.Option>
    </Select>
  );
};

export default CustomFieldSelectType;
