import React from 'react';
import { Tooltip, Typography } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { Locale } from '../../../../localization/LocalizationKeys';
import { genericFieldConfig } from './utils/personFieldsUtils';
import {
  CanIGenerateApureBaseIdQuery,
  EntityTypeEnum, GenerateApureBaseIdMutation, GenerateApureBaseIdMutationVariables, PermissionEnum,
} from '../../../../gql/typings';
import { usePermission } from '../../../permission/usePermission';
import { useLocalization } from '../../../util/useLocalization';
import { TableFieldReturned } from '../../search_old/types';

type SupportedEntityTypes = EntityTypeEnum.PERSON|EntityTypeEnum.SITE|EntityTypeEnum.AFFILIATION;

const permissionMap: Record<SupportedEntityTypes, `${PermissionEnum}`> = {
  PERSON: 'Person',
  SITE: 'Site',
  AFFILIATION: 'Link',
};

export const buildApureBaseIdFieldConfig = (
  entityType: SupportedEntityTypes,
  sorting?: TableFieldReturned<unknown, unknown>['sorting'],
) => genericFieldConfig(
  'aPureBaseId',
  ['id', 'apurebaseId', 'maintainerSourceCode'],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.aPureBase_ID),
    additionalTableConfig: {
      width: 100
    },
    sorting,
    render: (record) => {
      const localization = useLocalization();
      const { update } = usePermission(permissionMap[entityType], !record.apurebaseId);
      const { data } = useQuery<CanIGenerateApureBaseIdQuery>(DATA_QUERY, { skip: !!record.apurebaseId });
      const [
        generate,
        { loading },
      ] = useMutation<GenerateApureBaseIdMutation, GenerateApureBaseIdMutationVariables>(GENERATE_MUTATION);

      if (record.apurebaseId) {
        return <Typography.Text>{record.apurebaseId}</Typography.Text>;
      }

      if (!data?.canIGenerateApureBaseId || !update) {
        return <span />;
      }

      return (
        <Typography.Text
          onClick={() => generate({ variables: { id: record.id, entityType } })}
          style={{ cursor: 'pointer' }}
        >
          <Tooltip title={localization.formatMessage(Locale.Text.Generate_apurebase_id_right_away_description)}>
            {localization.formatMessage(Locale.Attribute.None)}
            &nbsp;
            {loading ? <LoadingOutlined /> : <ReloadOutlined />}
          </Tooltip>
        </Typography.Text>
      );
    },
  })
);

const GENERATE_MUTATION = gql`
  mutation GenerateApureBaseId($entityType: EntityTypeEnum!, $id: ID!) {
    synchronizeApureBaseId(entityType: $entityType, entityAffiliationId: $id) {
      ... on Person { id, apurebaseId }
      ... on Site { id, apurebaseId }
      ... on SitePerson { id, apurebaseId }
    }
  }
`;

const DATA_QUERY = gql`
  query CanIGenerateApureBaseId {
    canIGenerateApureBaseId
  }
`;
