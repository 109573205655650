import React, { useState } from 'react';
import { Badge, Col, Modal, Row, Tooltip } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { useLocalization } from '../../../../util/useLocalization';
import CreateCustomField from '../../customFields/CreateCustomField';
import { dashboardGridViewDraggableCancelClassName } from '../../../dashboards/DashboardGridView/DashboardGridView';
import Icon, { ITE } from '../../../../components/Icon/Icon';
import Card from '../../../../components/Card/Card';
import UpdateCustomField from '../../customFields/UpdateCustomField';
import { DetailedGridLayoutItemProps } from '../DetailedGridLayout';
import {
  CustomFieldGridDisplayQueryQuery,
  CustomFieldGridDisplayQueryQueryVariables,
  CustomFieldTypeEnum
} from '../../../../../gql/typings';

const CustomFieldGridDisplay: React.FC<DetailedGridLayoutItemProps> = ({
  entityType,
  entityGridItemConfigurationId,
}) => {
  const localization = useLocalization();
  const [, setSelectedRecord] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const { data, refetch } = useQuery<CustomFieldGridDisplayQueryQuery, CustomFieldGridDisplayQueryQueryVariables>(DATA_QUERY, {
    variables: { entityGridItemConfigurationId },
  });
  const icons = (e: string): ITE => {
    switch (e) {
      case 'HCP_CONSENT':
        return 'CONSENT';
      case 'HCP_SEGMENTATION':
        return 'SEGMENTATION';
      case 'HCP_EXTERNAL_IDS':
        return 'EXTERNAL_ID';
      case 'HCP_TAGS':
        return 'TAGS';
      case 'HCP_RES_AREAS':
        return 'RES_USERS';
      case 'HCP_CUSTOM_FIELD':
        return 'CUSTOM_FIELD';

      default:
        return 'SETTINGS';
    }
  };

  const onUpdate = () => {
    setIsSelected(false);
    refetch();
  };

  return (
    <div className="custom-field-admin-grid-container">
      <Row className={`customField-heading ${dashboardGridViewDraggableCancelClassName}`} gutter={[8, 8]}>
        {(data?.entityGridItemConfiguration?.fields?.nodes ?? []).map(item => (
          <Col key={item.id} span={24}>
            <Card size="noSize">
              <Row>
                <Col span={22}>
                  {item && <Icon
                    iconType={icons(item?.type.code)}
                    text={item.customFieldConfiguration
                      ? item.customFieldConfiguration.customField.heading
                      : localization.formatMessageByStr(item.type.heading)}
                    onClick={item.customFieldConfiguration ? (() => setSelectedRecord(it)) : undefined}
                  />}
                </Col>
                {item?.customFieldConfiguration?.customField.options != null && <Col span={2}>
                  <Tooltip title="Amount of options">
                    <Badge
                      style={{ backgroundColor: '#C2CE26' }}
                      count={item.customFieldConfiguration?.customField.options.totalCount}
                    />
                  </Tooltip>
                </Col>}
              </Row>
            </Card>
            <Modal
              open={isSelected}
              onCancel={() => setIsSelected(false)}
              footer={false}
              wrapClassName={dashboardGridViewDraggableCancelClassName}
            >
              {item && (
                item.customFieldConfiguration?.customField
                  ? <UpdateCustomField customFieldCode={item.customFieldConfiguration.customField.code} />
                  : (
                    <CreateCustomField
                      initialType={item.customFieldConfiguration?.customField.fieldType.enum ?? CustomFieldTypeEnum.TEXT}
                      onCreated={onUpdate}
                      entityType={entityType}
                    />
                  )
              )}
            </Modal>
          </Col>
        ))}
      </Row>
    </div>
  );
};

const DATA_QUERY = gql`
  query CustomFieldGridDisplayQuery($entityGridItemConfigurationId: Int!) {
    entityGridItemConfiguration(id: $entityGridItemConfigurationId) {
      id
      fields {
        hash
        nodes {
          id
          gridItemFieldTypeCode
          entityGridItemConfigurationId
          sort
          type {
            code
            heading
            isActive
          }
          customFieldConfiguration{
            id
            customField{
              code
              heading
              customFieldTypeCode
              options{
                hash
                totalCount
                nodes{
                  code
                  value{
                    id
                    value
                  }
                }
              }
              fieldType {
                code
                enum
              }
            }
          }
        }
      }
    }
  }
`;

export default CustomFieldGridDisplay;
