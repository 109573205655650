import React from 'react';
import { Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '../Icon/Icon';

type GlowButtonProps = {
  loading?: boolean;
  onClick?: () => void;
  type?: 'primary'|'action'|'remove';
  label?: string|React.ReactNode;
  size?: 'x-small'|'small'|'medium';
  tooltip?: string;
  labelPlacement?: 'under'|'left';
  className?: string;
};

const GlowButton: React.FC<GlowButtonProps> = ({
  loading,
  onClick,
  type = 'primary',
  label,
  size = 'medium',
  tooltip,
  labelPlacement = 'under',
  className,
}) => (
  <div className={`blobs-container ${labelPlacement} ${className}`} onClick={onClick}>
    {label && labelPlacement === 'left' && <div className={`label-left ${size}`}>{label}</div>}
    <Tooltip destroyTooltipOnHide={!!tooltip} title={tooltip}>
      <div className={`blob ${type} ${size}`}>
        {loading
          ? <LoadingOutlined />
          : (
            <Icon
              iconType={type === 'remove' ? 'MINUS' : 'PLUS'}
              className={`default-plus-icon ${size}`}
              iconOnly
            />
          )}
      </div>
      {label && labelPlacement === 'under' && <div className="label">{label}</div>}
    </Tooltip>
  </div>
);

export default GlowButton;

