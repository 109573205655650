import React from 'react';
import { Alert, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useLocalization } from '../util/useLocalization';
import { Locale } from '../../localization/LocalizationKeys';

const HeaderIsImpersonating = () => {
  const navigate = useNavigate();
  const localization = useLocalization();
  const myToken = localStorage.getItem('myToken');

  if (!myToken) return null;

  const back = () => {
    localStorage.setItem('token', localStorage.getItem('myToken')!);
    localStorage.removeItem('myToken');
    navigate('/admin/users/list');
    window.location.reload();
  };

  return (
    <Alert
      message={localization.formatMessage(Locale.Text.You_are_impersonating_user)}
      type="warning"
      action={
        <Button onClick={back}>
          {localization.formatMessage(Locale.Text.Back_to_my_user)}
        </Button>
      }
    />
  );
};

export default HeaderIsImpersonating;
