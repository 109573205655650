import React from 'react';
import AnchoredSections, { AnchoredItem } from '../../adminComponents/AdminSection/AnchoredSections';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import ConfigureTableColumnSettings from '../../../../components/ConfigureTableColumnSettings/ConfigureTableColumnSettings';
import {
  TableColumnConfigureSettingType
} from '../../../../components/ConfigureTableColumnSettings/useTableColumnConfigureSettings';
import AdminConfigureSearch from '../../adminComponents/AdminConfigureSearch/AdminConfigureSearch';
import AdminExternalId from '../../adminComponents/AdminExternalId/AdminExternalId';
import { EntityTypeEnum } from '../../../../../gql/typings';

const ListAdminSettings: React.FC = () => {
  const localization = useLocalization();
  return (
    <div className="list-admin-settings-container">
      <AnchoredSections>

        <AnchoredItem anchor="search" title={localization.formatMessage(Locale.Command.Search)}>
          <AdminConfigureSearch entityType={EntityTypeEnum.LIST} />
        </AnchoredItem>

        <AnchoredItem anchor="external-id" title={localization.formatMessage(Locale.Attribute.External_IDs)}>
          <AdminExternalId entityType={EntityTypeEnum.LIST} />
        </AnchoredItem>

        <AnchoredItem anchor="table-columns" title={localization.formatMessage(Locale.General.Table_columns)}>
          <ConfigureTableColumnSettings
            entityType={EntityTypeEnum.LIST}
            type={TableColumnConfigureSettingType.SYSTEM}
          />
        </AnchoredItem>

      </AnchoredSections>
    </div>
  );
};

export default ListAdminSettings;
