import React, { createContext } from 'react';
import { Layout } from 'react-grid-layout';
import { StateArray } from '../../../util/StateArrayType';
import { DynamicTabGridFragmentFragment } from '../../../../gql/typings';

export type DynamicEntityTabItem = Layout & {
  item: DynamicTabGridFragmentFragment;
};

export type DynamicEntityTabContextType = {
  gridState: StateArray<DynamicEntityTabItem[]>;
  rootRef: React.MutableRefObject<HTMLDivElement|null>;
};

// @ts-ignore
export default createContext<DynamicEntityTabContextType>({});
