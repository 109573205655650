import React from 'react';
import MainView from '../../components/View/MainView';
import { EntitiesSearch } from '../../components/entitiesSearch';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';
import { EntityTypeEnum } from '../../../gql/typings';


const BrickView = () => {
  const intl = useLocalization();

  return (
    <MainView
      className="brick-view-container"
      title={intl.formatMessage(Locale.General.Brick)}
    >
      <EntitiesSearch entityType={EntityTypeEnum.BRICK} />
    </MainView>
  );
};
export default BrickView;
