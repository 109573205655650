import { TableFieldsConfig } from '../search_old/types';
import { siteSelectionRowRowIdFieldConfig } from './field/siteSelectionRowRowIdFieldConfig';
import { siteSelectionRowCustomerNameFieldConfig } from './field/siteSelectionRowCustomerNameFieldConfig';
import {
  siteSelectionRowSelectionGroupDescriptionFieldConfig
} from './field/siteSelectionRowSelectionGroupDescriptionFieldConfig';
import { siteSelectionRowSelectionDescriptionFieldConfig } from './field/siteSelectionRowSelectionDescriptionFieldConfig';
import { siteSelectionRowSelectionTypeFieldConfig } from './field/siteSelectionRowSelectionTypeFieldConfig';

export type SupportedSiteSelectionRowTableColumns =
  'rowId'
  |'customerName'
  |'selectionGroupDescription'
  |'selectionDescription'
  |'selectionType';

export const supportedSiteSelectionRowTableColumns: TableFieldsConfig<SupportedSiteSelectionRowTableColumns>['fields'] = {
  rowId: siteSelectionRowRowIdFieldConfig,
  customerName: siteSelectionRowCustomerNameFieldConfig,
  selectionGroupDescription: siteSelectionRowSelectionGroupDescriptionFieldConfig,
  selectionDescription: siteSelectionRowSelectionDescriptionFieldConfig,
  selectionType: siteSelectionRowSelectionTypeFieldConfig,
};
