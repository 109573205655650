import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import Icon, { ITE } from '../Icon/Icon';
import { Locale } from '../../../localization/LocalizationKeys';
import { DATE_FORMAT_TIME_DAY } from '../../util/format';


const AdvanceCard: React.FC<{
  title?: string | null;
  description?: string | null;
  icon: ITE;
  createdUser: string | undefined | null;
  date: string | null;
  onClick: (() => void) | null;

}> = ({
  title,
  description,
  icon,
  date,
  createdUser,
  onClick
}) => {
  const mStart = moment(date);
  const dateFormat = DATE_FORMAT_TIME_DAY;
  const { formatMessage } = useIntl();

  return (
    <div className="advance-card-container" onClick={() => onClick?.()}>
      <Icon style={{ fontSize: '36px', textAlign: 'center' }} iconType={icon} />
      <div className='card-info'>
        <div className='note-creation-details'>
          <h5>{formatMessage(Locale.Attribute.Created_by)} : {createdUser}</h5>
          <h5>{formatMessage(Locale.Attribute.Created)}: {mStart.format(dateFormat)}</h5>
        </div>
        <h4>{title}</h4>
        {/* eslint-disable-next-line react/no-danger */}
        <div className="text" dangerouslySetInnerHTML={{ __html: description || '----' }} />
      </div>
    </div>
  );
};


export default AdvanceCard;

