import { Button, Form, Input, Modal } from 'antd';
import { toLower } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { gql, useMutation } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { StateArray } from '../../../util/StateArrayType';
import { CreateNewSandboxMutationMutation, CreateNewSandboxMutationMutationVariables } from '../../../../gql/typings';

type CreateSandboxModalProps = {
  customerCode: string;
  visibleState: StateArray<boolean>;
  onCreated?: () => void;
};

const CreateSandboxModal: React.FC<CreateSandboxModalProps> = ({
  customerCode,
  visibleState: [visible, setVisible],
  onCreated = () => {},
}) => {
  const intl = useIntl();
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [create] = useMutation<CreateNewSandboxMutationMutation, CreateNewSandboxMutationMutationVariables>(CREATE_MUTATION);

  const onCreate = () => {
    setLoading(true);
    return create({
      variables: { name: value }
    }).finally(() => {
      setVisible(false);
      setLoading(false);
      onCreated();
    });
  };

  return (
    <Modal
      open={visible}
      onCancel={() => !loading && setVisible(false)}
      title="Create new sandbox environment"
      footer={(
        <div>
          <Button onClick={() => setVisible(false)} disabled={loading}>
            {intl.formatMessage(Locale.Command.Cancel)}
          </Button>
          <Button type="primary" onClick={onCreate} loading={loading}>
            {intl.formatMessage(Locale.Command.Create)}
          </Button>
        </div>
      )}
    >
      <Form>
        <Form.Item label={intl.formatMessage(Locale.Attribute.Name)}>
          <Input
            addonBefore="https://"
            value={value}
            onChange={e => setValue(e.target.value)}
            addonAfter={`.${toLower(customerCode)}.pureadvance.com`}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const CREATE_MUTATION = gql`
  mutation CreateNewSandboxMutation($name: String!) {
    deployNewSandbox(heading: $name) {
      id
      heading
    }
  }
`;

export default CreateSandboxModal;
