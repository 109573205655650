import { Tooltip, Typography } from 'antd';
import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { SiteCriteriaOrder } from '../../../../gql/typings';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';


export const siteMailAddressLine2FieldConfig = siteFieldConfig(
  'mailAddressLine2',
  [
    'mailAddress.id',
    'mailAddress.addressLine2',
  ],
  ({ localization }) => ({
    sorting: {
      ascend: SiteCriteriaOrder.MAIL_STREET_ASC,
      descend: SiteCriteriaOrder.MAIL_STREET_DESC,
    },
    title: localization.formatMessage(Locale.Attribute.Mail_address_line_2),
    additionalTableConfig: {
      width: 160
    },
    // className: 'apb-antd-table-column-width-no-wrap',
    render: (record, { isViewingFromPage }) => {
      const addressLine2 = record.mailAddress?.addressLine2;
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={addressLine2}>{addressLine2}</Tooltip>
      </Typography.Paragraph>);
    },
  })
);
