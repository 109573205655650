import React from 'react';
import { gql } from '@apollo/client';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { MergeItemPersonCountryFragment, useUpdatePersonCountryMutation } from '../../../../../../../../../gql/typings';
import Flag, { CountryLabel, FlagCountry } from '../../../../../../../../components/Flag/Flag';
import CountryPicker from '../../../../../../../../components/Input/Fields/CountryPicker';

const AdminMergeItemPersonCountry: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const localization = useLocalization();
  const info = props.item.type as MergeItemPersonCountryFragment;
  const [updateCountry] = useUpdatePersonCountryMutation();

  const displayCountry = (country: FlagCountry) => (
    <span>
      <Flag country={country} rectangle height={24} />
      &nbsp;
      {localization.formatMessage(CountryLabel[country]!)}
    </span>
  );

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.fromCountry}
      toValue={info.toCountry}
      valueDisplayMapper={v => displayCountry(v as FlagCountry)}
      updateMutation={newValue => updateCountry({
        variables: {
          personId: props.into.id,
          newValue,
        },
      })}
      inputRenderer={({ value, onChange }) => <CountryPicker value={value} onChange={onChange} />}
    >
      {localization.formatMessage(Locale.Text.Merge_request_countries_mismatch_description, {
        country1: (
          <span>
            <Flag country={info.fromCountry} rectangle height={24} />
            &nbsp;
            {localization.formatMessage(CountryLabel[info.fromCountry as FlagCountry]!)}
          </span>
        ),
        country2: (
          <span>
            <Flag country={info.toCountry} rectangle height={24} />
            &nbsp;
            {localization.formatMessage(CountryLabel[info.toCountry as FlagCountry])}
          </span>
        ),
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation UpdatePersonCountry($personId: Int!, $newValue: CountryCode!) {
    updatePersonCountry(persId: $personId, country: $newValue) {
      id
      countryCode
      countryEnum
      country {
        code
      }
    }
  }
`;

gql`
  fragment MergeItemPersonCountry on PersonCountry_EntityDataToBeTransferredType {
    code
    entityType
    fromCountry: from
    toCountry: to
  }
`;

export default AdminMergeItemPersonCountry;
