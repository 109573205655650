import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Divider, Form, Modal, Steps } from 'antd';
import { gql, useApolloClient } from '@apollo/client';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import { Optional, RecursivePartial, StateArray } from '../../../util/StateArrayType';
import {
  AddWorkplaceInput,
  SiteInformationForWorkplaceQueryQuery,
  SiteInformationForWorkplaceQueryQueryVariables, SiteInput
} from '../../../../gql/typings';
import CreatePersonAddWorkspaceSiteStep, {
  CreatePersonAddWorkspaceSiteStepRef
} from './steps/CreatePersonAddWorkspaceSiteStep';
import CreatePersonAddWorkspacePositionStep from './steps/CreatePersonAddWorkspacePositionStep';
import CreatePersonAddWorkspaceInformationCard, {
  AddWorkspaceInformationCard,
} from './CreatePersonAddWorkspaceInformationCard';
import { useTableRowSelection } from '../../../components/Table/useTableRowSelection';

type CreatePersonAddWorkspaceProps = {
  countryCode?: string;
  visibleState: StateArray<boolean>;
  initialState?: Optional<RecursivePartial<AddWorkplaceInput>>;
  hidden?: boolean;
  loading?: boolean;
};

export type CreatePersonAddWorkspaceRef = {
  clear: () => void;
};

const CreatePersonAddWorkspace = forwardRef<CreatePersonAddWorkspaceRef, CreatePersonAddWorkspaceProps>(({
  countryCode,
  visibleState: [visible, setVisible],
  initialState,
  hidden,
  loading,
}, ref) => {
  const [form] = Form.useForm<AddWorkplaceInput>();
  const localization = useLocalization();
  const apollo = useApolloClient();
  const siteTableSelection = useTableRowSelection({ virtualSupport: false, type: 'radio' });
  const [currentStep, setCurrentStep] = useState(0);
  const [currentTab, setCurrentTab] = useState<'new'|'existing'>(initialState?.siteInput ? 'new' : 'existing');
  const [cardInfo, setCardInfo] = useState<AddWorkspaceInformationCard>();
  const siteStepRef = useRef<CreatePersonAddWorkspaceSiteStepRef>(null);

  const selectedSiteId = siteTableSelection.selection.selected[0];

  useEffect(() => {
    form.setFields([{ name: 'siteId', value: selectedSiteId }]);
  }, [form, selectedSiteId]);

  const buildSiteCardById = useCallback((siteId: Optional<number>, siteInput: Optional<RecursivePartial<SiteInput>>) => {
    const values = form.getFieldsValue(true);
    const sId = siteId ?? values.siteId ?? -1;
    setCurrentStep(0);
    setCurrentTab('existing');
    apollo.query<SiteInformationForWorkplaceQueryQuery, SiteInformationForWorkplaceQueryQueryVariables>({
      query: SITE_INFO_QUERY,
      variables: {
        siteId: sId,
        positionCode: values.positionCode ?? '',
      },
    }).then(res => {
      let siteName = res.data?.site?.name ?? '';
      if (siteName.length === 0) siteName = siteInput?.departmentName ?? '';
      if (siteName.length === 0) siteName = siteInput?.name ?? '';
      setCardInfo({
        name: siteName,
        countryCode: res.data?.site?.countryCode ?? siteInput?.country,
        positionLabel: res.data?.position?.displayLabel || null,
        missing: false,
      });
    });
  }, [form, apollo]);

  useEffect(() => {
    if (initialState?.siteInput) buildSiteCardById(undefined, initialState.siteInput);
  }, [buildSiteCardById, initialState]);

  const clear = useCallback(() => {
    setTimeout(() => {
      setCurrentStep(0);
      setCurrentTab('existing');
      form.setFields([
        { name: 'siteId', value: undefined },
        { name: 'siteInput', value: undefined },
        { name: 'positionCode', value: undefined },
      ]);
      setVisible(false);
      setCardInfo(undefined);
      siteTableSelection.clearState();
      siteStepRef.current?.clear();
    }, 250);
  }, [form, setVisible, siteTableSelection]);

  
  useImperativeHandle(ref, () => ({ clear }) as CreatePersonAddWorkspaceRef, [clear]);

  return (
    <>
      {!hidden && (
        <CreatePersonAddWorkspaceInformationCard
          form={form}
          cardInfo={cardInfo}
          clear={clear}
          setVisible={setVisible}
        />
      )}
      <Modal
        open={visible}
        width="clamp(580px, 85%, 1400px)"
        style={{ top: 42 }}
        footer={false}
        closable
        onCancel={clear}
      >
        <Steps
          current={currentStep}
          style={{ padding: '18px 32px' }}
          items={[
            {
              title: localization.formatMessage(Locale.Command.Select_Site),
              onClick: () => setCurrentStep(0),
              style: { cursor: 'pointer' }
            },
            {
              title: localization.formatMessage(Locale.Command.Enter_position_information)
            }
          ]}
        />
        <Divider />
        <div style={{ display: visible && currentStep === 1 ? 'inherit' : 'none' }}>
          <CreatePersonAddWorkspacePositionStep
            countryCode={countryCode}
            initialState={initialState}
            form={form}
            clear={clear}
            onFinish={buildSiteCardById}
            loading={loading}
            setVisible={setVisible}
          />
        </div>
        <div style={{ display: visible && currentStep === 0 ? 'inherit' : 'none' }}>
          <CreatePersonAddWorkspaceSiteStep
            ref={siteStepRef}
            form={form}
            initialState={initialState}
            countryCode={countryCode}
            currentStepState={[currentStep, setCurrentStep]}
            currentTabState={[currentTab, setCurrentTab]}
            siteTableSelection={siteTableSelection}
            next={() => setCurrentStep(1)}
          />
        </div>
      </Modal>
    </>
  );
});

const SITE_INFO_QUERY = gql`
  query SiteInformationForWorkplaceQuery($siteId: Int!, $positionCode: String!) {
    site(siteId: $siteId) {
      id
      name
      countryCode
    }
    position(id: $positionCode) {
      code
      type
      displayLabel
    }
  }
`;

export default CreatePersonAddWorkspace;
