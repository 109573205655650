import { EntityTypeEnum } from '../../../gql/typings';
import { TableFieldsConfig } from '../search_old/types';
import { personCountryCodeFieldConfig } from './fields/personCountryCodeFieldConfig';
import { personTitleFieldConfig } from './fields/personTitleFieldConfig';
import { personFullNameFieldConfig } from './fields/personFullNameFieldConfig';
import { personFirstNameFieldConfig } from './fields/personFirstNameFieldConfig';
import { personLastNameFieldConfig } from './fields/personLastNameFieldConfig';
import { personSegmentationFieldConfig } from './fields/personSegmentationFieldConfig';
import { personWorkingSpecialtyFieldConfig } from './fields/personWorkingSpecialtyFieldConfig';
import { personMainSiteNameFieldConfig } from './fields/PersonMainSiteNameFieldConfig';
import { personMainSiteVisitCityFieldConfig } from './fields/personMainSiteVisitCityFieldConfig';
import { personCherryPickFieldConfig } from './fields/personCherryPickFieldConfig';
import { personDateOfBirthFieldConfig } from './fields/personDateOfBirthFieldConfig';
import { personEducationFieldConfig } from './fields/personEducationFieldConfig';
import { personTypeFieldConfig } from './fields/personTypeFieldConfig';
import { personLanguagesFieldConfig } from './fields/personLanguagesFieldConfig';
import { personPrivateAddressFieldConfig } from './fields/personPrivateAddressFieldConfig';
import { buildEntityContactInformationFieldConfigs } from './fields/buildEntityContactInformationFieldConfigs';
import { buildApureBaseIdFieldConfig } from './fields/personApureBaseIdFieldConfig';
import { personCreatedFieldConfig } from './fields/personCreatedFieldConfig';
import { personIdFieldConfig } from './fields/personIdFieldConfig';
import { personImportedFieldConfig } from './fields/personImportedFieldConfig';
import { personMaintainerSourceFieldConfig } from './fields/personMaintainerSourceFieldConfig';
import { personUpdatedFieldConfig } from './fields/personUpdatedFieldConfig';
import { personOriginSourceFieldConfig } from './fields/personOriginSourceFieldConfig';
import { personValidatedFieldConfig } from './fields/personValidatedFieldConfig';
import { buildEntityCustomFieldConfigs } from '../site/fields/buildEntityCustomFieldConfigs';
import { personGenderFieldConfig } from './fields/personGenderFieldConfig';
import { personMainPositionFieldConfig } from './fields/personMainPositionFieldConfig';
import { personSiteNamesFieldConfig } from './fields/personSiteNamesFieldConfig';
import { personExamYearFieldConfig } from './fields/personExamYearFieldConfig';
import { personMainSiteTypeFieldConfig } from './fields/personMainSiteTypeFieldConfig';
import { buildEntityExternalIdFieldConfigs } from '../site/fields/buildEntityExternalIdFieldConfigs';
import { mailToPrivateFieldConfig } from './fields/mailToPrivateFieldConfig';
import { personLinkTherapeuticFocusFieldConfig } from './fields/personLinkTherapeuticFocusFieldConfig';
import { personOptOutClientsFieldConfig } from './fields/personOptOutClientsFieldConfig';
import { personMiddleNameFieldConfig } from './fields/personMiddleNameFieldConfig';
import { personTherapeuticFocusFieldConfig } from './fields/personTherapeuticFocusFieldConfig';


export type SupportedPersonTableColumns =
  'countryCode'
  | 'title'
  | 'fullname'
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'segmentation'
  | 'workingSpecialty'
  | 'education'
  | 'personType'
  | 'dateOfBirth'
  | 'languages'
  | 'privateAddress'
  | 'contactInformation'
  | 'created'
  | 'updated'
  | 'imported'
  | 'validated'
  | 'id'
  | 'aPureBaseId'
  | 'originSource'
  | 'maintainerSource'
  | 'customField'
  | 'gender'
  | 'mainPosition'
  | 'mainSiteName'
  | 'mainSiteVisitCity'
  | 'mainSiteType'
  | 'siteNames'
  | 'examYear'
  | 'externalId'
  | 'cherryPick'
  | 'mailToPrivate'
  | 'therapeuticFocus'
  | 'linkTherapeuticFocus'
  | 'optOutClients';
export const supportedPersonTableColumns: TableFieldsConfig<SupportedPersonTableColumns>['fields'] = {
  id: personIdFieldConfig,
  aPureBaseId: buildApureBaseIdFieldConfig(EntityTypeEnum.PERSON),
  countryCode: personCountryCodeFieldConfig,
  title: personTitleFieldConfig,
  fullname: personFullNameFieldConfig,
  middleName: personMiddleNameFieldConfig,
  firstName: personFirstNameFieldConfig,
  lastName: personLastNameFieldConfig,
  segmentation: personSegmentationFieldConfig,
  workingSpecialty: personWorkingSpecialtyFieldConfig,
  cherryPick: personCherryPickFieldConfig,
  dateOfBirth: personDateOfBirthFieldConfig,
  education: personEducationFieldConfig,
  personType: personTypeFieldConfig,
  languages: personLanguagesFieldConfig,
  privateAddress: personPrivateAddressFieldConfig,
  contactInformation: buildEntityContactInformationFieldConfigs(EntityTypeEnum.PERSON),
  created: personCreatedFieldConfig,
  updated: personUpdatedFieldConfig,
  imported: personImportedFieldConfig,
  maintainerSource: personMaintainerSourceFieldConfig,
  originSource: personOriginSourceFieldConfig,
  validated: personValidatedFieldConfig,
  customField: buildEntityCustomFieldConfigs(EntityTypeEnum.PERSON),
  gender: personGenderFieldConfig,
  siteNames: personSiteNamesFieldConfig,
  mainPosition: personMainPositionFieldConfig,
  mainSiteName: personMainSiteNameFieldConfig,
  mainSiteVisitCity: personMainSiteVisitCityFieldConfig,
  mainSiteType: personMainSiteTypeFieldConfig,
  examYear: personExamYearFieldConfig,
  externalId: buildEntityExternalIdFieldConfigs(EntityTypeEnum.PERSON),
  mailToPrivate: mailToPrivateFieldConfig,
  therapeuticFocus: personTherapeuticFocusFieldConfig,
  linkTherapeuticFocus: personLinkTherapeuticFocusFieldConfig,
  optOutClients: personOptOutClientsFieldConfig,
};

