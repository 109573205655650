import React from 'react';
import { Form, Skeleton } from 'antd';
import { useIntl } from 'react-intl';
import { Locale } from '../../../../localization/LocalizationKeys';
import { ReportControllerType } from '../controller/reportController';
import { DynamicReportInput } from './dynamicReportInput/DynamicReportInput';
import ContentSection from '../../../components/ContentSection/ContentSection';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

/**
 *
 * @param report
 * @param {ReportController} controller
 * @return {*}
 * @constructor
 */
const ReportInput: React.FC<{ controller: ReportControllerType }> = ({
  controller,
}) => {
  const { loading, inputDefs } = controller.getInputData();
  const { formatMessage } = useIntl();
  return (
    <div className="report-input-container">
      <Skeleton loading={loading} active={loading}>
        {!loading && (
          <Form form={controller.form} {...formItemLayout}>
            <ContentSection
              title={formatMessage(Locale.Command.Input_for, { name: controller.selectedReport?.heading })}
              tooltip={formatMessage(Locale.Text.Populate_these_inputs_to_generate_the_selected_report)}
            >
              {inputDefs.map(def => {
                const ToRender = DynamicReportInput[def.key];
                return <ToRender key={def.key} inputDef={def} controller={controller} />;
              })}
            </ContentSection>
          </Form>
        )}
      </Skeleton>
    </div>
  );
};


export default ReportInput;
