import React from 'react';
import { Form } from 'antd';
import { useIntl } from 'react-intl';
import MainView from '../../components/View/MainView';
import { EntitiesSearch } from '../../components/entitiesSearch';
import { usePageTitle } from '../../hooks/usePageTitle';
import { Locale } from '../../../localization/LocalizationKeys';
import { useSiteTableQuickActions } from './hooks/useSiteTableQuickActions';
import { EntityTypeEnum } from '../../../gql/typings';
import { useSystemPermission } from '../../permission/useSystemPermission';

const SiteView = () => {
  const { formatMessage } = useIntl();
  usePageTitle(formatMessage(Locale.Attribute.Site_HCO));
  const [form] = Form.useForm();

  const { entitiesSearchProps, tableSelection, HiddenRender } = useSiteTableQuickActions(form, { maintainPageState: true });

  const hasGlobalSearchPermission = useSystemPermission('globalSearch');


  return (
    <MainView className="site-view-container" boundingBox={false}>
      <EntitiesSearch
        form={form}
        entityType={EntityTypeEnum.SITE}
        tableSelection={tableSelection}
        {...entitiesSearchProps}
        globalSupport
        globalSearch={hasGlobalSearchPermission}
        urlSearchEnabled
      />
      <HiddenRender />
    </MainView>
  );
};

export default SiteView;
