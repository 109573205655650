import React from 'react';
import { Button, Divider, Empty, TreeSelect, Typography } from 'antd';
import {
  GridCardsDataElement,
  SupportGridCardEntityTypes,
  useGridRightCardsData
} from './useGridRightCardsData';
import { useLocalization } from '../../../../../util/useLocalization';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { isActualNumber } from '../../../../../util/Util';
import DraggableTable from '../../../../../components/DraggableTable/DraggableTable';
import { DynamicList } from '../../../../../util/AHookTypes';
import { TableColumnTreeFnReturn } from '../../../../../components/ConfigureTableColumnSettings/useTableColumnTreeDataFn';

type AdminGridConfigureCardsTableProps = {
  entityType: SupportGridCardEntityTypes;
  itemConfigurationId: number;
  mainBoilerplate: string[];
  metaBoilerplate: string[];
};

const AdminGridConfigureCardsTable: React.FC<AdminGridConfigureCardsTableProps> = ({
  itemConfigurationId,
  entityType,
  mainBoilerplate,
  metaBoilerplate,
}) => {
  const localization = useLocalization();
  const {
    loading,
    isUpdating,
    onListUpdate,
    metaTreeData,
    mainTreeData,
    dynamicMeta,
    dynamicMain,
    HeadingRenderer,
  } = useGridRightCardsData(entityType, itemConfigurationId);

  const Table = (props: {
    type: 'main'|'meta';
    dynamicList: DynamicList<GridCardsDataElement>;
    treeData: TableColumnTreeFnReturn;
  }) => (
    <DraggableTable<GridCardsDataElement>
      loading={loading}
      dynamicList={props.dynamicList}
      size="small"
      pagination={false}
      onListUpdate={onListUpdate(props.type)}
      draggable
      columns={[
        {
          title: localization.formatMessage(Locale.Attribute.Column_key),
          width: '20%',
          render: (_, { id }) => <Typography.Text code>{id}</Typography.Text>,
        },
        {
          title: localization.formatMessage(Locale.Attribute.Column),
          render: (_, record) => (
            <TreeSelect
              value={isActualNumber(record.id) && record.id == -1 ? undefined : record.id}
              dropdownMatchSelectWidth={false}
              placeholder={localization.formatMessage(Locale.Command.Please_select_column)}
              treeData={props.treeData(record)}
              showSearch
              onChange={e => {
                const index = props.dynamicList.list.map(e => e.id).indexOf(record.id);
                const newList = [...props.dynamicList.list];
                const prev = newList[index]!;

                newList[index] = {
                  id: e,
                  fieldKey: e,
                  isHeadingKey: prev.isHeadingKey,
                  heading: prev.heading,
                };

                props.dynamicList.resetList(newList);
                onListUpdate(props.type)(newList);
              }}
            />
          )
        },
        {
          title: localization.formatMessage(Locale.Attribute.Heading),
          render: (_, record) => <HeadingRenderer record={record} type={props.type} />,
        },
        {
          width: '20%',
          render: (_, { id }) => (
            <Button
              danger
              type="primary"
              loading={isUpdating}
              onClick={() => {
                const newList = props.dynamicList.list.filter(i => i.id !== id);
                onListUpdate(props.type)(newList);
                props.dynamicList.resetList(newList);
              }}
            >
              {localization.formatMessage(Locale.Command.Remove)}
            </Button>
          )
        }
      ]}
      footer={() => (
        <Button
          type="primary"
          onClick={() => props.dynamicList.push({ id: '-1', heading: null, fieldKey: '-1', isHeadingKey: false })}
          disabled={props.dynamicList.list.map(l => l.id).indexOf('-1') >= 0}
        >
          {localization.formatMessage(Locale.Command.Add_new_column)}
        </Button>
      )}
      locale={{ emptyText: (
        <Empty description="No fields configured">
          <Button
            type="primary"
            onClick={() => {
              let fields: GridCardsDataElement[] = [];
              let list = dynamicMain;
              if (props.type === 'main') {
                list = dynamicMain;
                fields = mainBoilerplate.map(f => ({ id: f, fieldKey: f, heading: null, isHeadingKey: false }));
              }
              if (props.type === 'meta') {
                list = dynamicMeta;
                fields = metaBoilerplate.map(f => ({ id: f, fieldKey: f, heading: null, isHeadingKey: false }));
              }
              list.resetList(fields);
              onListUpdate(props.type)(fields);
            }}
          >
            Use boilerplate
          </Button>
        </Empty>
      ) }}
    />
  );

  return (
    <div>
      <Typography.Title level={4}>Main Fields</Typography.Title>
      <Table treeData={mainTreeData} dynamicList={dynamicMain} type="main" />
      <Divider />
      <Typography.Title level={4}>Drawer Fields</Typography.Title>
      <Table treeData={metaTreeData} dynamicList={dynamicMeta} type="meta" />
    </div>
  );
};

export default AdminGridConfigureCardsTable;
