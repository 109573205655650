import React from 'react';
import { Col, Row } from 'antd';
import { gql, useQuery } from '@apollo/client';
import CardWithFields from '../../components/Card/CardWithFields';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';
import { EntityTabCardsProps } from '../../components/EntityTabs/Cards/EntityTabCards';
import { EntityTypeEnum, LinkCardsContainerQueryQuery, LinkCardsContainerQueryQueryVariables } from '../../../gql/typings';
import { FIELD_KEY_FRAGMENT } from '../../util/useFieldKeysTransformed';

type LinkCardsContainerProps = {
  linkId: number;
  entityGridItemConfiguration: EntityTabCardsProps['configuration'];
};

const LinkCardsContainer: React.FC<LinkCardsContainerProps> = ({
  linkId,
  entityGridItemConfiguration,
}) => {
  const localization = useLocalization();
  const { data } = useQuery<LinkCardsContainerQueryQuery, LinkCardsContainerQueryQueryVariables>(DATA_QUERY, {
    variables: { linkId, entityGridItemConfigurationId: entityGridItemConfiguration.id },
  });

  return (
    <Row gutter={[16, 8]}>
      <Col id="person-card" sm={24} md={8} lg={8} xl={8} xxl={6}>
        {data?.sitePersonAffiliation && data?.cardFieldKeys && <CardWithFields
          entityType={EntityTypeEnum.AFFILIATION}
          rootEntityType={EntityTypeEnum.AFFILIATION}
          icon="POSITION"
          title={localization.formatMessage(Locale.General.Work_Information)}
          recordId={linkId}
          fields={data.cardFieldKeys.link.fields}
          secondaryFields={data.cardFieldKeys.link.drawerFields}
          size={entityGridItemConfiguration.height}
        />}
      </Col>
      <Col id="person-card" sm={24} md={8} lg={8} xl={8} xxl={6}>
        {data?.sitePersonAffiliation?.person && data.cardFieldKeys && <CardWithFields
          icon="PERSON"
          entityType={EntityTypeEnum.PERSON}
          rootEntityType={EntityTypeEnum.AFFILIATION}
          title={localization.formatMessage(Locale.General.Person_Information)}
          recordId={data?.sitePersonAffiliation?.person.id}
          fields={data.cardFieldKeys.person.fields}
          secondaryFields={data.cardFieldKeys.person.drawerFields}
          size={entityGridItemConfiguration.height}
        />}
      </Col>
      <Col id="person-card" sm={24} md={8} lg={8} xl={8} xxl={6}>
        {data?.sitePersonAffiliation?.site && data.cardFieldKeys && <CardWithFields
          entityType={EntityTypeEnum.SITE}
          rootEntityType={EntityTypeEnum.AFFILIATION}
          icon="SITE"
          title={localization.formatMessage(Locale.General.Site_Information)}
          recordId={data?.sitePersonAffiliation?.site.id}
          fields={data.cardFieldKeys.site.fields}
          secondaryFields={data.cardFieldKeys.site.drawerFields}
          size={entityGridItemConfiguration.height}
        />}
      </Col>
    </Row>
  );
};

const DATA_QUERY = gql`
  query LinkCardsContainerQuery($linkId: Int!, $entityGridItemConfigurationId: Int!) {
    cardFieldKeys(entityGridItemConfigurationId: $entityGridItemConfigurationId) {
      entityGridItemConfigurationId
      person {
        entityGridItemConfigurationId
        entityType
        fields { ...GridCardField }
        drawerFields { ...GridCardField }
      }
      link {
        entityGridItemConfigurationId
        entityType
        fields { ...GridCardField }
        drawerFields { ...GridCardField }
      }
      site {
        entityGridItemConfigurationId
        entityType
        fields { ...GridCardField }
        drawerFields { ...GridCardField }
      }
    }
    sitePersonAffiliation(id: $linkId) {
      id
      position {
        code
        type
        label
      }
      person {
        id
        fullName
      }
      site {
        id
        name
      }
    }
  }
  ${FIELD_KEY_FRAGMENT}
`;

export default LinkCardsContainer;
