import React from 'react';
import { Typography } from 'antd';
import GlowButton from '../../../../Input/GlowButton';
import { useLocalization } from '../../../../../util/useLocalization';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import pillGreyIcon from '../../../../../../img/icon-pill-grey.svg';
import { PersonDetailSegmentationTabQueryQuery } from '../../../../../../gql/typings';

type ProductSegmentationCodeCardProps = NonNullable<
PersonDetailSegmentationTabQueryQuery['person']>['productSegmentations']['nodes']['0'] & {
  onRemove?: (() => void)|false|null;
};

export const ProductSegmentationCodeCard: React.FC<ProductSegmentationCodeCardProps> = ({
  onRemove,
  product,
  segmentation
}) => (
  <div className="product-segmentation-code-card-container">
    <div className="card">
      <div className="pill-wrapper">
        <img src={pillGreyIcon} alt="pill" />
        <span className="badge">{segmentation?.heading}</span>
      </div>
      <Typography.Title level={5}>
        {product?.heading}
      </Typography.Title>
    </div>
    {onRemove && <span className="remove" onClick={onRemove}>Remove</span>}
  </div>
);

export const ProductSegmentationCodeAddCard: React.FC<{ loading: boolean; onClick: () => void }> = ({
  onClick,
  loading,
}) => {
  const localization = useLocalization();
  return (
    <div className="product-segmentation-code-add-card-container" onClick={() => { if (!loading) onClick(); }}>
      <GlowButton
        type="primary"
        loading={loading}
        label={localization.formatMessage(Locale.Command.Add)}
        size="x-small"
      />
    </div>
  );
};

