import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import {
  MergeItemSiteIsUnplacedFragment,
  MRUpdateIsUnplacedMutation,
  MRUpdateIsUnplacedMutationVariables
} from '../../../../../../../../../gql/typings';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';


const AdminMergeItemSiteIsUnplaced: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemSiteIsUnplacedFragment;
  const localization = useLocalization();
  const [update] = useMutation<MRUpdateIsUnplacedMutation, MRUpdateIsUnplacedMutationVariables>(UPDATE_MUTATION);

  return (
    <AdminMergeItemBasicConflictField<boolean>
      {...props}
      fromValue={info.isUnplacedFrom}
      toValue={info.isUnplacedTo}
      valueDisplayMapper={v => v ? 'true' : 'false'}
      updateMutation={newValue => update({
        variables: { siteId: props.from.id, isUnplaced: newValue },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_is_unplaced_mismatch_description)}
    </AdminMergeItemBasicConflictField>
  );
};

const UPDATE_MUTATION = gql`
  mutation MRUpdateIsUnplaced($siteId: ID!, $isUnplaced: Boolean!) {
    setSiteIsUnplaced(siteId: $siteId, isUnplaced: $isUnplaced) {
      id
      isUnplaced
    }
  }
`;

gql`
  fragment MergeItemSiteIsUnplaced on SiteIsUnplaced_EntityDataToBeTransferredType {
    code
    entityType
    isUnplacedFrom: from
    isUnplacedTo: to
  }
`;

export default AdminMergeItemSiteIsUnplaced;
