import { useQuery, gql } from '@apollo/client';
import React from 'react';
import { Checkbox, Col, Row, Skeleton, Table, Tooltip } from 'antd';
import { get, reduce } from 'lodash';
import { useIntl } from 'react-intl';
import { Locale } from '../../../../localization/LocalizationKeys';
import Select from '../../../components/Input/Select';
import { FileValidationQueryQuery, FileValidationQueryQueryVariables } from '../../../../gql/typings';


const ValidationOfFile: React.FC<{ projectId: number }> = ({ projectId }) => {
  const intl = useIntl();

  const { data, loading } = useQuery<FileValidationQueryQuery, FileValidationQueryQueryVariables>(DATA_QUERY, {
    variables: { projectId },
  });

  if (loading || !data?.importProject) return (
    <Skeleton active loading paragraph={{ rows: 12 }} />
  );

  const lineColumns = data.importProject.linePreview.columns.map((col, index) => ({
    key: get(col, 'code', index),
    dataIndex: index,
    title: (
      <Tooltip title={col && col.note}>
        {get(col, 'label', 'Skipped')}
      </Tooltip>
    ),
  }));


  const lineDataSource = data.importProject.linePreview.values.map(line => reduce(line.values, (acc, value, index) => ({
    ...acc,
    [index]: value.value,
  }), { key: line.key }));

  return (
    <div>
      <Row gutter={48} align="middle" justify="center" style={{ marginBottom: 24 }}>
        <Col span={7}>
          <h2>{intl.formatMessage(Locale.Text.Input_file_preview)}</h2>
        </Col>
        <Col span={6}>
          {intl.formatMessage(Locale.General.Charset)}
          <Select
            selected={data.importProject.linePreview.charset}
            disabled
            items={data.charsets.map(charset => ({
              id: charset,
              label: charset,
            }))}
          />
        </Col>
        <Col span={6}>
          {intl.formatMessage(Locale.Attribute.Delimiter)}
          <Select
            selected={data.importProject.linePreview.delimiter}
            disabled
            items={data.delimiters.map(delimiter => ({
              id: delimiter,
              label: delimiter,
            }))}
          />
        </Col>
        <Col span={5}>
          {intl.formatMessage(Locale.General.First_line_header)}: &nbsp;
          <Checkbox checked={data.importProject.linePreview.firstLineHeader} disabled />
        </Col>
      </Row>
      <Row>
        <Table
          columns={lineColumns}
          dataSource={lineDataSource}
          pagination={false}
          scroll={{ x: true }}
        />
      </Row>
    </div>
  );
};

const DATA_QUERY = gql`
  query FileValidationQuery($projectId:Int!) {
    delimiters
    charsets
    importProject(id: $projectId) {
      id
      linePreview(linesCount: 5) {
        projectId
        charset
        delimiter
        firstLineHeader
        columns {
          code
          label
          note
        }
        values {
          key
          values {
            index
            code
            value
          }
        }
      }
    }
  }
`;

export default ValidationOfFile;
