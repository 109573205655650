import React from 'react';
import { Table, Typography } from 'antd';
import { NavLink } from 'react-router-dom';
import { Locale } from '../../../localization/LocalizationKeys';
import { useEntityRecord } from '../../hooks/useEntityRecord';
import { EntityTypeEnum } from '../../../gql/typings';
import { useLocalization } from '../../util/useLocalization';
import { DATE_FORMAT } from '../../util/format';

type EntityMergeModalSelectRecordStepProps = {
  ids: number[];
  entityType: EntityTypeEnum.PERSON|EntityTypeEnum.SITE;
  setWinnerId: (winnerId: number) => void;
};

const EntityMergeModalSelectRecordStep: React.FC<EntityMergeModalSelectRecordStepProps> = ({
  ids,
  entityType,
  setWinnerId,
}) => {
  const localization = useLocalization();
  const { records, loading } = useEntityRecord(entityType, ids);

  return (
    <div>
      <Typography.Paragraph>
        Please select the winner record from below.
      </Typography.Paragraph>
      <Table
        loading={loading}
        rowKey='id'
        pagination={false}
        rowSelection={{
          type: 'radio',
          onSelect: r => setWinnerId(r.id),
        }}
        dataSource={records ?? []}
        columns={[
          {
            key: 'id',
            title: localization.formatMessage(Locale.Attribute.ID),
            render: (_, r) => <NavLink to={r.url} target="_blank">{r.id}</NavLink>,
          },
          {
            key: 'name',
            title: localization.formatMessage(Locale.Attribute.Name),
            render: (_, r) => r.name,
          },
          {
            key: 'updated',
            title: localization.formatMessage(Locale.Attribute.Updated),
            render: (_, r) => r.ut.format(DATE_FORMAT),
          },
          {
            key: 'created',
            title: localization.formatMessage(Locale.Attribute.Created),
            render: (_, r) => r.ct.format(DATE_FORMAT),
          },
        ]}
      />
    </div>
  );
};

export default EntityMergeModalSelectRecordStep;
