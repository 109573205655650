import React from 'react';
import { useIntl } from 'react-intl';
import { isEqual } from 'lodash';
import { DashboardItemProps } from './DashboardItemTypes';
import DashboardPieItem from './items/EntityPie/DashboardPieItem';
import DashboardRecordsImportedItem from './items/DashboardRecordsImportedItem';
import DashboardMasterDataOverviewItem from './items/DashboardMasterDataOverviewItem';
import DashboardCherryPickOverviewItem from './items/DashboardCherryPickOverviewItem';
import DashboardCard from './DashboardCard';
import DashboardDcrStatusItem from './items/DashboardDcrStatusItem';

const types: Record<string, React.FC<DashboardItemProps>> = {
  APB_ENTITY_PIE: DashboardPieItem,
  APB_RECORD_IMPORTED: DashboardRecordsImportedItem,
  APB_MASTERDATA_OVERVIEW: DashboardMasterDataOverviewItem,
  APB_CHERRY: DashboardCherryPickOverviewItem,
  APB_DCR: DashboardDcrStatusItem,
};

const DashboardItem: React.FC<DashboardItemProps> = React.memo((props) => {
  const intl = useIntl();
  const ToDisplay = types[props.configuration.dashboardItemCode] ?? (() => {
    const key = props.configuration.headingKey ?? props.configuration.dashboardItem.headingKey;
    return (
      <DashboardCard
        editMode={props.editStatus}
        title={intl.formatMessage({ id: key, defaultMessage: key })}
      >
        Unknown Dashboard Item Type: '{props.configuration.dashboardItemCode}'
      </DashboardCard>
    );
  });

  return (
    <ToDisplay {...props} />
  );
}, isEqual);

export default DashboardItem;
