import { FormInstance } from 'antd/lib/form/Form';
import React, { useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Form, message } from 'antd';
import { TableActionItem } from '../../../components/Table/Actions';
import useCreateListActions, { CreateListActionProps } from '../../list/CreateList/useCreateListActions';
import BulkPersonsToActivity from '../Components/BulkPersonsToActivity';
import { EntitiesSearchQuickActionItem } from '../../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import { Locale } from '../../../../localization/LocalizationKeys';
import { TableRowSelectionReturnProps, useTableRowSelection } from '../../../components/Table/useTableRowSelection';
import { ActivityDetailsInitialState } from '../../activity/ActivityDetails/ActivityDetailsTypes';
import multiCherryPickConfirmBtnAction from '../MultiCherryPickConfirmBtnAction';
import {
  ActivityPersonSelectionIds_DATA_QUERY,
} from '../../activity/ActivityDetails/ActivityPersons/useActivityPersonSelection';
import { useLocalization } from '../../../util/useLocalization';
import MassAssign from '../../massAssign/MassAssign';
import {
  ActivityPersonSelectionIdsQueryQuery, ActivityPersonSelectionIdsQueryQueryVariables,
  EntityTypeEnum,
  SelectionCriteriaStyle
} from '../../../../gql/typings';
import EntityMergeModal from '../../../components/EntityMerge/EntityMergeModal';
import { usePermission } from '../../../permission/usePermission';
import { EntitiesSearchProps } from '../../../components/entitiesSearch';


type PersonTableQuickActionsOptions = {
  activity?: boolean;
  listId?: number;
  dynamicListSupport?: CreateListActionProps['dynamicListSupport'];
  maintainPageState?:boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EntityTableQuickActions<T = any> = (form?: FormInstance, options?: T) => {
  HiddenRender: React.FC;
  tableSelection: TableRowSelectionReturnProps;
  entitiesSearchProps: Pick<EntitiesSearchProps, 'actions'|'quickActions'>;
};


export const usePersonTableQuickActions: EntityTableQuickActions<PersonTableQuickActionsOptions> = (
  form: FormInstance = Form.useForm()[0],
  {
    activity = true,
    listId,
    dynamicListSupport,
    maintainPageState
  }: PersonTableQuickActionsOptions = {},
) => {
  const localization = useLocalization();
  const apollo = useApolloClient();
  const navigate = useNavigate();
  const showMassassignState = useState(false);
  const showMergeRequestState = useState(false);
  const showMassAssignPersonsToActivityState = useState(false);

  const { create: allowCreateMergeRequest } = usePermission('MERGE_RECORD');

  const rowSelectionProps = useMemo(() => {
    if (listId) return { listId, entityType: EntityTypeEnum.PERSON };
    if (!form) return { entityType: EntityTypeEnum.PERSON };
    return { form };
  }, [form, listId]);

  const tableSelection = useTableRowSelection({
    ...rowSelectionProps,
    ...(maintainPageState && { maintainPageState: { entityType: EntityTypeEnum.PERSON } })
  });
  const [createListAction, CreateListElement] = useCreateListActions({
    dynamicListSupport,
    entityType: EntityTypeEnum.PERSON,
    selection: tableSelection.selection,
  });


  const actions:Array<boolean|TableActionItem> = [
    {
      key: 'mass-assign',
      label: localization.formatMessage(Locale.Attribute.Mass_assign),
      onClick: () => showMassassignState[1](true),
      disabled: tableSelection.selection.count === 0,
    },
    {
      key: 'merge-request',
      label: localization.formatMessage(
        tableSelection.selection.count === 1
          ? Locale.Command.Merge_record
          : Locale.Command.Merge_records
      ),
      hide: !allowCreateMergeRequest,
      disabled: tableSelection.selection.count === 0 || tableSelection.selection.count > 2,
      onClick: () => showMergeRequestState[1](true),
    },
    activity && {
      key: 'activities',
      label: localization.formatMessage(Locale.General.Activity),
      items: [
        {
          key: 'add-to-existing-activity',
          label: localization.formatMessage(Locale.Text.Add_people_to_activity),
          tooltip: localization.formatMessage(Locale.Text.Add_selected_persons_to_new_Activity),
          disabled: tableSelection.selection.count === 0,
          onClick: () => {
            if (tableSelection.selection.count > 2000) return message.warning(
              localization.formatMessage(Locale.Text.Activity_add_persons_max_exceeded, {
                selectedAmount: tableSelection.selection.count,
              }),
              4,
            );
            showMassAssignPersonsToActivityState[1](true);
          },
        },
        {
          key: 'add-to-new-activity',
          label: localization.formatMessage(Locale.Text.Add_to_new_Activity),
          disabled: tableSelection.selection.count === 0,
          onClick: () => {
            if (tableSelection.selection.count > 2000) return message.warning(
              localization.formatMessage(Locale.Text.Activity_add_persons_max_exceeded, {
                selectedAmount: tableSelection.selection.count,
              }),
              4,
            );

            if (tableSelection.selection.style === SelectionCriteriaStyle.STANDARD) {
              const state: ActivityDetailsInitialState = { personIds: tableSelection.selection.selected };
              navigate('/activity/create', { state });
            } else {
              // TODO: Should have some loading indicator
              apollo.query<ActivityPersonSelectionIdsQueryQuery, ActivityPersonSelectionIdsQueryQueryVariables>({
                query: ActivityPersonSelectionIds_DATA_QUERY,
                variables: { selection: tableSelection.selection },
              }).then(res => {
                const state: ActivityDetailsInitialState = { personIds: res.data.selectionToIds };
                navigate('/activity/create', { state });
              });
            }
          },
        },
      ],
    },
    !listId && createListAction,
  ];

  return {
    HiddenRender: () => (
      <>
        <CreateListElement />
        <MassAssign
          visibleState={showMassassignState}
          selection={tableSelection.selection}
          entityType={EntityTypeEnum.PERSON}
        />
        {tableSelection.selection.selected[0] && <EntityMergeModal
          visibleState={showMergeRequestState}
          entityType={EntityTypeEnum.PERSON}
          fromId={tableSelection.selection.selected[0]}
          intoId={tableSelection.selection.selected[1]}
        />}
        <BulkPersonsToActivity
          visibleState={showMassAssignPersonsToActivityState}
          selection={tableSelection.selection}
        />
      </>
    ),
    tableSelection,
    entitiesSearchProps: {
      actions: actions.filter(e => e) as EntitiesSearchQuickActionItem[],
      quickActions: [
        multiCherryPickConfirmBtnAction(tableSelection.selection),
      ].filter(e => e) as EntitiesSearchQuickActionItem[],
    },
  };


};
