import React, { useMemo } from 'react';
import { flatMap, flatten, identity, isArray, isFunction, isNil, isNumber, isEqual } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { ColumnsType, SortOrder } from 'antd/lib/table/interface';
// eslint-disable-next-line import/no-extraneous-dependencies
import { gql, useQuery } from '@apollo/client';
import { NavigateFunction } from 'react-router/lib/hooks';
import { TableProps } from 'antd';
import { useBroadcastStorage } from '../../../util/useBroadcastStorage';
import { generateGraphQL } from '../../../util/graphqlUtils';
import { LocalizationShape, useLocalization } from '../../../util/useLocalization';
import { EntitySearchColumnParamType } from '../../../browse/search_old/types/EntitySearchColumnParamType';
import {
  entitiesTableConfigs,
  SupportedEntitySearchTypes,
  TableConfig,
  TableFieldReturnedRecordOptions,
  TableFieldReturnedRecordPageType,
  TableFieldsConfig,
  UseTableColumns
} from '../../../browse/search_old/types';
import { useFieldOptionAttributes } from '../../Card/useFieldOptionAttributes';
import { FieldItemConfigs } from '../../FieldLabel/FieldLabel';
import { EntityTypeEnum, TableColumnsQueryQuery, TableColumnsQueryQueryVariables } from '../../../../gql/typings';
import { Locale } from '../../../../localization/LocalizationKeys';

export const generateTableColumnsSortingKey = (entityType: EntityTypeEnum) => `${entityType}-table-sorting`;
export const generateTableColumnsFieldsKey = (entityType: EntityTypeEnum) => `${entityType}-table-columns`;

export function orderingFiltering<F extends string = string>(
  entityType: SupportedEntitySearchTypes,
  navigate: NavigateFunction,
  localization: LocalizationShape,
): (initialLocalStorage: SortingStorage<F>[]) => SortingStorage<F>[] {
  const columnConfig = entitiesTableConfigs[entityType].columnConfig as TableFieldsConfig<F>;
  return initialLocalStorage => {
    if (initialLocalStorage && initialLocalStorage.length > 0) {
      const supported = flatten(flatMap(columnConfig.fields, f => {
        const columnConf = f({ navigate, localization });
        return [columnConf.sorting?.ascend, columnConf.sorting?.descend];
      })).filter(identity);
      return initialLocalStorage.filter(ns => supported.includes(ns.currentEnum));
    }
    return initialLocalStorage;
  };
}

export type SortingStorage<F = string> = {
  key: F;
  direction: 'ascend'|'descend';
  currentEnum: string;
};

export function useTableColumns<F extends string = string>(
  entityType: SupportedEntitySearchTypes,
  props?: EntitySearchColumnParamType,
): UseTableColumns<F> {
  const config: TableConfig = entitiesTableConfigs[entityType];
  const configFields = config.columnConfig.fields;

  const navigate = useNavigate();
  const localization = useLocalization();
  const { data } = useQuery<TableColumnsQueryQuery, TableColumnsQueryQueryVariables>(TABLE_COLUMNS_DATA_QUERY, {
    variables: { entityType },
  });
  const [sorting, setSorting] = useBroadcastStorage<SortingStorage<F>[]>(
    generateTableColumnsSortingKey(entityType),
    [],
    orderingFiltering(entityType, navigate, localization),
  );
  const fields = data?.viewer?.tableColumns?.map(tc => tc.columnKey);
  const { optionMapGet, rawMap, items } = useFieldOptionAttributes(entityType, fields ?? [], props);

  return useMemo(() => {
    // TODO: Add support for fields to be always included. Example cherrypick icon
    const selected: FieldItemConfigs<string>[] = items
      ?.filter(([f, { keyCode }]) => {
        const found = configFields[f.key as F];
        // eslint-disable-next-line no-console
        if (!found) console.warn(`Could not find the configuration for field ${f}`);
        return found;
      }) || [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onTableChange: TableProps<any>['onChange'] = (pagination, filters, sorters) => {
      const keyIndex: { [key: string]: number } = {};

      sorting.forEach((item, index) => keyIndex[item.key] = index);

      const newSorting = flatten((isArray(sorters) ? sorters : [sorters]).map(s => {
        // @ts-ignore
        const enums = s.column?.sorting?.[s.order] as null|string|string[];
        if (!enums) return [];
        return (typeof enums === 'string' ? [enums] : enums).map(e => ({
          key: s.columnKey as F,
          direction: s.order as Exclude<SortOrder, null>,
          currentEnum: e,
        }));
      }));

      const mutateArray: SortingStorage<F>[] = [];

      newSorting.forEach((item, index) => {
        const indexedKey = keyIndex[item.key];
        if (isNumber(indexedKey) && indexedKey !== undefined) {
          mutateArray[indexedKey] = item;
        } else {
          mutateArray[index] = item;
        }
      });

      if (!isEqual(newSorting, sorting)) setSorting(mutateArray.filter(item => item));
    };

    const testing = selected?.map(([field, { keyCode }], i) => {
      const Render: React.FC<{ record: object; options: TableFieldReturnedRecordOptions }> = ({ record, options }) => <span>
        {field.render(record, options)}
      </span>;
      const renderOptions: TableFieldReturnedRecordOptions = {
        isViewingFromPage: TableFieldReturnedRecordPageType.VIEW_PAGE,
        isViewingFromEntity: entityType,
        refetchData: () => {
          throw Error('Refetch is currently not supported');
        },
      };
      const additionalTableConfig = field.additionalTableConfig ?? {};
      if (!field.sorting) {
        if (React.isValidElement(field.title)) return {
          ...field,
          ...additionalTableConfig,
          title: field.title,
          render: (_, record: object) => <Render record={record} options={renderOptions} />,
        };
        if (isFunction(field.title)) {
          const selectedOption = optionMapGet(field.key)?.filter(e => e.code === keyCode)[0];
          if (!selectedOption) return field;
          const optionProps = { ...renderOptions, selectedOption };
          return {
            ...field,
            ...additionalTableConfig,
            title: field.title(optionProps),
            render: (_, r: object) => <Render record={r} options={optionProps} />,
          };
        }
        return {
          ...field,
          ...additionalTableConfig,
          title: <span>{field.title}</span>,
          render: (_, record: object) => <Render record={record} options={renderOptions} />,
        };
      }
      const sortIndex = sorting.map(s => s.key).indexOf(field.key as F);
      const showIndex = sorting.length > 1 && sortIndex !== -1;
      const sortTitle = sortIndex === -1
        ? localization.formatMessage(Locale.Command.Click_to_sort_ascending)
        : sorting[sortIndex]?.direction === 'ascend'
          ? localization.formatMessage(Locale.Command.Click_to_sort_descending)
          : localization.formatMessage(Locale.Command.Click_to_cancel_sorting);
      return {
        ...field,
        ...additionalTableConfig,
        sorter: { multiple: i },
        sortOrder: sorting[sortIndex]?.direction,
        showSorterTooltip: {
          title: sortTitle,
          placement: 'bottom'
        },
        title: () => (
          <div className="use-table-columns-title-container">
            <span className="column-header">{field.title}</span>
            <span className="column-sorting">&nbsp;&nbsp;{showIndex ? sortIndex + 1 : ''}</span>
          </div>
        ),
        render: (_, record: object) => <Render record={record} options={renderOptions} />,
      };
    }) as ColumnsType;

    return {
      loading: isNil(selected),
      query: selected ? generateGraphQL(flatten(selected.map(s => s[0]!.updatedQueries ?? []))) : null,
      onTableChange,
      columns: testing ?? [],
      sorting,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configFields, entityType, items, optionMapGet, setSorting, sorting, rawMap]);
}

export const TABLE_COLUMNS_DATA_QUERY = gql`
  query TableColumnsQuery($entityType: EntityTypeEnum!) {
    viewer {
      id
      tableColumns(entityType: $entityType) {
        columnKey
        sort
      }
    }
  }
`;

