import React, { createContext } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Optional, StateArray } from '../util/StateArrayType';
import { ITE } from '../components/Icon/Icon';
import { NavigationDataQueryQuery } from '../../gql/typings';
import { AppContextSystemPermissionType } from '../permission/permissionUtils';


export const defaultAppContext: AppContextType = {
  collapse: [false, (isCollapse: boolean | ((current: boolean) => boolean)) => isCollapse],
  systemPermissions: {
    globalSearch: true
  }
};

export const AppContext = createContext<AppContextType>(defaultAppContext);

export type AppContextType = {
  collapse: StateArray<boolean>;
  systemPermissions: Optional<Record<AppContextSystemPermissionType, boolean>>;
};


export type NavigationMenuItem = {
  url: string;
  class: string;
  icon: ITE;
  label: string;
  popover?: boolean;
  tooltip?: string;
  iconClass?: string;
};

export type NavigationMenuGroupRendererProps = {
  data: NavigationDataQueryQuery|undefined;
  sortListModalState: StateArray<boolean>;
};

export type NavigationMenuGroup = {
  key: string;
  className?: string;
  label: string|((props: NavigationMenuGroupRendererProps) => string|React.ReactNode);
  items?: NavigationMenuItem[];
  render?: React.FC<NavigationMenuGroupRendererProps>;
};
