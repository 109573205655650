import { useQuery, gql } from '@apollo/client';
import React from 'react';
import { debounce } from 'lodash';
import { Select } from 'antd';
import { SelectSiteTypeQueryQuery, SelectSiteTypeQueryQueryVariables } from '../../../../gql/typings';


type SelectSiteTypeProps = {
  value?: string;
  countryCode?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
};

const SelectSiteType: React.FC<SelectSiteTypeProps> = ({
  value,
  countryCode,
  onChange,
  disabled,
}) => {
  const { data, loading, refetch } = useQuery<SelectSiteTypeQueryQuery, SelectSiteTypeQueryQueryVariables>(
    DATA_QUERY,
    { variables: { countryCode, search: '%' } },
  );

  const searchDebounced = debounce(
    search => {
      const input: SelectSiteTypeQueryQueryVariables = { search: `%${search}%` };
      if (countryCode) input.countryCode = countryCode;
      return refetch(input);
    },
    25,
  );

  return (
    <Select
      placeholder="Select a Site Type"
      loading={loading}
      value={value as string}
      showSearch
      onSearch={searchDebounced}
      filterOption={false}
      onChange={e => {
        onChange?.(e);
        searchDebounced('');
      }}
      disabled={disabled}
      allowClear
      className="site-type-selector"
    >
      {(data?.siteTypes.nodes ?? []).map(type => (
        <Select.Option key={type.code} value={type.code}>
          {type.label}
        </Select.Option>
      ))}
    </Select>
  );
};

const DATA_QUERY = gql`
  query SelectSiteTypeQuery($search: String, $countryCode: CountryCode) {
    siteTypes(criteria: {label: $search, country: $countryCode}) {
      hash
      nodes {
        code
        label: displayLabel
        type
      }
    }
  }
`;

export default SelectSiteType;
