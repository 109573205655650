import { message, Modal } from 'antd';
import React from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import { gql } from '@apollo/client/core';
import { StateArray } from '../../../util/StateArrayType';
import { EntitiesSearch } from '../../../components/entitiesSearch';
import { Locale } from '../../../../localization/LocalizationKeys';
import ExternalNavLink from '../../../components/ExternalNavLink/ExternalNavLink';
import { useLocalization } from '../../../util/useLocalization';
import {
  AttachmentRuleEnum,
  BulkPersToActivityMutation,
  BulkPersToActivityMutationVariables,
  EntityTypeEnum, SelectionCriteriaInput
} from '../../../../gql/typings';
import { useTableRowSelection } from '../../../components/Table/useTableRowSelection';

const BulkPersonsToActivity: React.FC<{
  visibleState: StateArray<boolean>;
  selection?: SelectionCriteriaInput;
  personId?: number;
}> = (props) => {
  const localization = useLocalization();
  const tableSelection = useTableRowSelection({ virtualSupport: false, type: 'radio' });
  const navigate = useNavigate();

  const [add, { loading }] = useMutation<BulkPersToActivityMutation, BulkPersToActivityMutationVariables>(BULK_ADD_MUTATION);
  const [messageApi, contextHolder] = message.useMessage();
  const selection = props?.selection;
  const personId = props?.personId;
  return (
    <Modal
      open={props.visibleState[0]}
      onCancel={() => props.visibleState[1](false)}
      onOk={() => add({
        variables: {
          activityId: tableSelection.selection.selected[0]!,
          personId: personId || 1

        },
        refetchQueries: ['PersonAffiliationsContainerQuery'],
      }).then(() => props.visibleState[1](false))
        .finally(() => message.success(localization.formatMessage(Locale.Text.Successfully_added_hcps_to_activity)))}
      destroyOnClose
      okButtonProps={{ style: { display: personId ? 'unset' : 'none' } }}
      width="65%"
      title={localization.formatMessage(Locale.Text.Mass_assign_to_activity)}
    >
      {contextHolder}
      {props.visibleState[0] && <>
        {selection && <EntitiesSearch
          entityType={EntityTypeEnum.ACTIVITY}
          tableSelection={tableSelection}
          criteriaFilter={{
            hasOneOfPersonRules: selection.count > 1
              ? [AttachmentRuleEnum.MANY, AttachmentRuleEnum.OPTIONAL_MANY]
              : [
                AttachmentRuleEnum.ONE, AttachmentRuleEnum.OPTIONAL_ONE,
                AttachmentRuleEnum.MANY, AttachmentRuleEnum.OPTIONAL_MANY,
              ]
          }}
          quickActions={[
            {
              key: 'add',
              label: localization.formatMessage(Locale.Text.Add_people_to_activity),
              disabled: tableSelection.selection.count === 0,
              loading,
              onClick: () => add({
                variables: {
                  activityId: tableSelection.selection.selected[0]!,
                  selection,
                  personId

                }
              }).then((res) => {
                if (res.errors && res.errors.length > 0) return;
                props.visibleState[1](false);
                return messageApi.success(
                  localization.pluralMessage(Locale.Text.Successfully_added_hcps_to_activity,
                    selection.count,
                    {
                      activityName: <ExternalNavLink
                        navigate={navigate}
                        to={`/activity/${res.data?.addPersonsToActivity.id}`}
                      >
                        {res.data?.addPersonsToActivity.heading}
                      </ExternalNavLink>,
                    })
                );
              })
            }
          ]}
        />}

        {props.personId && <EntitiesSearch
          entityType={EntityTypeEnum.ACTIVITY}
          tableSelection={tableSelection}
        />}
      </>}


    </Modal>
  );
};


const BULK_ADD_MUTATION = gql`
  mutation BulkPersToActivity(
    $activityId: Int!,
    $selection: SelectionCriteriaInput
    $personId: Int
  ) {
    addPersonsToActivity(activityId: $activityId, selection: $selection, personId: $personId) {
      id
      heading
      persons {
        hash
        totalCount
      }
    }
  }
`;

export default BulkPersonsToActivity;
