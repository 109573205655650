import { useQuery, gql } from '@apollo/client';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { SelectOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import AffiliationCard from '../../../components/Card/AffiliationCard';
import { SiteActivityContainerQueryQuery } from '../../../../gql/typings';


const SiteActivityContainer: React.FC<{ siteId: number }> = ({ siteId }) => {
  const { data, loading } = useQuery<SiteActivityContainerQueryQuery>(DATA_QUERY, { variables: { siteId } });
  const activityList = data?.site?.activities.nodes ?? [];

  return (
    <Skeleton loading={loading}>
      {activityList.map(it => <span key={it.id}>
        <AffiliationCard
          title={it.title}
          key={it.id}
          url={<><NavLink
            to={`/activity/${it.id}`}
          >
            <SelectOutlined />
          </NavLink></>}
          startDate={it.startDate}
          type={it.type}
          note={it.notes.nodes[0]?.text ?? ''}
          users={it.responsibleUsers.nodes}
          createdDate={it.ct}
          status={it.activityStatus.code}
          endDate={it.endDate}
        />
      </span>)}
    </Skeleton>
  );
};


const DATA_QUERY = gql`
  query SiteActivityContainerQuery($siteId: Int!) { 
    site(siteId: $siteId) {
      id
      activities {
        hash
        nodes {
          id
          title: heading
          startDate
          endDate
          ct
          activityStatus: status {
            code
          }
          responsibleUsers {
            hash
            nodes {
              id
              fullname
            }
          }
          type {
            code
            color {
              code
            }
          }
          notes {
            hash
            nodes {
              id
              text
            }
          }
        }
      }
    }
  }
`;

export default SiteActivityContainer;

