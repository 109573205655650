import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { EntityTypeEnum, FieldEnum } from '../../../../gql/typings';

export const siteBrickFieldConfig = siteFieldConfig(
  'brick',
  [
    'id',
    'bricks.hash',
    'bricks.nodes.code',
    'bricks.nodes.heading',
    'bricks.nodes.territory.code',
    'bricks.nodes.territory.heading',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.General.Brick),
    additionalTableConfig: {
      width: 100
    },
    dcrInfo: site => ({
      entityType: EntityTypeEnum.SITE,
      field: FieldEnum.BRICK_NAME,
      entityAffiliationId: site.id,
    }),
    render: (site) => <span>
      {site.bricks.nodes[0]?.heading ?? ''}
      {site.bricks.nodes[0]?.territory && (
        ` (${site.bricks.nodes[0].territory.heading})`
      )}
    </span>,
  })
);
