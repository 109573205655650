import { gql } from '@apollo/client';
import { useUsePreferredLOVLanguageQuery } from '../../gql/typings';

export enum PreferredLOVLanguage {
  LOCAL,
  ENGLISH,
}

export const usePreferredLOVLanguage = () => {
  const { data } = useUsePreferredLOVLanguageQuery();
  return data?.viewer?.user.hasEnglishAsDefault
    ? PreferredLOVLanguage.ENGLISH
    : PreferredLOVLanguage.LOCAL;
};

gql`
  query UsePreferredLOVLanguage {
    viewer {
      id
      user {
        id
        hasEnglishAsDefault
      }
    }
  }
`;
