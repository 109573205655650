import React from 'react';
import { DocumentNode } from '@apollo/client';
import { DatePicker, DatePickerProps } from 'antd';
import { get } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { Optional } from '../../util/StateArrayType';
import { usePersonFieldMutation } from '../../browse/person/Components/attributeFields/AttributeFields/usePersonFieldMutation';
import { TableFieldUpdateViewProps } from '../../browse/search_old/types';
import { ObjectToDotted } from './fieldConfigTypes';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';
import { DATE_FORMAT } from '../../util/format';

interface RecordType {
  id: number;
  controlSetting?: Optional<{ id: number; createDcr: boolean }>;
}

type PanelMode = 'week'|'month'|'quarter'|'year';

type FreeDateFieldArgs<T extends RecordType, V> = {
  mode?: PanelMode;
  mutation: DocumentNode;
  valuePath: ObjectToDotted<T, Date>;
  buildVariables: (newDate: Optional<Dayjs>, record: T) => V;
  dateFormat?: string;
  additionalDateProps?: Omit<DatePickerProps, 'picker'|'autoFocus'|'format'|'onBlur'
  |'style'|'defaultValue'|'value'|'onChange'>;
};

export function buildDateUpdateFieldConfig<T extends RecordType, V>(
  args: FreeDateFieldArgs<T, V>,
) {
  return (props: TableFieldUpdateViewProps<T>) => {
    const localization = useLocalization();
    const rawDate = get(props.record, args.valuePath as string);
    const { Renderer, submit, blocking } = usePersonFieldMutation<unknown, V>({
      mutation: args.mutation,
      controlSetting: props.record.controlSetting,
    });

    const onBlur = () => !blocking && props.endEditing();

    return (
      <Renderer>
        <DatePicker
          picker={args.mode ?? 'date'}
          placeholder={localization.formatMessage(Locale.Command.Select_date)}
          autoFocus
          format={args.dateFormat ?? (args.mode === 'year') ? 'YYYY' : DATE_FORMAT}
          onBlur={onBlur}
          style={{ width: '100%' }}
          defaultValue={rawDate ? dayjs(rawDate) : undefined}
          onChange={date => {
            submit(args.buildVariables(date, props.record)).then(onBlur);
          }}
        />
      </Renderer>
    );
  };
}
