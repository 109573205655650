import { gql } from '@apollo/client';
import React from 'react';
import { Button, Drawer, Form, Input, Space, Switch } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  EntityTypeEnum,
  SelectionCriteriaInput, useCreateStaticListMutationMutation
} from '../../../../gql/typings';

type CreateStaticListModalProps = {
  visible: boolean;
  onClose: () => void;
  selection: SelectionCriteriaInput;
  entityType: EntityTypeEnum.PERSON | EntityTypeEnum.ACTIVITY | EntityTypeEnum.SITE;
};

const CreateStaticListModal: React.FC<CreateStaticListModalProps> = ({
  selection,
  visible,
  onClose,
  entityType,
}) => {
  const navigate = useNavigate();
  const [createList] = useCreateStaticListMutationMutation();
  const [listForm] = Form.useForm();
  const { formatMessage } = useIntl();

  const onSaveUpdates = () => listForm.validateFields()
    .then(values => {

      createList({
        variables: {
          selection,
          entityType,
          title: values['list-title'],
          description: values['list-description'],
          shared: values['list-visibility'],

        },
      }).then(res => res.data?.createStaticList.id && navigate(`/list/${res.data.createStaticList.id}`));
    });


  return (
    <>
      <Drawer
        title={formatMessage(Locale.Command.Create_Static_List)}
        placement="right"
        width="500px"
        closable={false}
        onClose={onClose}
        open={visible}
        className="create-static-list-drawer-container"
      >
        <div className="static-list-container">
          <Form form={listForm} initialValues={{ 'list-visibility': false }}>
            <span className="label">
              {formatMessage(Locale.Attribute.Heading)}
            </span>
            <div className="input">
              <Form.Item
                name="list-title"
                rules={[
                  { required: true,
                    message: formatMessage(Locale.Text.Please_enter_heading) },
                  { max: 40,
                    message: formatMessage(Locale.Text.Heading_must_be_beneath_40_characters) },
                ]}
              >
                <Input
                  className="list-title"
                  autoFocus
                  placeholder={formatMessage(Locale.Command.Enter_title)}
                />
              </Form.Item>
            </div>


            <span className="label">
              {formatMessage(Locale.Attribute.Description)}
            </span>
            <div className="input">
              <Form.Item name="list-description">
                <Input.TextArea
                  id="list-description"
                  placeholder={formatMessage(Locale.Command.Enter_description)}
                />
              </Form.Item>
            </div>

            <span className="label">
              {formatMessage(Locale.General.Shared)}
            </span>
            <div className="input">
              <Form.Item name="list-visibility" valuePropName="checked">
                <Switch
                  checkedChildren="Shared"
                  unCheckedChildren="Private"
                />
              </Form.Item>
            </div>


            <div className="entities-search-top-container" />

            {/* <div className="dynamic-list-modal-create">
              <div className="entities-search-top-container">
                <Input
                  id="heading"
                  placeholder="List Heading"
                  onChange={e => setHeading(e.target.value)}
                  autoFocus
                />
              </div>
              <div className="entities-search-top-container">
                <Input.TextArea
                  id="desc"
                  onChange={e => setDescription(e.target.value)}
                  placeholder="List description"
                />
              </div>
              <div className="entities-search-top-container">
                <Switch
                  checkedChildren="Shared"
                  onChange={setSharedStatus}
                  unCheckedChildren="Private"
                />
              </div>
              <br />
              <p>Adding <b>{ids.length}</b> records to static list</p>
            </div> */}

          </Form>
          <Space>
            <Button onClick={onClose}> {formatMessage(Locale.Command.Cancel)}</Button>
            <Button type="primary" onClick={onSaveUpdates}>
              {formatMessage(Locale.Command.Save)}
            </Button>
          </Space>
        </div>
      </Drawer>
    </>

  );
};

gql`
  mutation CreateStaticListMutation(
    $title: String!,
    $selection: SelectionCriteriaInput!,
    $description: String!
    $shared: Boolean!
    $entityType: EntityTypeEnum!
  ) {
    createStaticList(
      title: $title,
      selection: $selection,
      description: $description,
      isShared: $shared,
      entityType: $entityType
    ) {
      id
    }
  }
`;


export default CreateStaticListModal;
