import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { personFieldConfig } from './utils/personFieldsUtils';
import { PersonCriteriaOrder } from '../../../../gql/typings';


export const personMainSiteVisitCityFieldConfig = personFieldConfig(
  'mainSiteVisitCity',
  [
    'primaryOrFirstAffiliation.id',
    'primaryOrFirstAffiliation.site.id',
    'primaryOrFirstAffiliation.site.visitAddress.id',
    'primaryOrFirstAffiliation.site.visitAddress.city',
  ],
  ({ localization, openInNewTab }) => ({
    sorting: {
      ascend: PersonCriteriaOrder.PRIMARY_SITE_VISIT_CITY_ASC,
      descend: PersonCriteriaOrder.PRIMARY_SITE_VISIT_CITY_DESC,
    },
    additionalTableConfig: {
      width: 150
    },
    title: localization.formatMessage(Locale.Attribute.Visit_city),
    render: record => (
      <LinkBadge
        url={record.primaryOrFirstAffiliation ? `/hco/${record.primaryOrFirstAffiliation.site.id}` : undefined}
        label={record.primaryOrFirstAffiliation?.site?.visitAddress?.city}
        openInNewTab={openInNewTab}
      />
    ),
  })
);
