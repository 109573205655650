import React from 'react';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { Skeleton } from 'antd';
import MainView from '../../components/View/MainView';
import EntitySettingRoutes from './entitySettings/EntitySettingRoutes';
import UrlTabs from './adminComponents/UrlTabs/UrlTabs';
import { useLocalization } from '../../util/useLocalization';
import ClientsRoutes from './clients/ClientsView';
import UserRoutes from './users/UsersView';
import SystemSettingRoutes from './system/SystemSettingRoutes';
import ReportRoutes from './report/ReportRoutes';
import { AdminMenuItemsQueryQuery } from '../../../gql/typings';

const groups: Record<string, React.FC> = {
  system: SystemSettingRoutes,
  entity: EntitySettingRoutes,
  clients: ClientsRoutes,
  users: UserRoutes,
  reports: ReportRoutes,
};

const AdminView: React.FC = () => {
  const localization = useLocalization();
  const { data, loading } = useQuery<AdminMenuItemsQueryQuery>(DATA_QUERY);

  const items = (data?.viewer?.adminMenuItems.nodes ?? []).map(group => {
    const renderer = groups[group.url!];
    if (!renderer) throw Error(`Couldn't find a renderer for '${group.headingKey}'`);
    return {
      path: group.url,
      title: localization.formatMessageByStr(group.headingKey),
      render: renderer,
    };
  });

  if (!items) return (
    <MainView className="admin-container" withoutPadding>
      <Skeleton active={loading} loading={loading} paragraph={{ rows: 8 }} />
    </MainView>
  );

  return (
    <MainView className="admin-container" withoutPadding>
      <UrlTabs
        path="admin"
        loading={loading}
        items={items}
      />
    </MainView>
  );
};

const DATA_QUERY = gql`
  query AdminMenuItemsQuery {
    viewer {
      id
      adminMenuItems {
        hash
        nodes {
          code
          headingKey
          url
        }
      }
    }
  }
`;

export default AdminView;
