import React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Result, Space } from 'antd';
import { toNumber } from 'lodash';
import AdminEntityMergeRequestProgress from './AdminEntityMergeRequestProgress';
import { isActualNumber } from '../../../../../util/Util';
import MainView from '../../../../../components/View/MainView';
import ActionsToTakeDropdown from '../../../../../components/ActionsToTake/ActionsToTakeDropdown';
import {
  AdminEntityMergeRequestBasicQuery,
  AdminEntityMergeRequestBasicQueryVariables,
  EntityMergeStatusEnum,
  EntityTypeEnum,
} from '../../../../../../gql/typings';
import { useEntityMerge } from './useEntityMerge';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../../util/useLocalization';


const AdminEntityMergeRequestDetails: React.FC = () => {
  const localization = useLocalization();
  const navigate = useNavigate();
  const match = useMatch('/admin/merge/:id');

  if (!isActualNumber(match?.params?.id)) return <MainView className="user-admin-details-container">
    <Result status="500" />
  </MainView>;


  const {
    data,
    refetch,
    loading: dataLoading,
  } = useQuery<AdminEntityMergeRequestBasicQuery, AdminEntityMergeRequestBasicQueryVariables>(DATA_QUERY, {
    variables: { mergeRequestId: match!.params.id! },
  });
  const {
    emitter,
    generateDescription,
    from,
    status,
    render,
    loading,
    doMerge,
    showNonConflictState: [showNonConflicts, setShowNonConflicts],
    showMaintainerIgnoredState: [showMaintainerIgnored, setShowMaintainerIgnored],
  } = useEntityMerge(data?.entityMerge?.entityType, data?.entityMerge?.from?.id, data?.entityMerge?.into?.id);

  const generateTitle = (): string => {
    if (from?.__typename === 'Person') {
      return `Preview merges of "${from.fullName}"`;
    }
    if (from?.__typename === 'Site') {
      return `Preview merges of "${from.name}"`;
    }
    return '';
  };

  const entityType = data?.entityMerge?.entityType;

  return (
    <MainView
      className="user-admin-details-container"
      titleLoading={dataLoading}
      title={generateTitle()}
      rightActions={
        <Space direction="vertical">
          <AdminEntityMergeRequestProgress emitter={emitter} />
          <Space>
            <div style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gridTemplateRows: 'repeat(2, 1fr)',
              columnGap: 4,
            }}
            >
              {data?.entityMerge?.byUser && <>
                <strong>{localization.formatMessage(Locale.Attribute.Created_by)}:</strong>
                <span>{data?.entityMerge?.byUser?.fullname}</span>
              </>}
              <strong>{localization.formatMessage(Locale.General.Status)}:</strong><span>{status}</span>
            </div>
            <ActionsToTakeDropdown
              loading={loading}
              actions={[
                {
                  key: 'merge',
                  label: 'Merge',
                  hide: status === EntityMergeStatusEnum.HANDLED,
                  onClick: () => doMerge(toNumber(match!.params.id!)).finally(() => refetch()),
                },
                null,
                {
                  key: 'showNonConflicts',
                  label: showNonConflicts ? 'Hide non conflicts' : 'show non conflicts',
                  onClick: () => setShowNonConflicts(v => !v),
                },
                {
                  key: 'showMaintainerIgnored',
                  label: showMaintainerIgnored ? 'Hide ignored' : 'Show ignored',
                  onClick: () => setShowMaintainerIgnored(v => !v),
                },
              ]}
            />
          </Space>
        </Space>
      }
      breadcrumbs={[
        {
          label: 'Admin Settings',
          onClick: () => navigate('/admin'),
        },
        {
          label: 'Entity',
          onClick: () => navigate('/admin/entity'),
        },
        {
          label: entityType === EntityTypeEnum.PERSON
            ? 'Person'
            : entityType === EntityTypeEnum.SITE
              ? 'Site'
              : entityType === EntityTypeEnum.AFFILIATION
                ? 'Affiliation'
                : '...',
          onClick: () => navigate(entityType === EntityTypeEnum.PERSON
            ? '/admin/entity/hcp'
            : entityType === EntityTypeEnum.SITE
              ? '/admin/entity/hco'
              : '/admin/entity/link'),
        },
        {
          label: `Merge Request (ID: ${data?.entityMerge?.id})`,
        },
      ]}
    >
      {generateDescription()}
      {render()}
    </MainView>
  );
};

const DATA_QUERY = gql`
  query AdminEntityMergeRequestBasic($mergeRequestId: Int!) {
    entityMerge(id: $mergeRequestId) {
      id
      byUser {
        id
        fullname
      }
      entityType
      from { id }
      into { id }
    }
  }
`;

export default AdminEntityMergeRequestDetails;
