import React from 'react';
import { useIntl } from 'react-intl';
import { Drawer, Tabs } from 'antd';
import EntitiesSimpleSearch from './EntitiesSimpleSearch';
import { StateArray } from '../../../../util/StateArrayType';
import { Locale } from '../../../../../localization/LocalizationKeys';


const EntitiesSearchDrawer: React.FC<{
  drawerState: StateArray<boolean>;
}> = ({
  drawerState,
  children,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Drawer
      open={drawerState[0]}
      onClose={() => drawerState[1](false)}
      width={465}
      closable
      className="entities-search-drawer-container"
    >
      {children}
      <Tabs
        className="tabs"
        centered
        items={[
          {
            key: '1001',
            label: formatMessage(Locale.General.Simple_search),
            children: <EntitiesSimpleSearch />
          },
          {
            key: '1003',
            label: 'Advanced Search',
            children: <p style={{ margin: 20 }}>
              This functionality hasn't been finalized yet. Please come back sometime in the future to try it out.
            </p>
          }
        ]}
      />
    </Drawer>
  );
};


export default EntitiesSearchDrawer;
