import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import NoteDrawer from '../../../components/Note/NoteDrawer';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import ApbButton, { ButtonComponentProps } from '../../../components/Button/ApbButton';
import { EntityTypeEnum } from '../../../../gql/typings';
import { useInactivateLinkAction } from './useInactivateLinkAction';


const LinkSectionRight: React.FC<{ linkId: number }> = ({
  linkId
}) => {
  const localization = useLocalization();
  const [isNoteDrawerVisible, setIsNoteDrawerVisible] = useState<boolean>(false);
  const inactivateLinkAction = useInactivateLinkAction(linkId);

  const menu: ButtonComponentProps['menuItems'] = [
    {
      key: 'create-note',
      onClick: () => setIsNoteDrawerVisible(true),
      label: localization.formatMessage(Locale.Command.Create_Note),
    },
    inactivateLinkAction.action,
  ].filter(e => e);

  return (
    <div className="content-right-section">
      <div className="button-select-container">
        <ApbButton
          icon={<DownOutlined />}
          menuItems={menu}
          size="large"
          buttonType="action"
          text={localization.formatMessage(Locale.Text.Actions_to_take)}
        />
      </div>
      <NoteDrawer
        id={linkId}
        entity={EntityTypeEnum.AFFILIATION}
        displayDrawer={isNoteDrawerVisible}
        setDisplayDrawer={setIsNoteDrawerVisible}
      />
      {inactivateLinkAction.hidden}
    </div>
  );

};

export default LinkSectionRight;
