import React from 'react';
import { Optional } from './StateArrayType';

/**
 * This function is based on the exact same idea as the standard javascript join() method.
 * But works flawlessly with react elements.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/join
 */
export function reactJoin(elements: React.ReactElement[], separator: Optional<React.ReactNode> = ', '): React.ReactElement {
  if (elements.length === 0) return <></>;
  if (elements.length === 1) return elements[0]!;

  // @ts-ignore
  return elements.reduce((acc, curr) => <>
    {acc && <>{acc}{separator}</>}
    {curr}
  </>, null);
}
