import { Input, message, Modal, Radio, Space } from 'antd';
import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import Icon from '../../../components/Icon/Icon';
import { EntitiesSearchQuickActionItem } from '../../../components/entitiesSearch/top/EntitiesSearchQuickActionItemType';
import DcrModalConfirm from '../../../components/DcrModalConfirm/DcrModalConfirm';
import {
  ActivateHcpSiteRelationMutation,
  ActivateHcpSiteRelationMutationVariables,
  DeleteReasonDcrEnum,
  EntityTypeEnum,
  RemoveHcpSiteRelationMutation,
  RemoveHcpSiteRelationMutationVariables
} from '../../../../gql/typings';
import { useDetailsContext } from '../../../components/DetailsView/useDetailsContext';
import { useIsAnyInactive } from '../../../util/useIsAnyInactive';
import { useIsAnyActive } from '../../../util/useIsAnyActive';
import { TableRowSelectionReturnProps } from '../../../components/Table/useTableRowSelection';

type ReturnType = {
  action: EntitiesSearchQuickActionItem;
  hidden: React.ReactNode;
};

export const useRemoveWorkplaceFromPerson = (
  personId: number,
  tableSelection: TableRowSelectionReturnProps,
  isMaintainerApb: boolean,
): ReturnType => {
  const localization = useLocalization();
  const { selected } = tableSelection.selection;

  const [renderDcrNotification, setDcrNotification] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [reason, setReason] = useState<DeleteReasonDcrEnum>(DeleteReasonDcrEnum.WORKINGABROUD);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { broadcastShouldRefetchData } = useDetailsContext();
  const [hasSelectedInactivateRecord] = useIsAnyInactive(EntityTypeEnum.AFFILIATION, selected);
  const [hasSelectedActivateRecord] = useIsAnyActive(EntityTypeEnum.AFFILIATION, selected);

  const [
    removeHcpSiteRelation,
    { loading: isRemoving },
  ] = useMutation<RemoveHcpSiteRelationMutation, RemoveHcpSiteRelationMutationVariables>(REMOVE_MUTATION);
  const [
    activateHcpSiteRelation,
    { loading: isActivating },
  ] = useMutation<ActivateHcpSiteRelationMutation, ActivateHcpSiteRelationMutationVariables>(ACTIVATE_MUTATION);

  const loading = isRemoving || isActivating;

  const maintainerSourceCheck = () => {
    if (isMaintainerApb) {
      setModalVisible(false);
      setDcrNotification(true);
    } else {
      setModalVisible(false);
      removeHcpSiteRelation({
        variables: {
          linkIds: selected,
          reason,
          comment
        },
      }).then(() => {
        broadcastShouldRefetchData();
        tableSelection.clearState();
        return message.success(localization.formatMessage(Locale.Text.Action_was_successful));
      });
    }
  };


  const submitDcr = () => {
    setModalVisible(false);
    setDcrNotification(false);
    removeHcpSiteRelation({
      variables: {
        linkIds: selected,
        reason,
        comment
      },
    }).then(() => {
      broadcastShouldRefetchData();
      tableSelection.clearState();
      return message.success(localization.formatMessage(Locale.Text.Action_was_successful));
    });
  };

  const onButtonClick = () => {
    if (isMaintainerApb) {
      setModalVisible(true);
    } else if (!hasSelectedInactivateRecord) {
      removeHcpSiteRelation({
        variables: {
          linkIds: selected,
          reason,
          comment: ''
        },
      }).then(() => {
        broadcastShouldRefetchData();
        tableSelection.clearState();
        return message.success(localization.formatMessage(Locale.Text.Action_was_successful));
      });
    } else if (hasSelectedInactivateRecord) {
      Promise.all(selected.map(linkId => activateHcpSiteRelation({
        variables: { linkId },
      }))).then(() => {
        broadcastShouldRefetchData();
        tableSelection.clearState();
        return message.success(localization.formatMessage(Locale.Text.Action_was_successful));
      });
    }
  };

  const getLabel = () => {
    const label = hasSelectedInactivateRecord
      ? localization.formatMessage(Locale.Command.Activate_record)
      : localization.formatMessage(Locale.Command.Remove_workplace);
    const tooltip = hasSelectedInactivateRecord
      ? undefined
      : localization.formatMessage(Locale.Text.Remove_affiliation_toltip);

    return isMaintainerApb ? <Icon
      style={{ height: '16px', marginRight: '5px' }}
      toolTip={tooltip}
      iconType="DCR_WHITE"
      text={label}
    /> : <span>{label}</span>;
  };

  return {
    action: {
      key: 'remove-workplace',
      hide: (hasSelectedInactivateRecord && hasSelectedActivateRecord) || selected.length === 0,
      onClick: onButtonClick,
      loading,
      label: getLabel(),
    },
    hidden: (
      <>
        <Modal
          title={localization.formatMessage(Locale.Command.Remove_workplace)}
          open={modalVisible}
          onOk={maintainerSourceCheck}
          onCancel={() => setModalVisible(false)}
        >
          <p>{localization.formatMessage(Locale.Text.Remove_affiliation_hco_text)}
          </p>
          <div>

            <Radio.Group value={reason} onChange={e => setReason(e.target.value)}>
              <Space direction="vertical">
                <Radio value={DeleteReasonDcrEnum.OPTOUT}>
                  {localization.formatMessage(Locale.Text.Opt_out)}
                </Radio>
                <Radio value={DeleteReasonDcrEnum.DESCEASED}>
                  {localization.formatMessage(Locale.Text.Deceased)}
                </Radio>
                <Radio value={DeleteReasonDcrEnum.RETIRED}>
                  {localization.formatMessage(Locale.Text.Retired)}
                </Radio>
                <Radio value={DeleteReasonDcrEnum.WORKINGABROUD}>
                  {localization.formatMessage(Locale.Text.Working_abroad)}
                </Radio>
                <Radio value={DeleteReasonDcrEnum.NOWORKHERE}>
                  {localization.formatMessage(Locale.Text.No_work_here)}
                </Radio>
                <Radio value={DeleteReasonDcrEnum.TEMPNOWORKHERE}>
                  {localization.formatMessage(Locale.Text.Temp_no_work_here)}
                </Radio>
                <Radio value={DeleteReasonDcrEnum.OTHER}>
                  {localization.formatMessage(Locale.Text.Other)}
                  {reason === DeleteReasonDcrEnum.OTHER
                    ? <Input autoFocus onChange={(e) => setComment(e.target.value)} style={{ marginTop: 5 }} /> : null}
                </Radio>
              </Space>
            </Radio.Group>
          </div>
        </Modal>
        <DcrModalConfirm
          visibleState={[renderDcrNotification, setDcrNotification]}
          onAccept={submitDcr}
          messageKey={Locale.Text.Remove_workplace_dcr_validation}
        />
      </>
    ),
  };
};

const ACTIVATE_MUTATION = gql`
  mutation ActivateHcpSiteRelation($linkId: Int!) {
    setSitePersonActiveStatus(id: $linkId, isActive: true) {
      id
      isActive
    }
  }
`;

const REMOVE_MUTATION = gql`
  mutation RemoveHcpSiteRelation($linkIds: [Int!]!, $comment: String, $reason: DeleteReasonDcrEnum!) {
    removeHcpSiteRelation(linkIds: $linkIds, comment: $comment, reason: $reason)
  }
`;
