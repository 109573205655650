import React from 'react';
import { gql } from '@apollo/client';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import { MergeItemSiteNotesFragment, } from '../../../../../../../../../gql/typings';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';


const AdminMergeItemSiteNotes: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemSiteNotesFragment;
  const localization = useLocalization();

  return (
    <AdminMergeItemBasicField {...props}>
      {localization.pluralMessage(Locale.Text.Merge_request_notes_move_description, info.noteIds.length)}
    </AdminMergeItemBasicField>
  );
};

gql`
  fragment MergeItemSiteNotes on SiteNotes_EntityDataToBeTransferredType {
    code
    entityType
    noteIds
  }
`;

export default AdminMergeItemSiteNotes;
