import React from 'react';
import { gql } from '@apollo/client/core';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';
import { Locale } from '../../../../localization/LocalizationKeys';
import { LinkColumn } from '../../../components/Table/utils';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum, SelectPersonPosSuggestionQueryQuery, SelectPersonPosSuggestionQueryQueryVariables, SitePersonCriteriaOrder,
  UpdatePersonPositionMutationMutationVariables
} from '../../../../gql/typings';
import { buildSelectUpdateFieldConfig } from '../../../components/FieldConfig/buildSelectUpdateFieldConfig';

export const linkPositionFieldConfig = linkFieldConfig(
  'position',
  [
    'id',
    'countryCode',
    'position.code',
    'position.displayLabel',
    'position.type',
    'person.id',
    'person.controlSetting.id',
    'person.controlSetting.entityTypeId',
    'person.controlSetting.maintainerSourceCode',
    'person.controlSetting.createDcr',
  ],
  ({ localization, openInNewTab }) => ({
    title: localization.formatMessage(Locale.Attribute.Position),
    additionalTableConfig: {
      width: 150
    },
    sorting: {
      ascend: [SitePersonCriteriaOrder.POSITION_ASC],
      descend: [SitePersonCriteriaOrder.POSITION_DESC],
    },
    dcrInfo: link => ({
      entityType: EntityTypeEnum.AFFILIATION,
      entityAffiliationId: link.id,
      field: FieldEnum.HCP_WORK_POS_CODE,
    }),
    render: (record, { isViewingFromPage, isViewingFromEntity }) => {
      if (isViewingFromPage === TableFieldReturnedRecordPageType.VIEW_PAGE
      || isViewingFromEntity !== EntityTypeEnum.AFFILIATION
      ) return (
        <LinkColumn
          text={record.position ? record.position.displayLabel
            : localization.formatMessage(Locale.Text.No_position_provided)}
          url={`/link/${record?.id}`}
          openInNewTab={openInNewTab}
        />
      );
      return record.position?.displayLabel;
    },
    updateView: buildSelectUpdateFieldConfig({
      mode: 'single',
      allowClear: true,
      selectPlaceholder: localization.formatMessage(Locale.Command.Select_position),
      mutation: UPDATE_MUTATION,
      selectedKey: record => record.position?.code,
      buildVariables: (selectedKey, record) => ({
        linkId: record.id,
        positionCode: selectedKey,
      }) as UpdatePersonPositionMutationMutationVariables,
      fetchSuggestions: ({ client, record, userInput }) => (
        client.query<SelectPersonPosSuggestionQueryQuery, SelectPersonPosSuggestionQueryQueryVariables>({
          query: SUGGESTIONS_QUERY,
          variables: { country: record.countryCode, search: `%${userInput}%` },
        }).then(res => res.data.positions.nodes)
      ),
    }),
  })
);


const UPDATE_MUTATION = gql`
  mutation UpdatePersonPositionMutation($linkId: Int!, $positionCode: String) {
    updatePosition(id: $linkId, positionCode: $positionCode) {
      id
      positionCode
      position {
        code
        type
      }
    }
  }
`;


const SUGGESTIONS_QUERY = gql`
  query SelectPersonPosSuggestionQuery($search: String, $country: CountryCode!) {
    positions(criteria: { country: $country label: $search, fetchSize: { limit: 1000 } }) {
      hash
      nodes {
        code
        label: displayLabel
        type
      }
    }
  }
`;
