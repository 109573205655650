import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Form, Select } from 'antd';
import { useDebounce } from 'use-debounce';
import { useIntl } from 'react-intl';
import { DynamicReportInputProps } from './dynamicReportInputUtils';
import { useLocalization } from '../../../../util/useLocalization';
import { ReportProductInputQueryQuery, ReportProductInputQueryQueryVariables } from '../../../../../gql/typings';

const ReportProducts: React.FC<DynamicReportInputProps & { multiple?: boolean }> = ({
  inputDef,
  multiple,
}) => {
  const { formatMessage } = useIntl();
  const localization = useLocalization();
  const [search, setSearch] = useState('');
  const [searchDebounced] = useDebounce(search, 500, { maxWait: 1000 });
  const { data, loading, refetch } = useQuery<ReportProductInputQueryQuery, ReportProductInputQueryQueryVariables>(DATA_QUERY);

  useEffect(() => {
    refetch({ search: `%${searchDebounced}%` }).then(() => {});
  }, [refetch, searchDebounced]);

  return (
    <Form.Item
      name={inputDef.labelKey!}
      label={formatMessage({ id: inputDef.labelKey! })}
      rules={inputDef.rules}
    >
      <Select
        mode={multiple ? 'multiple' : undefined}
        placeholder={inputDef.placeholderKey && localization.formatMessageByStr(inputDef.placeholderKey)}
        onSearch={setSearch}
        onDeselect={() => setSearch('')}
        style={{ width: 400 }}
        loading={loading}
        filterOption={false}
      >
        {((data && data.products && data.products.nodes) || []).map(product => (
          <Select.Option key={product.id} value={product.id}>
            {product.heading}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};


const DATA_QUERY = gql`
  query ReportProductInputQuery($search: String) {
    products(criteria: { name: $search, fetchSize: { limit: 250 } }) {
      hash
      nodes {
        id
        heading
      }
    }
  }
`;


export default ReportProducts;

