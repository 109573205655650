import { gql, useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useIntl } from 'react-intl';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Locale } from '../../../../localization/LocalizationKeys';
import { usePermission } from '../../../permission/usePermission';
import { NavigationListsFunction_DataQuery } from '../../../layout/Navigation/NavigationListsFunction';
import {
  ListStarQueryQuery,
  ListStarQueryQueryVariables, SetPinStatusForListMutation, SetPinStatusForListMutationVariables,
  updateFavListByStarMutation,
  updateFavListByStarMutationVariables
} from '../../../../gql/typings';

const ListDetailsStar: React.FC<{ listId: number }> = ({
  listId,
}) => {
  const { update } = usePermission('LIST_PIN');
  const { formatMessage } = useIntl();
  const { data } = useQuery<ListStarQueryQuery, ListStarQueryQueryVariables>(DATA_QUERY, { variables: { listId } });
  const [updateFavStatus] = useMutation<updateFavListByStarMutation, updateFavListByStarMutationVariables>(FAV_MUTATION);
  const [updatePinStatus] = useMutation<SetPinStatusForListMutation, SetPinStatusForListMutationVariables>(PIN_MUTATION);

  const isPinned = data?.list?.isPinned;
  const isFavorite = data?.list?.isFavoriteList;

  const onClick = () => {


    const refetchQueries = [
      NavigationListsFunction_DataQuery,
      DATA_QUERY,
    ];
    const favorite = (enabled: boolean) => updateFavStatus({
      refetchQueries,
      variables: {
        listId,
        enabled,
      },
    });
    const pin = (enabled: boolean) => updatePinStatus({
      refetchQueries,
      variables: {
        listId,
        enabled,
      },
    });

    if (!isFavorite && !isPinned) {
      favorite(true);
    } else if (update && !isPinned) {
      pin(true);
    } else if (update && isPinned) {
      pin(false);

    } else if (!update && isFavorite) favorite(false);
    // else if (isPinned) console.log('You do not have access to this!');
  };

  let starClassNames = 'list-details-star-container';
  starClassNames += isPinned ? ' pin' : isFavorite ? ' favorite' : ' standard';
  starClassNames += isPinned && !update ? ' locked' : '';

  let tooltipText = formatMessage(Locale.Text.Press_to_mark_list_as_favorite);
  if (isFavorite && update) {
    tooltipText = formatMessage(Locale.Text.Press_to_mark_list_as_pinned);
  } else if (isFavorite && !update) {
    tooltipText = formatMessage(Locale.Text.Press_to_remove_list_from_favorites);
  } else if (isPinned && update) {
    tooltipText = formatMessage(Locale.Text.Press_to_unpin_list);
  } else if (isPinned && !update) tooltipText = formatMessage(Locale.Text.This_list_is_pinned_globally_for_all_users);


  return (
    <Tooltip title={tooltipText} mouseEnterDelay={0.33}>
      {(isPinned || isFavorite)
        ? <StarFilled className={starClassNames} onClick={onClick} />
        : <StarOutlined className={starClassNames} onClick={onClick} />}
    </Tooltip>
  );
};

const PIN_MUTATION = gql`
  mutation SetPinStatusForList($listId: Int!, $enabled: Boolean!) {
    updatePinList(listId: $listId, enabled: $enabled) { id }
    updateFavoriteList(listId: $listId, enabled: false) @include(if: $enabled) { id }
  }
`;

const FAV_MUTATION = gql`
  mutation updateFavListByStar($listId: Int!, $enabled: Boolean!) {
    updateFavoriteList(listId: $listId, enabled: $enabled) { id }
  }
`;

const DATA_QUERY = gql`
  query ListStarQuery($listId: Int!) {
    list(id: $listId) {
      id
      isPinned
      isFavoriteList
    }
  }
`;

export default ListDetailsStar;
