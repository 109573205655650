import React from 'react';
import { Card, Col, Row } from 'antd';
import DateDisplay from '../../../components/Badge/DateDisplay';
import DcrEntity from '../../../components/Badge/DcrEntity';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import { DcrGroupDetailQuery } from '../../../../gql/typings';


const DcrAttributes: React.FC<{ group: DcrGroupDetailQuery['dcrGroup'] }> = ({
  group,
}) => {
  const localization = useLocalization();

  return (
    <Card>
      <Row className="detail-view-row" gutter={[10, 10]}>
        <Col span={3}>
          <span className="detail-view-label">
            {localization.formatMessage(Locale.Attribute.ID)}
          </span>
        </Col>
        <Col span={5}>
          <span className="dcr-group-id">{group?.id}</span>
        </Col>
        <Col span={3}>
          <span className="detail-view-label">
            {localization.formatMessage(Locale.General.Entities)}
          </span>
        </Col>
        <Col span={5}>
          <DcrEntity nodes={group?.dcrs.nodes ?? []} />
        </Col>
        <Col span={3}>
          <span className="detail-view-label">
            {localization.formatMessage(Locale.Attribute.Created)}
          </span>
        </Col>
        <Col span={5}>
          <DateDisplay>{group?.ct}</DateDisplay>
        </Col>
        <Col span={3}>
          <span className="detail-view-label">
            {localization.formatMessage(Locale.Attribute.DCR_requester)}
          </span>
        </Col>
        <Col span={5}>
          <span className="requester-user" data-requester-user-id={group?.responsibleUser.id}>
            {group?.responsibleUser.fullname}
          </span>
        </Col>
        <Col span={3}>
          <span className="detail-view-label">
            {localization.formatMessage(Locale.Text.DCR_comment)}
          </span>
        </Col>
        <Col span={5}>
          <span className="requester-user" data-requester-user-id={group?.responsibleUser.id}>
            {group?.GroupComment?.comment}
          </span>
        </Col>
      </Row>
    </Card>
  );
};


DcrAttributes.propTypes = {};


export default DcrAttributes;
