import React from 'react';
import { Tooltip } from 'antd';
import * as PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import overviewCompletedIcon from '../../../../img/icon-overview-completed.svg';
import { Locale } from '../../../../localization/LocalizationKeys';

const ActivityOverviewMenu = ({
  onChange,
  isCompletedActive,
}) => {
  const intl = useIntl();
  return (
    <div className="top-actions">
      <div className="overview-view-type">
        <ul>
          <li className={isCompletedActive ? 'active' : ''}>
            <Tooltip
              placement="top"
              title={isCompletedActive
                ? intl.formatMessage(Locale.Text.Hide_completed_activities)
                : intl.formatMessage(Locale.Text.Include_completed_activities)}
            >
              <img
                src={overviewCompletedIcon}
                onClick={() => onChange('completed')}
                alt={intl.formatMessage(Locale.Text.Show_all_activities)}
              />
            </Tooltip>
          </li>
        </ul>
      </div>
    </div>
  );
};

ActivityOverviewMenu.propTypes = {
  isCompletedActive: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ActivityOverviewMenu;
