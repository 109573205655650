// noinspection RedundantIfStatementJS

import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { List, message, Spin, Typography } from 'antd';
import { sortBy } from 'lodash';
import { NavLink } from 'react-router-dom';
import { MergeEventEmitter, MergeEventValue, useEntityMergeEmitter } from './useEntityMergeEmitter';
import {
  AdminEntityMergeRequestQuery,
  AdminEntityMergeRequestQueryVariables, EntityMergeStatusEnum,
  EntityTypeEnum, PerformEntityMergeMutationMutation, PerformEntityMergeMutationMutationVariables,
} from '../../../../../../gql/typings';
import AdminEntityMergeRequestItem, {
  AdminEntityMergeItem_FRAGMENT,
  AdminEntityMergeRequestItemProps,
} from './Item/AdminEntityMergeRequestItem';
import { Optional, StateArray } from '../../../../../util/StateArrayType';
import { useLocalization } from '../../../../../util/useLocalization';
import { Locale } from '../../../../../../localization/LocalizationKeys';

export type EntityMergeHookValue = {
  emitter: MergeEventEmitter;
  from: Optional<NonNullable<AdminEntityMergeRequestQuery['virtualMergeOverview']>['from']>;
  into: Optional<NonNullable<AdminEntityMergeRequestQuery['virtualMergeOverview']>['into']>;
  loading: boolean;
  commitEmitterState: (items?: MergeEventValue[]) => Promise<unknown>;
  status: Optional<EntityMergeStatusEnum>;
  dataFields: Optional<NonNullable<AdminEntityMergeRequestQuery['virtualMergeOverview']>['overview']>;
  render: () => React.ReactElement;
  generateDescription: () => React.ReactElement;
  showMaintainerIgnoredState: StateArray<boolean>;
  showNonConflictState: StateArray<boolean>;
  doMerge: (mergeRequestId: number) => Promise<unknown>;
};

export const useEntityMerge = (
  entityType: Optional<EntityTypeEnum>,
  loserId: Optional<number>,
  winnerId: Optional<number>,
): EntityMergeHookValue => {
  const localization = useLocalization();
  const emitter = useEntityMergeEmitter();
  const showMaintainerIgnoredState = useState(true);
  const showNonConflictState = useState(true);
  const [loading, setLoading] = useState(false);
  const [
    load,
    { data, loading: dataLoading, error },
  ] = useLazyQuery<AdminEntityMergeRequestQuery, AdminEntityMergeRequestQueryVariables>(DATA_QUERY);
  const [
    performMerge,
    { loading: isMerging },
  ] = useMutation<PerformEntityMergeMutationMutation, PerformEntityMergeMutationMutationVariables>(MERGE_MUTATION);

  const doMerge = async (mergeRequestId: number) => commitEmitterState().then(() => performMerge({
    variables: {
      mergeRequestId,
    },
  })).catch((e) => {
    message.error(e?.message ?? 'Something went wrong, please contact support!');
  });
  const from = data?.virtualMergeOverview?.from;
  const into = data?.virtualMergeOverview?.into;


  useEffect(() => {
    if (entityType && loserId && winnerId) {
      load({
        variables: {
          entityType,
          fromId: loserId,
          intoId: winnerId,
        }
      });
    }
  }, [entityType, load, loserId, winnerId]);

  const commitEmitterState = (
    items: MergeEventValue[] = Object.values(emitter.stateRef.current ?? []),
  ): Promise<unknown> => {
    if (!into || !emitter.stateRef.current || !emitter.canSave()) {
      // eslint-disable-next-line no-console
      console.error('Error', emitter.stateRef.current);
      return Promise.reject(Error(
        localization.formatMessage(Locale.Text.Merge_request_missing_handling_conflicts_message)
      ));
    }
    function execute(items: MergeEventValue[]) {
      const item = items[0];
      if (!item) return Promise.resolve();
      if (!item.onSave) return Promise.resolve(commitEmitterState(items.splice(1)));
      return items[0]!.onSave!().then(() => commitEmitterState(items.splice(1)));
    }
    setLoading(true);
    return execute(items).finally(() => setLoading(false));
  };

  const generateDescription = () => {
    if (from?.__typename === 'Person' && into?.__typename === 'Person') return (
      <Typography.Text>
        <NavLink to={`/hcp/${from.id}`}>
          {from.fullName} (ID: {from.id})
        </NavLink> is set to be merged into <NavLink to={`/hcp/${into.id}`}>
          {into.fullName} (ID: {into.id})
        </NavLink>.
        <br />
        Here's an overview of what data will be transferred over.
      </Typography.Text>
    );
    if (from?.__typename === 'Site' && into?.__typename === 'Site') return (
      <Typography.Text>
        <NavLink to={`/hco/${from.id}`}>
          {from.name} (ID: {from.id})
        </NavLink> is set to be merged into <NavLink to={`/hco/${into.id}`}>
          {into.name} (ID: {into.id})
        </NavLink>.
        <br />
        Here's an overview of what data will be transferred over.
      </Typography.Text>
    );
    if (from?.__typename === 'SitePerson' && into?.__typename === 'SitePerson') return (
      <Typography.Text>
        The link:<br />
        <NavLink to={`/hcp/${from.person.id}`}>{from.person.fullName} (ID: {from.person.id})</NavLink>
        &nbsp;@
        &nbsp;<NavLink to={`/hco/${from.site.id}`}>{from.site.name} (ID: {from.site.id})</NavLink>
        (Link ID: <NavLink to={`/link/${from.id}`}>{from.id})</NavLink><br />
        is set to be merged into<br />
        <NavLink to={`/hcp/${into.person.id}`}>{into.person.fullName} (ID: {into.person.id})</NavLink>
        &nbsp;@
        &nbsp;<NavLink to={`/hco/${into.site.id}`}>{into.site.name} (ID: {into.site.id})</NavLink>
        (Link ID: <NavLink to={`/link/${into.id}`}>{into.id}</NavLink>)
        <br />
        <br />
        Here's an overview of what data will be transferred over.
      </Typography.Text>
    );
    return <span />;
  };

  const ds = sortBy((data?.virtualMergeOverview.overview ?? [])
    .filter(item => {
      if (!showMaintainerIgnoredState[0] && item.ignoredByMaintainer) return false;
      if (!showNonConflictState[0] && !item.hasConflict) return false;
      return true;
    })
    .map(item => ({
      item,
      emitter: emitter.$,
      _stateRef: emitter.stateRef,
      customEmitter: emitter.customEmitter,
      from,
      into,
    } as AdminEntityMergeRequestItemProps)),
  ({ item }) => {
    if (item.type?.__typename === 'PersonLink_EntityDataToBeTransferredType') return item.hasConflict ? 40 : 30;
    if (item.hasConflict) return 10;
    return item.ignoredByMaintainer ? 20 : 30;
  });

  return {
    emitter,
    from,
    into,
    loading: dataLoading || loading || isMerging,
    commitEmitterState,
    status: data?.virtualMergeOverview.status,
    dataFields: data?.virtualMergeOverview.overview,
    generateDescription,
    showMaintainerIgnoredState,
    showNonConflictState,
    doMerge,
    render: () => (
      <List
        dataSource={ds}
        renderItem={AdminEntityMergeRequestItem}
        locale={{ emptyText: loading
          ? <Spin />
          : error?.message
            ? <span>Something went wrong</span>
            : <span>No data conflicts</span>
        }}
      />
    )
  };
};


const MERGE_MUTATION = gql`
  mutation PerformEntityMergeMutation($mergeRequestId: ID!) {
    performMerge(mergeRequestId: $mergeRequestId) {
      fromId
      intoId
      hasConflict
    }
  }
`;

const DATA_QUERY = gql`
  query AdminEntityMergeRequest($entityType: EntityTypeEnum!, $fromId: ID!, $intoId: ID!) {
    virtualMergeOverview(entityType: $entityType, fromId: $fromId, intoId: $intoId) {
      entityType
      status
      from { ...EntityMergeFragment }
      into { ...EntityMergeFragment }
      overview: mergeOverview {
        ...AdminEntityMergeItemFragment
      }
    }
  }

  fragment EntityMergeFragment on Model {
    id
    ... on Person {
      id
      countryCode
      fullName
    }
    ... on Site {
      id
      countryCode
      name
    }
    ... on SitePerson {
      id
      countryCode
      person {
        id
        fullName
      }
      site {
        id
        name
      }
      position {
        code
        type
        displayLabel
      }
    }
  }
  ${AdminEntityMergeItem_FRAGMENT}
`;
