import React, { useEffect, useState } from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Tree, Skeleton } from 'antd';
import { NavLink } from 'react-router-dom';
import { gql, useApolloClient, useQuery } from '@apollo/client';
import { Optional } from '../../util/StateArrayType';
import { SiteHierarchyQueryQuery, SiteHierarchyQueryQueryVariables } from '../../../gql/typings';

type SiteTreeHierarchyProps = {
  siteId: number;
};

type TreeNodeRaw<T> = {
  key: string;
  title: string;
  disabled?: boolean;
  selectable?: boolean;
  icon?: React.ReactNode;
  children?: T[];
};

// @ts-ignore
type TreeNode = TreeNodeRaw<TreeNode>;

function buildTree(site: NonNullable<SiteHierarchyQueryQuery['site']>, includeParents: boolean): TreeNode[] {
  let tmpTree: TreeNode[] = [];
  let last: Optional<TreeNode> = null;

  const thisNode: TreeNode = {
    key: site.id,
    title: `${site.name}${site.name2 ? `, ${site.name2}` : ''}`,
    icon: <HomeOutlined />,
    children: [],
  };

  if (includeParents) {
    site.parentHierarchy.nodes.map(parent => {
      const newNode: TreeNode = {
        key: parent.ancestor.id,
        title: <NavLink to={`/hco/${parent.ancestor.id}`}>{parent.ancestor.name}</NavLink>,
        icon: <HomeOutlined />,
        children: [],
      };
      if (last == null) {
        tmpTree.push(newNode);
      } else {
        last.children.unshift(newNode);
      }
      last = newNode;
    });
  }
  if (includeParents && last == null) {
    tmpTree.push(thisNode);
  } else if (includeParents && last != null) {
    last.children.push(thisNode);
  } else if (!includeParents) {
    tmpTree = thisNode.children;
  }

  site.childrenHierarchy.nodes.slice().sort((a, b) => a.descendant.name.localeCompare(b.descendant.name)).map(child => {
    thisNode.children.push({
      key: child.descendant.id,
      title: <NavLink to={`/hco/${child.descendant.id}`}>{child.descendant.name}</NavLink>,
      isLeaf: child.descendant.childrenHierarchy.totalCount === 0,
      icon: <HomeOutlined />,
    });
  });

  return tmpTree;
}

function updateTreeData(list: TreeNode[], key: React.Key, children: TreeNode[]): TreeNode[] {
  return list.map(node => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });
}

const SiteTreeHierarchy: React.FC<SiteTreeHierarchyProps> = ({ siteId }) => {
  const client = useApolloClient();
  const { data, loading } = useQuery<SiteHierarchyQueryQuery, SiteHierarchyQueryQueryVariables>(DATA_QUERY, {
    variables: { siteId },
  });
  const [tree, setTree] = useState<TreeNode[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    if (!loading && data?.site) {
      setTree(buildTree(data.site, true));
      setExpandedKeys([siteId]);
    }
  }, [data, loading, siteId]);

  const onLoadData = (treeNode: TreeNode): Promise<void> => {
    if (!treeNode.children || treeNode.children.length === 0) {
      return client.query<SiteHierarchyQueryQuery, SiteHierarchyQueryQueryVariables>({
        query: DATA_QUERY,
        variables: { siteId: treeNode.key },
      }).then(res => {
        if (res.data.site) {
          setTree(updateTreeData(tree, treeNode.key, buildTree(res.data.site, false)));
        }
      });
    }
    return Promise.resolve();
  };

  return (
    <Skeleton loading={loading}>
      <Tree.DirectoryTree
        showIcon
        showLine
        selectable={false}
        autoExpandParent
        expandedKeys={expandedKeys}
        onExpand={setExpandedKeys}
        treeData={tree}
        loadData={onLoadData}
      />
    </Skeleton>
  );
};

const DATA_QUERY = gql`
  query SiteHierarchyQuery($siteId: Int!) {
    site(siteId: $siteId) {
      id
      name
      name2
      parentHierarchy { ...HierarchyConnectionFragment }
      childrenHierarchy { ...HierarchyConnectionFragment }
    }
  }
  
  fragment HierarchyConnectionFragment on SiteClosureConnection {
    hash
    totalCount
    nodes {
      ancestorId
      descendantId
      depth
      descendant {
        id
        name
        childrenHierarchy { hash, totalCount }
      }
      ancestor {
        id
        name
        childrenHierarchy { hash, totalCount }
      }
    }
  }
`;

export default SiteTreeHierarchy;
