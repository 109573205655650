import React, { useContext, useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, message } from 'antd';
import { useIntl } from 'react-intl';
import { ActivityDetailContext } from '../ActivityDetailsTypes';
import DetailsPageSection from '../DetailsPageSection';
import AdvanceInputOnly from '../../../../components/Input/AdvanceInputOnly';
import { Locale } from '../../../../../localization/LocalizationKeys';
import AnimatedTextError from '../../../../components/AnimatedTextError/AnimatedTextError';
import {
  ActivityHeadingDetailsQueryQuery, ActivityHeadingDetailsQueryQueryVariables,
  UpdateActivityTitleMutation,
  UpdateActivityTitleMutationVariables
} from '../../../../../gql/typings';


const ActivityTitle: React.FC = () => {
  const {
    heading,
    dispatch,
    activityId,
    headingErrors,
  } = useContext(ActivityDetailContext);
  const [, setIsEditing] = useState(activityId === null);
  const [
    updateActivityTitle,
  ] = useMutation<UpdateActivityTitleMutation, UpdateActivityTitleMutationVariables>(UPDATE_ACTIVITY_TITLE);
  const intl = useIntl();
  const { data } = useQuery<ActivityHeadingDetailsQueryQuery, ActivityHeadingDetailsQueryQueryVariables>(DATA_QUERY, {
    variables: { activityId: activityId! },
    skip: !activityId
  });
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (data?.activity?.heading) {
      dispatch({ type: 'updateHeading', value: data.activity.heading });
    }
  }, [activityId, data, dispatch]);

  const onSave = () => {
    if (activityId) {
      setIsEditing(false);
      const beforeHeading = data?.activity?.heading ?? '';
      if (beforeHeading === heading) return;
      const undo = () => {
        messageApi.destroy('activity-success-update');
        updateActivityTitle({
          variables: { activityId, title: beforeHeading },
        }).then(() => messageApi.success(intl.formatMessage(Locale.Text.Successfully_reverted_activity_title)));
      };
      updateActivityTitle({
        variables: {
          activityId,
          title: heading ?? '',
        },
      }).then(() => messageApi.success({
        key: 'activity-success-update',
        content: (
          <span>
            {intl.formatMessage(Locale.Text.Successfully_updated_activity_title,
              { beforeHeading: <b>{beforeHeading}</b>, newHeading: <b>{heading}</b> })}
            &nbsp;
            <Button type="link" onClick={undo}>
              {intl.formatMessage(Locale.Command.Undo)}
            </Button>
          </span>
        ),
      }));
    }
  };

  return (
    <div className="activity-heading-details-container">
      { contextHolder }
      <DetailsPageSection
        heading={intl.formatMessage(Locale.Attribute.Heading)}
        className='activity-detail-margin-fix'
      >
        <AdvanceInputOnly
          suffix="PERSON"
          onBlur={onSave}
          value={heading}
          onChange={e => dispatch({ type: 'updateHeading', value: e.target.value })}
        />
        <AnimatedTextError message={typeof headingErrors?.[0] === 'string' ? headingErrors[0] : headingErrors?.[0]?.message} />
      </DetailsPageSection>
    </div>
  );
};

const UPDATE_ACTIVITY_TITLE = gql`
  mutation UpdateActivityTitle($activityId: Int!, $title: String!) {
    updateActivity(input: { activityId: $activityId, heading: $title }) {
      id
      heading
    }
  }
`;

const DATA_QUERY = gql`
  query ActivityHeadingDetailsQuery($activityId: Int!) {
    activity(id: $activityId) {
      id
      heading
      typeCode
      statusCode
      startDate
      endDate
    }
  }
`;

export default ActivityTitle;
