import React from 'react';
import { flatten, identity } from 'lodash';
import { useQuery } from '@apollo/client';
import FieldLabel from '../FieldLabel/FieldLabel';
import {
  entitiesTableConfigs,
  SupportedEntitySearchTypes,
  TableFieldReturnedRecordPageType,
} from '../../browse/search_old/types';
import FieldLabelGroup from '../FieldLabel/FieldLabelGroup';
import { generateGraphQL } from '../../util/graphqlUtils';
import { useFieldOptionAttributes } from './useFieldOptionAttributes';
import { GridCardFieldFragment } from '../../../gql/typings';

type CardAttributesProps = {
  recordId: number;
  entityType: SupportedEntitySearchTypes;
  rootEntityType?: SupportedEntitySearchTypes;
  fields: GridCardFieldFragment[];
  gradientFooter?: boolean;
};


const CardAttributes: React.FC<CardAttributesProps> = ({
  recordId,
  entityType,
  rootEntityType,
  fields,
  gradientFooter,
}) => {
  const typeConfig = entitiesTableConfigs[entityType];
  const { optionMapGet, items } = useFieldOptionAttributes(entityType, fields);
  const query = generateGraphQL(flatten(items.map(i => i[0].updatedQueries)).filter(e => e) as string[]);
  const { data, loading, refetch } = useQuery(typeConfig.buildRecordQuery(query), { variables: { recordId } });

  return (
    <div>
      <FieldLabelGroup>
        {items.filter(identity).map(config => {
          const optionData = optionMapGet(config[0].key);
          return <FieldLabel
            key={config[0].key + (config[1].keyCode ?? '')}
            record={data?.record}
            loading={loading || (!!config[1].keyCode && !optionData)}
            refetch={refetch}
            optionData={optionData}
            config={config}
            entityType={rootEntityType ?? entityType}
            viewingFrom={TableFieldReturnedRecordPageType.DETAIL_PAGE}
          />;
        })}
      </FieldLabelGroup>
      {gradientFooter && <div className="gradientback" />}
    </div>
  );
};

export default CardAttributes;
