import React from 'react';
import { Form } from 'antd';
import MainView from '../../components/View/MainView';
import { EntitiesSearch } from '../../components/entitiesSearch';
import { usePageTitle } from '../../hooks/usePageTitle';
import { Locale } from '../../../localization/LocalizationKeys';
import { usePersonTableQuickActions } from './hooks/usePersonTableQuickActions';
import { EntityTypeEnum } from '../../../gql/typings';
import EstherEasterEgg from '../../components/EasterEggs/EstherEasterEgg';
import { useUser } from '../../util/useUser';
import { useLocalization } from '../../util/useLocalization';
import { useSystemPermission } from '../../permission/useSystemPermission';


const PersonView = () => {
  const localization = useLocalization();
  const headerStr = localization.formatMessage(Locale.General.Persons_HCP);
  usePageTitle(headerStr);
  const [form] = Form.useForm();
  const { entitiesSearchProps, tableSelection, HiddenRender } = usePersonTableQuickActions(form, { maintainPageState: true });
  const { username } = useUser();
  const hasGlobalSearchPermission = useSystemPermission('globalSearch');

  return (
    <MainView className="person-view-container" boundingBox={false}>
      {username.slice(-14) === '@apurebase.com' && (
        <EstherEasterEgg
          text={['Launching', 'PureAdvance MDM', '2023 Hooray!!']}
        />
      )}
      <EntitiesSearch
        entityType={EntityTypeEnum.PERSON}
        form={form}
        globalSupport
        globalSearch={hasGlobalSearchPermission}
        urlSearchEnabled
        tableSelection={tableSelection}
        {...entitiesSearchProps}
      />
      <HiddenRender />
    </MainView>
  );
};

export default PersonView;
