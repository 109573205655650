import { gql } from '@apollo/client';


export const ManualPersonAction_FRAGMENT = gql`
  fragment ManualPersonActionFragment on ImportManualPersonStage {
    projectId
    externalIdTypeCode
    externalIdValue
    fuzzySearchAvailable
    candidatesFor {
      fieldName
      fieldNameKey
      value
    }
    candidates {
      apurebaseId
      inLocalDb
      score
      fields {
        fieldName
        fieldNameKey
        value
      }
    }
  }
`;

export const DownloadPersonByApbId_MUTATION = gql`
  mutation DownloadPersonByApbId($apbId: Int!) {
    person: cherryPick(entityType: PERSON, apurebaseIds: [$apbId]) {
      id
    }
  }
`;
