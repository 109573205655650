import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Locale } from '../../../../localization/LocalizationKeys';
import ActivityOverViewCard from '../../../components/Card/ActivityOverViewCard';
import { activityConnectionOverviewFragment } from '../../../../gql/typings';

const Cards: React.FC<{
  connection: activityConnectionOverviewFragment | undefined;
  intl: IntlShape;
}> = ({ connection, intl }) => {
  // TODO: create a better view for when no activities are found
  if ((connection?.totalCount ?? 0) < 1) {
    return <h3>{intl.formatMessage(Locale.Text.No_activities_in_timespan)}</h3>;
  }

  return (
    <>
      {connection?.nodes.map(it => <ActivityOverViewCard
        key={it.id}
        id={it.id}
        title={it.heading}
        time={it.time}
        end={it.end}
        persons={it.persons}
        sites={it.sites}
        status={it.status}
        type={it.type}
      />)}
    </>
  );
};

const ActivityColumn: React.FC<{
  connection: activityConnectionOverviewFragment | undefined;
  title?: React.ReactNode;
  bold?: boolean;
}> = ({ connection, title, bold }) => {
  const intl = useIntl();
  return (
    <div className={`overview-column ${bold ? 'current-day' : ''}`}>
      {title && <h2>{title}</h2>}
      <div className="activity-cards">
        <Cards connection={connection} intl={intl} />
      </div>
    </div>
  );
};

export default ActivityColumn;
