import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, notification } from 'antd';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import MainView from '../../../components/View/MainView';
import { usePageTitle } from '../../../hooks/usePageTitle';
import CreatePerson from './CreatePerson';
import { CreatePersonProvider } from './CreatePersonProvider';
import {
  CreatePersonMutationMutation,
  CreatePersonMutationMutationVariables,
  DcrResponseTypeEnum
} from '../../../../gql/typings';

const CreatePersonView: React.FC = () => {
  const localization = useLocalization();
  const navigate = useNavigate();
  const [
    create,
    { loading },
  ] = useMutation<CreatePersonMutationMutation, CreatePersonMutationMutationVariables>(CREATE_PERSON_MUTATION);
  const [api, contextHolder] = notification.useNotification();

  usePageTitle(localization.formatMessage(Locale.Command.Create_Person));


  return (
    <MainView
      className="create-person-view-container"
      title={localization.formatMessage(Locale.Command.Create_Person)}
    >
      {contextHolder}
      <CreatePersonProvider
        onCreate={input => create({ variables: { input } }).then(res => {
          if (res.data?.createPerson?.person) navigate(`/hcp/${res.data.createPerson.person.id}`);
          if (res.data?.createPerson.dcr && res.data.createPerson.dcr.currentResponseCode !== DcrResponseTypeEnum.ACCEPT) {
            api.open({
              message: 'Created a DCR',
              description: 'A DCR was created',
              btn: <Button type="primary" onClick={() => navigate(`/dcr/${res.data?.createPerson.dcr!.id}`)}>
                {localization.formatMessage(Locale.Command.Open_DCR)}
              </Button>,
            });
          }
        })}
      >
        <CreatePerson loading={loading} allowAddWorkplace />
      </CreatePersonProvider>
    </MainView>
  );
};


export const CREATE_PERSON_MUTATION = gql`
  mutation CreatePersonMutation($input: PersonInput!) {
    createPerson(input: $input) {
      person {
        id
        fullName
      }
      dcr {
        id
        currentResponseCode
      }
    }
  }
`;
export default CreatePersonView;
