import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Col } from 'antd';
import { EntityTabFieldProps } from '../EntityTabFieldsTypes';
import {
  CustomField_FRAGMENT,
  CustomFieldProps,
  CustomFieldValue_FRAGMENT
} from '../../../../CustomField/CustomFieldUtils';
import CustomFieldText from '../../../../CustomField/CustomFieldText';
import CustomFieldSwitch from '../../../../CustomField/CustomFieldSwitch';
import CustomFieldNumber from '../../../../CustomField/CustomFieldNumber';
import CustomFieldTag from '../../../../CustomField/CustomFieldTag';
import CustomFieldSelectMultiSupport from '../../../../CustomField/CustomFieldSelectMultiSupport';
import CustomFieldCheckMultiSupport from '../../../../CustomField/CustomFieldCheckMultiSupport';
import {
  CustomFieldTypeEnum,
  EntityTabCustomFieldDataQueryQuery,
  EntityTabCustomFieldDataQueryQueryVariables
} from '../../../../../../gql/typings';


export const customFieldTypes: Record<CustomFieldTypeEnum,
React.FC<CustomFieldProps>> = {
  TEXT: CustomFieldText,
  SELECT: CustomFieldSelectMultiSupport,
  CHECK: CustomFieldCheckMultiSupport,
  NUMBER: CustomFieldNumber,
  SWITCH: CustomFieldSwitch,
  TAG: CustomFieldTag,
};

const CustomFieldTabField: React.FC<EntityTabFieldProps> = (props) => {
  const {
    data,
    refetch,
  } = useQuery<EntityTabCustomFieldDataQueryQuery, EntityTabCustomFieldDataQueryQueryVariables>(DATA_QUERY, {
    variables: { fieldId: props.field.id, recordId: props.recordId },
  });

  if (!data?.info?.fieldConfig?.customField.fieldType.enum) return <></>;

  const ToDisplay = customFieldTypes[data.info.fieldConfig.customField.fieldType.enum]!;

  if (!data?.info?.fieldConfig.customField.isActive) {
    return <></>;
  }

  return (
    <>
      {
        data?.info?.fieldConfig?.customField.myAccessRules!.allowRead && <>
          <Col className="custom-field-heading" span={8}>
            {data?.info?.fieldConfig?.customField?.heading}:
          </Col>
          <Col span={16}>
            <ToDisplay
              recordId={props.recordId}
              field={data.info.fieldConfig.customField}
              values={data.info.fieldConfig.customField.customLinkValues!}
              refreshData={refetch}
            />
          </Col>
        </>
      }
    </>
  );
};

const DATA_QUERY = gql`
  query EntityTabCustomFieldDataQuery($fieldId: Int!, $recordId: Int!) {
    info: entityTabGridConfigurationField(id: $fieldId) {
      id
      fieldConfig: customFieldConfiguration {
        id
        customField {
          code
          heading
          myAccessRules {
            id
            allowMutation
            allowRead
          }
          ...CustomFieldFragment
          customLinkValues(recordId: $recordId, criteria: { fetchSize: { limit: null } }) {
            hash
            ...CustomFieldValueFragment
          }
        }
      }
    }
  }
  ${CustomField_FRAGMENT}
  ${CustomFieldValue_FRAGMENT}
`;

export default CustomFieldTabField;
