import { Badge, Form, Select, Switch } from 'antd';
import React from 'react';
import { CreateCustomFieldTypeConfigurationsProps } from '../createCustomFieldTypeConfigurations';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import Icon from '../../../../components/Icon/Icon';


const CreateCustomFieldTagConfiguration: React.FC<CreateCustomFieldTypeConfigurationsProps> = ({
  field,
}) => {
  const defaultValue = field?.color || 'default';
  const defaultStatus = field != null;
  const localization = useLocalization();
  return (
    <div>
      <Form.Item
        label={<Icon
          iconType="INFO"
          text={localization.formatMessage(Locale.Text.Custom_Field_Tag_color)}
          iconBeforeText={false}
          toolTip={localization.formatMessage(Locale.Text.Custom_Field_Tag_color_ToolTip)}
        />}
        initialValue={defaultValue}
        name="colorConfiguration"
      >
        <Select style={{ width: '50%', minWidth: '100px' }}>
          <Select.Option value="default"><Badge color="#d1d1d1" text="Default" /></Select.Option>
          <Select.Option value="pink"><Badge color="pink" text="Pink" /></Select.Option>
          <Select.Option value="red"><Badge color="red" text="Red" /></Select.Option>
          <Select.Option value="yellow"><Badge color="yellow" text="Yellow" /></Select.Option>
          <Select.Option value="orange"><Badge color="orange" text="Orange" /></Select.Option>
          <Select.Option value="cyan"><Badge color="cyan" text="Cyan" /></Select.Option>
          <Select.Option value="green"><Badge color="green" text="Green" /></Select.Option>
          <Select.Option value="blue"><Badge color="blue" text="Blue" /></Select.Option>
          <Select.Option value="purple"><Badge color="purple" text="Purple" /></Select.Option>
          <Select.Option value="geekblue"><Badge color="geekblue" text="Geek Blue" /></Select.Option>
          <Select.Option value="magenta"><Badge color="magenta" text="Magenta" /></Select.Option>
          <Select.Option value="volcano"><Badge color="volcano" text="Volcano" /></Select.Option>
          <Select.Option value="gold"><Badge color="gold" text="Gold" /></Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={<Icon
          iconType="INFO"
          text={localization.formatMessage(Locale.General.Status)}
          iconBeforeText={false}
          toolTip={localization.formatMessage(Locale.Text.Custom_Field_Status_ToolTip)}
        />}
        valuePropName="checked"
        initialValue={defaultStatus}
        name="isActive"
      >
        <Switch />
      </Form.Item>
    </div>
  );
};

export default CreateCustomFieldTagConfiguration;
