import React from 'react';
import { useIntl } from 'react-intl';
import * as PropTypes from 'prop-types';
import { Locale } from '../../../../localization/LocalizationKeys';

const productFields = ({
  product,
}) => {
  const { formatMessage } = useIntl();
  return (
    <div className="product-field-container">
      <div className="product-dispay-fields">
        <div className="field-header">Code</div>
        <div className="field-label">{product.code ? product.code : ''}</div>
      </div>
      <div className="product-dispay-fields">
        <div className="field-header">{formatMessage(Locale.Attribute.Strength)}</div>
        <div className="field-label">{product.strength ? product.strength : ''}</div>
      </div>
      <div className="product-dispay-fields">
        <div className="field-header">{formatMessage(Locale.Attribute.Strength_Unit_Type)}</div>
        <div className="field-label">
          {product.strengthUnitType.name ? `${product.strengthUnitType.name} (${product.strengthUnitType.abbreviation})` : ''}
        </div>
      </div>
      <div className="clear" />
    </div>
  );
};

productFields.propTypes = {
  product: PropTypes.object.isRequired,
};

export default (productFields);
