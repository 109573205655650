import { gql } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import React from 'react';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { TableConfig, TableFieldsConfig } from '../../../search_old/types';
import Icon from '../../../../components/Icon/Icon';

export const buildAdminEntityMergeRequestFragment = (additionalQuery: string|null) => gql`
  fragment AdminEntityMergeRequestFragment on EntityMerge {
    id
    ${additionalQuery ?? ''}
  }
`;

export const buildAdminEntityMergeRequestConnectionDataQuery = (additionalQuery: string|null) => gql`
  query AdminEntityMergeRequestConnectionQuery($criteria: EntityMergeCriteria) {
    connection: entityMerges(criteria: $criteria) {
      hash
      totalCount
      nodes {
        id
        ...AdminEntityMergeRequestFragment
      }
    }
  }
  ${buildAdminEntityMergeRequestFragment(additionalQuery)}
`;

export const buildAdminEntityMergeRequestRecordDataQuery = (additionalQuery: string|null) => gql`
  query AdminEntityMergeRequestRecordQuery($recordId: Int!) {
    record: entityMerge(id: $recordId) {
      id
      ...AdminEntityMergeRequestFragment
    }
  }
  ${buildAdminEntityMergeRequestFragment(additionalQuery)}
`;

export type SupportedAdminEntityMergeRequestTableFields = 'id'|'status'|'entityType';

export const supportedAdminEntityMergeRequestTableFields:
TableFieldsConfig<SupportedAdminEntityMergeRequestTableFields>['fields'] = {
  id: ({ localization }) => ({
    key: 'id',
    queryFields: ['id'],
    title: localization.formatMessage(Locale.Attribute.ID),
    render: (record) => (
      <NavLink to={`/admin/merge/${record.id}`}>
        {record.id}
      </NavLink>
    )
  }),
  status: () => ({
    key: 'status',
    queryFields: ['status'],
    title: 'Status',
    render: (record) => record.status,
  }),
  entityType: () => ({
    key: 'entityType',
    queryFields: ['entityTypeEnum'],
    title: 'Entity',
    render: (record) => (
      <Icon iconType={record.entityTypeEnum} />
    )
  })
};

export const adminEntityMergeRequestTable: TableConfig = {
  buildConnectionQuery: buildAdminEntityMergeRequestConnectionDataQuery,
  buildRecordQuery: buildAdminEntityMergeRequestRecordDataQuery,
  buildFragment: buildAdminEntityMergeRequestFragment,
  titleLabel: Locale.General.Merge_requests,
  columnConfig: {
    fields: supportedAdminEntityMergeRequestTableFields,
  },
};
