import { gql, useQuery } from '@apollo/client';
import React, { useContext, useRef } from 'react';
import { Menu, Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import Icon from '../../components/Icon/Icon';
import DragFavoriteList, { DragFavoriteListsRef } from '../../components/List/DragFavoritsList';
import { AppContext, NavigationMenuGroupRendererProps } from '../AppTypes';
import { FavoriteListsNavigationQueryQuery } from '../../../gql/typings';

const NavigationListsFunction: React.FC<Pick<NavigationMenuGroupRendererProps, 'sortListModalState'>> = ({
  sortListModalState: [sort, setSort],
}) => {
  const { collapse } = useContext(AppContext);
  const { data } = useQuery<FavoriteListsNavigationQueryQuery>(NavigationListsFunction_DataQuery);
  const ref = useRef<DragFavoriteListsRef>({});

  const favoriteLists = data?.viewer?.lists.nodes ?? [];

  const items: ItemType[] = favoriteLists.map(list => ({
    key: list.id,
    label: (
      <Tooltip title={list.description} mouseEnterDelay={3} placement="right">
        <NavLink id={`nav-${kebabCase(list.title)}`} to={`/list/${list.id}`}>
          <i className="menu-icon">
            {/* @ts-ignore */}
            <Icon iconType="LIST" className="displayCarousel" />
          </i>
          {!collapse[0] && <>
            <span className="menu-label">{list.title}</span>
            <span
              className="menu-label-count"
              style={{
                opacity: 0.65,
                paddingLeft: 2,
              }}
            >({list.affiliations.totalCount})</span>
          </>}
        </NavLink></Tooltip>
    )
  }));

  return (
    <Menu
      mode="inline"
      inlineCollapsed={collapse[0]}
      items={[
        ...items,
        {
          key: 'drag-favorite-list',
          label: <DragFavoriteList ref={ref} visible={sort} onClose={() => setSort(false)} />
        }
      ]}
    />
  );
};

export const NavigationListsFunction_DataQuery = gql`
  query FavoriteListsNavigationQuery {
    viewer {
      id
      lists: pinnedAndFavoriteLists(criteria: {fetchSize: {limit: 5}}) {
        hash
        totalCount
        nodes {
          id
          title
          description
          affiliations {
            # TODO: Should not be union based, but rather use interfaces.
            ... on PersonConnection { hash, totalCount }
            ... on ActivityConnection { hash, totalCount }
            ... on SiteConnection { hash, totalCount }
            ... on SitePersonConnection { hash, totalCount }
            ... on ActivityPersonConnection { hash, totalCount }
          }
        }
      }
    }
  }
`;

export default NavigationListsFunction;
