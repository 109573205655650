import React from 'react';
import PropType from 'prop-types';
import { Tooltip } from 'antd';

const BeekBadge = ({
  prefix = '',
  suffix = '',
  count = 0,
  greaterThan = 0,
  color = 'primary',
  tooltip,
}) => {
  if (count <= greaterThan) return null;
  return (
    <Tooltip title={tooltip}>
      <div className={`badge-container badge-icon color-${color}`}>
        {`${prefix}${count - greaterThan}${suffix}`}
      </div>
    </Tooltip>
  );
};

BeekBadge.propTypes = {
  prefix: PropType.string,
  suffix: PropType.string,
  count: PropType.number,
  tooltip: PropType.string,

  /**
     * When defined, final value displayed will be `[count] - [greaterThan]`.
     */
  greaterThan: PropType.number,
  color: PropType.oneOf(['primary', 'red', 'orange']),
};

BeekBadge.defaultProps = {
  prefix: '',
  suffix: '',
  tooltip: undefined,
  count: 0,
  greaterThan: 0,
  color: 'primary',
};

export default BeekBadge;
