const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const charactersLength = characters.length;

export function makeRandomKey(prefix?: string, length?: number): string {
  let result = prefix ?? '';
  for (let i = 0; i < (length ?? 12); i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
