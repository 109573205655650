import React, { memo } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Card, message, Skeleton } from 'antd';
import { isEqual } from 'lodash';
import { Locale } from '../../../../localization/LocalizationKeys';
import Icon from '../../../components/Icon/Icon';
import { useLocalization } from '../../../util/useLocalization';
import CustomFieldGridDisplay from './itemTypes/CustomFieldGridDisplay';
import AdminDetailedGridCardsPreview from './itemTypes/AdminDetailedGridCardsPreview';
import AdminDetailedGridRelationsPreview from './itemTypes/AdminDetailedGridRelationsPreview';
import {
  DeactivateItemGroupMutationMutation,
  DeactivateItemGroupMutationMutationVariables,
  entityGridItemConfigurationQueryQuery,
  EntityGridItemTypeEnum,
  EntityTypeEnum,
  useentityGridItemConfigurationQueryQuery
} from '../../../../gql/typings';

export type DetailedGridLayoutItemProps = {
  entityType: EntityTypeEnum;
  entityGridItemConfigurationId: number;
};

const types: Record<EntityGridItemTypeEnum, React.FC<DetailedGridLayoutItemProps>> = {
  FIELDS: CustomFieldGridDisplay,
  CARDS: AdminDetailedGridCardsPreview,
  RELATIONS: AdminDetailedGridRelationsPreview,
};

type DetailedGridLayoutProps = {
  id: number;
  entityType: EntityTypeEnum;
  active: boolean;
};

const DetailedGridLayout: React.FC<DetailedGridLayoutProps> = memo(({
  id,
  entityType,
  active,
}) => {
  const localization = useLocalization();
  const { data, loading } = useentityGridItemConfigurationQueryQuery({ variables: { id } });
  const object = data?.entityGridItemConfiguration;
  const [
    deactivateEntityGridItemConfiguration,
  ] = useMutation<DeactivateItemGroupMutationMutation, DeactivateItemGroupMutationMutationVariables>(DeactivateGridItemGroup);
  const remove = (group: NonNullable<entityGridItemConfigurationQueryQuery['entityGridItemConfiguration']>) => {
    deactivateEntityGridItemConfiguration({
      variables: { id: group.id },
      refetchQueries: ['HCPMoreDataQuery', 'ApureBaseSuggestedFields'],
    }).finally(() => message.success(localization.formatMessage(
      Locale.Text.Group_successfully_removed,
      { groupName: group.heading },
    )));
  };

  const skeleton = () => <Skeleton />;

  const ToDisplay = object ? (types[object.typeEnum] ?? skeleton) : skeleton;

  return (
    <Card
      title={object
        ? ((object.heading?.length ?? 0) > 0
          ? object.heading
          : <i>{localization.formatMessageByStr(object.type.headingKey)}</i>)
        : undefined}
      extra={<Skeleton loading={loading}><Icon iconType="CLOSE" onClick={() => object && remove(object)} /></Skeleton>}
      className={`detailed-grid-layout-container ${active ? 'active' : ''}`}
    >
      {object ? <ToDisplay entityType={entityType} entityGridItemConfigurationId={object.id} /> : skeleton()}
    </Card>
  );
}, isEqual);


const DeactivateGridItemGroup = gql`
  mutation DeactivateItemGroupMutation(
    $id: Int!
  ) {
    deactivateEntityGridItemConfiguration(id: $id)
  }
`;


gql`
  query entityGridItemConfigurationQuery($id: Int!) {
    entityGridItemConfiguration(id: $id) {
      x
      y
      width
      height
      id
      typeCode
      typeEnum
      heading
      description
      type {
        code
        headingKey
        minHeight
        minWidth
      }
    }
  }
`;


export default DetailedGridLayout;
