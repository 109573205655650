import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';
import { PersonCriteriaOrder } from '../../../../gql/typings';

export const personMainSiteTypeFieldConfig = personFieldConfig(
  'mainSiteType',
  [
    'primaryOrFirstAffiliation.id',
    'primaryOrFirstAffiliation.site.id',
    'primaryOrFirstAffiliation.site.type.code',
    'primaryOrFirstAffiliation.site.type.type',
    'primaryOrFirstAffiliation.site.type.displayLabel',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Site_type),
    additionalTableConfig: {
      width: 100
    },
    sorting: {
      ascend: PersonCriteriaOrder.PRIMARY_SITE_TYPE_ASC,
      descend: PersonCriteriaOrder.PRIMARY_SITE_TYPE_DESC,
    },
    render: record => record.primaryOrFirstAffiliation?.site?.type?.displayLabel,
  })
);
