/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { times } from 'lodash';
import { EntityPieListItem } from './DashboardPieItem';
import { PieChartDataItem } from '../../../Charts';
import { useStack } from '../../../../util/useStack';
import {
  PieDataQueryQuery,
  PieLevelDataQueryQuery,
  PieLevelDataQueryQueryVariables
} from '../../../../../gql/typings';

export type DashboardPieHook = {
  listData: EntityPieListItem[];
  replace: (index: number, newValue: EntityPieListItem) => void;
  remove: (index: number) => void;
  getCriteria: (index: number) => Record<string, string|string[]>;
};

export type HookFunc = (config: {
  configurationId: number;
  maxConfigurations: number;
  settings: PieDataQueryQuery['dashboardEntityPieSettings'];
}) => DashboardPieHook;

export const useDashboardPieHook: HookFunc = ({ configurationId, settings }) => {
  const apolloClient = useApolloClient();
  const dataStack = useStack<PieLevelDataQueryQuery>();
  const selectedStack = useStack<PieChartDataItem>();

  useEffect(() => {
    if (settings && settings.length > 0) {
      apolloClient.query({
        query: LEVEL_QUERY,
        variables: {
          configurationId,
          depth: 1,
        },
      }).then(res => {
        dataStack.keep(0);
        dataStack.push(res.data);
      });
    }
  }, [configurationId, settings]);

  const replace = (index: number, newValue: EntityPieListItem) => {
    if (newValue.selected) selectedStack.replaceAndDropAfter(newValue.selected, index - 1);
    else selectedStack.keep(index);

    if (newValue.selected) apolloClient.query<PieLevelDataQueryQuery, PieLevelDataQueryQueryVariables>({
      query: LEVEL_QUERY,
      variables: {
        configurationId,
        selection: times(index + 1).map(i => i === index
          ? { deselectedCodes: [], selectedValue: newValue.selected?.code }
          : { deselectedCodes: [], selectedValue: selectedStack.stack[i]?.code }),
      },
    }).then(res => {
      dataStack.replaceAndDropAfter(res.data, index);
    }); else dataStack.keep(index + 1);
  };

  function getCriteria(index: number): Record<string, string|string[]> {
    const item = dataStack.stack[index];
    if (!item) return {};

    return item.dashboardEntityPieData?.criterias.reduce((acc, curr) => ({
      ...acc,
      [curr.name]: curr.values
        ? curr.values.map(tryJson)
        : tryJson(curr.value),
    }), {}) ?? {};
  }

  return {
    // listData: list,
    listData: dataStack.stack.map((data, index) => ({
      data,
      selected: selectedStack.stack[index] ?? undefined,
    })),
    replace,
    remove: (index: number) => {
      selectedStack.keep(index);
      dataStack.keep(index + 1);
    },
    getCriteria,
  };
};

function tryJson(input: string) {
  try {
    return JSON.parse(input);
  } catch {
    return input;
  }
}

const LEVEL_QUERY = gql`
  query PieLevelDataQuery($configurationId: Int!, $selection: [DashboardPieSelectionInput!]) {
    dashboardEntityPieData(dashboardItemConfigurationId: $configurationId, selection: $selection) {
      values {
        code
        label
        value
      }
      criterias {
        name
        value
        values
      }
    }
  }
`;
