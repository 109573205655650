import React from 'react';
import { Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import AdminAddressTypes from '../../adminComponents/AdminAddressTypes/AdminAddressTypes';
import AdminContactCategories from '../../adminComponents/AdminContactCategories/AdminContactCategories';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../../util/useLocalization';
import AnchoredSections, { AnchoredItem } from '../../adminComponents/AdminSection/AnchoredSections';
import Icon from '../../../../components/Icon/Icon';
import AdminEntityMergeRequestsOverview
  from '../../adminComponents/AdminEntityMergeRequest/overview/AdminEntityMergeRequestsOverview';
import AdminConfigureLinkTableColumns from './AdminConfigureLinkTableColumns/AdminConfigureLinkTableColumns';
import AdminConfigureSearch from '../../adminComponents/AdminConfigureSearch/AdminConfigureSearch';
import { usePermission } from '../../../../permission/usePermission';
import AdminExternalId from '../../adminComponents/AdminExternalId/AdminExternalId';
import { EntityTypeEnum } from '../../../../../gql/typings';
import AdminEntityCustomFields from '../../adminComponents/AdminEntityCustomFields/AdminEntityCustomFields';

const LinkAdminSettings: React.FC = () => {
  const localization = useLocalization();
  const navigate = useNavigate();
  const categoryPermission = usePermission('CONTACT_CATEGORY');


  return (
    <div className="link-admin-settings-container">
      <AnchoredSections>

        <AnchoredItem anchor="general" title={localization.formatMessage(Locale.General.General)}>
          <Space size="large" direction="vertical">
            <Button onClick={() => navigate('/admin/link/layout')}>
              Configure layout
              &nbsp;
              {/* MARK: https://github.com/ant-design/ant-design-icons/issues/71#issuecomment-816735899 */}
              <Icon iconType="LINK" />
            </Button>
          </Space>
        </AnchoredItem>

        <AnchoredItem anchor="search" title={localization.formatMessage(Locale.Command.Search)}>
          <AdminConfigureSearch entityType={EntityTypeEnum.AFFILIATION} />
        </AnchoredItem>

        <AnchoredItem anchor="merge" title="Merge requests">
          <AdminEntityMergeRequestsOverview entityType={EntityTypeEnum.AFFILIATION} />
        </AnchoredItem>

        <AnchoredItem anchor="custom" title="Own Fields">
          <AdminEntityCustomFields entityType={EntityTypeEnum.AFFILIATION} />
        </AnchoredItem>

        <AnchoredItem anchor="address" title="Address types">
          <AdminAddressTypes entityType={EntityTypeEnum.AFFILIATION} />
        </AnchoredItem>

        <AnchoredItem anchor="external-id" title={localization.formatMessage(Locale.Attribute.External_IDs)}>
          <AdminExternalId entityType={EntityTypeEnum.AFFILIATION} />
        </AnchoredItem>

        <AnchoredItem anchor="contact" title="Contact categories">
          <AdminContactCategories entityType={EntityTypeEnum.AFFILIATION} permission={categoryPermission} />
        </AnchoredItem>

        <AnchoredItem anchor="table-columns" title={localization.formatMessage(Locale.General.Table_columns)}>
          <AdminConfigureLinkTableColumns />
        </AnchoredItem>
      </AnchoredSections>
    </div>
  );
};


export default LinkAdminSettings;
