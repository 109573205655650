import { personSelectionRowFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { Locale } from '../../../../localization/LocalizationKeys';
import { PersonSelectionRowCriteriaOrder } from '../../../../gql/typings';

export const personSelectionRowRowIdFieldConfig = personSelectionRowFieldConfig(
  'rowId',
  ['selectionRow.id'],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Rule_ID),
    sorting: {
      ascend: PersonSelectionRowCriteriaOrder.ROW_ID_ASC,
      descend: PersonSelectionRowCriteriaOrder.ROW_ID_DESC,
    },
    render: record => record.selectionRow?.id ?? localization.formatMessage(Locale.General.Unknown),
  }),
);
