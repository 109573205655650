import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { Checkbox, Col, Row, Skeleton, Space, Switch, Typography } from 'antd';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import {
  useCreateCustomFieldRoleMutationMutation,
  useCustomFieldPermissionQueryQuery,
  useCustomFieldPermissionRolesQueryQuery, useRemoveCustomFieldRoleMutationMutation, useUpdateCustomFieldRoleMutationMutation
} from '../../../../../gql/typings';

type CustomFieldPermissionSettingsProps = {
  customFieldCode: string;
};


const CustomFieldPermissionConfigurationTab: React.FC<CustomFieldPermissionSettingsProps> = ({ customFieldCode }) => {
  const localization = useLocalization();
  const {
    data,
    loading,
    suggestions,
    create,
    update,
    remove,
    refetch,
  } = useQueries(customFieldCode);

  const onCheckChange = (existing: { id: number }|undefined, roleId: number) => {
    if (existing) remove({
      variables: { fieldRoleId: existing.id },
    }).then(refetch);
    else create({
      variables: { customFieldCode, roleId },
    }).then(refetch);
  };

  const onSwitchChange = (fieldRoleId: number, type: 'read'|'mutation', checked: boolean) => update({
    variables: { fieldRoleId, [type]: checked },
  }).then(refetch);

  return (
    <Space direction="vertical" size="middle">
      <Typography.Paragraph>
        {localization.formatMessage(Locale.Text.Custom_field_permission_control_description)}
      </Typography.Paragraph>
      <Skeleton loading={!suggestions?.roles.nodes}>
        {(suggestions?.roles.nodes ?? []).map(role => {
          const checked = data
            ?.customField
            ?.accessRules
            ?.nodes
            ?.find(ac => ac.roleId === role.id);

          return (<Row key={role.id}>
            <Col span={12}>
              <Checkbox
                checked={!!checked}
                onChange={() => onCheckChange(checked, role.id)}
                disabled={loading}
              >
                {role.heading}
              </Checkbox>
            </Col>
            <Col span={6}>
              {localization.formatMessage(Locale.General.Read)}: <Switch
                size="small"
                loading={loading}
                disabled={!checked}
                checked={checked?.allowRead ?? false}
                onChange={e => checked && onSwitchChange(checked.id, 'read', e)}
              />
            </Col>
            <Col span={6}>
              {localization.formatMessage(Locale.General.Mutation)}: <Switch
                size="small"
                loading={loading}
                disabled={!checked}
                checked={checked?.allowMutation ?? false}
                onChange={e => checked && onSwitchChange(checked.id, 'mutation', e)}
              />
            </Col>
          </Row>);
        })}
      </Skeleton>
    </Space>
  );
};

const useQueries = (customFieldCode: string) => {
  const [ownLoading, setLoading] = useState(false);
  const { data, loading, refetch } = useCustomFieldPermissionQueryQuery({ variables: { customFieldCode } });
  const { data: suggestions, loading: suggestionLoading } = useCustomFieldPermissionRolesQueryQuery();
  const [create] = useCreateCustomFieldRoleMutationMutation();
  const [update] = useUpdateCustomFieldRoleMutationMutation();
  const [remove] = useRemoveCustomFieldRoleMutationMutation();
  return {
    data,
    loading: ownLoading || loading || suggestionLoading,
    suggestions,
    create,
    update,
    remove,
    refetch: () => {
      setLoading(true);
      refetch().finally(() => setLoading(false));
    },
  };
};

gql`
  query CustomFieldPermissionQuery($customFieldCode: String!) {
    customField(code: $customFieldCode) {
      code
      accessRules {
        hash
        nodes {
          id
          allowRead
          allowMutation
          roleId
        }
      }
    }
  }
`;

gql`
  query CustomFieldPermissionRolesQuery($search: String) {
    roles(criteria: {heading: $search}) {
      hash
      nodes {
        id
        heading
      }
    }
  }
`;

gql`
  mutation CreateCustomFieldRoleMutation($customFieldCode: String!, $roleId: Int!) {
    addRoleToCustomField(customFieldCode: $customFieldCode, roleId: $roleId) {
      id
    }
  }
`;

gql`
  mutation UpdateCustomFieldRoleMutation($fieldRoleId: Int!, $read: Boolean, $mutation: Boolean) {
    updateCustomFieldRoleRules(fieldRoleId: $fieldRoleId, allowRead: $read, allowMutation: $mutation) {
      id
      allowMutation
      allowRead
    }
  }
`;

gql`
  mutation RemoveCustomFieldRoleMutation($fieldRoleId: Int!) {
    removeRoleFromCustomField(fieldRoleId: $fieldRoleId)
  }
`;

export default CustomFieldPermissionConfigurationTab;
