import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined, LoadingOutlined, MergeCellsOutlined } from '@ant-design/icons';
import { MergeItemSiteLinkFragment } from '../../../../../../../../../gql/typings';
import { useColors } from '../../../../../../../../util/useColor';
import { EntityMergeHookValue } from '../../../useEntityMerge';
import { Optional } from '../../../../../../../../util/StateArrayType';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';

type AdminMergeItemSiteLinkTransferItemProps = {
  disabled?: boolean;
  link: MergeItemSiteLinkFragment;
  mergeWinner?: MergeItemSiteLinkFragment;
  mergeLoser?: MergeItemSiteLinkFragment;
  subMerge?: Optional<EntityMergeHookValue>;
  isTargeted?: boolean;
  setSelectedKey: (selectedKey: string) => void;
};

const AdminMergeItemSiteLinkTransferItem: React.FC<AdminMergeItemSiteLinkTransferItemProps> = ({
  link,
  mergeWinner,
  mergeLoser,
  isTargeted,
  disabled,
  subMerge,
  setSelectedKey,
}) => {
  const localization = useLocalization();
  const { colorSuccess, colorError } = useColors();

  const Icon: React.FC = () => {
    if (!mergeWinner || !mergeLoser || disabled) return <></>;
    if (isTargeted) {
      if (subMerge?.loading || !subMerge) return <LoadingOutlined />;
      const overviews = subMerge?.dataFields ?? [];
      const conflicts = overviews.filter(o => o.hasConflict);
      return (
        <Tooltip
          title={localization.formatMessage(Locale.Text.Merge_request_site_mismatch_links_link_merge_popover_description, {
            personName: mergeLoser.person.fullName,
            position: mergeLoser.position?.displayLabel ?? localization.formatMessage(Locale.General.Without_position),
          })}
        >
          &nbsp;&nbsp;
          <MergeCellsOutlined
            style={{ color: conflicts.length > 0 ? colorError : colorSuccess }}
            onClick={e => {
              e.stopPropagation();
              setSelectedKey(`${mergeWinner?.id}`);
            }}
          />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        title={localization.formatMessage(Locale.Text.Merge_request_site_mismatch_links_link_merged_popover_description, {
          personName: mergeLoser.person.fullName,
          position: mergeLoser.position?.displayLabel ?? localization.formatMessage(Locale.General.Without_position),
        })}
      >
        &nbsp;&nbsp;<InfoCircleOutlined />
      </Tooltip>
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {link.person.fullName} ({link.position?.displayLabel ?? localization.formatMessage(Locale.General.Without_position)})
      <Icon />
    </div>
  );
};

export default AdminMergeItemSiteLinkTransferItem;
