import { gql } from '@apollo/client';
import { Locale } from '../../../localization/LocalizationKeys';
import { TableConfig } from '../search_old/types';
import { supportedSiteTableColumns } from './supportedSiteTableColumns';
import { stringToAbsHash } from '../../apollo/util';

export const buildSiteTableFragment = (fields: string|null) => gql`
  fragment SiteTableFragment${stringToAbsHash(fields)} on Site {
    id
    countryCode
    isActive
    isLocal
    ${fields ?? ''}
  }
`;

export const buildSiteConnectionDataQuery = (fields: string|null, hasGlobalSearchPermission = true) => gql`
  query SiteTableQueryBuilt${stringToAbsHash(fields)}($criteria: SiteCriteria) {
    connection: sites(criteria: $criteria) {
      hash
      totalCount
      globalAdditionalCount @include(if: ${hasGlobalSearchPermission})
      nodes { ...SiteTableFragment${stringToAbsHash(fields)} }
    }
  }
  ${buildSiteTableFragment(fields)}
`;

export const buildSiteRecordDataQuery = (fields: string|null) => gql`
  query SiteRecordQueryBuilt${stringToAbsHash(fields)}($recordId: Int!) {
    record: site(siteId: $recordId) {
      id
      ...SiteTableFragment${stringToAbsHash(fields)}
    }
  }
  ${buildSiteTableFragment(fields)}
`;


export const SiteTableConfig: TableConfig = {
  buildConnectionQuery: buildSiteConnectionDataQuery,
  buildRecordQuery: buildSiteRecordDataQuery,
  buildFragment: buildSiteTableFragment,
  titleLabel: Locale.Attribute.Site_HCO,
  columnConfig: {
    fields: supportedSiteTableColumns,
  }
};
