/* eslint-disable react-hooks/exhaustive-deps */
import { useApolloClient } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { PERMISSIONS_QUERY } from './usePermission';
import {
  _PermissionStore,
  permissionCallbackPublish,
  permissionCallbackRegister,
  permissionCallbackUnregister,
  PermissionInfo
} from './permissionUtils';
import { HasPermissionQueryQuery, HasPermissionQueryQueryVariables, PermissionEnum } from '../../gql/typings';

export function usePermissions<K extends PermissionEnum>(permissionCodes: K[]): { [i in K]: PermissionInfo } {
  const apolloClient = useApolloClient();
  const [count, setCount] = useState(0);
  const update = () => setCount(s => s+1);

  const cacheKey = permissionCodes.reduce((acc, curr) => `${acc}|${curr}`, '');

  useEffect(() => {
    const toLoad = permissionCodes.filter(pc => {
      const existing = _PermissionStore[pc];
      return !existing || existing.last.isBefore(moment().subtract(15, 'minutes'));
    });

    const keys: [K, string][] = permissionCodes.map(permissionCode => [
      permissionCode,
      permissionCallbackRegister(permissionCode, update),
    ]);

    if (toLoad.length > 0) {
      apolloClient.query<HasPermissionQueryQuery, HasPermissionQueryQueryVariables>({
        query: PERMISSIONS_QUERY,
        variables: { permissions: toLoad },
      }).then(res => {
        if (res.data) {
          res.data.hasPermissions.map(p => {
            _PermissionStore[p.code as PermissionEnum] = {
              last: moment(),
              create: p.create,
              read: p.read,
              update: p.update,
              delete: p.delete,
            };
            permissionCallbackPublish(p.code as PermissionEnum);
          });
        }
      });
    }

    return () => {
      keys.map(([permissionCode, key]) => {
        permissionCallbackUnregister(permissionCode, key);
      });
    };
  }, [apolloClient, cacheKey, update]);

  return useMemo(() => permissionCodes.reduce((acc, curr) => ({
    ...acc,
    [curr]: {
      loading: !_PermissionStore[curr],
      create: _PermissionStore[curr]?.create ?? false,
      read: _PermissionStore[curr]?.read ?? false,
      update: _PermissionStore[curr]?.update ?? false,
      delete: _PermissionStore[curr]?.delete ?? false,
    }
  }), {}) as { [i in K]: PermissionInfo }, [count, cacheKey]);
}
