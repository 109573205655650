import React, { useContext, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Button, Checkbox, List, message, Modal, Tooltip, Typography } from 'antd';
import { useLocalization } from '../../../util/useLocalization';
import EntitiesSearchContext from '../EntitiesSearchContext';
import { getEntityTypeLabel } from '../../../browse/search_old/types';
import { useMainView } from '../../View/MainViewContext';
import Flag, { CountryLabel, FlagCountry } from '../../Flag/Flag';
import { Locale } from '../../../../localization/LocalizationKeys';
import {
  EntitiesSearchTopCountriesQuery,
  EntitiesSearchTopCountriesQueryVariables,
  EntityTypeEnum
} from '../../../../gql/typings';


export const isCountrySearchSupportedForEntity = (entityType: EntityTypeEnum) => [
  EntityTypeEnum.PERSON,
  EntityTypeEnum.SITE,
  EntityTypeEnum.AFFILIATION,
].indexOf(entityType) >= 0;

const EntitiesSearchTitleAndCountries: React.FC = () => {
  const localization = useLocalization();
  const { isBoundingBox } = useMainView();
  const {
    entityType,
    selectedCountriesState: [selectedCountries, setSelectedCountries],
  } = useContext(EntitiesSearchContext);
  const { data } = useQuery<EntitiesSearchTopCountriesQuery, EntitiesSearchTopCountriesQueryVariables>(DATA_QUERY);
  const [show, setShow] = useState(false);
  const [tempSelectedCountries, setTempSelectedCountries] = useState<FlagCountry[] | undefined>(selectedCountries);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (isCountrySearchSupportedForEntity(entityType)) {
      if ((tempSelectedCountries?.length ?? 0) > 1) setShow(true);
      else if ((data?.countries.totalCount ?? 0) > 1) setShow(true);
      else setShow(false);
    } else setShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!isModalVisible) {
      setSelectedCountries(tempSelectedCountries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  const onSelectCountry = (country: FlagCountry) => {
    if (!tempSelectedCountries) return setTempSelectedCountries([country]);
    const index = tempSelectedCountries.indexOf(country);
    if (index >= 0) {
      if (tempSelectedCountries.length <= 1) {
        message.warning(localization.formatMessage(Locale.Text.You_need_atleast_one_country_select)).then();
      } else {
        const copy = [...tempSelectedCountries];
        copy.splice(index, 1);
        setTempSelectedCountries(copy);
      }
    } else setTempSelectedCountries([...tempSelectedCountries, country]);
  };

  return (
    <div className="entities-search-title-and-countries-container">
      <div className="left">
        <span className={`title ${isBoundingBox ? 'bounding-box' : ''}`}>
          {localization.formatMessage(getEntityTypeLabel(entityType))}
        </span>
        <div className="flags">
          {show && (
            <>
              {tempSelectedCountries?.map(countryCode => (
                <Tooltip key={countryCode} title={localization.formatMessage(CountryLabel[countryCode as FlagCountry])}>
                  <span><Flag country={countryCode} rectangle /></span>
                </Tooltip>
              ))}
              <Button
                type="link"
                className={`add-countries ${isBoundingBox ? 'bounding-box' : ''}`}
                onClick={() => setIsModalVisible(true)}
              >
                + {localization.formatMessage(Locale.Command.Add_country)}
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="right" />
      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        wrapClassName="entities-search-title-and-countries-modal-container"
        title={localization.formatMessage(Locale.Text.Select_countries_to_search_in)}
        footer={null}
        width={380}
        closeIcon={<span className="close-modal">&nbsp;</span>}
      >
        <List
          dataSource={data?.countries.nodes ?? []}
          renderItem={item => (
            <List.Item>
              <Checkbox
                className="country-check"
                checked={tempSelectedCountries?.includes(item.code as FlagCountry)}
                onChange={() => onSelectCountry(item.code as FlagCountry)}
              >
                <div className="country-check-content">
                  <Typography.Text data-country-code={item.code}>
                    {localization.formatMessage(CountryLabel[item.code as FlagCountry])}
                  </Typography.Text>
                  <Flag rectangle country={item.code as FlagCountry} />
                </div>
              </Checkbox>
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
};

const DATA_QUERY = gql`
  query EntitiesSearchTopCountries($search: String) {
    countries(criteria: { label: $search }) {
      hash
      totalCount
      nodes {
        code
        label
      }
    }
  }
`;

export default EntitiesSearchTitleAndCountries;
