import React from 'react';
import { createPlugin } from '@fullcalendar/react';

const TestList = () => (
  <>
    <span>Ola</span>
  </>
);

export const apbListDayPlugin = createPlugin({
  views: {
    apbListDay: {
      content: () => ({
        html: TestList
      }),
    },
  },
});
