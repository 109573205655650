import { useQuery } from '@apollo/client';
import React, { useContext } from 'react';
import { Empty, Space, Table } from 'antd';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import { EntitiesSearchContext } from '../../../components/entitiesSearch';
import { buildPersonConnectionTableDataQuery } from '../../person/PersonTable';
import { SliderActionContext } from '../../../components/Table/SliderActions/SliderActions';
import { TableRowSelectionTableProps } from '../../../components/Table/useTableRowSelection';
import EntitiesSearchQuickActions from '../../../components/entitiesSearch/top/EntitiesSearchQuickActions';
import ApbTableHeader from '../../../components/Table/ApbTableHeader';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import EntitiesSearchResultPagination from '../../../components/entitiesSearch/results/EntitiesSearchResultPagination';
import { useTableColumns } from '../../../components/entitiesSearch/results/useTableColumns';
import ApbButton from '../../../components/Button/ApbButton';
import { EntityTypeEnum } from '../../../../gql/typings';
import { useLastVisitState } from '../../../util/useLastVisitState';


const NestTable: React.FC<{ activityId: number }> = ({ activityId }) => {
  const { query, columns, onTableChange, sorting } = useTableColumns(EntityTypeEnum.PERSON);
  const { data, loading } = useQuery(
    buildPersonConnectionTableDataQuery(query),
    {
      variables: {
        criteria: {
          activityId,
          ordering: sorting?.map(s => s.currentEnum),
        },
      },
    },
  );
  return <>
    <Table
      style={{ overflow: 'auto hidden', padding: '10px 0' }}
      loading={loading}
      size="small"
      /* @ts-ignore */
      columns={[ 
        {
          title: 'S.N',
          key: 'index',
          width: '60px',
          render: (text: string,
            record: unknown,
            index: number) => (<div>{index + 1}</div>),
        },
        ...columns]}
      onChange={onTableChange}
      pagination={false}
      dataSource={get(data, 'connection.nodes', [])}
    />
  </>;
};

const CustomActivityEntitiesSearchTable: React.FC<{ tableProps: TableRowSelectionTableProps; act: React.ReactNode }> = ({
  tableProps,
  act
}) => {
  const localization = useLocalization();
  const {
    tableSelection,
    data: {
      data,
      loading,
    },
    entityType,
    quickActions,
  } = useContext(EntitiesSearchContext);
  const { selected: span, mapGroup } = useContext(SliderActionContext);
  const { formatMessage } = useIntl();
  const resultCount = data.connection.totalCount;
  const perPageState = tableSelection?.tableProps.perPageState;
  const pageState = tableSelection?.tableProps.pageState;
  const lastVisitedState = useLastVisitState(entityType);


  const type = (e: EntityTypeEnum): React.ReactNode => {
    switch (e) {
      case 'ACTIVITY':
        return <ApbButton
          url="/activity/create"
          buttonType="action"
          text={formatMessage(Locale.Command.Create_Activity)}
        />;
      default:
        return 'not supported';
    }
  };


  const locale = {
    emptyText: (<>
      {!loading && <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{
          height: 60,
        }}
        description={
          <span>
            {formatMessage(Locale.Command.No_data)}
          </span>
        }
      >
        <Space>

          {resultCount === 0 && type(entityType)}

        </Space>
      </Empty>}
    </>

    )
  };

  const columnConfig = useTableColumns(EntityTypeEnum.ACTIVITY, { openInNewTab: false, span });

  // @ts-ignore
  const ds = mapGroup(get(data, 'connection.nodes', []));

  return (
    <div className="entities-search-table-results-container">
      <div className="table-top">
        <div className="left">
          <ApbTableHeader
            count={get(data, 'connection.totalCount', null)}
            selectedLabel={tableProps.rowSelection?.selectedLabel}
          >
            <EntitiesSearchQuickActions quickActions={quickActions} />
          </ApbTableHeader>
        </div>
        <h4 className="middle">
          {localization.formatMessage(Locale.Text.Entity_search_placeholder_text_Activity)}
        </h4>
        <div className="right entity-search-dropdown-action-to-take">
          {act && act}
        </div>
      </div>
      <Table
        {...tableProps}
        rowKey={r => r.id || r.key}
        loading={loading}
        dataSource={ds}
        size="small"
        className='activity-custom-table'
        columns={[
          {
            title: 'S.N',
            key: 'index',
            width: '60px',
            render: (text: string,
              record: unknown,
              index: number) => {
              // @ts-ignore
              const recordID = record.id;
              return (
                <div className={`${recordID === lastVisitedState[0]
                  ? 'last-visited' : ''}`}
                >
                  {(pageState[0] - 1) * perPageState[0] + index + 1}
                </div>
              );
            },
          },
          ...columnConfig.columns]}
        onChange={columnConfig.onTableChange}
        locale={locale}
        style={{ overflow: 'auto hidden' }}
        pagination={false}
        onRow={((record) => ({
          onClick: () => lastVisitedState[1](record.id)
        }))}
        rowSelection={{
          ...tableProps.rowSelection,
          getCheckboxProps: record => ({ disabled: record.children }),
        }}
        expandable={{
          rowExpandable: record => record.children || (record.persons && record.persons.totalCount > 0),
          expandedRowRender: (record, _, indent) => {
            if ((span?.groupBy && indent !== 1) || !span?.groupBy) return <NestTable activityId={record.id} />;
          },
        }}
      />
      <EntitiesSearchResultPagination />
    </div>
  );
};

export default CustomActivityEntitiesSearchTable;
