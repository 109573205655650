import React, { CSSProperties, useState } from 'react';
import { isArray, isObject, isString, toNumber } from 'lodash';
import { Breadcrumb, Col, Form, Row, Space } from 'antd';
import { useIntl } from 'react-intl';
import { FormInstance } from 'antd/lib/form/Form';
import SingleLineSkeleton from '../Skeleton/SingleLineSkeleton';
import MainViewInlineEdit, { MainViewInlineEditProps } from './MainViewInlineEdit';
import Input from '../Form/Input';
import StatusIcon, { StatusIconProps } from '../StatusIcon/StatusIcon';
import { AppContext } from '../../layout/AppTypes';
import { Locale } from '../../../localization/LocalizationKeys';
import { MainViewContext } from './MainViewContext';
import AppLayout from '../../layout/AppLayout';

export type MainViewPropsBreadcrumbItem = {
  label: string;
  onClick?: () => void;
};

export type MainViewProps = {
  title?: MainViewInlineEditProps | string | React.ReactNode;
  titleLoading?: boolean;
  type?: 'default' | 'gray';
  types?: React.ReactNode;
  boundingBox?: boolean;
  status?: React.ReactNode;
  badge?: StatusIconProps | StatusIconProps[];
  rightActions?: React.ReactNode;
  subComponent?: MainViewInlineEditProps | string | React.ReactNode;
  className: string;
  titleClassName?: string;
  editMode?: boolean;
  form?: FormInstance;
  withoutPadding?: boolean;
  style?: CSSProperties;
  breadcrumbs?: MainViewPropsBreadcrumbItem[];
};

const MainView: React.FC<MainViewProps> = ({
  subComponent,
  editMode,
  form,
  badge,
  title,
  types,
  status,
  titleLoading = false,
  children,
  rightActions,
  breadcrumbs,
  className,
  boundingBox = true,
  withoutPadding = false,
  style = {},
}) => {
  const isCollapseSet = localStorage.getItem('isCollapse');
  const isCollapse = !isCollapseSet ? window.innerWidth < 1250 : isCollapseSet === 'true';
  const collapse = useState(isCollapse);
  const intl = useIntl();
  const SubComponent = () => {
    if (!subComponent) return (<></>);
    if (isString(subComponent)) return <span>{subComponent}</span>;
    if (isObject(subComponent) && (subComponent as MainViewInlineEditProps).gqlMutation) {
      const sub = subComponent as MainViewInlineEditProps;
      return (
        <MainViewInlineEdit
          title={sub.title}
          entityId={toNumber(sub.entityId)}
          editAccess={sub.editAccess}
          gqlMutation={sub.gqlMutation}
          valueTypeLabel={sub.valueTypeLabel}
          size="medium"
        />
      );
    }
    // Add [form] and [editMode] onto the subComponent
    // @ts-ignore TODO: Try not to use @ts-ignore here!
    return (<subComponent.type {...subComponent.props} editMode={editMode} form={form} />);
  };

  let titleComponent; // TODO: Simplify
  if (isObject(title) && (title as MainViewInlineEditProps).gqlMutation) {
    const casted = title as MainViewInlineEditProps;
    titleComponent = (
      <MainViewInlineEdit
        entityId={toNumber(casted.entityId)}
        title={casted.title}
        editAccess={casted.editAccess}
        gqlMutation={casted.gqlMutation}
        valueTypeLabel={casted.valueTypeLabel}
        size="large"
      />
    );
  } else if (title || isString(title)) {
    if (isString(title)) {
      if (editMode) {
        titleComponent = (
          <h1>
            <Input
              id="title"
              key={`${className}-edit-title`}
              placeholder={intl.formatMessage(Locale.Command.Enter_title)}
              preventDefault
              initialValue={title}
              form={form}
            />
          </h1>
        );
      } else {
        titleComponent = (
          <div className="title">
            <span>{title}</span>
          </div>
        );
      }
    } else {
      titleComponent = title;
    }
  }
  const titleElement = (
    <>
      <Row justify="start">
        <Space className="title-element">
          <SingleLineSkeleton loading={titleLoading}>
            {titleComponent}
          </SingleLineSkeleton>
          {!titleLoading && <SingleLineSkeleton loading={titleLoading}>{types}</SingleLineSkeleton>}
          {!titleLoading && <SingleLineSkeleton loading={titleLoading}>{status}</SingleLineSkeleton>}
          {badge && (isArray(badge)
            ? badge.map((b, index) => <StatusIcon key={index} editMode={editMode} {...b} />)
            : <StatusIcon editMode={editMode} {...badge} />)}
        </Space>
      </Row>
      <Row justify="start">
        <Col><SubComponent /></Col>
      </Row>

    </>
  );

  // eslint-disable-next-line prefer-template
  const classes = 'main-view-container'
    + ' main-position'
    + (withoutPadding ? '' : ' main-padding')
    + (breadcrumbs ? ' has-breadcrumbs' : ' no-breadcrumbs')
    + (collapse[0] ? ' collapse' : ' open')
    + ` ${className}`;

  const view = (
    <div className={boundingBox ? 'main-view-bounding-background' : ''}>
      <Row className="content-top">
        <Col span={rightActions ? 12 : 24}>
          {titleElement}
        </Col>
        {rightActions && (
          <Col span={12}>
            <div className="top-actions">{rightActions}</div>
          </Col>
        )}
      </Row>
      {children}
    </div>
  );

  return (
    <AppContext.Provider
      value={{
        collapse,
        systemPermissions: {
          globalSearch: true
        }
      }}
    >
      <AppLayout>
        <div className={classes} style={style}>
          {breadcrumbs && (
            <Breadcrumb>
              {breadcrumbs.map((item, index) => (
                <Breadcrumb.Item
                  key={index}
                  onClick={item.onClick}
                  className={`breadcrumb-item ${item.onClick ? 'clickable' : ''}`}
                >
                  {item.label}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          )}
          <MainViewContext.Provider value={{ isBoundingBox: boundingBox }}>
            {form ? <Form form={form}>{view}</Form> : view}
          </MainViewContext.Provider>
        </div>
      </AppLayout>
    </AppContext.Provider>
  );
};

export default MainView;
