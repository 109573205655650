import React from 'react';
import { Col, Row, Tooltip, Typography } from 'antd';
import { useMutation } from '@apollo/client';
import { LoadingOutlined } from '@ant-design/icons';
import { CustomFieldProps, UPSERT_MUTATION } from './CustomFieldUtils';
import Icon from '../Icon/Icon';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import { UpsertCustomFieldValueMutation, UpsertCustomFieldValueMutationVariables } from '../../../gql/typings';


const CustomFieldText: React.FC<CustomFieldProps> = ({ recordId, field, values, refreshData }) => {
  const localization = useLocalization();
  const [
    upsert,
    { loading },
  ] = useMutation<UpsertCustomFieldValueMutation, UpsertCustomFieldValueMutationVariables>(UPSERT_MUTATION);

  const onChange = (newValue: string) => upsert({
    variables: {
      recordId,
      customFieldCode: field.code,
      customValues: [{ newValue }],
    }
  }).finally(refreshData);

  const settings = field.customFieldControlSetting;

  return (
    <Row>
      <Col span={settings?.isSynced ? 23 : 24}>
        <Tooltip
          title={field.accessRules?.allowMutation
            ? '' : localization.formatMessage(Locale.Text.Mutation_permission_denied)}
        >
          <Typography.Text
            disabled={!field.accessRules?.allowMutation}
            style={{ left: 0 }} // No clue why Ant ads -12px to this by default...
            editable={{
              onChange,
              icon: loading ? <LoadingOutlined /> : undefined,
            }}
          >
            {values.nodes[0]?.value?.value}
          </Typography.Text>
        </Tooltip>
      </Col>
      {settings?.isSynced
      && <Col span={1}><Icon toolTip="is synchronized" style={{ marginTop: 8, marginLeft: 8 }} iconType="SYNC" /></Col>}
    </Row>

  );
};


export default CustomFieldText;
