import { POLL_SLOW, POLL_NORMAL, POLL_FAST } from '../../../../apollo/hocs';
import { ImportProcessStatusEnum } from '../../../../../gql/typings';


export const statusOptions: Record<ImportProcessStatusEnum, {
  progressIndicator: 'normal'|'exception'|'active'|'success';
  pollInterval: number;
}> = {
  FAIL: {
    progressIndicator: 'exception',
    pollInterval: POLL_SLOW,
  },
  NOT_STARTED: {
    progressIndicator: 'normal',
    pollInterval: POLL_NORMAL,
  },
  STARTED: {
    progressIndicator: 'active',
    pollInterval: POLL_FAST,
  },
  STOPPED: {
    progressIndicator: 'normal',
    pollInterval: POLL_SLOW,
  },
  MANUAL: {
    progressIndicator: 'normal',
    pollInterval: POLL_NORMAL,
  },
  WAITING: {
    progressIndicator: 'active',
    pollInterval: POLL_FAST,
  },
  RUNNING: {
    progressIndicator: 'active',
    pollInterval: POLL_FAST,
  },
  PROCESSED: {
    progressIndicator: 'success',
    pollInterval: POLL_SLOW,
  },
};
