import React, { useState } from 'react';
import { get } from 'lodash';


const ActivitySiteAddress = ({
  site,
}) => {
  const [address] = useState(get(site, 'visitStreet', false));
  const [city] = useState(get(site, 'visitCity', false));
  const [zip] = useState(get(site, 'visitZip', false));
  const [country] = useState(get(site, 'countryCode', false));
  const [name] = useState(get(site, 'name', false));

  const renderAddress = address != null && city != null;
  const renderCity = city && zip ? <span>, {zip} {city}</span> : <span>, {city}</span>;
  const renderCountry = country ? <span>, {country}</span> : <></>;

  const displayAddress = <span>{name}{address}{renderCity}{renderCountry}</span>;

  if (!renderAddress) return <></>;

  return (
    <span>{displayAddress}</span>
  );
};


ActivitySiteAddress.propTypes = {

};


export default ActivitySiteAddress;
