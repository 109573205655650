/* eslint-disable prefer-rest-params,@typescript-eslint/no-explicit-any */
import {
  isNaN,
  isNumber,
  isBoolean,
  isString,
  isArray,
  slice,
  toNumber, isNil,
} from 'lodash';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
export const b64DecodeUnicode = (str: string) =>
// Going backwards: from bytestream, to percent-encoding, to original string.
// eslint-disable-next-line no-undef,implicit-arrow-linebreak
  decodeURIComponent(atob(str).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
export const decodeJWT = (token: string) => {
  const firstIndex = token.indexOf('.');
  const lastIndex = token.lastIndexOf('.');
  const toDecode = token.substring(firstIndex + 1, lastIndex);
  const decoded = b64DecodeUnicode(toDecode);
  return JSON.parse(decoded);
};

/**
 * @param {function} fn
 */
export function safeCall() {
  if (arguments[0]) {
    arguments[0](slice(arguments, 1));
  }
}

/**
 * Checks whether a [value] is a number.
 * @param value
 * @returns {boolean}
 *
 * @example
 *   isActualNumber(25)     // true
 *   isActualNumber('25')   // true
 *   isActualNumber(NaN)    // false
 *   isActualNumber('abc')  // false
 *   isActualNumber(null)   // false
 *   isActualNumber()       // false
 */
export const isActualNumber = (value?: any): value is number => !isNil(value)
  && isNumber(toNumber(value))
  && !isNaN(toNumber(value))
  && !isArray(value);

/**
 * Tries to convert a value to a boolean value
 *
 * @param {*} value
 * @returns {boolean}
 */
export const extractBooleanValue = (value: any): boolean => {
  if (isNil(value)) return false;
  if (isArray(value) && value.length === 1) return extractBooleanValue(value[0]);
  if (isBoolean(value)) return value;
  if (isActualNumber(value)) {
    switch (value) {
      case 0:
      case -1:
        return false;
      case 1:
        return true;
      default:
        throw Error(`Can't parse '${value}' to a boolean value!`);
    }
  }
  if (isString(value)) {
    switch (value.toLowerCase()) {
      case 'true':
      case '1':
        return true;
      case 'false':
      case '0':
      case '-1':
      case '':
        return false;
      default:
        throw Error(`Can't parse '${value}' to a boolean value!`);
    }
  }
  // eslint-disable-next-line no-console
  console.error(`Couldn't parse '${value}' to a boolean value`);
  return false;
};


/**
 *
 * @return {{urlSearchMap: object, setSearch: (function(object=): object)}}
 */
export const useURLSearch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const setSearch = (obj: any) => navigate(`${location.pathname}?${queryString.stringify(obj)}`);
  return {
    setSearch,
    urlSearchMap: queryString.parseUrl(location.search).query,
  };
};


export function isNumberArray(value: any): value is number[] {
  if (value instanceof Array) {
    value.forEach((item) => {
      if (typeof item !== 'number') {
        return false;
      }
    });
    return true;
  }
  return false;
}
