import React from 'react';
import UrlTabs from '../adminComponents/UrlTabs/UrlTabs';
import SandboxView from '../sandbox/SandboxView';
import MenuSettings from '../menu/MenuSettings';
import GeneralSystemSettings from './general/GeneralSystemSettings';
import { usePermission } from '../../../permission/usePermission';

const SystemSettingRoutes : React.FC = () => {
  const { loading, read } = usePermission('SANDBOX');
  return (
    <UrlTabs
      path="system"
      loading={loading}
      items={[
        {
          path: 'general',
          title: 'General',
          render: GeneralSystemSettings,
        },
        {
          path: 'menu',
          title: 'Menu',
          render: MenuSettings,
        },
        read && {
          path: 'sandbox',
          title: 'Sandbox',
          render: SandboxView,
        },
      ].filter(e => e)}
    />
  );
};

export default SystemSettingRoutes;
