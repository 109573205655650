import React from 'react';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { Col, Tooltip, Typography } from 'antd';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { EntityTabFieldProps } from './EntityTabFieldsTypes';
import { PersonExternalIdsQueryQuery, PersonExternalIdsQueryQueryVariables } from '../../../../../gql/typings';

const PersonExternalIdsTabField: React.FC<EntityTabFieldProps> = (props) => {
  const localization = useLocalization();
  const { data, loading } = useQuery<PersonExternalIdsQueryQuery, PersonExternalIdsQueryQueryVariables>(DATA_QUERY, {
    variables: { personId: props.recordId },
  });

  const nodes = data?.person?.externalIds.nodes ?? [];

  const displayHeading = (e: NonNullable<PersonExternalIdsQueryQuery['person']>['externalIds']['nodes']['0']) => {
    if (e.type.isHeadingKey) return localization.formatMessageByStr(e.type.heading);
    return e.type.heading;
  };

  return (
    <>
      {(data?.person?.externalIds.nodes ?? []).map(eId => (
        <React.Fragment key={eId.id}>
          <Col md={6} sm={8}>
            <Tooltip title={displayHeading(eId)}>
              <strong><span style={{ wordBreak: 'break-word' }}>{displayHeading(eId)}</span></strong>:
            </Tooltip>
          </Col>
          <Col md={18} sm={16}>
            <Typography.Text copyable>
              {eId.externalId}
            </Typography.Text>
          </Col>
        </React.Fragment>
      ))}
      {nodes.length === 0 && !loading && (
        <Col span={24}>
          {localization.formatMessage(Locale.Text.No_external_IDs)}
        </Col>
      )}
    </>
  );
};

const DATA_QUERY = gql`
  query PersonExternalIdsQuery($personId: Int!) {
    person(id: $personId) {
      id
      externalIds {
        hash
        nodes {
          id
          externalId
          type {
            id
            heading
            isHeadingKey
            description: note
          }
        }
      }
    }
  }
`;

export default PersonExternalIdsTabField;
