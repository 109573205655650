import React, { useContext, useState } from 'react';
import { Button, Dropdown } from 'antd';
import { uniq } from 'lodash';
import { MoreOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import Modal from 'antd/lib/modal/Modal';
import { TableActionItem } from '../../Table/Actions';
import { useLocalization } from '../../../util/useLocalization';
import { Optional } from '../../../util/StateArrayType';
import ActionsToTakeDropdown from '../../ActionsToTake/ActionsToTakeDropdown';
import { generateTableColumnsSortingKey, orderingFiltering, SortingStorage } from './useTableColumns';
import { useBroadcastStorage } from '../../../util/useBroadcastStorage';
import { SupportedEntitySearchTypes } from '../../../browse/search_old/types';
import { Locale } from '../../../../localization/LocalizationKeys';
import { EntitiesSearchContext } from '../index';
import ConfigureTableColumnSettings from '../../ConfigureTableColumnSettings/ConfigureTableColumnSettings';
import { TableColumnConfigureSettingType } from '../../ConfigureTableColumnSettings/useTableColumnConfigureSettings';

type EntityTableRightActionsProps = {
  entityType: SupportedEntitySearchTypes;
  actionsToTake: Optional<TableActionItem[]>;
};

const EntityTableRightActions: React.FC<EntityTableRightActionsProps> = ({ entityType, actionsToTake }) => {
  const localization = useLocalization();
  const navigate = useNavigate();
  const [sorting, setSorting] = useBroadcastStorage<SortingStorage[]>(
    generateTableColumnsSortingKey(entityType),
    [],
    orderingFiltering(entityType, navigate, localization),
  );
  const { actions, globalState } = useContext(EntitiesSearchContext);

  const visibleState = useState(false);

  const actionToTakeItems = (actionsToTake && (!globalState || !globalState[0])) ? actionsToTake : [];
  const actionItems = (actions && (!globalState || !globalState[0])) ? ([...actions, null] || []) : [];
  const defaultItems = [
    {
      key: '1',
      label: localization.formatMessage(Locale.Command.Reset_sorting),
      disabled: sorting.length === 0,
      onClick: () => setSorting([]),
    },
    {
      key: '2',
      label: localization.formatMessage(Locale.Command.Configure_table_columns),
      onClick: () => visibleState[1](true),
    },
  ];

  const formattedActionItems = uniq([...actionItems, ...(actionItems && actionItems?.length > 0 ? [null] : [])]);

  const items: (TableActionItem|null)[] = [
    ...actionToTakeItems,
    ...(actionsToTake && actionsToTake?.length > 0 ? [null] : []),
    ...formattedActionItems,
    ...defaultItems,
  ];

  const hidden = (
    <Modal
      title={localization.formatMessage(Locale.Command.Configure_table_columns)}
      open={visibleState[0]}
      onCancel={() => visibleState[1](false)}
      footer={null}
      width="clamp(280px, 75%, 920px)"
    >
      <ConfigureTableColumnSettings
        entityType={entityType}
        type={TableColumnConfigureSettingType.PERSONAL}
      />
    </Modal>
  );

  if (actionToTakeItems.length === 0 && actionItems.length === 0) return (
    <>
      <Dropdown
        trigger={['click']}
        placement="bottomRight"
        menu={{ items }}
      >
        <Button type="ghost" size="small">
          <MoreOutlined />
        </Button>
      </Dropdown>
      {hidden}
    </>
  );

  return (
    <>
      <ActionsToTakeDropdown actions={items} />
      {hidden}
    </>
  );
};

export default EntityTableRightActions;
