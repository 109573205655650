import React from 'react';
import { CustomFieldProps } from './CustomFieldUtils';
import CustomFieldRadio from './CustomFieldRadio';
import CustomFieldCheck from './CustomFieldCheck';


const CustomFieldCheckMultiSupport: React.FC<CustomFieldProps> = ({ recordId, field, values, refreshData }) => (
  <span>
    {field.allowMultiple
      ? <CustomFieldCheck recordId={recordId} field={field} values={values} refreshData={refreshData} />
      : <CustomFieldRadio recordId={recordId} field={field} values={values} refreshData={refreshData} />}
  </span>
);


export default CustomFieldCheckMultiSupport;
