import React, { useState } from 'react';
import { AutoComplete, Form, Spin } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { usePersonFieldMutation } from '../Components/attributeFields/AttributeFields/usePersonFieldMutation';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  EntityTypeEnum,
  FieldEnum,
  PersonCriteriaOrder, SuggestedStandardTitleDetailPageQueryQuery, SuggestedStandardTitleDetailPageQueryQueryVariables,
  UpdatePersonTitleMutationMutation,
  UpdatePersonTitleMutationMutationVariables
} from '../../../../gql/typings';

export const personTitleFieldConfig = personFieldConfig(
  'title',
  [
    'id',
    'title',
    'countryCode',
    'controlSetting.id',
    'controlSetting.createDcr',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Title),
    additionalTableConfig: {
      width: 200
    },
    sorting: {
      ascend: PersonCriteriaOrder.TITLE_ASC,
      descend: PersonCriteriaOrder.TITLE_DESC,
    },
    dcrInfo: record => ({
      entityType: EntityTypeEnum.PERSON,
      entityAffiliationId: record.id,
      field: FieldEnum.HCP_TITLE,
    }),
    render: record => record.title,
    updateView: ({ endEditing, record: person }) => {
      const [title, setTitle] = useState(person.title ?? '');

      const {
        submit,
        Renderer,
        blocking,
      } = usePersonFieldMutation<UpdatePersonTitleMutationMutation, UpdatePersonTitleMutationMutationVariables>({
        mutation: UPDATE_MUTATION,
        controlSetting: person.controlSetting,
      });

      const onEndEditing = () => !blocking && endEditing();

      const {
        data: suggestedStandardTitlesData,
      } = useQuery<SuggestedStandardTitleDetailPageQueryQuery, SuggestedStandardTitleDetailPageQueryQueryVariables>(
        SUGGESTED_STANDARD_TITLE_QUERY,
        { variables: { countryCode: person.countryCode } }
      );

      const onSelect = (t: string) => {
        setTitle(t);
      };

      const onChange = (t: string) => {
        setTitle(t);
      };

      return (
        <Renderer>
          <Form
            style={{ position: 'relative' }}
          >
            <AutoComplete
              style={{ width: '100%' }}
              options={(suggestedStandardTitlesData?.standardTitles?.nodes ?? []).map(it => ({
                value: it.label
              }))}
              autoFocus
              value={title}
              placeholder={localization.formatMessage(Locale.Command.Enter_title)}
              onSelect={onSelect}
              onChange={onChange}
              onBlur={() => !blocking && submit({ persId: person.id, title }).then(onEndEditing)}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: 400, maxWidth: 600 }}
              filterOption={(inputValue, option) => option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
            />
            {blocking && <Spin size='small' style={{ position: 'absolute', top: '8px', right: '5px' }} />}
          </Form>
        </Renderer>
      );
    }
  })
);

const UPDATE_MUTATION = gql`
  mutation UpdatePersonTitleMutation($persId: Int!, $title: String! ) {
    updatePersonTitle(persId: $persId, title: $title) {
      id
      title
    }
  }
`;

const SUGGESTED_STANDARD_TITLE_QUERY = gql`
  query SuggestedStandardTitleDetailPageQuery($countryCode: String!) {
    standardTitles(criteria: {country: $countryCode, fetchSize: {limit: 1000}}) {
      hash
      nodes {
        id
        label
      }
    }
  }
`;
