import { isArray } from 'lodash';
import { FormInstance } from 'antd/es/form';
import { AnyValueType, Optional } from '../../util/StateArrayType';
import { Locale } from '../../../localization/LocalizationKeys';
import { LocalizationShape } from '../../util/useLocalization';
import { CriteriaInputFragmentFragment, LoadCriteriasByCodesQueryQuery } from '../../../gql/typings';


export const generateCriteriaInputValue = (
  inputPath: NonNullable<LoadCriteriasByCodesQueryQuery['criterias']>['nodes']['0']['inputPaths']['nodes']['0'],
  input: AnyValueType,
  localization: LocalizationShape,
): AnyValueType => {
  if (!inputPath?.type?.type) throw Error(localization.formatMessage(Locale.Text.Criteria_object_not_have_any_type));
  switch (inputPath.type.type) {
    case 'select':
    case 'multiSelect':
    case 'multiGroupSelect':
      return (isArray(input) ? input : [input]) as AnyValueType;
    default:
      return input;
  }
};

export type CriteriaInputProps = {
  criteria: CriteriaInputFragmentFragment;
  inputPath: NonNullable<CriteriaInputFragmentFragment['inputPaths']>['nodes']['0'];
  disabled: boolean;
  isDisallowed: boolean;
  form: FormInstance;
  initialValue?: AnyValueType;
  countryCodes: Optional<string[]>;
  disableMaxCount?: boolean;
  initialValueOptionsLoad?: boolean;

};
