import React, { useContext, useMemo } from 'react';
import EntitiesSearchContext from '../EntitiesSearchContext';
import { EntitySearchColumnParamTypeWithState } from '../../../browse/search_old/types';
import EntityTable from './EntityTable';

export type EntitySearchRowIndicator = {
  label: string;
  color: string;
  visible: (record: Record<string, unknown>) => boolean;
  overviewVisible: (data: ({ connection: { nodes: Array<Record<string, unknown>> } })) => boolean;
};


const EntitiesSearchResults: React.FC<{
  rowIndicator?: EntitySearchRowIndicator | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowClick?: (record: any, args: EntitySearchColumnParamTypeWithState) => void;
}> = ({
  rowIndicator,
  onRowClick,
}) => {
  const {
    entityType,
    data: { data, loading },
    tableSelection,
    quickActions,
    globalState,
    openInNewTab,
    tableSize,
    onCreateEntityTab,
    showCreateEntityButton
  } = useContext(EntitiesSearchContext);

  const tableData = useMemo(() => ({
    loading,
    nodes: data?.connection?.nodes,
    totalCount: data?.connection?.totalCount,
    globalSearchCount: data.connection.globalAdditionalCount,
  }), [data, loading]);

  return (
    <div className="entities-search-table-results-container">
      <EntityTable
        entityType={entityType}
        globalState={globalState}
        rowIndicator={rowIndicator}
        tableSelection={tableSelection}
        quickActions={quickActions}
        openLinksInNewTab={openInNewTab}
        onRowClick={onRowClick}
        showTableDescription
        tableProps={{ size: tableSize }}
        onCreateEntityTab={onCreateEntityTab}
        showCreateEntityButton={showCreateEntityButton}
        data={tableData}
      />
    </div>
  );
};


export default EntitiesSearchResults;
