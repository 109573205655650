import React from 'react';
import { gql } from '@apollo/client';
import { Card, Divider, Transfer, Typography } from 'antd';
import { concat } from 'lodash';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import {
  EntityMergeFragment_Site_Fragment,
  MergeItemSiteLinksFragment,
} from '../../../../../../../../../gql/typings';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import MergeRequestItemPrefixIcon from '../../components/MergeRequestItemPrefixIcon';
import AdminMergeItemSiteLinkTransferItem from './AdminMergeItemSiteLinkTransferItem';
import { useSiteLinksMerge } from './useSiteLinksMerge';

type RecordType = {
  key: string;
  disabled: boolean;
  render: React.FC;
};

const AdminMergeItemSiteLink: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemSiteLinksFragment;
  const loserSite = props.from as EntityMergeFragment_Site_Fragment;
  const winnerSite = props.into as EntityMergeFragment_Site_Fragment;
  const localization = useLocalization();

  const hook = useSiteLinksMerge(props);

  const ds = concat<RecordType>(info.linksToBeMoved.map(l => ({
    key: `${l.id}`,
    disabled: false,
    render: () => <AdminMergeItemSiteLinkTransferItem
      link={l}
      isTargeted={hook.targetKeys.filter(tk => tk === `${l.id}`).length === 1}
      setSelectedKey={hook.handleTargetSelectedKeys}
    />,
  })), info.alreadyOnWinner.map(l => {
    const enabled = hook.targetKeys.filter(tk => tk === `M${l.id}`)[0];
    return {
      key: `${l.id}`,
      disabled: !enabled,
      render: () => {
        const merge = info.linksToBeMerged.filter(tbm => tbm.winner.id === l.id)[0];
        const subMerge = hook.subMerges.filter(sm => sm.from?.id === merge?.loser.id && sm.into?.id === merge?.winner.id)[0];
        return <AdminMergeItemSiteLinkTransferItem
          link={l}
          disabled={!enabled}
          isTargeted={hook.targetKeys.filter(tk => tk === `M${l.id}`).length === 1}
          mergeWinner={merge?.winner}
          mergeLoser={merge?.loser}
          subMerge={subMerge}
          setSelectedKey={hook.handleTargetSelectedKeys}
        />;
      },
    };
  }));

  info.linksToBeMerged.map(tbm => {
    const sm = hook.subMerges.filter(sm => sm.from?.id === tbm.loser.id && sm.into?.id === tbm.winner.id)[0];

    if (!hook.targetKeys.filter(tk => tk === `M${tbm.winner.id}`)[0]) ds.push({
      key: `M${tbm.winner.id}`,
      disabled: false,
      render: () => <AdminMergeItemSiteLinkTransferItem
        link={tbm.loser}
        mergeLoser={tbm.loser}
        mergeWinner={tbm.winner}
        subMerge={sm}
        setSelectedKey={hook.handleTargetSelectedKeys}
      />,
    });
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16, width: '100%' }}>
      <Typography.Text>
        <MergeRequestItemPrefixIcon state={hook.state} />
        {localization.formatText(Locale.Text.Merge_request_site_links_mismatch_description)}
      </Typography.Text>
      <div style={{ display: 'flex', columnGap: 16 }}>
        <div>
          <Transfer<RecordType>
            dataSource={ds}
            render={({ render: Render }) => <Render />}
            listStyle={{ width: 'auto', height: 420 }}
            targetKeys={hook.targetKeys}
            onSelectChange={(ssk, tsk) => {
              hook.handleTargetSelectedKeys(tsk);
              hook.setSelectedKeys([...ssk, ...tsk]);
            }}
            onChange={hook.onTransferChange}
            selectedKeys={hook.selectedKeys}
            titles={[
              <>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {loserSite.name}
                &nbsp;
                ({localization.formatMessage(Locale.Attribute.ID)}:
                &nbsp;
                {loserSite.id})
              </>,
              <>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {winnerSite.name}
                &nbsp;
                ({localization.formatMessage(Locale.Attribute.ID)}:
                &nbsp;
                {winnerSite.id})
              </>,
            ]}
          />
          <Typography.Text>
            {localization.formatMessage(Locale.Text.Merge_request_site_links_mismatch_result_description, {
              transferCount: hook.targetKeys.length - info.alreadyOnWinner.length,
              inactivateCount: (info.linksToBeMoved.length + info.linksToBeMerged.length)
                - (hook.targetKeys.length - info.alreadyOnWinner.length),
            })}
          </Typography.Text>
        </div>

        <div>
          {hook.subMerges
            .filter(sm => sm.from?.id && sm.into?.id)
            .map(sm => (
              <Card
                key={`${sm.from?.id}_${sm.into?.id}`}
                title={<span><MergeRequestItemPrefixIcon state="OK" />Merging two links</span>}
                style={{
                  width: '100%',
                  display: !(hook.mergeHandle?.from?.id === sm.from?.id && hook.mergeHandle?.into?.id === sm.into?.id)
                    ? 'none'
                    : 'inherit',
                }}
              >
                {sm.generateDescription()}
                <Divider />
                {sm.render()}
              </Card>
            ))}
        </div>
      </div>
    </div>
  );
};

gql`
  fragment MergeItemSiteLinks on SiteLinks_EntityDataToBeTransferredType {
    code
    alreadyOnWinner { ...MergeItemSiteLink }
    linksToBeMoved: toBeMoved  {...MergeItemSiteLink }
    linksToBeMerged: toBeMerged {
      loser { ...MergeItemSiteLink }
      winner { ...MergeItemSiteLink }
    }
  }
  fragment MergeItemSiteLink on SitePerson {
    id
    personId: persId
    siteId
    person {
      id
      fullName
    }
    position {
      code
      type
      displayLabel
    }
  }
`;

export default AdminMergeItemSiteLink;
