import React, { useEffect } from 'react';
import { get } from 'lodash';
import { Col, Form, Input, Row, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { Locale } from '../../localization/LocalizationKeys';
// import gql from 'graphql-tag';
// import { useQuery } from '@apollo/react-hooks';
import { doPasswordsMatch, isAuthenticated, isPasswordValid } from './authenticate';
import advanceLogo from '../../img/png/advance-logo-white.png';
import apbLogo from '../../img/apurebaseLogo.svg';
import Button from '../components/Input/Button';

const CreatePasswordPage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { token } = useParams();
  const intl = useIntl();
  // const { data } = useQuery(INSTANCE);
  // const instanceName = get(data, 'instanceName', '');
  // const imgPath = get(data, 'imgPath', advanceLogo);

  useEffect(() => {
    if (isAuthenticated()) navigate('/', { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // TODO: Do not use any!
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmitPassword = async (values: any) => {
    const newPassword = get(values, 'password', '');
    const repeatedPassword = get(values, 'repeat-password', '');
    if ((!newPassword && !repeatedPassword) || (!newPassword || !repeatedPassword)) {
      return message.error(
        intl.formatMessage(Locale.Text.Provide_both_passwords),
        5,
      );

    }
    if (!doPasswordsMatch(newPassword, repeatedPassword)) {
      return message.error(intl.formatMessage(Locale.Text.Passwords_do_not_match),
        5);
    }

    if (!isPasswordValid(newPassword)) {
      return message.error(intl.formatMessage(Locale.Text.This_is_not_a_valid_password),
        5);
    }

    const resetData = {
      newPassword,
      tokenWithUserId: token,
      repeatedPassword,
    };

    if (!resetData) {
      return message.error(intl.formatMessage(Locale.Text.Something_went_wrong),
        5);
    }

    const response = await fetch('/password/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(resetData),
    });
    if (response.status === 400) {
      message.error(intl.formatMessage(Locale.Text.Token_is_not_valid),
        5);
    }

    if (response.status === 200) {
      message.info(intl.formatMessage(Locale.Text.Successfully_created_new_password),
        5);
      navigate('/login', { replace: true });
    }
  };

  return (
    <section id="create-password-screen">
      <div className="login-container">
        <img alt="advance logo" className="advance-logo" src={advanceLogo} />
        <div className="login-card create-password-login-card">
          <Form id="create-password-form" onFinish={handleSubmitPassword} form={form}>
            <h3 id='create-password-heading'>{intl.formatMessage(Locale.Command.Create_new_password)}</h3>
            <ul>
              <li>{intl.formatMessage(Locale.Text.characters_8_15)}</li>
              <li>{intl.formatMessage(Locale.Text.Uppercase_and_lowercase_letters)}</li>
              <li>{intl.formatMessage(Locale.Text.At_least_one_number)}</li>
              <li>{intl.formatMessage(Locale.Text.At_least_one_special_character)}</li>
            </ul>

            <Form.Item name="password" className="login-field login-password">
              <Input.Password
                placeholder={
                  intl.formatMessage(Locale.General.Password)
                }
                size="large"
              />
            </Form.Item>
            <Form.Item name="repeat-password" className="login-field login-password">
              <Input.Password
                placeholder={
                  intl.formatMessage(Locale.Text.Repeat_password)
                }
                size="large"
              />
            </Form.Item>
            <div className="login-field button-submit">
              <Form.Item>
                <Button
                  label={
                    intl.formatMessage(Locale.Command.Submit)
                  }
                  color="green"
                  shape="circle"
                  size="small"
                  className="login-button"
                  style={{ width: '50%' }}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>

      <div className="icon background" />
      <div className="footer">
        <div className="footer-container">
          <Row>
            <Col sm={24} md={12} lg={12} xl={12} xxl={12}>
              {/* {imgPath && <img className="customer-logo" alt="" src={imgPath} />} */}
            </Col>
            <Col sm={24} md={24} lg={12} xl={12} xxl={12}>
              <img alt="apurebase-logo" className="apurebase-logo" src={apbLogo} />
            </Col>
          </Row>

        </div>
      </div>
    </section>
  );
};

// const INSTANCE = gql`
//   query Instance {
//     instanceName
//     imgPath
//   }
// `;

export default CreatePasswordPage;

