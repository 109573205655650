import React from 'react';
import { EventContentArg } from '@fullcalendar/common';
import CalendarCard from '../../components/Card/CalendarCard';

type CalendarActivityEventProps = {
  content: EventContentArg;
};

const CalendarActivityEvent: React.FC<CalendarActivityEventProps> = ({ content }) => {
  const borderColor = content.event.textColor ?? '#f1f1f1';
  if (content.view.type === 'month') return (
    <span
      style={{
        display: 'flex',
        width: '100%',
        borderLeft: `2px solid ${borderColor}`,
        backgroundColor: `${borderColor}19`,
        paddingLeft: 4,
      }}
    >{content.event.title}
    </span>
  );

  return <div className="calendar-activity-event-card-container">
    <CalendarCard
      title={content.event.title}
      startTime={content.event.start}
      endTime={content.event.end}
      status={content.event.extendedProps.status}
      activityId={content.event.extendedProps.id}
      phone={content.event.extendedProps.phone}
      site={content.event.extendedProps.site}
      containerHeight="100%"
      borderColor={borderColor}
      persons={content.event.extendedProps.persons}
    />
  </div>;
};
export default CalendarActivityEvent;
