import { useMemo } from 'react';
import { decodeJWT } from '../apollo/util';

export type UserType = {
  id: number;
  fullname: string;
  username: string;
  roleId: number;
};

export const useUser = (): UserType => useMemo(() => decodeJWT(
  localStorage
    .getItem('token')!
    .substr(7)
), []);
