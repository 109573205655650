import React, { useEffect } from 'react';
import { Button, Form, InputNumber, message } from 'antd';
import moment, { Moment } from 'moment';
import { gql } from '@apollo/client';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import MonthPicker from '../../../../components/Date/MonthPicker';
import {
  KPIQueryQuery,
  SegmentationSaleCycleInput,
  useupdateSaleCycleMutationMutation
} from '../../../../../gql/typings';


type ConfigureReportKPIProps = {
  selectedSetting: NonNullable<KPIQueryQuery['salesCycleSetups']>['nodes']['0'];
};

type FormData = {
  cycleStartMonth: Moment;
  numberOfCyclesInYear: number;
  segmentations: SegmentationSaleCycleInput[];
};

const ConfigureReportKPI: React.FC<ConfigureReportKPIProps> = ({ selectedSetting }) => {
  const [form] = Form.useForm<FormData>();
  const localization = useLocalization();
  const [update, { loading }] = useupdateSaleCycleMutationMutation();
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = (values: FormData) => update({
    variables: {
      countryCode: selectedSetting.countryCode,
      cycleAmount: values.numberOfCyclesInYear,
      year: selectedSetting.year,
      segmentations: values.segmentations,
      id: selectedSetting.id,
      cycleStartMonth: values.cycleStartMonth.get('month') + 1,
    }
  }).finally(() => messageApi.success('Sales cycle has been successfully created'));

  useEffect(() => {
    if (selectedSetting) {
      form.setFieldsValue({
        cycleStartMonth: moment().set('month', selectedSetting.cycleStartMonth - 1),
        numberOfCyclesInYear: selectedSetting.numberOfCyclesInYear,
        segmentations: selectedSetting.targets.nodes.map(e => ({
          segmentationId: e.id,
          goal: e.goal
        })),
      });
    }
  }, [selectedSetting, form]);


  return (
    <Form
      form={form}
      onFinish={onFinish}
      labelCol={{ span: 4 }}
      labelWrap
      colon={false}
      labelAlign="left"
      wrapperCol={{ span: 10 }}
      style={{ marginTop: 16, marginLeft: 20 }}
    >
      {contextHolder}
      <Form.Item
        name="cycleStartMonth"
        label={localization.formatMessage(Locale.Attribute.Fiscal_year_start)}
      >
        <MonthPicker />
      </Form.Item>
      <Form.Item
        name="numberOfCyclesInYear"
        label={localization.formatMessage(Locale.Text.No_of_targeting_cycle_in_year)}
      >
        <InputNumber min={0} max={4} />
      </Form.Item>

      <Form.Item label={localization.formatMessage(Locale.Text.No_of_calls_cycle_per_hcp_segment_group)}>
        <Form.List name="segmentations">
          {(fields, _, { errors }) => <>
            {fields.map((field, index) => (
              <Form.Item
                {...field}
                key={field.key}
                label={selectedSetting.targets.nodes[index]!.segmentationCode?.heading}
              >
                <SegmentationInput />
              </Form.Item>
            ))}
            <Form.ErrorList errors={errors} />
          </>}
        </Form.List>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 4 }}>
        <Button htmlType="submit" type="primary" loading={loading}>
          {localization.formatMessage(Locale.Command.Save_changes)}
        </Button>
      </Form.Item>
    </Form>
  );
};

const SegmentationInput: React.FC<{
  value?: FormData['segmentations'][0];
  onChange?: (v: FormData['segmentations'][0]) => void;
}> = props => (
  <InputNumber
    min={0}
    max={100}
    value={props.value?.goal}
    onChange={goal => props.onChange?.({ ...props.value!, goal: goal as number })}
  />
);


gql`
  mutation updateSaleCycleMutation(
    $year: Int!,
    $cycleStartMonth: Month!,
    $countryCode: CountryCode!,
    $cycleAmount: Int!,
    $id: Int!,
    $segmentations: [SegmentationSaleCycleInput!]!
  ) {
    updateSaleCycle(
      id: $id,
      country: $countryCode,
      cycleAmount: $cycleAmount,
      cycleStartMonth: $cycleStartMonth,
      year: $year,
      segmentationValues: $segmentations
    ) {
      id
      country
      createdByUserId
      cycleStartMonth
      modifiedByUserId
      numberOfCyclesInYear
      startMonth
      year
    }
  }
`;


export default ConfigureReportKPI;
