/* eslint-disable react/no-this-in-sfc,no-bitwise,no-var,vars-on-top,block-scoped-var,no-multi-assign,no-mixed-operators */
import React from 'react';
import Canvas from './Canvas';

// Source taken from: https://codepen.io/ErRobin/pen/ZEWYNEQ
const AnimatedText = ({
  texts = ['HAPPY', 'BIRTHDAY!'],
  height = 240,
  width = 380,
}) => {

  const draw = (ctx, frameCount) => {
    const { canvas } = ctx;
    let w = canvas.width;
    let h = canvas.height;

    let hw = w / 2;// half-width
    let hh = h / 2;

    const opts = {
      strings: texts,
      charSize: 30,
      charSpacing: 35,
      lineHeight: 40,

      cx: w / 2,
      cy: h / 2,

      fireworkPrevPoints: 10,
      fireworkBaseLineWidth: 5,
      fireworkAddedLineWidth: 8,
      fireworkSpawnTime: 200,
      fireworkBaseReachTime: 30,
      fireworkAddedReachTime: 30,
      fireworkCircleBaseSize: 20,
      fireworkCircleAddedSize: 10,
      fireworkCircleBaseTime: 30,
      fireworkCircleAddedTime: 30,
      fireworkCircleFadeBaseTime: 10,
      fireworkCircleFadeAddedTime: 5,
      fireworkBaseShards: 5,
      fireworkAddedShards: 5,
      fireworkShardPrevPoints: 3,
      fireworkShardBaseVel: 4,
      fireworkShardAddedVel: 2,
      fireworkShardBaseSize: 3,
      fireworkShardAddedSize: 3,
      gravity: 0.1,
      upFlow: -0.1,
      letterContemplatingWaitTime: 360,
      balloonSpawnTime: 20,
      balloonBaseInflateTime: 10,
      balloonAddedInflateTime: 10,
      balloonBaseSize: 20,
      balloonAddedSize: 20,
      balloonBaseVel: 0.4,
      balloonAddedVel: 0.4,
      balloonBaseRadian: -(Math.PI / 2 - 0.5),
      balloonAddedRadian: -1,
    };
    const calc = {
      totalWidth: opts.charSpacing * Math.max(opts.strings[0].length, opts.strings[1].length),
    };
    const Tau = Math.PI * 2;
    const TauQuarter = Tau / 4;

    const letters = [];


    ctx.font = `${opts.charSize}px Verdana`;

    function reset(letter) {
      letter.phase = 'firework';
      letter.tick = 0;
      letter.spawned = false;
      letter.spawningTime = opts.fireworkSpawnTime * Math.random() | 0;
      letter.reachTime = opts.fireworkBaseReachTime + opts.fireworkAddedReachTime * Math.random() | 0;
      letter.lineWidth = opts.fireworkBaseLineWidth + opts.fireworkAddedLineWidth * Math.random();
      letter.prevPoints = [[0, hh, 0]];
    }
    function Letter(char, x, y) {
      const hue = x / calc.totalWidth * 360;
      const l = {
        char,
        x,
        y,

        dx: -ctx.measureText(char).width / 2,
        dy: +opts.charSize / 2,

        fireworkDy: y - hh,

        color: 'hsl(hue,80%,50%)'.replace('hue', hue),
        lightAlphaColor: 'hsla(hue,80%,light%,alp)'.replace('hue', hue),
        lightColor: 'hsl(hue,80%,light%)'.replace('hue', hue),
        alphaColor: 'hsla(hue,80%,50%,alp)'.replace('hue', hue),
      };

      reset(l);
      return l;
    }

    function step(letter) {
      if (letter.phase === 'firework') {
        if (!letter.spawned) {
          ++letter.tick;
          if (letter.tick >= letter.spawningTime) {
            letter.tick = 0;
            letter.spawned = true;
          }
        } else {
          ++letter.tick;
          const linearProportion = letter.tick / letter.reachTime;
          const armonicProportion = Math.sin(linearProportion * TauQuarter);
          // noinspection JSAnnotator
          var x = linearProportion * letter.x;
          // noinspection JSAnnotator
          var y = hh + armonicProportion * letter.fireworkDy;

          if (letter.prevPoints.length > opts.fireworkPrevPoints) letter.prevPoints.shift();

          letter.prevPoints.push([x, y, linearProportion * letter.lineWidth]);

          const lineWidthProportion = 1 / (letter.prevPoints.length - 1);

          for (var i = 1; i < letter.prevPoints.length; ++i) {

            var point = letter.prevPoints[i];
            var point2 = letter.prevPoints[i - 1];

            ctx.strokeStyle = letter.alphaColor.replace('alp', i / letter.prevPoints.length);
            ctx.lineWidth = point[2] * lineWidthProportion * i;
            ctx.beginPath();
            ctx.moveTo(point[0], point[1]);
            ctx.lineTo(point2[0], point2[1]);
            ctx.stroke();

          }

          if (letter.tick >= letter.reachTime) {

            letter.phase = 'contemplate';

            letter.circleFinalSize = opts.fireworkCircleBaseSize + opts.fireworkCircleAddedSize * Math.random();
            letter.circleCompleteTime = opts.fireworkCircleBaseTime + opts.fireworkCircleAddedTime * Math.random() | 0;
            letter.circleCreating = true;
            letter.circleFading = false;

            letter.circleFadeTime = opts.fireworkCircleFadeBaseTime + opts.fireworkCircleFadeAddedTime * Math.random() | 0;
            letter.tick = 0;
            letter.tick2 = 0;

            letter.shards = [];

            var shardCount = opts.fireworkBaseShards + opts.fireworkAddedShards * Math.random() | 0;
            var angle = Tau / shardCount;
            var cos = Math.cos(angle);
            var sin = Math.sin(angle);

            // noinspection JSAnnotator
            var x = 1;
            // noinspection JSAnnotator
            var y = 0;

            for (var i = 0; i < shardCount; ++i) {
              var x1 = x;
              x = x * cos - y * sin;
              y = y * cos + x1 * sin;

              letter.shards.push(Shard(letter.x, letter.y, x, y, letter.alphaColor));
            }
          }

        }
      } else if (letter.phase === 'contemplate') {
        ++letter.tick;
        if (letter.circleCreating) {
          ++letter.tick2;
          var proportion = letter.tick2 / letter.circleCompleteTime;
          var armonic = -Math.cos(proportion * Math.PI) / 2 + 0.5;

          ctx.beginPath();
          ctx.fillStyle = letter.lightAlphaColor.replace('light', 50 + 50 * proportion).replace('alp', proportion);
          ctx.beginPath();
          ctx.arc(letter.x, letter.y, armonic * letter.circleFinalSize, 0, Tau);
          ctx.fill();

          if (letter.tick2 > letter.circleCompleteTime) {
            letter.tick2 = 0;
            letter.circleCreating = false;
            letter.circleFading = true;
          }
        } else if (letter.circleFading) {

          ctx.fillStyle = letter.lightColor.replace('light', 70);
          ctx.fillText(letter.char, letter.x + letter.dx, letter.y + letter.dy);

          ++letter.tick2;
          var proportion = letter.tick2 / letter.circleFadeTime;
          var armonic = -Math.cos(proportion * Math.PI) / 2 + 0.5;

          ctx.beginPath();
          ctx.fillStyle = letter.lightAlphaColor.replace('light', 100).replace('alp', 1 - armonic);
          ctx.arc(letter.x, letter.y, letter.circleFinalSize, 0, Tau);
          ctx.fill();

          if (letter.tick2 >= letter.circleFadeTime) letter.circleFading = false;

        } else {
          ctx.fillStyle = letter.lightColor.replace('light', 70);
          ctx.fillText(letter.char, letter.x + letter.dx, letter.y + letter.dy);
        }

        for (var i = 0; i < letter.shards.length; ++i) {

          shardStep(letter.shards[i]);

          if (!letter.shards[i].alive) {
            letter.shards.splice(i, 1);
            --i;
          }
        }

        if (letter.tick > opts.letterContemplatingWaitTime) {

          letter.phase = 'balloon';

          letter.tick = 0;
          letter.spawning = true;
          letter.spawnTime = opts.balloonSpawnTime * Math.random() | 0;
          letter.inflating = false;
          letter.inflateTime = opts.balloonBaseInflateTime + opts.balloonAddedInflateTime * Math.random() | 0;
          letter.size = opts.balloonBaseSize + opts.balloonAddedSize * Math.random() | 0;

          var rad = opts.balloonBaseRadian + opts.balloonAddedRadian * Math.random();
          var vel = opts.balloonBaseVel + opts.balloonAddedVel * Math.random();

          letter.vx = Math.cos(rad) * vel;
          letter.vy = Math.sin(rad) * vel;
        }
      } else if (letter.phase === 'balloon') {

        ctx.strokeStyle = letter.lightColor.replace('light', 80);

        if (letter.spawning) {

          ++letter.tick;
          ctx.fillStyle = letter.lightColor.replace('light', 70);
          ctx.fillText(letter.char, letter.x + letter.dx, letter.y + letter.dy);

          if (letter.tick >= letter.spawnTime) {
            letter.tick = 0;
            letter.spawning = false;
            letter.inflating = true;
          }
        } else if (letter.inflating) {

          ++letter.tick;

          var proportion = letter.tick / letter.inflateTime;
          var x = letter.cx = letter.x;
          var y = letter.cy = letter.y - letter.size * proportion;

          ctx.fillStyle = letter.alphaColor.replace('alp', proportion);
          ctx.beginPath();
          generateBalloonPath(x, y, letter.size * proportion);
          ctx.fill();

          ctx.beginPath();
          ctx.moveTo(x, y);
          ctx.lineTo(x, letter.y);
          ctx.stroke();

          ctx.fillStyle = letter.lightColor.replace('light', 70);
          ctx.fillText(letter.char, letter.x + letter.dx, letter.y + letter.dy);

          if (letter.tick >= letter.inflateTime) {
            letter.tick = 0;
            letter.inflating = false;
          }

        } else {

          letter.cx += letter.vx;
          letter.cy += letter.vy += opts.upFlow;

          ctx.fillStyle = letter.color;
          ctx.beginPath();
          generateBalloonPath(letter.cx, letter.cy, letter.size);
          ctx.fill();

          ctx.beginPath();
          ctx.moveTo(letter.cx, letter.cy);
          ctx.lineTo(letter.cx, letter.cy + letter.size);
          ctx.stroke();

          ctx.fillStyle = letter.lightColor.replace('light', 70);
          ctx.fillText(letter.char, letter.cx + letter.dx, letter.cy + letter.dy + letter.size);

          if (letter.cy + letter.size < -hh || letter.cx < -hw || letter.cy > hw) letter.phase = 'done';
        }
      }
    }
    function Shard(x, y, vx, vy, color) {
      var vel = opts.fireworkShardBaseVel + opts.fireworkShardAddedVel * Math.random();

      return {
        vx: vx * vel,
        vy: vy * vel,

        x,
        y,

        prevPoints: [[x, y]],
        color,

        alive: true,

        size: opts.fireworkShardBaseSize + opts.fireworkShardAddedSize * Math.random(),
      };
    }
    function shardStep(shard) {

      shard.x += shard.vx;
      shard.y += shard.vy += opts.gravity;

      if (shard.prevPoints.length > opts.fireworkShardPrevPoints) shard.prevPoints.shift();

      shard.prevPoints.push([shard.x, shard.y]);

      var lineWidthProportion = shard.size / shard.prevPoints.length;

      for (var k = 0; k < shard.prevPoints.length - 1; ++k) {

        var point = shard.prevPoints[k];
        var point2 = shard.prevPoints[k + 1];

        ctx.strokeStyle = shard.color.replace('alp', k / shard.prevPoints.length);
        ctx.lineWidth = k * lineWidthProportion;
        ctx.beginPath();
        ctx.moveTo(point[0], point[1]);
        ctx.lineTo(point2[0], point2[1]);
        ctx.stroke();

      }

      if (shard.prevPoints[0][1] > hh) shard.alive = false;
    }

    function generateBalloonPath(x, y, size) {
      ctx.moveTo(x, y);
      ctx.bezierCurveTo(x - size / 2, y - size / 2,
        x - size / 4, y - size,
        x, y - size);
      ctx.bezierCurveTo(x + size / 4, y - size,
        x + size / 2, y - size / 2,
        x, y);
    }

    function anim() {
      window.requestAnimationFrame(anim);

      ctx.fillStyle = '#111';
      ctx.fillRect(0, 0, w, h);

      ctx.translate(hw, hh);

      var done = true;
      for (var l = 0; l < letters.length; ++l) {
        step(letters[l]);
        if (letters[l].phase !== 'done') done = false;
      }

      ctx.translate(-hw, -hh);

      if (done) {
        for (var l = 0; l < letters.length; ++l) {
          reset(letters[l]);
        }
      }
    }
    for (var i = 0; i < opts.strings.length; ++i) {
      for (var j = 0; j < opts.strings[i].length; ++j) {
        letters.push(Letter(opts.strings[i][j],
          j * opts.charSpacing + opts.charSpacing / 2 - opts.strings[i].length * opts.charSize / 2,
          i * opts.lineHeight + opts.lineHeight / 2 - opts.strings.length * opts.lineHeight / 2));
      }
    }
    anim();

    window.addEventListener('resize', () => {
      w = canvas.width = window.innerWidth;
      h = canvas.height = window.innerHeight;

      hw = w / 2;
      hh = h / 2;

      ctx.font = `${opts.charSize}px Verdana`;
    });
  };

  return <Canvas draw={draw} height={height} width={width} />;
};

export default AnimatedText;
