import React from 'react';
import { Col, Row, Typography } from 'antd';
import { Locale } from '../../../localization/LocalizationKeys';
import { useLocalization } from '../../util/useLocalization';
import RelatedActivityDcr from './RelatedActivityDcr';
import { isTypename } from '../../util/graphqlUtils';
import { Optional } from '../../util/StateArrayType';
import FilterListsDcr from './FilterListsDcr';
import { DcrGroupDetailQuery, EntityTypeEnum } from '../../../gql/typings';

type DcrRelatedDataTabProps = {
  group: Optional<DcrGroupDetailQuery['dcrGroup']>;
  mdmEnabled: boolean | null | undefined;
};


const { Title, Paragraph } = Typography;

const DcrRelatedDataTab: React.FC<DcrRelatedDataTabProps> = ({ group, mdmEnabled }) => {
  const localization = useLocalization();

  const snapshots = (group?.dcrs.nodes ?? []).reduce((acc, dcr) => ({
    ...acc,
    [dcr.entityTypeEnum!]: [
      ...(acc[dcr.entityTypeEnum!] ?? []),
      ...dcr.dcrSnapshotInfo.map(s => ({
        entityType: s.entityTypeEnum,
        affiliationId: s.entityAffiliationId,
        ...s.affiliationEntity,
      })),
    ],
  }), {} as Record<EntityTypeEnum, Optional<({
    entityType: EntityTypeEnum;
    affiliationId: number;
  } & NonNullable<DcrGroupDetailQuery['dcrGroup']>['dcrs']['nodes']['0']['dcrSnapshotInfo']['0']['affiliationEntity'])[]
  >>);


  const personActivities = snapshots[EntityTypeEnum.PERSON]?.filter(isTypename('Activity')) ?? [];
  const siteActivities = snapshots[EntityTypeEnum.SITE]?.filter(isTypename('Activity')) ?? [];
  const renderActivities = personActivities.length > 0 || siteActivities.length > 0;
  const personLists = snapshots[EntityTypeEnum.PERSON]?.filter(isTypename('List')) ?? [];
  const siteLists = snapshots[EntityTypeEnum.SITE]?.filter(isTypename('List')) ?? [];
  const renderLists = personLists.length > 0 || siteLists.length > 0;

  return (
    <div>
      <Paragraph>
        <Row>
          <Col span={20}>
            <Title level={4}>{localization.formatMessage(Locale.General.Overview)}</Title>
            <ul>
              <li>
                {localization.formatMessage(Locale.Text.DCR_related_data_overview_text)}
              </li>
              <li>
                {localization.formatMessage(Locale.Text.DCR_related_data_take_action_text)}
              </li>
              <li>
                {mdmEnabled ? localization.formatMessage(Locale.Text.DCR_related_data_display_below)
                  : localization.formatMessage(Locale.Text.DCR_related_data_display_below_no_mdm)}
              </li>
              {mdmEnabled && <li>
                {localization.formatMessage(Locale.Text.DCR_related_data_confirm_rejection_text)}
              </li>}
            </ul>
          </Col>
        </Row>
      </Paragraph>
      {renderActivities && <>
        <Title level={4}>
          {localization.formatMessage(Locale.General.Activities)}
        </Title>
        <Paragraph>
          <ul id="related-activities-list-container">
            <RelatedActivityDcr activities={personActivities} entityType={EntityTypeEnum.PERSON} />
            <RelatedActivityDcr activities={siteActivities} entityType={EntityTypeEnum.SITE} />
          </ul>
        </Paragraph>
      </>}
      {renderLists && <>
        <Title level={4}>
          {localization.formatMessage(Locale.General.Lists)}
        </Title>
        <Paragraph>
          <ul>
            <FilterListsDcr
              responsibleUserId={group?.responsibleUserId}
              ownerStatus={group?.areYouDcrOwner}
              relatedLists={[...personLists, ...siteLists]}
            />
          </ul>
        </Paragraph>
      </>}
    </div>
  );
};

export default DcrRelatedDataTab;
