import { gql, useMutation } from '@apollo/client';
import { CherryPickMutation, CherryPickMutationVariables } from '../../../../gql/typings';

// This is in a separate file to avoid circular dependency from DownloadBadge and PersonTable / SiteTable
export default () => useMutation<CherryPickMutation, CherryPickMutationVariables>(gql`
  mutation CherryPick($type: EntityTypeEnum!, $apurebaseIds: [Int!]!) {
    cherryPick(entityType: $type, apurebaseIds: $apurebaseIds) {
      id
      ... on Person {
        fullName
      }
    }
  }
`);
