import React, { useState } from 'react';
import { Divider, Modal } from 'antd';
import { EntityTypeEnum } from '../../../gql/typings';
import { StateArray } from '../../util/StateArrayType';
import { useLocalization } from '../../util/useLocalization';
import { Locale } from '../../../localization/LocalizationKeys';
import EntityMergeModalSelectRecordStep from './EntityMergeModalSelectRecordStep';
import EntityMergeModalFooter from './EntityMergeModalFooter';
import { useEntityMerge } from '../../browse/admin/adminComponents/AdminEntityMergeRequest/MergeRequestDetails/useEntityMerge';
import AdminEntityMergeRequestProgress
  from '../../browse/admin/adminComponents/AdminEntityMergeRequest/MergeRequestDetails/AdminEntityMergeRequestProgress';
import { EntitiesSearch } from '../entitiesSearch';
import { useTableRowSelection } from '../Table/useTableRowSelection';

export type EntityMergeStepsPage = 'selectAgainst' | 'selectWinner' | 'overview';

export type EntityMergeModalProps = {
  visibleState: StateArray<boolean>;
  fromId: number;
  intoId?: number;
  entityType: EntityTypeEnum.PERSON|EntityTypeEnum.SITE;
  onCompleteHook?: () => void;
};

const EntityMergeModal: React.FC<EntityMergeModalProps> = ({
  visibleState: [visible, setVisible],
  entityType,
  fromId: id1,
  intoId,
  onCompleteHook,
}) => {
  const localization = useLocalization();

  const [id2, setId2] = useState(intoId);
  const [winnerId, setWinnerId] = useState<number>();
  const loserId = winnerId === id1 ? id2 : id1;

  const stepState = useState<EntityMergeStepsPage>(id2 ? 'selectWinner' : 'selectAgainst');
  const mergeInfo = useEntityMerge(entityType, loserId, winnerId);
  const currentStep = stepState[0]!;

  const againstTableSelection = useTableRowSelection({ type: 'radio' });

  return (
    <Modal
      open={visible}
      width="clamp(40%, 1460px, 80%)"
      style={{ top: 20 }}
      title={localization.formatMessage(Locale.Command.Merge_record)}
      onCancel={() => setVisible(false)}
      footer={<EntityMergeModalFooter
        stepState={stepState}
        modalVisibleState={[visible, setVisible]}
        mergeInfo={mergeInfo}
        entityType={entityType}
        loserId={loserId}
        winnerId={winnerId}
        setId2={setId2}
        againstTableSelection={againstTableSelection}
        onCompleteHook={onCompleteHook}
      />}
    >
      {visible && currentStep === 'selectAgainst' && (
        <EntitiesSearch
          entityType={entityType}
          tableSelection={againstTableSelection}
          criteriaFilter={{ excludeIds: [id1] }}
          quickActions={[{
            key: 'select',
            label: localization.formatMessage(Locale.Command.Select),
            disabled: againstTableSelection.selection.selected.length !== 1,
            onClick: () => {
              setId2(againstTableSelection.selection.selected[0]);
              stepState[1]('selectWinner');
            },
          }]}
        />
      )}
      {visible && currentStep === 'selectWinner' && (
        <EntityMergeModalSelectRecordStep
          entityType={entityType}
          setWinnerId={setWinnerId}
          ids={id2 ? [id1, id2] : [id1]}
        />
      )}
      {visible && currentStep === 'overview' && <div>
        <AdminEntityMergeRequestProgress emitter={mergeInfo.emitter} />
        {mergeInfo.generateDescription()}
        <Divider />
        {mergeInfo.render()}
      </div>}
    </Modal>
  );
};

export default EntityMergeModal;
