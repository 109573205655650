import { toNumber } from 'lodash';
import { Moment } from 'moment';
import { Locale } from '../../../../localization/LocalizationKeys';
import { DATE_FORMAT } from '../../../util/format';

export type ActivityTableSpan = {
  label: { id: string };
  format?: string|((m: Moment) => string);
  groupBy?: (m: Moment) => number;
};

export const spans: ActivityTableSpan[] = [
  {
    label: Locale.Attribute.None,
  },
  {
    label: Locale.Attribute.Year,
    format: 'YYYY',
    groupBy: (m: Moment) => m.year(),
  },
  {
    label: Locale.Attribute.Quarter,
    format: (m: Moment) => `Quarter ${m.format('Q')} - ${m.format('YYYY')}`,
    groupBy: (m: Moment) => toNumber(`${m.year()}${m.quarter()}`),
  },
  {
    label: Locale.Attribute.Month,
    format: 'MMMM YYYY ',
    groupBy: (m: Moment) => toNumber(`${m.year()}${m.month()}`),
  },
  {
    label: Locale.Attribute.Week,
    format: (m: Moment) => `Week ${m.week()} - ${m.year()}`,
    groupBy: (m: Moment) => toNumber(`${m.year()}${m.month()}${m.week()}`),
  },
  {
    label: Locale.Attribute.Day,
    format: DATE_FORMAT,
    groupBy: (m: Moment) => toNumber(`${m.year()}${m.dayOfYear()}`),
  },
  {
    label: Locale.Attribute.Hour,
    format: (m: Moment) => `${m.format(DATE_FORMAT)} - Hour ${m.hour()}`,
    groupBy: (m: Moment) => toNumber(`${m.year()}${m.dayOfYear()}${m.hour()}`),
  },
  // {
  //   label: 'Minute',
  //   format: 'LLL',
  //   groupBy: (m: Moment) => toNumber(`${m.year()}${m.dayOfYear()}${m.hour()}${m.minute()}`),
  // },
];

export const spansDefaultIndex = 0;
