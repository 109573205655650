import React from 'react';
import { gql } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import LinkBadge from '../../../components/Badge/LinkBandge';
import { personFieldConfig } from './utils/personFieldsUtils';
import {
  EntityTypeEnum, FieldEnum, PersonCriteriaOrder, UpdateHcpMiddleNameMutationMutationVariables
} from '../../../../gql/typings';
import { buildFreeTextUpdateFieldConfig } from '../../../components/FieldConfig/buildFreeTextUpdateFieldConfig';


export const personMiddleNameFieldConfig = personFieldConfig(
  'middleName',
  [
    'id',
    'middleName',
    'isLocal',
    'controlSetting.id',
    'controlSetting.createDcr',
  ],
  ({ localization, openInNewTab }) => ({
    dcrInfo: person => ({
      entityType: EntityTypeEnum.PERSON,
      entityAffiliationId: person.id,
      field: FieldEnum.HCP_MIDDLENAME,
    }),
    sorting: {
      ascend: [PersonCriteriaOrder.MIDDLENAME_ASC],
      descend: [PersonCriteriaOrder.MIDDLENAME_DESC],
    },
    additionalTableConfig: {
      width: 100
    },
    title: localization.formatMessage(Locale.Attribute.Middle_name),
    render: (record, { isViewingFromPage, isViewingFromEntity }) => {
      if (isViewingFromPage == 'DETAIL_PAGE' && isViewingFromEntity === 'PERSON') return record.middleName;
      return <LinkBadge
        url={record.isLocal ? `/hcp/${record.id}` : undefined}
        label={record.middleName}
        openInNewTab={openInNewTab}
      />;
    },
    updateView: buildFreeTextUpdateFieldConfig({
      mutation: UPDATE_MUTATION,
      valuePath: 'middleName',
      buildVariables: (v, record) => ({
        middleName: v,
        persId: record.id,
      }) as UpdateHcpMiddleNameMutationMutationVariables,
    }),
  }),
);

const UPDATE_MUTATION = gql`
  mutation UpdateHcpMiddleNameMutation($persId: Int!,$middleName: String!) {
    updatePersonMiddleName(persId: $persId, middleName: $middleName) {
      id
      firstName
      middleName
      lastName
      fullName
      title
    }
  }
`;
