import { gql } from '@apollo/client';
import { ManualPersonAction_FRAGMENT } from './manualPersonActionQueries';
import { ManualEventActionFragment } from './manualEventAction';
import { ManualExternalUserMapping_FRAGMENT } from './manualExternalUserMappingAction';
import {
  SelectExternalSystemManual_FRAGMENT,
} from '../ImportDetails/manual/SelectExternalSystem/SelectExternalSystemManualFragment';


export const CURRENT_IMPORT_STATE_FRAGMENT = gql`
  fragment currentImportState on ImportProject {
    id
    heading
    statusCode
    statusNote
    ut
    manual {
      projectId
      lineId
      templateProcessId
      ... on ImportManualPersonStage { ...ManualPersonActionFragment }
      ... on ImportManualEventMatchStage { ...ManualEventActionFragment }
      ... on ManualExternalUserIdMapping { ...ManualExternalUserMappingFragment }
      ... on SelectExternalSystemManualState { ...SelectExternalSystemManual }
    }
    meta {
      projectId
      missingLineCount
      steps
      currentStepIndex
      percentage
    }
  }
  ${ManualPersonAction_FRAGMENT}
  ${ManualEventActionFragment}
  ${ManualExternalUserMapping_FRAGMENT}
  ${SelectExternalSystemManual_FRAGMENT}
`;
