import { pickBy, startsWith, flow } from 'lodash/fp';
import { dynamicCriteriaKey } from '../../components/Form/Dynamic/dynamicCriteriaInputFactory';
import { criteriasToArrayFactory } from '../search_old/criterias/criteriasToArrayFactory';

const criteriasToArray = criteriasToArrayFactory();

/**
 * Transforms ant form values into graphql ready for updating dynamic lists
 *
 * @param {object} values - Should be the object retrieved from form.getFieldsValue()
 * @returns {{criteriaId: number, value: object}[]}
 */
export const prepareDynamicForGraphql = flow(
  pickBy((_, key) => startsWith(dynamicCriteriaKey, key)),
  criteriasToArray,
);

// MARK: These IDs are strings to be able to work along side ant form equal operations
export const LIST_TYPE_ID = {
  DYNAMIC: '1',
  STATIC: '2',
};
