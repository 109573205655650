import React from 'react';
import { gql } from '@apollo/client';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { MergeItemPersonTitleFragment, useUpdatePersonTitleMutation } from '../../../../../../../../../gql/typings';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';


const AdminMergeItemPersonTitle: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemPersonTitleFragment;
  const localization = useLocalization();
  const [updateTitle] = useUpdatePersonTitleMutation();

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.fromTitle}
      toValue={info.toTitle}
      updateMutation={newValue => updateTitle({
        variables: {
          personId: props.into.id,
          newValue,
        },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_title_mismatch_description, {
        title1: <strong>{info.fromTitle}</strong>,
        title2: <strong>{info.toTitle}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation UpdatePersonTitle($personId: Int!, $newValue: String!) {
    updatePersonTitle(persId: $personId, title: $newValue) {
      id
      title
    }
  }
`;

gql`
  fragment MergeItemPersonTitle on PersonTitle_EntityDataToBeTransferredType {
    code
    fromTitle: from
    toTitle: to
  }
`;

export default AdminMergeItemPersonTitle;
