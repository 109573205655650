import React from 'react';
import { gql } from '@apollo/client';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useUpdateSiteWebpageMutation, MergeItemSiteWebpageFragment } from '../../../../../../../../../gql/typings';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';


const AdminMergeItemSiteWebpage: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemSiteWebpageFragment;
  const localization = useLocalization();
  const [updateWebpage] = useUpdateSiteWebpageMutation();

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.fromWebpage}
      toValue={info.toWebpage}
      updateMutation={newValue => updateWebpage({
        variables: {
          siteId: props.into.id,
          newValue,
        },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_webpage_mismatch_description, {
        webpage1: <strong>{info.fromWebpage}</strong>,
        webpage2: <strong>{info.toWebpage}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation UpdateSiteWebpage($siteId: Int!, $newValue: String!) {
    updateSiteWebpage(siteId: $siteId, webpage: $newValue) {
      id
      siteWebpage
    }
  }
`;

gql`
  fragment MergeItemSiteWebpage on SiteWebpage_EntityDataToBeTransferredType {
    code
    fromWebpage: from
    toWebpage: to
  }
`;

export default AdminMergeItemSiteWebpage;
