import React from 'react';
import { FormInstance } from 'antd';
import CreateCustomFieldOptionsSection from './CreateCustomFieldOptionsSection';
import CreateCustomFreeTextInput from './CreateCustomFreeTextInput';
import { CreateCustomFieldFormData } from '../CreateCustomField';
import { CustomFieldTypeEnum, EntityTypeEnum } from '../../../../../gql/typings';

export type CreateCustomFieldTypeSectionProps = {
  customFieldCode?: string;
  entityType: EntityTypeEnum;
  form: FormInstance<CreateCustomFieldFormData>;
};

export const createCustomFieldTypeSections: Record<CustomFieldTypeEnum, React.FC<CreateCustomFieldTypeSectionProps>> = {
  CHECK: CreateCustomFieldOptionsSection,
  NUMBER: CreateCustomFreeTextInput,
  SWITCH: CreateCustomFreeTextInput,
  TAG: CreateCustomFreeTextInput,
  TEXT: CreateCustomFreeTextInput,
  // RADIO: CreateCustomFieldOptionsSection,
  SELECT: CreateCustomFieldOptionsSection,
  // MULTI_SELECT: CreateCustomFieldOptionsSection,
};
