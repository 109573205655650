import React from 'react';
import { Col, Row, Switch } from 'antd';
import { gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import { useMap } from 'ahooks';
import { useLocalization } from '../../../../util/useLocalization';
import { extractBooleanValue } from '../../../../util/Util';
import { Locale } from '../../../../../localization/LocalizationKeys';
import {
  InstanceSettingEnum,
  SegmentationSettingsQueryQuery,
  UpdateSegmentationSettingMutation,
  UpdateSegmentationSettingMutationVariables
} from '../../../../../gql/typings';

const PersonSegmentationAdminSettings: React.FC = () => {
  const localization = useLocalization();
  const { loading, data } = useQuery<SegmentationSettingsQueryQuery>(DATA_QUERY);
  const [update] = useMutation<UpdateSegmentationSettingMutation, UpdateSegmentationSettingMutationVariables>(UPDATE_MUTATION);
  const [, { get, set }] = useMap<InstanceSettingEnum, boolean>();

  const updateSwitch = (type: InstanceSettingEnum) => (checked: boolean) => {
    set(type, true);
    update({
      variables: {
        type,
        newValue: checked.toString(),
      }
    }).finally(() => set(type, false));
  };

  return (
    <div>
      <Row gutter={[0, 16]}>
        <Col span={4}>
          <strong>{localization.formatMessage(Locale.Attribute.HCP_Segmentation)}</strong>:
        </Col>
        <Col span={20}>
          <Switch
            loading={loading || get(InstanceSettingEnum.PERSON_SEGMENTATION)}
            checked={extractBooleanValue(data?.person?.value)}
            onChange={updateSwitch(InstanceSettingEnum.PERSON_SEGMENTATION)}
          />
        </Col>
        <Col span={4}>
          <strong>{localization.formatMessage(Locale.Attribute.Product_Segmentation)}</strong>:
        </Col>
        <Col span={20}>
          <Switch
            loading={loading || get(InstanceSettingEnum.PRODUCT_SEGMENTATION)}
            checked={extractBooleanValue(data?.product?.value)}
            onChange={updateSwitch(InstanceSettingEnum.PRODUCT_SEGMENTATION)}
          />
        </Col>
        <Col span={3} offset={1}>
          {localization.formatMessage(Locale.Text.Allow_multiple)}:
        </Col>
        <Col span={20}>
          <Switch
            loading={loading || get(InstanceSettingEnum.PRODUCT_SEGMENTATION_MULTIPLE)}
            disabled={!extractBooleanValue(data?.product?.value)}
            checked={extractBooleanValue(data?.allowMultipleProducts?.value)}
            onChange={updateSwitch(InstanceSettingEnum.PRODUCT_SEGMENTATION_MULTIPLE)}
            size="small"
          />
        </Col>
      </Row>
    </div>
  );
};

const DATA_QUERY = gql`
  query SegmentationSettingsQuery {
    person: instanceSetting(type: PERSON_SEGMENTATION) { code, value }
    product: instanceSetting(type: PRODUCT_SEGMENTATION) { code, value }
    allowMultipleProducts: instanceSetting(type: PRODUCT_SEGMENTATION_MULTIPLE) { code, value }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateSegmentationSetting($type: InstanceSettingEnum!, $newValue: String!) {
    updateInstanceSetting(type: $type, newValue: $newValue) {
      code
      value
    }
  }
`;

export default PersonSegmentationAdminSettings;
