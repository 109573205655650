import React from 'react';
import { gql } from '@apollo/client/core';
import { Col, Row, Skeleton, Space, Tag } from 'antd';
import { useQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import Icon from '../../../components/Icon/Icon';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useLocalization } from '../../../util/useLocalization';
import { useColors } from '../../../util/useColor';
import EntityHeaderContainer from '../../../components/ContactCard/EntityHeaderContainer';
import { FlagCountryWithUnknown } from '../../../components/Flag/Flag';
import LinkSectionRight from './LinkSectionRight';
import { LinkHeaderQuery, LinkHeaderQueryVariables } from '../../../../gql/typings';
import { usePageTitle } from '../../../hooks/usePageTitle';

type LinkHeaderContainerProps = {
  linkId: number;
};

const LinkHeaderContainer: React.FC<LinkHeaderContainerProps> = ({ linkId }) => {
  const { data, loading } = useQuery<LinkHeaderQuery, LinkHeaderQueryVariables>(DATA_QUERY, { variables: { id: linkId } });
  const localization = useLocalization();
  const link = data?.sitePersonAffiliation;
  const { colorAction: actionColor } = useColors();
  const renderInactiveBadge = data?.sitePersonAffiliation && !data.sitePersonAffiliation.isActive;
  const renderOwnRecord = data?.sitePersonAffiliation?.maintainerSourceCode === 'LOCAL';

  usePageTitle(link ? `${link.person.fullName} @ ${link.site.name}` : null);

  return (
    <Skeleton loading={loading}>
      <Row>
        <Col span={16}>
          <EntityHeaderContainer
            country={link?.countryCode as FlagCountryWithUnknown}
            title={<>
              <NavLink to={`/hcp/${link?.person?.id}`}><b>{link?.person?.fullName}</b></NavLink>
              &nbsp;@&nbsp;
              <NavLink to={`/hco/${link?.site?.id}`}><b>{link?.site?.name}</b></NavLink>
            </>}
          />
        </Col>
        <Col span={8}>
          <LinkSectionRight linkId={linkId} />
        </Col>
        <Col span={8} />
        <Col style={{ marginLeft: 24 }} span={24}>
          <Space>
            {renderInactiveBadge && <Tag color="#A6A6A6">
              <Icon
                iconType="CLOSE"
                text={localization.formatMessage(Locale.Text.Record_is_inactive)}
              />
            </Tag>}
            {renderOwnRecord && <Tag color={actionColor}>
              {localization.formatMessage(Locale.Text.Client_maintain)}
            </Tag>}

          </Space>
        </Col>
      </Row>
    </Skeleton>
  );
};

const DATA_QUERY = gql`
  query LinkHeader($id: Int!) {
    sitePersonAffiliation(id: $id) {
      id
      apurebaseId
      countryCode
      yesIfIsPrimary
      isActive
      maintainerSourceCode
      person{
        id
        countryCode
        fullName
      }
      specialties{
        hash
        nodes{
          id
          specialty{
            code
            label
            type
            displayLabel
          }
        }
      }
      site{
        id
        name
      }
      position{
        code
        type
        label
        displayLabel
      }
    }
  }
`;


export default LinkHeaderContainer;
