import React from 'react';
import { useEventEmitter } from 'ahooks';
import MainView, { MainViewProps } from '../View/MainView';
import DetailsViewContext from './DetailsViewContext';

type DetailsViewProps = MainViewProps;

const DetailsView: React.FC<DetailsViewProps> = ({
  children,
  ...props
}) => {
  const $ = useEventEmitter<void>();

  return (
    <DetailsViewContext.Provider value={{
      broadcastShouldRefetchData: $.emit,
      useShouldRefetchData: $.useSubscription,
    }}
    >
      <MainView {...props}>
        {children}
      </MainView>
    </DetailsViewContext.Provider>
  );
};

export default DetailsView;
