import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { SupportedEntitySearchTypes } from '../../search_old/types';
import { Locale } from '../../../../localization/LocalizationKeys';
import { genericFieldConfig, getVariableField } from '../../person/fields/utils/personFieldsUtils';
import {
  CustomFieldRuleQueryQuery, CustomFieldRuleQueryQueryVariables,
  CustomFieldTypeEnum,
  EntityCustomFieldConfigQueryQuery,
  EntityCustomFieldConfigQueryQueryVariables,
} from '../../../../gql/typings';
import { customFieldTypes } from './customFields/customFieldTypes';
import Loading from '../../../components/Loading/Loading';
import apolloClient from '../../../apollo/apolloClient';

export const buildEntityCustomFieldConfigs = (
  entityType: SupportedEntitySearchTypes,
) => genericFieldConfig(
  'customField',
  [
    'id',
    'customFields(criteria: { customFieldCode: "{{keyCode}}" }).hash',
    'customFields(criteria: { customFieldCode: "{{keyCode}}" }).nodes.id',
    'customFields(criteria: { customFieldCode: "{{keyCode}}" }).nodes.customFieldCode',
    'customFields(criteria: { customFieldCode: "{{keyCode}}" }).nodes.id',
    'customFields(criteria: { customFieldCode: "{{keyCode}}" }).nodes.value.id',
    'customFields(criteria: { customFieldCode: "{{keyCode}}" }).nodes.value.value',
  ],
  ({ localization }) => ({
    title: ({ selectedOption }) => selectedOption?.label,
    additionalTableConfig: {
      width: 150
    },
    getOptions: [
      localization.formatMessage(Locale.General.CustomField),
      ({ apolloClient }) => apolloClient.query<EntityCustomFieldConfigQueryQuery, EntityCustomFieldConfigQueryQueryVariables>({
        query: FIELD_TYPES,
        variables: { entityType },
      }).then(res => res.data.customFields.nodes)
    ],
    permission: (keycode: string) => {
      if (!keycode) return true;
      return apolloClient.query({
        query: RULE_QUERY,
        variables: {
          code: keycode
        }
      }).then(res => res.data.customField?.myAccessRules?.allowRead ?? false);
    },
    render: (record, options) => {
      if (!options.selectedOption) return ' ';
      return getVariableField(record, options, 'customFields')
        .nodes
        .map(n => n.value?.value)
        .join(', ');
    },
    hasUpdateSupport: ({ options: { selectedOption } }) => {
      const { data } = useQuery<CustomFieldRuleQueryQuery, CustomFieldRuleQueryQueryVariables>(RULE_QUERY, {
        skip: !selectedOption,
        fetchPolicy: 'cache-first',
        variables: {
          code: (selectedOption?.code ?? '') as string,
        },
      });
      if (!selectedOption) return false;
      return data?.customField?.myAccessRules?.allowMutation ?? false;
    },
    updateView: (record) => {
      const { data, loading } = useQuery(GET_FIELD_TYPE, {
        variables: {
          code: record.options?.selectedOption?.code
        }
      });
      if (loading) return <Loading />;
      const type = data.customField.fieldType.enum as CustomFieldTypeEnum;
      const ToDisplay = customFieldTypes[type];
      return <ToDisplay {...record} />;
    }
  })
);

const FIELD_TYPES = gql`
  query EntityCustomFieldConfigQuery($entityType: EntityTypeEnum!) {
    customFields(criteria: { entityType: $entityType }) {
      hash
      nodes {
        allowMultiple
        code
        label: heading
      }
    }
  }
`;


const GET_FIELD_TYPE = gql`
  query GetFieldTypeQuery($code: String!) {
    customField(code: $code) {
      fieldType {
        enum
      }
    }
  }
`;

const RULE_QUERY = gql`
  query CustomFieldRuleQuery($code: String!) {
    customField(code: $code) {
      myAccessRules {
        id
        allowMutation
        allowRead
      }
    }
  }
`;
