import React from 'react';
import { Form, Input, Switch } from 'antd';
import { AddWorkplaceInput, EntityTypeEnum } from '../../../../gql/typings';
import { Locale } from '../../../../localization/LocalizationKeys';
import SelectPosition from '../../../components/Input/Fields/SelectPosition';
import { useLocalization } from '../../../util/useLocalization';
import ContactFormInput from '../../person/Components/ContactFormInput';
import WorkingSpecialityFormInput from '../components/WorkingSpecialityInput';
import { useBroadcastStorage } from '../../../util/useBroadcastStorage';

type CreateLinkFieldProps = {
  countryCode?: string;
  keyCodes?: string[];
};
export const createLinkFields: Record<keyof Omit<AddWorkplaceInput, 'siteInput'|'siteId'>, React.FC<CreateLinkFieldProps>> = {
  positionCode: ({ countryCode }) => {
    const localization = useLocalization();
    return (
      <Form.Item
        name="positionCode"
        rules={[{ required: true }]}
        label={localization.formatMessage(Locale.Attribute.Position)}
      >
        <SelectPosition countryCode={countryCode} />
      </Form.Item>
    );
  },
  isPrimary: () => {
    const localization = useLocalization();
    return (
      <Form.Item
        name="isPrimary"
        label={localization.formatMessage(Locale.Attribute.Primary_Position)}
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    );
  },
  contacts: ({ keyCodes = [] }) => {
    const localization = useLocalization();
    const formListInitialValue = keyCodes?.map(keyCode => ({ categoryCode: keyCode }));
    return (
      <Form.List name='contacts' initialValue={formListInitialValue}>
        {
          (fields) => fields?.map((field, index) => <Form.Item
            key={field.key}
            label={index === 0 ? localization.formatMessage(Locale.General.Contact_information): ' '}
            labelCol={{ span: 5 }}
            colon={index === 0}
            shouldUpdate={() => true}
          >
            {() => (<ContactFormInput
              namePath={[field.name.toString()]}
              entityType={EntityTypeEnum.AFFILIATION}
            />)}
          </Form.Item>)
        }
      </Form.List>
    );
  },
  notes: () => {
    const localization = useLocalization();
    return <Form.Item name="notes" label={localization.formatMessage(Locale.Attribute.Note)}>
      <Input.TextArea
        id="link-notes"
        placeholder={localization.formatMessage(Locale.Command.Enter_Notes)}
      />
    </Form.Item>;
  },
  instantValidation: () => {
    const localization = useLocalization();
    const [instantValidate, setInstantValidate] = useBroadcastStorage('instant-validation', true);
    return (
      <Form.Item
        name='instantValidation'
        label={localization.formatMessage(Locale.Attribute.Instant_Validation)}
        valuePropName={instantValidate ? 'checked': ''}
        initialValue={instantValidate}
      >
        <Switch
          checkedChildren="Yes"
          unCheckedChildren="No"
          onChange={e => setInstantValidate(e)}
        />
      </Form.Item>
    );
  },
  customFieldValues: () => <span>Currently not supported</span>,
  personId: () => (
    <Form.Item name='personId' />
  ),
  workingSpecialtyCodes: ({ countryCode }) => {
    const localization = useLocalization();
    return (
      <Form.Item name='workingSpecialtyCodes' label={localization.formatMessage(Locale.Attribute.Working_specialty)}>
        <WorkingSpecialityFormInput countryCode={countryCode || 'DK'} />
      </Form.Item>
    );
  }

};

