import { gql } from '@apollo/client';

export const CUSTOM_UPSERT_MUTATION = gql`
  mutation UpsertCustomFieldInputValue(
    $customFieldCode: String!, 
    $customValues: [UpdateSelectCustomFieldValueForRecord!]!,
    $recordId: Int!,
  ) {
    setCustomLinkValues(customFieldCode: $customFieldCode, customValues: $customValues, recordId: $recordId) {
      customFieldCode
    }
  }
`;

export const CUSTOM_REMOVE_MUTATION = gql`
  mutation RemoveCustomFieldInputValue($customValues: [ID!]!) {
    removeCustomLinkValues(customLinkValueIds: $customValues)
  }
`;

export const FIELD_OPTIONS = gql`
  query EntityCustomFieldOptionsQuery($code: String!) {
    customField(code: $code) {
      options {
        hash
        nodes {
          id
          value {
            id
            value
          }
        }
      }
    }
  }
`;

