import { useMutation, useQuery, gql } from '@apollo/client';
import React, { useState } from 'react';
import { Form, Select, Col, Row } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import MainView from '../../components/View/MainView';
import DatePicker from '../../components/Date/DatePicker';
import Button from '../../components/Input/Button';
import Input from '../../components/Form/Input';
import { Locale } from '../../../localization/LocalizationKeys';


const ProjectCreate = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { data } = useQuery(ProjectTypes);
  const [abc] = useMutation(CreateNewProject);
  const { formatMessage } = useIntl();

  const onSubmit = () => {
    const fields = form.getFieldsValue();
    abc({
      variables: {
        title: fields.title,
        typeId: fields.type,
        start: mStartDateState[0],
        end: mEndDateState[0],
      },
    })
      .then(({ data: { createNewProject: { id } } }) => navigate(`/project/${id}`));
  };


  const listOfTypes = get(data, 'projectTypes.nodes', []);
  const currentTime = moment()
    .minute(0);
  const currentEndDate = moment()
    .minute(0);
  const mStartDateState = useState(currentTime.add(1, 'hour'));
  const mEndDateState = useState(currentEndDate.add(2, 'hour'));

  return (
    <MainView
      title={formatMessage(Locale.Command.Create_Project)}
      titleClassName="project-create"
      className="project-create-container"
      rightActions={<Button
        color="light"
        onClick={onSubmit}
        label={formatMessage(Locale.Command.Create_Project)}
      />}
    >
      <Form form={form}>
        <Row type="flex" align="middle" justify="start">
          <Col span={1}>
            Title:
          </Col><Col span={6}>
            <Input
              form={form}
              id="title"
              placeholder={formatMessage(Locale.Attribute.Project_Title)}
              autoFocus
            />
          </Col>
        </Row>
        <Row type="flex" align="middle" justify="start">
          <Col span={1}>
            Type:
          </Col><Col span={6}>
            <Form.Item
              name="type"
              rules={[{ required: true, message: formatMessage(Locale.Text.You_need_to_specify_a_title) }]}
            >
              <Select placeholder={formatMessage(Locale.Command.Select_Project_Title)}>
                {listOfTypes.map(it => (
                  <Select.Option key={it.id} value={it.id}>{it.heading}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <DatePicker
          startDateState={mStartDateState}
          endDateState={mEndDateState}
        />
      </Form>
    </MainView>
  );
};

const CreateNewProject = gql`
  mutation CreateNewProject(
    $title: String!,
    $typeId: Int!,
    $start: DateTime!,
    $end: DateTime!
  ) {
    createNewProject(title: $title, typeId: $typeId, start: $start, end: $end) { id }
  }
`;
const ProjectTypes = gql`
  query ProjectTypes {
    projectTypes {
      hash
      nodes {
        id
        code
        heading
        note
        ct
        ut
      }
    }
  }
`;

export default ProjectCreate;
