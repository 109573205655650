import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Radio } from 'antd';
import { useIntl } from 'react-intl';
import { DurationInput } from '@fullcalendar/common';
import moment, { Moment } from 'moment';
import { useLocalStorageState, useThrottleFn } from 'ahooks';
import { Dayjs } from 'dayjs';
import { ApbCalendarApi, FullcalendarDisplayMode, useCalendarOn } from './FullcalendarTypes';
import { Locale } from '../../../localization/LocalizationKeys';
import { DATE_FORMAT } from '../../util/format';

type CalendarTopMenuProps = {
  api: ApbCalendarApi;
  loading?: boolean;
  storageKey: string;
  hideChangeViewButtons?: boolean;
  setActivityPlanning: (value: boolean) => void;
  activityPlanningState: boolean;
};

type DatePickerType = 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year' | undefined;

const modeToDuration = (mode: FullcalendarDisplayMode, amount = 1): DurationInput => {
  switch (mode) {
    case 'month':
      return { months: amount };
    case 'week':
    case '3day': {
      return { days: amount };
    }
    case 'workWeek':
      return { weeks: amount };
    case 'day':
      return { days: amount };
    default:
      return {};
  }
};

const CalendarTopMenu: React.FC<CalendarTopMenuProps> = ({
  storageKey,
  api,
  loading,
  hideChangeViewButtons,
  setActivityPlanning,
  activityPlanningState
}) => {
  const intl = useIntl();
  const [displayMode, setDisplayMode] = useLocalStorageState<FullcalendarDisplayMode>(
    `${storageKey}-calendar-display-mode`,
    { defaultValue: 'workWeek' },
  );
  const [selectedTime, setSelectedTime] = useState<Moment>(moment());

  useEffect(() =>
  // @ts-ignore
  // eslint-disable-next-line implicit-arrow-linebreak
    api.changeView(displayMode),
  [api, displayMode]);

  useCalendarOn(() => {
    setSelectedTime(moment(api.getDate()));
  }, 'datesSet', api);

  const { run } = useThrottleFn(
    (amount?: number) => {
      // @ts-ignore
      if (amount) api.incrementDate(modeToDuration(displayMode, amount));
      else api.gotoDate(moment().toISOString());
    },
    { wait: 250 },
  );

  const incrementDate = (amount?: number) => () => run(amount);

  return (
    <div className="calendar-top-menu-container">
      <div className="actions">
        {!hideChangeViewButtons && <DatePicker
          className="test"
          format={DATE_FORMAT}
          value={null}
          onChange={(value: Dayjs | null) => value && api.gotoDate(value.toISOString())}
          // @ts-ignore
          picker={{ month: 'month', week: 'week', workWeek: 'week', day: 'day' }[displayMode] as DatePickerType}
        />}
        <Radio.Group value={displayMode} onChange={e => setDisplayMode(e.target.value)} className="display-mode-group">
          {!hideChangeViewButtons && <Radio.Button value="day">
            {intl.formatMessage(Locale.Attribute.Day)}
          </Radio.Button>}

          {!hideChangeViewButtons && <Radio.Button value="workWeek">
            {intl.formatMessage(Locale.Attribute.Work_Week)}
          </Radio.Button>}
          {!hideChangeViewButtons && <Radio.Button value="week">
            {intl.formatMessage(Locale.Attribute.Week)}
          </Radio.Button>}
          {!hideChangeViewButtons && <Radio.Button value="3day">
            {intl.formatMessage(Locale.Attribute.Day3)}
          </Radio.Button>}
          {!hideChangeViewButtons && <Radio.Button value="month">
            {intl.formatMessage(Locale.Attribute.Month)}
          </Radio.Button>}
        </Radio.Group>
        {!hideChangeViewButtons && <div className="date-navigation">
          <Button shape="circle" onClick={incrementDate(-1)} className="arrow">
            &#60;
          </Button>
          <Button shape="round" onClick={incrementDate()}>
            {intl.formatMessage(Locale.Attribute.Today)}
          </Button>
          <Button shape="circle" onClick={incrementDate(1)} className="arrow">
            &#62;
          </Button>

          <Button
            style={{ marginLeft: 20 }}
            className="activity-planning-button"
            type={activityPlanningState ? 'default' : 'primary'}
            onClick={() => activityPlanningState ? setActivityPlanning(false) : setActivityPlanning(true)}
          >
            {activityPlanningState
              ? intl.formatMessage(Locale.General.Close_Activity_Planning)
              : intl.formatMessage(Locale.General.Activity_Planning)}
          </Button>

        </div>}

      </div>
      <div className="selected-date-display">
        <div className="left">
          <span className="primary">
            {displayMode === 'day'
              ? selectedTime.format('YYYY MMMM')
              : selectedTime.format('MMM YYYY')}
          </span>
          <span className="secondary">
            {(displayMode === 'week' || displayMode === 'workWeek') && (
              `${intl.formatMessage(Locale.Attribute.Week)} ${selectedTime.format('w')}`
            )}
            {(displayMode === 'day') && selectedTime.format('Do')}
            &nbsp;
          </span>
          {hideChangeViewButtons && <div className="date-navigation">
            <Button shape="circle" onClick={incrementDate(-1)} className="arrow">
              &#60;
            </Button>
            <Button shape="round" onClick={incrementDate()}>
              {intl.formatMessage(Locale.Attribute.Today)}
            </Button>
            <Button shape="circle" onClick={incrementDate(1)} className="arrow">
              &#62;
            </Button>
          </div>}
        </div>
        <div className="right">
          {/* <SyncOutlined spin /> */}
        </div>
      </div>
    </div>
  );
};

export default CalendarTopMenu;
