import React from 'react';
import { Typography } from 'antd';

export type AdminSectionProps = {
  id: string;
  title: string|React.ReactNode;
};

const AdminSection: React.FC<AdminSectionProps> = ({ id, title, children }) => (
  <div id={id} className="admin-section-container">
    <Typography.Title level={3}>{title}</Typography.Title>
    <div className="section-content">
      {children}
    </div>
  </div>
);

export default AdminSection;
