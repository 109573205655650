import { ApolloClient, from, createHttpLink } from '@apollo/client';
import { authenticationAfterware, authenticationMiddleware } from './authentication';
import cache from './cache';

const httpLink = createHttpLink({ uri: '/graphql' });

const link = from([
  authenticationMiddleware,
  authenticationAfterware,
  httpLink,
]);

export default new ApolloClient({
  connectToDevTools: true,
  link,
  cache,
  defaultOptions: {
    mutate: { errorPolicy: 'all' },
    query: { errorPolicy: 'all' },
  },
});
