import { Locale } from '../../../../localization/LocalizationKeys';
import { personFieldConfig } from './utils/personFieldsUtils';

export const personMaintainerSourceFieldConfig = personFieldConfig(
  'maintainerSource',
  [
    'maintainerSource.code',
    'maintainerSource.heading',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Maintainer_Source),
    additionalTableConfig: {
      width: 100
    },
    render: (record) => record.maintainerSource.heading,
  })
);

