import { personSelectionRowFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { Locale } from '../../../../localization/LocalizationKeys';

export const selectionRowResultRowIdFieldConfig = personSelectionRowFieldConfig(
  'rowId',
  ['selectionRow.id'],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Rule_ID),
    render: record => record.selectionRow?.id ?? localization.formatMessage(Locale.General.Unknown),
  }),
);
