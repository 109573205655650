import { CustomFieldTypeEnum } from '../../../../gql/typings';


export const customFieldTypeOptionSupport = (e: CustomFieldTypeEnum): boolean => {
  switch (e) {
    case CustomFieldTypeEnum.TEXT:
    case CustomFieldTypeEnum.NUMBER:
    case CustomFieldTypeEnum.SWITCH:
    case CustomFieldTypeEnum.TAG:
      return false;
    default:
      return true;
  }
};
