import { Tooltip, Typography } from 'antd';
import React from 'react';
import { Locale } from '../../../../localization/LocalizationKeys';
import { linkFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { EntityTypeEnum, FieldEnum, SitePersonCriteriaOrder } from '../../../../gql/typings';
import { TableFieldReturnedRecordPageType } from '../../search_old/types';


export const linkPersonTypeFieldConfig = linkFieldConfig(
  'personType',
  [
    'id',
    'person.id',
    'person.persType.code',
    'person.persType.type',
    'person.persType.label',
  ],
  ({ localization }) => ({
    title: localization.formatMessage(Locale.Attribute.Person_type),
    additionalTableConfig: {
      width: 130
    },
    sorting: {
      ascend: [SitePersonCriteriaOrder.PERSON_TYPE_ASC],
      descend: [SitePersonCriteriaOrder.PERSON_TYPE_DESC],
    },
    dcrInfo: record => ({
      entityType: EntityTypeEnum.PERSON,
      entityAffiliationId: record.person.id,
      field: FieldEnum.HCP_TYPE_CODE,
    }),
    render: (record, { isViewingFromPage }) => {
      const personTypeField = record.person.persType?.label;
      return (<Typography.Paragraph
        style={{ marginBottom: 0 }}
        ellipsis={{ rows: isViewingFromPage === TableFieldReturnedRecordPageType.DETAIL_PAGE ? 2 : 1 }}
      >
        <Tooltip placement='left' title={personTypeField}>{personTypeField}</Tooltip>
      </Typography.Paragraph>);
    },
  })
);
