import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Skeleton, Tooltip } from 'antd';

type ContentSectionProps = {
  title?: string;
  tooltip?: string;
  loading?: boolean;
  noLeftMargin?: true;
  className?: string;
};

const ContentSection: React.FC<ContentSectionProps> = ({
  title,
  tooltip,
  loading,
  noLeftMargin,
  className,
  children,
}) => (
  <Skeleton loading={loading}>
    <div className={`content-section-container detail-view-section ${className || ''}`}>
      {title && (
        <h2>
          {title}{tooltip && <Tooltip title={tooltip}>
            <InfoCircleOutlined
              style={{
                fontSize: 15,
                marginLeft: 8,
              }}
            />
          </Tooltip>}
        </h2>
      )}

      <div className={`detail-view-sub-section ${noLeftMargin ? 'no-left-margin' : ''}`}>
        {children}
      </div>
    </div>
  </Skeleton>
);

export default ContentSection;
