import React from 'react';
import { gql } from '@apollo/client';
import { Select } from 'antd';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import AdminMergeItemBasicField from '../../components/basicField/AdminMergeItemBasicField';
import { MergeItemSiteTagsFragment, useMRFilterCodesQuery } from '../../../../../../../../../gql/typings';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { reactJoin } from '../../../../../../../../util/reactJoin';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';


const AdminMergeItemSiteTags: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const info = props.item.type as MergeItemSiteTagsFragment;
  const localization = useLocalization();
  const { data, loading } = useMRFilterCodesQuery();

  if (!props.item.hasConflict) return (
    <AdminMergeItemBasicField {...props}>
      {localization.formatMessage(Locale.Text.Merge_request_person_tags_description, {
        tags: reactJoin(info.loserCodes.map(lc => <strong key={lc.id}>{lc.filterCode.header}</strong>))
      })}
    </AdminMergeItemBasicField>
  );

  return (
    <AdminMergeItemBasicConflictField<number[]>
      {...props}
      fromValue={info.loserCodes.map(lc => lc.filterCode.id)}
      toValue={info.winnerCodes.map(lc => lc.filterCode.id)}
      updateMutation={() => Promise.reject()}
      valueDisplayMapper={ids => reactJoin((data?.filterCodes?.nodes ?? []).filter(n => ids?.includes(n.id)).map(fc => (
        <strong key={fc.id}>{fc.header}</strong>
      )))}
      inputRenderer={props => (
        <Select
          {...props}
          loading={loading}
          mode="tags"
          options={(data?.filterCodes.nodes ?? []).map(fc => ({ value: fc.id, label: fc.header }))}
        />
      )}
    >
      {localization.formatMessage(Locale.Text.Merge_request_person_tags_mismatch_description, {
        tags1: reactJoin(info.loserCodes.map(lc => <strong key={lc.id}>{lc.filterCode.header}</strong>)),
        tags2: reactJoin(info.winnerCodes.map(lc => <strong key={lc.id}>{lc.filterCode.header}</strong>)),
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  fragment MergeItemSiteTags on SiteTags_EntityDataToBeTransferredType {
    code
    entityType

    loserCodes {
      id
      filterCode { id, header }
    }
    winnerCodes {
      id
      filterCode { id, header }
    }
  }
`;

export default AdminMergeItemSiteTags;
