import TextyAnim from 'rc-texty';
import React from 'react';

type AnimatedTextErrorProps = {
  message?: string;
};

export const AnimatedTextErrorClassName = 'animated-text-error-container';

const AnimatedTextError: React.FC<AnimatedTextErrorProps> = (props) => (
  <div className={`${AnimatedTextErrorClassName} ${props.message ? `${AnimatedTextErrorClassName}-active` : ''}`}>
    <TextyAnim type="top" mode="sync">
      {props.message}
    </TextyAnim>
  </div>
);

export default AnimatedTextError;

