import React from 'react';
import MainView from '../../components/View/MainView';

const ImportProjectView: React.FC = (props) => (
  <MainView className="import-view-container">
    View currently not supported!
    {/* <EntitiesSearch entityType={EntityTypeEnum.IMPORT_PROJECT} /> */}
  </MainView>
);

export default ImportProjectView;
