import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { ActivityDetailContext } from '../ActivityDetails/ActivityDetailsTypes';
import HeaderWithValue from '../../../components/DisplayFields/HeaderWithValue';
import { Locale } from '../../../../localization/LocalizationKeys';
import { ActivityCreatedByQueryQuery, ActivityCreatedByQueryQueryVariables } from '../../../../gql/typings';

const ActivityCreatedBy = () => {
  const intl = useIntl();
  const {
    activityId,
  } = useContext(ActivityDetailContext);
  const { data } = useQuery<ActivityCreatedByQueryQuery, ActivityCreatedByQueryQueryVariables>(DATA_QUERY, {
    skip: !activityId,
    variables: { activityId: activityId ?? -1 },
  });

  const user = data?.activity?.createdBy;
  if (!activityId) {
    return <></>;
  }

  return (
    <HeaderWithValue heading={intl.formatMessage(Locale.Attribute.Created_by)}>
      <div className="advance-input-data-container">
        <Tooltip title={user?.fullname} placement='bottom'>{user?.fullname}</Tooltip>
      </div>
    </HeaderWithValue>
  );
};

const DATA_QUERY = gql`
  query ActivityCreatedByQuery($activityId: Int!) {
    activity(id: $activityId) {
      id
      createdBy{
        id
        fullname
        email
        md5Email
      }
    }
  }
`;

export default ActivityCreatedBy;

