import React from 'react';
import { gql } from '@apollo/client';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { AdminEntityMergeRequestItemProps } from '../../AdminEntityMergeRequestItem';
import { useLocalization } from '../../../../../../../../util/useLocalization';
import AdminMergeItemBasicConflictField from '../../components/basicConflictField/AdminMergeItemBasicConflictField';
import { Locale } from '../../../../../../../../../localization/LocalizationKeys';
import { MergeItemPersonExamYearFragment, useUpdatePersonExamYearMutation } from '../../../../../../../../../gql/typings';


const AdminMergeItemPersonExamYear: React.FC<AdminEntityMergeRequestItemProps> = (props) => {
  const localization = useLocalization();
  const info = props.item.type as MergeItemPersonExamYearFragment;
  const [updateExamYear] = useUpdatePersonExamYearMutation();

  return (
    <AdminMergeItemBasicConflictField
      {...props}
      fromValue={info.examYearFrom ? dayjs().set('year', info.examYearFrom) : undefined}
      toValue={info.examYearTo ? dayjs().set('year', info.examYearTo) : undefined}
      inputRenderer={props => <DatePicker.YearPicker {...props} />}
      valueDisplayMapper={v => v ? v.format('yyyy') : ''}
      updateMutation={newValue => updateExamYear({
        variables: {
          personId: props.into.id,
          newValue,
        },
      })}
    >
      {localization.formatMessage(Locale.Text.Merge_request_exam_years_mismatch_description, {
        examYear1: <strong>{info.examYearFrom}</strong>,
        examYear2: <strong>{info.examYearTo}</strong>,
      })}
    </AdminMergeItemBasicConflictField>
  );
};

gql`
  mutation UpdatePersonExamYear($personId: Int!, $newValue: Year!) {
    updatePersonExamYear(persId: $personId, date: $newValue) {
      id
      examYear
    }
  }
`;

gql`
  fragment MergeItemPersonExamYear on PersonExamYear_EntityDataToBeTransferredType {
    code
    examYearFrom: from
    examYearTo: to
  }
`;

export default AdminMergeItemPersonExamYear;
