import { Reducer, useReducer } from 'react';
import { maxBy } from 'lodash';

type StackAction<T> = {
  push?: T;
  keep?: number;
  pop?: true;
  replaceAndDropAfter?: { value: T; index: number };
};

function stackReducer<T>(state: T[], action: StackAction<T>) {
  if ('push' in action) {
    return [...state, action.push!];
  }
  if ('keep' in action) {
    return state.slice(0, maxBy([action.keep, 0]));
  }
  if (action.pop) {
    return state.slice(0, -1);
  }
  if ('replaceAndDropAfter' in action) {
    return [
      ...state.slice(0, maxBy([action.replaceAndDropAfter!.index + 1, 0])),
      action.replaceAndDropAfter!.value,
    ];
  }
  return state;
}

export function useStack<T>() {
  const [state, dispatch] = useReducer<Reducer<T[], StackAction<T>>>(
    stackReducer,
    [],
  );

  return {
    stack: state,
    push: (value: T) => dispatch({ push: value }),
    keep: (amount: number) => dispatch({ keep: amount }),
    pop: () => dispatch({ pop: true }),
    replaceAndDropAfter: (value: T, index: number) => dispatch({ replaceAndDropAfter: { value, index } }),
  };
}
