import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { Col, message, Modal, Row, Skeleton, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useLocalization } from '../../../../util/useLocalization';
import { usePermission } from '../../../../permission/usePermission';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { StateArray } from '../../../../util/StateArrayType';
import {
  AddressTypeQueryQuery, AddressTypeQueryQueryVariables,
  EntityTypeEnum,
  GetAvailableAddressTypesQuery,
  GetAvailableAddressTypesQueryVariables
} from '../../../../../gql/typings';

type AdminAddressTypesProps = {
  entityType: EntityTypeEnum;
};

const AdminAddressTypes: React.FC<AdminAddressTypesProps> = ({ entityType }) => {
  const { create } = usePermission('ADDRESS_TYPE');
  const localization = useLocalization();
  const { data, loading } = useQuery<GetAvailableAddressTypesQuery, GetAvailableAddressTypesQueryVariables>(DATA_QUERY, {
    variables: { entityType },
  });
  const selectedState = useState<string>();

  return (
    <div className="admin-address-types-container">
      <Skeleton loading={loading} active={loading}>
        {(data?.addressTypes.nodes ?? []).map(type => (
          <Tag key={type.code} onClick={() => selectedState[1](type.code)} style={{ cursor: 'pointer' }}>
            {localization.formatMessageByStr(type.headingKey)}
          </Tag>
        ))}
        {create && (
          <Tag
            style={{ cursor: 'pointer' }}
            onClick={() => message.warning('Currently not supported to add new address types')}
          >
            <PlusOutlined /> {localization.formatMessage(Locale.Command.New_Type)}
          </Tag>
        )}
      </Skeleton>
      <AdminAddressTypeModal selectedTypeCodeState={selectedState} />
    </div>
  );
};

const AdminAddressTypeModal: React.FC<{ selectedTypeCodeState: StateArray<string|undefined> }> = ({
  selectedTypeCodeState: [typeCode, setTypeCode],
}) => {
  const { data, loading } = useQuery<AddressTypeQueryQuery, AddressTypeQueryQueryVariables>(MODAL_QUERY, {
    variables: { code: typeCode ?? '' },
  });
  return (
    <Modal open={!!typeCode} onCancel={() => setTypeCode(undefined)} footer={false}>
      <Skeleton active={loading} loading={loading}>
        <Row>
          <Col span={8}>
            <strong>Heading Key</strong>:
          </Col>
          <Col span={16}>
            {data?.addressType?.headingKey}
          </Col>
          <Col span={8}>
            <strong>Sort Index</strong>:
          </Col>
          <Col span={16}>
            {data?.addressType?.sort}
          </Col>
          <Col span={8}>
            <strong>Amount of affiliations</strong>:
          </Col>
          <Col span={16}>
            {data?.addressType?.affiliations.totalCount}
          </Col>
        </Row>
      </Skeleton>
    </Modal>
  );
};

const DATA_QUERY = gql`
  query GetAvailableAddressTypes($entityType: EntityTypeEnum!) {
    addressTypes(criteria: { entityType: $entityType }) {
      hash
      nodes {
        code
        headingKey
      }
    }
  }
`;

const MODAL_QUERY = gql`
  query AddressTypeQuery($code: String!) {
    addressType(code: $code) {
      code
      headingKey
      sort
      affiliations {
        hash
        totalCount
      }
    }
  }
`;

export default AdminAddressTypes;
