import React, { useCallback, useRef, useState } from 'react';
import { Divider, Form, message, Modal, Steps } from 'antd';
import { gql } from '@apollo/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import { GraphQLError } from 'graphql/error/GraphQLError';
import {
  AddWorkplaceInput,
  PersonInput,
  useAddExistingPersonToSiteMutationMutation,
  useAddNewPersonToSiteMutationMutation
} from '../../../../../gql/typings';
import { useLocalization } from '../../../../util/useLocalization';
import { Locale } from '../../../../../localization/LocalizationKeys';
import { CreatePersonProvider } from '../../../person/create/CreatePersonProvider';
import AddPersonsToSiteStep1, { AddPersonToSiteRef } from './AddPersonsToSiteStep1';
import AddPersonsToSiteStep2 from './AddPersonsToSiteStep2';
import { useDetailsContext } from '../../../../components/DetailsView/useDetailsContext';
import { useTableRowSelection } from '../../../../components/Table/useTableRowSelection';

type AddPersonsToSiteModalProps = {
  siteId: number;
  visible: boolean;
  setVisible: (newValue: boolean) => void;
  countryCode: string | undefined;
};

const AddPersonsToSiteModal: React.FC<AddPersonsToSiteModalProps> = ({
  siteId,
  visible,
  setVisible,
  countryCode,
}) => {
  const [positionForm] = Form.useForm<AddWorkplaceInput>();
  const localization = useLocalization();
  const [currentStep, setCurrentStep] = useState(0);
  const [currentTab, setCurrentTab] = useState<'existing-person' | 'create-person'>('existing-person');
  const personTableSelection = useTableRowSelection({ virtualSupport: false, type: 'radio' });
  const [personInput, setPersonInput] = useState<PersonInput>();
  const personStepRef = useRef<AddPersonToSiteRef>(null);

  const [
    addPerson,
    { loading: addPersonLoading },
  ] = useAddExistingPersonToSiteMutationMutation();
  const [
    createPerson,
    { loading: createPersonLoading },
  ] = useAddNewPersonToSiteMutationMutation();
  const { broadcastShouldRefetchData } = useDetailsContext();


  const clear = useCallback(() => {
    setCurrentStep(0);
    setCurrentTab('existing-person');
    personTableSelection.clearState();
    setVisible(false);
    setPersonInput(undefined);
    positionForm.resetFields();
    personStepRef.current?.clear();
  }, [setVisible, personTableSelection, positionForm]);

  const onCreate = () => {
    const positionValues = positionForm.getFieldsValue(true);

    const onSuccess = (errors: ReadonlyArray<GraphQLError> | undefined) => {
      // TODO: Maybe use [willCreateDcr] to show that a DCR was created?
      if ((errors?.length ?? 0) === 0) {
        message.success(localization.formatMessage(Locale.Text.Successfully_added_person_to_site));
      }
      setVisible(false);
      clear();
      broadcastShouldRefetchData();
    };

    if (currentTab === 'existing-person') addPerson({
      variables: {
        workplaceInput: {
          ...positionValues,
          siteId,
          personId: personTableSelection.selection.selected[0],
        },
      },
    }).then(res => onSuccess(res.errors));
    else if (personInput) createPerson({
      variables: {
        personInput: {
          ...personInput,
          workplace: { siteId, ...positionValues },
        }
      }
    }).then(res => onSuccess(res.errors)); else { /* Something went terribly wrong??? */
    }
  };

  return (
    <Modal
      open={visible}
      width="clamp(580px, 85%, 1400px)"
      onCancel={clear}
      footer={false}
      style={{ top: 20 }}
    >
      <Steps
        current={currentStep}
        style={{ padding: '18px 32px' }}
        items={[{
          title: localization.formatMessage(Locale.Command.Select_person),
          onClick: () => setCurrentStep(0),
          style: { cursor: 'pointer' }
        },
        {
          title: localization.formatMessage(Locale.Command.Enter_position_information)
        }]}
      />
      <Divider />

      <CreatePersonProvider onCreate={personInput => {
        setPersonInput(personInput);
        setCurrentStep(1);
      }}
      >
        <div style={{ display: 'none' }}>
          <AddPersonsToSiteStep1
            siteId={siteId}
            next={() => setCurrentStep(1)}
            personTableSelection={personTableSelection}
            countryCode={countryCode}
            tabState={[currentTab, setCurrentTab]}
          />
        </div>
        {currentStep === 0 && (
          <AddPersonsToSiteStep1
            ref={personStepRef}
            siteId={siteId}
            next={() => setCurrentStep(1)}
            personTableSelection={personTableSelection}
            countryCode={countryCode}
            tabState={[currentTab, setCurrentTab]}
          />)}
        {currentStep === 1 && (
          <AddPersonsToSiteStep2
            form={positionForm}
            tabState={[currentTab, setCurrentTab]}
            countryCode={countryCode}
            onCreate={onCreate}
            onCancel={() => setVisible(false)}
            loading={addPersonLoading || createPersonLoading}
          />
        )}
      </CreatePersonProvider>
    </Modal>
  );
};

const FRAGMENT = gql`
  fragment SitePersonForCache on SitePerson {
    id
    site {
      id
      affiliations {
        hash
        totalCount
        nodes {
          id
        }
      }
    }
  }
`;

gql`
  mutation AddNewPersonToSiteMutation($personInput: PersonInput!) {
    createPerson(input: $personInput) {
      dcr { id }
      person {
        id
        affiliations {
          hash
          nodes {
            ...SitePersonForCache
          }
        }
      }
    }
  }
  ${FRAGMENT}
`;

gql`
  mutation AddExistingPersonToSiteMutation($workplaceInput: AddWorkplaceInput!) {
    createSitePerson(input: $workplaceInput) {
      dcr { id }
      sitePerson {
        ...SitePersonForCache
      }
    }
  }
  ${FRAGMENT}
`;

export default AddPersonsToSiteModal;
