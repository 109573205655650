/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import { useHover } from 'ahooks';
import { isFunction } from 'lodash';
import {
  TableFieldReturned,
  TableFieldReturnedRecordOptions, TableFieldReturnedRecordPageType,
  TableFieldsReturnedOptionType, TableFieldUpdateViewProps
} from '../../browse/search_old/types';
import DcrPopOver from '../DcrPopOver/DcrPopOver';
import Icon from '../Icon/Icon';
import FieldLabelValue from './FieldLabelValue';
import { EntityTypeEnum, GridCardFieldFragment } from '../../../gql/typings';
import { useLocalization } from '../../util/useLocalization';

export type FieldItemConfig<T> = TableFieldReturned<T> & {
  updatedQueries: TableFieldReturned<T>['queryFields'];
  alias?: string;
  fieldOptions?: GridCardFieldFragment;
};

export type FieldItemConfigs<T> = [FieldItemConfig<T>, { keyCode: string }];

type FieldLabelGroupProps<T = any> = {
  config: FieldItemConfigs<T>;
  record: T;
  refetch: () => void;
  loading: boolean;
  optionData: TableFieldsReturnedOptionType[] | undefined;
  entityType: EntityTypeEnum;
  viewingFrom: TableFieldReturnedRecordPageType;
};


const FieldLabel: React.FC<FieldLabelGroupProps> = ({
  record,
  entityType,
  refetch,
  loading,
  optionData,
  config,
  viewingFrom,
}) => {
  const localization = useLocalization();
  const [editing, setEditing] = useState<boolean>(false);
  const labelRef = useRef<any>();
  const valueRef = useRef<any>();
  const isLabelHovering = useHover(labelRef);
  const isValueHovering = useHover(valueRef);

  const [{
    title,
    dcrInfo,
    preserve,
    render,
    updateView: UpdateView,
    hasUpdateSupport,
  }] = config;


  const options: TableFieldReturnedRecordOptions = {
    selectedOption: optionData?.filter(o => o.code == config[1].keyCode)[0],
    refetchData: refetch,
    isViewingFromEntity: entityType,
    isViewingFromPage: viewingFrom,
  };
  const viewArgs: TableFieldUpdateViewProps = { record, endEditing: () => setEditing(false), options };
  // MARK: Making sure to call 'hasUpdateSupport' before any return statements, as it may contain additional hooks inside it.
  const updateSupport = (hasUpdateSupport ? hasUpdateSupport(viewArgs) : true);

  const isHovering = isLabelHovering || isValueHovering;

  useEffect(() => {
    if (!loading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  const titleView = () => {
    const isFn = isFunction(title);
    const { fieldOptions } = config[0];
    const { keyCode } = config[1];

    if (fieldOptions?.heading && !fieldOptions.isHeadingKey) {
      return fieldOptions.heading;
    }
    if (fieldOptions?.heading && fieldOptions.isHeadingKey) {
      return localization.formatMessageByStr(fieldOptions.heading);
    }

    if (isFn && keyCode) {
      return title(options);
    }
    return <>{title}</>;
  };

  const renderDcr = () => {
    if (!record) return;
    if (!dcrInfo) return;
    if (!(!editing || preserve)) return;
    const props = dcrInfo(record, options);
    if (!props) return;
    return <DcrPopOver {...props} />;
  };

  const onClickEdit = () => {
    if ((!editing || preserve) && UpdateView && updateSupport) {
      setEditing(true);
    }
  };

  return (
    <>
      <span className="field-label" ref={labelRef}>
        {titleView()}:
      </span>
      <span
        className="field-value"
        ref={valueRef}
      >
        <FieldLabelValue isEditing={editing} render={render} preserve={preserve} record={record} options={options}>
          {UpdateView && <UpdateView {...viewArgs} />}
        </FieldLabelValue>
        {renderDcr()}
        {(!editing || preserve) && UpdateView && updateSupport && (
          <Icon
            onClick={onClickEdit}
            className={`edit-icon ${isHovering ? 'icon-hovering' : ''}`}
            iconType="EDIT"
          />
        )}
      </span>
    </>
  );
};

export default FieldLabel;
