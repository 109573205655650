import React, { FC } from 'react';
import { gql } from '@apollo/client';
import ReactJson from 'react-json-view';
import { List } from 'antd';
import { MergeEventEmitter } from '../useEntityMergeEmitter';
import AdminMergeItemPersonSegmentation from './person/PersonSegmentation/AdminMergeItemPersonSegmentation';
import {
  AdminEntityMergeItemFragmentFragment,
  EntityMergeFragmentFragment,
  MergeItemAffiliationContactValueFragmentDoc,
  MergeItemAffiliationIsPrimaryFragmentDoc,
  MergeItemAffiliationPositionCodeFragmentDoc,
  MergeItemAffiliationWorkingSpecialitiesFragmentDoc,
  MergeItemPersonActivitiesFragmentDoc,
  MergeItemPersonAddressFragmentDoc,
  MergeItemPersonBirthFragmentDoc,
  MergeItemPersonConsentFragmentDoc,
  MergeItemPersonContactValueFragmentDoc,
  MergeItemPersonCountryFragmentDoc,
  MergeItemPersonCustomFieldFragmentDoc,
  MergeItemPersonEducationsFragmentDoc,
  MergeItemPersonExamYearFragmentDoc,
  MergeItemPersonExternalIdFragmentDoc,
  MergeItemPersonFirstNameFragmentDoc,
  MergeItemPersonGdprFragmentDoc,
  MergeItemPersonGenderFragmentDoc,
  MergeItemPersonIsActiveFragmentDoc,
  MergeItemPersonLastNameFragmentDoc,
  MergeItemPersonLinkFragmentDoc,
  MergeItemPersonListsFragmentDoc,
  MergeItemPersonMailToPrivateFragmentDoc,
  MergeItemPersonNotesFragmentDoc,
  MergeItemPersonProductSegmentationFragmentDoc,
  MergeItemPersonResponsibleAreasFragmentDoc,
  MergeItemPersonSegmentationFragmentDoc,
  MergeItemPersonTagsFragmentDoc,
  MergeItemPersonTherapeuticFocusFragmentDoc,
  MergeItemPersonTitleFragmentDoc,
  MergeItemPersonTypeFragmentDoc,
  MergeItemSiteActivitiesFragmentDoc,
  MergeItemSiteAddressFragmentDoc,
  MergeItemSiteContactValueFragmentDoc,
  MergeItemSiteCountryFragmentDoc,
  MergeItemSiteCustomFieldFragmentDoc,
  MergeItemSiteExternalIdFragmentDoc,
  MergeItemSiteIsUnplacedFragmentDoc,
  MergeItemSiteLinksFragmentDoc,
  MergeItemSiteListsFragmentDoc,
  MergeItemSiteMailingNamePrefixFragmentDoc,
  MergeItemSiteNameFragmentDoc,
  MergeItemSiteNotesFragmentDoc,
  MergeItemSiteSegmentationFragmentDoc,
  MergeItemSiteTagsFragmentDoc,
  MergeItemSiteWebpageFragmentDoc
} from '../../../../../../../gql/typings';
import AdminMergeItemPersonProductSegmentation
  from './person/PersonProductSegmentation/AdminMergeItemPersonProductSegmentation';
import AdminMergeItemPersonNotes from './person/PersonNotes/AdminMergeItemPersonNotes';
import AdminMergeItemPersonTitle from './person/PersonTitle/AdminMergeItemPersonTitle';
import AdminMergeItemPersonCountry from './person/PersonCountry/AdminMergeItemPersonCountry';
import AdminMergeItemPersonExternalId from './person/PersonExternalId/AdminMergeItemPersonExternalId';
import AdminMergeItemPersonLastName from './person/PersonLastName/AdminMergeItemPersonLastName';
import AdminMergeItemPersonLists from './person/PersonLists/AdminMergeItemPersonLists';
import AdminMergeItemPersonFirstName from './person/PersonFirstName/AdminMergeItemPersonFirstName';
import AdminMergeItemPersonIsActive from './person/PersonIsActive/AdminMergeItemPersonIsActive';
import AdminMergeItemPersonExamYear from './person/PersonExamYear/AdminMergeItemPersonExamYear';
import AdminMergeItemPersonSex from './person/PersonSex/AdminMergeItemPersonSex';
import AdminMergeItemPersonAddress from './person/PersonAddress/AdminMergeItemPersonAddress';
import AdminMergeItemPersonResponsibleAreas from './person/PersonResponsibleAreas/AdminMergeItemPersonResponsibleAreas';
import AdminMergeItemPersonCustomField from './person/PersonCustomField/AdminMergeItemPersonCustomField';
import AdminMergeItemPersonConsent from './person/PersonConsent/AdminMergeItemPersonConsent';
import AdminMergeItemPersonGdpr from './person/PersonGdpr/AdminMergeItemPersonGdpr';
import AdminMergeItemPersonActivities from './person/PersonActivities/AdminMergeItemPersonActivities';
import AdminMergeItemPersonContactValue from './person/PersonContactValue/AdminMergeItemPersonContactValue';
import AdminMergeItemPersonTags from './person/PersonTags/AdminMergeItemPersonTags';
import AdminMergeItemPersonBirth from './person/PersonBirth/AdminMergeItemPersonBirth';
import AdminMergeItemPersonType from './person/PersonType/AdminMergeItemPersonType';
import AdminMergeItemPersonEducations from './person/PersonEducations/AdminMergeItemPersonEducations';
import AdminMergeItemPersonMailToPrivate from './person/PersonMailToPrivate/AdminMergeItemPersonMailToPrivate';
import AdminMergeItemPersonTherapeuticFocus from './person/PersonTherapeuticFocus/AdminMergeItemPersonTherapeuticFocus';
import AdminMergeItemSiteName from './site/SiteName/AdminMergeItemSiteName';
import AdminMergeItemSiteActivities from './site/SiteActivity/AdminMergeItemSiteActivities';
import AdminMergeItemSiteAddress from './site/SiteAddress/AdminMergeItemSiteAddress';
import AdminMergeItemSiteContactValue from './site/SiteContactValue/AdminMergeItemSiteContactValue';
import AdminMergeItemAffiliationPositionCode from './link/LinkPositionCode/AdminMergeItemAffiliationPositionCode';
import AdminMergeItemSiteCustomField from './site/SiteCustomField/AdminMergeItemSiteCustomField';
import AdminMergeItemSiteExternalId from './site/SiteExternalId/AdminMergeItemSiteExternalId';
import AdminMergeItemSiteLists from './site/SiteLists/AdminMergeItemSiteLists';
import AdminMergeItemSiteNotes from './site/SiteNotes/AdminMergeItemSiteNotes';
import AdminMergeItemSiteSegmentation from './site/SiteSegmentation/AdminMergeItemSiteSegmentation';
import AdminMergeItemSiteTags from './site/SiteTags/AdminMergeItemSiteTags';
import AdminMergeItemSiteCountry from './site/SiteCountry/AdminMergeItemSiteCountry';
import AdminMergeItemSiteIsUnplaced from './site/SiteIsUnplaced/AdminMergeItemSiteIsUnplaced';
import AdminMergeItemSiteMailingNamePrefix from './site/SiteMailingNamePrefix/AdminMergeItemSiteMailingNamePrefix';
import AdminMergeItemPersonLink from './person/PersonLink/AdminMergeItemPersonLink';
import AdminMergeItemAffiliationIsPrimary from './link/LinkIsPrimary/AdminMergeItemAffiliationIsPrimary';
import AdminMergeItemSiteLink from './site/SiteLinks/AdminMergeItemSiteLink';
import AdminMergeItemSiteWebpage from './site/SiteWebpage/AdminMergeItemSiteWebpage';
import AdminMergeItemAffiliationWorkingSpecialities
  from './link/LinkWorkingSpecialities/AdminMergeItemAffiliationWorkingSpecialities';
import AdminMergeItemAffiliationContactValue from './link/ContactValue/AdminMergeItemAffiliationContactValue';


export type AdminEntityMergeRequestItemProps = {
  emitter: MergeEventEmitter['$'];
  customEmitter: MergeEventEmitter['customEmitter'];
  _stateRef: MergeEventEmitter['stateRef'];
  item: AdminEntityMergeItemFragmentFragment;
  from: EntityMergeFragmentFragment;
  into: EntityMergeFragmentFragment;
};


/**
 * When adding the new implementations below, remember to:
 *   1. Create a test migration, so we can easily test it (preferably in the existing merge request id: 2)
 *   2. Add graphql fragment to the query on the bottom of this page
 *   3. Emit all state events via the event-emitter (look at other implementations for examples)
 *      Remember to send the start state event via a `useEffect(..., []);`
 */
const types: Record<
NonNullable<AdminEntityMergeItemFragmentFragment['type']>['__typename'],
FC<AdminEntityMergeRequestItemProps>
> = {
  PersonResponsibleArea_EntityDataToBeTransferredType: AdminMergeItemPersonResponsibleAreas,
  PersonFirstName_EntityDataToBeTransferredType: AdminMergeItemPersonFirstName,
  PersonLastName_EntityDataToBeTransferredType: AdminMergeItemPersonLastName,
  PersonTitle_EntityDataToBeTransferredType: AdminMergeItemPersonTitle,
  PersonGender_EntityDataToBeTransferredType: AdminMergeItemPersonSex,
  PersonCountry_EntityDataToBeTransferredType: AdminMergeItemPersonCountry,
  PersonExamYear_EntityDataToBeTransferredType: AdminMergeItemPersonExamYear,
  PersonActivity_EntityDataToBeTransferredType: AdminMergeItemPersonActivities,
  PersonConsent_EntityDataToBeTransferredType: AdminMergeItemPersonConsent,
  PersonAddress_EntityDataToBeTransferredType: AdminMergeItemPersonAddress,
  PersonContactValue_EntityDataToBeTransferredType: AdminMergeItemPersonContactValue,
  PersonCustomField_EntityDataToBeTransferredType: AdminMergeItemPersonCustomField,
  PersonExternalId_EntityDataToBeTransferredType: AdminMergeItemPersonExternalId,
  PersonGdpr_EntityDataToBeTransferredType: AdminMergeItemPersonGdpr,
  PersonLists_EntityDataToBeTransferredType: AdminMergeItemPersonLists,
  PersonNotes_EntityDataToBeTransferredType: AdminMergeItemPersonNotes,
  PersonSegmentation_EntityDataToBeTransferredType: AdminMergeItemPersonSegmentation,
  PersonProductSegmentation_EntityDataToBeTransferredType: AdminMergeItemPersonProductSegmentation,
  PersonIsActive_EntityDataToBeTransferredType: AdminMergeItemPersonIsActive,
  PersonTags_EntityDataToBeTransferredType: AdminMergeItemPersonTags,
  PersonBirth_EntityDataToBeTransferredType: AdminMergeItemPersonBirth,
  PersonType_EntityDataToBeTransferredType: AdminMergeItemPersonType,
  PersonEducations_EntityDataToBeTransferredType: AdminMergeItemPersonEducations,
  PersonMailToPrivate_EntityDataToBeTransferredType: AdminMergeItemPersonMailToPrivate,
  PersonTherapeuticFocus_EntityDataToBeTransferredType: AdminMergeItemPersonTherapeuticFocus,
  PersonProjects_EntityDataToBeTransferredType: ({ item }) => <ReactJson src={item} />,

  PersonLink_EntityDataToBeTransferredType: AdminMergeItemPersonLink,

  SiteName_EntityDataToBeTransferredType: AdminMergeItemSiteName,
  SiteActivity_EntityDataToBeTransferredType: AdminMergeItemSiteActivities,
  SiteAddress_EntityDataToBeTransferredType: AdminMergeItemSiteAddress,
  SiteContactValue_EntityDataToBeTransferredType: AdminMergeItemSiteContactValue,
  SiteCustomField_EntityDataToBeTransferredType: AdminMergeItemSiteCustomField,
  SiteExternalId_EntityDataToBeTransferredType: AdminMergeItemSiteExternalId,
  SiteLists_EntityDataToBeTransferredType: AdminMergeItemSiteLists,
  SiteNotes_EntityDataToBeTransferredType: AdminMergeItemSiteNotes,
  SiteSegmentation_EntityDataToBeTransferredType: AdminMergeItemSiteSegmentation,
  SiteTags_EntityDataToBeTransferredType: AdminMergeItemSiteTags,
  SiteCountry_EntityDataToBeTransferredType: AdminMergeItemSiteCountry,
  SiteIsUnplaced_EntityDataToBeTransferredType: AdminMergeItemSiteIsUnplaced,
  SiteMailingNamePrefix_EntityDataToBeTransferredType: AdminMergeItemSiteMailingNamePrefix,
  SiteLinks_EntityDataToBeTransferredType: AdminMergeItemSiteLink,
  SiteWebpage_EntityDataToBeTransferredType: AdminMergeItemSiteWebpage,
  SiteParent_EntityDataToBeTransferredType: ({ item }) => <ReactJson src={item} />,
  SiteType_EntityDataToBeTransferredType: ({ item }) => <ReactJson src={item} />,

  AffiliationPositionCode_EntityDataToBeTransferredType: AdminMergeItemAffiliationPositionCode,
  AffiliationIsPrimary_EntityDataToBeTransferredType: AdminMergeItemAffiliationIsPrimary,
  AffiliationWorkingSpecialities_EntityDataToBeTransferredType: AdminMergeItemAffiliationWorkingSpecialities,
  AffiliationContactValue_EntityDataToBeTransferredType: AdminMergeItemAffiliationContactValue,
  AffiliationCustomField_EntityDataToBeTransferredType: ({ item }) => <ReactJson src={item} />,
  AffiliationExternalId_EntityDataToBeTransferredType: ({ item }) => <ReactJson src={item} />,
  AffiliationNotes_EntityDataToBeTransferredType: ({ item }) => <ReactJson src={item} />,
  AffiliationCountryCode_EntityDataToBeTransferredType: ({ item }) => <ReactJson src={item} />,
  AffiliationTherapeuticFocus_EntityDataToBeTransferredType: ({ item }) => <ReactJson src={item} />,
};


const AdminEntityMergeRequestItem = ({
  item,
  from,
  into,
  emitter,
  customEmitter,
  _stateRef,
}: AdminEntityMergeRequestItemProps): React.ReactNode => {
  if (!item?.type?.__typename) return <span>No type provided???</span>;

  const Renderer = types[item.type.__typename];
  return (
    <List.Item>
      <Renderer item={item} into={into} from={from} emitter={emitter} customEmitter={customEmitter} _stateRef={_stateRef} />
    </List.Item>
  );
};

export const AdminEntityMergeItem_FRAGMENT = gql`
  fragment AdminEntityMergeItemFragment on VirtualEntityMergeFieldInfo {
    hasConflict
    ignoredByMaintainer
    type {
      ... on PersonResponsibleArea_EntityDataToBeTransferredType { ...MergeItemPersonResponsibleAreas }
      ... on PersonFirstName_EntityDataToBeTransferredType { ...MergeItemPersonFirstName }
      ... on PersonLastName_EntityDataToBeTransferredType { ...MergeItemPersonLastName }
      ... on PersonTitle_EntityDataToBeTransferredType { ...MergeItemPersonTitle }
      ... on PersonGender_EntityDataToBeTransferredType { ...MergeItemPersonGender }
      ... on PersonCountry_EntityDataToBeTransferredType { ...MergeItemPersonCountry }
      ... on PersonExamYear_EntityDataToBeTransferredType { ...MergeItemPersonExamYear }
      ... on PersonActivity_EntityDataToBeTransferredType { ...MergeItemPersonActivities }
      ... on PersonConsent_EntityDataToBeTransferredType { ...MergeItemPersonConsent }
      ... on PersonAddress_EntityDataToBeTransferredType { ...MergeItemPersonAddress }
      ... on PersonContactValue_EntityDataToBeTransferredType { ...MergeItemPersonContactValue }
      ... on PersonCustomField_EntityDataToBeTransferredType { ...MergeItemPersonCustomField }
      ... on PersonExternalId_EntityDataToBeTransferredType { ...MergeItemPersonExternalId }
      ... on PersonGdpr_EntityDataToBeTransferredType { ...MergeItemPersonGdpr }
      ... on PersonLists_EntityDataToBeTransferredType { ...MergeItemPersonLists }
      ... on PersonNotes_EntityDataToBeTransferredType { ...MergeItemPersonNotes }
      ... on PersonSegmentation_EntityDataToBeTransferredType { ...MergeItemPersonSegmentation }
      ... on PersonProductSegmentation_EntityDataToBeTransferredType { ...MergeItemPersonProductSegmentation }
      ... on PersonIsActive_EntityDataToBeTransferredType { ...MergeItemPersonIsActive }
      ... on PersonTags_EntityDataToBeTransferredType { ...MergeItemPersonTags }
      ... on PersonBirth_EntityDataToBeTransferredType { ...MergeItemPersonBirth }
      ... on PersonType_EntityDataToBeTransferredType { ...MergeItemPersonType }
      ... on PersonEducations_EntityDataToBeTransferredType { ...MergeItemPersonEducations }
      ... on PersonMailToPrivate_EntityDataToBeTransferredType { ...MergeItemPersonMailToPrivate }
      ... on PersonTherapeuticFocus_EntityDataToBeTransferredType { ...MergeItemPersonTherapeuticFocus }
      ... on PersonLink_EntityDataToBeTransferredType { ...MergeItemPersonLink }
      
      ... on SiteName_EntityDataToBeTransferredType { ...MergeItemSiteName }
      ... on SiteActivity_EntityDataToBeTransferredType { ...MergeItemSiteActivities }
      ... on SiteAddress_EntityDataToBeTransferredType { ...MergeItemSiteAddress }
      ... on SiteContactValue_EntityDataToBeTransferredType { ...MergeItemSiteContactValue }
      ... on SiteCustomField_EntityDataToBeTransferredType { ...MergeItemSiteCustomField }
      ... on SiteExternalId_EntityDataToBeTransferredType { ...MergeItemSiteExternalId }
      ... on SiteLists_EntityDataToBeTransferredType { ...MergeItemSiteLists }
      ... on SiteNotes_EntityDataToBeTransferredType { ...MergeItemSiteNotes }
      ... on SiteSegmentation_EntityDataToBeTransferredType { ...MergeItemSiteSegmentation }
      ... on SiteTags_EntityDataToBeTransferredType { ...MergeItemSiteTags }
      ... on SiteCountry_EntityDataToBeTransferredType { ...MergeItemSiteCountry }
      ... on SiteIsUnplaced_EntityDataToBeTransferredType { ...MergeItemSiteIsUnplaced }
      ... on SiteMailingNamePrefix_EntityDataToBeTransferredType { ...MergeItemSiteMailingNamePrefix }
      ... on SiteLinks_EntityDataToBeTransferredType { ...MergeItemSiteLinks }
      ... on SiteWebpage_EntityDataToBeTransferredType { ...MergeItemSiteWebpage }
      
      ... on AffiliationPositionCode_EntityDataToBeTransferredType { ...MergeItemAffiliationPositionCode }
      ... on AffiliationIsPrimary_EntityDataToBeTransferredType { ...MergeItemAffiliationIsPrimary }
      ... on AffiliationWorkingSpecialities_EntityDataToBeTransferredType { ...MergeItemAffiliationWorkingSpecialities }
      ... on AffiliationContactValue_EntityDataToBeTransferredType { ...MergeItemAffiliationContactValue }
    }
  }
  ${MergeItemPersonResponsibleAreasFragmentDoc}
  ${MergeItemPersonFirstNameFragmentDoc}
  ${MergeItemPersonLastNameFragmentDoc}
  ${MergeItemPersonTitleFragmentDoc}
  ${MergeItemPersonGenderFragmentDoc}
  ${MergeItemPersonCountryFragmentDoc}
  ${MergeItemPersonExamYearFragmentDoc}
  ${MergeItemPersonActivitiesFragmentDoc}
  ${MergeItemPersonConsentFragmentDoc}
  ${MergeItemPersonAddressFragmentDoc}
  ${MergeItemPersonContactValueFragmentDoc}
  ${MergeItemPersonCustomFieldFragmentDoc}
  ${MergeItemPersonExternalIdFragmentDoc}
  ${MergeItemPersonGdprFragmentDoc}
  ${MergeItemPersonListsFragmentDoc}
  ${MergeItemPersonNotesFragmentDoc}
  ${MergeItemPersonSegmentationFragmentDoc}
  ${MergeItemPersonProductSegmentationFragmentDoc}
  ${MergeItemPersonIsActiveFragmentDoc}
  ${MergeItemPersonTagsFragmentDoc}
  ${MergeItemPersonBirthFragmentDoc}
  ${MergeItemPersonTypeFragmentDoc}
  ${MergeItemPersonEducationsFragmentDoc}
  ${MergeItemPersonMailToPrivateFragmentDoc}
  ${MergeItemPersonTherapeuticFocusFragmentDoc}
  ${MergeItemPersonLinkFragmentDoc}
  
  ${MergeItemSiteNameFragmentDoc}
  ${MergeItemSiteActivitiesFragmentDoc}
  ${MergeItemSiteAddressFragmentDoc}
  ${MergeItemSiteContactValueFragmentDoc}
  ${MergeItemSiteCustomFieldFragmentDoc}
  ${MergeItemSiteExternalIdFragmentDoc}
  ${MergeItemSiteListsFragmentDoc}
  ${MergeItemSiteNotesFragmentDoc}
  ${MergeItemSiteSegmentationFragmentDoc}
  ${MergeItemSiteTagsFragmentDoc}
  ${MergeItemSiteCountryFragmentDoc}
  ${MergeItemSiteIsUnplacedFragmentDoc}
  ${MergeItemSiteMailingNamePrefixFragmentDoc}
  ${MergeItemSiteLinksFragmentDoc}
  ${MergeItemSiteWebpageFragmentDoc}
  
  ${MergeItemAffiliationPositionCodeFragmentDoc}
  ${MergeItemAffiliationIsPrimaryFragmentDoc}
  ${MergeItemAffiliationWorkingSpecialitiesFragmentDoc}
  ${MergeItemAffiliationContactValueFragmentDoc}
`;


export default AdminEntityMergeRequestItem;
