import React, { useEffect } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Button, Form, Input, Modal, Select, Skeleton, Switch } from 'antd';
import { gql } from '@apollo/client/core';
import { useDebounceFn } from 'ahooks';
import { StateArray } from '../../../../../util/StateArrayType';
import { useLocalization } from '../../../../../util/useLocalization';
import { Locale } from '../../../../../../localization/LocalizationKeys';
import {
  ContactCategoryCodeCheckQueryQuery, ContactCategoryCodeCheckQueryQueryVariables,
  ContactCategoryModalQueryQuery,
  ContactCategoryModalQueryQueryVariables, ContactCategorySuggestionQueryQuery,
  ContactTypeEnum, CreateContactCategoryMutation, CreateContactCategoryMutationVariables,
  EntityTypeEnum, UpdateContactCategoryMutation, UpdateContactCategoryMutationVariables
} from '../../../../../../gql/typings';
import { PermissionInfo } from '../../../../../permission/permissionUtils';

type AdminContactCategoryModalProps = {
  selectedState: StateArray<string|undefined>;
  entityType: EntityTypeEnum;
  createNewState: StateArray<boolean>;
  permission: PermissionInfo;
  refetch: () => void;
};

type FormData = {
  code: string;
  heading: string;
  isHeadingKey: boolean;
  typeCode: ContactTypeEnum;
  allowMultipleValues: boolean;
};

const AdminContactCategoryModal: React.FC<AdminContactCategoryModalProps> = ({
  selectedState: [selected, setSelected],
  createNewState: [createNew, setCreateNew],
  entityType,
  permission,
  refetch,
}) => {
  const apolloClient = useApolloClient();
  const [form] = Form.useForm<FormData>();
  const localization = useLocalization();
  const { data, loading } = useQuery<ContactCategoryModalQueryQuery, ContactCategoryModalQueryQueryVariables>(DATA_QUERY, {
    skip: createNew,
    variables: { categoryCode: selected ?? '' },
  });
  const {
    data: suggestionData,
    loading: suggestionLoading,
  } = useQuery<ContactCategorySuggestionQueryQuery>(SUGGESTION_QUERY);
  const [
    create,
    { loading: isCreating },
  ] = useMutation<CreateContactCategoryMutation, CreateContactCategoryMutationVariables>(CREATE_MUTATION);
  const [
    update,
    { loading: isUpdating },
  ] = useMutation<UpdateContactCategoryMutation, UpdateContactCategoryMutationVariables>(UPDATE_MUTATION);

  useEffect(() => {
    if (!createNew && data?.contactCategory) form.setFieldsValue({
      code: data.contactCategory.code,
      typeCode: data.contactCategory.type.enum,
      heading: data.contactCategory.heading,
      allowMultipleValues: data.contactCategory.allowMultipleValues,
    });
    if (createNew) form.setFieldsValue({
      code: undefined,
      typeCode: undefined,
      heading: undefined,
      allowMultipleValues: true,
    });
  }, [data, createNew, form]);

  const { run: debouncedCheckCode } = useDebounceFn(
    (
      code: string,
      promiseResolveCb: (_: string) => void,
      promiseRejectCb: (error: Error) => void,
    ) => {
      if (!createNew) promiseResolveCb('');
      else if (!code || code.length === 0) {
        promiseRejectCb(new Error(localization.formatMessage(Locale.Text.Code_required)));
      } else if (code.includes(' ')) {
        promiseRejectCb(new Error(localization.formatMessage(Locale.Text.Spaces_not_allowed_in_code)));
      } else apolloClient.query<ContactCategoryCodeCheckQueryQuery, ContactCategoryCodeCheckQueryQueryVariables>({
        query: CHECK_CODE_QUERY,
        variables: { categoryCode: code }
      }).then(data => {
        if (data.data.contactCategory?.code) {
          promiseRejectCb(new Error(localization.formatMessage(Locale.Text.Code_already_in_use)));
        } else promiseResolveCb('');
      });
    },
    { wait: 1000, trailing: true, leading: true },
  );


  const onFinish = (values: FormData) => (createNew
    ? create({
      variables: {
        input: {
          code: values.code,
          heading: values.heading,
          isHeadingKey: values.isHeadingKey,
          type: values.typeCode,
          allowMultipleValues: values.allowMultipleValues,
          entityType,
        }
      }
    }) : update({
      variables: {
        input: {
          code: values.code,
          heading: values.heading,
          isHeadingKey: values.isHeadingKey,
          type: values.typeCode,
          allowMultipleValues: values.allowMultipleValues,
        }
      }
    })).then(() => {
    setCreateNew(false);
    setSelected(undefined);
    refetch();
  });

  return (
    <Modal
      open={!!selected || createNew}
      closeIcon={<></>}
      footer={false}
      onCancel={() => {
        setSelected(undefined);
        setCreateNew(false);
      }}
    >
      <Skeleton active={loading} loading={loading}>
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          onFinish={onFinish}
        >
          <Form.Item
            name="code"
            label="Code"
            required
            rules={[
              {
                validator: (_, value) => new Promise((res, rej) => {
                  debouncedCheckCode(value, res, rej);
                }),
              },
            ]}
          >
            <Input autoComplete="off" disabled={!createNew} />
          </Form.Item>

          <Form.Item
            name="heading"
            label={localization.formatMessage(Locale.Attribute.Heading)}
            rules={[{ required: true }, { min: 1 }]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            name="typeCode"
            label={localization.formatMessage(Locale.Attribute.Type)}
            rules={[{ required: true }]}
          >
            <Select
              loading={loading || suggestionLoading}
              value={data?.contactCategory?.type.heading}
              options={suggestionData?.contactTypes.nodes.map(n => ({
                value: n.code,
                label: n.heading,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="allowMultipleValues"
            valuePropName="checked"
            label="Allow multiple"
          >
            <Switch />
          </Form.Item>

          <Form.Item label="Mapping" hidden={(data?.contactCategory?.mappings.nodes.length ?? 0) === 0}>
            {(data?.contactCategory?.mappings.nodes ?? []).map(n => n.mapping).join(',')}
          </Form.Item>

          <Form.Item label="Amount of affiliations" hidden={createNew}>
            {data?.contactCategory?.values.totalCount}
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={createNew ? !permission.create : !permission.update}
              loading={isCreating || isUpdating}
            >
              {createNew
                ? localization.formatMessage(Locale.Command.Create)
                : localization.formatMessage(Locale.Command.Save)}
            </Button>
          </Form.Item>
        </Form>
      </Skeleton>
    </Modal>
  );
};

const CREATE_MUTATION = gql`
  mutation CreateContactCategory($input: CreateContactCategoryInput!) {
    createContactCategory(input: $input) {
      code
      heading
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateContactCategory($input: UpdateContactCategoryInput!) {
    updateContactCategory(input: $input) {
      code
      heading
      isHeadingKey
      allowMultipleValues
      typeEnum
      type {
        code
        enum
        heading
      }
    }
  }
`;

const SUGGESTION_QUERY = gql`
  query ContactCategorySuggestionQuery {
    contactTypes {
      hash
      nodes {
        code
        heading
      }
    }
  }
`;

const CHECK_CODE_QUERY = gql`
  query ContactCategoryCodeCheckQuery($categoryCode: String!) {
    contactCategory(code: $categoryCode) {
      code
    }
  }
`;

const DATA_QUERY = gql`
  query ContactCategoryModalQuery($categoryCode: String!) {
    contactCategory(code: $categoryCode) {
      code
      heading
      isHeadingKey
      allowMultipleValues
      mappings {
        hash
        nodes {
          id
          mapping
        }
      }
      type {
        code
        heading
        enum
      }
      values {
        hash
        totalCount
      }
    }
  }
`;

export default AdminContactCategoryModal;
