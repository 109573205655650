import React from 'react';
import { Tooltip, Typography } from 'antd';
import { gql } from '@apollo/client';
import { Locale } from '../../../../localization/LocalizationKeys';
import { siteFieldConfig } from '../../person/fields/utils/personFieldsUtils';
import { EntityTypeEnum, FieldEnum, UpdateSiteWebsiteMutationMutationVariables, } from '../../../../gql/typings';
import { buildFreeTextUpdateFieldConfig } from '../../../components/FieldConfig/buildFreeTextUpdateFieldConfig';

export const siteWebSiteFieldConfig = siteFieldConfig(
  'website',
  [
    'id',
    'siteWebpage',
    'controlSetting.id',
    'controlSetting.entityTypeId',
    'controlSetting.maintainerSourceCode',
    'controlSetting.createDcr',
  ],
  ({ localization }) => ({
    dcrInfo: site => ({
      field: FieldEnum.HCO_WEBPAGE,
      entityType: EntityTypeEnum.SITE,
      entityAffiliationId: site.id,
    }),
    title: localization.formatMessage(Locale.Attribute.Homepage),
    additionalTableConfig: {
      width: 150,
      ellipsis: {
        showTitle: false
      },
    },
    render: (record) => (
      record.siteWebpage ? <Typography.Link
        id='webpage-link'
        style={{ width: 'calc(100% - 20px)' }}
        ellipsis
        href={record.siteWebpage}
        target="_blank"
      >
        <Tooltip placement='left' title={record.siteWebpage}>
          {record.siteWebpage}
        </Tooltip>
      </Typography.Link> : ''
    ),
    updateView: buildFreeTextUpdateFieldConfig({
      mutation: UPDATE_MUTATION,
      valuePath: 'siteWebpage',
      buildVariables: (value, record) => ({
        siteId: record.id,
        webpage: value,
      }) as UpdateSiteWebsiteMutationMutationVariables,
    }),
  })
);

const UPDATE_MUTATION = gql`
  mutation UpdateSiteWebsiteMutation($siteId: Int!, $webpage: String!) {
    updateSiteWebpage(siteId: $siteId, webpage: $webpage) {
      id
      siteWebpage
    }
  }
`;
