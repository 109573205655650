import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, notification, Row, Skeleton, Space, Tag, Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import { gql, useQuery } from '@apollo/client';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import HcoSectionRight from './HcoSectionRight';
import { FlagCountryWithUnknown } from '../../../components/Flag/Flag';
import { Locale } from '../../../../localization/LocalizationKeys';
import { useColors } from '../../../util/useColor';
import Icon from '../../../components/Icon/Icon';
import Puffin from '../../../components/Puffin/Puffin';
import ExternalNavLink from '../../../components/ExternalNavLink/ExternalNavLink';
import { useLocalization } from '../../../util/useLocalization';
import EntityHeaderContainer from '../../../components/ContactCard/EntityHeaderContainer';
import {
  CheckSiteMergeRequestQueryQuery,
  CheckSiteMergeRequestQueryQueryVariables, EntityMergeStatusEnum,
  SiteDetailQuery
} from '../../../../gql/typings';

type SiteHeaderContainerProps = {
  siteId: number;
  site: SiteDetailQuery['site'];
  loading: boolean;
  refreshEmitter: EventEmitter<void>;
  setValue: () => void;
};

const puffinNotificationKey = 'puffin-site-entity-merged-notification';

const SiteHeaderContainer: React.FC<SiteHeaderContainerProps> = ({
  siteId,
  site,
  loading,
  setValue,
  refreshEmitter,
}) => {
  const renderOwnRecord = site?.maintainerSourceCode === 'LOCAL';
  const renderUnPlaced = site?.isUnplaced === true;
  const navigate = useNavigate();
  const localization = useLocalization();
  const { colorAction: actionColor } = useColors();
  const { formatMessage } = useIntl();
  const { data } = useQuery<CheckSiteMergeRequestQueryQuery, CheckSiteMergeRequestQueryQueryVariables>(MRS_QUERY, {
    skip: !site || site.isActive,
    variables: { siteId },
  });
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => () => {
    api.destroy(puffinNotificationKey);
  }, [api]);

  useEffect(() => {
    if ((data?.site?.mergeRequests?.totalCount ?? 0) > 0) {
      const request = data!.site!.mergeRequests!.nodes![0]!;
      const into = request.into as Extract<(typeof request)['into'], { ['__typename']: 'Site' }>;
      api.open({
        key: puffinNotificationKey,
        duration: null,
        message: (
          <Puffin
            loop
            type="explaining"
            message={<div style={{ textAlign: 'center' }}>{localization.formatMessage(Locale.Text.Record_has_merge_request, {
              handleState: <strong>{request.status === EntityMergeStatusEnum.UNHANDLED ? 'unhandled' : 'handled'}</strong>,
              recordName: (
                <ExternalNavLink
                  navigate={navigate}
                  to={`/hco/${into.id}`}
                  onClick={() => api.destroy(puffinNotificationKey)}
                >
                  {into.name}
                </ExternalNavLink>
              ),
            })}</div>}
          />
        ),
      });
    }
  }, [data, navigate, localization, api]);

  return (
    <Skeleton loading={loading}>
      {contextHolder}
      <Row>
        <Col span={16}>
          <EntityHeaderContainer
            title={site?.name}
            country={site?.countryCode as FlagCountryWithUnknown}
          />
        </Col>
        <Col span={8}>
          <HcoSectionRight
            siteId={siteId}
            setValue={setValue}
            site={site}
            refreshEmitter={refreshEmitter}
          />
        </Col>
        <Col style={{ marginLeft: 24 }} span={24}>
          <Space>
            {renderOwnRecord && <Tag color={actionColor}>
              {formatMessage(Locale.Text.Client_maintain)}
            </Tag>}
            {renderUnPlaced && <Tooltip title={formatMessage(Locale.Text.Unplaced_toolTip)}><Tag color={actionColor}>
              {formatMessage(Locale.Text.Unplaced)}
            </Tag></Tooltip>}
            {site && !site.isActive && <Tag color="#A6A6A6">
              <Icon
                iconType="CLOSE"
                text={formatMessage(Locale.Text.Record_is_inactive)}
              />
            </Tag>}
            {site?.isHcoCherryPicked && <Tag color="#85B0D6">
              {formatMessage(Locale.Text.Cherry_picked)}
            </Tag>}
          </Space>
        </Col>
      </Row>
    </Skeleton>
  );
};

const MRS_QUERY = gql`
  query CheckSiteMergeRequestQuery($siteId: Int!) {
    site(siteId: $siteId) {
      id
      mergeRequests {
        hash
        totalCount
        nodes {
          id
          status
          entityAffiliationId
          intoEntityAffiliationId
          into {
            ... on Site {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export default SiteHeaderContainer;

