import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { DcrGroupDetailQuery, EntityTypeEnum } from '../../../gql/typings';
import { Locale } from '../../../localization/LocalizationKeys';

type RelatedActivityDcrProps = {
  activities: Extract<NonNullable<
  DcrGroupDetailQuery['dcrGroup']>['dcrs']['nodes']['0']['dcrSnapshotInfo']['0']['affiliationEntity'],
  { ['__typename']: 'Activity' }>[];
  entityType: EntityTypeEnum;
};

const RelatedActivityDcr: React.FC<RelatedActivityDcrProps> = ({ activities, entityType }) => {
  if (activities.length === 0) return <></>;
  const { formatMessage } = useIntl();

  const conf: { [k in EntityTypeEnum]?: { id: string } } = {
    PERSON: Locale.Text.DCR_Hcp_activity_related_data,
    SITE: Locale.Text.DCR_Hco_activity_related_data,
  };

  return (<>
    {activities?.map(x => conf[entityType] && (
      <li key={x.id}>
        {formatMessage(
          conf[entityType]!,
          { activity: <NavLink to={`/activity/${x.id}`} className="content">{x.heading}</NavLink> },
        )}
      </li>
    ))}
  </>
  );
};


export default RelatedActivityDcr;
