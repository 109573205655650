import React from 'react';
import UrlTabs from '../adminComponents/UrlTabs/UrlTabs';
import { useLocalization } from '../../../util/useLocalization';
import { Locale } from '../../../../localization/LocalizationKeys';
import ReportKPIAnchoredSettings from './kpi/ReportKPIAnchoredSettings';
import ReportCallFrequencyAnchoredSettings from './kpi/ReportCallFrequencyAnchoredSettings';

const Noop = () => (
  <span>This report currently has no support for configuration</span>
);

const ReportRoutes: React.FC = () => {
  const localization = useLocalization();
  return (
    <UrlTabs
      path="reports"
      items={[

        {
          path: 'kpi',
          title: localization.formatMessage(Locale.General.KPIs),
          render: ReportKPIAnchoredSettings,
        },
        {
          path: 'call-frequency',
          title: 'Call Frequency',
          render: ReportCallFrequencyAnchoredSettings,
        },
        {
          path: 'call-types',
          title: 'Call Types',
          render: Noop,
        },
        {
          path: 'product-detailing',
          title: 'Product Detailing',
          render: Noop,
        },
        {
          path: 'monthly-call-freq',
          title: 'Monthly call frequency pr. segment',
          render: Noop,
        },
      ]}
    />
  );
};

export default ReportRoutes;
